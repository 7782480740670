import React from 'react';
import {languageObj} from "../locale/index";
import {enData} from "../locale/lang-en";
export const HostContext = React.createContext(false);
export const InstaVCContext = React.createContext(null);
let lang = localStorage.getItem('setLang') ||'en';



// export const LanguageContext = React.createContext(lang === 'fr' ? fnData.locale : enData.locale);
let locale_data = languageObj[lang] && languageObj[lang].locale ? languageObj[lang].locale : enData.locale;
export const LanguageContext = React.createContext(locale_data);

export const GroupDataContext = React.createContext({});