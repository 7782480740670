// Import Actions
import { SET_ID, SET_DOC, SET_IND_CHAT, SET_PARTICIPANT, CLEAR_IND_COUNT, CAM_CONTROLE, CAM_CONTROLE_ID } from './actions';

// Initial State
const initialState = {
  id: null, sharedocument: null, shareId: null, indchat: {}, participant: null, camControleStatus: false, camControleId: null
};

const ConferenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ID:
      return Object.assign({}, state, {id: action.id})
    case SET_DOC:
        return Object.assign({}, state, {sharedocument: action.val, shareId: action.shareId})
    case SET_IND_CHAT: let {indchat, participant} = state;
        if (indchat[action.id]) {
          let {data, unread} = indchat[action.id];
          data.push(action.message);
          indchat[action.id] = {
            unread: action.id === participant ? 0 : unread + 1,
            data
          }
        } else {
          indchat[action.id] = {
            unread: 1,
            data: [action.message]
          }
        }
        return Object.assign({}, state, {indchat: {...indchat}});
      case SET_PARTICIPANT: return Object.assign({}, state, {participant: action.id});
      case CLEAR_IND_COUNT: let _indchat = {...state.indchat};
        if (_indchat[action.id]) {
          _indchat[action.id]['unread'] = 0;
        }
        return Object.assign({}, state, {indchat: {..._indchat}});
      case CAM_CONTROLE:
        return Object.assign({}, state, {camControleStatus: action.status});
      case CAM_CONTROLE_ID:
        return Object.assign({}, state, {camControleId: action.id});
    default:
      return state;
  }
};

/* Selectors */

// Get showAddPost
// export const getShowAddPost = state => state.app.showAddPost;

// Export Reducer
export default ConferenceReducer;
