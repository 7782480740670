import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './SideNav.css';
// import './SideNavRtl.css';
class ContentNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	componentDidMount() {
		console.log('Content Pro', this.props);
	}

	render() {
		return (
			<React.Fragment>
				<ul>
					<li>
						<NavLink to="/content" activeClassName="activeTabNav"><span className="imgLink"><img src="/images/darkblue24icons/orders.png" alt="dashboard" /></span><span className="txtLink">{this.props.language.manage_content}</span></NavLink>
					</li>
					<li>
						<NavLink to="/questionnaire" activeClassName="activeTabNav"><span className="imgLink"><img src="/images/blue/questionnaire.png" alt="questionarie" /></span><span className="txtLink">{this.props.language.questionnaire}</span></NavLink>
					</li>
					{true &&
					<li>
						<NavLink to="/assignments" activeClassName="activeTabNav"><span className="imgLink"><img src="/images/blue/assignment.png" alt="assignment" /></span><span className="txtLink">{this.props.language.assignments}</span></NavLink>
					</li>
					}
					<li>
						<NavLink to="/uploads" activeClassName="activeTabNav"><span className="imgLink"><img src="/images/blue/upload-doc.png" alt="dashboard" /></span><span className="txtLink">{this.props.language.crteContent_upload_document}</span></NavLink>
					</li>
				</ul>
			</React.Fragment>
		)
	}
}

export default ContentNav;