import React, { Component, Suspense } from 'react';
import { Route } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import '../../App.css';
import './mainlayout.css';
/*import '../AppRtl.css';*/
import AdminSideNav from '../../components/common/sideNav/adminSideNav.js';
import Header from '../../components/common/header/header';

class AdminLayout extends Component {

	constructor(props) {
		super(props);
		this.state = {
			openSideNav:false,
	}
}
    handleOpen = (flag) => {
        this.setState({ openSideNav : flag });
    }
    handleCloseSideNav = () => {
        this.setState({openSideNav : false});
}

	render(){
		return(
            <div className="mainLayout">
				<Header  adminSideNav={this.handleOpen} {...this.props} />
                <div className="mainContainer">
                <div className={this.state.openSideNav ? "responsiveSideNav block320 slideInSideNav" : "responsiveSideNav block320"}>
                    <AdminSideNav {...this.props} />
                </div>
                    <div className="wrapperLayout mrgnLeft320">
                        <div className="fullStretchWrapper">
                            <Suspense fallback={<Loading />}>
                                <Route exact path={this.props.path} component={this.props.component} />
                            </Suspense>
                        </div>
                    </div>
                </div>
            </div>
		)
	}
}

export default AdminLayout;