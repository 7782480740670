import React from "react";
import { Route, Redirect } from "react-router-dom";
import ContentLayout from '../containers/layout/contentLayout';

export default ({ component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props =>
        cProps.isAuthenticated
        ?  <ContentLayout {...props} {...cProps} component={(props) => <C {...cProps} {...props} />} />
            : <Redirect
                to={`/`}
              />}
  />;

  //</MainLayout><C {...props} {...cProps} />