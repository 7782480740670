import React, { Component, Suspense, Fragment } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-dom';
import Loading from '../../components/common/Loading'
import "./Home.css";

export default class Home extends Component {
  componentDidMount() {
    console.log("THis Prop", this.props);
  }
  render() {
     return (
      <div className="homeWrapper">
        <div className="homeContainer">
          { ( this.props.isAuthenticated === undefined ) ? <Loading /> : 
          <Fragment>
            <div className="homeCol2 bannerPort">
              <div className="absFade"></div>
              <div className="brandingBlock">
                {/* {<img src="/images/logos/instavc-white-160.png" alt="instavc-log" />} */}
                <img  className="invertWhite" src={`/images/logos/instavc-small-white.png`} alt="Company Logo" />
                {/* <span>Atoms in the service of nation</span> */}
              </div>
              <div className="contentBodyBlock box475" style={{display:''}}>
                <h1 className="headingLine whiteTx">Build meaningful relationships between organizations and employees</h1>
                <h2 className="puhchLine">The Conference Platforms with an Unparalleled User Experience</h2>
                
                {/* <h1 className="headingLine whiteTx">Atoms in the service of nation</h1>  */}
                {/* <h2 className="puhchLine">The Conference Platforms with an <br /> Unparalleled User Experience</h2> */}
                {/* <h2 className="puhchLine">{this.props.language.home_instavc_conference_platform}</h2> */}

                <div className="inlineBlock">
                  {/* <span>Start Conference</span>
                  <span>|</span>
                  <span>Join Conference</span>
                  <span>|</span>
                  <span>Insta Conference</span> */}
                  {/* <span>{this.props.language.home_instavc_start_conference}</span> */}
                  <span>START CONFERENCE | JOIN CONFERENCE | INSTA CONFERENCE</span>
                </div>
                <div className="inlineBlock">
                  {/* <span> */}
                    {/* HIPAA eligible and PCI DSS, SOC, ISO/EIC 27001, ISO/EIC 27017, ISO/EIC 27018, and ISO 9001 compliant.
                     */}
                     {/* {this.props.language.home_instavc_Hippa} */}
                  {/* </span> */}
                      <span>
                        HIPAA ELIGIBLE AND PCI DSS, SOC, ISO/EIC 27001, ISO/EIC 27017, ISO/EIC 27018, AND ISO 9001 COMPLIANT.
                      </span>
                </div>  
              </div>
              <div className="contentFotrBlock">
                <small className="copyRtTxt">&#169; 2018 · instaVC · PeopleLink Unified Communications Pvt. Ltd.</small>
                <div className="tcRtTxt">I agree to the <Link to="/" className="tcAnchor">Terms &amp; Conditions</Link></div>
                {/* <small className="copyRtTxt">{this.props.language.home_instavc_copyrights}</small>
                <div className="tcRtTxt">{this.props.language.home_instavc_terms_agree}<Link to={{pathname: "https://www.instavc.com/video-conferencing-terms-of-service"}} target="_blank" className="tcAnchor">{this.props.language.home_instavc_terms}</Link></div> */}
              </div>
              {/* <div className="contentFotrBlock">
                <div className="tcRtTxt">I agree to the <Link to="/" className="tcAnchor">Terms &amp; Conditions</Link></div>
              </div> */}
            </div>
            <div className="homeCol2 brdrWLeft">
            <Suspense fallback={<Loading />}>
              <Route exact path={this.props.path} {...this.props} component={this.props.component} />
            </Suspense>
          </div>
          </Fragment>
          }
        </div>
      </div >
    

     );
  }
}