import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './SideNav.css';
// import './SideNavRtl.css';
class AdminSideNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	componentDidMount() {
		console.log('Admin Pro', this.props);
	}

	render() {
		return (
			<React.Fragment>
				<ul>
					{/* <li>
						<NavLink to="/admin" activeClassName="activeTabNav"><span className="imgLink"><img src="/images/darkblue24icons/db-dashboard-24.png" alt="dashboard" /></span><span className="txtLink">{this.props.language.instavc_myprofile_dashboard}</span></NavLink>
					</li> */}
					<li>
						<NavLink to="/admin/users" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/db-user-24.png" alt="myprofile" /></span><span className="txtLink">{this.props.language.instavc_admin_users}</span></NavLink>
					</li>
                    {/* {this.props.isPlanManager && <li>
						<NavLink to="/admin/plans" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/plans1-24.png" alt="settings" /></span><span className="txtLink">{this.props.language.instavc_plans_product}</span></NavLink>
					</li>}
                   
                    <li>
						<NavLink to="/admin/rooms" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/rooms-24.png" alt="settings" /></span><span className="txtLink">{this.props.language.instavc_package_userrooms_silver_rooms}</span></NavLink>
					</li>
					<li>
						<NavLink to="/admin/settings" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/db-setting-24.png" alt="settings" /></span><span className="txtLink">{this.props.language.instavc_dashboard_settings}</span></NavLink>
					</li>
					<li>
						<NavLink to="/admin/coupons" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/coupon-24.png" alt="settings" /></span><span className="txtLink">{this.props.language.instavc_coupon_offers}</span></NavLink>
					</li>
					<li>
						<NavLink to="/admin/affiliatelist" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/referral-account-24.png" alt="affiliates" /></span><span className="txtLink">{this.props.language.affiliates}</span></NavLink>
					</li>
					<li>
						<NavLink to="/admin/withdrawlist" activeClassName="activeTabNav" ><span className="imgLink"><img src="/images/darkblue24icons/referral-account-24.png" alt="withdraw" /></span><span className="txtLink">{this.props.language.withdraws}</span></NavLink>
					</li> */}
				</ul>
			</React.Fragment>
		)
	}
}

export default AdminSideNav;