import DispatcherEvent from "./DispatcherEvent";

export default class VoiceCommandProcess {
    constructor() {
        this.events = {};
        this.joinCommands = [];
    }
    dispatch(eventName, data) {
        const event = this.events[eventName];
        if (event) {
            event.fire(data);
        }
    }

    on(eventName, callback) {
        let event = this.events[eventName];
        if (!event) {
            event = new DispatcherEvent(eventName);
            this.events[eventName] = event;
        }
        event.registerCallback(callback);
    }

    off(eventName, callback) {
        const event = this.events[eventName];
        if (event && event.callbacks.indexOf(callback) > -1) {
            event.unregisterCallback(callback);
            if (event.callbacks.length === 0) {
                delete this.events[eventName];
            }
        }
    }

    init = () => {
        if (window.annyang) {
			// Let's define a command.
			var commands = {
				'Insta VC': function() { alert('Hello welcome to InstaVC!'); },
                'Start conference': () => { this.processCommand('START_CONFERENCE')},
                'Show rooms': () => { this.processCommand('START_CONFERENCE')},
                'Close side panel': () => { this.processCommand('CLOSE_SIDE_PANEL')},
                'Ok Start': () => { this.processCommand('OK_START')},
                'Start VC': () => { this.processCommand('OK_START')}
			};
			
			// Add our commands to annyang
			window.annyang.addCommands(commands);
			
			// Start listening.
            window.annyang.start();
		}
    }

    addJoinCommand = (command, callbackid) => {
        if (window.annyang) {
            let _index = this.joinCommands.findIndex( (cmd) => cmd === command);
            if (_index >=0) return;
            this.joinCommands.push(command);
            var commands = {
                [command]: () => {
                    this.dispatch(callbackid, {roomName: command})
                }
            };
            window.annyang.addCommands(commands);
        }
        // commands[command] = () => {};
    }
    processCommand = (command) => {
        switch (command) {
            case 'START_CONFERENCE':
                this.dispatch('voice-startconf', {});
                break;
            case 'CLOSE_SIDE_PANEL':
                this.dispatch('voice-closepanel', {});
                break;
            case 'OK_START': 
                this.dispatch('goto-conference', {});
                break;
        }
    }
}