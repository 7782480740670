import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import DehazeIcon from '@material-ui/icons/Dehaze';
import './header.css';
import { Auth, API } from "aws-amplify";
import LogoBlock from './components/LogoBlock';
import * as AuthAPI from "../../../offline-auth/auth";
import {config} from '../../../config';
import {OffLineAPI, api_server} from '../../../offline-auth/api';
import {LanguageContext} from "../../../libs/context";
const _Auth = config.isOffline ? AuthAPI.Auth : Auth;
let _API = config.isOffline ? OffLineAPI : API;


class Header extends Component {
	constructor(props){
		super(props);
		this.state = {
			dropDownMenu: false,
			hideToggle : false,
			data:null
			
		}
		this.setWrapperRef =  this.setWrapperRef.bind(this);
		
	}

	setWrapperRef(node){
		this.wrapperRef = node;
	}

	componentDidMount(){
		document.addEventListener("mousedown",this.handleClickOutside);
		this.getUserByUserId();
	}
	componentWillUnmount(){
		document.removeEventListener("mousedown",this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		if(this.wrapperRef && !this.wrapperRef.contains(event.target)){
				this.setState({ dropDownMenu: false});
				if (this.props.adminSideNav) {
					this.props.adminSideNav(false);
				}
				if (this.props.affiliateSideNav) {
					this.props.affiliateSideNav(false);
				}
				if (this.props.contentSideNav) {
					this.props.contentSideNav(false);
				}
				if (this.props.pluginSideNav) {
					this.props.pluginSideNav(false);
				}
	}
}


	toggleDropDown = () => {
		this.setState({ dropDownMenu : !this.state.dropDownMenu});
		
    };

    
    handleLogout = async event => {
        await _Auth.signOut();
		this.removelogintoken();
        this.props.userHasAuthenticated(false, null);
	}

	removelogintoken(){
		_API.get('auth', '/removelogintoken')
		.then((res) => {
			if (res && res.status) {
				console.log("login token removed successfully")
			}else{
				console.log("Please try again")
			}
		}).catch(error => {
			console.log(error);
		})
	}

	handleOpenSideNav = () => {
        console.log('hittted ldfksdflsdf');
		this.props.adminSideNav(true);
	}
	handleOpenAffiliateSideNav = () => {
        console.log('hittted ldfksdflsdf');
		this.props.affiliateSideNav(true);
	}
	
	handleOpenContentSideNav = () => {
        console.log('hittted ldfksdflsdf');
		this.props.contentSideNav(true);
	}

	handleOpenPluginSideNav = () => {
        console.log('hittted ldfksdflsdf');
		this.props.pluginSideNav(true);
	}


	getUserByUserId() {
		_API.get("user", '/listuser').then(res => {
			console.log("userres======>", res);
			this.setState({ data: res.data })

			console.log("this.state.data", this.state.data);


		}).catch(err => {
			console.log("err", err);
		})
	}
	


	render(){
		let language = this.context;
		let imgsrc='/images/icons/default-gray-user-avatar.png';
		let {userPayload} = this.props;
		if (config.isOffline) {
			let {profile, userPayload} = this.props;
			console.log("profile === ", profile);
			console.log("this.props====", this.props);
			console.log("userpayload=====", userPayload)
			if (profile.profileImage !== '') {
				imgsrc = api_server + "/uploads/"+ profile.profileImage;	
			} else if (userPayload.profileImage !== '' && userPayload.profileImage) {
				imgsrc = api_server + "/uploads/"+ userPayload.profileImage;	
			}
		}
		
			// let {data} = this.state;
			// if(data){

			// 	if (data.profileImage !== '') {
			// 		imgsrc = data.profileImage.url;
			// 	} 
			// }
		
		// if (props.location.pathname.match(/admin/)){
		// 	<div className="displayOrNot mrgRight" onClick={this.handleOpenSideNav} ref={e=>this.setWrapperRef(e)}>
		// 		<DehazeIcon/>
		// 	</div>
		// }
		let patt = new RegExp("/admin/users");
		let showToggleIcon = patt.test(window.location.pathname);

		let affiliatepage = new RegExp("/referral");
		let showToggleAffiliateIcon = affiliatepage.test(window.location.pathname);

		let contentpage = new RegExp("/content");
		let showToggleContentIcon = contentpage.test(window.location.pathname);

		let pluginpage = new RegExp("/plugin");
		let showTogglePluginIcon = pluginpage.test(window.location.pathname);

		
		
		return(
			<div className="header">
				{/****** Hamburger Menu for Responsive *****/}
				{showToggleIcon &&
				<div className="displayOrNot mrgRight" onClick={this.handleOpenSideNav} ref={e=>this.setWrapperRef(e)}>
					<DehazeIcon/>
				</div>
				}
				{showToggleAffiliateIcon &&
				<div className="displayOrNot mrgRight" onClick={this.handleOpenAffiliateSideNav} ref={e=>this.setWrapperRef(e)}>
					<DehazeIcon/>
				</div>
				}
				{showToggleContentIcon &&
				<div className="displayOrNot mrgRight" onClick={this.handleOpenContentSideNav} ref={e=>this.setWrapperRef(e)}>
					<DehazeIcon/>
				</div>
				}
				{showTogglePluginIcon &&
				<div className="displayOrNot mrgRight" onClick={this.handleOpenPluginSideNav} ref={e=>this.setWrapperRef(e)}>
					<DehazeIcon/>
				</div>
				}
				{/************ */}
				<LogoBlock logo={this.props.webLogo} />
				<div className="headerAvatarBlock" onClick={this.toggleDropDown} ref={e=>this.setWrapperRef(e)}>
					<div className="userName">
						<span>{userPayload.name}</span>
					</div>
					<div className="userAvatar">
						<img src= {imgsrc} alt="Profile"/>
					</div>
					<Suspense fallback={<div>{language.header_loading}</div>}>
						<div className={this.state.dropDownMenu ? 'headerAvaDrpDwn show' : 'headerAvaDrpDwn'} >
							<ul>
								{/* <li className="displayOrNot"><Link to="https://www.peoplelinkvc.fr/tarification-par-salle" className="secondaryTxtColor">{this.props.language.instavc_package_buynowbutton}</Link></li> */}

								<li className="displayOrNot"><Link to="/dashboard">{language.instavc_myprofile_dashboard}</Link></li>
								<li><Link to="/myprofile"> {language.instavc_dashboard_myprofile}</Link></li>
								<li><Link to="/settings">{language.instavc_dashboard_settings}</Link></li>
								{/* <li><Link to="/plugin">{language.plugins}</Link></li> */}
								{/* <li><Link to="/recordings">Video Recordings</Link></li> */}
								{/* {config.isClass && <li><Link to="/content">{language.header_content}</Link></li>} */}
								{/* {false && <li><Link to="/apidashboard">API's</Link></li> } */}
								{(this.props.isAdmin) && <li><Link to="/admin/users">{language.instavc_dashboard_myadmin}</Link></li>}
								{/* {(this.props.isAffiliate) ? <li><Link to="/referral">{language.instavc_dashboard_affiliate}</Link></li> : 
								<li><Link to="/affiliate">{language.become_affiliate}</Link></li>
								} */}
							  <li><Link onClick={this.handleLogout} to="#">{language.instavc_dashboard_logout}</Link></li>
							</ul>
					  	</div>
					</Suspense>
				</div>	
			</div>
		)
	}
}

const mapStateToProps = state => ({
    profile: state.profile,
});
Header.contextType = LanguageContext;

export default connect(mapStateToProps)(Header);
