const initialState =
{
	shareAreaOpen: false,
	currentShareTab: 'whiteboard', // whiteboard, docs, video
	docObject: null,
	wtObject: null
	// unreadMessages : 0,
	// unreadFiles    : 0
};

const sharearea = (state = initialState, action) => {
	switch (action.type) {
		case 'TOGGLE_SHARE_AREA':
			{
				const shareAreaOpen = !state.shareAreaOpen;
				// const unreadMessages = toolAreaOpen && state.currentToolTab === 'chat' ? 0 : state.unreadMessages;
				// const unreadFiles = toolAreaOpen && state.currentToolTab === 'files' ? 0 : state.unreadFiles;

				return { ...state, shareAreaOpen /*, unreadMessages, unreadFiles */ };
			}

		case 'OPEN_SHARE_AREA':
			{
				const shareAreaOpen = true;
				// const unreadMessages = state.currentToolTab === 'chat' ? 0 : state.unreadMessages;
				// const unreadFiles = state.currentToolTab === 'files' ? 0 : state.unreadFiles;

				return { ...state, shareAreaOpen /*, unreadMessages, unreadFiles*/ };
			}

		case 'CLOSE_SHARE_AREA':
			{
				const shareAreaOpen = false;

				return { ...state, shareAreaOpen };
			}

		case 'SET_SHARE_TAB':
			{
				const { shareTab } = action.payload;
				// const unreadMessages = toolTab === 'chat' ? 0 : state.unreadMessages;
				// const unreadFiles = toolTab === 'files' ? 0 : state.unreadFiles;

				return { ...state, currentShareTab: shareTab /*, unreadMessages, unreadFiles*/ };
			}
		case 'REQ_DOC_SHARE':
			{
				const { val } = action.payload;
				return { ...state, requestDocShare:val /*, unreadMessages, unreadFiles*/ };
			}
		case 'SET_DOC_OBJECT':
			{
				const docObject = action.payload;

				return { ...state, docObject: docObject, currentShareTab: "docs" };
			}

		case 'SET_DOC_Page':
			{
				const dcObj = state.docObject;
				dcObj['page'] = action.payload;
				return { ...state, docObject: dcObj };
			}

		case 'SET_WT_OBJECT':
			{
				const wtObject = action.payload;

				return { ...state, wtObject: wtObject, currentShareTab: "watch" };
			}

		// case 'ADD_NEW_RESPONSE_MESSAGE':
		// {
		// 	if (state.toolAreaOpen && state.currentToolTab === 'chat')
		// 	{
		// 		return state;
		// 	}

		// 	return { ...state, unreadMessages: state.unreadMessages + 1 };
		// }

		// case 'ADD_FILE':
		// {
		// 	if (state.toolAreaOpen && state.currentToolTab === 'files')
		// 	{
		// 		return state;
		// 	}

		// 	return { ...state, unreadFiles: state.unreadFiles + 1 };
		// }

		default:
			return state;
	}
};

export default sharearea;
