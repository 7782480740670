export const mlData = {
    "locale": {
        "home_instavc_build": "തൊഴിലിടവും  തൊഴിലാളികളും തമ്മിലുള്ള അര്‍ഥവത്തായ ബന്ധം സ്ഥാപിക്കല്‍ ",
        "home_instavc_conference_platform": "സമാനതകളില്ലാത്ത ഉപഭോക്തൃ അനുഭങ്ങളോടുകൂടിയ സമ്മേളന സംവിധാനം",
        "home_instavc_start_conference": "സമ്മേളനം തുടങ്ങുക | സമ്മേളനത്തില്‍ പങ്കുചേരുക | ദ്രുത സമ്മേളനം ",
        "home_instavc_Hippa": "HIPAA ELIGIBLE AND PCI DSS, SOC, ISO/EIC 27001, ISO/EIC 27017, ISO/EIC 27018, AND ISO 9001 COMPLIANT.",
        "home_instavc_copyrights": "© 2018 · instaVC പീപ്പിള്‍ലിങ്ക് ",
        "home_instavc_terms_agree": "താഴെ പറയുന്ന സേവന നിബന്ധനകളോട് ",
        "home_instavc_terms": "ഞാന്‍ യോജിക്കുന്നു ",
        "instavc_login": "അകത്തേക്ക് പ്രവേശിക്കുക",
        "instavc_login_email": "ഇമെയില്‍",
        "instavc_login_password": "പാസ്സ്‌വേര്‍ഡ്‌",
        "instavc_login_button": "അകത്തേക്ക് പ്രവേശിക്കുക",
        "instavc_login_forgotpassword": "പാസ്സ്‌വേര്‍ഡ്‌ മറന്നു പോയോ?",
        "instavc_login_have_account": "ഒരു അക്കൗണ്ട് ഇല്ലേ?",
        "instavc_login_signup": "അക്കൗണ്ട് സൃഷ്ടിക്കുക  ",
        "instavc_signup": "അക്കൗണ്ട് സൃഷ്ടിക്കുക  ",
        "instavc_signup_firstname": "ആദ്യപേര് ",
        "instavc_signup_lastname": "അവസാനപേര് ",
        "instavc_signup_email": "ഇമെയില്‍",
        "instavc_signup_password": "പാസ്സ്‌വേര്‍ഡ്‌",
        "instavc_signup_cpassword": "പാസ്സ്‌വേര്‍ഡ്‌ ഉറപ്പിക്കുക",
        "instavc_signup_button": "അക്കൗണ്ട് സൃഷ്ടിക്കുക ",
        "instavc_signup_already_have_account": "നിലവില്‍ അക്കൌണ്ട് ഉണ്ടോ ?",
        "instavc_signup_login": "അകത്തേക്ക് പ്രവേശിക്കുക",
        "instavc_signup_fname_error": "ആദ്യപേരില്‍ അക്ഷരങ്ങള്‍ മാത്രം ",
        "instavc_signup_lname_error": "അവസാന പേരിലും അക്ഷരങ്ങള്‍ മാത്രം ",
        "instavc_signup_email_error": "ശരിയായ ഇമെയില്‍ വിവരങ്ങള്‍ മാത്രം നല്‍കുക",
        "instavc_signup_password_error": "പാസ്സ്‌വേര്‍ഡിന് കുറഞ്ഞത്‌ 6 അക്ഷരമെങ്കിലും ഉണ്ടായിരിക്കണം,അതില്‍ ഒരു വലിയക്ഷരവും,ഒരു ചെറിയക്ഷരവും ,ഒരക്കവും,ഒരു ചിഹ്നവും ഉള്‍പ്പെട്ടിരിക്കണം",
        "instavc_signup_cpassword_error": "പാസ്സ്‌വേര്‍ഡ്‌ ഉറപ്പിക്കുന്നതിനു മുകളില്‍ നല്‍കിയ പാസ്സ്‌വേര്‍ഡ്‌ തന്നെ നല്‍കുക ",
        "instavc_forgotpassword_link": "താങ്കള്‍ ഇവിടെ നല്‍കിയിരിക്കുന്ന  ഇമെയില്‍ വിലാസത്തില്‍ ഒരു ലിങ്ക് ലഭിക്കുന്നതായിരിക്കും,അതുപയോഗിച്ചു പാസ്സ്‌വേര്‍ഡ്‌ മാറ്റാവുന്നതാണ്  ",
        "instavc_forgotpassword_email": "ഇവിടെ നല്‍കിയിരിക്കുന്ന  ഇമെയില്‍ ടൈപ്പ് ചെയ്യുക",
        "instavc_forgotpassword": "പാസ്സ്‌വേര്‍ഡ്‌ മറന്നു പോയി",
        "instavc_forgotpassword_button": "പാസ്സ്‌വേര്‍ഡ്‌ മറന്നു പോയി",
        "instavc_confirmpass_heading": "വിവരങ്ങള്‍ നല്‍കി പുതിയ പാസ്സ്‌വേര്‍ഡ്‌ ഉണ്ടാക്കുക",
        "instavc_confirmpass_verificationcode": "സ്ഥിതികരണ കോഡ്‌ നല്‍കുക ",
        "instavc_confirmpass_newpassword": "പുതിയ പാസ്സ്‌വേര്‍ഡ്‌ ടൈപ്പ് ചെയ്യുക ",
        "instavc_confirmpass_retypepassword": "ഒരിക്കല്‍ക്കൂടി പുതിയ പാസ്സ്‌വേര്‍ഡ്‌ ടൈപ്പ് ചെയ്യുക ",
        "instavc_confirmpass_button": "പുതിയ പാസ്സ്‌വേര്‍ഡ്‌ ഉണ്ടാക്കുക",
        "instavc_forgotpassword_signin": "അകത്തേക്ക് പ്രവേശിക്കുക",
        "instavc_forgotpassword_passwordcreated": "അഭിനന്ദനങ്ങള്‍ നിങ്ങള്‍ പുതിയ പാസ്സ്‌വേര്‍ഡ്‌ സൃഷ്ടിച്ചിരിക്കുന്നു,ഇനി നിങ്ങള്‍ക്ക് ഇന്സ്ടാവിസി ഉപയോഗിച്ച്ഹു തുടങ്ങാം ",
        "instavc_dashboard": "ഡാഷ് ബോര്‍ഡ്‌ ",
        "instavc_dashboard_schedule": "സമ്മേളനം  നേരത്തെ  നിശ്ചയിക്കുക",
        "instavc_dashboard_meetinglist": "മുന്‍ സമ്മേളനങ്ങള്‍ ",
        "instavc_dashboard_conferenceplatform": "സമാനതകളില്ലാത്ത ഉപഭോക്തൃ അനുഭങ്ങളോടുകൂടിയ സമ്മേളന സംവിധാനം ",
        "instavc_dashboard_SC_room": "സമ്മേളനം തുടങ്ങുക",
        "instavc_dashboard_SC_roomlist": "മുന്പ് നിശ്ചയിച്ച സമ്മേളനങ്ങളില്‍ നിന്ന് തിരഞ്ഞെടുക്കുക ",
        "instavc_dashboard_startconference": "സമ്മേളനം തുടങ്ങുക",
        "instavc_dashboard_JC": "സമ്മേളനത്തില്‍ പങ്കു ചേരുക",
        "instavc_dashboard_JC_ID": "തിരിച്ചറിയല്‍ നമ്പര്‍ ഉപയോഗിച്ച് അല്ലങ്കില്‍  നിസ്ക്ചയിച്ചവയില്‍നിന്നു ",
        "instavc_dashboard_JC_button": "സമ്മേളനത്തില്‍ പങ്കു ചേരുക",
        "instavc_dashboard_SC": "സമ്മേളനം തുടങ്ങുക",
        "instavc_dashboard_SC_p2p": "പട്ടികയില്‍ നിന്നും സമ്മേളന മുറി തിരഞ്ഞെടുക്കുക ",
        "instavc_dashboard_instaconference": "ദ്രുത സമ്മേളനം ",
        "instavc_dashboard_buynow": "ഇപ്പോള്‍ വാങ്ങുക ",
        "instavc_dashboard_myprofile": "എന്റെ വിവരങ്ങള്‍",
        "instavc_dashboard_settings": "ക്രമീകരണങ്ങള്‍ ",
        "instavc_dashboard_myadmin": "മേധാവിത്വ ക്രമീകരണങ്ങള്‍ ",
        "instavc_dashboard_affiliate": "ഞാന്‍ അംഗമാക്കിയവര്‍",
        "instavc_dashboard_logout": "പുറത്തുകടക്കുക",
        "conference_myrooms": "എന്റെ മുറികള്‍",
        "conference_myrooms_host": "സമ്മേളന നിയന്ത്രകന്‍ ",
        "conference_myrooms_roomhost": "ഈ സമ്മേളനത്തിന്റെ നിയന്ത്രകന്‍ നിങ്ങളാണ് ",
        "conference_myrooms_search": "പരതുക ...",
        "conference_myrooms_hostuser": "നിയന്ത്രിക്കുന്ന ആള്‍ ",
        "conference_myrooms_gotopremium": "മുന്‍നിര ഉപഭോക്താവകുക",
        "conference_myrooms_buynow": "ഇപ്പോള്‍ വാങ്ങുക",
        "conference_myrooms_renewal": "പുതുക്കുക",
        "conference_setup": "ക്രമീകരിക്കുക",
        "conference_myrooms_renewnow": "ഇപ്പോള്‍ പുതുക്കുക",
        // "conference_myrooms_hosts": "സമ്മേളന നിയന്ത്രകന്‍",
        "conference_myrooms_join": "പങ്കുചേരുക",
        "conference_myrooms_setup": "മുറി ക്രമീകരിക്കുക",
        "conference_myrooms_sethost_email": "താങ്കള്‍ സമ്മേളന നിയന്ത്രകനാകുക അല്ലങ്കില്‍ മറ്റാരെയെങ്കിലും നിയന്ത്രകനക്കുന്നതിനു അവരുടെ ഇമെയില്‍ വിലാസം നല്‍കുക.",
        "conference_myrooms_selfhost": "താങ്കളെ തന്നെ  സമ്മേളന നിയന്ത്രകനാകുക",
        "conference_myrooms_otherhost": "അല്ലങ്കില്‍ മറ്റാരെയെങ്കിലും നിയന്ത്രകനക്കുന്നതിനു അവരുടെ ഇമെയില്‍ വിലാസം നല്‍കുക.",
        "conference_myrooms_email": "ഇമെയില്‍ വിലാസം ",
        "cancel": "നിര്‍ത്തലാക്കുക",
        "conference_myrooms_apply": "പ്രയോഗിക്കുക",
        "view_schedule_myrooms": "എന്റെ മുറികള്‍",
        "view_schedule_search": "പരതുക ...",
        "view_schedule_schedule": "സമ്മേളനപ്പട്ടിക",
        "view_schedule_calender": "ഈ മുറിയിലെ സമ്മേളനപ്പട്ടിക കാണുന്നതിനു താഴെ തന്നിരിക്കുന്ന കലണ്ടര്‍ പരിശോധിക്കുക",
        "video_recordings": "റെക്കോര്‍ഡ്‌ ചെയ്ത സമ്മേളനങ്ങള്‍ ",
        "view_recordings_search": "പരതുക",
        "view_recordings_month": "ഈ മാസം",
        "view_recordings_week": "കഴിഞ്ഞ ഏഴു ദിവസങ്ങള്‍ ",
        "view_recordings_today": "ഇന്ന്",
        "no_recordings": "ഇതുവരെ ഒന്നും റെക്കോര്‍ഡ്‌ ചെയ്തിട്ടില്ല !",
        "no_recordings_message": "ഇതുവരെ ഇവിടെ ഇപ്പോള്‍ വീഡിയോകള്‍ ഒന്നുംതന്നെയില്ല.",
        "no_recordings_startmessage": "ഒരിക്കല്‍   റെകോര്‍ഡിംഗ് തുടങ്ങിയാല്‍  അതുമുതല്‍ റെക്കോര്‍ഡ്‌ ചെയ്ത വീഡിയോകള്‍ ഇവിടെ കാണാം .",
        "stop_recording": "നിങ്ങള്‍ക്ക് ഈ റെകോര്‍ഡിംഗ് നിരത്തലാക്കണമോ??",
        "stop_recording_agree": "വേണം ",
        "stop_recording_disagree": "വേണ്ട ",
        "conference_in_use": "സമ്മേളനം നടന്നുകൊണ്ടിരിക്കുകയാണ് ",
        "conference_mode" : "സമ്മേളന രീതി ",
        "large_conference_mode" : "വലിയ സമ്മേളനം ",
        "conference_setup_configuration": "സമ്മേളന ക്രമീകരണം ",
        "conference_setup_generateid": "സമ്മേളന തിരിച്ചറിയല്‍ നമ്പര്‍ ഇല്ല,ഒരണ്ണം ഉണ്ടാക്കു !",
        "conference_setup_regenerate": "വീണ്ടും ഉണ്ടാക്കു ",
        "conference_setup_meetingid": "തിരിച്ചറിയല്‍ നമ്പര്‍",
        "conference_setup_camera": "ക്യാമറ",
        "conference_setup_defaultmicrophone": "മൈക്ക്",
        "conference_setup_microphone": "മൈക്ക്",
        "conference_setup_volume": "ശബ്ദം ",
        "conference_setup_videomode": "വീഡിയോ ശൈലി ",
        "conference_setup_forward": "അതുപോലെതന്നെ",
        "conference_setup_mix": "കൂട്ടിക്കലര്‍ത്തി ",
        "conference_setup_invite": "ക്ഷണിക്കുക",
        "conference_setup_invitepeople": "അളകുകളെ ക്ഷണിക്കുക ",
        "conference_setup_invitefriends": "നിങ്ങളുടെ സുഹൃത്തുക്കളെയോ സഹപ്രവര്തകരെയോ സഹകരിക്കാന്‍ ക്ഷണിക്കുക .",
        "conference_setup_invite_directlink": "നിങ്ങളുടെ മുറിയിലേക്ക് നേരിട്ടുള്ള  കണ്ണി",
        "conference_setup_invite_sharelink": "നിങ്ങളുടെ യോഗത്തില്‍  ആളുകള്‍ക്ക് പങ്കെടുക്കാന്‍ ഈ കണ്ണി പങ്കുവെയ്ക്കുക ",
        "conference_setup_invite_link": "https://connect.instavc.com/conference-guest/eddca0fc7adcdc61a24dabe02597412a36bb96dac7302f3cedb6aac50169d58614eb46b940b556a9/f",
        "conference_setup_invitebyemail": "ക്ഷണം ഇമെയിലില്‍ അയക്കുക ",
        "conference_setup_invite_enteremail": "നിങ്ങള്‍ക്ക് ക്ഷണിക്കെണ്ടവരുടെ,  താല്പര്യമുള്ള ഇമെയില്‍ വിലാസം നല്‍കുക ",
        "conference_setup_invite_emailid": "ഇമെയില്‍ വിലാസം",
        "conference_setup_invite_close": "പൂര്‍ത്തിയാക്കുക",
        "conference_setup_invite_button": "ക്ഷണിക്കുക",
        "conference_setup_advancesettings": "വിപുലമായ ക്രമീകരണങ്ങള്‍ ",
        "conference_setup_changeresolution": "വീഡിയോ റസുലുഷന്‍ തിരഞ്ഞെടുക്കുക ",
        "conference_setup_lowbandwidth": "വേഗത കുറഞ്ഞ ഇന്റര്‍നെറ്റ്‌ ",
        "conference_setup_poornetwork": "തീരെ വേഗത കുറഞ്ഞ ഇന്റര്‍നെറ്റ്‌ ",
        "conference_setup_vga": "VGA - (640x480)",
        "conference_setup_vga_graphics": "വീഡിയോ ഗ്രാഫിക്ക്സ് അറെ (VGA) വീഡിയോ റസുലുഷനു വേണ്ടി ",
        "conference_setup_hd1080": "HD1080p - (1920x1080)",
        "conference_setup_fhd": "ഫുള്‍ HD  വീഡിയോ റസുലുഷനു വേണ്ടി ",
        "conference_setup_4k": "4K - (3840 x 2160)",
        "conference_setup_4k_fhd": "4K വീഡിയോ റസുലുഷനു വേണ്ടി ",
        "conference_setup_sif": "SIF - (320x240)",
        "conference_setup_sif_vr": "SIF വീഡിയോ റസുലുഷനു വേണ്ടി ",
        "conference_setup_hd720": "HD720p - (1280x720)",
        "conference_setup_hd720_vr": "HD  വീഡിയോ റസുലുഷനു വേണ്ടി ",
        "conference_setup_2k": "2K - (2048×1080)",
        "conference_setup_2k_fhd": "FHD  വീഡിയോ റസുലുഷനു വേണ്ടി",
        "conference_setup_broadcastoptions": "പ്രക്ഷേപണ താത്പര്യം",
        "conference_setup_enable": "ചെയ്യുക",
        "conference_setup_disable": "ചെയ്യാതിരിക്കുക",
        "conference_setup_reportquality": "വീഡിയോ/ഓഡിയോ കാളുകളുടെ നിലവാരം ഉയര്‍ത്താന്‍ സഹായിക്കാന്‍  ഗുണമേന്മ സ്ഥിതിവിവരം അറിയിക്കുക ",
        "conference_setup_havetroubleshoot": "സമ്മേളനത്തിലെ തകരാറുകള്‍ പരിശോധിക്കാന്‍ തല്പര്യപ്പെടുന്നുണ്ടോ ",
        "conference_setup_conferencetroubleshoot": "സമ്മേളന തകരാര്‍ പരിശോധന ",
        "conference_troubleshoot": "പരിശോധിക്കുക ",
        "conference_troubleshoot_faq": "ഏറ്റവും കൂടുതല്‍ ചോദിച്ച സംശയങ്ങള്‍ ",
        "conference_troubleshoot_emailreport": "പരിശോധന ഫലം ഇമെയില്‍ ചെയ്യുക",
        "conference_troubleshoot_gotoconference": "സമ്മേളനത്തിലേക്ക് കടക്കുക ",
        "conference_troubleshoot_os": "കമ്പ്യൂട്ടറിന്റെ ഒപെറേറ്റിംഗ് സിസ്റ്റം ",
        "conference_troubleshoot_version": "പതിപ്പ് ",
        "conference_troubleshoot_browser": "ഇന്റര്‍നെറ്റ്‌ ബ്രൌസര്‍",
        "conference_troubleshoot_chromeversion": "ഗൂഗിള്‍ ക്രോം പതിപ്പ് ",
        "conference_troubleshoot_privatebrowsing": "രഹസ്യ പരതല്‍",
        "conference_troubleshoot_displayresolution": "പ്രദര്‍ശന അളവ്  ",
        "conference_troubleshoot_aspectratio": "ദര്‍ശനം അളവ് ",
        "conference_troubleshoot_hasspeakers": "കമ്പ്യൂട്ടറിനു ഉച്ചഭാഷിണി ഉണ്ടോ  ?",
        "conference_troubleshoot_foundspeaker": "നിലവിലെ ഉച്ചഭാഷിണി  ",
        "conference_troubleshoot_hasmicrophone": "കമ്പ്യൂട്ടറിനു മൈക്ക് ഉണ്ടോ ?",
        "conference_troubleshoot_foundmicrophone": "നിലവിലെ മൈക്ക്",
        "conference_troubleshoot_haswebcam": "കമ്പ്യൂട്ടറിനു ക്യാമറ ഉണ്ടോ ?",
        "conference_troubleshoot_foundcameras": "നിലവിലെ ക്യാമറ ",
        "conference_troubleshoot_webcampermission": "വെബ് സൈറ്റിന് ക്യാമറ അനുമതി ഉണ്ടോ ?",
        "conference_troubleshoot_microphonepermission": "വെബ് സൈറ്റിന് മൈക്ക് അനുമതി ഉണ്ടോ?",
        "conference_troubleshoot_getUsermedia": "getUserMedia ബ്രൌസര്‍ അനുമതിയുണ്ടോ ?",
        "conference_troubleshoot_changeoutput_audiodevice": "ഉച്ചഭാഷിണി  മാറ്റാന്‍ സാധിക്കുന്നുണ്ടോ ?",
        "conference_troubleshoot_changecamera_resolution": "നിലവിലെ സമ്മേളനത്തില്‍ നിന്നുകൊണ്ട് ക്യാമറ ദര്‍ശന അളവ് മാറ്റാന്‍ സാധിക്കുന്നുണ്ടോ ?",
        "conference_troubleshoot_supportwebrtc": "WebRTC പ്രവര്‍ത്തനക്ഷമമായ ഇന്റര്‍നെറ്റ്‌ ബ്രൌസെര്‍  ആണോ  (Either 1.0 or 1.1)?",
        "conference_troubleshoot_supportortc": "ORTC പ്രവര്‍ത്തനക്ഷമമായ ഇന്റര്‍നെറ്റ്‌ ബ്രൌസെര്‍  ആണോ (WebRTC 1.1) ?",
        "conference_troubleshoot_turnserver": "TURN സെര്‍വര്‍ കണക്ഷന്‍ ലഭ്യമാണോ  ?",
        "conference_troubleshoot_turn": "turn:139.59.64.172:443",
        "conference_troubleshoo_coturn" : "turn:coturn.instavc.com:443",
        "conference_troubleshoot_replacetracks": "അടുത്തടുത്ത ഘടകങ്ങളുമായി സംവധിക്കാതെ തന്നെ ട്രാക്കുകള്‍ മാറ്റാന്‍ സാധിക്കുണ്ടോ ?",
        "conference_troubleshoot_record_remoteaudio": "WebAudio API യിലൂടെ മറ്റു സ്ഥലങ്ങളിലെ ശബ്ദം  റെക്കോര്‍ഡ്‌ ചെയ്യുന്നതിനുംസംസകരിക്കുന്നതിനും  നിങ്ങളുടെ ബ്രൌസറില്‍ കൂടി സാധിക്കുമോ?  ",
        "conference_troubleshoot_browsersupports_websocketapi": "WebSockets API ഈ ബ്രൌസറില്‍ പ്രവര്‍ത്തിക്കുമോ ?",
        "conference_troubleshoot_blockwebsocket": "നിങ്ങളുടെ സിസ്റ്റം വെബ് സോക്കെറ്റ് പ്രോട്ടോക്കോള്‍ തടസപ്പെടുത്തുന്നുണ്ടോ അല്ലങ്കില്‍ വെബ് സെര്‍വര്‍ ബന്ധം ലഭിക്കുന്നുണ്ടോ ? ഇല്ല  ",
        "conference_troubleshoot_supportwebaudioapi": "WebAudio API ഈ ബ്രൌസറില്‍ പ്രവര്‍ത്തിക്കുമോ?",
        "conference_troubleshoot_supportsctp": "SCTP Data Channels ഈ ബ്രൌസറില്‍ പ്രവര്‍ത്തിക്കുമോ ?",
        "conference_troubleshoot_supportsrtp": "RTP Data Channels ഈ ബ്രൌസറില്‍ പ്രവര്‍ത്തിക്കുമോ ?",
        "conference_troubleshoot_support_screencapturing": "സ്ക്രീന്‍ ക്യപ്ച്ചറിംഗ്  API  ബ്രൌസറില്‍ പ്രവര്‍ത്തിക്കുമോ ?",
        "conference_troubleshoot_support_multimonitor": "ഒന്നിലധികം മോണിറ്റര്‍ തിരഞ്ഞെടുക്കുന്നതിനും അതെലെതന്കിലും ഒന്നില്‍ നിന്നും  റെക്കോര്‍ഡ്‌ ചെയ്യുന്നതിന് ബ്രൌസര്‍ സജ്ജമാണോ ?",
        "conference_troubleshoot_mobiledevice": "ഇതൊരു മൊബൈല്‍ ആണോ ?",
        "conference_troubleshoot_supportscreencap_canvas": "ക്യാന്‍വാസില്‍ നിന്നും റെക്കോര്‍ഡ്‌ ചെയ്യുന്നതിന് ബ്രൌസര്‍ സജ്ജമാണോ ?",
        "conference_troubleshoot_supportscreencap_video": "വിഡിയോയില്‍ നിന്നും റെക്കോര്‍ഡ്‌ ചെയ്യുന്നതിന് ബ്രൌസര്‍ സജ്ജമാണോ ?",
        "conference_troubleshoot_supportpromisses": "ബ്രൗസർ വാഗ്ദാനങ്ങളെ പിന്തുണയ്ക്കുന്നുണ്ടോ ?",
        "conference_troubleshoot_ipaddress": "IP അഡ്രെസ്സ് കാണുവാന്‍ ഇവിടെ ക്ലിക്ക് ചെയ്യുക ",
        "conference_troubleshoot_stunserver": "STUNസെര്‍വര്‍ ബന്ധം ലഭ്യമാണോ ?", "അസാധുവാക്കുക": "അസാധുവാക്കുക",
        "instavc_conference_revoke_fromhost" : "സമ്മേളന നിയന്ത്രണം ഒഴിവാക്കുക ",
        "instavc_conference_startvideoconf_button": "സമ്മേളനം തുടങ്ങുക",
        "instavc_conference_loading": "ചിത്രങ്ങള്‍ സജ്ജമാക്കുന്നു ദയവായി കാത്തിരിക്കുക ",
        "instavc_conference_authenticating": "ഇന്സ്ടാവിസി സെര്‍വറില്‍ വിവരങ്ങള്‍ പരിശോധിക്കുന്നു ",
        "instavc_conference_joinconference": "സമ്മേളനത്തിലോട്ട് കടക്കുന്നു ",
        "instavc_conference_publishyourvideo": "ദൃശ്യം പ്രസിദ്ധീകരിക്കുന്നു ",
        "instavc_conference_publishyouraudio": "ശബ്ദം പ്രസിദ്ധീകരിക്കുന്നു ",
        "instavc_conference_settings": "ക്രമീകരണങ്ങള്‍ ",
        "instavc_conference_setting": "സമ്മേളന ക്രമീകരണങ്ങള്‍",
        "instavc_conference_setting_source": "ഉറവിടം ",
        "instavc_conference_setting_camera": "ക്യാമറ",
        "instavc_conference_audioinputs": "ശബ്ദം  നല്‍കുന്നത് ",
        "instavc_conference_setting_apply": "പ്രയോഗിക്കുക",
        "instavc_conference_setting_resolution": "ദ്രിശ്യമികവ്",
        "instavc_conference_setting_qr": "ക്യൂ ആര്‍ (ബീറ്റാ)",
        "instavc_conference_whiteboard": "എഴുത്തുപകരണം",
        "instavc_conference_screenshare": "സ്ക്രീന്‍ പങ്കുവെക്കല്‍ ",
        "instavc_conference_presenter": "സമ്മേളന മേധാവി",
        "instavc_conference_stopshare": "പങ്കുവെയ്ക്കല്‍ അവസാനിപ്പിക്കുക",
        "instavc_conf_install_Screenshare" : "സ്ക്രീന്‍ പങ്കുവെക്കല്‍ ഇന്‍സ്ടാള്‍ ചെയ്യുക ",
        "instavc_conference_videosync": "ഒരുമിച്ചു കാണുക ",
        "instavc_conference_videourl": "വീഡിയോ URL",
        "instavc_conference_enter_videourl": "വീഡിയോ URL നല്‍കുക",
        "instavc_conference_videourl_note": "കുറിപ്പ്: നിലവില്‍ സമ്മേളനത്തില്‍ ഉള്ളവര്‍ക്ക് മാത്രമായിരിക്കും വീഡിയോ കാണുവാന്‍ സാധിക്കു ,വൈകി പങ്കെടുക്കുന്നവര്‍ക്ക് കാണുവാന്‍ സാധിക്കില്ല",
        "instavc_conf_recordings" : "റെക്കോര്‍ഡ്‌ ചെയ്യുന്നു",
        "instavc_conference_sharecontent": "നിങ്ങളുടെ സ്ക്രീന്‍ പങ്കുവെയ്ക്കുക",
        "instavc_conference_sharecontent_screen": "connect.instavc.com നിങ്ങളുടെ സ്ക്രീനില്‍ കാണുന്നത് പങ്കുവെയ്ക്കാന്‍ താല്പര്യപ്പെടുന്നു ",
        "instavc_conference_shareentire_screen": "നിങ്ങളുടെ സ്ക്രീന്‍ മുഴുവനും",
        "instavc_conference_sharescreen_applicationwindow": "അപ്പ്ലിക്കേഷന്‍ മാത്രം ",
        "instavc_conference_sharescreen_chrometab": "ഇന്റര്‍നെറ്റ്‌ ബ്രൌസറിലെ ടാബ്",
        "instavc_conference_sharescreen_share": "പങ്കുവെയ്ക്കുക",
        "instavc_conf_sharescreen":"നിങ്ങള്‍ നിങ്ങളുടെ സ്ക്രീന്‍ പങ്കുവെയ്കുകയാണ്!",
        "instavc_conf_share_otherparticipants": "മറ്റുള്ളവര്‍ക്ക് നിങ്ങളുടെ സ്ക്രീനിലുള്ള അപ്ളിക്കേഷനും,പ്രമാണങ്ങളും കാണാവുന്നതാണ്",
        "instavc_stopsharing" : "പങ്കുവെയ്ക്കുന്നത് അവസാനിപ്പിക്കുക",
        "instavc_conference_chat": "ചാറ്റ്",
        "instavc_conference_additional_camera": "അധികമായുള്ള ക്യാമറ",
        "instavc_conference_sharescreen_groupchat": "ഗ്രൂപ്പ് ചാറ്റ്",
        "instavc_conference_sharescreen_GC": "GC",
        "instavc_conference_entermessage": "എന്തെങ്കിലും പറയു!",
        "instavc_conference_adddevice": "ഉപകരണങ്ങള്‍ ചേര്‍ക്കുക ",
        "instavc_conference_adddevice_addcamera": "ക്യാമറ ചേര്‍ക്കുക",
        "instavc_conference_adddevice_source": "ഉറവിടം",
        "instavc_conference_adddevice_camera": "ക്യാമറ",
        "instavc_conference_adddevice_cameraname": "FaceTime HD Camera",
        "instavc_conference_adddevice_close": "നിര്‍ത്തുക ",
        "instavc_conference_adddevice_publish": "പ്രസിദ്ധികരിക്കുക",
        "instavc_conference_info": "വിവരങ്ങള്‍",
        "questions": "ചോദ്യങ്ങള്‍",
        "instavc_conference_askquestions": "ചോദ്യങ്ങള്‍ ചോദിക്കുക",
        "instavc_conference_info_meetingid": "വ്യക്തിഗത സമ്മേളന കോഡ്‌",
        "instavc_conference_fullscreen": "സ്ക്രീന്‍ മുഴുവാനാക്കുക",
        "conference_setup_invitepeoplel": "ആളുകളെ ക്ഷണിക്കുക",
        "conference_setup_invite_linktojoin": "https://connect.instavc.com/conference-guest/93ac2135d88727ca29061b4b6bf9477bc8f285a8121ff580649512abad353fbc6751a134d56438f7/f",
        "conference_setup_invite_byemail": "ഇമെയില്‍ വഴി ക്ഷണിക്കുക.",
        "instavc_conference_participants": "പങ്കെടുക്കുന്നവര്‍",
        "instavc_conference_participants_search": "പരതുക...",
        "instavc_conference_participant": "പങ്കെടുക്കുന്ന ആള്‍",
        "instavc_conference_participants_muteall": "എല്ലാവരെയും നിശബ്ധരക്കുക",
        "instavc_conference_participants_unmuteall": "എല്ലാവരെയും സംസാരിക്കാന്‍ അനുവദിക്കുക",
        "instavc_conference": "സമ്മേളനം",
        "instavc_conf_more": "ഇനിയും",
        "instavc_conference_picturemode": "ഇപ്പോള്‍ നിങ്ങള്‍ കാണുന്നത് ചിത്രന്തര്‍ചിത്രമായാണ് ",
        "instavc_conference_mediastreams_stats": "മാധ്യമ ഗതിയുടെ അവസ്ഥ ",
        "instavc_conference_mediastreams_audio": "ശബ്ദം",
        "instavc_conference_mediastreams_audio_send": "അയക്കുക",
        "instavc_conference_mediastreams_audio_codec": "മാധ്യമ പരിഭാഷി",
        "instavc_conference_mediastreams_audio_rates": "നിരക്ക്(Kbps)",
        "instavc_conference_mediastreams_audio_lost": "നഷ്ടമായവ",
        "instavc_conference_mediastreams_audio_fraction": "അംശം",
        "instavc_conference_mediastreams_video": "Video",
        "instavc_conference_mediastreams_video_send": "അയക്കുക",
        "instavc_conference_mediastreams_video_codec": "മാധ്യമ പരിഭാഷി",
        "instavc_conference_mediastreams_video_rates": "നിരക്ക്(Kbps)",
        "instavc_conference_mediastreams_video_lost": "നഷ്ടമായവ",
        "instavc_conference_mediastreams_video_fraction": "അംശം",
        "instavc_conference_resolution": "വിഡിയോ അളവ്",
        "instavc_conference_framerate": "ചിത്രങ്ങളുടെ എണ്ണം",
        "instavc_conference_nack": "Nack",
        "instavc_conference_pli": "PLI",
        "instavc_conference_bw": "ലഭ്യമായ ബാന്‍ഡ് വിഡ്ത്ത്",
        "instavc_conference_audiomuted": "ശബ്ധംഒഴിവാക്കി",
        "instavc_conference_microphonemuted": "നിങ്ങളുടെ മൈക്ക് ഇല്ലാതാക്കിയിരിക്കുന്നു",
        "join_conference_join_meetinginvitation": "ക്ഷണിച്ച സമ്മേളനങ്ങളില്‍ പങ്കെടുക്കുക",
        "join_conference_search": "പരതുക...",
        "join_conference_meeting details": "സമ്മേളന നമ്പര്‍ \nക്ഷണനം അനുസരിച്ച് :\nക്ഷണിച്ച ആള്‍ ",
        "join_conference_join": "പങ്കുചേരുക",
        "join_conference_nomeetings": "യോഗങ്ങള്‍ ഒന്നുമില്ല",
        "join_conference_noupcomingmeetings": "ഇനി വരാനിനിരിക്കുന്ന യോഗങ്ങള്‍ ഒന്നുമില്ല ",
        "insta_conference_mycontacts": "സമ്പര്‍ക്ക ശേഖരം",
        "insta_conference_search": "പരതുക...",
        "insta_conference_typemessage": "ഒരു സന്ദേശം കുറിക്കു",
        "insta_conference_addcontact": "സമ്പര്‍ക്കം ചേര്‍ക്കുക",
        "insta_conference_enteremail": "ഇമെയില്‍ നല്‍കുക ",
        "insta_conference_add": "ചേര്‍ക്കുക",
        "insta_conference_nouserfound": "നല്‍കിയ വിലാസത്തില്‍ ഒരു ഉപഭോക്തവില്ല",
        "instavc_conf_callnot_allowed" : "ഒരു കോളില്‍ ആയിരിക്കുമ്പോള്‍ വേറൊരാളെ വിളിക്കാന്‍ സാധിക്കില്ല",
        "insta_conference_pendingapproval": "അനുവാദം കാത്തിരിക്കുന്നു ",
        "insta_conference_outgoingvideocall": "വിഡിയോ കോള്‍ പുറത്തേക്കു ",
        "insta_conference_calling": "കാളിംഗ് ....",
        "insta_conference_incomingvideocall": "വിഡിയോ കോള്‍ അകത്തേക്ക് ",
        "insta_conference_calldecline": "നിരസിക്കു ",
        "insta_conference_callaccept": "സ്വീകരിക്കു ",
        "insta_conference_instavcalert": "connect.instavc.com  അനുവാദം ചോദിക്കുന്നു ",
        "insta_conference_usemicrophone": "നിങ്ങളുടെ മൈക്ക് ഉപയോഗിക്കാന്‍ ",
        "insta_conference_usecamera": "നിങ്ങളുടെ ക്യാമറ ഉപയോഗിക്കാന്‍",
        "insta_conference_blockdevices": "ഉപയോഗിക്കരുത് ",
        "insta_conference_allowdevices": "ഉപയോഗിക്കാം ",
        "instavc_schedulemeeting_createmeeting": "പുതിയ യോഗം/നിലവിലുള്ളത് തിരുത്തുക",
        "instavc_schedulewebinar_createmeeting": "പുതിയ വെബിനാര്‍/നിലവിലുള്ളത് തിരുത്തുക",
        "instavc_schedulemeeting_meetingname": "യോഗത്തിന് പേര് നല്‍കുക",
        "instavc_schedulemeeting_selectnames_tomeeting": "യോഗത്തിന് മുറി തിരഞ്ഞെടുക്കുക",
        "instavc_schedulemeeting_setduration": "സമയപരിധി നിശ്ചയിക്കുക",
        "instavc_schedulemeeting_15min": "15 മിനുറ്റ്",
        "instavc_schedulemeeting_30min": "30 മിനുറ്റ്",
        "instavc_schedulemeeting_60min": "60 മിനുറ്റ്",
        "instavc_schedulemeeting_setcustom": "ഇഷ്ടാനുസരണം നല്‍കുക",
        "instavc_schedulemeeting_customduration": "നിശ്ചിത സമയം",
        "instavc_schedulemeeting_minute": "മിനുറ്റ്",
        "instavc_schedulemeeting_setconfpassword": "യോഗത്തിന് പാസ്സ്‌വേര്‍ഡ്‌ നല്‍കുക",
        "instavc_schedulemeeting_typepassword": "യോഗത്തിന്റെ  പാസ്സ്‌വേര്‍ഡ്‌ ടൈപ്പ് ചെയ്യുക",
        "instavc_schedulemeeting_conferencecolor": "യോഗത്തിന്റെ പ്രതീകത്തിന് നിറം നല്‍കുക ",
        "instavc_schedulemeeting_invitepeople": "ആളുകളെ യോഗത്തിലേക്ക് ക്ഷണിക്കുക",
        "instavc_schedulemeeting_search": "പരതുക...",
        "instavc_schedulemeeting_SM": "SM",
        "instvc_SM_message" : "താങ്കളുടെ ലൈസന്‍സ് പ്രകാരം യോഗത്തില്‍ പങ്കെടുക്കാവുന്ന ആളുകള്ടെ എന്നതിന്റെ പരിധി കഴിഞ്ഞു,കൂടുതല്‍ ആളുകളെ ക്ഷണിക്കണം എങ്കില്‍",
        "instavc_SM_message_href" : "പ്രീമിയം തിരഞ്ഞെടുക്കുക",
        "instavc_schedulemeeting_notify": "തിരഞ്ഞെടുത്ത ആളുകള്‍ക്ക് യോഗത്തിന്റെ അറിയിപ്പ് നല്‍കുക",
        "instavc_schedulemeeting_addreminder": "ഓര്‍മ്മപ്പെടുത്തല്‍ ചേര്‍ക്കുക",
        "instavc_schedulemeeting_add": "ചേര്‍ക്കുക",
        "instavc_schedulemeeting_addmeetingreminder": "യോഗത്തിന്റെ ഓര്‍മ്മപ്പെടുത്തല്‍ ചേര്‍ക്കുക",
        "instavc_schedulemeeting_adddatetime": "തിയതി തിരഞ്ഞെടുക്കുക  , സമയം തിരഞ്ഞെടുക്കുക  ",
        "instavc_schedulemeeting_apply": "പ്രയോഗിക്കുക",
        "instavc_schedulemeeting_name": "യോഗത്തിന്റെ പേര് നല്‍കുക ",
        "instavc_schedulemeeting_selectroom": "യോഗത്തിന് മുറി തിരഞ്ഞെടുക്കുക  ",
        "instavc_schedulemeeting_selectduration": "യോഗത്തിന് പരിധിനിശ്ചയിക്കുക  ",
        "instavc_recuring_schedule" : "ആവര്‍ത്തിക്കുന്ന യോഗങ്ങള്‍",
        "instavc_schedulemeeting_nothinginreminders": "ആവര്‍ത്തിക്കുന്ന യോഗങ്ങള്‍ഒന്നുമില്ല ",
        "instavc_schedulemeeting_createbutton": "ഉണ്ടാക്കുക",
        "instavc_schedulemeeting_createdsuccessfully": "വിജയകരമായി യോഗം മുന്‍കൂട്ടി നിശ്യയിചിരിക്കുന്നു",
        "instavc_meetinglist_calender": "കലണ്ടര്‍",
        "instavc_meetinglist_selecteddates": "നിങ്ങള്‍ തിരഞ്ഞെടുത്ത തീയതികള്‍",
        "instavc_meetinglist_schedulecreted": "യോഗം മുന്‍കൂട്ടി തീരുമാനിച്ചു",
        "instavc_meetinglist_pastschedule": "കഴിഞ്ഞ യോഗങ്ങള്‍",
        "instavc_meetinglist": "യോഗങ്ങള്‍",
        "instavc_meetinglist_meetingdetils": "യോഗത്തിന്റെ പേര്,സമയം,നിശ്ചയിച്ച ആള്‍",
        "instavc_meetinglist_search": "പരതുക...",
        "instavc_meetinglist_deleterecuring": "ആവര്‍ത്തിക്കുന്ന യോഗങ്ങള്‍ ഒഴിവാക്കുക ",
        "instavc_meetinglist_alow_deleterecuring": "ഇപ്പോള്‍ ഉള്ളത് മാത്രമാണോ,അതോ എല്ലാ ആവര്‍ത്തന യോഗങ്ങളും ഒഴിവാക്കണോ?",
        "instavc_meetinglist_schedule_series": "ബാക്കിയുള്ള ആവര്‍ത്തിക്കുന്ന യോഗങ്ങളില്‍ മാറ്റം ഒന്നുമില്ല",
        "instavc_meetinglist_schedule_seriesdelete": "ഈ ശ്രേണിയിലെ എല്ലാ യോഗങ്ങളും ഒഴിവാക്കുന്നതാണ്:",
        "instavc_meetinglist_allrecuringsch": "ആവര്‍ത്തിക്കുന്ന എല്ലാ യോഗങ്ങളും",
        "instavc_meetinglist_cancelchanges": "എല്ലാ മാറ്റങ്ങളും അസാധുവാക്കുക",
        "instavc_meetinglist_noschedules": "മുന്‍കൂട്ടി നിശ്ചയിച്ചവ ഒന്നുമില്ല",
        "instavc_ml_schedulemessage" : "മുന്‍കൂട്ടി നിശ്ചയിച്ചവ ഒന്നുമില്ല !\n താഴെയുള്ള ബട്ടന്‍ ഉപയോഗിച്ച് പുതിയത് ഉണ്ടാക്കു",
        "instavc_create_schedule" : "മുന്‍കൂട്ടി നിശ്ചയിക്കു",
        "instavc_meetinglist_currentschedule": "ഇപ്പോള്‍ നിശ്ചയിച്ചത്",
        "instavc_meetinglist_join": "പങ്കുചേരുക",
        "instavc_meetinglist_buynow": "ഇപ്പോള്‍ വാങ്ങുക",
        "instavc_dashboard_chooseplan": "ഒരു പ്ലാന്‍ തിരഞ്ഞെടുക്കു",
        "instavc_package_smbplan": "SMB പ്ലാനുകള്‍ ",
        "instavc_package_smbplan_host": "(ആതിഥേയാധിഷ്ടിത ലൈസന്‍സ്)",
        "instavc_package_smbplandescription": "ഒരു യോഗത്തില്‍ ഒരാള്‍ക്കേ ആതിഥേയന്‍ ആകാനാകു,ലൈസന്‍സ് ഉടമയ്ക്ക് യോഗത്തിന്റെ ആതിഥേയനെ മുന്‍കൂട്ടി നിശ്ചയിക്കാവുന്നതാണ്,യോഗത്തിനിടയ്ക് ആഥിധെയത്വം മാറ്റാനാകില്ല,ആതിഥേയന്‍ പങ്കെടുക്കാതെ മറ്റാര്‍ക്കും യോഗത്തില്‍ പങ്കെടുക്കാനകില്ല.",
        "instavc_package_basic": "അടിസ്ഥാന പ്ലാന്‍",
        "instavc_package_hostuser_smb": "1 ആതിഥേയനും ",
        "instavc_package_hostuser_smb_users": "അംഗങ്ങളും",
        "instavc_package_currency": "$",
        "instavc_package_permonth": "ഒരു മാസത്തേക്ക് ",
        "instavc_package_bill_smb": "വാര്‍ഷിക നിരക്ക് ",
        "instavc_package_include_smb": "ഈ പാക്കേജില്‍ ഉള്‍പ്പെടുന്നത്",
        "instavc_package_specifictions_Whiteboard": "എഴുത്തുപകരണം",
        "instavc_package_specifictions_Screen": "സ്ക്രീന്‍ പങ്കുവെക്കല്‍",
        "instavc_package_specifictions_nChat": "ചാറ്റ്",
        "instavc_package_specifictions_Recording": "റെകോര്‍ഡിംഗ്",
        "instavc_package_specifictions_PresenterMode": "യോഗ മേധാവിയാകുക ",
        "instavc_package_buynowbutton": "ഇപ്പോള്‍ വാങ്ങുക",
        "instavc_package_fullfeatures_smb": "മുഴുവന്‍ സവിശേഷതകളും",
        "instavc_package_billinginfo": "രസീത് വിവരങ്ങള്‍",
        "instavc_package_billinginfo_name": "പേര്",
        "instavc_package_billinginfo_orginazation": "സ്ഥാപനത്തിന്റെ പേര്",
        "instavc_package_biilinginfo_gstin": "GSTIN",
        "save": "സേവ്",
        "instavc_package_billinginfo_edit": "തിരുത്തുക",
        "instavc_package_billingdetails": "രസീത് വിശദാംശങ്ങള്‍",
        "instavc_package_billingdetails_firstname": "ആദ്യ പേര്",
        "instavc_package_billingdetails_lastname": "അവസാന പേര്",
        "instavc_package_billingdetails_address": "വിലാസം",
        "instavc_package_billingdetails_zip": "പിന്‍",
        "instavc_package_shippinginfo": "വ്യാപാര വിവരങ്ങള്‍",
        "instavc_package_shippinginfo_edit": "തിരുത്തുക",
        "instavc_package_shippingdetails": "വ്യാപാര വിവിശദാംശങ്ങള്‍",
        "instavc_package_shippingdetails_firstname": "ആദ്യ പേര്",
        "instavc_package_shippingdetails_lastname": "അവസാന പേര്",
        "instavc_package_shippingdetails_address": "വിലാസം",
        "instavc_package_shippingdetails_zip": "പിന്‍",
        "instavc_package_cartsummary": "വാങ്ങുവാന്‍ തിരഞ്ഞെടുത്തവ",
        "instavc_package_hostbase": "ആതിഥേയധിഷ്ടിത ലൈസന്‍സ്( License name)",
        "instavc_package_smbplan_basic": "SMB പ്ലാന്‍ - അടിസ്ഥാന പ്ലാന്‍ ",
        "instavc_package_plandetails": "\n വാര്‍ഷിക നിരക്ക് $ 144.02 \nമാസനിരക്ക്  $ 13 ",
        "instavc_package_term": "കാലാവധി",
        "instavc_package_1year": "1 വര്ഷം",
        "instavc_package_2years": "2  വര്ഷം",
        "instavc_package_3years": "3  വര്ഷം",
        "instavc_package_4years": "4  വര്ഷം",
        "instavc_package_5years": "5 വര്ഷം",
        "instavc_package_subtotal": "ആകെ",
        "instavc_package_taxes": "നികുതിയുംനിരക്കുകളും (GST +18%)",
        "instavc_package_launchoffer": "പ്രാരംഭ കിഴിവ്",
        "instavc_package_promo": "ഇളവ് വിജയകരമായി അഭ്യര്ധിചിരിക്കുന്നു",
        "instavc_package_remove": "ഒഴിവാക്കുക",
        "instavc_package_havepromocode": "കിഴിവിനുള്ള എന്തെങ്കിലും കോഡുകള്‍ കൈവശമുണ്ടോ ?ഉണ്ടെങ്കില്‍ ഉപയോഗിക്കു",
        "instavc_package_entercouponcode": "ദയവായി സാധുവായ ഒരു കോഡ് നല്‍കുക",
        "instavc_package_total": "ആകെ",
        "instavc_package_offerdisclaimer": "കിഴിവിന്റെ നിയമവശങ്ങള്‍ കാണുക",
        "instavc_package_paynow": "ഇപ്പോള്‍ പണമടയ്കുക",
        "instavc_package_payment_instavclicense": "പീപ്പിള്‍ ലിങ്ക് യുനിഫൈഡ് കമ്മ്യുണിക്കെഷന്‍\n ഇന്സ്ടാ വിസിയ്ക്ക് ഉള്ള ലൈസന്‍സ്", 
        "instavc_package_phone": "ഫോണ്‍",
        "instavc_package_entervalid_contactnumber": "ദയവായി ശരിയായ ഫോണ്‍ നമ്പര്‍ നല്‍കുക",
        "instavc_package_email": "ഇമയില്‍",
        "instavc_package_enteremail": "ദയവായി ശരിയായ ഇമെയില്‍ വിലാസം  നല്‍കുക.ഉദാഹരണത്തിന് : you@example.com",
        "instavc_package_paybycard": "കാര്‍ഡ് ഉപയോഗിച്ച് പണമടയ്ക്കുക",
        "instavc_package_securedbyrazorpay": "Razorpay-യാല്‍ സുരക്ഷിതം",
        "instavc_package_card": "കാര്‍ഡ്‌",
        "instavc_package_enterotp": " xxxxxxxxx ലേക്ക് അയച്ച OTP നല്‍കുക\n സേവ് ചെയ്ത കാര്‍ഡ് തിരഞ്ഞെടുക്കുക",
        "instavc_package_resendotp": "OTP വീണ്ടും അയക്കുക",
        "instavc_package_skipsavedcards": "സേവ് ചെയ്ത കാര്‍ഡ്‌  ഒഴിവാക്കുക",
        "instavc_package_enter_otp": "ദയവായി OTP നല്‍കുക",
        "instavc_package_skipthesavedcards": "സേവ് ചെയ്ത കാര്‍ഡ്‌  ഒഴിവാക്കുക",
        "instavc_package_cardnumber": "കാര്‍ഡ് നമ്പര്‍",
        "instavc_package_entervalid_cardnumber": "ദയവായി ശരിയായ കാര്‍ഡ് നമ്പര്‍ നല്‍കുക ",
        "instavc_package_expiry": "കാര്‍ഡിന്റെ കാലാവധി",
        "instavc_package_cardholdername": "കാര്‍ഡ് ഉടമയുടെ പേര്",
        "instavc_package_cvv": "CVV",
        "instavc_package_3digitcode": "കാര്‍ഡിന് പിറകിലുള്ള മൂന്നക്ക നമ്പര്‍",
        "instavc_package_pay": "പണമടയ്ക്കു",
        "instavc_package_remembercard": "കാര്‍ഡ് ഓര്‍മയില്‍ വെക്കുക",
        "instavc_package_medium": "ഇടത്തരം",
        "instavc_package_hostuser_medium": "1 ആതിഥേയനും 50 അംഗങ്ങളും",
        "instavc_package_month_medium": "$15/പ്രതിമാസം",
        "instavc_package_bill_medium": "വാര്‍ഷിക വരിസംഖ്യ + GST 18%",
        "instavc_package_include_medium": "ഈ പാക്കേജില്‍ ഉള്‍പ്പെടുന്നത്",
        "instavc_package_specifictions_medium": "എഴുത്തുപകരണം\nസ്ക്രീന്‍ പങ്കുവെക്കല്‍ \nചാറ്റ്\nറെക്കോര്‍ഡിംഗ്\nയോഗ മേധാവി",
        "instavc_package_buynow_medium": "ഇപ്പോള്‍ വാങ്ങുക",
        "instavc_package_fullfeatures_medium": "മുഴുവന്‍ സവിശേഷതകളും",
        "instavc_package_advanced": "Advanced",
        "instavc_package_hostuser_advanced": "1 ആതിഥേയന്നും 100 അംഗങ്ങളും",
        "instavc_package_month_advanced": "$16/പ്രതിമാസം",
        "instavc_package_bill_advanced": "വാര്‍ഷിക വരിസംഖ്യ + GST 18%",
        "instavc_package_include_advanced": "ഈ പാക്കേജില്‍ ഉള്‍പ്പെടുന്നത്",
        "instavc_package_specifictions_advanced": "എഴുത്തുപകരണം\nസ്ക്രീന്‍ പങ്കുവെക്കല്‍ \nചാറ്റ്\nറെക്കോര്‍ഡിംഗ്\nയോഗ മേധാവി",
        "instavc_package_buynow_advanced": "ഇപ്പോള്‍ വാങ്ങുക",
        "instavc_package_fullfeatures_advanced": "മുഴുവന്‍ സവിശേഷതകളും",
        "instavc_package_virtualmcu": "Virtual MCU License",
        "instavc_package_virtualmcu_room": "(Room Based License)",
        "instavc_package_vmcudetails": "ഒരു യോഗത്തില്‍ ഒരാള്‍ക്കേ ആതിഥേയന്‍ ആകാനാകു,ലൈസന്‍സ് ഉടമയ്ക്ക് യോഗത്തിന്റെ ആതിഥേയനെ മുന്‍കൂട്ടി നിശ്ചയിക്കാവുന്നതാണ്,യോഗത്തിനിടയ്ക് ആഥിധെയത്വം മാറ്റാനാകില്ല,ആതിഥേയന്‍ പങ്കെടുക്കാതെ മറ്റാര്‍ക്കും യോഗത്തില്‍ പങ്കെടുക്കാനകില്ല.",
        "instavc_package_instavc": "ഇന്സ്റാ വിസി",
        "instavc_package_silver": "സില്‍വര്‍",
        "instavc_package_userrooms_silver_users": "അംഗങ്ങള്‍ ",
        "instavc_package_userrooms_silver_rooms": "മുറികള്‍",
        "instavc_package_month_silver": "$281/പ്രതിമാസം",
        "instavc_package_bill_silver": "വാര്‍ഷിക വരിസംഖ്യ + GST 18%",
        "instavc_package_include_silver": "ഈ പാക്കേജില്‍ ഉള്‍പ്പെടുന്നത്",
        "instavc_package_specifictions_silver": "എഴുത്തുപകരണം\nസ്ക്രീന്‍ പങ്കുവെക്കല്‍ \nചാറ്റ്\nറെക്കോര്‍ഡിംഗ്\nയോഗ മേധാവി",
        "instavc_package_buynow_silver": "ഇപ്പോള്‍ വാങ്ങുക",
        "instavc_package_fullfeatures_silver": "മുഴുവന്‍ സവിശേഷതകളും",
        "instavc_package_gold": "ഗോള്‍ഡ്‌",
        "instavc_package_userrooms_gold": "200 അംഗങ്ങളും - 30 മുറികളും",
        "instavc_package_month_gold": "$467/പ്രതിമാസം",
        "instavc_package_bill_gold": "വാര്‍ഷിക വരിസംഖ്യ + GST 18%",
        "instavc_package_include_gold": "ഈ പാക്കേജില്‍ ഉള്‍പ്പെടുന്നത്",
        "instavc_package_specifictions_gold": "എഴുത്തുപകരണം\nസ്ക്രീന്‍ പങ്കുവെക്കല്‍ \nചാറ്റ്\nറെക്കോര്‍ഡിംഗ്\nയോഗ മേധാവി",
        "instavc_package_buynow_gold": "ഇപ്പോള്‍ വാങ്ങുക",
        "instavc_package_fullfeatures_gold": "മുഴുവന്‍ സവിശേഷതകളും",
        "instavc_package_platinum": "പ്ളാറ്റിനം",
        "instavc_package_userrooms_platinum": "500 അംഗങ്ങളും - 50 മുറികളും",
        "instavc_package_month_platinum": "$ 933/പ്രതിമാസം",
        "instavc_package_bill_platinum": "വാര്‍ഷിക വരിസംഖ്യ + GST 18%",
        "instavc_package_include_platinum": "ഈ പാക്കേജില്‍ ഉള്‍പ്പെടുന്നത്",
        "instavc_package_specifictions_platinum": "എഴുത്തുപകരണം\nസ്ക്രീന്‍ പങ്കുവെക്കല്‍ \nചാറ്റ്\nറെക്കോര്‍ഡിംഗ്\nയോഗ മേധാവി",
        "instavc_package_buynow_platinum": "ഇപ്പോള്‍ വാങ്ങുക",
        "instavc_package_fullfeatures_platinum": "മുഴുവന്‍ സവിശേഷതകളും",
        "instavc_package_legacybaselicense": "പരമ്പരാഗത ലൈസന്‍സ്",
        "instavc_package_licensebased_ports": "പോര്‍ട്ട്‌ എണ്ണം അധിഷ്ഠിതമായ ലൈസന്‍സ്",
        "instavc_package_hardwareendpoint": "ഹാര്‍വെയര്‍ ഉപകരണങ്ങള്‍ സജ്ജമാക്കവുന്ന ഇന്സ്റ വിസി",
        "instavc_package_webrtcconf_legacysupport": "പരമ്പരാഗത സംവിധാനങ്ങള്‍ ഉള്‍പ്പെട്ട വെബ്‌ ആര്‍ ടി സി കോണ്ഫെരെന്‍സിംഗ്",
        "instavc_package_sip": "SIP / BFCPപിന്തുണയ്ക്കുന്ന",
        "instavc_package_chh323": "ChH323 / H.239 പിന്തുണയ്ക്കുന്ന",
        "instavc_package_contactus": "ഞങ്ങളുമായി ബന്ധപ്പെടുക",
        "instavc_myprofile_dashboard": "ഡാഷ് ബോര്‍ഡ്",
        "instavc_myprofile_settings": "ക്രമീകരണങ്ങള്‍",
        "instavc_myprofile": "എന്റെ ചെറു വിവരണം",
        "instavc_myprofile_personaldetail": "വ്യക്തിപരമായ വിവരങ്ങള്‍",
        "instavc_myprofile_personaldetails_name": "പേര്",
        "instavc_myprofile_personaldetails_email": "ഇമയില്‍",
        "instavc_myprofile_personaldetails_phoneno": "ഫോണ്‍ നമ്പര്‍",
        "instavc_myprofile_personaldetails_address": "വിലാസം",
        "instavc_myprofile_personaldetails_city": "പട്ടണം",
        "instavc_myprofile_edit": "തിരുത്തുക",
        "instavc_myprofile_personaldetails": "വ്യക്തിപരമായ വിവരങ്ങള്‍",
        "instavc_myprofile_firstname": "ആദ്യ പേര്",
        "instavc_myprofile_lastname": "അവസാന പേര്",
        "instavc_myprofile_email": "ഇമയില്‍",
        "instavc_myprofile_phoneno": "ഫോണ്‍ നമ്പര്‍",
        "instavc_myprofile_professionaldetails": "തൊഴില്‍പരമായ വിവരങ്ങള്‍",
        "instavc_myprofile_position": "ഔധ്യോഗിക സ്ഥാനം",
        "instavc_myprofile_professionaldetails_email": "ഇമയില്‍",
        "instavc_myprofile_professionaldetails_phoneno": "ഫോണ്‍ നമ്പര്‍",
        "instavc_myprofile_professionaldetails_address": "വിലാസം",
        "instavc_myprofile_professionaldetails_branch": "ശാഖ",
        "instavc_myprofile_professionaldetails_edit": "തിരുത്തുക",
        "instavc_myprofile_officialdetails": "ഔധ്യോഗിക വിവരങ്ങള്‍",
        "instavc_myprofile_officialdetails_position": "ഔധ്യോഗിക സ്ഥാനം",
        "instavc_myprofile_officialdetails_email": "ഇമയില്‍",
        "instavc_myprofile_officialdetails_phoneno": "ഫോണ്‍ നമ്പര്‍",
        "instavc_myprofile_officialdetails_branch": "ശാഖ",
        "instavc_myprofile_officialdetails_address": "വിലാസം",
        "instavc_myprofile_contactinfo": "ബന്ധപ്പെടേണ്ട വിലാസം",
        "instavc_myprofile_contactinformation": "ബന്ധപ്പെടേണ്ട വിലാസം",
        "instavc_myprofile_add": "ചേര്‍ക്കുക",
        "instavc_myprofile_contactinformation_address": "വിലാസം",
        "instavc_myprofile_contactinformation_city": "പട്ടണം/പ്രദേശം",
        "instavc_myprofile_contactinformation_zip": "പിന്‍",
        "instavc_myprofile_contactinformation_landmark": "ആടയാളസ്ഥലം",
        "instavc_myprofile_address": "വിലാസം",
        "instavc_myprofile_landmark": "ആടയാളസ്ഥലം",
        "instavc_myprofile_delete": "ജാഗ്രത",
        "instavc_myprofile_deletealert": "ഇതെല്ലം നീക്കം ചെയ്യാണമെന്ന് ഉറപ്പാണോ?",
        "instavc_myprofile_disagree": "അല്ല",
        "instavc_myprofile_agree": "വേണം",
        "instavc_myprofile_websitelinks": "വെബ്‌ സൈറ്റ് വിലാസം ",
        "instavc_myprofile_website_add": "ചേര്‍ക്കുക",
        "instavc_myprofile_website": "വെബ്‌ സൈറ്റ്",
        "instavc_myprofile_websiteexample": "https://www.google.com",
        "instavc_myprofile_sociallinks": "സാമുഹിക മാധ്യമ വിലാസം",
        "instavc_myprofile_sociallinks_add": "ചേര്‍ക്കുക",
        "instavc_myprofile_sociallink": "സാമുഹിക മാധ്യമ വിലാസം",
        "instavc_myprofile_sociallinks_example": "https://www.facebook.com/xxx",
        "instavc_myprofile_basicinfo": "അടിസ്ഥാന വിവരങ്ങള്‍",
        "instavc_myprofile_basicinfo_add": "ചേര്‍ക്കുക",
        "instavc_myprofile_basicinfo_dob": "ജനന തീയതി",
        "instavc_myprofile_basicinfo_gender": "ലിംഗം",
        "instavc_myprofile_basicinformation": "അടിസ്ഥാന വിവരങ്ങള്‍",
        "instavc_myprofile_basicinformation_dob":"ജനന തീയതി",
        "instavc_myprofile_basicinformation_gender": "ലിംഗം",
        "instavc_myprofile_basicinformation_deletealert": "ജാഗ്രത\nഇതെല്ലം നീക്കം ചെയ്യാണമെന്ന് ഉറപ്പാണോ?",
        "instavc_myprofile_basicinformation_disagree": "അല്ല",
        "instavc_myprofile_basicinformation_agree": "വേണം",
        "instavc_myprofile_uploadnewavatar": "പുതിയ ചിത്രം ചേര്‍ക്കുക",
        "instavc_myprofile_cropimage": "ചിത്രം ചെറുതാക്കുക",
        "instavc_myprofile_locale": "ഇവിടുത്തെ",
        "instavc_myprofile_localesettings": "ഇവിടുത്തെ ക്രമീകരണങ്ങള്‍",
        "instavc_myprofile_preferredlanguage": "ഇഷ്ട ഭാഷ:",
        "instavc_myprofile_language": "ഇംഗ്ലീഷ്",
        "instavc_settings": "ക്രമീകരണങ്ങള്‍",
        "instavc_settings_changepassword": "പാസ്സ്‌വേര്‍ഡ്‌ മാറ്റുക",
        "instavc_settings_changepass": "പാസ്സ്‌വേര്‍ഡ്‌ മാറ്റുക",
        "instavc_settings_currentpassword": "നിലവിലെ പാസ്സ്‌വേര്‍ഡ്‌ നല്‍കുക ",
        "instavc_settings_newpassword": "പുതിയ പാസ്സ്‌വേര്‍ഡ്‌ നല്‍കുക ",
        "instavc_settings_enter_currentpassword": "ദയവായി നിലവിലെ പാസ്സ്‌വേര്‍ഡ്‌ നല്‍കുക ",
        "instavc_settings_enter_newpassword": "ദയവായി പുതിയ പാസ്സ്‌വേര്‍ഡ്‌ നല്‍കുക ",
        "instavc_settings_help": "സഹായം",
        "instavc_settings_affiliate": "അംഗത്വ ദാതാവാകുക",
        "instavc_settings_support": "സഹായ കേന്ദ്രം",
        "instavc_settings_newticket": "പുതിയ പരാതി നല്കുക",
        "instavc_settings_ticketstatus": "പരാതിയുടെ സ്ഥിതി പരിശോധിക്കുക",
        "instavc_settings_troubleshoot": "തകരാറ് പരിശോധന",
        "instavc_legacy_dailout": "പുറത്തേക്ക് വിളിക്കുക",
        "instavc_legacy_stats" : "സ്ഥിതി",
        "instavc_legacy_dail_in_detail" :"ഇങ്ങോട്ട് വിളിക്കുവാനുള്ള വിവരങ്ങള്‍",
        "instavc_legacy_h323_endpoint" : "H.323 ഉപകരണം",
        "instavc_legacy_sip_endpoint" : "SIP ഉപകരണം",
        "instavc_admin_users" : "അംഗങ്ങളും",
        "instavc_admin_username" : "അംഗത്വനാമം",
        "instavc_admin_user_accountstatus" : "അക്കൌണ്ട് സ്ഥിതി",
        "instavc_admin_user_confirmed" :"ഉറപ്പാക്കിയിരിക്കുന്നു",
        "instavc_admin_user_unconfirmed" :"ഉറപ്പാക്കിയിട്ടില്ല",
        "instavc_admin_user_resetall" : "എല്ലാം പുനക്രമീകരിക്കുക",
        "instavc_admin_user_sync" : "ഒരുപോലെ ആക്കുക",
        "instavc_admin_user_status" : "സ്ഥിതി",
        "instavc_admin_user_phonenumber" : "ഫോണ്‍ നമ്പര്‍",
        "instavc_admin_user_created" : "നിര്‍മിച്ചു",
        "instavc_admin_user_all" : "എല്ലാം",
        "instavc_admin_groups" : "കൂട്ടം",
        "instavc_admin_addmore" : "ഇനിയും ചേര്‍ക്കുക",
        "instavc_admin_group_peoplelink" : "Queens, GOLIVE, PeopleLink",
        "instavc_admin_select_plan" :"പദ്ധതി തിരഞ്ഞെടുക്കുക",
        "instavc_admin_lastmodified" :"അവസാനം മാറ്റം വരുത്തിയത്",
        "instavc_admin_email_verified" : "ഇമെയില്‍ ശേരിയെന്നുറപ്പാക്കി ",
        "instavc_admin_verified" : "ഉറപ്പാക്കി",
        "instavc_admin_Unverified" : "ഉറപ്പാക്കിയിട്ടില്ല",
        "instavc_admin_licenses" : "ലൈസെന്‍സ്",
        "instavc_admin_addlicense" : "ലൈസെന്‍സ് ചേര്‍ക്കുക ",
        "instavc_admin_defaultroom" : "സ്ഥിരമായ മുറി",
        "instavc_admin_hostlicense" : "ആതിഥേയധിഷ്ടിത ലൈസന്‍സ്",
        "instavc_admin_roomlicense" : "യോഗമുറിയധിഷ്ടിത ലൈസന്‍സ്",
        "instavc_admin_virtualmcu" : "Virtual MCU അധിഷ്ഠിത ലൈസന്‍സ്",
        "instavc_admin_enterprise" : "സ്ഥാപനങ്ങള്‍ക്കുള്ള ലൈസന്‍സ്",
        "instavc_mcuserver": "MCU സെര്‍വര്‍",
        "instavc_mculink" : "https://awsmcu.instavc.com",
        "instavc_admin_expirydate" : "കാലാവധി",
        "instavc_admin_settings" : "അഡ്മിന്‍ ക്രമീകരണങ്ങള്‍",
        "instavc_default_offer" : "റഫറലിനായുള്ള സ്ഥിരസ്ഥിതി ഓഫർ",
        "instavc_enter_coupon_code" : "കുപ്പണ് കോഡ്  നല്‍കുക",
        "instavc_current_partshare" : " പങ്കാളിത്വ വിഹിതംഇതുവരെ",
        "instavc_share_value" : "വിഹിത മൂല്യം",
        "instavc_affliate_share" : "ആളുകളിലേക്ക് എത്തിച്ചകൊണ്ട് ലഭിച്ച വിഹിതം",
        "instavc_enter_share" : "വിഹിത മൂല്യം നല്‍കുക (%)",
        "instavc_roomname" : "സമ്മേളന മുറി",
        "instavc_room_ten" :"പത്ത്",
        "instavc_room_twenty" : "ഇരുപത്",
        "instavc_room_thirty" : "മുപ്പത്",
        "instavc_room_hostbased" : "ആതിഥേയധിഷ്ടിതം",
        "instavc_virtualmcu": "Virtual MCU",
        "instavc_rooms_enterprises" : "സ്ഥാപനങ്ങള്‍ക്കുള്ളത്",
        "instavc_roomid" : "മുറി നമ്പര്‍",
        "instavc_roomtype" : "മുറി സ്വഭാവം",
        "instavc_room_options" : "മുറിയിലെ  സൗകര്യങ്ങള്‍",
        "instavc_commercialtype": "വാണിജ്യടിസ്ഥാനത്ത്തിലുള്ളത്",
        "instavc_free" : "സൗജന്യമായ",
        "instavc_poc" : "POC",
        "instavc_commercial" : "വാണിജ്യം",
        "instavc_createdAt" : "ൽ സൃഷ്ടിച്ചു",
        "instavc_useraccess" : "ഉപഭോക്തൃ പരിധി",
        "instavc_peoplelinkroom" : "Peoplelink Room",
        "instavc_inputlimit" : "അകത്തേക്കുള്ള പരിധി",
        "instavc_participant_limit" : "പങ്കെടുക്കാവുന്നവരുടെ പരിധി",
        "instavc_adduser" : "ഉപഭോക്താവിനെ ചേര്‍ക്കുക",
        "instavc_searchbyname" : "പേര് വെച്ച് പരതുക",
        "instavc_new_plans": "പുതിയ പദ്ധതി/ഉല്പന്നം",
        "instavc_allplan_list" :"എല്ലാ പദ്ധതികളുടെയും പട്ടിക",
        "instavc_sortby" : "തരാം തിരിക്കല്‍",
        "instavc_active" :"സജീവമായവര്‍",
        "instavc_inactive" : "സജീവമല്ലാത്തവര്‍",
        "instavc_create_plan" : "പുതിയ പദ്ധതി ഉണ്ടാക്കുക",
        "instavc_plan_name" : "പദ്ധതിയുടെ പേര്",
        "instavc_enteramount" :"തുക നല്‍കുക(കുറഞ്ഞത് 100 രൂപ)",
        "instavc_select_product" : "ഉല്പന്നം തിരഞ്ഞെടുക്കുക",
        "instavc_host_room" : "1 ആതിഥേയന്/മുറി",
        "instavc_multiroom" : "ഒന്നിലധികം ആതിഥേയന്/മുറി",
        "instavc_legacyrooms" :"പരമ്പരാഗത മുറി",
        "instavc_number_hosts" :"ആതിഥേയരുടെ എണ്ണം",
        "instavc_number_participants" : "പങ്കെടുക്കുന്നവരുടെ എണ്ണം",
        "instavc_number_room" :"മുറികളുടെ എണ്ണം",
        "instavc_subscriptiom_period" :"ഉപയോഗ കാലാവധി",
        "instavc_sub_yearly" : "വര്ഷംതോറും",
        "instavc_sub_monthly" :"മാസംതോറും ",
        "instavc_sub_weekly" :"ആഴ്ച്ചതോറും",
        "instavc_setinterval" : "ഇടവേള നിശ്ചയിക്കുക",
        "instavc_type" :"തരം",
        "instavc_subscription" :"ഉപഭോക്തൃ അംഗത്വം",
        "instavc_onetime" : "ഒറ്റത്തവണ",
        "instavc_description" : "വിവരണം",
        "instavc_planid" : "പദ്ധതി തിരിച്ചറിയല്‍നമ്പര്‍",
        "instavc_orders" : "ഓര്‍ഡറുകള്‍",
        "instavc_orderid" : "ഓര്‍ഡര്‍ നമ്പര്‍",
        "instavc_none" : "ഇവയോന്നുമല്ല",
        "instavc_customer" : "ഉപഭോക്താവ്",
        "instavc_build_anually" : "വാര്‍ഷിക നിര്‍മിതി",
        "instavc_recurring_billing" : "ആവര്‍ത്തിക്കുന്ന ബില്ലിംഗ്",
        "instavc_next_due" : "ഇനി ബില്ലിംഗ്",
        "instavc_invoice_details" :"ബില്ലിന്റെ വിശദാംശങ്ങള്‍",
        "instavc_recurring_payment" : "ആവര്‍ത്തിക്കുന്ന പണമൊടുക്കല്‍",
        "instavc_todays_sale" : "ഇന്നത്തെ വിറ്റുവരവ്",
        "instavc_total_order" : "ആക്കെ ഓര്‍ഡറുകള്‍",
        "instavc_total_rooms" : "ആക്കെ മുറികള്‍",
        "instavc_current_conference" : "നിലവിലെ സമ്മേളനങ്ങള്‍",
        "instavc_current_user" : "നിലവിലെ ഉപയോക്താക്കൾ",
        "instavc_live_broadcast" : "സജീവ പ്രക്ഷേപണങ്ങള്‍ ",
        "instavc_loading_chart" : "പട്ടിക ശേഖരിക്കുന്നു",
        "instavc_view" : "കാണുക",
        "instavc_coupon_info" : "കൂപ്പണ്‍ വിവരം",
        "instavc_new_coupon" : "പുതിയ കൂപ്പണ്‍ ",
        "instavc_create_coupon" : "പുതിയ കൂപ്പണ്‍ ഉണ്ടാക്കുക ",
        "instavc_coupon_code" : "കൂപ്പണ്‍ കോഡ്  ",
        "instavc_company_code" : "കമ്പനി കോഡ്  ",
        "instavc_select_plan" : "പദ്ധതി തിരഞ്ഞെടുക്കുക",
        "instavc_discount" : "കിഴിവ്",
        "instavc_activestatus" : "activeStatus",
        "instavc_usage" : "ഉപഭോഗം",
        "instavc_activeusage" : "activeusage",
        "instavc_singleuse" : "ഒറ്റ ഉപയോഗം",
        "instavc_multiuse" : "ഒന്നിലധികം ഉപയോഗം",
        "instavc_first_page" : "ഒന്നാം പേജ്",
        "instavc_previosu_page" : "മുന്‍ പേജ്",
        "instavc_next_page" : "അടുത്ത പേജ്",
        "instavc_last_page" : "അവസാന പേജ്",
        "instavc_plan" : "പദ്ധതി",
        "instavc_request_withdrawal" : "പിന്‍വലിക്കല്‍ അവിശ്യപ്പെടുക",
        "instavc_withdraw" : "പിന്‍വലിക്കുക",
        "instavc_account" : "അംഗത്വം",
        "instavc_verification" : "പരിശോധനനടപടി",
        "instavc_withdraw_history" : "മുന്പ് പിന്‍വലിച്ചതിന്റെ വിവരങ്ങള്‍",
        "instavc_add_bank" : "പിന്‍വലിക്കാന്‍ ഒരു ബാങ്ക് അക്കൌണ്ട് നല്‍കുക",
        "instavc_bank_description" : "ഞങ്ങള്‍ നിങ്ങളുടെ സാമ്പത്തിക വിവരങ്ങള്‍ അതീവ രഹസ്യമായി സൂക്ഷിച്ച് നിങ്ങളുടെ അക്കൌണ്ട് സുരക്ഷിതമാക്കുന്നതാണ്,ഈ അക്കൌണ്ടില്‍ നിന്നും പിന്‍വലിക്കാന്‍ അവിശ്യപ്പെടുമ്പോള്‍ അതിന്റെ സ്ഥിതി ഞങ്ങള്‍  നിങ്ങള്‍ക്ക്  ഇമെയില്‍ അയക്കുന്നതായിരിക്കും.പിന്‍വലിക്കുന്നത് പരാജയപ്പെടതിരിക്കാനും പണം തിരികെ പോകുന്നതും ഒഴിവാക്കാന്‍, ബാങ്ക് അക്കൌണ്ടിലെ പേരും ഇവിടെ നല്‍കിയിരിക്കുന്ന പേരും ഒന്നാണ് എന്ന് ഉറപ്പാക്കുക.ബാങ്കിന്റെ  വ്യവസ്ഥകളും  അവധി ദിവസങ്ങളുമനുസരിച്ചു  പണമിടപാട് പൂര്‍ത്തിയാകാന്‍ കുറച്ചു സമയമെടുത്തേക്കാം",
        "instavc_bank_accountnumber" : "ബാങ്ക് ആക്കൌണ്ട് നമ്പര്‍",
        "instavc_enter_accountnumber" : "അക്കൗണ്ട്‌ നമ്പര്‍ നല്‍കുക",
        "instavc_account_name" : "അക്കൗണ്ട്‌ ഉടമയുടെ പേര്",
        "instavc_per_bank_account" :"ബാങ്ക് അക്കൗണ്ട്‌ പ്രകാരം",
        "instavc_ifsc" : "IFSC കോഡ്‌:",
        "instavc_bankname" : "ബാങ്കിന്റെ പേര്",
        "instavc_branchname" : "ബാങ്കിന്റെ ശാഖ",
        "instavc_confirmdetails" : "വിവരങ്ങള്‍ ഉറപ്പാക്കുക",
        "instavc_pandetails" : "പാന്‍ കാര്‍ഡിലെ വിവരങ്ങള്‍ നല്‍കുക",
        "instavc_enter_pan" : "പാന്‍ കാര്‍ഡിലെ പേര് നല്‍കുക",
        "instavc_fathername" : "പിതാവിന്റെ പേര്",
        "instavc_enter_fathername" : "പിതാവിന്റെ പേര് നല്‍കുക ",
        "instavc_pannumber" : "പാന്‍ നമ്പര്‍",
        "instavc_panexampe" : "ഉദാ.: BNZPM2501G",
        "instavc_upload_pan_copy" : "പാന്‍ കാര്‍ഡിന്റെ കോപ്പി അപ്പ്‌ലോഡ് ചെയ്യുക",
        "instavc_save_details" : "വിവരങ്ങള്‍ സേവ് ചെയ്യുക",
        "instavc_sales" : "വിറ്റുവരവ്",
        "instavc_transactionid" : "ഇടപാട് നമ്പര്‍",
        "instavc_date" : "തിയതി",
        "instavc_amount" : "തുക",
        "instavc_setaccount_withdraw" : "പിന്വളിക്കുന്നതിനു മുന്പ് അക്കൌണ്ട് വിവരങ്ങള്‍ നല്‍കുക",
        "instavc_first_withdraw" : "നിങ്ങള്‍ ആദ്യമായി പണം പിന് വലിക്കുകയാണെങ്കില്‍, അടുത്ത ടാബില്‍ അക്കൌണ്ട്  വിവരങ്ങള്‍ നല്‍കി പാന്‍ കാര്‍ഡ് ഉപയോഗിച്ച് അതു ഉറപ്പക്കെണ്ടാതുമാണ്.ഒരിക്കല്‍ പരിശോധിച്ച് ഉറപ്പാക്കിയാല്‍ നിങ്ങള്‍ക്ക് അക്കൌണ്ടില്‍ ഉള്ള പണം പിന്‍വലിക്കാന്‍ സാധിക്കുന്നതാണ്",
        "instavc_accountdetails" : "അക്കൌണ്ട് വിവരങ്ങള്‍ നല്‍കുക",
        "instavc_available_balance" :"നിലവിലെ ബാലന്‍സ്",
        "instavc_amount_withdraw" : "പിന്‍വലിക്കേണ്ട തുക",
        "instavc_enteramount_withdraw" :"പിന്‍വലിക്കേണ്ട തുക നല്‍കുക",
        "instavc_select_payment_mode" : "പണമിടപാടിന്റെ ശൈലി തിരഞ്ഞെടുക്കുക",
        "instavc_paypal_payment" : "Paypal പണമിടപാട്",
        "instavc_bank_transaction" : "ബാങ്ക് ഇടപാട്",
        "instavc_paypalfee" : "Paypal ഫീസ്",
        "instavc_totalamount_withdraw" : "പിന്‍വലിക്കേണ്ട ആകെ തുക",
        "instavc_sendrequest" :"അവിശ്യപ്പെടുക",
        "instavc_no_request_withdraw" : "ഇതുവരെ പിന്‍വലിക്കല്‍ അവിശ്യപ്പെട്ടിട്ടില്ല!",
        "instavc_start_process_withdraw" : "താഴെയുള്ള സൗകര്യം ഉപയോഗിച്ചു പിന്‍വലിക്കല്‍ അവിശ്യപ്പെടാം",
        "instavc_request_withdraw_now" : "പിന്‍വലിക്കല്‍ അവിശ്യപ്പെടുക",
        "instavc_current_commission" :"നിലവിലുള്ള കമ്മിഷന്‍",
        "instavc_from" : "മുതല്‍",
        "instavc_to" :"വരെ",
        "instavc_today" : "ഇന്ന്",
        "instavc_last7days" :"കഴിഞ്ഞ 7 ദിവസങ്ങള്‍",
        "instavc_lastmonth" : "ഈ മാസം",
        "instavc_paid_customers" : "പണം നല്‍കി ഉപയോഗിക്കുന്നവര്‍",
        "instavc_share_referral_link" : "നിങ്ങളുടെ സുഹൃത്തുക്കളെ ഇതിലേക്ക് ക്ഷണിക്കാന്‍ ഈ ലിങ്ക് ഉപയോഗിക്കുക",
        "instavc_copylink" :"ലിങ്ക് കോപി ചെയ്യുക",
        "instavc_minimum_payout" :"ഏറ്റവും കുറഞ്ഞ പിന്‍വലിക്കല്‍",
        "instavc_referral_signup" : "നിങ്ങള്‍ വഴി ഉപയോഗം തുടങ്ങിയവര്‍",
        "instavc_referral_clicks" : "നിങ്ങള്‍ നല്‍കിയ ലിങ്കില്‍ ക്ലിക്ക് ചെയ്തവര്‍",
        "instavc_paid" :"പണമടച്ചവര്‍",
        "instavc_unpaid" :"പണമടക്കാത്തവര്‍",
        "instavc_totalearned" :"ഇതുവരെ സമ്പാദിച്ചത്",
        "instavc_myreferral" :"ഞാന്‍ പരിചയപ്പെടുതിയവര്‍",
        "instavc_viewall" :"എല്ലാം കാണുക",
        "instavc_mysales" :"എന്റെ വിറ്റുവരവ്",
        "instavc_sms_mfa" : "SMS MFA സ്ഥിതി",
        "instavc_facebook" :"ഫെസബൂക്",
        "instavc_twitter" :"ട്വിറ്റെര്‍",
        "instavc_linkedin" :"ലിങ്ക്ഡ്ഇന്‍",
        "instavc_referred_users" : "പരിചയപ്പെട്ട ഉപഭോക്താക്കള്‍",
        "instavc_created_date" : "CreatedDate",
        "instavc_myearnings" :"എന്റെ സമ്പാദ്യം",
        "instavc_earnings" : "സമ്പാദ്യം",
        "instavc_commission_share" : "കമ്മിഷന്‍ വിഹിതം",
        "instavc_commission_percentage" :"കമ്മിഷന്‍ ശതമാനം",
        "instavc_coupon_offers":"കൂപ്പണ്‍/കിഴിവ്",
        "instavc_my_referral_account" : "ഞാന്‍ പരിചയപ്പെടുത്താന്‍ ഉപയോഗിക്കുന്ന അക്കൌണ്ട്",
        "instavc_req_withdraw" : "പിന്‍വലിക്കല്‍ അപേക്ഷിക്കുക",
        "instavc_authenticating" : "ഇന്സ്റാ വിസി സെര്‍വറുമായി പരിശോധിക്കുന്നു.....", 
        "instavc_join_access_media" : "പങ്കു ചേര്‍ന്നു.. മാധ്യമ ഉപകരണങ്ങള്‍ ബന്ധിപ്പിക്കുന്നു",
        "instavc_publish_success" : "വിജയകരമായി പ്രസിദ്ധികരിചിരിക്കുന്നു",
        "instavc_recieves" : "Receives",
        "instavc_host_screenshare" : "ഡോക്യുമെന്‍റല്ലങ്കില്‍ സ്ക്രീന്‍ പങ്കുവെയ്ക്കാന്‍ നിങ്ങള്‍ സമ്മേളന മേധാവിയാകുക",
        "instavc_request_sharescreen" : "സ്‌ക്രീൻ / ഡോക്യുമെന്റ് പങ്കിടലിനായി ആക്‌സസ്സ് അഭ്യർത്ഥിക്കുക",
        "instavc_note" : "നോട്ട്",
        "instavc_filesize" : "ഫയല്‍ വലിപ്പം 10 MB താഴെയായിരിക്കണം",
        "instavc_become_host" : "സമ്മേളന മേധാവിയാകാന്‍ വിഡിയോയിലുള്ള മെനുവില്‍ ക്ലിക്ക് ചെയ്യുക",
        "instavc_clicklink" : "സമ്മേളന മേധാവിയാകുക എന്നതില്‍ ക്ലിക്ക് ചെയ്ത് പാസ്സ്‌വേര്‍ഡ്‌ നല്‍കി സമ്മേളനമേധാവിയാകുക എന്നതില്‍ ക്ലിക് ചെയ്യുക",
        "instavc_doc_share" :"ഡോക്യുമെന്റ് പങ്കുവെക്കല്‍",
        "instavc_search_host" :"സമ്മേളന മേധാവിയെ കാത്തിരിക്കുന്നു",
        "instavc_wait_for_host" : "സമ്മേളന മേധാവി യോഗം തുടാങ്ങുന്നതുവരെ ദയവായി കാത്തിരിക്കുക",
        "instavc_open_black_dialog" : "കറുത്ത വാചകങ്ങള്‍ തറക്കുക",
        "instavc_open_white_dialog" : "വെളുത്ത  വാചകങ്ങള്‍ തറക്കുക",
        "instavc_dail_detail" : "യോഗത്തിലേക്ക് വിളിക്കേണ്ട വിവരങ്ങള്‍",
        "instavc_request_access" : "സ്വാധീനം അവിശ്യപ്പെടുക",
        "instavc_inordder_enable" : "പ്രവർത്തനക്ഷമമാക്കുന്നതിന്",
        "instavc_host_permission" :"നിങ്ങൾക്ക്സമ്മേളന നിയന്ത്രകന്റെ അനുവാദം ആവിശ്യമാണ് ",
        "instavc_please_wait" : "ദയവായി കാത്തിരിക്കു",
        "instavc_host_to_permit" : "(Host) അനുവദിക്കുന്നത് വരെ",
        "instavc_alternate_content" : "ഇതര ഉള്ളടക്കം",
        "instavc_meeting_room" : "എന്ന ആളിന്റെ സമ്മേളനമുറി",
        "instavc_host_left_conference" : "സമ്മേളന മേധാവി സമ്മേളനത്തില്‍ നിന്നും പുറത്തുപോയി",
        "instavc_conf_end" : "സമ്മേളനം ഓട്ടോമാറ്റിക്ക് ആയി അവസാനിക്കുന്നതാണ്",
        "instavc_seconds" : "സെക്കന്‍ഡുകള്‍", 
        "instavc_waiting_msg" :"കാത്തിരുന്നതിന് നന്ദി,സമ്മേളന മേധാവി വന്നയുടന്‍ യോഗം ആരംഭിക്കുന്നതാണ്",
        "instavc_let_hostname" : "നിങ്ങള്‍ കാത്തിരിക്കുന്ന വിവരം സമ്മേളന മേധാവിയെ അറിയിക്കണമോ",
        "instavc_exitconf" : "സമ്മേളനത്തില്‍ നിന്ന് പുറത്തുകടക്കുക",
        "instavc_host_left" : "സമ്മേളന മേധാവി പോയി",
        "instavc_end_host_msg" : "സമ്മേളന മേധാവി യോഗമാവസനിപ്പിച്ചു പങ്കെടുത്തതിന് നന്ദി",
        "instavc_close_conf" : "നിങ്ങള്‍ക്ക് ആദ്യ പേജിലേക്ക്  മടങ്ങാം/ആപ്ലിക്കേഷന്‍ ക്ലോസ് ചെയ്യാം",
        "instavc_gohome" : "ആദ്യ പേജിലേക്ക്  മടങ്ങുക",
        "instavc_hand_raised" : "കൈ ഉയര്‍ത്തി",
        "instavc_ask" : "ചോദിക്കു",
        "instavc_protocal" : "കീഴ്വഴക്കം",
        "instavc_bitrate" : "ബിറ്റ് റേറ്റ്",
        "instavc_confirm_details" : "നിങ്ങളുടെ വിവരങ്ങള്‍ ഉറപ്പാക്കു",
        "instavc_enter_info_conf" : "സമ്മേളനത്തിന് മുന്പ് നിങ്ങളുടെ വിവരങ്ങള്‍ നല്‍കുക",
        "instavc_have_acc" : "നിലവില്‍ നിങ്ങള്‍ക്ക് ഒരു അക്കൌണ്ട് ഉണ്ടോ?",
        "instavc_host_access" : "സമ്മേളന മേധാവി സ്വാധീനം",
        "instavc_host_pass" : "സമ്മേളന മേധാവി പാസ്സ്‌വേര്‍ഡ്‌ നല്‍കുക",
        "instavc_becomehost" : "സമ്മേളന മേധാവിയാകുക",
        "instavc_permit_screenshare" : "സ്ക്രീന്‍ പങ്കുവെക്കല്‍ അനുവദിക്കുക",
        "instavc_allow_access" : "എഴുതുപകരണത്തിന്റെ ഉപയോഗം അനുവദിക്കുക",
        "instavc_request_permission" : "എന്നയാള്‍ അധിക അനുവാദങ്ങള്‍ ചോദിക്കുന്നു",
        "instavc_deny" : "നിരാകരിക്കുക",
        // "instavc_planid" : "PlanId",
        "instavc_customeremail" : "ഉപയോക്താവിന്റെ ഇമെയില്‍",
        "instavc_orderlink" : "ഓർഡർ ലിങ്ക്",
        "instavc_customerid" : "ഉപയോക്താവിന്റെ തിരിച്ചറിയല്‍",

        "instavc_selectpackage" : "പദ്ധതി തിരഞ്ഞെടുക്കുക",
        "instavc_host_package" : "നിയന്ത്രകാധിഷ്ഠിത ലൈസന്‍സ്",
        "instavc_virtualbase_mcu" : "Virtual MCU അധിഷ്ഠിതം",
        "instavc_plans_product" : "പദ്ധതിയും/ഉല്പന്നങ്ങളും",
        "instavc_nodata" : "വിവരങ്ങള്‍ ലഭ്യമല്ല",
        "instavc_nodata_msg" : "ഓ.. ഇപ്പോള്‍ നിങ്ങള്ക്ക് കാണുവാന്‍ വിവരങ്ങള്‍ ഒന്നുമില്ല",
        "instavc_basicplan" :"അടിസ്ഥാന പദ്ധതി",
        "instavc_host_participants" : "1 നിയന്ത്രകനും 30 ആളുകളും",
        "instavc_link" : "Link",
        "instavc_cancel_order" : "ആവിശ്യം ഒഴിവാക്കുക",
        "instavc_per" : "per",
        "instavc_user_email" : "ഉപയോക്താവിന്റെ ഇമെയില്‍",
        "instavc_owner" : "ഉടമസ്ഥന്‍",
        "instavc_user" : "ഉപയോക്താവ്",
        "instavc_default_users" : "പൊതുവായ കിഴിവ്",
        "instavc_system_settings" : "സിസ്റെം ക്രമീകരണങ്ങള്‍",
        "instavc_expires" : "കാലാവധി",
        "instavc_start_VC" : "സമ്മേളനം തുടങ്ങുക",
        "instavc_start_AC" : "ശബ്ദ സമ്മേളനം തുടങ്ങുക",
        "instavc_date_error" : "സമ്മേളനം തുടങ്ങുന്ന സമയം ഇപ്പോഴത്തെ സമയം കഴിഞ്ഞുള്ള സമയമായിരിക്കണം",
        "instavc_browser_update" : "ബ്രൌസര്‍ അപ്പ്‌ഡേറ്റ് ആവിശ്യമാണ്",
        "instavc_browser_note" : "താങ്കള്‍ ഒരു പഴയ പതിപ്പ് BROWSERNAME  ആണ് ഉപയോഗിക്കുന്നതെന്ന്‍ മനസിലായി,അത് പ്രായോഗികമല്ല",
        "instavc_browser_msg" : "Instavc.com ന് ഏറ്റവും പുതിയപതിപ്പ്  BROWSERNAME അല്ലങ്കില്‍  ഏതങ്കിലും പുതിയ ബ്രൌസര്‍ ആവിശ്യമാണ്.",
        "instavc_browser_upgrade" :"ഏറ്റവും മികച്ച പ്രവര്‍ത്തനത്തിന് ദയവായി താഴെയുള്ളവയില്‍ ഏതെങ്കിലും ബ്രൌസര്‍ തിരഞ്ഞെടുക്കുക", 
        "instavc_googlechrome" : "ഗൂഗിള്‍ ക്രോം",
        "instavc_gc_version74" : "പതിപ്പ് 74",
        "instavc_mfirefox" : "മോസില ഫയര്‍ഫോക്സ്",
        "instavc_mff_version" : "പതിപ്പ് 66",
        "instavc_opera" : "ഒപെര",
        "instavc_opera_version" : "പതിപ്പ് 60.0നു മുകളിലുള്ളവ",
        "instavc_microsoftedge" : "മൈക്രോസോഫ്റ്റ് എഡ്ജ്",
        "instavc_ms_version" :" 44 നു മുകളിലുള്ള പതിപ്പ്",
        "instavc_safari" : "അപ്പിള്‍ സഫാരി",
        "instavc_safari_version" : "12.1 /അതിനു മുകളില്‍",
        "instavc_contact_msg" : "അപ്പ്‌ഗ്രേഡ് ചെയ്തിട്ടും പ്രശ്നങ്ങള്‍ ഉണ്ടോ? ഞങ്ങളുമായി ബന്ധപ്പെടുക",
        "instavc_camera_busy" : "കാമറ തിരക്കിലാണ്!",
        "instavc_close_camera" : "മറ്റെല്ലാ അപ്ലിക്കേഷനുകളും നിര്തിവച്ചതിനു ശേഷം ശ്രമിക്കുക",
        "instavc_resolution" :"മിഴിവ് പിന്തുണയ്ക്കുന്നില്ല",
        "instavc_camera_notsupport" : "നിങ്ങളുടെ കാമറ ഈ മിഴിവ് പിന്തുണയ്ക്കുന്നില്ല",
        "instavc_camera_reconfig" : "കുറഞ്ഞ മിഴിവിലെക്ക് മാറ്റിയ ശേഷം ശ്രമിക്കുക",
        "instavc_change_resolution" :"മിഴിവ് മാറ്റുക",
        "instavc_confirmation" : "ഉറപ്പാക്കുക",
        "instavc_yes" : "അതെ",
        "instavc_delete_room" : "ഈ സമ്മേളന മുറി ഒഴിവാക്കണമെന്ന് ഉറപ്പാണോ?",
        "instavc_delete_license" : "ലൈസന്‍സ്  ഒഴിവാക്കണമെന്ന് ഉറപ്പാണോ?",
        "instavc_help" :"നിങ്ങളെ സഹായിക്കാന്‍ ഞങ്ങളുണ്ടിവിടെ",
        "instavc_browse_questions" : "ഏറ്റവും കൂടുതല്‍ ചോദിച്ച സംശയങ്ങള്‍ നോക്ക്കുക",
        "instavc_os" :"ഒപ്പെറേറ്റിംഗ് സിസ്റ്റം",
        "instavc_macos" :"Mac OS X പതിപ്പ്:",
        "instavc_chrome_version" : "ക്രോം പതിപ്പ്:",
        "instavc_private_browsing" : "രഹസ്യ ഉപയോഗം?",
        "instavc_faq_no" : "വേണ്ട",
        "instavc_display_resolution" : "instavc_display_resolution",
        "instavc_aspect_ratio" : "വീക്ഷണാനുപാതം",
        "instavc_sys_hasspeakers" : "സിസ്റത്തില്‍ ഉച്ചഭാഷിണിഉണ്ടോ?",
        "instavc_found_speakers" : "2 :ഉച്ചഭാഷിണികണ്ടെത്തിയിരിക്കുന്നു",
        "instavc_speakers1" : "ഉച്ചഭാഷിണി 1",
        "instavc_speakers2" : "ഉച്ചഭാഷിണി 2",
        "instavc_sys_have_microphone" : "സിസ്റത്തില്‍ മൈക്ക് ഉണ്ടോ?",
        "instavc_found_microphone" : "2 :മൈക്ക് കണ്ടെത്തിയിരിക്കുന്നു",
        "instavc_microphone1" : "മൈക്ക് 1 ",
        "instavc_microphone2" : "മൈക്ക് 2 ",
        "instavc_has_webcam" : "സിസ്റത്തില്‍ വെബ്‌ കാമറയുണ്ടോ?",
        "instavc_found_speakers1" : "1  :ഉച്ചഭാഷിണികണ്ടെത്തിയിരിക്കുന്നു",
        "instavc_camera1" : "കാമറ 1",
        "instavc_webcam_permission" : "സിസ്റത്തില്‍ വെബ്‌ക്യാം അനുമതിയുണ്ടോ?",
        "instavc_microphone_permission" : "സിസ്റത്തില്‍ മൈക്ക് അനുമതിയുണ്ടോ?",
        "instavc_browser_allows" : "getUserMedia ബ്രൌസര്‍ അനുമതിയുണ്ടോ?",
        "instavc_change_output_device" : "ഉച്ചഭാഷിണി മാറ്റാന്‍ സാധിക്കുന്നുണ്ടോ?", 
        "instavc_change_camera_resolutions" : "പുതിയ getUserMedia അഭ്യര്‍ഥന  നടത്താതെ ക്യാമറ മിഴിവ് മാറ്റുവാന്‍  സാധിക്കുന്നുണ്ടോ?", 
        "instavc_browser_support_webrtc" : "ബ്രൌസര്‍ WebRTCപിന്തുണയ്ക്കുമോ ( ഒന്നുകില്‍ 1.0 യോ അല്ലങ്കില്‍  1.1)",
        "instavc_browser_support_ortc" : "ബ്രൌസര്‍ ORTCപിന്തുണയ്ക്കുമോ ( ഒന്നുകില്‍ 1.0 യോ അല്ലങ്കില്‍  1.1)",
        "instavc_initial_setup" : "പ്രാധമിക ക്രമീകരണങ്ങള്‍ ചെയ്തു തീരും വരെ കാത്തിരിക്കുക!",
        "instavc_close_browser" : "ബ്രൌസര്‍ ക്ലോസ് ചെയ്യുകയോ ബാക്ക് ബട്ടന്‍ അമര്‍ത്തുകയോ ചെയ്യരുത്",
        "instavc_whoops" : "ഓഹ്.....!",
        "instavc_nonet_msg" : "ഇന്റര്‍നെറ്റ്‌ ലഭ്യമല്ല,പരിശോധിച്ച ശേഷം വീണ്ടും ശ്രമിക്കുക",
        "instavc_nosearch" : "തിരയല്‍ ഫലം ഒന്നുമില്ല",
        "instavc_noserchmsg" : "നിങ്ങള്‍ തിരഞ്ഞതിന്റെ ചേര്‍ന്ന ഫലങ്ങള്‍ ഒന്നുമില്ല",
        "instavc_nosearch_filter" : "തിരെയേണ്ട കാര്യം ക്രിയ്തമായി നല്‍കിയോ ഫില്‍ടര്‍ മാറ്റിയോ തിരയുക",
        "instavc_gotohoem_page" : "ഹോം പേജിലേക്ക് പോകുക",
        "instavc_pageloading" : "പേജ് വന്നുകൊണ്ടിരിക്കുന്നു.... ദയവായി കാത്തിരിക്കുക!",
        "instavc_process" : "നടപടിക്രമത്തിലാണ്......", 
        "instavc_session_timeout" : "ഈ ഭാഗത്തിന്റെ സമയം കഴിഞ്ഞു",
        "instavc_session_inactivity" : "നിങ്ങള്‍ സജീവമല്ലാതിരുന്നതിനാല്‍  ഭാഗത്തിന്റെ സമയം കഴിഞ്ഞു",
        "instavc_session_msg" : "അകത്തു കടക്കുന്നത്  തുടരാൻ തിരഞ്ഞെടുക്കുക",
        "instavc_session_reconnect" : "വീണ്ടും ബന്ധിപ്പ്ക്കുക",
        "instavc_site_notreached" : "ഈ സൈറ്റിലെത്താന്‍ കഴിയില്ല!",
        "instavc_site_webaddress" : "നിങ്ങൾക്ക് ശരിയായ വെബ് വിലാസം ലഭിച്ചുവെന്ന് ഉറപ്പാക്കുക",
        "instavc_check_internet" : "നിങ്ങളുടെ നെറ്റ്‌വർക്ക് പരിശോധിക്കുക",
        "instavc_search_url" : "മറ്റ് ബ്രൌസറിൽ‌ URL നായി തിരയുക",
        "instavc_goto" : "ലേക്ക് പോകുക",
        "instavc_request_for_access" : "അനുമതി അവിശ്യപ്പെടുക",
        "instavc_start_collabrate" : "എഴുതുപകരണത്തില്‍  എഴുതാന്‍  ആരംഭിക്കുക",
        "instavc_large_conference_mode" : "ഇതൊരു വലിയ സമ്മേളനമാണ്,ആതിഥേയന്‍ അനുവണ്ടിക്കാതെ നിങ്ങള്‍ക്ക് മറ്റുള്ളവരെ കാണുവാനോ സംസരിക്കവുനോ സാധിക്കില്ല ",
        "instavc_allow_participants" : "ഇതൊരു വലിയ സമ്മേളനമാണ്,മറ്റുള്ളവര്‍ക്ക് സംസാരിക്കാന്‍ നിങ്ങള്‍ അനുമതി നല്‍കണം",
        "instavc_buy_premium" : "ഈ സവിശേഷതകള്‍ ലഭ്യമാകാന്‍ പ്രീമിയം വാങ്ങുക",
        "instavc_alert_tryagain" : "എന്തോ പിഴവ് സംഭവിച്ചു,ദയവായി വീണ്ടും ശ്രമിക്കുക",
        "instavc_enter_name" : "നിങ്ങളുടെ പേര് നല്‍കുക",
        "instavc_confirm" : "ഉറപ്പാക്കുക",
        "instavc_ok" : "ശരി",
        "instavc_askquestion" : "ഒരു ചോദ്യം ഉന്നയിക്കു.....",
        "instavc_recieve" : "സ്വീകരിക്കുക",
        "instavc_delay" : "താമസം(Ms)",
        "instavc_audio_opus" : "Audio/opus",
        "instavc_video_vp8" : "ചിത്രം/vp8",
        "instavc_browser_not_support_incall" : "കോളിലായിരിക്കുമ്പോള്‍ മിഴിവ് മാറ്റുന്നത് ഇങ്ങളുടെ ബ്രൌസര്‍ പിന്തുണയ്ക്കുന്നില്ല",
        "instavc_failed_toPublish" : "വീഡിയോ പ്രസിദ്ധീകരിക്കുന്നതിൽ പരാജയപ്പെട്ടു",
        "instavc_publish_fail" : "പ്രസിദ്ധീകരിക്കുന്നതിൽ പരാജയപ്പെട്ടു",
        "instavc_video_cant_publish" : "അംഗങ്ങളുടെ എണ്ണം കൂടിയതിനാല്‍ വീഡിയോ പ്രസിദ്ധീകരിക്കുന്നതിൽ പരാജയപ്പെട്ടു",
        "instavc_failed_joinConference" : "യോഗത്തില്‍ ചേരുന്നത് പരാജയപ്പെട്ടു",
        "instavc_mute_audio" : "വിദൂര ഓഡിയോ നിശബ്ദമാക്കിയിട്ടുണ്ടെങ്കിൽ നിങ്ങൾക്ക് നിശബ്ദമാക്കാനോ ശബ്ദമാക്കാനോ കഴിയില്ല",
        "instavc_remote_audio_muted" : "വിദുര ഓഡിയോ നിശബ്ദമാണ്",
        "instavc_enter_conferenceid" : "നിങ്ങളുടെ യോഗത്തിന്റെ തിരിച്ചറിയല്‍ നല്‍കുക",
        "instavc_share_screen" : "സ്ക്രീന്‍ പങ്കുവെക്കുക",
        "instavc_sharing_your_screen" : "സ്ക്രീന്‍ പങ്കുവെയ്ക്കുന്നു....!",
        "instavc_stop" : "നിര്‍ത്തുക",
        "instavc_back" : "പിറകോട്ട്",
        "instavc_choose_plan" : "താഴെയുല്ലതില്‍നിന്നു നിങ്ങള്‍ക്ക് അനുയോജ്യമായ പദ്ധതി തിരഞ്ഞെടുക്കുക",
        "instavc_have_promotionalcode" : "പ്രോത്സാഹന കോഡ്/പങ്കാളികള്‍ക്കുള്ള കോഡ് ഉണ്ടോ?",
        "instavc_contact_pricing" : "വിലവിവരങ്ങള്‍ക്ക് ഞങ്ങളെ സമീപിക്കുക",
        "instavc_payment_failed" : "പണമിടപാട് പരാജയപ്പെട്ടു",
        "instavc_payment_error" : "നിർഭാഗ്യവശാൽ നിങ്ങളുടെ പണമിടപാട് കൈകാര്യം ചെയ്യുമ്പോൾ ഒരു പിശക് സംഭവിച്ചു.",
        "instavc_contact_support" : "എന്തെങ്കിലും സഹായം ആവശ്യമുണ്ടെങ്കിൽ ദയവായി ഞങ്ങളുടെ പിന്തുണാ സംഘത്തെ  ബന്ധപ്പെടുക.",
        "instavc_tryagain" : "വീണ്ടും ശ്രമിക്കുക", 
        "instavc_payment_success" : "പണമിടപാട് വിജയകരമായി",
        "instavc_thanks_for_payment" : "പണമടച്ചതിന് നന്ദി",
        "instavc_payment_processed" : "നിങ്ങളുടെ പണമിടപാട് വിജയകരമായിരിക്കുന്നു.ഒരു പണമിടപാട് രസീത്",
        "instavc_mail_sent" : "നിങ്ങളുടെ രജിസ്റ്റർ ചെയ്ത ഇമെയിൽ വിലാസത്തിലേക്ക് ഉടൻ അയയ്‌ക്കും",
        "instavc_goto_homepage" : "മുഖ്യ പേജിലേക്ക് മടങ്ങി പോകുക",
        "instavc_want_toend_call" : "കോള്‍ അവസാനിപ്പിക്കണോ?",
        "instavc_exit_window" : "ഈ വിൻഡോ അടയ്‌ക്കുമ്പോള്‍  നിങ്ങള്‍ കോളിൽ നിന്ന് പുറത്തുകടക്കും. ഈ കോൾ അവസാനിപ്പിക്കണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ? ",
        "instavc_create_room" : "സമ്മേളന മുറി ഉണ്ടാക്കുക",
        "instavc_userlimit" : "അംഗങ്ങളുടെ പരമാവധി എണ്ണം",
        "instavc_edit_room" : "സമ്മേളന മുറി തിരുത്തുക",
        "instavc_update" : "അപ്ഡേറ്റ്",
        "instavc_manage_license" : "ലൈസൻസുകൾ നിയന്ത്രിക്കുക",
        "instavc_package" : "പാക്കേജ്",
        "instavc_no_of_rooms" : "മുറികളുടെ എണ്ണം",
        "instavc_no_of_users" : "ആകെ ഉപയോക്താക്കളുടെഎണ്ണം",
        "instavc_purchasedOn" : "വാങ്ങിയ തീയതി",
        "instavc_expiredon" : "അസാധുവായ  തീയതി",
        "instavc_or" : "അല്ലങ്കില്‍",
        "instavc_otherhost_email" : "ഏതെങ്കിലും ഉപയോക്താവിനെ ആതിഥേയനാക്കി  മാറ്റണമെങ്കിൽ, അവന്റെ / അവളുടെ ഇമെയിൽ വിലാസം നൽകുക.",
        "instavc_set_host_password" : "ആതിഥേയ പാസ്സ്‌വേര്‍ഡ്‌ നല്‍കുക",
        "instavc_host_password" : "ആതിഥേയ പാസ്സ്‌വേര്‍ഡ്‌",
        "instavc_access_room" : "വേറെയര്‍ക്കൊക്കെ ഈ സമ്മേളനമുറി ഉപയോഗിക്കാം?",
        "instavc_select_user_host" : "ലിസ്റ്റിൽ നിന്ന് ഏതെങ്കിലും ഉപയോക്താവിനെ / അവളെ ഹോസ്റ്റുചെയ്യാൻ തിരഞ്ഞെടുക്കുക.",
        "instavc_prologin" : "InstaVC പ്രോ ലോഗിൻ",
        "instavc_start" : "ആരംഭിക്കുക",
        "instavc_enter_website" : "ദയവായി വെബ്‌സൈറ്റിന്റെ പേര് നൽകുക",
        "instavc_enter_valid_email" : "സാധുവായ ഇമെയിൽ നൽകുക",
        "instavc_enter_valid_phone" : "സാധുവായ ഫോൺ നമ്പർ നൽകുക",
        "instavc_field_required" : "സാധുവായ ഫോൺ നമ്പർ നൽകുക",
        "instavc_invitedby" : "ക്ഷണിച്ചത്",
        "instavc_storage" : "സംഭരണം",
        "affiliate_approve" : "അംഗീകരിച്ചു",
        "affiliate_pending" : "അംഗീകരിചില്ല",
        "affiliate_rejected" : "നിരസിച്ചു",
        "affiliate_user_status" : "ഉപയോക്തൃ സ്ഥിതി",
        "commission_other" : "മറ്റ് കമ്മീഷനുകൾ",
        "add_commission" : "കമ്മീഷനുകൾ ചേര്‍ക്കുക",
        "delete_commission" : "കമ്മീഷനുകൾ ഒഴിവാക്കണമോ?",
        "update_commission" : "കമ്മീഷന്‍അപ്ഡേറ്റ് ചെയ്യുക",
        "name" : "പേര്",
        "offer_value" : "കിഴിവിന്റെ മൂല്യം",
        "add" : "ചേര്‍ക്കുക",
        "update" : "അപ്ഡേറ്റ്",
        "affiliates" : "അംഗമാക്കിയിട്ടുള്ളവര്‍",
        "status" : "സ്ഥിതി",
        "status_change_message" : "സ്ഥിതി മാറ്റുവാന്‍ താല്പര്യപ്പെടുന്നുണ്ടോ?",
        "approve" : "അംഗീകരിക്കുക",
        "reject" :"നിരസിക്കുക",
        "commissions" : "കമ്മീഷനുകൾ",
        "withdraw_data" : "പിന്‍വലിച്ച വിവിരങ്ങള്‍",
        "withdraw_amount" : "പിന്‍വലിച്ച തുക",
        "payment_method" : "പണമിടപാട് ശൈലി",
        "payment_fee" : "പണമിടപാട് കൂലി",
        "total_amount_withdraw" : "ആകെ പിന്‍വലിക്കേണ്ട തുക",
        "total_earnings" : "ആകെ സമ്പാദ്യം",
        "pending_amount" : "ശേഷിക്കുന്ന തുക",
        "paid_amount" : "അടച്ച തുക",
        "unpaid_amount" : "അടയ്ക്കാത്ത  തുക",
        "requested_withdraw" : "പിന്‍വലിക്കാന്‍ അഭ്യര്ധിച്ച തുക",
        "english" : "ഇംഗ്ലീഷ്",
        "french" : "ഫ്രഞ്ച്",
        "portuguese": "പോർച്ചുഗീസ്",
        "hindi": "ഹിന്ദി",
        "gujarati": "ഗുജറാത്തി",
        "kannada": "കന്നഡ",
        "marathi": "മറാത്തി",
        "tamil": "തമിഴ്",
        "telugu": "തെലുങ്ക്",
        "urdu": "ഉർദു",
        "spanish": "സ്പാനിഷ്",
        "malyalam": "മല്യാലം",
        "italian": "ഇറ്റാലിയൻ",
        "bangla" : "ബംഗ്ല",
        "room" : "മുറി",
        "create" : "സൃഷ്ടിക്കുക",
        "repeat" : "ആവര്‍ത്തിക്കുക",
        "daily" : "ദിവസേന",
        "repeat_every" : "എല്ലാം ആവര്‍ത്തിക്കുക",
        "repeat_on" : "ആവർത്തിക്കുക",
        "repeat_by" : "ആവർത്തിക്കുക",
        "ends" : "അവസാനിക്കും",
        "after" : "ശേഷം",
        "occurences" : "സംഗതികള്‍",
        "on" : "യില്‍",
        "summary" : "സംഗ്രഹം",
        "repeats" : "ആവർത്തിക്കുന്നു",
        "withdraws" : "പിൻവലിക്കുന്നു",
        "every" : "എല്ലാ",
        "days" : "ദിവസങ്ങളിലും",
        "weeks" : "ആഴ്ച്ചകളിലും",
        "months": "മാസവും",
        "onday" : "ദിവസം",
        "years" : "വർഷം",
        "times" : "സമയം",
        "until" : "വരെ",
        "past_meeting_list" : "കഴിഞ്ഞ യോഗങ്ങളുടെ പട്ടിക",
        "organised_by" : "സംഘടിപ്പിക്കുന്നത്",
        "delete" : "ഇല്ലാതാക്കുക",
        "join" : "പങ്കുചേരുക",
        "to" : "ലേക്ക്",
        "enter_password" : "പാസ്സ്‌വേര്‍ഡ്‌ നല്‍കുക",
        "submit" : "സമര്‍പ്പിക്കുക",
        "enter_passwordmessage" : "ദയവായി പാസ്സ്‌വേര്‍ഡ്‌ നല്‍കുക",
        "delete_message" :"യോഗം എന്നന്നേക്കുമായി", 
        "meeting_permanent" : "ഇല്ലാതാക്കണമോ",
        "day" : "ദിവസം",
        "password_message": "പാസ്സ്‌വേര്‍ഡ്‌ തെറ്റാണ്",
        "timezone" : "സമയ മേഖല",
        "by" : "യാല്‍",
        "weekdays" : ["ഞായര്‍", "തിങ്കള്‍", "ചൊവ്വ", "ബുധന്‍", "വ്യാഴം", "വെള്ളി", "ശനി"],
        "allweek" : ["ഞായര്‍", "തിങ്കള്‍", "ചൊവ്വ", "ബുധന്‍", "വ്യാഴം", "വെള്ളി", "ശനി"],
        "day_month" : "മാസത്തിലെ ദിവസം",
        "day_week" : "ആഴ്ചയിലെ ദിവസം",
        "week": "ആഴ്ച",
        "month": "മാസം",
        "year": "വർഷം",
        "enterpassword": "ദയവായി നിങ്ങളുടെ പുതിയ പാസ്‌വേഡ് നൽകി പാസ്‌വേഡ് സ്ഥിരീകരിക്കുക",
        "passwordmessage" : "പാസ്‌വേഡും സ്ഥിരീകരിക്കുന്ന പാസ്‌വേഡും   ഒന്നായിരിക്കണം",
        "passwordupdate" : "പാസ്‌വേഡ് വിജയകരമായി അപ്‌ഡേറ്റുചെയ്‌തു",
        "numeric" : ["ഒന്നാമത്തെ", "രണ്ടാമത്തെ", "മൂന്നാമത്തെ", "നാലാമത്തെ", "അഞ്ചാമത്തെ"],
        "verify_email" : "നിങ്ങളുടെ ഇമെയിൽ പരിശോധിക്കുക",
        "confirm_code" : "സ്ഥിരീകരണ കോഡ്",
        "verify_msg" : "കോഡിനായി നിങ്ങളുടെ മൊബൈൽ പരിശോധിക്കുക.",
        "verify" : "പരിശോധിക്കുക",
        "not_recieved" : "ലഭിച്ചിട്ടില്ല?",
        "resend_otp" : "OTP വീണ്ടും അയക്കുക",
        "start_browsing" : "ബ്രൗസിംഗ് ആരംഭിക്കുക",
        "become_affiliate" : "അംഗത്വ ദാതാവാകുക",
        "affiliate" : "അംഗത്വം നല്‍കുക",
        "verify_mobile" : "നിങ്ങളുടെ മൊബൈൽ പരിശോധിക്കുക",
        "instavc_allow_DocumentAccess" : "ഡോക്യുമെന്റ്-പങ്കിടൽ അനുവദിക്കുക",
        "instavc_allow_Notes" : "കുറിപ്പുകൾ അനുവദിക്കുക",

        //common - CameraResolution	
        "camera_not_access" : "ഇനിപ്പറയുന്ന കാരണങ്ങളിലൊന്ന് കാരണം ക്യാമറ ഉപയോഗിക്കനകുന്നില്ല:",
        "camera_not_access_reason1" : "റെസല്യൂഷൻ ക്രമീകരണങ്ങൾ ഈ ഡിസ്പ്ലേയുമായി പൊരുത്തപ്പെടുന്നില്ലായിരിക്കാം. കുറഞ്ഞ റെസല്യൂഷൻ ഉപയോഗിച്ച് നിങ്ങളുടെ ക്രമീകരണങ്ങൾ വീണ്ടും ക്രമീകരിച്ച് വീണ്ടും ശ്രമിക്കുക.",
        "camera_not_access_reason2" : "നിങ്ങളുടെ ക്യാമറ വ്യത്യസ്ത ആപ്ലിക്കേഷൻ ഉപയോഗിക്കുന്നു, ദയവായി എല്ലാ ആപ്ലിക്കേഷനുകളും അടച്ച് ലിങ്കിൽ ക്ലിക്കുചെയ്ത് വീണ്ടും ശ്രമിക്കുക.",
            
        //Containers	
        //content - components - assignQuestionnaire	
        "asgnQue_Assign_Question" : "ചോദ്യാവലി നൽകുക",
        "asgnQue_assigned_question" : "നിയുക്ത ചോദ്യാവലി",
        //assignQuestList	
        "quslsit_assig_que_list" : "നിയുക്ത ചോദ്യാവലി പട്ടിക",
        "quslsit_question_lists" : "ചോദ്യാവലി പട്ടിക",
        "quslsit_add_new_question" : "പുതിയ ചോദ്യാവലി ചേർക്കുക",
        "quslsit_no_question_assign":"ചോദ്യാവലി ഇതുവരെ നൽകിയിട്ടില്ല!",
        "quslsit_assign_new_ques" : "മുകളിലുള്ള ചോദ്യാവലി ബട്ടൺ ക്ലിക്കു ചെയ്ത്  ഒരു പുതിയ ചോദ്യാവലി നൽകുക",

        //model-assign-Questionnaire	
        "select_questionnaire" : "ചോദ്യാവലി തിരഞ്ഞെടുക്കുക",
        "duration" : "ദൈർഘ്യം",
        "time_limit" : "സമയ പരിധി",
        "show_results" : "ഫലങ്ങൾ കാണിക്കുക",
        "save" : "സേവ്",
        //viewAssigned	
        "content_group" : "ഉള്ളടക്ക ഗ്രൂപ്പ്",
        "list_assigned_ques" : "നിയുക്ത ചോദ്യങ്ങളുടെ പട്ടിക:",
        "mins" : "മിനിറ്റ്",
        "marks" : "മാർക്കുകൾ",
        //AssignedQuestions	
        "asgQues_5_Marks" : "(5മാർക്കുകൾ)",
        "asgQues_answers" : "ഉത്തരങ്ങൾ",
        "asgQues_no_questions" : "ചോദ്യങ്ങളൊന്നുമില്ല!",

        //content - components - contentGrade	
        //GradeSetting	
        "grdSet_GPA_calculation" : "ശതമാനത്തിൽ നിന്നുള്ള ജിപിഎ കണക്കുകൂട്ടൽ",
        "grdSet_GPA_cal_msg" : "ശതമാനം 100% ആണ്, അത് 100 പോയിന്റല്ലാതെ മറ്റൊന്നുമല്ല. അതിനാൽ 25 പോയിന്റുകൾ ജിപി‌എ സിസ്റ്റത്തിലെ 1 പോയിന്റിന് തുല്യമാണ്. ജി‌പി‌എ ഫോർമുലയുടെ ശതമാനം നിങ്ങളുടെ ശതമാനത്തെ 100 കൊണ്ട് ഹരിക്കുക, 4 കൊണ്ട് ഗുണിക്കുക. നിങ്ങളുടെ ശതമാനം 75% ആണെങ്കിൽ നിങ്ങളുടെ ഗ്രേഡ് (75/100) * 4 = 3. ",
        "grdSet_grade_point" : "ഗ്രേഡ് പോയിന്റുകളാണെങ്കിൽ",
        //managegrade	
        "mngGrd_grades" : "ഗ്രേഡുകളും",
        "mngGrd_modify" : "പരിഷ്‌ക്കരിക്കുക",
        "mngGrd_save_grades" : "ഗ്രേഡുകൾ സൂക്ഷിക്കുക",
        "mngGrd_modify_grades" : "ഗ്രേഡുകൾ പരിഷ്‌ക്കരിക്കുക",
        "mngGrd_grade_info" : "ഗ്രേഡ് വിവരം",
        //content - components - ContentParticipants	
        //components-admnAnsView
        "admnAns_answer_list_for_question" : "ഈ ഉള്ളടക്ക ഗ്രൂപ്പിന്റെ എല്ലാ ചോദ്യാവലിയ്ക്കുമായുള്ള ഉത്തരങ്ങളുടെ പട്ടിക ചുവടെയുണ്ട്.",
        //components-participants	
        "prtcpt_certification" : "സർട്ടിഫിക്കേഷനുകൾ:",
        "prtcpt_other_content_group" : "മറ്റ് ഉള്ളടക്ക ഗ്രൂപ്പുകളിൽ",
        "prtcpt_sales" : "വിറ്റുവരവ്",
        "prtcpt_marketing" : "വിപണിയിലിറക്കുക",
        "prtcpt_human_resource" : "മാനവ വിഭവ ശേഷി",
        //manageparticipants	
        "mngPrtcpt_add_participants" : "ആളുകളെ ചേര്‍ക്കുക",
        "mngPrtcpt_participants_list" : "പങ്കെടുക്ക്ന്നവരുടെ പട്ടിക",
        "mngPrtcpt_import_participants" : "പ്രധാന പങ്കാളികള്‍",
        "mngPrtcpt_download_sample" : "മാതൃക ഡൌണ്‍ലോഡ് ചെയ്യുക",
        "mngPrtcpt_no_participants_added" : "പങ്കാളികളെയൊന്നും ഇതുവരെ ചേർത്തിട്ടില്ല!",
        "mngPrtcpt_add_new_participant" : "ഒരു പുതിയ പങ്കാളിയെ ചേർക്കുക, ചേർക്കുന്നതിന് മുകളിൽ പങ്കാളി ചേർക്കുക ബട്ടൺ ക്ലിക്കുചെയ്യുക",
        //modal-add-participants	
        "addPrtcpt_select_participants" : "പങ്കാളികളെ തിരഞ്ഞെടുക്കുക",
        "addPrtcpt_model_add_participants" : "ആളുകളെ ചേര്‍ക്കുക",
        //partipantGradeView	
        "prtcptGrde_participant_grade" : "പങ്കെടുക്കുന്നവരുടെ  ഗ്രേഡ് വിശദാംശങ്ങൾ",
        "prtcptGrde_grade_participants_list" : "പങ്കെടുക്ക്ന്നവരുടെ പട്ടിക",
        //content - components - ContentQuestionnaire	
        //managequestionnaire	
        "mngQuesnr_create_questionnaire" : "ചോദ്യാവലി ഉണ്ടാക്കുക",
        "questionnaire_list" : "ചോദ്യാവലി പട്ടിക",
        //managequestions	
        "mngQues_question_details" : "ചോദ്യാവലി വിവരങ്ങള്‍",
        "mngQues_no_question_created" : "ഇതവരെ ചോദ്യാവലി ഒന്നും തന്നെ ഉണ്ടാക്കിയിട്ടില്ല",
        "mngQues_create_new_question" : "പുതിയ ചോദ്യം ഉണ്ടാക്കുന്നതിനു ചോദ്യം ഉണ്ടാക്കുക എന്ന ബട്ടന്‍ ഉപയോഗിക്കുക ",
        //modal-create-quest-group	
        "mdlGrp_questionnaire_name" : "ചോദ്യാവലിയുടെ പേര്",
        "mdlGrp_update" : "അപ്ഡേറ്റ്",
        "mdlGrp_create" : "ഉണ്ടാക്കുക",
        //modal-create-questions
        "mdlCrtQues_question_type" : "ചോദ്യത്തിന്റെ രീതി",
        "mdlCrtQues_sel_question_type" : "ചോദ്യത്തിന്റെ രീതി തിരഞ്ഞെടുക്കുക",
        "mdlCrtQues_single_sel_multiple_choice" : "ഒന്നിലധികം ഉത്തരങ്ങളില്‍ നിന്ന് ഒന്ന് തിരഞ്ഞെടുക്കാവുന്ന തരം",
        "mdlCrtQues_multiselect_multiple_choice" : "ഒന്നിലധികം ഉത്തരങ്ങളില്‍ നിന്ന് പലത്തിരഞ്ഞെടുക്കാവുന്ന തരം",
        "mdlCrtQues_ture_false" : "ശരിയോ/തെറ്റോ",
        "mdlCrtQues_enter_answer_choice" : "ഒന്നിലധികം ഉത്തരങ്ങളില്‍ നിന്ന് ശരിയുത്തരം അടയാളപ്പെടുത്തുക",
        "mdlCrtQues_add_another_choice" : "+ മറ്റൊരു ഉത്തരം ചേർക്കുക",
        //viewQuestionnaire	
        "viewQues_add_questions" : "ചോദ്യങ്ങള്‍ ചേര്‍ക്കുക",
        "viewQues_no_question_created" : "ഇതവരെ ചോദ്യാവലി ഒന്നും തന്നെ ഉണ്ടാക്കിയിട്ടില്ല",
        "viewQues_create_new_question" : "പുതിയ ചോദ്യം ഉണ്ടാക്കുന്നതിനു ചോദ്യം ഉണ്ടാക്കുക എന്ന ബട്ടന്‍ ഉപയോഗിക്കുക ",

        //content - components - createContent	
        //createcontent	
        "crteContent_create_new_content" :"പുതിയ ഉള്ളടക്കം സൃഷ്ടിക്കുക",
        "crteContent_update_content" : "ഉള്ളടക്കം അപ്‌ഡേറ്റുചെയ്യുക",
        "crteContent_save_content" : "ഉള്ളടക്കം സേവ് ചെയ്യുക",
        "crteContent_content_list" : "ഉള്ളടക്ക പട്ടിക",
        "edit_content" : "ഉള്ളടക്കം തിരുത്തുക",
        "crteContent_create_content" : " ഉള്ളടക്കം സൃഷ്ടിക്കുക",
        "crteContent_create_your_content_like" : "ഇമേജുകൾ, വീഡിയോ ഫയലുകൾ, ഓഡിയോ ഫയലുകൾ, ഡോക്യുമെന്റ് എന്നിവ പോലുള്ള മാധ്യമങ്ങൾ ചേർത്ത് നിങ്ങളുടെ ഉള്ളടക്കം സൃഷ്ടിക്കുക.",
        "crteContent_upload_document" : "ഡോക്യുമെന്റ് അപ്‌ലോഡ് ചെയ്യുക",
        "crteContent_upload_media" : "മീഡിയ അപ്‌ലോഡുചെയ്യുക",
        "crteContent_document" : "ഡോക്യുമെന്റ്കള്‍",
        "crteContent_media_files" : "മീഡിയ ഫയലുകള്‍",
        //view-content	
        "viewCon_content_view" : "ഉള്ളടക്ക കാഴ്‌ച",
        "viewCon_content_list" : "ഉള്ളടക്കംപട്ടിക",
        "viewCon_View_content" : "ഉള്ളടക്കം കാണുക",
        "viewCon_enable" :"പ്രവർത്തനക്ഷമമാക്കുക:",
        "viewCon_document" : "ഡോക്യുമെന്റ്കള്‍",
        "viewCon_media_files" : "മീഡിയ ഫയലുകള്‍",

        //content - components - contentRow	
        "conRow_by" : "എഴുതിയത്: ",

        //content - components - managecontent	
        "create_new_content" : "ഉള്ളടക്കം സൃഷ്ടിക്കുക",
        "mngCon_all" : "എല്ലാം",
        "mngCon_content_list" : "ഉള്ളടക്കംപട്ടിക",
        "mngCon_no_content_created" : "ഇതവരെ ഉള്ളടക്കം ഒന്നും തന്നെ ഉണ്ടാക്കിയിട്ടില്ല",
        "mngCon_create_new_content" : "പുതിയ ഉള്ളടക്കം ഉണ്ടാക്കുന്നതിനു ഉള്ളടക്കം ഉണ്ടാക്കുക എന്ന ബട്ടന്‍ ഉപയോഗിക്കുക ",
        //content - components - model-access-right	
        "mdlAccRight_share_access" :"മറ്റ് അധ്യാപകരുമായി സ്വാധീനം പങ്കുവെയ്ക്കുക",
        "mdlAccRight_type_id_add" : "ഇമെയില്‍ വിലാസം നല്‍കി ചേര്‍ക്കുക",
        "mdlAccRight_no_instructor_added" : "ഇതവരെ അധ്യാപകരെ ഒന്നും തന്നെ ചേര്ത്ട്ടില്ല",
        "mdlAccRight_add_people_from_addressbok" : "നിങ്ങളുടെ വിലാസ ശേഖരത്തില്‍  നിന്നോ ഇമെയിൽ വിലാസം ടൈപ്പുചെയ്യുന്നതിലൂടെയോ ആളുകളെ ചേർക്കുക.",
        "mdlAccRight_contacts" : "വിലാസ ശേഖരം",
        "mdlAccRight_add_contacts" : "നിങ്ങളുടെ  വിലാസ ശേഖരത്തില്‍ നിന്ന് ഉള്ളടക്ക ഗ്രൂപ്പിലേക്ക് ആളുകളെ  ചേർക്കുക",
        "mdlAccRight_no_contacts_added" : "ഇതവരെ  ആളുകളെ ഒന്നും തന്നെ ചേര്ത്ട്ടില്ല",
        //content - components - model-confirm-delete	
        "mdlConfDel_confirmation" : "ഉറപ്പാക്കുക",
        "mdlConfDel_are_you_sure_delete" : "ഇത് ഡിലീറ്റ് ചെയ്യാണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ",
        "mdlConfDel_delete" : "ഇല്ലാതാക്കുക",
        //content - components - model-create-content-group	
        "mdlCrtConGrp_alert_enter_group_title" : "ദയവായി ഗ്രൂപ്പ് ശീർഷകം നൽകുക",
        "mdlCrtConGrp_alert_enter_group_description" : "ദയവായി ഗ്രൂപ്പ് വിവരണം നൽകുക",
        "mdlCrtConGrp_group_name" : "ഗ്രൂപ്പ് പേര്",
        //content - components - model-upload-doc	
        "mdlUpldoc_alert_enter_title_doc" : "ദയവായി ഡോക്യുമെന്റിന്റെ ശീർഷകം നൽകുക",
        "mdlUpldoc_upload_document" : "ഡോക്യുമെന്റ് അപ്‌ലോഡ് ചെയ്യുക",
        "mdlUpldoc_document_title" : " ഡോക്യുമെന്‍റ്   ശീർഷകം",
        "mdlUpldoc_upload_doc_from_system" : "നിങ്ങളുടെ സിസ്റ്റത്തിൽ നിന്ന് ഡോക്യുമെന്റ് അപ്‌ലോഡ് ചെയ്യുക, ഇത് .pdf, Excel, വേഡ് ഡോക്യുമെന്റ് പിന്തുണയ്ക്കുന്നു.",
        "mdlUpldoc_upload_doc" : "ഡോക്യുമെന്റ് അപ്‌ലോഡ്",
        "mdlUpldoc_added_doc" : "ഡോക്യുമെന്റ് ചേര്‍ക്കുക ",
        //content - components - model-upload-media	
        "mdlUplMedia_alert_enter_title_media" : "ദയവായി മീഡിയയുടെ ശീർഷകം നൽകുക",
        "mdlUplMedia_alert_enter_title_url" : "ദയവായി ശീർഷകവും URLഉം  നൽകുക",
        "mdlUplMedia_alert_enter_youtube_vimeo_url": "സാധുവായതും URL ഉം നൽകുക (Youtube / Vimeo)",
        "mdlUplMedia_upload_media" : "മീഡിയ അപ്‌ലോഡുചെയ്യുക",
        "mdlUplMedia_upload_url" : "അപ്ലോഡ് URL",
        "mdlUplMedia_Upload_media_system" : "നിങ്ങളുടെ സിസ്റ്റത്തിൽ നിന്ന് മീഡിയ അപ്‌ലോഡ് ചെയ്യുക, ഇത്, .jpg, .png, .jpeg, .mp3, .mp4 എന്നിവ പിന്തുണയ്ക്കുന്നു ",
        "mdlUplMedia_add_media_url" : "ഒരു മീഡിയ അറ്റാച്ചുചെയ്യാൻ മീഡിയ URL വിലാസം ചേർക്കുക",
        "mdlUplMedia_upload_media_url" : "മീഡിയ URL അപ്‌ലോഡുചെയ്യുക",
        "mdlUplMedia_upload_youtube_url" : "കുറിപ്പ്: Youtube URL- കൾ അപ്‌ലോഡുചെയ്യുക",
        "mdlUplMedia_Add_media_file" : "മീഡിയ ഫയലുകൾ ചേർത്തു",
        
        //content - managecontent - index	
        // "mngContent_content" : "ഉള്ളടക്കം",
        "mngContent_participants" : "പങ്കെടുക്കുന്നവര്‍",
        "mngContent_grade_settings" : "ഗ്രേഡ് ക്രമീകരണങ്ങൾ",
        
        //content - index	
        "content_all_content_group" : "എല്ലാമടങ്ങിയ ഗ്രൂപ്പ്",
        "create_new_group" : "ഗ്രൂപ്പ് ഉണ്ടാക്കുക",
        "content_no_group_created" : "ഇതുവരെ ഗ്രൂപ്പ് ഉണ്ടാക്കിയിട്ടില്ല",
        "content_create_new_group" :"പുതിയ ഗ്രൂപ്പ്‌ ഉണ്ടാക്കുന്നതിനു ഗ്രൂപ്പ്‌ ഉണ്ടാക്കുക എന്ന ബട്ടണില്‍ ക്ലിക്ക് ചെയ്യുക",

        //ContentShare - contentList
        "conList_all_group" :"എല്ലാ ഗ്രൂപ്പുകളും",
        "conList_content_list" : "ഉള്ളടക്കംപട്ടിക",
        "conList_no_content_created" : "ഇതവരെ ഉള്ളടക്കം ഒന്നും തന്നെ ഉണ്ടാക്കിയിട്ടില്ല",
        //contentShare - grouplist	
        "grpList_all_content_group" : "എല്ലാമടങ്ങിയ ഗ്രൂപ്പ്",
        "grpList_no_group_created" :"ഇതുവരെ ഗ്രൂപ്പുകള്‍ ഒന്നും ഉണ്ടാക്കിയിട്ടില്ല",


        //ContentShare - questionStepper	
        "queStepper_cant_skip" : "നിങ്ങള്‍ക്ക് ഐശ്ചികമല്ലത്ത കടമ്പകള്‍ ഒന്നും ഒഴിവാക്കാനാകില്ല",
        "queStepper_back" : "പിറകോട്ട്",
        "queStepper_next" :"അടുത്തത്",
        "queStepper_skip" :"പിന്നീട്",
        "queStepper_finish" :"പൂര്‍ത്തിയാക്കുക",
        "queStepper_submit_next" :"സമര്‍പ്പിച്ചു മുന്നോട്ട്",
        //contentshare - showContent	
        "conList_content_list" :"ഉള്ളടക്കപട്ടിക",
        "showCont_document" : "ഡോക്യുമെന്റ്കള്‍",
        "showCont_previous" : "മുന്പിലെത്തത്",
        "showCont_next" :"അടുത്തത്",
        "showCont_have_completed_topic" : "ഞാൻ ഈ ഉള്ളടക്കത്തിലെ വിഷയങ്ങൾ പൂർത്തിയാക്കി",
        //contentShare - showMedia
        "showMedia_page" : "പേജ്",
       "showMedia_of" : "ല്‍",
        "showMedia_previous" : "മുന്‍",

        //contentShare - showquestions	
        "showQues_min_left" : "ശേഷിക്കുന്ന മിനിറ്റ്",
        "showQues_completed_test" : "ഈ ചോദ്യാവലിയിൽ നിങ്ങൾ പരിശോധന പൂർത്തിയാക്കി.",
        "showQues_go_back_content_group" : "ഉള്ളടക്ക ഗ്രൂപ്പിലേക്ക് മടങ്ങുക",
        //contentShare - viewUserScore	
        "viewUserScore_view_score" : "മാര്‍ക്ക് കാണുക",
        "viewUserScore_total_marks" :"ആകെ മാര്‍ക്ക്",
        "viewUserScore_scored_marks" : "ലഭിച്ച മാര്‍ക്ക്",
        "viewUserScore_GPA_score" : "നിങ്ങളുടെ ജിപി‌എ സ്കോർ:",
        "viewUserScore_below_list_of_answer" : "ഈ ഉള്ളടക്ക ഗ്രൂപ്പിന്റെ എല്ലാ ചോദ്യാവലിയ്ക്കുമായുള്ള ഉത്തരങ്ങളുടെ പട്ടിക ചുവടെയുണ്ട്.",
        "viewUserScore_answered" : "ഉത്തരം പറഞ്ഞവ ",
        "viewUserScore_correct_answer" : "ശരിയുത്തരം ",

        //schedule - components	
        //ConfirmCancelInvite	
        "confCancle_confirmation" : "ഉറപ്പാക്കുക",
        "confCancle_are_you_sure" : "നിങ്ങള്‍ക്ക് ഉറപ്പാണോ?",
        "confCancle_delete_user" : "ഉപയോക്താവിനെ കളയുക",
        //confirmscheduledelete	
        "confSche_are_you_sure_delete" : "ഈ മുന്‍‌കൂര്‍ നിശ്ചയിച്ച യോഗം ഒഴിവാക്കണമെന്ന് നിങ്ങള്‍ക്ക് ഉറപ്പാണോ?",
        "confSche_no" : "വേണ്ട",
        //PastMeetingitem	
            // "pastMtnItem_rn" : "RN",
            // "pastMtnItem_room_name" : "Room Name: ",
            // "pastMtnItem_webinar_list" : "Webinar List",
            // "pastMtnItem_list_webinar" : "Below are the list of webinars.",
            // "pastMtnItem_today" : "ഇന്ന്",
            // "pastMtnItem_webinar_title_falls" : "Webinar Title Falls Here",
            // "pastMtnItem_view" : "കാണുക",
            // "pastMtnItem_upcoming" : "Upcoming",

        //SchedulesView	
        //"scheView_edit_content" : "ഉള്ളടക്കം തിരുത്തുക",
        "scheView_scheduled_by" : "മുന്‍കൂട്ടി നിശ്ചയിച്ചതനുസരിച്ചു",
        "scheView_delete" : "ഇല്ലാതാക്കുക",
        "scheView_download_calander" : "കലണ്ടർ ഡൗൺലോഡുചെയ്യുക",
        "scheView_invite_participants" : "പങ്കെടുക്കുന്നവരെ ക്ഷണിക്കുക",
        "scheView_start_conference" : "യോഗം ആരംഭിക്കുക",
        "scheView_event_details" : "പരിപാടിയുടെ വിശദാംശങ്ങള്‍",
        "scheView_room_name" : "സമ്മേളന മുറി",
        "scheView_minute" : "മിനുറ്റ്",
        "scheView_conference_password" :"യോഗത്തിന്റെ പാസ്സ്‌വേര്‍ഡ്‌",
        "scheView_settings" : "ക്രമീകരണങ്ങള്‍",
        "scheView_webinar_event" : "വെബിനാര്‍ ചടങ്ങ്‌",
        "scheView_list_webinar" :"ഈ വെബ്‌നാർ ചടങ്ങ് instavc.com/webinar ൽ ലിസ്റ്റുചെയ്യുക, കൂടാതെ രജിസ്ട്രേഷനുകൾ സ്വീകരിക്കുക.",
        "scheView_guest_login_anonymous" :"അതിഥിക്ക് അജ്ഞാതനായി പ്രവേശിക്കാൻ കഴിയും",
        "scheView_allow_invities" : "ക്ഷണിച്ചവര്‍ മാത്രം പങ്കെടുക്കുക",
        "scheView_enable_webinar_chat" : "വെബിനാറില്‍ചാറ്റ് അനുവദിക്കുക",
        "scheView_enable_hand_rise" : "വെബിനാറില്‍ കൈ ഉയര്‍ത്തല്‍  അനുവദിക്കുക",
        "scheView_list_of_people_invited" : "ക്ഷണിച്ചവരുടെ പട്ടിക താഴെ",
        "scheView_alert_err_to_join" : "ചേരുന്നതിൽ പിശക്",
        //WebinarDetails	
        "webnrDtls_upcoming_webinar_room" : "ഈ റൂമില്‍ നടക്കാനിരിക്കുന്ന  വെബിനാർ താഴെ .",
            // Webinar 1
            // Webinar 2
            // Webinar 3
        "webnrDtls_webinar_details" : "വെബിനാര്‍ വിശദാംശങ്ങള്‍",
        "webnrDtls_webinar_list" : "വെബിനാര്‍ പട്ടിക",
        "webnrDtls_webinar_title" : "വെബിനാര്‍ ശീര്‍ഷകം",
        "webnrDtls_webinar_title_falls" : "വെബിനാര്‍ ശീര്‍ഷകം ഇവിടെ വരുന്നു ",
        "webnrDtls_get_more_joining" : "ഈ വെബിനറിൽ ചേരുന്നതിലൂടെ കൂടുതൽ നേടുക.",
        "webnrDtls_join_webinar" :"വെബിനറിൽ ചേരുക",
        //webinarList	
        "webnrList_webinar_list" :"വെബിനാര്‍ പട്ടിക",
        "webnrList_below_list_of_webinar" : "വെബിനാര്‍ പട്ടിക താഴെ ",
        "webnrList_today" : "ഇന്ന്",
        "webnrList_webinar_title_falls" : "വെബിനാര്‍ ശീര്‍ഷകം ഇവിടെ വരുന്നു ",
        "webnrList_view" : "കാണുക",
        "webnrList_upcoming" : "വരാനിരിക്കുന്നത്",
            
        //schedule - 	
        //AddressBook	
        "addBook_address_book" : "വിലാസ ശേഖരം",
        "addBook_contact" : "വിലാസ ശേഖരം",
        "addBook_add_contact_to_meeting" : "നിങ്ങളുടെ വിലാസ ശേഖരത്തില്‍ നിന്ന് യോഗത്തിലേക്ക് ആളുകളെ ചേർക്കുക",

        //MeetingList	
        "meetingList_past_meetings" : "കഴിഞ്ഞ യോഗങ്ങള്‍",
        "meetingList_past_meetings_list" : "കഴിഞ്ഞ യോഗങ്ങളുടെ പട്ടിക ",
        "meetingList_room_name" : "മുറിയുടെ പേര്",
        "meetingList_view_content" : "ഉള്ളടക്കം കാണുക",
        "meetingList_recording" : "റെകോര്‍ഡിംഗുകള്‍",
        "meetingList_view" : "കാണുക",
        //scheduleInvite	
        "scheInvt_err_invalid_id" :"തെറ്റായ ഇമയില്‍ വിലാസം",
        "scheInvt_invite_participants" : "പങ്കെടുക്കുന്നവരെ ക്ഷണിക്കുക",
        "scheInvt_address_book" : "വിലാസ ശേഖരം",
        "scheInvt_invite_people_to_meeting" : "നിങ്ങളുടെ വിലാസ ശേഖരത്തില്‍ നിന്നോ അല്ലങ്കില്‍ ഇമയില്‍ വിലാസം നല്‍കിയോ  യോഗത്തിലേക്ക് ആളുകളെ ചേർക്കുക",
        "scheInvt_start" : "തുടങ്ങുന്നത്",
        "scheInvt_below_list_of_answer" : "ഈ ഉള്ളടക്ക ഗ്രൂപ്പിന്റെ എല്ലാ ചോദ്യാവലിയ്ക്കുമായുള്ള ഉത്തരങ്ങളുടെ പട്ടിക ചുവടെയുണ്ട്.",
        "scheInvt_host" : "നടത്തുന്നത്:",
        "scheInvt_minute" : "മിനുറ്റ്",
            //Webinar 1
        "scheInvt_add" : "ചേര്‍ക്കുക",
        "scheInvt_no_participants_added" :"ഇതുവരെ പങ്കെടുക്കുന്നവരെ ചേര്‍ത്തിട്ടില്ല ",
        "scheInvt_add_ppl_to_meetings" :"നിങ്ങളുടെ വിലാസ ശേഖരത്തില്‍ നിന്നോ അല്ലങ്കില്‍ പരതിയോ  യോഗത്തിലേക്ക് ആളുകളെ ചേർക്കുക",
        "scheInvt_send_invitation" : "ക്ഷണം അയക്കുക",
        "scheInvt_send_passcodes" :"പ്രത്യേക മെയിലിൽ പാസ്‌കോഡുകൾ അയയ്‌ക്കുക.",

        //Invitation - component - invitationbody	
        "invitBody_invite_only_upto" : "നിങ്ങൾക്ക് 10 ആളുകളെ വരെ മാത്രമേ ക്ഷണിക്കാൻ കഴിയൂ",
        "invitBody_invite_ppl_meeting_email_add" : "ഈ യോഗത്തിലേക്ക് ആളുകളെ ക്ഷണിക്കുക, നിങ്ങളുടെ വിലാസ ശേഖരത്തില്‍  നിന്ന് ആളുകളെ ചേർക്കുക, അല്ലെങ്കിൽ ഇമെയിൽ വിലാസം വഴി ചേർക്കുക.",
        "invitBody_copy" :"കോപ്പി",
        "invitBody_enter_emailid" : "ഇമെയില്‍ വിലാസം നല്‍കുക",
        "invitBody_close" :"അടയ്ക്",
        "invitBody_send_invitation" :"ക്ഷണം അയയ്ക്കുക",

        //documentShare 	
        //index,js	
        "docShare_doc_sharing" :"ഡോക്യുമെന്റ് പങ്കുവെക്കുക",
        "docShare_upload_doc_system_pdf" : "നിങ്ങളുടെ സിസ്റ്റത്തിൽ നിന്ന് ഡോക്യുമെന്റ് അപ്‌ലോഡ് ചെയ്യുക, ഇത് .pdf, Excel, വേഡ് ഡോക്യുമെന്റ് പിന്തുണയ്ക്കുന്നു.",
        "docShare_upload_doc" : "ഡോക്യുമെന്റ് അപ്ലോഡ് ചെയ്യുക ",
        "docShare_no_doc" : "ഡോക്യുമെന്റ് ഒന്നുമില്ല ",
		"docShare_no_doc_in_list" :"പട്ടികയില്‍ ഡോക്യുമെന്റ് ഒന്നും ചേര്‍ത്തിട്ടില്ല  ",
        "docShare_add_doc" :"ഡോക്യുമെന്റ് ചേര്‍ക്കുക ",
        "docShare_alert_title_doc" : "ദയവായി ഡോക്യുമെന്റിന്റെ ശീർഷകം നൽകുക",
        "docShare_alert_something_went_wrong" : "എന്തോ കുഴപ്പം സംഭവിച്ചു, ദയവായി വീണ്ടും ശ്രമിക്കുക",
        //components - PermitDocShare
        "permitDocShr_permit_doc_share_access" : "ഡോക്കുമെന്റ് പങ്കിടൽ സ്വാധീനം  അനുവദിക്കുക",
        "permitDocShr_req_permission_doc_share" : "ഡോക്കുമെന്റ് പങ്കിടൽ സ്വാധീനം  അവിശ്യപ്പെട്ടു ",
        "permitDocShr_deny" :"നിരാകരിക്കുക",
        "permitDocShr_allow" : "അനുവദിക്കുക",
        //components - RequestDocShare	
        "reqDocShr_req_doc_share_access" : "ഡോക്കുമെന്റ് പങ്കിടൽ സ്വാധീനം  അവിശ്യപ്പെടുക ",
        "reqDocShr_access_permission_host" : "ഡോക്കുമെന്റ് പങ്കിടുവാന്‍,സമ്മേളന മേധാവിയില്‍ നിന്ന്  ഡോക്കുമെന്റ് പങ്കിടൽ സ്വാധീനം  അവിശ്യപ്പെടുക ",
        "reqDocShr_plz_wait" : "ദയവായി കാത്തിരിക്കുക",
        "reqDocShr_permission_start_doc_share" : "(Host)ഡോക്കുമെന്റ് പങ്കിടൽ ആരംഭിക്കാനുള്ള അനുമതി. ",
        "reqDocShr_doc_share" : "ഡോക്യുമെന്റ് പങ്കിടുക",
        //components - ShowDocument View	
        "showDocViewback" : "പിറകോട്ട്",
        "showDocViewdoc_title_name" :"ഡോക്യുമെന്റ് തലക്കെട്ട്",
        "showDocViewnext" :"അടുത്തത്",
        "showDocViewprevious" : "മുന്‍",

        //Broadcast - 	
        //boradcastScreenShare	
        "borcstScreen_alert_unsupported" : 'ഡോക്യുമെന്റ് "പിന്തുണയ്‌ക്കാത്ത പ്ലേ ഓർഡർ അഭ്യർത്ഥിച്ചു. പിന്തുണയ്‌ക്കുന്ന ഫോർമാറ്റുകൾ webrtc, hls എന്നിവയാണ്. PlayOrder = webrtc, hls പോലുള്ളവ ഉപയോഗിക്കുക',
        "borcstScreen_alert_no_stream_spec" : "ഗതി നിശ്ചയിച്ചിട്ടില്ല,ദയവായി  ?id={STREAM_ID} URL നോട് ചേര്‍ക്കുക", 
        "borcstScreen_Plz_enable_js" : "ഈ വീഡിയോ കാണുന്നതിന് ദയവായി ജാവാസ്ക്രിപ്റ്റ് പ്രാപ്തമാക്കുക, കൂടാതെ ഒരു HTML5 പിന്താങ്ങുന്ന വെബ് ബ്രൌസറിലേക്ക് ",
        "borcstScreen_support_HTML5" : "അപ്ഗ്രേഡ് ചെയ്യുന്നത് പരിഗണിക്കുക", 
        "borcstScreen_steam_play_when_live" : "വീഡിയോ ഗതി തത്സമയമാകുമ്പോൾ പ്ലേ ചെയ്യും",
        //broadcast	
        "broadcast_alert_unsupported" : 'ഡോക്യുമെന്റ് "പിന്തുണയ്‌ക്കാത്ത പ്ലേ ഓർഡർ അഭ്യർത്ഥിച്ചു. പിന്തുണയ്‌ക്കുന്ന ഫോർമാറ്റുകൾ webrtc, hls എന്നിവയാണ്. PlayOrder = webrtc, hls പോലുള്ളവ ഉപയോഗിക്കുക',
        "broadcast_alert_no_stream_spec" : "ഗതി നിശ്ചയിച്ചിട്ടില്ല,ദയവായി  ?id={STREAM_ID} URL നോട് ചേര്‍ക്കുക ",
        "broadcast_enter_your_name" : "ദയവായി പേര് നല്‍കുക",
        "broadcast_enter_your_email" : "ദയവായി ഇമയില്‍ നല്‍കുക",
        "broadcast_user_details" : "ഉപയോക്താവിന്റെ വിവരങ്ങള്‍",
        "broadcast_submit_next" : "സമര്‍പ്പിക്കുക",
        "broadcast_Plz_enable_js" :"ഈ വീഡിയോ കാണുന്നതിന് ദയവായി ജാവാസ്ക്രിപ്റ്റ് പ്രാപ്തമാക്കുക, കൂടാതെ ഒരു HTML5 പിന്താങ്ങുന്ന വെബ് ബ്രൌസറിലേക്ക് ",
        "broadcast_support_HTML5" : "അപ്ഗ്രേഡ് ചെയ്യുന്നത് പരിഗണിക്കുക",
        "broadcast_steam_play_when_live" : "വീഡിയോ ഗതി തത്സമയമാകുമ്പോൾ പ്ലേ ചെയ്യും",
        //broadcastConference	
        "brdcstConf_whiteboard" : "എഴുത്തുപകരണം",
        "brdcstConf_screen_share" : "സ്ക്രീന്‍ പങ്കുവെക്കല്‍",
        // "brdcstConf_participants" : "പങ്കെടുക്കുന്നവര്‍",
        "brdcstConf_broadcast" : "പ്രക്ഷേപണം ചെയ്യ് ",
        "brdcstConf_right" : "ശരി",
        //broadcast - component	
        //broadcasterParicipants	
        "brdcstPart_broadcast_list" : "പ്രക്ഷേപണം ചെയ്യേണ്ട പട്ടിക ",
        

        //Containers - calender	
        //index	
        "calender_scheduled_calendar" : "കലണ്ടറില്‍ മുന്‍കൂട്ടി നിശ്ചയിക്കു",
        "calender_select_all" : "എല്ലാം തിരഞ്ഞെടുക്കു ",
        "calender_calender" : "കലണ്ടര്‍",
        "calender_daily" : "ദിവസേന",
        "calender_weekly" :"ആഴ്ച്ചതോറും",
        "calender_month" : "മാസം",
        "calender_today" : "ഇന്ന്",

        //Dashboard - componenet	

        //MeetingReminder	
        "meetReminder_scheduled_confirm" : "പട്ടിക ഉറപ്പാക്കുക",
        "meetReminder_meeting_has_been_scheduled" : "താഴെപ്പറയുന്ന യോഗം ഇപ്പോള്‍ നിശ്ച്യിച്ചു",
        "meetReminder_start_meeting" : "ഈ യോഗം തുടങ്ങുക",
        "meetReminder_join_meeting" : "ഈ യോഗത്തില്‍ ചേരുക",
        "meetReminder_quick_start_another_meeting" : "പെട്ടന്ന് വേറൊരു യോഗം തുടങ്ങുക",
        //MyMeetingItem	
        "myMeetItem_start" : "ആരംഭിക്കുക",
        "myMeetItem_for" : "നു വേണ്ടി ",
        "myMeetItem_minute" : "മിനുറ്റ്",
        "myMeetItem_host" : "യോഗം നടത്തുന്നയാള്‍: ",
        //MyMeeting	
        "myMeet_my_meetings" : "എന്റെ യോഗങ്ങള്‍",
        "myMeet_search_my_meetings" : "എന്റെ യോഗങ്ങള്‍തിരയുക ",
        //NoScheduledMeeting	
        "noScheMeet_no_meeting_scheduled" : "ഇതുവരെ യോഗങ്ങള്‍ ഒന്നും തീരുമാനിച്ചിട്ടില്ല",
        "noScheMeet_no_upcoming_meetings" : "ഇതുവരെ വരാനിരിക്കുന്ന യോഗങ്ങള്‍ ഒന്നും തീരുമാനിച്ചിട്ടില്ല.നിങ്ങള്‍ക്ക് വേണമെങ്കില്‍ മുന്‍കൂട്ടി നിശ്ചയിക്കാം അല്ലങ്കില്‍ ഇപ്പോള്‍തന്നെ പെട്ടന്ന് യോഗം തുടങ്ങാം.",
        "noScheMeet_create_schedule" : "മുന്‍കൂട്ടി നിശ്ചയിക്കു",
        "noScheMeet_quick_start" : "പെട്ടന്ന് തുടങ്ങുക",
        "noScheMeet_or" : "അല്ലങ്കില്‍",

        //RoomSettings	
        "roomSet_alert_select_license" : "ദയവായി ലൈസൻസ് തിരഞ്ഞെടുക്കുക",
        "roomSet_manage_licenses" : "ലൈസൻസുകൾ നിയന്ത്രിക്കുക",
        "roomSet_package" : "പാക്കേജ്",
        "roomSet_no_of_rooms" :"മുറികളുടെ എണ്ണം",
        "roomSet_no_of_user" : "ഉപയോക്താക്കളുടെ എണ്ണം",
        "roomSet_purchased_on" : "വാങ്ങിയ തീയതി",
        "roomSet_expired_on" : "അസാധുവായ  തീയതി",
        "roomSet_create_room" : "സമ്മേളന മുറി ഉണ്ടാക്കുക",


        //conference - component
        //AddSipDevice	
        "addSipDev_add_sip_details" : "SIP വിവരങ്ങള്‍ നല്‍കുക",
        "addSipDev_sip_address" :"SIP വിലാസം ",
        "addSipDev_connect" : "ബന്ധപ്പെടുക",
        "addSipDev_enter_sip_address" :"SIP വിവരങ്ങള്‍ നല്‍കുക",
        //ConfNotification		
        "confNotf_notification" : "അറിയിപ്പുകൾ",
        "confNotf_user_name" : "ഉപയോക്തൃ നാമം",
        "confNotf_want_to_join" : "ഈ മീറ്റിംഗ് റൂമിൽ ചേരാൻ ആഗ്രഹിക്കുന്നു.",
        "confNotf_allow" :"അനുവദിക്കുക",
        "confNotf_deny" :"നിരാകരിക്കുക",
        "confNotf_ask_question" : "ഒരു ചോദ്യം ചോദിച്ചു",
        "confNotf_send_msg_lobby" : "സന്ദര്‍ശക മുറിയില്‍ നിന്ന് ഒരു സന്ദേശമയച്ചു",
        //Loddy	
        "lobby_participants_loddy" : "സന്ദര്‍ശക മുറിയിലുള്ളവര്‍",
        "lobby_pramote_all" : "എല്ലാവരെയും യോഗത്തിലേക്ക് കടത്തിവിടുക",
        "lobby_user_name" : "ഉപയോക്തൃ നാമം",
        //RemoveUserDialog	
        "removeUserDlg_manage_user_selecting" : "ഇവയിലേതെങ്കിലും ഉപയോഗിച്ച് ഉപയോക്താവിനെ നിയന്ത്രിക്കുക",
        "removeUserDlg_user_name" : "ഉപയോക്തൃ നാമം",
        "removeUserDlg_block_participant" : "ഉപയോക്താവിനെ തടയുക",
        "removeUserDlg_send_to_lobby" : "സന്ദര്‍ശക മുറിയിലേക്ക് മാറ്റുക",
        "removeUserDlg_remote_participant" : "ഉപയോക്താവിനെ പുറത്താക്കുക",
        "removeUserDlg_close" : "അടക്കുക",
        //participantsList	
        "PartList_participants_in_lobby" : "സന്ദര്‍ശക മുറിയിലുള്ളവര്‍",
        "PartList_alert_something_went_wrong" : "എന്തോ പിഴവ് സംഭവിച്ചു,ദയവായി വീണ്ടും ശ്രമിക്കുക",
        "PartList_in_webinar" :"വെബിനാറില്‍ ഉള്ളവര്‍",
        "PartList_promote_all" : "എല്ലാവരെയും യോഗത്തിലേക്ക് കടത്തിവിടുക",
        //participants2020	Retry
        //WaitingRoom	
        "waitingRoom_waiting_room" : "സന്ദര്‍ശകമുറി",
        "waitingRoom_plz_wait_host_allow" : "സമ്മേളന മേധാവി അനുവധിക്കുംവരെ സന്ദര്‍ശകമുറിയില്‍ തുടരുക",
        "waitingRoom_chat" : "ചാറ്റ്",

        "waitingRoom_mins" : "മിനിറ്റ്",
        "waitingRoom_no_msg_yet" : "ഇതുവരെ സന്ദേശങ്ങള്‍ ഒന്നുമില്ല",
        "waitingRoom_no_msg_from_host" :"സമ്മേളന മേധാവിയുടെ സന്ദേശങ്ങള്‍ ഒന്നുമില്ല",
        //userRemoved	
        "userRemoved_you_removed_by_host" : "സമ്മേളന മേധാവിനിങ്ങളെ പുറത്താക്കിയിരുന്നു", 
        //invitationSlide	
        "invitSlide_invite" : "ക്ഷണിക്കുക",
        "invitSlide_add_email" : "ഇമെയില്‍ ചേര്‍ക്കുക ",
        "invitSlide_add" : "ചേര്‍ക്കുക",
        "invitSlide_broadcast_list" : "പ്രക്ഷേപണ കണ്ണി",
        "invitSlide_invite_people_to_meeting" : "ആളുകളെ യോഗത്തിലേക്ക് ക്ഷണിക്കുക.ഇമെയില്‍ വിലാസം നല്‍കി ആളുകളെ ചേര്‍ത്ത് ക്ഷണനം നല്‍കേണ്ടവരുടെ പട്ടികയില്‍ ചേര്‍ക്കുക",
        "invitSlide_err_invalid_id" : "തെറ്റായ ഇമയില്‍ വിലാസം",
        //participantsChat	
        "particiChat_no_chat_msg" : "ഇതുവരെ ചാറ്റ് സന്ദേശം ഒന്നുമില്ല!",
        "particiChat_start_conversation" : "ഒരു സംഭാഷണം ആരംഭിച്ചു സന്ദേശങ്ങള്‍ ഇവിടെ കാണുക.",

	
        //components - lobbyparticipants	
        "lobbyPartici_no_participants" : "പങ്കെടുക്കുന്നവര്‍ ആരുമില്ല",
        "lobbyPartici_no_participants_lobby" : "സന്ദര്‍ശകമുറിയില്‍ ആരുമില്ല",


        //conference	
        //simulcast	
        "simulcast_alert_decline_access" : "സമ്മേളനമേധാവി അനുവാദം നിഷേധിച്ചു",
        "simulcast_invalid_url" : "തെറ്റായ URL",
        "simulcast_no_host_available" : "ഇതുവരെയാരും സമ്മേളനമേധാവിയയ്യിട്ടില്ല,സമ്മേളനമേധാവിയായ ശേഷം സ്ക്രീന്‍ പങ്കുവെക്കുക",
        //simulcastVAD & // Conference.js	
        "conference_access_to_participants" : "പങ്കെടുക്കുന്നവരിലേക്കുള്ള സ്വധീനം ",
        "conference_screen_share" : "സ്ക്രീന്‍ പങ്കുവെക്കല്‍",
        "conference_chat" : "ചാറ്റ്",
        "conference_lock_all" : "എല്ലാം പൂട്ടുക ",
        "conference_lock_conf" : "യോഗം പൂട്ടുക",
        "conference_become_host" : "സമ്മേളന മേധാവിയാകുക",
        "conference_you_are_host" : "നിങ്ങള്‍ സമ്മേളന മേധാവിയാണ് ",
        "conference_share" : "പങ്കുവെക്കുക",
        "conference_document_share" :"ഡോക്യുമെന്റ് പങ്കുവെക്കല്‍",
        "conference_content_share" : "ഉള്ളടക്കം പങ്കുവെക്കുക",
        "conference_more" : "ഇനിയും...",
        "conference_become_host" : "സമ്മേളന മേധാവിയാകുക",
        "conference_alert_no_host_aval_screen" : "ഇതുവരെയാരും സമ്മേളനമേധാവിയയ്യിട്ടില്ല,സമ്മേളനമേധാവിയായ ശേഷം സ്ക്രീന്‍ പങ്കുവെക്കുക",
        "conference_alert_no_host_aval_doc" : "ഇതുവരെയാരും സമ്മേളനമേധാവിയയ്യിട്ടില്ല,സമ്മേളനമേധാവിയായ ശേഷം ഡോക്യുമെന്റ്‍ പങ്കുവെക്കുക",
        "conference_alert_err_sip_device" : "SIP ഉപകരണവുമായി ബന്ധപ്പെടുന്നതില്‍ പിഴവ് ",
        "conference_alert_host_decline_access" : "സമ്മേളന മേധാവി അനുവാദം നിരാകരിച്ചു",
        "conference_alert_invalid_url" : "തെറ്റായ URL",	
        "conference_unmute_mic_start_talk" : "മൈക് ഓണ്‍ ആക്കി സംസാരിച്ചു തുടങ്ങുക",

        // tools	
        "confTools_new_whiteboard" : "നിങ്ങളുടെ പുതിയ എഴുതുപകരണം ഇതാ",
        "confTools_video_sync" : "നിങ്ങളുടെ വീഡിയോ ഒരുപോലക്കല്‍",
        "confTools_microphone" : "മൈക്ക്",
        "confTools_type_text_here" : "നിങ്ങളുടെ സന്ദേശം കുറിക്കുക...",
        "whiteboard" : "എഴുത്തുപകരണം",

        //meetingRecording	
        "meetRec_video_rec_meeting_name" : "സമ്മേളന പേരുകളുടെ വീഡിയോ റെക്കോർഡിംഗുകൾ",
        "meetRec_page_view" : "പേജ് കാഴ്ച:",
        //meetingRecording- recordinglistview	
        "recListView_title_name_rec" : "റെക്കോർഡിംഗ് ശീർഷകത്തിന്റെ പേര് ",
        "recListView_name_user" : "ഉപയോക്താവിന്റെ പേര്",




        // /////////////////////////////////////////////////////////////////////
        //Src	
        //component - common - BrowserUpgrade	
        "brwUpg_req_fun_not_avail" : "ആവശ്യമായ പ്രവർത്തനം നിങ്ങളുടെ ബ്ര .സറിൽ ലഭ്യമല്ല",
        "brwUpg_meeting_req_fun_sup" : "ഈ മീറ്റിംഗ് സേവനത്തിന് നിങ്ങളുടെ ബ്ര .സർ പിന്തുണയ്‌ക്കാത്ത ഫൺ‌കേഷണാലിറ്റി ആവശ്യമാണ്.",
        "brwUpg_plz_upgrade" : "ദയവായി നവീകരിക്കുക, മറ്റൊരു ബ്ര .സറിലേക്ക് മാറുക , or നിങ്ങളുടെ ക്രമീകരണങ്ങൾ പരിശോധിക്കുക . പിന്തുണയ്‌ക്കുന്ന ബ്രൗസർ :",
        //"    Samsung Internet
        //component - common - header - header.js	
        "header_content" : "ഉള്ളടക്കം (ബീറ്റ)",
        "header_loading" : "ലോഡിംഗ്...",
        
        //containers/admin/plans/components/createPlan.js	
        "crtePln_plz_enter_name" : "പ്ലാനിന്റെ പേര് നൽകുക",
        "crtePln_plz_amount_Plan" : "പ്ലാനിന്റെ തുക നൽകുക",
        "crtePln_plz_enter_des" : "വിവരണം നൽകുക",
        "crtePln_Plz_sel_product" : "ഉൽപ്പന്നം തിരഞ്ഞെടുക്കുക",
        "crtePln_plz_sel_no_host" : "ദയവായി ആതിഥേയരുടെ എണം തിരഞ്ഞെടുക്കുക",
        "crtePln_plz_enter_no_participants" : "ദയവായി  പങ്കെടുക്കുന്നവരുടെ എണം നൽകുക ",
        "crtePln_plz_enter_roomno" : "ദയവായി മുറികളുടെ എണം നൽകുക",
        "crtePln_select_period" : "ദയവായി കാലയളവ് തിരഞ്ഞെടുക്കുക",
        "crtePln_sel_interval" : "ദയവായി ഇടവേള തിരഞ്ഞെടുക്കുക ",
        "crtePln_update_succ" : "വിജയകരമായി അപ്‌ഡേറ്റുചെയ്‌തു",
        "crtePln_err_creating_plan" : "പ്ലാൻ സൃഷ്ടിക്കുന്നതിൽ പിശക് ",

        //containers/broadcast/components/BroadcastHandRaise.js	
        "brdcRaise_alert_question_50_char" : "നിങ്ങളുടെ ചോദ്യത്തിന് കുറഞ്ഞത് 50 അക്ഷരങ്ങള്‍  ഉണ്ടായിരിക്കണം ",
        //containers/conference/components/components	
        "cofCom_no_part_lobby" : "ലോബിയിൽ പങ്കെടുക്കുന്നയാളുകൾ  ആരും ഇല്ല .",
        //containers/conference/components/ConfirmUserDetails.js	
        "confmUser_plz_provide_name_email" : "ദയവായി നിങ്ങളുടെ പേരും ഇമെയിലും നൽകുക",
        "confmUser_notes" : "കുറിപ്പുകൾ ",
        //containers/conference/ConferenceSetup.js 	
        "confSetup_telepresence" : "ടെലിപ്രസൻസ്",
        "confSetup_alert_not_generate_meetingID" : "മീറ്റിംഗ് ഐഡി സൃഷ്ടിക്കാൻ  കഴിഞ്ഞില്ല , കുറച്ച് സമയത്തിന് ശേഷം ശ്രമിക്കുക",
        "confSetup_auth_part_can_join" : "പങ്കെടുക്കുവാൻ  അനുവാദം ഉള്ളവര്‍ക്ക് മാത്രമേ ചേരാനാകൂ  !.",
        "confSetup_invalid_link" : "ലിങ്ക് അസാധുവാണ് ",
        "confSetup_meeting_notyet_started" : "ഈ മീറ്റിംഗ് ഇതുവരെ ആരംഭിച്ചിട്ടില്ല . ദയവായി  പിന്നീട് ചേരുക  !.    നിങ്ങൾ ഒരു  ആതിഥേയനാണെങ്കില്‍  , ദയവായി instaVC- ലേക്ക് ലോഗിൻ ചെയ്ത് മീറ്റിംഗ് ആരംഭിക്കുക",
        "confSetup_meeting_locked_byhost" : "ഈ മീറ്റിംഗ് ആതിഥേയൻ പൂട്ടി ",
        "confSetup_meeting_notyet_started_wait_host" : "ഈ മീറ്റിംഗ് ഇതുവരെ ആരംഭിച്ചിട്ടില്ല . ദയവായി  ആതിഥേയൻ മീറ്റിംഗ് ആരംഭിക്കുന്നവരെ കാത്തിരിക്കുക   !",
        //containers/conference/components/NewChat.js",
        "newChat_panel_chat" : "പാനൽ സല്ലാപം ",
        //containers/conference/libs/InstaVC.js 	
        "instaVc_server_busy" : "സെർവർ തിരക്കിലാണെന്ന് തോന്നുന്നു! മറ്റൊരു സെർവർ കണക്റ്റുചെയ്യാൻ ശ്രമിക്കുന്നു ...",
        //"    load-message",
        "instaVc_server_busy_reload_tojoin" : "സെർവർ തിരക്കിലാണെന്ന് തോന്നുന്നു ! കോൺഫറൻസിലേക്ക് ചേരാൻ വീണ്ടും ലോഡുചെയുക",
        "instaVc_broadcast_scr_failed" : "സ്‌ക്രീൻ പ്രക്ഷേപണം പരാജയപ്പെട്ടു",
        "instaVc_broadcast_failed" : "പ്രക്ഷേപണം പരാജയപ്പെട്ടു",
        "instaVc_waiting_for_host" : "വീഡിയോയ്‌ക്കായി കാത്തിരിക്കുന്നു ",
        //containers/content/components/contentQuestionnaire/modal-create-quest-group.js 	
        "modlCreate_plz_enter_title" : "ദയവായി ശീർഷകം നൽകുക",
        "modlCreate_poll" : "വോട്ടെടുപ്പ് ",
        //containers/content/components/contentQuestionnaire/modal-create-poll-question.js	
        "mdlCretPoll_plz_enter_quest" : "ദയവായി ചോദ്യം നൽകുക ",
        "mdlCretPoll_option_should_not_same" : "ഓപ്ഷനുകൾ ഒന്നായിരിക്കരുത് ",
        "mdlCretPoll_plz_enter_option" : "ദയവായി ഓപ്ഷനുകൾ നൽകുക",
        "mdlCretPoll_plz_select_type_option" : "ദയവായി ഓപ്ഷൻ തിരഞ്ഞെടുക്കുക ",
        "alert_server_error" : "ഇന്റേർണൽ സെർവറിൽ പിശക് സംഭവിച്ചു , ദയവായി  വീണ്ടും ശ്രമിക്കുക",
        "mdlCretPoll_update_ques" : "ചോദ്യം അപ്‌ഡേറ്റുചെയ്യുക ",
        "mdlCretPoll_create_ques" : "ചോദ്യം സൃഷ്ടിക്കുക ",
        "mdlCretPoll_enter_que_here" : "നിങ്ങളുടെ ചോദ്യം ഇവിടെ നൽകുക ",
        "mdlCretPoll_end" : "അവസാനിക്കുന്നു ",
        //containers/content/components/contentQuestionnaire/viewQuestionnaire.js	
        "delete_successfully" : "വിജയകരമായി ഇല്ലാതാക്കി ",

        //containers/content/components/hozContentNavBlock.js / managecontent-index.js	
       // "mngContent_mngContent_content" : "ഉള്ളടക്കം ",
        // "mngContent_mngContent_participants" : "പങ്കെടുക്കുന്നവർ ",
        "mngContent_mngContent_fav_prog_lang" : "നിങ്ങളുടെ പ്രിയപ്പെട്ട പ്രോഗ്രാമിംഗ് ഭാഷ എന്താണ്? ",
        "mngContent_mngContent_pollings" : "Pollings",
        

        //containers/contentShare/showQuestions.js 	
        "showQue_obtained_marks" : "Obtained Marks:",
        //containers/contentShare/viewUserScore.js	
        "viewUser_your_grade" : "Your Grade:",
        //containers/packages/Package.js	
        "package_one_host" : "1 Host and",
        //"package_participants" : "Participants",
           // SOHO
        //"    SMB 300
        "package_concurrentUser_room2" : "20 ഒരേസമയത്തുള്ള ഉപയോക്താക്കൾ  – 2 മുറികൾ",
        "package_concurrentUser_room5" : "30 ഒരേസമയത്തുള്ള ഉപയോക്താക്കൾ -5 മുറികൾ",
        "package_concurrentUser_room20" : "100 ഒരേസമയത്തുള്ള ഉപയോക്താക്കൾ – 20 മുറികൾ",
        "package_concurrentUser_room30" : "200 ഒരേസമയത്തുള്ള ഉപയോക്താക്കൾ - 30 മുറികൾ",
        "package_concurrentUser_room50" : "500 ഒരേസമയത്തുള്ള ഉപയോക്താക്കൾ – 50 മുറികൾ",

        //containers/schedule/components/PastMeetingItem.js	
        "pastMeeting_rn" : "RN",
        "pastMeeting_chat_history_deleted" : "ചാറ്റ് ചരിത്രം വിജയകരമായി ഇല്ലാതാക്കി",
        "pastMeeting_past_meeting_dtls" : "കഴിഞ്ഞ മീറ്റിംഗ് വിശദാംശങ്ങൾ",
        "pastMeeting_duration" : "കാലാവധി:",
        "pastMeeting_download_parti_atten" : "പങ്കെടുത്തവരുടെ വിവരങ്ങള്‍  ഡൗൺലോഡുചെയ്യുക",
        "pastMeeting_download_webinar" : "വെബിനാർ ഡൗൺലോഡുചെയ്യു",
        // "pastMeeting_participants" : "പങ്കെടുത്തവർ",
        "pastMeeting_attendees" : "പങ്കെടുക്കുന്നവർ",
        "pastMeeting_chat_history" : "ചാറ്റ് ചരിത്രം",
        "pastMeeting_recordings" : "റെക്കോർഡിംഗുകൾ",
        "pastMeeting_webinar_user" : "വെബിനാർ  ഉപയോക്താക്കൾ",
        "pastMeeting_webinar_chat" : "വെബിനാർ ചാറ്റ്",
        "pastMeeting_no_participants_in_meeting" : "ഈ മീറ്റിംഗിൽ പങ്കെടുക്കുന്നവർ ഇല്ല!",
        "pastMeeting_no_parti_attended_meeting" : "ഈ യോഗത്തിൽ പങ്കെടുത്ത ആരും ഉണ്ടായിരുന്നില്ല.",
        "pastMeeting_guest_list" : "അതിഥി പട്ടിക",
        "pastMeeting_no_guest_in_meeting" : "ഈ മീറ്റിംഗിൽ അതിഥികളൊന്നുമില്ല!",
        "pastMeeting_no_guests_were_invited " : "ഈ മീറ്റിംഗിൽ ക്ഷണിക്കപ്പെടുകയോ പങ്കെടുക്കുകയോ ചെയ്തിട്ടില്ല.",
        "pastMeeting_meeting_chat_history" : "മീറ്റിംഗ് ചാറ്റ് ചരിത്രം",
        "pastMeeting_delete_all" : "എല്ലാം ഇല്ലാതാക്കുക",
        "pastMeeting_no_chat_msg_inmeeting" : "ഈ മീറ്റിംഗിൽ ചാറ്റ് സന്ദേശങ്ങളൊന്നുമില്ല!",
        "pastMeeting_no_conver_during_meeting" : "മീറ്റിംഗിനിടെ ചാറ്റിൽ സംഭാഷണങ്ങളൊന്നും ഉണ്ടായിരുന്നില്ല.",
        "pastMeeting_no_user_inwebinar" : "ഈ വെബിനാറിൽ ഉപയോക്താക്കളൊന്നുമില്ല!",
        "pastMeeting_no_user_attand_webinar" : "ഈ വെബിനറിൽ പങ്കെടുത്ത ഉപയോക്താക്കളൊന്നുമില്ല.",
        "pastMeeting_webinar_chat_history" : "വെബിനാർ ചാറ്റ് ചരിത്രം",
        "pastMeeting_no_chat_msg_inwebinar" : "ഈ വെബിനറിൽ ചാറ്റ് സന്ദേശങ്ങളൊന്നുമില്ല!",
        "pastMeeting_no_conver_during_webinar" : "വെബിനാർ സമയത്ത് ചാറ്റിൽ സംഭാഷണങ്ങളൊന്നുമില്ല.",
        "pastMeeting_del_chat_history" : "ചാറ്റ് ചരിത്രം ഇല്ലാതാക്കുക",
        "pastMeeting_delete_all_chat_history" : "നിങ്ങൾക്ക് ഉറപ്പാണോ, എല്ലാ ചാറ്റ് ചരിത്രവും ശാശ്വതമായി ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നോ?",
        "pastMeeting_delete" : "ഇല്ലാതാക്കുക",
        //containers/schedule/components/Schedules.js	
        "schedul_webinar_description" : "വെബിനാർ വിവരണം",
        "schedul_upload_custom_banner" : "നിങ്ങളുടെ വെബിനാർ ഇവന്റിനായി ഒരു ഇഷ്‌ടാനുസൃത ബാനർ അപ്‌ലോഡുചെയ്യുക.",
        "schedul_setting" : "ക്രമീകരണങ്ങൾ",
        //containers/schedule/components/SchedulesView.js	
        "schedulView_time" : "സമയം",
        "schedulView_edit" : "മാറ്റുക",
        "schedulView_enable_training_mode" : "പരിശീലന മോഡ് പ്രവർത്തനക്ഷമമാക്കുക",
        "schedulView_audio_conference" : "ഓഡിയോ കോൺഫറൻസ്",
        "schedulView_enable_mix_video" : "സ്ഥിരസ്ഥിതിയായി മിക്സ് വീഡിയോ പ്രാപ്തമാക്കുക",
        
        //class components
        //content - components - hozContentNavBlock	
        // "hozConNav_content" : "ഉള്ളടക്കം",
        //scheduleMeeting	
        "scheMeet_room_reserved_from" : "മുറി റിസർവ്വ് ചെയ്തിരിക്കുന്നത് ",
        "scheMeet_meeting_name" : "സമ്മേളന പേര്‌",
        //dashboard - component -Contact	
        "contact_alert_already_in_call" : "നിങ്ങൾ ഒരു കോളിൽ ആയിരിക്കുബോൾ ,നിങ്ങൾക്ക് മറ്റ് ഉപയോക്താവിനെ വിളിക്കാൻ കഴിയില്ല",
        "contact_alert_user_offline" : "ഉപയോക്താവ് ഓഫ്‌ലൈൻ",
        //dashboard - dashboard.js	
        "dashboard_alert_cannot_start" : " നിങ്ങൾ വേറെ ഒരു കോളിൽ ആയിരിക്കുബോൾ, നിങ്ങൾക്ക് കോൺഫറൻസ് ആരംഭിക്കാൻ കഴിയില്ല,",
        "dashboard_alert_cannot_join" : "നിങ്ങൾ വേറെ ഒരു കോളിൽ ആയിരിക്കുബോൾ , നിങ്ങൾക്ക് കോൺഫറൻസിൽ ചേരാനാവില്ല",
        "dashboard_quick_start_conf" : "ദ്രുത സമ്മേളനം ആരംഭിക്കുക ",
        "dashboard_maintenance_msg" : "പതിവ് പരിപാലനം കാരണം 2020 ഏപ്രിൽ 22 ന് 8.30 PM IST മുതൽ 9.00 PM IST വരെ ഞങ്ങൾ സെർവർ പ്രവർത്തനരഹിതമാകുമെന്ന് ദയവായി ശ്രദ്ധിക്കുക.",
        //coBrowsing	
        "coBrows_host_share-video" : "കാത്തിരിക്കൂ! ആതിഥേയൻ ഒരു വീഡിയോ പങ്കിടാൻ പോകുന്നു",
        //Additionalcamera	
        "addCam_add_device" : "ഉപകരണം ചേർക്കുക",

        // genral same lang
        "answer_list" : "ഉത്തരങ്ങളുടെ പട്ടിക",
        "answer" : "ഉത്തരങ്ങൾ",
        "answered" : "ഉത്തരം നൽകി",
        "correct_answer" : "ശരിയായ ഉത്തരം",
        "please_try_again" : "ദയവായി വീണ്ടും ശ്രമിക്കുക",
        "question" : "ചോദ്യം",
        "cancel" : "റദ്ദാക്കുക",
        "questionnaire" : "ചോദ്യാവലി",
        "content" : "ഉള്ളടക്കം",
        "address_book" : "മേൽവിലാസ പുസ്തകം",
        "view" : "കാണുക",
        "rn" : "RN",

        // new langs
        "plz_enter_marks" :"ചോദ്യത്തിന്റെ മാർ‌ക്കുകൾ‌ നൽ‌കുക",
        "plz_sel_crtans" : "ശരിയായ ഉത്തരം തിരഞ്ഞെടുക്കുക",
        "truefalse_choice" : "ശരി / തെറ്റ്   തിരഞ്ഞെടുക്കുക",
        "questionnarie" : "ചോദ്യാവലി",
        "content_title" : "ഉള്ളടക്ക ശീർഷകം",
        "media_title" : "മീഡിയ_ടൈറ്റിൽ",
        "add_url_add" : "URL വിലാസം ചേർക്കുക ...",
        "val_shuld_grtr_from_val" : "മൂല്യത്തിലേക്ക് മൂല്യത്തേക്കാൾ വലുതായിരിക്കണം",
        "val_shuld_grtr_grade" : " മൂല്യത്തിൽ നിന്ന് ഗ്രേഡിനേക്കാൾ വലുതായിരിക്കണം ",
        "to_val" : "മൂല്യത്തിലേക്ക്",
        "enable_hand_raise" : "ഒരു വെബിനാറിൽ കൈ ഉയർത്തുക / ചോദ്യം ചോദിക്കുക ",
        "enable_waiting_room" : "വെയിറ്റിംഗ് റൂം പ്രവർത്തനക്ഷമമാക്കുക",
        "allow_only_invite" : "സമ്മേളനത്തിൽ ചേരാൻ ക്ഷണിതാക്കളെ മാത്രം അനുവദിക്കുക",
        "guest_login_anonymous" : "അതിഥിക്ക് അജ്ഞാതനായി പ്രവേശിക്കാൻ കഴിയും",
        "give_name_webinar" : "നിങ്ങളുടെ വെബിനറിന് ഒരു പേര് നൽകുക",
        "Webinar" : "വെബിനാർ",
        "video_syn" : "വീഡിയോ സമന്വയം",
        "notepad" : "നോട്ട്പാഡ്",
        "enable_handraise" : "ഒരു വെബിനറിൽ ഹാൻഡ് റൈസ് പ്രാപ്തമാക്കുക",
        //new one venkatesh
        "polls_list" : "വോട്ടെടുപ്പ് പട്ടിക",
        "no_poll_assign" : "ഒരു വോട്ടെടുപ്പും ഇതുവരെ നൽകിയിട്ടില്ല!",
        "poll_view" : "വോട്ടെടുപ്പ് കാഴ്ച",

        // new Prudhvi
        "filter_block": "ഫിൽട്ടർ ബ്ലോക്ക്",
        "file_size": "ഫയൽ വലുപ്പം",
        "select_room": "ഒരു മുറി തിരഞ്ഞെടുക്കുക",
        "provide_event_name": "ഒരു ഇവന്റ് നാമം നൽകുക",
        "end_time_greater_start_time": "അവസാന സമയം ആരംഭ സമയത്തേക്കാൾ വലുതായിരിക്കണം!",
        "invite_people_meeting": "ഈ മീറ്റിംഗിലേക്ക് ആളുകളെ ക്ഷണിക്കുക",
        "download_ical": "ICal ഡൗൺലോഡുചെയ്യുക",
        "download": "ഡൗൺലോഡുചെയ്യുക",
        "delete_meeting": "ഈ മീറ്റിംഗ് ഇല്ലാതാക്കുക",
        "room": "മുറി",
        "new_event": "പുതിയ ഇവന്റ്",
        "end_repeat": "അവസാനം ആവർത്തിക്കുക",
        "invite_users_timezone": "ഇനിപ്പറയുന്ന സമയമേഖല ഉപയോഗിച്ച് ഉപയോക്താക്കളെ ക്ഷണിക്കുക",
        "update_event": "ഇവന്റ് അപ്‌ഡേറ്റുചെയ്യുക",
        "create_event": "ഇവന്റ് സൃഷ്ടിക്കുക",
        "wrong_password": "തെറ്റായ പാസ്‌വേഡ്",
        "college": "കോളേജ്",
        "university": "യൂണിവേഴ്സിറ്റി",
        "country": "രാജ്യം",
        "time_period": "സമയ കാലയളവ്:",
        "currently_working_here": "നിലവിൽ ഇവിടെ പ്രവർത്തിക്കുന്നു",
        "from": "മുതൽ:",
        "to": "ടു:",
        "graduated": "ബിരുദം എടുത്തത്",
        "enter_description": "നിങ്ങളുടെ വിവരണം ഇവിടെ നൽകുക...",
        "concentration": "ഏകാഗ്രത",
        "school": "സ്കൂൾ",
        "poll_question": "വോട്ടെടുപ്പ് ചോദ്യം",
        "create_poll_ques": "വോട്ടെടുപ്പ് ചോദ്യം സൃഷ്‌ടിക്കുക",
        "options": "ഓപ്ഷനുകൾ",
        "no_ques_assigned_yet": "ഇതുവരെ ചോദ്യങ്ങളൊന്നും നൽകിയിട്ടില്ല!",
        "create_new_ques_click_create_ques": "ഒരു പുതിയ ചോദ്യങ്ങൾ സൃഷ്ടിക്കുക, മുകളിലുള്ളത് ക്ലിക്കുചെയ്യുന്നതിന് ചോദ്യ ബട്ടൺ സൃഷ്ടിക്കുക",
        "enter_room_name": "റൂമിന്റെ പേര് നൽകുക",
        "billed_annually": "* വാർഷിക ബിൽ",
        "subscribe_now": "ഇപ്പോൾ സബ്‌സ്‌ക്രൈബുചെയ്യുക ",
        "group": "ഗ്രൂപ്പ്",
        "sample_webinar": "സാമ്പിൾ വെബിനാർ",
        "webinar_affiliate": "അഫിലിയേറ്റുകളെക്കുറിച്ചുള്ള വെബിനാർ",
        "webinar_one": "വെബിനാർ വൺ",
        "workspace": "ജോലി ചെയ്യുന്ന സ്ഥലം",
        "company": "കമ്പനി",
        "add_another": "മറ്റൊന്ന് ചേർക്കുക",

        "Cancel":"റദ്ദാക്കുക",
        "instavc_schedulemeeting_minutes":"മിനിറ്റുകള്‍",
        "Save":"സേവ്",
        "instavc_manage_plans":"നിയന്ത്രിക്കുക പദ്ധതികൾ / ഉൽപ്പന്നങ്ങൾ ",
        "scheView_minutes":"മിനിറ്റുകള്‍",
        "scheInvt_minutes":"മിനിറ്റുകള്‍",
        "myMeetItem_minutes":"മിനിറ്റുകള്‍",
        "pastMeeting_notes":"കുറിപ്പുകൾ",
        "assignment":"അസൈൻമെന്റ്",
        "assignments":"അസൈൻമെന്റുകൾ",
        "create_new_poll":"അസൈൻ ചെയ്യാൻ ആരംഭിക്കുന്നതിന് ഒരു പുതിയ വോട്ടെടുപ്പ് ചോദ്യം സൃഷ്ടിക്കുക.",
        "plz_title_url":"ശീർഷകവും Url ഉം നൽകുക",
        "votes":"സമ്മതിദാനം",
        "enter_content_title":"ഉള്ളടക്കത്തിന്റെ ശീർഷകം നൽകുക",
        "failed_result":"പരാജയപ്പെട്ട ഫലം",
        "removed_successfully":"വിജയകരമായി നീക്കംചെയ്‌തു",
        "enter_duration":"ദയവായി ദൈർഘ്യം നൽകുക",
        "select_questionnarie":"ചോദ്യാവലി തിരഞ്ഞെടുക്കുക",
        "no_assignments":"അസൈൻമെന്റുകളൊന്നുമില്ല",
        "create_new_assignment":"ഒരു പുതിയ അസൈൻ‌മെന്റ് സൃഷ്‌ടിക്കുന്നതിന്, മുകളിൽ‌ കാണുന്ന പുതിയ അസൈൻ‌മെന്റ് സൃഷ്‌ടിക്കുക ബട്ടൺ‌  ക്ലിക്കുചെയ്യുക",
        "create_assignment":"അസൈൻ‌മെന്റ് സൃഷ്‌ടിക്കുക",
        "assignments_list":"അസൈൻമെന്റ് ലിസ്റ്റ്",
        "assignment_description":"അസൈൻ‌മെന്റ് വിവരണം",
        "assignment_name":"അസൈൻമെന്റിന്റെ പേര്",
        "update_assignment":"അസൈൻ‌മെന്റ് അപ്‌ഡേറ്റുചെയ്യുക",
        "add_new_assignment":"പുതിയ അസൈൻമെന്റ് ചേർക്കുക",
        "assignment_list":"അസൈൻ‌മെന്റ് പട്ടിക",
        "assign_assignments":"അസൈൻമെന്റുകൾ നൽകുക",
        "assigned_assignments_list":"അസൈൻ‌ഡ് അസൈൻ‌മെൻറ് പട്ടിക",
        "assignment_answer":"അസൈൻ‌മെന്റ് ഉത്തരം",
        "no_assig_submitted":"അസൈൻമെന്റുകളൊന്നും ഇതുവരെ സമർപ്പിച്ചിട്ടില്ല.",
        "no_assig_submitted_participants":"പങ്കെടുക്കുന്നവരിൽ നിന്ന് ഇതുവരെ അസൈൻമെന്റുകളൊന്നും സമർപ്പിച്ചിട്ടില്ല.",
        "view_assignment":"അസൈൻമെന്റ് കാണുക",
        "assigned_participants":"നിയുക്ത പങ്കാളികൾ",
        "select_assignment":"അസൈൻമെന്റ് തിരഞ്ഞെടുക്കുക",
        "fill_your_assignment":"നിങ്ങളുടെ അസൈൻമെന്റ് പൂരിപ്പിക്കുക",
        "submit_assignment":"അസൈൻമെന്റ് സമർപ്പിക്കുക",
        "assignment_details":"അസൈൻമെന്റ് വിശദാംശങ്ങൾ",
        "assignment_submit_success":"അസൈൻമെന്റ് വിജയകരമായി സമർപ്പിച്ചു",
        "assignment_saved_success" : "അസൈൻ‌മെന്റ് വിജയകരമായി സംരക്ഷിച്ചു",
        "how_to_get_started":"എങ്ങനെ ആരംഭിക്കാം.",
        "create_new_room":"പുതിയ മുറി സൃഷ്ടിക്കുക",
        "create_room_add_participants":"ഒരു പുതിയ മുറി സൃഷ്‌ടിച്ച് പങ്കാളികളെ മുറിയിലേക്ക് ചേർക്കുക.",
        "repeats_every":"ആവർത്തിക്കുന്നു എല്ലാ",
        "now":"ഇപ്പോൾ",
        "Plz_enter_title_meeting":"മീറ്റിംഗിന്റെ ശീർഷകം നൽകുക",
        "no_start_time":"ആരംഭ സമയം കണ്ടെത്തിയില്ല",
        "no_end_time":"അവസാന സമയമൊന്നും കണ്ടെത്തിയില്ല",
        "item_id_not_found":"ഇനം ഐഡി കണ്ടെത്തിയില്ല",
        "msg_title_not_found":"സന്ദേശ ശീർഷകം കണ്ടെത്തിയില്ല",
        "plz_join_conf_clicking_link":"ഇനിപ്പറയുന്ന ലിങ്ക് ക്ലിക്കുചെയ്ത് ദയവായി കോൺഫറൻസിൽ ചേരുക",
        "hi_there":"ഹേയ്, അവിടെയുണ്ടോ",
        "room_permanently":"സ്ഥിരമായി മുറി?",
        "sure_want_to_delete_participants":"നിങ്ങൾക്ക് ഉറപ്പാണോ, ഈ പങ്കാളിയെ ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നു ?",
        "delete_participant":"പങ്കാളിയെ ഇല്ലാതാക്കുക",
        "add_email":"ഇമെയിൽ ചേർക്കുക",
        "add_participants_to_the_room":"ചുവടെയുള്ള ഇൻപുട്ട് ബോക്സിൽ പങ്കെടുക്കുന്നവരുടെ ഇമെയിൽ വിലാസം ടൈപ്പുചെയ്ത് ഈ മുറിയിലേക്ക് ചേർക്കുക.",
        "no_of_users":"ഉപയോക്താക്കളുടെ എണ്ണം",
        "edit_the_name":"നിങ്ങൾക്ക് പേരും ഈ മുറിയുടെ ഉപയോക്താക്കളുടെ നമ്പറും എഡിറ്റുചെയ്യാനാകും.",
        "delete_room":"മുറി ഇല്ലാതാക്കുക",
        "are_you_sure_delete":"നിങ്ങൾക്ക് ഉറപ്പാണോ, ഇല്ലാതാക്കാൻ ആഗ്രഹിക്കുന്നു",
        "delete_this_room":"ഈ മുറി ഇല്ലാതാക്കുക",
        "delete_room_will_parmanently":"ഇല്ലാതാക്കുക ക്ലിക്കുചെയ്യുന്നതിലൂടെ, ഈ മുറി ശാശ്വതമായി ഇല്ലാതാക്കപ്പെടും.",
        "enable_storage":"സംഭരണം പ്രവർത്തനക്ഷമമാക്കുക",
        "enable_recordings":"റെക്കോർഡിംഗുകൾ പ്രവർത്തനക്ഷമമാക്കുക",
        "enable_SIP":"SIP പ്രവർത്തനക്ഷമമാക്കുക",
        "addons":"ആഡോണുകൾ",
        "expires_on":"കാലഹരണപ്പെടുന്നു",
        "purchased_on":"വാങ്ങിയത്",
        "more_storage":"കൂടുതൽ സംഭരണം",
        "total_storage":"മൊത്തം സംഭരണം",
        "storage_occupied":"സംഭരണം കൈവശപ്പെടുത്തി",
        "added":"ചേർത്തു",
        "open_graphs":"ഓപ്പൺ ഗ്രാഫുകൾ",
        "participant_controls":"പങ്കാളി നിയന്ത്രണങ്ങൾ",
        "dashboard_controls ":"ഡാഷ്‌ബോർഡ് നിയന്ത്രണങ്ങൾ ",
        "start_meeting":"ഒരു മീറ്റിംഗ് ആരംഭിക്കുക",
        "join_a_meeting":"ഒരു മീറ്റിംഗിൽ ചേരുക",
        "meeting_settings":"മീറ്റിംഗ് ക്രമീകരണങ്ങൾ",
        "videos_to_get_started":"ആരംഭിക്കുന്നതിനുള്ള വീഡിയോകൾ",
        "if_you_are_host":"നിങ്ങൾ ഒരു ആതിഥേയൻ ആണെങ്കില്‍, മീറ്റിംഗ് ആരംഭിക്കുന്നതിന് ചുവടെയുള്ള ബട്ടൺ ക്ലിക്കുചെയ്യുക",
        "guest_link":"അതിഥി ചങ്ങലക്കണ്ണി",
        "pay_now":"ഇപ്പോൾ പണമടയ്‌ക്കുക",
        "promo_code_apply_here":"ഒരു പ്രൊമോ കോഡ് ഉണ്ടോ? ഇവിടെ പ്രയോഗിക്കുക",
        "per_month":"മാസം തോറും",
        "billed_for":"ബില്ലുചെയ്‌തു",
        "gst_applicable":"സർക്കാർ മാനദണ്ഡങ്ങൾ അനുസരിച്ച്, ഇന്ത്യയിലെ ഉപഭോക്താക്കൾക്ക് 18% അധിക ജിഎസ്ടി ബാധകമാകും.",
        "select_country_code":"രാജ്യ കോഡ് തിരഞ്ഞെടുക്കുക",
        "country_code":"രാജ്യ കോഡ്",
        "enter_email_id":"ഇമെയിൽ വിലാസം നൽകുക ...",
        "downLoad_chat":"ഡൗൺലോഡ് ചാറ്റ്",
        "allow_guest_control_their":"അതിഥിയെ അവരുടെ വീഡിയോ / ഓഡിയോ നിയന്ത്രിക്കാൻ അനുവദിക്കുക",
        "below_list_people_invited":"ക്ഷണിച്ച ആളുകളുടെ പട്ടിക ചുവടെ",
        "verification_code":"പരിശോധന കോഡ്",
        "open_link_enter_verify_code":"ചുവടെയുള്ള URL ലിങ്ക് തുറന്ന് സംയോജനത്തിനായി നിങ്ങളുടെ പരിശോധന കോഡ് നൽകുക",
        "social_media_integration":"സോഷ്യൽ മീഡിയ സംയോജനം",
        "integrate_social_media_platform":"നിങ്ങൾക്ക് സോഷ്യൽ മീഡിയ പ്ലാറ്റ്‌ഫോമുകൾ സംയോജിപ്പിക്കാൻ കഴിയും, ഒരു ബട്ടൺ ക്ലിക്കുചെയ്യുന്നതിലൂടെ, നിങ്ങൾക്ക് സ്ഥിരീകരണ കോഡ് ലഭിക്കും, സംയോജനത്തിനായി ഉപയോഗിക്കുക.",
        "plz_enter_val_email":"സാധുവായ ഇമെയിൽ നൽകുക",
        "record":"റെക്കോർഡ്",
        "in_conference":"സമ്മേളനത്തിൽ",
        "password_if_any":"പാസ്‌വേഡ് (എന്തെങ്കിലും ഉണ്ടെങ്കിൽ)",
        "stop_send":"നിർത്തി അയയ്‌ക്കുക",
        "broadcast_not_enabled_meeting":"ഈ മീറ്റിംഗിനായി പ്രക്ഷേപണം പ്രാപ്തമാക്കിയിട്ടില്ല",
        "facebook_url":"ഫേസ്ബുക്ക് URL",
        "enter_facebook_url":"ഫേസ്ബുക്ക് RTMB URL നൽകുക",
        "add_facebook_url":"ഫേസ്ബുക്ക് RTMP URL ചേർക്കുക",
        "youtube_url":"യൂട്യൂബ്  URL",
        "enter_youtube_url":"യൂട്യൂബ് RTMB URL നൽകുക",
        "add_youtube_url":"യൂട്യൂബ്  RTMP URL ചേർക്കുക",
        "register":"രജിസ്റ്റർ ചെയ്യുക",
        "time_of_program":"പ്രോഗ്രാമിന്റെ സമയം",
        "date_of_program":"പരിപാടിയുടെ തീയതി",
        "name_program_interested":"താൽപ്പര്യമുള്ള പ്രോഗ്രാമിന്റെ പേര്",
        "email_id":"ഇ - മെയിൽ വിലാസം",
        "dob":"ജന്മദിനം",
        "applicant_address":"അപേക്ഷകന്റെ വിലാസം",
        "qualification":"യോഗ്യത",
        "designation":"പദവി",
        "org_name_address":"സ്ഥാപനത്തിന്റെ പേരും വിലാസവും",
        "fullname_participant":"പങ്കെടുക്കുന്നയാളുടെ മുഴുവൻ പേര്",
        "registration_form":"രജിസ്ട്രേഷൻ ഫോം",
        "thanks_submitting_data":"നിങ്ങളുടെ ഡാറ്റ സമർപ്പിച്ചതിന് നന്ദി",
        "manage_content":"ഉള്ളടക്കം നിയന്ത്രിക്കുക",
        "API_settings":"API ക്രമീകരണങ്ങൾ",
        "schedule_API":"API ഷെഡ്യൂൾ ചെയ്യുക",
        "image_upload_success":"ചിത്രം വിജയകരമായി അപ്‌ലോഡുചെയ്‌തു",
        "less_than_10MB":"10MB- യിൽ താഴെയുള്ള ഫയൽ അപ്‌ലോഡുചെയ്യുക",
        "at_time_of_event":"ഇവന്റ് സമയത്ത്",
        "min_5":"5 മിനിറ്റ് മുമ്പ്",
        "min_10":"10 മിനിറ്റ് മുമ്പ്",
        "min_15":"15 മിനിറ്റ് മുമ്പ്",
        "min_30":"30 മിനിറ്റ് മുമ്പ്",
        "hrs_1":"1 മണിക്കൂറിന് മുമ്പ്",
        "hrs_2":"2 മണിക്കൂറിന് മുമ്പ്",
        "day_1":"1 ദിവസം മുമ്പ്",
        "day_2":"2 ദിവസം മുമ്പ്",
        "jan":"ജനുവരി",
        "feb":"ഫെബ്രുവരി",
        "mar":"മാർച്ച്",
        "apr":"ഏപ്രിൽ",
        "may":"മെയ്",
        "jun":"ജൂൺ",
        "jul":"ജൂലൈ",
        "aug":"ഓഗസ്റ്റ്",
        "sep":"സെപ്റ്റംബർ",
        "oct":"ഒക്ടോബർ",
        "nov":"നവംബർ",
        "dec":"ഡിസംബർ",
        "mon":"തിങ്കളാഴ്ച",
        "tue":"ചൊവ്വാഴ്ച",
        "wed":"ബുധനാഴ്ച",
        "thu":"വ്യാഴാഴ്ച",
        "fri":"വെള്ളിയാഴ്ച",
        "sat":"ശനിയാഴ്ച",
        "sun":"ഞായറാഴ്ച",
        "all_day":"ദിവസം മുഴുവൻ",
        "starts_ddmmyyyy":"ആരംഭിക്കുന്നു   (ദിവസം/മാസം/വർഷം)",
        "ends_ddmmyyyy":"അവസാനിക്കുന്നു (ദിവസം/മാസം/വർഷം)",
        "ddmmyyyy":"(ദിവസം/മാസം/വർഷം)",
        "by_dayweek":"ദിവസം / ആഴ്ച",
        "never":"ഒരിക്കലും",
        "on_date":"തീയതിയിൽ",
        "alert":"ജാഗ്രത",
        "multi_factor_auth" : "മൾട്ടി ഫാക്ടർ പ്രാമാണീകരണം പ്രാപ്തമാക്കുക (MFA)",
        "advanced_settings" : "വിപുലമായ ക്രമീകരണങ്ങൾ",
        "verify_Otp" : "OTP പരിശോധിക്കുക",
        "resend_Otp" : "OTP വീണ്ടും അയയ്‌ക്കുക",
        "otp_sent_mobile" : "നിങ്ങളുടെ മൊബൈൽ നമ്പറിലേക്ക് അയച്ച OTP നൽകുക",
        "update_mobile" : "മൊബൈൽ അപ്‌ഡേറ്റുചെയ്യുക",
        "verify_mobile" : "മൊബൈൽ പരിശോധിക്കുക",
        "mob_verfi_pending" : "നിങ്ങളുടെ മൊബൈൽ  പരിശോധന തീർപ്പുകൽപ്പിച്ചിട്ടില്ല.",
        "mobile_number" : "മൊബൈൽ നമ്പർ",
        "enter_Otp" : "OTP നൽകുക",
        "enter_mob_no" : "മൊബൈൽ നമ്പർ നൽകുക",
        "sure_want_to_delete_rec" : "റെക്കോർഡിംഗ് ഇല്ലാതാക്കുക, നിങ്ങൾക്ക് ഉറപ്പാണോ ",
        "delete_recording" : "റെക്കോർഡിംഗ് ഇല്ലാതാക്കുക",
        "invalid_credentials" : "അസാധുവായ ക്രെഡൻഷ്യലുകൾ",
        "join_conf_by_clicking_link" : "ഇനിപ്പറയുന്ന ലിങ്ക് ക്ലിക്കുചെയ്ത് കോൺഫറൻസിൽ ചേരുക",
        "hi_there" : "ഹായ്യ്, അവിടെയുണ്ടോ,",
        "valid_room_name" : "സാധുവായ മുറിയുടെ പേര് നൽകുക",
        "internal_server_error" : "സെർവറിൽ പിശക് സംഭവിച്ചു, ദയവായി വീണ്ടും ശ്രമിക്കുക",
        "room_limit_exceed" : "മുറി പരിധി കവിഞ്ഞു",
        "cant_start_meeting_already_goingon" : "നിങ്ങൾക്ക് ഒരു മീറ്റിംഗ് ആരംഭിക്കാൻ കഴിയില്ല, കാരണം ഇതിനകം ഒരു മീറ്റിംഗ് നടക്കുന്നു.",

        //new
        "social_integration" : "സാമൂഹിക സംയോജനം",
        "advanced" : "വിപുലമായത്",
        "add_youtube" : "Youtube ചേർക്കുക",
        "plugins" : "പ്ലഗിനുകൾ",
        "subtitles" : "സബ്‌ടൈറ്റിലുകൾ",
        "view_local" : "ലോക്കൽ കാണുക",
        "hide_local" : "ലോക്കൽ മറയ്‌ക്കുക",
        "switch_conf_mode" : "കോൺഫറൻസ് മോഡിലേക്ക് മാറുക",
        "switch_pres_mode" : "അവതാരക മോഡിലേക്ക് മാറുക",
        "conference_locked" : "കോൺഫറൻസ് ലോക്കുചെയ്‌തു",
        "conference_unlocked" : "കോൺഫറൻസ് അൺലോക്കുചെയ്‌തു",
        "sharing_tools" : "ഉപകരണങ്ങൾ പങ്കിടൽ",
        "record" : "റെക്കോർഡ്",
        "recording" : "റെക്കോർഡിംഗ്",
        "plz_wait_host_shr_video" : "കാത്തിരിക്കൂ! ഹോസ്റ്റ് ഒരു വീഡിയോ പങ്കിടാൻ പോകുന്നു",
        "supported_sources_include" : "പിന്തുണയ്‌ക്കുന്ന ഉറവിടങ്ങളിൽ ഉൾപ്പെടുന്നു",

        "team_plugins" : "ടീം പ്ലഗിനുകൾ",
        "open" : "തുറക്കുക",
        "click" : "ക്ലിക്കുചെയ്യുക",
        "more_app" : "കൂടുതൽ അപ്ലിക്കേഷനുകൾ",
        "upload_custom_app" : "ഇഷ്‌ടാനുസൃത അപ്ലിക്കേഷൻ അപ്‌ലോഡുചെയ്യുക",
        "download_team_plugin" : "ടീം പ്ലഗിൻ ഡൗൺലോഡുചെയ്യുക",
        "install_mng_app_microsoft_team" : "Microsoft ടീമുകളിൽ അപ്ലിക്കേഷൻ എങ്ങനെ ഇൻസ്റ്റാൾ ചെയ്യാം, നിയന്ത്രിക്കാം",
        "microsoft_step1" : "ഘട്ടം 1: മൈക്രോസോഫ്റ്റ് ടീമുകൾ തുറക്കുക",
        "microsoft_step1_para1" : "ആരംഭിക്കുന്നതിന്, ആദ്യം ഈ പേജിന്റെ മുകളിൽ ലഭ്യമായ നിങ്ങളുടെ സിസ്റ്റത്തിലേക്ക് ടീം പ്ലഗിൻ ഡ download ൺലോഡ് ചെയ്യണം. തുടർന്ന് നിങ്ങളുടെ Microsoft ടീമുകൾ തുറന്ന് തിരഞ്ഞെടുക്കുക",
        "microsoft_step1_para2" : " ടീം അപ്ലിക്കേഷനുകൾ കാണുന്നതിന് സൈഡ് നാവിഗേഷനിൽ നിന്നുള്ള ഓപ്ഷനുകൾ.",
        "microsoft_step2" : "ഘട്ടം 2: കൂടുതൽ അപ്ലിക്കേഷനുകളിലേക്ക് പോകുക",
        "microsoft_step2_para1" : "കൂടുതൽ അപ്ലിക്കേഷനുകൾ എന്ന ബട്ടണിനൊപ്പം കുറച്ച് അപ്ലിക്കേഷനുകൾ ഇവിടെ നിങ്ങൾക്ക് കണ്ടെത്താനാകും. ക്ലിക്ക് ചെയ്യുക",
        "microsoft_step2_para2" : "ഇഷ്‌ടാനുസൃത അപ്‌ലോഡ് ഓപ്ഷനും മറ്റ് സ്റ്റോർ ഓപ്ഷനുകളും കൂടുതൽ പരിശോധിക്കുന്നതിന്.",
        "microsoft_step3" : "ഘട്ടം 3: അപ്ലിക്കേഷൻ അപ്‌ലോഡുചെയ്യുക",
        "microsoft_step3_para1" : "നിങ്ങൾ സ്റ്റോർ തുറന്നുകഴിഞ്ഞാൽ, നിങ്ങൾക്ക് ഒരു കണ്ടെത്താനാകും",
        "microsoft_step3_para2" : "ഇടത് നാവിഗേഷനിലെ ഓപ്ഷൻ. അതിൽ ക്ലിക്കുചെയ്‌ത് നിങ്ങളുടെ ഡൗൺലോഡുചെയ്‌ത പ്ലഗിൻ അപ്ലിക്കേഷൻ ഇവിടെ അപ്‌ലോഡുചെയ്യുക.",
        "microsoft_step4" : "ഘട്ടം 4: സംഭരണത്തിൽ നിന്ന് InstaVC അപ്ലിക്കേഷൻ തിരഞ്ഞെടുക്കുക",
        "microsoft_step4_para1" : "വിൻഡോ ആവശ്യപ്പെടുമ്പോൾ, (ഘട്ടം 1) ഡ download ൺലോഡ് ചെയ്ത നിങ്ങളുടെ സിസ്റ്റത്തിൽ നിന്ന് InstaVC പ്ലഗിൻ അപ്ലിക്കേഷൻ തിരഞ്ഞെടുത്ത് ക്ലിക്കുചെയ്യുക",
        "microsoft_step5" : "ഘട്ടം 5: മൈക്രോസോഫ്റ്റ് ടീമുകളിൽ അപ്‌ലോഡ് ചെയ്ത അപ്ലിക്കേഷൻ",
        "microsoft_step5_para1" : "ഫയൽ അപ്‌ലോഡുചെയ്‌തതിനുശേഷം, ഇത് മൈക്രോസോഫ്റ്റ് ടീം അപ്ലിക്കേഷൻ ലിസ്റ്റിൽ അപ്‌ലോഡുചെയ്യുന്നു. തുടർന്ന് നിങ്ങൾക്ക് കാണാനും അപ്ലിക്കേഷനുകളിൽ ടീമുകളിലേക്ക് ചേർക്കാനും അപ്ലിക്കേഷനിൽ ക്ലിക്കുചെയ്യാം.",
        "microsoft_step6" : "ഘട്ടം 6: InstaVC അപ്ലിക്കേഷൻ തുറക്കുക",
        "microsoft_step6_para1" : "InstaVC അപ്ലിക്കേഷനിൽ ക്ലിക്കുചെയ്‌തുകഴിഞ്ഞാൽ, നിങ്ങൾക്ക് ഇപ്പോൾ ഒരു ബട്ടണിനൊപ്പം പ്ലഗിൻ അപ്ലിക്കേഷന്റെ പൂർണ്ണ വിശദാംശങ്ങളും കാണാനാകും",
        "microsoft_step6_para2" : "മൈക്രോസോഫ്റ്റ് ടീമുകളിലേക്ക് ഈ അപ്ലിക്കേഷൻ ചേർക്കുന്നതിനുള്ള ബട്ടൺ.",
        "microsoft_step7" : "ഘട്ടം 7: മൈക്രോസോഫ്റ്റ് ടീമുകളിലേക്ക് ഇൻസ്റ്റാവിസി ചേർത്തു",
        "microsoft_step7_para1" : "എളുപ്പവും ഇഷ്ടാനുസൃതവുമായ ഉപയോഗത്തിനായി ഇടത് വശത്തെ നാവിഗേഷനിലുള്ള മൈക്രോസോഫ്റ്റ് ടീമുകളിലേക്ക് ഇപ്പോൾ നിങ്ങളുടെ അപ്ലിക്കേഷൻ ചേർക്കും.",

        "ddyymm" : "DD/MM/YYYY",
        "fill_details_for_webinar" : "Please fill your registration details for Webinar at",
        "microsoft_teams" : "Microsoft Teams",
        "microsoft_outlook" : "Microsoft Outlook",
        "select_the_plan" : "Please select the plan",
        "enter_company_code" : "Please enter the Company Code",
        "enter_code" : "Please enter the Code",
        "enter_valid_coupon_code" : "Please enter the valid coupon code",
        "coupon_alreadt_taken" : "This coupon code already taken",
        "error_updating_plan" : "Error in updating plan",
        "unit" : "unit",
        "referrals" : "Referrals",
        "sel_coupon" : "Please Select the Coupon",
        "share_val_0_99" : "Share Value should be in between 0 to 99",
        "enter_share_val" : "Please Enter the Share Value",
        "valid_commission_per" : "Please Enter Valid Commission Percentage",
        "enter_commission_per" : "Please Enter the Commission Percentage",
        "enter_commission_name" : "Please Enter the Commission Name",
        "sync_successfully" : "Users Sync Successfully",
        "room_deleted_suc" : "Room Deleted Successfully",
        "lic_deleted_suc" : "License Deleted Successfully",
        "female" : "Female",
        "male" : "Male",
        "insta_conference" : "Insta Conference",
        "quick_meeting_by" : "Quick Meeting By",
        "in_lobby" : "In Lobby",
        "switch_to_broadcast" : "Switch to Broadcast",
        "Broadcasters_5" : "Broadcasters (5)",
        "Participants_5" : "Participants (5)",
        "search_participants" : "Search participants",
        "host_controls" : "Host Controls",
        "deleteroom" : "delete room",
        "pm" : "PM",
        "am" : "AM",
        "email_exist_inroom" : "Email Already Existed in this Room",
        "type_a_message" : "Type a message...",
        "please_enter_user_limit" : "Please Enter User Limit",
        "user_limit_exceeded" : "User Limit is exceeded",
        "sel_country_code" : "Please select country code",
        "available_storage" : "Available Storage",
        "enter_phone_number" : "Enter Phone Number",
        "startdate_less_enddate" : "please select start date less than end date",
        "havent_add_data" : "You haven't added any data yet, Please add data.",
        "professional_skills" : "Professional Skills",
        "enter_prof_skills" : "Enter Professional skills",
        "Present_Invitations" : "Present Invitations",
        "sending" : "Sending...",
        "sent" : "Sent",
        "invite_10ppl_only" : "You can invite only up to 10 People",
        "join_meeting" : "Join Meeting",
        "enter_doc_title" : "Enter Document Title",
        "meeting_name" : "Meeting Name",
        "select_the_weeks" : "Please Select the weeks",
        "grade" : "Grade",
        "coference_ID" : "Coference ID...",
        "dont_find_room_meetingid" : "We dont find any room, which is associated with this meeting id",
        "wireless_share" : "Wireless Share",
        "video_layout" : "Video Layout",
        "speaker_screen" : "Speaker Screen",
        "presentation_screen" : "Presentation Screen",
        "multiple_display" : "Multiple Display",
        "hellow" : "hellow",
        "enter_passcode" : "Enter the passcode",
        "another_meeting_stillon" : "Another meeting is still on, you cannot start this meeting right now",
        "SIP_device" : "SIP Device",
        "instavc_whiteBoard" : "InstaVC WhiteBoard",
        "speaker" : "Speaker",
        "instavc_notepad" : "InstaVC Notepad",
        "promote_in" : "Promote In",
        "guest" : "Guest",
        "breakout_rooms" : "Breakout Rooms",
        "stop_broadcast" : "Stop Broadcast",
        "noise_suppress" : "Noise Suppression",
        "echo_cancel" : "Echo Cancellation:",
        "enter_name_here" : "Enter Name Here",
        "Viewer" : "Viewer",
        "SIP_user" : "SIP User",
        "remove_participants" : "Remove Participants",
        "disable_audio_video" : "Disable Remote Audio & Video",
        "disable_audio" : "Disable Audio",
        "enable_audio" : "Enable Audio",
        "disable_video" : "Disable Video",
        "enable_video" : "Enable Video",
        "audio_video" : "Audio & Video",
        "audio_only" : "Audio Only",
        "video_only" : "Video Only",
        "outlook_addins" : "Outlook Add-ins",
        "install_microsoft" : "How to install and manage add-ins in Microsoft Outlook",
        "outlook_step1" : "Step 1: Copy Link & open Outlook",
        "first_copy_link" : "First copy the link",
        "add_plugin_oulook" : "from here to add the plugin to your outlook.",
        "outlook_step2" : "Step 2: Get Add-ins",
        "outlook_step2_para" : "Now open outlook and under Home tab you can find an option Get Add-ins. Click on it to view add-ins and other custom options. ",
        "outlook_step3" : "Step 3: My Add-ins",
        "outlook_step3_para" : "You can now view the add-ins page with left navigation. Go to My add-ins to manage your add-ins.",
        "outlook_step4" : "Step 4: Add Custom Add-ins",
        "outlook_step4_para" : "Here you can find a dropdown Add Custom Add-in under custom add-ins in My add-ins page. Select Add from URL from the drop down.",
        "outlook_step5" : "Step 5: Add Private Add-in from URL",
        "outlook_step5_para" : "You can now see a pop up asking to Add private Add-in from URL. Now paste the link you copied in (Step 1) in the input field and click OK, that installs the add-in to outlook. ",
        "outlook_step6" : "Step 6: InstaVC Added in My Add-ins",
        "outlook_step6_para" : "After adding, you can check the add in added to your My Add-ins List. From here you can manage your add-ins further.",
        "outlook_step7" : "Step 7: Close Outlook and reopen",
        "outlook_step7_para" : "After installing, make sure you close the complete outlook once and re open again to see the add-in.",
        "outlook_step8" : "Step 8: Go to New Items",
        "outlook_step8_para" : "After opening outlook, You can now go to New Meetings &#62; Meeting to see the InstaVC add in added to outlook.",
        "outlook_step9" : "Step 9: Open InstaVC Meeting",
        "outlook_step9_para" : "Once you open meetings, you will be opened with a compose mail window along with InstaVC Meeting add-in enabled in the top bar under meetings tab.",
        "outlook_step10" : "Step 10: Create Schedule",
        "outlook_step10_para" : "From the compose mail layout, click on Instavc meetings to create a new schedule. you will be opened with InstaVC Meeting Login on the right side if it's a first time. For the users who already logged in , they will be directed to rooms list and can create a schedule.",
        "outlook_step11" : "Step 11: Open InstaVC Meeting",
        "outlook_step11_para" : "You can create a schedule on the left side and select a room on the right side to schedule meeting in a particular room. Once you create a schedule, you can see the Start Meeting button now. From here you can click and start the meeting.",
        "outlook_step12" : "Step 12: View Schedule in Calendar",
        "outlook_step12_para" : "Once you have created schedule, the next time when you open calendar, you can view your schedules in the calendar as well.",
        "outlook_step13" : "Step 13: Schedule Created",
        "outlook_step13_para" : "Once you create a schedule, you can then see the composed mail along with a Start Meeting button on the right side.",
        "sel_declaration" : "Please Select the Declaration",
        "enter_company_code" : "Please Enter the Company Code",
        "invalid_company_code" : "Invalid Company Code",
        "affiliate_acc" : "Your affiliate account is pending approval.",
        "aff_acc_approved" : "Your affiliate account is approved.",
        "aff_acc_rej" : "Your affiliate account is rejected.",
        "aff_TC" : "Affiliate Terms and Conditions",
        "ref_par_agreement" : "REFERRAL PARTNER AGREEMENT",
        "aff_partner_agreement" : 'This Partner Agreement, consisting of this Cover Page and the attached Business Terms (collectively, this "Agreement"), is made and entered into by and between PeopleLink Unified Communications Pvt Ltd (referred to as PeopleLink) and “Partner” or “You”. "Partner" or "You" means the person or company (including Company Name and other information) detailed in the Form submitted to. The Agreement shall become binding once You complete and submit the Referral Partner Program Form ("Form") to indicate Your acceptance of this Agreement. PeopleLink and Partner are sometimes referred to as a "Party", and together as the "Parties."',
        "background" : "Background",
        "aff_market_advertise" : 'PeopleLink offers InstaVC Unified communication services and solutions. Partner wishes to promote, market and advertise PeopleLink products and solutions to potential PeopleLink customers ("Referrals") through its website(s) and other marketing channels, in accordance with this Agreement.', 
        "agreement" : "Agreement",
        "aff_parties_agree" : "The Parties agree as follows:",
        "heading1" : "PROGRAM COMMITMENTS",
        "heading2" : "QUALIFIED REFERRALS, COMMISSIONS.",
        "heading3" : "TERM AND TERMINATION.",
        "heading4" : "GENERAL",
        "the_program" : "The Program. ",
        "to_participate_program_partner" : 'To participate in the Program Partner must complete the online application for participation in the Program found on PeopleLink or its associated websites ("Site") and enter this Agreement. PeopleLink may accept or reject any application at its sole discretion.',
        "aff_str11" : "Legal Agreements.",
        "aff_str12" : "Promotion, Referral Activities:",
        "aff_str13" : "Prohibited Activities.",
        "aff_str14" : "Permissible Use of PeopleLink Marks",
        "aff_str15" : "Liabilities.",
        "aff_str16" : "Customer Relations.",
        "aff_str11_para" : "As part of its participation in the Program and in acting as PeopleLink’s Partner, Partner hereby agrees and consents to the terms of this Agreement and the Program, the Guidelines, and any other requests and rules set by PeopleLink from time to time, in its reasonable discretion, in connection with Partner's ongoing participation in the Program and promotion of PeopleLink products, Solutions and Service to Referrals. In all its activities under this Agreement, and specific such activities relating to the Partner's promotion of PeopleLink, Partner shall cooperate with PeopleLink and act in good faith. By entering this Agreement Partner further recognizes and accepts the terms and rules set in PeopleLink’s Terms of Service",
        "aff_str12_para" : "Partner agrees to engage in continued, active promotion of the PeopleLink Products and Solutions in various marketing channels using the Licensed Marks and Marketing Materials, and do so in compliance with the terms of this Agreement.",
        "aff_str13_para" : 'Partner agrees not to associate Marketing Materials with content that is unlawful in any manner, or which is otherwise harmful, threatening, defamatory, obscene, offensive, harassing, sexually explicit, violent, discriminatory, or otherwise objectionable in PeopleLink’s sole discretion. Partner agrees not to send unsolicited electronic messages to multiple unrelated recipients ("Spamming") in promoting the PeopleLink services and solutions, or otherwise to engage in any other form of mass electronic communications prohibited by law in connection with activities contemplated under this Agreement.',
        "aff_str14_para1" : "Partner expressly agrees to comply with all the terms herein in using the Licensed Marks and in creating Marketing Materials.",
        "aff_str14_para2" : "Through the Guidelines and otherwise, PeopleLink shall provide specifications and other instructions from time to time as to Partner's permissible use of the Licensed Marks in creating Marketing Materials and promoting the PeopleLink Service. Partner further agrees to comply with all such specifications and instructions.",
        "aff_str14_para3" : "Partner shall ensure that all Licensed Marks appearing on its Marketing Materials are in the form approved by PeopleLink in the Guidelines or otherwise, shall not modify any PeopleLink Marks or otherwise substantially modify other Marketing Materials contrary to reasonable instructions provided by PeopleLink, and shall further comply with reasonable instructions from PeopleLink as to the form, content and display of Marketing Materials. Upon termination of this Agreement for any reason whatsoever, or upon written request by PeopleLink, the license granted herein shall expire and Partner shall immediately cease all its activities under this Agreement.",
        "aff_str15_para" : "Partner shall be solely responsible for its operations in acting under this Agreement, including, without limitation, the legality of Partner's operations and materials, created and used in connection with this Agreement. Except for a claim alleging that a PeopleLink Mark violates a third party's trademark rights, PeopleLink is not responsible for the development, operation or content of Partner's Marketing Materials and Partner agrees to defend, indemnify and hold PeopleLink harmless against any claims, actions, causes of action, damages, or expenses (including attorney fees) relating to the development, operation, content and maintenance of Partner's Marketing Materials.",
        "aff_str16_para" : "During and after the Term, PeopleLink shall be the exclusive owner of all relations created via Partner among PeopleLink and Referrals with respect to the PeopleLink Service, including any and all information identifying Referrals who contract with PeopleLink for the use of the PeopleLink Service. The Terms, Privacy Policy, and PeopleLink's rules and procedures for the PeopleLink Service will apply to these Referrals and may be changed by PeopleLink without prior notice to Partner, and Partner agrees to convey to Referrals the nature of their relations with PeopleLink under the Terms.",
        "aff_str20_para1" : "referred by Partner to PeopleLink and who complete the sign-up procedure in accordance with the procedure described in Section 3(b) below; ",
        "aff_str20_para2" : "of whom PeopleLink has no record in connection with the PeopleLink Service, or who are not, at the time referred to PeopleLink by Partner, in any contractual relations or ongoing negotiations with PeopleLink in connection with the PeopleLink Service;",
        "aff_str20_para3" : "who accept the Terms and acquire, at a Referral's own discretion and without receiving any monetary or other incentive from Partner, at least annual subscriptions of any of the following PeopleLink Service plan bundles listed on PeopleLink websites or otherwise (iv) who are not rejected by PeopleLink, and make at least one payment (annual prepay) or three payments (annual term, monthly billing) to receive the PeopleLink Service.",
        "aff_str21" : "Referral Procedure.",
        "aff_str22" : "Commissions. Responsibilities.",
        "aff_str221" : "Referral Fees.",
        "aff_str222" : "Ongoing Sales From A Referral",
        "aff_str223" : "Associated charges.",
        "aff_str23" : "Sales/Commissions Reports.",
        "aff_str21_para" : "Each Referral shall be referred to PeopleLink by Partner through an online url provided by PeopleLink to Partner, PeopleLink shall be responsible for the sales process to all Referrals, subject to the Parties' continued good-faith cooperation in promoting the sales process to Referral.",
        "aff_str22_para" : "PeopleLink shall collect all fees from Referrals for the PeopleLink Service directly from Referrals.",
        "aff_str22_para1" : "Upon a Referral becoming a Qualified Referral, PeopleLink shall pay Partner referral fees in arrears based on the listed fees located at ",
        "aff_str22_para12" : "(excluding any discounts) payable by the Qualified Referral. Such Referral Fees shall become payable and be paid to Partner within approximately (<add) ninety (90) days of the Paid service start date granted payment obligations are achieved.",
        "aff_str22_para2" : "- Partner shall receive referral fees for additional sales from a Qualified Referral that take place within first 90 days of the date the referral was initially submitted.",
        "aff_str22_para3" : " Partner shall be responsible for payment of all taxes, duties, governmental charges and other like charges levied on the Referral Fees, and Partner shall indemnify, defend and hold PeopleLink harmless from and against any claims arising out or relating to all charges emanating from PeopleLink's payment of Referral Fees.",
        "aff_str23_para" : "PeopleLink shall provide Partner in their online dashboard report summarizing the sales activities of Partner and its commissions for Qualified Referrals derived from Referrals. PeopleLink shall not reveal the names or other personal information about Referrals and Qualified Referrals.",
        "aff_str31" : "Initial Term.",
        "aff_str32" : "Renewal Term.",
        "aff_str33" : "Early Termination.Without Cause.",
        "aff_str34" : "For Cause.",
        "aff_str35" : "Effect of Termination.",
        "aff_str31_para" : 'This Agreement shall become effective as of the Effective Date and shall continue for twelve (12) months thereafter ("Initial Term"), unless PeopleLink rejects Partners application to participate in the Program',
        "aff_str32_para" : 'Following the expiration of the Initial Term, this Agreement will be automatically renewed for additional consecutive terms of twelve (12) months (each, "Renewal Term"), unless a Party gives written notice of termination to the other Party at least thirty (30) days prior to the end of the Initial Term or any Renewal Term.',
        "aff_str33_para" : "PeopleLink shall have the right to terminate this Agreement at any time for any or no reason by giving five (5) days prior written notice to Partner.",
        "aff_str34_para" : "Either Party may terminate this Agreement at any time, effective immediately upon written notice to the other Party who has materially breached this Agreement, provided that prior to terminating this Agreement the terminating Party shall provide written notice of such material breach and thirty (30) days opportunity for the breaching Party to cure such breach.",
        "aff_str35_para" : "From and following the date of termination of this Agreement Partner's rights under this Agreement shall terminate, and Partner shall not be entitled to receive any Referral Fees or any other payments under this Agreement other than commissions or payments earned or accrued prior to termination of this Agreement.",
        "aff_str41" : "Assignment.",
        "aff_str42" : "Intellectual Property Rights.",
        "aff_str43" : "No Waiver.",
        "aff_str44" : "Limited Warranty.",
        "aff_str45" : "Disclaimer of Warranty.",
        "aff_str46" : "Limitation of Liability.",
        "aff_str47" : "Independent Contractors.",
        "aff_str48" : "Indemnification.",
        "aff_str49" : "Force Majeure.",
        "aff_str410" : "Entire Agreement; Severability.",
        "aff_str411" : "Counterparts; Notices.",
        "aff_str412" : "Governing Law; Jurisdiction; Dispute Resolution.",
        "aff_str41_para" : "PeopleLink may assign this Agreement at any time. Partner may not assign or transfer this Agreement without PeopleLink's prior written consent, such consent not to be unreasonably withheld.",
        "aff_str42_para" : "All intellectual property rights (such as but not limited to trademarks, trade names, logos, patents, copyrights, domain names and derivative rights) in PeopleLink Marks, the PeopleLink Service and related content and technology around the world (PeopleLink IP Rights) are and will remain the exclusive property of PeopleLink and its subsidiary companies. Partner's right to use the Licensed Marks is at the discretion of PeopleLink and is subject to Partner's compliance with the terms of this Agreement, Guidelines, and with all applicable laws and regulations. Partner agrees to (a) not use any PeopleLink IP Rights in any manner reasonably likely to breach this Agreement; (b) not do anything contesting or impairing any PeopleLink IP Rights; (c) not create or obtain any intellectual property rights (such as but not limited to trademarks, trade names, logos, patents, copyrights, domain names and derivative rights) that are substantially similar to any PeopleLink IP Rights; (d) promptly notify PeopleLink of any unauthorized use of any PeopleLink IP Rights of which Partner has actual knowledge; and (e) always use the Licensed Marks and any other PeopleLink Marks in compliance with the Guidelines. PeopleLink may perform periodic reviews of any Marketing Materials presented by Partner, and shall have the exclusive authority and discretion to order the removal and/or amendment of any Marketing Materials presented by Partner.",
        "aff_str43_para" : "Either Party's failure to enforce the other Party's strict performance of any provision of this Agreement will not constitute a waiver of the first Party's right to subsequently enforce such provision or any other provision of this Agreement.",
        "aff_str44_para" : "Both Parties warrant that at all times during the Term they will comply with all applicable laws, regulations, codes of practice, as well as this Agreement, the Terms, Privacy Policy and Guidelines. During the Term and after its termination for any reason whatsoever, Partner expressly undertakes not to do anything that might reasonably be expected to damage the business, interests or reputation of PeopleLink and will not make, publish or allow to be made or published any disparaging remarks concerning PeopleLink, its representatives, or the PeopleLink Service.",
        "aff_str45_para" : "Other than PeopleLink's express warranty under the previous subsection (e), PeopleLink makes no other warranty, express or implied, of any kind and PeopleLink expressly disclaims any warranties and conditions, including but not limited to any implied warranty of merchantability, fitness for a particular purpose, availability, security, title, and/or non-infringement of the subject matter of this Agreement.",
        "aff_str46_para" : "Neither PeopleLink nor any officer, employee, director or any other representative of PeopleLink shall be liable towards Partner or towards any third party, under or in connection with this Agreement or its termination, in contract, pre-contract, tort or otherwise for (i) any economic loss (including loss of revenues, profits, contracts, business or anticipated savings) or (ii) any loss of goodwill or reputation. Such losses include, without limitation, any special, indirect, incidental, statutory, punitive or consequential losses or damages as well as any losses or damages caused by interruption of operations. Notwithstanding any other circumstances or understandings surroundings any relations among the Parties, PeopleLink's entire liability to Partner under this Agreement shall not exceed INR (Indian Rupees One thousand only) for any and all claims for damages of any kind made by Partner under this Agreement, and by entering this Agreement Partner recognizes the limitations herein on PeopleLink's liability.",
        "aff_str47_para" : "The Parties herein act on their own behalf as independent contractor. Nothing in this Agreement shall create any joint venture, agency, franchise, sales representative, employment or any other relationship between the Parties beyond the relations set out in this Agreement, and Partner is expressly precluded from acting on PeopleLink's behalf. Partner's display of Licensed Marks under this Agreement, other content presented by Partner, or contact among Partner and third parties shall not misrepresent the relations described herein.",
        "aff_str48_para" : 'Partner will indemnify, defend and hold PeopleLink and its subsidiaries, affiliates, officers and employees (the "PeopleLink Indemnified Parties") harmless from and against any costs, liabilities, losses and expenses (including but not limited to reasonable attorneys fees) resulting from any claim, suit, action, demand or proceeding brought by any third party against the PeopleLink Indemnified Parties arising from any of the following: (i) a breach of the Agreement by Partner; (ii) the negligence, gross negligence or willful misconduct of Partner or its employees, agents or contractors; or (iii) a failure by Partner or its employees, agents, contractors or invitees to comply with the laws and regulations referenced hereinbefore.',
        "aff_str49_para" : 'A Party shall not be obliged to perform any of its obligations herein if it is prevented from doing so by a situation of force majeure. "Force majeure" events shall include events beyond the reasonable control of the Parties, including acts of God, acts of government, acts of nature, strikes or riots, as well as improper performance by PeopleLinks suppliers or defects in objects, materials or software of third parties. If a situation of force majeure lasts for more than thirty (30) days, either Party may terminate this agreement upon written notice to the other Party.',
        "aff_str410_para" : "This Agreement represents the entire agreement among the Parties regarding the subject matter thereof and the Parties' respective obligations and commitments herein. No other documents, or oral or written agreements among the Parties reflect in any way on the agreements laid out in this Agreement, and this Agreement may not be modified except in a writing executed by both parties. . Whenever possible, each provision of this Agreement shall be interpreted in such a manner as to be effective and valid under applicable law, but if any provision of this Agreement shall be prohibited by or invalid under applicable law, such provision shall be ineffective only to the extent of such prohibition or invalidity, without invalidating the remainder of such provision or the remaining provisions of this Agreement.",
        "aff_str411_para" : "This Agreement may be signed in counterparts and such counterparts shall be valid and binding on the parties hereto with the same effect as if original signatures had been exchanged.",
        "aff_str412_para" : "This Agreement shall be governed by the laws of the jurisdiction of Hyderabad, Telangana, India, without giving effect to any principles of conflicts of law. Jurisdiction shall lie exclusively in the District Courts of Hyderabad, Telangana, India. The sole and exclusive jurisdiction and venue for any litigation arising out of this Agreement shall be an appropriate district or state court located at Hyderabad, Telangana, India, and the Parties agree not to raise, and hereby waive, any objections or defenses based upon venue or forum non convenience. Prior to initiating any legal action arising under or relating to this Agreement, a Party shall provide the other Party written notice of a dispute and the Parties shall actively and in good faith negotiate with a view to speedy resolution of such dispute within thirty (30) business days of the receipt of such notice.",
        "aff_str40" : "Modification of Agreement.",
        "aff_str40_para" : "PeopleLink may modify this Agreement from time-to-time at its reasonable discretion by posting a change on the Site (at www.peoplelinkvc.com/affiliatestermsofservice) or notifying Partner via email. If Partner objects to any such change, Partner may terminate this Agreement for cause. Partner's continued participation in the Program following receipt of notice about changes to this Agreement shall constitute binding acceptance of this Agreement as amended.",
        "qualified_ref" : "Qualified Referrals",
        "mean_referrals" : "mean Referrals",
        "apply" :"Apply",
        "company_code_validate" : "Please enter the company code to validate",
        "golive_company_code" : "Golive Company Code",
        "accept_TC" : "I accept the above terms and conditions",
        "by_sub_referral" : "By submitting the Referral Partner Application, Partner represents and warrants that it has read, understands and accepts this Agreement.",
        "term" : "Terms",
        "found_at" : "found at",
        "our_privacy_policy" : "and our Privacy Policy",
        "privacy_policy" : "Privacy Policy",
        "as_applicable" : "as applicable.",
        "screen-sharing" : "screen sharing",
        "whiteboard_access" : "whiteboard access",
        "Document_access" : "Document access",
        "share_the_screen" : "share the screen!",
        "to_collaborate" : "to collaborate!",
        "share_document" : "share document!",

        "exit_or_end" : "Exit or End Meeting?",
        "exit_from_meeting" : "Exit from Meeting?",
        "are_sure_exit_meeting_everyone" : "Are you sure you want to exit from the meeting? You can either end the meeting for everyone or you can exit from the meeting.",
        "are_sure_exit_meeting" : "Are you sure you want to exit from the meeting?",
        "exit_meeting" : "Exit Meeting",
        "end_for_all" : "End Meeting for all",
        "end_meeting" : "End Meeting",
        "will_let_alone_exit" : "will let you alone exit or leave from the meeting.",
        "will_end_for_everyone" : "will end the meeting for everyone in the conference (All participants & attendees).",
        "user_already_exists" : "Users Already Exists",
        "already_user_avail" : "There are already some users available in the conference. ",
        "if_like_continue" : "If you would like to continue click on ",
        "force_start_meetinh" : "Force Start Another Meeting ",
        "otherwise_click" : "otherwise click on ",
        "to_go_back" : "to go back. ",

        //New by Keerthi - 17th Sep 2020
        "lang_settings" : "Language Settings",
        "lang_info" : "Choose your preferred language to use the application.",
        "select_language" : "Select Language",
        "settings_languages" : "Languages",

        "rec_plugin" : "Recording Plugin",
        "rec_heading1" : "How to install and manage InstaVC Screen-Video Recorder with the plugin",
        "rec_step1" : "Step 1: Copy & open Link in Chrome",
        "rec_step1_para" : "First copy the link ",
        "rec_step1_para_br" : "from here and open it in chrome to add the plugin to your browser. Click on ",
        "rec_step1_para_bold" : "Add to Chrome",
        "rec_step1_para_ct" : "to add this extension.",

        "rec_step2" : "Step 2: Pop Up for Permission",
        "rec_step2_para_br" : "When you click on Add to chrome, here appears a popup asking for permission to display notification access your content. Click on ",
        "rec_step2_para_bold" : "Add Extension",
        "rec_step2_para_ct" : "to proceed.",

        "rec_step3" : "Step 3: Extension Added to Chrome",
        "rec_step3_para" : "When the extension is added to chrome, it shows a window giving you an information with a plugin icon and how to manage this plugin from here.",

        "rec_step4" : "Step 4: Pin the plugin on the toolbar",
        "rec_step4_para_br" : "To pin the plugin to your toolbar, you need to click on manage extensions, go to ",
        "rec_step4_para_bold" : "InstaVC Screen-Recording",
        "rec_step4_para_ct" : "and click on the pin icon to add.",

        "rec_step5" : "Step 5: Extension/Plugin Pinned to Toolbar",
        "rec_step5_para" : "Once the extension is pinned, you can now see an instavc recording icon in your browser toolbar and start using it for easy access." ,

        "rec_step6" : "Step 6: InstVC Recorder Setup",
        "rec_step6_para_br" : "Once you have installed the plugin and the next time when you open it, you can now see the ",
        "rec_step6_para_bold" : "InstaVC Recorder Setup",
        "rec_step6_para_ct" : "page to enable or disable the recording tools. By default both the options will be enabled. Click on ",
        "rec_step6_para_bold1" : "Next",
        "rec_step6_para_ct1" : "to continue.",

        "rec_step7" : "Step 7: Additional Permissions",
        "rec_step7_para_br" : "Once the Next button is clicked, system will then show you a popup asking for additional permissions to read and change your data. Click ",
        "rec_step7_para_bold" : "Allow",
        "rec_step7_para_ct" : "to continue.",

        "rec_step8" : "Step 8: Permissions Allowed",
        "rec_step8_para" : "After allowing the permission, this page now gets frozen or in a disabled mode from where you can now start exploring recording options now.",

        "rec_heading2" : "Desktop Screen-Recording Plugin",

        "rec_step9" : "Step 9: Desktop Screen-Recording",
        "rec_step9_para_br" : "After allowing all permissions, you can now access recording menus from the plugin extension in your toolbar. Click on it to see further options. Here we have selected ",
        "rec_step9_para_bold" : "Desktop Screen Recording",
        "rec_step9_para_ct" : "and click on the",
        "rec_step9_para_bold1" : "Record",
        "rec_step9_para_ct1" : "button to start.",

        "rec_step9a" : "Step 9(a): Share your Screen Popup",
        "rec_step9a_para_br" : "Now the system shows up this popup asking to select the contents of your screen, whether its an Entire Screen or only application window. Click on the tab which you want to share and click on ",
        "rec_step9a_para_bold" : "Share",
        "rec_step9a_para_ct" : "button and go to that screen which you want to start recording.",

        "rec_step9b" : "Step 9(b): Desktop Screen Recording Started",
        "rec_step9b_para_br" : "The Desktop Screen recording is now started and you can see a blink in the toolbar while its in recording mode. Also, you can see a tab at the bottom showing buttons for ",
        "rec_step9b_para_bold" : "Stop Sharing",
        "rec_step9b_para_ct" : "or",
        "rec_step9b_para_bold1" : "Hide",
        "rec_step9b_para_ct1" : ". You can see the bottom message on any screen you are opening until you are in the desktop screen recording mode.",

        "rec_step9c" : "Step 9(c): Restart / Pause or Stop Desktop Screen Recording",
        "rec_step9c_para_br" : "Once you want to stop Recording. Click on the plugin in the toolbar and here you can see the options to ",
        "rec_step9c_para_bold" : "Restart, Pause",
        "rec_step9c_para_ct" : "and",
        "rec_step9c_para_bold1" : "Stop",
        "rec_step9c_para_ct1" : "buttons. You can click any of those accordingly.",

        "rec_step9d" : "Step 9(d):Recent Recording View",
        "rec_step9d_para_br" : "Once you stop the recording from the toolbar, the recent recording will now automatically open the recording in a new tab along with Title and ",
        "rec_step9d_para_bold" : "Download",
        "rec_step9d_para_ct" : "option in the top and the recording details in the right column.",
        
        "rec_heading3" : "Browser Tab Screen-Recording Plugin",

        "rec_step10" : "Step 10: Browser Tab Screen-Recording",
        "rec_step10_para_br" : "When we want to select ",
        "rec_step10_para_bold" : "Browser Tab",
        "rec_step10_para_ct" : "option for recording your browser screen. First open your screen(url) in chrome whichever you want to record and then open the plugin and click on Browser Tab, adjust microphone options and then click",
        "rec_step10_para_bold1" : "Record",
        "rec_step10_para_ct1" : "button to start recording.",

        "rec_step10a" : "Step 10(a): Browser Tab is being Recorded",
        "rec_step10a_para" : "Once the Browser tab is started recording, you can see a red dot blinking in your toolbar which indicates the tab is being recorded and also a blue box blinking in your browser title bar",
        
        "rec_step10b" : "Step 10(b): Record another tab",
        "rec_step10b_para_br" : "At the time of current screen recording, if you open another url and open the plugin, it will show you the recording status along with an option",
        "rec_step10b_para_bold" : "Record This Tab",
        "rec_step10b_para_ct" : "if you want to change the recording scree to the new url. Meanwhile the blue box blinking in the title bar of the browser indicates the tab that is being recorded at the moment.",

        "rec_step10c" : "Step 10(C): Stop and View recording",
        "rec_step10c_para_br" : "Later, when you stop the recording, the browser itself opens the recording in a new tab along with Title and",
        "rec_step10c_para_bold" : "Download",
        "rec_step10c_para_ct" : "Option at the top and the recording details in the right column.",

        "rec_heading4" : "Webcam Recording",

        "rec_step11" : "Step 11: Start Webcam Screen Recording",
        "rec_step11_para_br" : "When you start with Webcam screen recording, make sure to select the microphone & camera settings among the devices detected by the plugin. and then click on the",
        "rec_step11_para_bold" : "Record",
        "rec_step11_para_ct" : "button to start with.",

        "rec_step11a" : "Step 11(a): Webcam opens Self Video and starts Recording",
        "rec_step11a_para_br" : "The recording opens a web page with yourself video with",
        "rec_step11a_para_bold" : "Pause",
        "rec_step11a_para_ct" : "and",
        "rec_step11a_para_bold1" : "Stop",
        "rec_step11a_para_ct1" : "recording options at the bottom.",

        "rec_step11b" : "Step 11(b): View Webcam Recorded Video",
        "rec_step11b_para" : "When you stop the recording in the previous screen, the browser then opens the recording in a new tab to view and download along with the recording details.",
        
        "rec_heading5" : "Top Menus in Recording Plugin",

        "rec_step12" : "Step 12: Navigation Menus",
        "rec_step12_para_br" : "The plugin has few navigation menus to fetch your data as well as manage different actions related to the plugin. One of them is a",
        "rec_step12_para_bold" : "Hamburger Menu",
        "rec_step12_para_ct" : "Click on it to open.",

        "rec_step12a" : "Step 12(a): Open Hamburger Menu",
        "rec_step12a_para_br" : "On click of",
        "rec_step12a_para_bold" : "Hamburger Menu",
        "rec_step12a_para_ct" : ", you can find 2 tabs named",
        "rec_step12a_para_bold1" : "My Recordings",
        "rec_step12a_para_ct1" : "and",
        "rec_step12a_para_bold2" : "Options",
        "rec_step12a_para_ct2" : "which manages all the data you have been recording and options you have enabled or disabled for that plugin. Click on",
        "rec_step12a_para_bold3" : "My Recordings",
        "rec_step12a_para_ct3" : "to see the videos you have recorded till date.",

        "rec_step12b" : "Step 12(b): Access My Recordings",
        "rec_step12b_para_br" : "There is another way of accessing",
        "rec_step12b_para_bold" : "My Recordings",
        "rec_step12b_para_ct" : ", page from the top menu in the plugin which can be considered as a shortcut to navigate.",
        
        "rec_step12c" : "Step 12(c): My Recordings",
        "rec_step12c_para" : "Here will be the detailed list of videos(recordings) you have recorded till date. Each video contains the date, duration, size of the video along with delete icon in the thumbnail view. When you click on any video it opens the details in the view recording page. Note that all the videos will be stored locally here.",

        "rec_step12d" : "Step 12(d): Delete All recordings",
        "rec_step12d_para_br" : "Looking at My recordings Page, there is a more icon in the top right corner which opens the",
        "rec_step12d_para_bold" : "Delete All",
        "rec_step12d_para_ct" : "option to easily delete all recordings with a single click.",
        
        "rec_step12e" : "Step 12(e): Side Navigation",
        "rec_step12e_para_br" : "Instead of opening the plugin again and change, You can directly navigate to another tab from here using the Hamburger Menu in the top left corner of the page that opens the navigation to navigate to the",
        "rec_step12e_para_bold" : "Options",
        "rec_step12e_para_ct" : "page",

        "rec_heading6" : "Plugin Options",

        "rec_step13" : "Step 13: Plugin Options",
        "rec_step13_para_br" : "On click on Hamburger Menu, you can find another tab named Options which manages all the options you have enabled or disabled for that plugin. Click on",
        "rec_step13_para_bold" : "Options",
        "rec_step13_para_ct" : "to see the options that are enabled for you.",

        "rec_step13a" : "Step 13(a): Recorder Options",
        "rec_step13a_para" : "Recorder Options ask you for various options like video storage, Notifications, Keyboard Shortcuts",

        "rec_step13b" : "Step 13(b): Recorder Options",
        "rec_step13b_para" : "Recorder Options contain various other options like Recording Settings and advanced.",

        "rec_heading7" : "Plugin Notifications",

        "rec_step14" : "Step 14: Plugin Notifications",
        "rec_step14_para" : "On the top navigation part, you can find a bell icon for notifications if any related to instavc screen recording plugin.",

        "rec_step14a" : "Step 14(a): Open Notifications",
        "rec_step14a_para" : "On click on the notification icon, you can find the list of notifications if any or it shows an empty slide in.",

        "rec_heading8" : "Recording Settings",

        "rec_step15" : "Step 15: Settings",
        "rec_step15_para_br" : "Then comes the last navigation menu on the top right corner named",
        "rec_step15_para_bold" : "Settings",
        "rec_step15_para_ct" : "where you can open and manage the recording settings accordingly.",

        "rec_step15a" : "Step 15(a): Advanced Settings Options",
        "rec_step15a_para" : "Here you can adjust the settings based on the Resize tab, Limit resolution and maximum frame rate which are given by plugin.",

        "crteContent_upload_medialist" : "Uploaded Media List",
        "content_upld_desc_txt" : "The files you upload here will be available to users who view content.",
        "content_browse_txt" : "Browse Files to Upload",
        "content_upld_types" : "Files with only .jpg, .jpeg, .png, and .pdf are allowed limiting the max size upto 15MB",

        "video_qlty": "വീഡിയോ ഗുണമേന്മ",
        "audio_qlty": "ഓഡിയോ നിലവാരം",
        "cntnt_shrng": "ഉള്ളടക്ക പങ്കിടൽ",
        "fdb_poor": "പാവം",
        "fdb_good": "കൊള്ളാം",
        "fdb_avg": "ശരാശരി",
        "fdb_excellent": "മികച്ചത്",
        "share_feedback": "നിങ്ങളുടെ ഫീഡ്ബാക്ക് പങ്കിടുക",
        "ovrl_rating": "മൊത്തത്തിലുള്ള റേറ്റിംഗ്",
        "fdb_cmnts": "നിങ്ങളുടെ അഭിപ്രായങ്ങൾ വളരെയധികം വിലമതിക്കപ്പെടുന്നു",
        "no_thnks": "വേണ്ട, നന്ദി",
        "upload_size_max_15mb": "നിങ്ങൾക്ക് പരമാവധി വലുപ്പ പരിധി 15MB ആണ് അപ്‌ലോഡ് ചെയ്യാൻ കഴിയുന്നത്",

        "pastMeeting_feedback" : "ഫീഡ്‌ബാക്ക്",
        "pastMeeting_comments" : "അഭിപ്രായങ്ങൾ",
        
        "microsoft_webplugin" : "Microsoft Outlook വെബ് പ്ലഗിൻ",
        "microsoft_installable" : "മൈക്രോസോഫ്റ്റ് lo ട്ട്‌ലുക്ക് ഇൻസ്റ്റാൾ ചെയ്യാവുന്ന പ്ലഗിൻ",
        "outlook_installable": "Lo ട്ട്‌ലുക്ക് ഇൻസ്റ്റാളുചെയ്യാനാകും",
        "customization_installer": "ഇഷ്‌ടാനുസൃതമാക്കൽ ഇൻസ്റ്റാളർ എങ്ങനെ ആക്‌സസ്സുചെയ്യാം",
        "plugin_step1" : "ഘട്ടം 1: ഫയൽ ഡ download ൺലോഡ് ചെയ്യാൻ ലിങ്കിൽ ക്ലിക്കുചെയ്യുക",
        "click_link_download": "ഫയൽ ഡ Download ൺലോഡ് ചെയ്യുന്നതിനുള്ള ലിങ്ക് ക്ലിക്കുചെയ്യുക",
        "first_click_link": "ആദ്യം ചുവടെയുള്ള ലിങ്കിൽ ക്ലിക്കുചെയ്യുക",
        "to_download_file": "നിങ്ങളുടെ സിസ്റ്റത്തിലേക്ക് ഫയൽ ഡ download ൺലോഡ് ചെയ്യാൻ. ഇൻസ്റ്റാളേഷൻ ആരംഭിക്കുന്നതിന് ഡ download ൺലോഡ് ചെയ്ത ഫയൽ തുറന്ന് ക്ലിക്കുചെയ്യുക",
        "install_txt": "ഇൻസ്റ്റാൾ ചെയ്യുക",
        "plugin_step2" : "ഘട്ടം 2: ഇഷ്‌ടാനുസൃതമാക്കൽ ഇൻസ്റ്റാളുചെയ്‌തു",
        "plugin_step2_para":"Once you start installing, wait untill the file gets installed and you get a popup showing successfully Installed and then close.",
        "plugin_step3" : "ഘട്ടം 3: lo ട്ട്‌ലുക്ക് തുറന്ന് മീറ്റിംഗുകളിലേക്ക് പോകുക",
        "plugin_step3_para" : "ഇൻസ്റ്റാളുചെയ്‌തതിനുശേഷം, മൈക്രോസോഫ്റ്റ് lo ട്ട്‌ലുക്ക് തുറന്ന് മീറ്റിംഗുകളിലേക്ക് പോകുക.",
        "plugin_step3_para1" : "പുതിയ ഇമെയിൽ> മീറ്റിംഗുകളിലേക്ക് പോകുക",
        "plugin_step4" : "ഘട്ടം 4: ഓപ്പൺ മീറ്റിംഗുകൾ",
        "plugin_step4_para" : "മീറ്റിംഗ് വിൻ‌ഡോ ഒരിക്കൽ‌ മുകളിലുള്ള ടാബുകളുടെ പട്ടികയിൽ‌ തുറന്ന നീക്കം ലഭിച്ചാൽ‌ നിങ്ങൾ‌ക്ക് ടാബിൽ‌ ക്ലിക്കുചെയ്യാം",
        "plugin_step4_para1": "പീപ്പിൾലിങ്ക്",
        "plugin_step5": "ഘട്ടം 5: ഷെഡ്യൂൾ കോൺഫറൻസ്",
        "plugin_step5_para": "പീപ്പിൾലിങ്കിന് കീഴിൽ നിങ്ങൾക്ക് ഒരു ഉപ ടാബ് ഉണ്ട്",
        "plugin_step5_para1": "ഷെഡ്യൂൾ കോൺഫറൻസ്.",
        "plugin_step5_para2": "അതിൽ ക്ലിക്കുചെയ്യുക, വിൻഡോയുടെ വലതുവശത്ത് നിങ്ങൾക്ക് ഷെഡ്യൂൾ ഓപ്ഷനുകൾ കാണാൻ കഴിയും. ഇവിടെ നിങ്ങൾക്ക് ഇൻ‌വി‌സി ഷെഡ്യൂളുകൾ‌ മാനേജുചെയ്യാൻ‌ കഴിയും.",
        "plugin_step6": "ഘട്ടം 6: ഷെഡ്യൂളുകൾ കൈകാര്യം ചെയ്യുക അല്ലെങ്കിൽ ഇഷ്ടാനുസൃതമാക്കുക",
        "plugin_step6_para": "റൂമുകളുടെയും സമയങ്ങളുടെയും പട്ടിക പ്രകാരം നിങ്ങൾക്ക് ഷെഡ്യൂളുകൾ മാനേജുചെയ്യാനോ ഇഷ്ടാനുസൃതമാക്കാനോ ഇവിടെ നിന്ന് ഒരു പുതിയ ഷെഡ്യൂൾ സൃഷ്ടിക്കാനും കഴിയും, അത് നിങ്ങളെ ബ്രൗസറിലേക്ക് തിരിച്ചുവിടുകയും കാഴ്ചപ്പാടിലേക്ക് മടങ്ങുകയും ചെയ്യും.",
        "plugin_step6_para1": "ഒരിക്കൽ‌ നിങ്ങൾ‌ക്ക് ഷെഡ്യൂൾ‌ ശരിയാണെങ്കിൽ‌ ക്ലിക്കുചെയ്യാം",
        "plugin_step6_para2": "ലിങ്ക് നേടുക",
        "plugin_step7": "ലിങ്ക് ജനറേറ്റുചെയ്‌തു",
        "plugin_step7_para": "മെയിലിൽ ജനറേറ്റുചെയ്‌തതും രചിച്ചതുമായ ലിങ്ക് ഇവിടെ ലഭിക്കുന്നു, അത് നിങ്ങൾക്ക് ഓൺലൈനിൽ പങ്കിടാനോ അല്ലെങ്കിൽ തുടരാനോ കഴിയും.",
    }
}
