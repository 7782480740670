export const itData = {
     "locale": {
        "home_instavc_build": "Costruire relazioni significative tra le organizzazioni e i dipendenti",
        "home_instavc_conference_platform": "Le Piattaforme di riunione con\nEsperienza utente senza pari",
        "home_instavc_start_conference": "AVVIA LA RIUNIONE | UNISCITI ALLA RIUNIONE | RIUNIONE IMMEDIATA",
        "home_instavc_Hippa": "HIPAA ELIGIBLE E PCI DSS, SOC, ISO\/EIC 27001, ISO\/EIC 27017, ISO\/EIC 27018 E ISO 9001 COMPLIANT.",
        "home_instavc_copyrights": "B) 2018 B7 instaVC B7 PeopleLink Unified Communications Pvt.",
        "home_instavc_terms_agree": "Sono d'accordo con il",
        "home_instavc_terms": "Termini e condizioni",
        "instavc_login": "Accedi",
        "instavc_login_email": "Invia un'e-mail a",
        "instavc_login_password": "Password",
        "instavc_login_button": "Accedi",
        "instavc_login_forgotpassword": "Password dimenticata?",
        "instavc_login_have_account": "Non hai un conto?",
        "instavc_login_signup": "Iscriviti a",
        "instavc_signup": "Iscriviti",
        "instavc_signup_firstname": "Nome",
        "instavc_signup_lastname": "Cognome",
        "instavc_signup_email": "Invia un'e-mail a",
        "instavc_signup_password": "Password",
        "instavc_signup_cpassword": "Conferma la password",
        "instavc_signup_button": "Iscriviti",
        "instavc_signup_already_have_account": "Hai gi\u00e0 un conto?",
        "instavc_signup_login": "Accedi",
        "instavc_signup_fname_error": "Il nome dovrebbe essere solo l'alfabeto",
        "instavc_signup_lname_error": "Il cognome dovrebbe essere solo l'alfabeto",
        "instavc_signup_email_error": "L'e-mail deve essere in un formato adeguato",
        "instavc_signup_password_error": "La password deve essere lunga sei caratteri e combinarne uno maiuscolo, uno minuscolo, una cifra e un carattere speciale",
        "instavc_signup_cpassword_error": "Confermare la password deve corrispondere a quella sopra indicata",
        "instavc_forgotpassword_link": "Ti invieremo un link alla tua email registrata. Basta cliccare sul link e impostare la nuova password",
        "instavc_forgotpassword_email": "Inserisci il tuo indirizzo email registrato",
        "instavc_forgotpassword": "PASSWORD DIMENTICATA",
        "instavc_forgotpassword_button": "PASSWORD DIMENTICATA",
        "instavc_confirmpass_heading": "Creare una nuova password compilando il modulo.",
        "instavc_confirmpass_verificationcode": "Inserire il codice di verifica",
        "instavc_confirmpass_newpassword": "Inserisci una nuova password",
        "instavc_confirmpass_retypepassword": "Ridigitare la nuova password",
        "instavc_confirmpass_button": "CREARE UNA NUOVA PASSWORD",
        "instavc_forgotpassword_signin": "Accedi",
        "instavc_forgotpassword_passwordcreated": "Congratulazioni! La vostra nuova password \u00e8 stata creata, ora potete accedere a instaVC.",
        "instavc_dashboard": "Cruscotto",
        "instavc_dashboard_schedule": "CALENDARIO DELLE RIUNIONI",
        "instavc_dashboard_meetinglist": "REUNIONI COMPLETATE",
        "instavc_dashboard_conferenceplatform": "Le piattaforme di conferenze con un'esperienza utente senza pari",
        "instavc_dashboard_SC_room": "Aviare una riunione",
        "instavc_dashboard_SC_roomlist": "Selezionando la riunione programmata",
        "instavc_dashboard_startconference": "AVVIARE LA RIUNIONE",
        "instavc_dashboard_JC": "Partecipa alla riunione",
        "instavc_dashboard_JC_ID": "tramite ID Conferenza o Programmato",
        "instavc_dashboard_JC_button": "Partecipa alla conferenza",
        "instavc_dashboard_SC": "Aviare una riunione",
        "instavc_dashboard_SC_p2p": "Selezionando una camera dalla vostra lista",
        "instavc_dashboard_instaconference": "CONFERENZA DI INSTA",
        "instavc_dashboard_buynow": "Compra ora",
        "instavc_dashboard_myprofile": "Il mio profilo",
        "instavc_dashboard_settings": "Impostazioni",
        "instavc_dashboard_myadmin": "Il mio amministratore",
        "instavc_dashboard_affiliate": "Il mio Affiliato",
        "instavc_dashboard_logout": "Disconnettiti",
        "conference_myrooms": "Le mie camere",
        "conference_myrooms_host": "Ospite",
        "conference_myrooms_roomhost": "Sei un ospite per questa stanza.",
        "conference_myrooms_search": "Ricerca ...",
        "conference_myrooms_hostuser": "Utente ospitante",
        "conference_myrooms_gotopremium": "vai a premio",
        "conference_myrooms_buynow": "Compra ora",
        "conference_myrooms_renewal": "Rinnovo",
        "conference_setup": "Configurazione",
        "conference_myrooms_renewnow": "Rinnovare ora",
        "conference_myrooms_join": "Iscriviti a",
        "conference_myrooms_setup": "Impostazione della camera",
        "conference_myrooms_sethost_email": "Impostarsi come host, o inserire l'indirizzo e-mail di un utente per farne un host.",
        "conference_myrooms_selfhost": "Impegnati a fare da padrone di casa",
        "conference_myrooms_otherhost": "OPPURE - Se si desidera rendere qualsiasi utente come host, inserire il suo indirizzo e-mail.",
        "conference_myrooms_email": "Indirizzo e-mail",
        "conference_myrooms_cancel": "Annulla",
        "conference_myrooms_apply": "APPLICARE",
        "view_schedule_myrooms": "Le mie camere",
        "view_schedule_search": "Ricerca ...",
        "view_schedule_schedule": "Programmazione",
        "view_schedule_Videorecording": "Registrazioni video",
        "view_schedule_calender": "Di seguito il calendario per controllare gli orari di questa camera",
        "view_recordings": "Registrazioni video",
        "view_recordings_search": "Ricerca",
        "view_recordings_month": "Questo mese",
        "view_recordings_week": "Ultimi 7 giorni",
        "view_recordings_today": "Oggi",
        "no_recordings": "Ancora nessuna registrazione!",
        "no_recordings_message": "Non ci sono video qui, al momento.",
        "no_recordings_startmessage": "Una volta iniziata la registrazione, potete guardare tutti i vostri video qui.",
        "stop_recording": "Volete fermare questa registrazione?",
        "stop_recording_agree": "Accetto",
        "stop_recording_disagree": "Non sono d'accordo",
        "conference_in_use": "La riunione \u00e8 in uso",
        "conference_mode": "Modalit\u00e0 Riunione",
        "large_conference_mode": "Modalit\u00e0 \"Grande Riunione\"",
        "conference_setup_configuration": "Configurazione",
        "conference_setup_generateid": "Nessun ID riunione trovato, generane uno!",
        "conference_setup_regenerate": "Rigenerare",
        "conference_setup_meetingid": "ID riunione",
        "conference_setup_camera": "Telecamera FaceTime HD",
        "conference_setup_defaultmicrophone": "Predefinito - Microfono interno (integrato)",
        "conference_setup_microphone": "Microfono interno (integrato)",
        "conference_setup_volume": "Volume",
        "conference_setup_videomode": "Modalit\u00e0 video",
        "conference_setup_forward": "Diretta",
        "conference_setup_mix": "Mix",
        "conference_setup_invite": "Invitare",
        "conference_setup_invitepeople": "Invitare le persone",
        "conference_setup_invitefriends": "Invitate i vostri amici e colleghi a collaborare.",
        "conference_setup_invite_directlink": "Collegamento diretto alla vostra camera",
        "conference_setup_invite_sharelink": "Condividi questo link con i tuoi partecipanti per farli partecipare alla tua sessione",
        "conference_setup_invite_link": "https:\/\/connect.instavc.com\/conference-guest\/eddca0fc7adcdc61a24dabe02597412a36bb96dac7302f3cedb6aac50169d58614eb46b940b556a9\/f",
        "conference_setup_invitebyemail": "Invito per posta",
        "conference_setup_invite_enteremail": "Inserisci l'indirizzo e-mail preferito per invitare i tuoi partecipanti",
        "conference_setup_invite_emailid": "Indirizzo e-mail",
        "conference_setup_invite_close": "Chiudere",
        "conference_setup_invite_button": "Invitare",
        "conference_setup_advancesettings": "Impostazioni avanzate",
        "conference_setup_changeresolution": "Scegliere la risoluzione",
        "conference_setup_lowbandwidth": "Bassa larghezza di banda",
        "conference_setup_poornetwork": "Povera rete",
        "conference_setup_vga": "VGA - (640x480)",
        "conference_setup_vga_graphics": "Per la risoluzione video VGA (Video Graphics Array)",
        "conference_setup_hd1080": "HD1080p - (1920x1080)",
        "conference_setup_fhd": "Per la risoluzione video ad alta definizione (FHD)",
        "conference_setup_4k": "4K - (3840 x 2160)",
        "conference_setup_4k_fhd": "Per la risoluzione video ad alta definizione (FHD)",
        "conference_setup_sif": "SIF - (320x240)",
        "conference_setup_sif_vr": "Per la risoluzione video SIF",
        "conference_setup_hd720": "HD720p - (1280x720)",
        "conference_setup_hd720_vr": "Per la risoluzione video ad alta definizione (HD)",
        "conference_setup_2k": "2K - (2048C1080)",
        "conference_setup_2k_fhd": "Per la risoluzione video ad alta definizione (FHD)",
        "conference_setup_broadcastoptions": "Opzioni di trasmissione",
        "conference_setup_enable": "Attivare",
        "conference_setup_disable": "Disattivare",
        "conference_setup_reportquality": "Segnala statistiche di qualit\u00e0 per contribuire a migliorare le chiamate vocali e video",
        "conference_setup_havetroubleshoot": "Volete avere una risoluzione dei problemi della riunione, quindi cliccate il link qui",
        "conference_setup_conferencetroubleshoot": "RISOLUZIONE DEI PROBLEMI DELLA CONFERENZA",
        "conference_troubleshoot": "Risoluzione dei problemi",
        "conference_troubleshoot_faq": "FAQ",
        "conference_troubleshoot_emailreport": "EMAIL RAPPORTO",
        "conference_troubleshoot_gotoconference": "VAI ALLA RIUNIONE",
        "conference_troubleshoot_os": "Sistema operativo",
        "conference_troubleshoot_version": "versione",
        "conference_troubleshoot_browser": "Browser",
        "conference_troubleshoot_chromeversion": "Versione cromata",
        "conference_troubleshoot_privatebrowsing": "Navigazione privata",
        "conference_troubleshoot_displayresolution": "Risoluzioni del display",
        "conference_troubleshoot_aspectratio": "Rapporto d'aspetto del display",
        "conference_troubleshoot_hasspeakers": "Il sistema ha degli altoparlanti?",
        "conference_troubleshoot_foundspeaker": "Dispositivi di altoparlanti trovati",
        "conference_troubleshoot_hasmicrophone": "Il sistema ha il microfono?",
        "conference_troubleshoot_foundmicrophone": "Dispositivi microfonici trovati",
        "conference_troubleshoot_haswebcam": "Il sistema ha una webcam?",
        "conference_troubleshoot_foundcameras": "Dispositivi di telecamere trovati",
        "conference_troubleshoot_webcampermission": "Il sito web ha i permessi per la webcam ?",
        "conference_troubleshoot_microphonepermission": "Il sito web ha i permessi per il microfono?",
        "conference_troubleshoot_getUsermedia": "Il browser consente getUserMedia in questa pagina?",
        "conference_troubleshoot_changeoutput_audiodevice": "\u00c8 possibile cambiare i dispositivi audio in uscita?",
        "conference_troubleshoot_changecamera_resolution": "\u00c8 possibile modificare le risoluzioni delle telecamere senza effettuare la nuova richiesta di riunione?",
        "conference_troubleshoot_supportwebrtc": "Il browser supporta WebRTC (1.0 o 1.1)?",
        "conference_troubleshoot_supportortc": "Browser Supporta ORTC (WebRTC 1.1) ?",
        "conference_troubleshoot_turnserver": "Il server TURN \u00e8 raggiungibile?",
        "conference_troubleshoot_turn": "giro:139.59.64.172:443",
        "conference_troubleshoo_coturn": "turno:coturn.instavc.com:443",
        "conference_troubleshoot_replacetracks": "\u00c8 possibile sostituire i binari senza rinegoziare i coetanei?",
        "conference_troubleshoot_record_remoteaudio": "Il vostro browser pu\u00f2 registrare l'audio remoto o elaborare lo streaming audio remoto in WebAudio API",
        "conference_troubleshoot_browsersupports_websocketapi": "I browser supportano le API di WebSockets",
        "conference_troubleshoot_blockwebsocket": "Il vostro sistema ha bloccato il protocollo WebSockets o il server WebSockets non \u00e8 accessibile? No",
        "conference_troubleshoot_supportwebaudioapi": "Il browser supporta le API WebAudio ?",
        "conference_troubleshoot_supportsctp": "Il browser supporta i canali dati SCTP?",
        "conference_troubleshoot_supportsrtp": "Browser supporta i canali dati RTP?",
        "conference_troubleshoot_support_screencapturing": "Questa pagina supporta le API di cattura delle schermate?",
        "conference_troubleshoot_support_multimonitor": "Il Browser supporta la selezione e l'acquisizione di schermi multi-monitor di qualsiasi monitor?",
        "conference_troubleshoot_mobiledevice": "\u00e8 un dispositivo mobile?",
        "conference_troubleshoot_supportscreencap_canvas": "Il browser supporta la cattura dello schermo da Canvas?",
        "conference_troubleshoot_supportscreencap_video": "Il browser supporta la cattura delle schermate da video?",
        "conference_troubleshoot_supportpromisses": "Il browser supporta le promesse?",
        "conference_troubleshoot_ipaddress": "Clicca per mostrare gli indirizzi IP",
        "conference_troubleshoot_stunserver": "Il server STUN \u00e8 raggiungibile?",
        "conference_troubleshoot_cancel": "Annulla",
        "instavc_conference_revoke_fromhost": "Revoca dall'ospite",
        "instavc_conference_startvideoconf_button": "Avvia la videoconferenza",
        "instavc_conference_loading": "caricare lo schermo attendere",
        "instavc_conference_authenticating": "Autenticazione nel server Instavc .....",
        "instavc_conference_joinconference": "Partecipare alla riunione",
        "instavc_conference_publishyourvideo": "Pubblicare il tuo video",
        "instavc_conference_publishyouraudio": "Pubblicare il tuo audio",
        "instavc_conference_settings": "Impostazioni",
        "instavc_conference_setting": "Impostazioni della riunione",
        "instavc_conference_setting_source": "Fonte:",
        "instavc_conference_setting_camera": "Fotocamera",
        "instavc_conference_audioinputs": "Ingressi audio",
        "instavc_conference_setting_cancel": "Annulla",
        "instavc_conference_setting_apply": "APPLICARE",
        "instavc_conference_setting_resolution": "RISOLUZIONE",
        "instavc_conference_setting_qr": "QR(BETA)",
        "instavc_conference_whiteboard": "Lavagna bianca",
        "instavc_conference_screenshare": "Condivisione dello schermo",
        "instavc_conference_presenter": "Presentatore",
        "instavc_conference_stopshare": "Basta condividere",
        "instavc_conf_install_Screenshare": "Installare ScreenShare",
        "instavc_conference_videosync": "Sincronizzazione video",
        "instavc_conference_videourl": "URL video",
        "instavc_conference_enter_videourl": "Inserisci l'URL del video",
        "instavc_conference_videourl_note": "Nota: solo i partecipanti attivi possono guardare questo video, i ritardatari non possono vedere il video",
        "instavc_conf_recordings": "Registrazione",
        "instavc_conference_sharecontent": "Condividi il tuo schermo",
        "instavc_conference_sharecontent_screen": "connect.instavc.com vuole condividere i contenuti del vostro schermo. Scegliete cosa volete condividere",
        "instavc_conference_shareentire_screen": "L'intero schermo",
        "instavc_conference_sharescreen_applicationwindow": "Finestra di applicazione",
        "instavc_conference_sharescreen_chrometab": "Scheda Cromo",
        "instavc_conference_sharescreen_cancel": "Annulla",
        "instavc_conference_sharescreen_share": "Condividi",
        "instavc_conf_sharescreen": "Stai condividendo il tuo schermo!",
        "instavc_conf_share_otherparticipants": "Gli altri partecipanti possono vedere le applicazioni e la documentazione sul vostro schermo",
        "instavc_stopsharing": "Basta condividere",
        "instavc_conference_chat": "Chat",
        "instavc_conference_additional_camera": "Telecamera aggiuntiva",
        "instavc_conference_sharescreen_groupchat": "Chat di gruppo",
        "instavc_conference_sharescreen_GC": "GC",
        "instavc_conference_entermessage": "Dite qualcosa!",
        "instavc_conference_adddevice": "Aggiungi dispositivi",
        "instavc_conference_adddevice_addcamera": "Aggiungi macchina fotografica",
        "instavc_conference_adddevice_source": "Fonte:",
        "instavc_conference_adddevice_camera": "Fotocamera",
        "instavc_conference_adddevice_cameraname": "Telecamera FaceTime HD",
        "instavc_conference_adddevice_close": "Chiudere",
        "instavc_conference_adddevice_publish": "PUBBLICITA'",
        "instavc_conference_info": "info",
        "instavc_conference_questions": "Domande",
        "instavc_conference_askquestions": "Fai domande",
        "instavc_conference_info_meetingid": "ID riunione personale",
        "instavc_conference_fullscreen": "Schermo intero",
        "conference_setup_invitepeoplel": "Invitare le persone",
        "conference_setup_invite_linktojoin": "https:\/\/connect.instavc.com\/conference-guest\/93ac2135d88727ca29061b4b6bf9477bc8f285a8121ff580649512abad353fbc6751a134d56438f7\/f",
        "conference_setup_invite_byemail": "Invito per posta",
        "instavc_conference_participants": "Partecipanti",
        "instavc_conference_participants_search": "Ricerca ...",
        "instavc_conference_participant": "Partecipante",
        "instavc_conference_participants_muteall": "Muto Tutti",
        "instavc_conference_participants_unmuteall": "UnMute Tutti",
        "instavc_conference": "Riunione",
        "instavc_conf_more": "Altro",
        "instavc_conference_picturemode": "Questo video viene riprodotto in modalit\u00e0 Picture-in-Picture",
        "instavc_conference_mediastreams_stats": "Statistiche dei media",
        "instavc_conference_mediastreams_audio": "Audio",
        "instavc_conference_mediastreams_audio_send": "Invia a",
        "instavc_conference_mediastreams_audio_codec": "Codec",
        "instavc_conference_mediastreams_audio_rates": "Tassi (Kbps)",
        "instavc_conference_mediastreams_audio_lost": "Perso",
        "instavc_conference_mediastreams_audio_fraction": "Frazione",
        "instavc_conference_mediastreams_video": "Video",
        "instavc_conference_mediastreams_video_send": "Invia a",
        "instavc_conference_mediastreams_video_codec": "Codec",
        "instavc_conference_mediastreams_video_rates": "Tassi (Kbps)",
        "instavc_conference_mediastreams_video_lost": "Perso",
        "instavc_conference_mediastreams_video_fraction": "Frazione",
        "instavc_conference_resolution": "RISOLUZIONE",
        "instavc_conference_framerate": "Frame rate",
        "instavc_conference_nack": "Nack",
        "instavc_conference_pli": "PLI",
        "instavc_conference_bw": "Disponibile BW",
        "instavc_conference_audiomuted": "Audio silenziato",
        "instavc_conference_microphonemuted": "Il tuo microfono silenziato",
        "join_conference_join_meetinginvitation": "Partecipare agli inviti alle riunioni",
        "join_conference_search": "Ricerca ...",
        "join_conference_meeting details": "nome della riunione\nInvitato da:\nnome dell'inventore",
        "join_conference_join": "Iscriviti a",
        "join_conference_nomeetings": "Nessun incontro",
        "join_conference_noupcomingmeetings": "Non hai invito alla riunione",
        "insta_conference_mycontacts": "I miei contatti",
        "insta_conference_search": "Ricerca ...",
        "insta_conference_typemessage": "Digitare un messaggio",
        "insta_conference_addcontact": "Aggiungi contatto",
        "insta_conference_enteremail": "Inserire l'ID della posta",
        "insta_conference_add": "Aggiungi",
        "insta_conference_nouserfound": "Nessun utente di questo tipo trovato con questo indirizzo e-mail",
        "instavc_conf_callnot_allowed": "Non \u00e8 possibile chiamare altri utenti quando si \u00e8 gi\u00e0 in una chiamata",
        "insta_conference_pendingapproval": "In attesa di approvazione",
        "insta_conference_outgoingvideocall": "Videochiamata in uscita",
        "insta_conference_calling": "Chiamata .....",
        "insta_conference_incomingvideocall": "Videochiamata in arrivo",
        "insta_conference_calldecline": "Diminuire",
        "insta_conference_callaccept": "Accetta",
        "insta_conference_instavcalert": "connect.instavc.com vuole permettere",
        "insta_conference_usemicrophone": "Usa il tuo microfono",
        "insta_conference_usecamera": "Usa la tua macchina fotografica",
        "insta_conference_blockdevices": "Blocco",
        "insta_conference_allowdevices": "Consentire",
        "instavc_schedulemeeting_createmeeting": "Crea nuova riunione \/ Modifica",
        "instavc_schedulewebinar_createmeeting": "Crea nuovo webinar \/ Modifica",
        "instavc_schedulemeeting_meetingname": "Date un nome al vostro incontro",
        "instavc_schedulemeeting_selectnames_tomeeting": "Scegliete una sala per la vostra riunione",
        "instavc_schedulemeeting_setduration": "Durata del set",
        "instavc_schedulemeeting_15min": "15 Minuti",
        "instavc_schedulemeeting_30min": "30 Minuti",
        "instavc_schedulemeeting_60min": "60 Minuti",
        "instavc_schedulemeeting_setcustom": "Impostazione personalizzata",
        "instavc_schedulemeeting_customduration": "Durata personalizzata",
        "instavc_schedulemeeting_minutes": "minuti",
        "instavc_schedulemeeting_setconfpassword": "Imposta la password della conferenza",
        "instavc_schedulemeeting_typepassword": "Digita la password per la tua riunione",
        "instavc_schedulemeeting_conferencecolor": "Dai colore alla tua icona della riunione",
        "instavc_schedulemeeting_invitepeople": "Invitare le persone a questo incontro",
        "instavc_schedulemeeting_search": "Ricerca ...",
        "instavc_schedulemeeting_SM": "SM",
        "instvc_SM_message": "Il numero di utenti con licenza d'uso autorizzata ha superato il limite di licenza. Se si desidera invitare pi\u00f9 persone \u00e8 possibile",
        "instavc_SM_message_href": "vai a premio",
        "instavc_schedulemeeting_notify": "Informare le persone selezionate di questo incontro",
        "instavc_schedulemeeting_addreminder": "Aggiungi promemoria",
        "instavc_schedulemeeting_add": "Aggiungi",
        "instavc_schedulemeeting_addmeetingreminder": "Aggiungi promemoria riunione",
        "instavc_schedulemeeting_adddatetime": "Raccoglitore di data , raccoglitore di tempo",
        "instavc_schedulemeeting_cancel": "Annulla",
        "instavc_schedulemeeting_apply": "APPLICARE",
        "instavc_schedulemeeting_name": "Inserire il nome dell'incontro",
        "instavc_schedulemeeting_selectroom": "Selezionare la camera",
        "instavc_schedulemeeting_selectduration": "Selezionare la durata",
        "instavc_recuring_schedule": "Programma ricorrente",
        "instavc_schedulemeeting_nothinginreminders": "Niente in Promemoria",
        "instavc_schedulemeeting_createbutton": "CREARE",
        "instavc_schedulemeeting_createdsuccessfully": "Programma creato con successo",
        "instavc_meetinglist_calender": "CALENDARIO",
        "instavc_meetinglist_selecteddates": "Le date selezionate",
        "instavc_meetinglist_schedulecreted": "Orari creati",
        "instavc_meetinglist_pastschedule": "Programmazione passata",
        "instavc_meetinglist": "Elenco Riunioni",
        "instavc_meetinglist_meetingdetils": "Nome della riunione, orario della riunione, organizzato da",
        "instavc_meetinglist_search": "Ricerca ...",
        "instavc_meetinglist_deleterecuring": "Cancellare il programma ricorrente",
        "instavc_meetinglist_alow_deleterecuring": "Volete cancellare solo gli orari attuali o tutti gli orari nella ricorrenza?",
        "instavc_meetinglist_schedule_series": "Tutti gli altri programmi della serie rimarranno:",
        "instavc_meetinglist_schedule_seriesdelete": "Tutti gli orari della serie saranno cancellati:",
        "instavc_meetinglist_allrecuringsch": "Tutti gli orari ricorrenti",
        "instavc_meetinglist_cancelchanges": "Annulla le modifiche",
        "instavc_meetinglist_noschedules": "Nessun programma",
        "instavc_ml_schedulemessage": "Non \u00e8 stato ancora creato un programma di incontri!\n Crea il tuo programma cliccando sul pulsante qui sotto.",
        "instavc_create_schedule": "Creare un programma",
        "instavc_meetinglist_currentschedule": "Calendario attuale",
        "instavc_meetinglist_join": "Iscriviti a",
        "instavc_meetinglist_buynow": "Compra ora",
        "instavc_dashboard_chooseplan": "Scegliere un piano",
        "instavc_package_smbplan": "Piani SMB",
        "instavc_package_smbplan_host": "(Licenza Host Based)",
        "instavc_package_smbplandescription": "Una camera pu\u00f2 avere solo un ospite alla volta. L'Acquirente pu\u00f2 impostare o modificare l'account dell'ospite, che pu\u00f2 controllare la stanza. Gli accessi dell'host possono essere condivisi durante la chiamata. Tutti gli altri utenti possono interagire fino a quando l'host non si unisce alla riunione.",
        "instavc_package_basic": "Basic",
        "instavc_package_hostuser_smb": "1 Ospite e",
        "instavc_package_hostuser_smb_users": "Utenti",
        "instavc_package_currency": "$",
        "instavc_package_permonth": "\/ mese",
        "instavc_package_bill_smb": "Fatturato ogni anno",
        "instavc_package_include_smb": "Questo pacchetto include con",
        "instavc_package_specifictions_Whiteboard": "Lavagna bianca",
        "instavc_package_specifictions_Screen": "Condivisione dello schermo",
        "instavc_package_specifictions_nChat": "Chat",
        "instavc_package_specifictions_Recording": "Registrazione",
        "instavc_package_specifictions_PresenterMode": "Modalit\u00e0 Presentatore",
        "instavc_package_buynowbutton": "Compra ora",
        "instavc_package_fullfeatures_smb": "CARATTERISTICHE DEL FULL",
        "instavc_package_billinginfo": "Informazioni sulla fatturazione",
        "instavc_package_billinginfo_name": "Nome",
        "instavc_package_billinginfo_orginazation": "Organizzazione",
        "instavc_package_biilinginfo_gstin": "GSTIN",
        "instavc_package_billinginfo_save": "SALVARE",
        "instavc_package_billinginfo_edit": "Modifica",
        "instavc_package_billingdetails": "Dettagli di fatturazione",
        "instavc_package_billingdetails_firstname": "Nome",
        "instavc_package_billingdetails_lastname": "Cognome",
        "instavc_package_billingdetails_address": "Indirizzo",
        "instavc_package_billingdetails_city": "Citt\u00e0",
        "instavc_package_billingdetails_zip": "Zip",
        "instavc_package_billingdetails_cancel": "Annulla",
        "instavc_package_billingdetails_save": "SALVARE",
        "instavc_package_shippinginfo": "Informazioni sulla spedizione",
        "instavc_package_shippinginfo_edit": "Modifica",
        "instavc_package_shippingdetails": "Dettagli di spedizione",
        "instavc_package_shippingdetails_firstname": "Nome",
        "instavc_package_shippingdetails_lastname": "Cognome",
        "instavc_package_shippingdetails_address": "Indirizzo",
        "instavc_package_shippingdetails_city": "Citt\u00e0",
        "instavc_package_shippingdetails_zip": "Zip",
        "instavc_package_shippingdetails_cancel": "Annulla",
        "instavc_package_shippingdetails_save": "SALVARE",
        "instavc_package_cartsummary": "Riepilogo carrello",
        "instavc_package_hostbase": "Licenza Host Based (nome della licenza)",
        "instavc_package_smbplan_basic": "Piano SMB - Basic",
        "instavc_package_plandetails": "$ 144.02\n\nfatturato per 1 anno\n13 dollari al mese",
        "instavc_package_term": "Termine",
        "instavc_package_1year": "1 anno",
        "instavc_package_2years": "2 anni",
        "instavc_package_3years": "3 anni",
        "instavc_package_4years": "4 anni",
        "instavc_package_5years": "5 anni",
        "instavc_package_subtotal": "Subtotale",
        "instavc_package_taxes": "Imposte e tasse (GST +18%)",
        "instavc_package_launchoffer": "LANCIATORE",
        "instavc_package_promo": "Promo applicata con successo",
        "instavc_package_remove": "Rimuovere",
        "instavc_package_havepromocode": "Hai un codice promozionale? Richiedi qui",
        "instavc_package_entercouponcode": "Inserire il codice coupon valido",
        "instavc_package_total": "Totale",
        "instavc_package_offerdisclaimer": "Visualizza l'offerta",
        "instavc_package_paynow": "PAGARE ORA",
        "instavc_package_payment_instavclicense": "PeopleLink Comunicazione unificata\nPagamento della licenza InstaVC",
        "instavc_package_phone": "Telefono",
        "instavc_package_entervalid_contactnumber": "Inserire il numero di contatto valido",
        "instavc_package_email": "Invia un'e-mail a",
        "instavc_package_enteremail": "Si prega di inserire un emai id valido. Esempio : you@example.com",
        "instavc_package_paybycard": "PAGARE PER CARTA",
        "instavc_package_securedbyrazorpay": "Protetto da Razorpay",
        "instavc_package_card": "CARTA",
        "instavc_package_enterotp": "Inserire OTP inviato il xxxxxxxxxxxxx\nper accedere alle Carte salvate",
        "instavc_package_resendotp": "Inviare nuovamente OTP",
        "instavc_package_skipsavedcards": "Salta le carte salvate",
        "instavc_package_enter_otp": "Inserire l'otp",
        "instavc_package_skipthesavedcards": "Salta le carte salvate",
        "instavc_package_cardnumber": "Numero della carta",
        "instavc_package_entervalid_cardnumber": "Inserire un numero di carta valido",
        "instavc_package_expiry": "Scadenza",
        "instavc_package_cardholdername": "Nome del titolare della carta",
        "instavc_package_cvv": "CVV",
        "instavc_package_3digitcode": "E' un codice a 3 cifre stampato sul retro della carta",
        "instavc_package_pay": "PAGARE",
        "instavc_package_remembercard": "Ricordare la carta",
        "instavc_package_medium": "Medio",
        "instavc_package_hostuser_medium": "1 Host e 50 utenti",
        "instavc_package_month_medium": "$ 15 al mese",
        "instavc_package_bill_medium": "Fatturato annualmente + GST 18%.",
        "instavc_package_include_medium": "Questo pacchetto include con",
        "instavc_package_specifictions_medium": "Lavagna bianca\nCondivisione dello schermo\nChat\nRegistrazione\nModalit\u00e0 Presentatore",
        "instavc_package_buynow_medium": "Compra ora",
        "instavc_package_fullfeatures_medium": "CARATTERISTICHE DEL FULL",
        "instavc_package_advanced": "Avanzato",
        "instavc_package_hostuser_advanced": "1 Host e 100 utenti",
        "instavc_package_month_advanced": "$ 16 al mese",
        "instavc_package_bill_advanced": "Fatturato annualmente + GST 18%.",
        "instavc_package_include_advanced": "Questo pacchetto include con",
        "instavc_package_specifictions_advanced": "Lavagna bianca\nCondivisione dello schermo\nChat\nRegistrazione\nModalit\u00e0 Presentatore",
        "instavc_package_buynow_advanced": "Compra ora",
        "instavc_package_fullfeatures_advanced": "CARATTERISTICHE DEL FULL",
        "instavc_package_virtualmcu": "Licenza MCU virtuale",
        "instavc_package_virtualmcu_room": "(Licenza per camera)",
        "instavc_package_vmcudetails": "Una camera pu\u00f2 avere solo un ospite alla volta. L'Acquirente pu\u00f2 impostare o modificare l'account dell'ospite, che pu\u00f2 controllare la stanza. Gli accessi dell'host possono essere condivisi durante la chiamata. Tutti gli altri utenti possono interagire fino a quando l'host non si unisce alla riunione.",
        "instavc_package_instavc": "InstaVC",
        "instavc_package_silver": "Sliver",
        "instavc_package_userrooms_silver_users": "Utenti",
        "instavc_package_userrooms_silver_rooms": "Camere",
        "instavc_package_month_silver": "$ 281 al mese",
        "instavc_package_bill_silver": "Fatturato annualmente + GST 18%.",
        "instavc_package_include_silver": "Questo pacchetto include con",
        "instavc_package_specifictions_silver": "Lavagna bianca\nCondivisione dello schermo\nChat\nRegistrazione\nModalit\u00e0 Presentatore",
        "instavc_package_buynow_silver": "Compra ora",
        "instavc_package_fullfeatures_silver": "CARATTERISTICHE DEL FULL",
        "instavc_package_gold": "Oro",
        "instavc_package_userrooms_gold": "200 Utenti - 30 Camere",
        "instavc_package_month_gold": "$ 467 al mese",
        "instavc_package_bill_gold": "Fatturato annualmente + GST 18%.",
        "instavc_package_include_gold": "Questo pacchetto include con",
        "instavc_package_specifictions_gold": "Lavagna bianca\nCondivisione dello schermo\nChat\nRegistrazione\nModalit\u00e0 Presentatore",
        "instavc_package_buynow_gold": "Compra ora",
        "instavc_package_fullfeatures_gold": "CARATTERISTICHE DEL FULL",
        "instavc_package_platinum": "Platino",
        "instavc_package_userrooms_platinum": "500 Utenti - 50 Camere",
        "instavc_package_month_platinum": "$ 933 al mese",
        "instavc_package_bill_platinum": "Fatturato annualmente + GST 18%.",
        "instavc_package_include_platinum": "Questo pacchetto include con",
        "instavc_package_specifictions_platinum": "Lavagna bianca\nCondivisione dello schermo\nChat\nRegistrazione\nModalit\u00e0 Presentatore",
        "instavc_package_buynow_platinum": "Compra ora",
        "instavc_package_fullfeatures_platinum": "CARATTERISTICHE DEL FULL",
        "instavc_package_legacybaselicense": "Licenza Legacy Based",
        "instavc_package_licensebased_ports": "Licenza in base al numero di porte",
        "instavc_package_hardwareendpoint": "InstaVC con endpoint hardware",
        "instavc_package_webrtcconf_legacysupport": "WebRTC Conferenze con supporti legacy",
        "instavc_package_sip": "Supporto SIP \/ BFCP",
        "instavc_package_chh323": "ChH323 \/ H.239 Supporto",
        "instavc_package_contactus": "CONTATTI",
        "instavc_myprofile_dashboard": "Cruscotto",
        "instavc_myprofile_settings": "Impostazioni",
        "instavc_myprofile": "Il mio profilo",
        "instavc_myprofile_personaldetail": "Dettagli personali",
        "instavc_myprofile_personaldetails_name": "Nome",
        "instavc_myprofile_personaldetails_email": "Invia un'e-mail a",
        "instavc_myprofile_personaldetails_phoneno": "Numero di telefono",
        "instavc_myprofile_personaldetails_address": "Indirizzo",
        "instavc_myprofile_personaldetails_city": "Citt\u00e0",
        "instavc_myprofile_edit": "Modifica",
        "instavc_myprofile_personaldetails": "Dettagli personali",
        "instavc_myprofile_firstname": "Nome",
        "instavc_myprofile_lastname": "Cognome",
        "instavc_myprofile_email": "Invia un'e-mail a",
        "instavc_myprofile_phoneno": "Numero di telefono",
        "instavc_myprofile_cancel": "Annulla",
        "instavc_myprofile_save": "SALVARE",
        "instavc_myprofile_professionaldetails": "Dettagli professionali",
        "instavc_myprofile_position": "Posizione",
        "instavc_myprofile_professionaldetails_email": "Invia un'e-mail a",
        "instavc_myprofile_professionaldetails_phoneno": "Numero di telefono",
        "instavc_myprofile_professionaldetails_address": "Indirizzo",
        "instavc_myprofile_professionaldetails_branch": "Filiale",
        "instavc_myprofile_professionaldetails_edit": "Modifica",
        "instavc_myprofile_officialdetails": "Dettagli ufficiali",
        "instavc_myprofile_officialdetails_position": "Posizione",
        "instavc_myprofile_officialdetails_email": "Invia un'e-mail a",
        "instavc_myprofile_officialdetails_phoneno": "Numero di telefono",
        "instavc_myprofile_officialdetails_branch": "Filiale",
        "instavc_myprofile_officialdetails_address": "Indirizzo",
        "instavc_myprofile_officialdetails_cancel": "Annulla",
        "instavc_myprofile_officialdetails_save": "SALVARE",
        "instavc_myprofile_contactinfo": "INFO CONTATTO",
        "instavc_myprofile_contactinformation": "Informazioni di contatto",
        "instavc_myprofile_add": "Aggiungi",
        "instavc_myprofile_contactinformation_address": "Indirizzo",
        "instavc_myprofile_contactinformation_city": "Citt\u00e0",
        "instavc_myprofile_contactinformation_zip": "Zip",
        "instavc_myprofile_contactinformation_landmark": "Punto di riferimento",
        "instavc_myprofile_contactinformation_cancel": "Annulla",
        "instavc_myprofile_contactinformation_save": "SALVARE",
        "instavc_myprofile_address": "Indirizzo",
        "instavc_myprofile_landmark": "Punto di riferimento",
        "instavc_myprofile_delete": "Allarme",
        "instavc_myprofile_deletealert": "Sei sicuro di volerlo cancellare?",
        "instavc_myprofile_disagree": "Non sono d'accordo",
        "instavc_myprofile_agree": "Accetto",
        "instavc_myprofile_websitelinks": "Link al sito web",
        "instavc_myprofile_website_add": "Aggiungi",
        "instavc_myprofile_website": "Sito web",
        "instavc_myprofile_websiteexample": "https:\/\/www.google.com",
        "instavc_myprofile_website_cancel": "Annulla",
        "instavc_myprofile_website_save": "SALVARE",
        "instavc_myprofile_sociallinks": "Link sociali",
        "instavc_myprofile_sociallinks_add": "Aggiungi",
        "instavc_myprofile_sociallink": "Link sociali",
        "instavc_myprofile_sociallinks_example": "https:\/\/www.facebook.com\/xxx",
        "instavc_myprofile_sociallink_cancel": "Annulla",
        "instavc_myprofile_sociallink_save": "SALVARE",
        "instavc_myprofile_basicinfo": "Informazioni di base",
        "instavc_myprofile_basicinfo_add": "Aggiungi",
        "instavc_myprofile_basicinfo_dob": "DOB",
        "instavc_myprofile_basicinfo_gender": "Sesso",
        "instavc_myprofile_basicinformation": "Informazioni di base",
        "instavc_myprofile_basicinformation_dob": "Data di nascita",
        "instavc_myprofile_basicinformation_gender": "Sesso",
        "instavc_myprofile_basicinformation_cancel": "Annulla",
        "instavc_myprofile_basicinformation_save": "SALVARE",
        "instavc_myprofile_basicinformation_deletealert": "Allarme\nSei sicuro di volerlo cancellare?",
        "instavc_myprofile_basicinformation_disagree": "Non sono d'accordo",
        "instavc_myprofile_basicinformation_agree": "Accetto",
        "instavc_myprofile_uploadnewavatar": "CARICARE UN NUOVO AVATAR",
        "instavc_myprofile_cropimage": "Immagine del raccolto",
        "instavc_myprofile_image_cancel": "Annulla",
        "instavc_myprofile_image_save": "SALVARE",
        "instavc_myprofile_locale": "LOCALE",
        "instavc_myprofile_localesettings": "Impostazioni locali",
        "instavc_myprofile_preferredlanguage": "Lingua preferita :",
        "instavc_myprofile_language": "Italiano",
        "instavc_settings": "Impostazioni",
        "instavc_settings_changepassword": "CAMBIARE LA PASSWORD",
        "instavc_settings_changepass": "CAMBIARE LA PASSWORD",
        "instavc_settings_currentpassword": "Digitare la password corrente",
        "instavc_settings_newpassword": "Digita nuova password",
        "instavc_settings_changepassword_cancel": "Annulla",
        "instavc_settings_changepassword_save": "SALVARE",
        "instavc_settings_enter_currentpassword": "Inserire la password attuale",
        "instavc_settings_enter_newpassword": "Inserisci la nuova password",
        "instavc_settings_help": "Aiuto",
        "instavc_settings_affiliate": "Diventa Affiliato",
        "instavc_settings_support": "Centro di supporto",
        "instavc_settings_newticket": "Apri un nuovo biglietto",
        "instavc_settings_ticketstatus": "Controlla lo stato del biglietto",
        "instavc_settings_troubleshoot": "Risoluzione dei problemi",
        "instavc_legacy_dailout": "Digitare fuori",
        "instavc_legacy_stats": "Statistiche",
        "instavc_legacy_dail_in_detail": "Dettagli del dial-In",
        "instavc_legacy_h323_endpoint": "H.323 Punto finale",
        "instavc_legacy_sip_endpoint": "Punto finale SIP",
        "instavc_admin_users": "Utenti",
        "instavc_admin_username": "Nome utente",
        "instavc_admin_user_accountstatus": "Stato del conto",
        "instavc_admin_user_confirmed": "Confermato",
        "instavc_admin_user_unconfirmed": "Non confermato",
        "instavc_admin_user_resetall": "Reimposta tutto",
        "instavc_admin_user_sync": "Sync",
        "instavc_admin_user_status": "Stato",
        "instavc_admin_user_phonenumber": "Numero di telefono",
        "instavc_admin_user_created": "Creato",
        "instavc_admin_user_all": "Tutti",
        "instavc_admin_groups": "Gruppi",
        "instavc_admin_addmore": "Aggiungi altro",
        "instavc_admin_group_peoplelink": "Regine, GOLIVE, PeopleLink",
        "instavc_admin_select_plan": "Seleziona il piano",
        "instavc_admin_lastmodified": "Ultima modifica",
        "instavc_admin_email_verified": "Verificato via e-mail",
        "instavc_admin_verified": "Verificato",
        "instavc_admin_Unverified": "Non verificato",
        "instavc_admin_licenses": "Licenze",
        "instavc_admin_addlicense": "Aggiungi licenze",
        "instavc_admin_defaultroom": "Camera predefinita",
        "instavc_admin_hostlicense": "Licenza Host Based",
        "instavc_admin_roomlicense": "Licenza per camera",
        "instavc_admin_virtualmcu": "Licenza virtuale basata su MCU",
        "instavc_admin_enterprise": "Licenza d'impresa",
        "instavc_mcuserver": "Server MCU",
        "instavc_mculink": "https:\/\/awsmcu.instavc.com",
        "instavc_admin_expirydate": "Data di scadenza",
        "instavc_admin_settings": "Impostazioni Admin",
        "instavc_default_offer": "Offerta predefinita per il rinvio",
        "instavc_enter_coupon_code": "Inserisci il codice del coupon",
        "instavc_current_partshare": "Quota attuale dei partner",
        "instavc_share_value": "Valore azionario",
        "instavc_affliate_share": "Quota di affiliazione attuale",
        "instavc_enter_share": "Inserire il valore azionario (%)",
        "instavc_roomname": "Nome della camera",
        "instavc_room_ten": "Dieci",
        "instavc_room_twenty": "Venti",
        "instavc_room_thirty": "Trenta",
        "instavc_room_hostbased": "Host Based",
        "instavc_virtualmcu": "MCU virtuale",
        "instavc_rooms_enterprises": "Impresa",
        "instavc_roomid": "Identificazione della camera",
        "instavc_roomtype": "Tipo di camera",
        "instavc_room_options": "Opzioni della camera",
        "instavc_commercialtype": "Tipo commerciale",
        "instavc_free": "Gratuito",
        "instavc_poc": "POC",
        "instavc_commercial": "Commerciale",
        "instavc_createdAt": "Creato a",
        "instavc_useraccess": "Accesso utente",
        "instavc_peoplelinkroom": "Camera Peoplelink",
        "instavc_inputlimit": "Limite di ingresso",
        "instavc_participant_limit": "Limite dei partecipanti",
        "instavc_adduser": "Aggiungi utente",
        "instavc_searchbyname": "Ricerca per nome",
        "instavc_manage_plans": "Gestire Piani\/prodotti",
        "instavc_new_plans": "Nuovo Piano\/Prodotto",
        "instavc_allplan_list": "Elenco di tutti i piani",
        "instavc_sortby": "Ordina per",
        "instavc_active": "Attivo",
        "instavc_inactive": "Inattivo",
        "instavc_create_plan": "Creare un nuovo piano",
        "instavc_plan_name": "Nome del piano",
        "instavc_enteramount": "Inserire l'importo (Min: INR 100)",
        "instavc_select_product": "Seleziona il prodotto",
        "instavc_host_room": "1 Ospite \/ Camera",
        "instavc_multiroom": "Multi Host \/ Camera",
        "instavc_legacyrooms": "Sala Legacy",
        "instavc_number_hosts": "Numero di ospiti",
        "instavc_number_participants": "Numero di partecipanti",
        "instavc_number_room": "Numero di camere",
        "instavc_subscriptiom_period": "Periodo di abbonamento",
        "instavc_sub_yearly": "Annuale",
        "instavc_sub_monthly": "Mensile",
        "instavc_sub_weekly": "Settimana",
        "instavc_setinterval": "Impostare l'intervallo",
        "instavc_type": "Tipo",
        "instavc_subscription": "Abbonamento",
        "instavc_onetime": "Una volta",
        "instavc_description": "Descrizione",
        "instavc_planid": "PlanID",
        "instavc_orders": "Ordini",
        "instavc_orderid": "Identificativo dell'ordine",
        "instavc_none": "Nessuno",
        "instavc_customer": "Cliente",
        "instavc_build_anually": "Costruire Anualmente",
        "instavc_recurring_billing": "Fatturazione ricorrente",
        "instavc_next_due": "Prossima scadenza",
        "instavc_invoice_details": "Dettaglio fatture",
        "instavc_recurring_payment": "Pagamento ricorrente",
        "instavc_todays_sale": "La vendita di oggi",
        "instavc_total_order": "Totale ordini",
        "instavc_total_rooms": "Totale camere",
        "instavc_current_conference": "Riunione in corso",
        "instavc_current_user": "Utenti attuali",
        "instavc_live_broadcast": "Trasmissioni in diretta",
        "instavc_loading_chart": "Grafico di carico",
        "instavc_view": "Vedi",
        "instavc_coupon_info": "Informazioni sul coupon",
        "instavc_new_coupon": "Nuovo Coupon",
        "instavc_create_coupon": "Crea nuovo coupon",
        "instavc_coupon_code": "Codice Coupon",
        "instavc_company_code": "Codice Aziendale",
        "instavc_select_plan": "Seleziona i piani",
        "instavc_discount": "Sconto",
        "instavc_activestatus": "activeStatus",
        "instavc_usage": "Utilizzo",
        "instavc_activeusage": "activeusage",
        "instavc_singleuse": "Uso singolo",
        "instavc_multiuse": "Multiuso",
        "instavc_first_page": "Prima pagina",
        "instavc_previosu_page": "Pagina precedente",
        "instavc_next_page": "Pagina successiva",
        "instavc_last_page": "Ultima pagina",
        "instavc_plan": "Piano",
        "instavc_request_withdrawal": "Richiesta di prelievo",
        "instavc_withdraw": "Ritirare",
        "instavc_account": "Conto",
        "instavc_verification": "Verifica",
        "instavc_withdraw_history": "Storia dei ritiri",
        "instavc_add_bank": "Aggiungere un conto bancario per il prelievo",
        "instavc_bank_description": "Proteggiamo il vostro conto bancario mantenendo riservate le vostre informazioni finanziarie. Per evitare che i prelievi non vadano a buon fine e la restituzione dell'importo, il nome su questo conto deve corrispondere al nome sul vostro conto bancario. Potrebbe essere necessario un certo tempo per completare il trasferimento, a seconda delle politiche della vostra banca e degli orari delle vacanze.",
        "instavc_bank_accountnumber": "Numero di conto bancario",
        "instavc_enter_accountnumber": "Inserisci il tuo numero di conto",
        "instavc_account_name": "Nome del titolare del conto",
        "instavc_per_bank_account": "Come da conto bancario",
        "instavc_ifsc": "Codice IFSC",
        "instavc_bankname": "Nome della banca",
        "instavc_branchname": "Nome della filiale della banca",
        "instavc_confirmdetails": "Confermare i dettagli",
        "instavc_pandetails": "Si prega di fornire di seguito i dettagli secondo il vostro PAN",
        "instavc_enter_pan": "Inserisci il tuo nome come da PAN",
        "instavc_fathername": "Nome del padre",
        "instavc_enter_fathername": "Inserisci il nome del padre",
        "instavc_pannumber": "Numero PAN",
        "instavc_panexampe": "Es: BNZPM2501G",
        "instavc_upload_pan_copy": "Carica copia PAN",
        "instavc_save_details": "Salva i dettagli",
        "instavc_sales": "Vendite",
        "instavc_transactionid": "Identificazione della transazione",
        "instavc_date": "Data",
        "instavc_amount": "Importo",
        "instavc_setaccount_withdraw": "Impostazione dei dettagli del conto prima di un prelievo",
        "instavc_first_withdraw": "Se siete un utente che richiede il prelievo per la prima volta, dovete compilare i dati del conto e verificare il vostro conto utilizzando i dati della PAN Card nelle schede successive. Una volta terminata la verifica, potete procedere alla richiesta di prelievo dal vostro saldo disponibile.",
        "instavc_accountdetails": "Compila i dettagli del conto",
        "instavc_available_balance": "Saldo disponibile",
        "instavc_amount_withdraw": "Importo da prelevare",
        "instavc_enteramount_withdraw": "Inserire l'importo che si desidera prelevare",
        "instavc_select_payment_mode": "Selezionare la modalit\u00e0 di pagamento",
        "instavc_paypal_payment": "Pagamento Paypal",
        "instavc_bank_transaction": "Transazione bancaria",
        "instavc_paypalfee": "Tassa Paypal",
        "instavc_totalamount_withdraw": "Importo totale da prelevare",
        "instavc_sendrequest": "Inviare la richiesta",
        "instavc_no_request_withdraw": "Non \u00e8 stata ancora sollevata alcuna richiesta di prelievo!",
        "instavc_start_process_withdraw": "\u00c8 possibile avviare l'elaborazione per il prelievo dal basso.",
        "instavc_request_withdraw_now": "Richiedi il ritiro ora",
        "instavc_current_commission": "Commissione attuale",
        "instavc_from": "Da",
        "instavc_to": "A",
        "instavc_today": "Oggi",
        "instavc_last7days": "Ultimi 7 giorni",
        "instavc_lastmonth": "Questo mese",
        "instavc_paid_customers": "Clienti a pagamento",
        "instavc_share_referral_link": "Condividi il tuo link di riferimento con gli amici",
        "instavc_copylink": "Copia link",
        "instavc_minimum_payout": "Pagamento minimo",
        "instavc_referral_signup": "Iscrizioni di rinvio",
        "instavc_referral_clicks": "Clic di rinvio",
        "instavc_paid": "Pagato",
        "instavc_unpaid": "Non pagato",
        "instavc_totalearned": "Totale Guadagnati",
        "instavc_myreferral": "I miei riferimenti",
        "instavc_viewall": "Visualizza tutti",
        "instavc_mysales": "Le mie vendite",
        "instavc_sms_mfa": "SMS Stato AMF",
        "instavc_facebook": "Facebook",
        "instavc_twitter": "Twitter",
        "instavc_linkedin": "LinkedIn",
        "instavc_referred_users": "Utenti referenziati",
        "instavc_created_date": "CreatedDate",
        "instavc_myearnings": "I miei guadagni",
        "instavc_earnings": "Guadagni",
        "instavc_commission_share": "Quota di commissione",
        "instavc_commission_percentage": "Percentuale di commissione",
        "instavc_coupon_offers": "Coupon\/Offerte",
        "instavc_my_referral_account": "Il mio account di riferimento",
        "instavc_req_withdraw": "Richiesta di prelievo",
        "instavc_authenticating": "Autenticazione al server instaVC ...",
        "instavc_join_access_media": "Iscritto... Accesso ai dispositivi multimediali...",
        "instavc_publish_success": "Pubblicare di successo",
        "instavc_recieves": "Riceve",
        "instavc_host_screenshare": "\u00c8 necessario diventare un Host per la condivisione di schermo \/ documenti o",
        "instavc_request_sharescreen": "Richiesta di accesso per la condivisione dello schermo \/ dei documenti",
        "instavc_note": "Nota",
        "instavc_filesize": "La dimensione del file non dovrebbe essere superiore a 10MB",
        "instavc_become_host": "Per diventare un host cliccare sulle icone del menu overflow doted sul video",
        "instavc_clicklink": "cliccare sul link Diventa un ospite, nel popup e inserire la password dell'ospite e cliccare sul pulsante Diventa un ospite",
        "instavc_doc_share": "Condividi il documento",
        "instavc_search_host": "In attesa dell'ospite",
        "instavc_wait_for_host": "Si prega di attendere che l'organizzatore inizi questo incontro",
        "instavc_open_black_dialog": "Aprire il dialogo nero",
        "instavc_open_white_dialog": "Aprire il dialogo bianco",
        "instavc_dail_detail": "Quadrante - In - Dettagli",
        "instavc_request_access": "Richiesta di accesso",
        "instavc_inordder_enable": "Al fine di abilitare",
        "instavc_host_permission": "\u00e8 necessario il permesso di accesso da parte dell'Host",
        "instavc_please_wait": "Si prega di attendere",
        "instavc_host_to_permit": "(Ospite) il permesso di",
        "instavc_alternate_content": "Contenuto alternativo",
        "instavc_meeting_room": "Sala riunioni",
        "instavc_host_left_conference": "Il padrone di casa ha lasciato la conferenza!",
        "instavc_conf_end": "La riunione si concluder\u00e0 automaticamente a",
        "instavc_seconds": "Secondi",
        "instavc_waiting_msg": "Grazie per l'attesa. Inizieremo l'incontro quando il padrone di casa si unisce",
        "instavc_let_hostname": "Volete far sapere all'ospite che state aspettando?",
        "instavc_exitconf": "Riunione di uscita",
        "instavc_host_left": "Ospite a sinistra...",
        "instavc_end_host_msg": "Il padrone di casa ha posto fine a questo incontro. Grazie per aver partecipato.",
        "instavc_close_conf": "si pu\u00f2 tornare a casa (o) chiudere l'applicazione.",
        "instavc_gohome": "Vai a casa",
        "instavc_hand_raised": "Mano alzata",
        "instavc_ask": "Chiedete a",
        "instavc_protocal": "Protocollo",
        "instavc_bitrate": "Bitrate",
        "instavc_confirm_details": "Conferma i tuoi dati",
        "instavc_enter_info_conf": "Si prega di inserire i propri dati prima di iniziare la riunione.",
        "instavc_have_acc": "Se avete gi\u00e0 un conto?",
        "instavc_host_access": "Accesso host",
        "instavc_host_pass": "Inserisci la password di Host",
        "instavc_becomehost": "Diventa Ospite",
        "instavc_permit_screenshare": "Permesso di Screen-Share",
        "instavc_allow_access": "Consentire l'accesso alla lavagna bianca",
        "instavc_request_permission": "ha richiesto ulteriori autorizzazioni per",
        "instavc_deny": "Negare",
        "instavc_customeremail": "Email del cliente",
        "instavc_orderlink": "Ordine Link",
        "instavc_customerid": "Identificazione del cliente",
        "instavc_selectpackage": "Seleziona il pacchetto",
        "instavc_host_package": "Pacchetto Host Based",
        "instavc_virtualbase_mcu": "Basato su MCU virtuale",
        "instavc_plans_product": "Piani\/prodotti",
        "instavc_nodata": "Nessun dato disponibile",
        "instavc_nodata_msg": "Al momento non sono disponibili dati da mostrare.",
        "instavc_basicplan": "Piano di base",
        "instavc_host_participants": "1 Ospite con 30 partecipanti",
        "instavc_link": "Link",
        "instavc_cancel_order": "Annullare l'ordine",
        "instavc_per": "per",
        "instavc_user_email": "Email dell'utente",
        "instavc_owner": "Proprietario",
        "instavc_user": "Utente",
        "instavc_default_users": "Offerte predefinite",
        "instavc_system_settings": "Impostazioni di sistema",
        "instavc_expires": "Scade",
        "instavc_start_VC": "Avvia la videoconferenza",
        "instavc_start_AC": "Avviare la riunione audio",
        "instavc_date_error": "La data e l'ora di inizio dovrebbero essere maggiori dell'ora attuale",
        "instavc_browser_update": "Aggiornamento del browser richiesto",
        "instavc_browser_note": "Abbiamo notato che state utilizzando una versione precedente di BROWSERNAME che ora non \u00e8 pi\u00f9 supportata.",
        "instavc_browser_msg": "Instavc.com richiede una versione aggiornata di BROWSERNAME o di qualsiasi altro browser moderno.",
        "instavc_browser_upgrade": "Si prega di aggiornare a una delle ultime versioni di questi browser per un'esperienza migliore.",
        "instavc_googlechrome": "Google Chrome",
        "instavc_gc_version74": "Versione 74",
        "instavc_mfirefox": "Mozilla Firefox",
        "instavc_mff_version": "Versione 66",
        "instavc_opera": "Opera",
        "instavc_opera_version": "Versione 60.0+",
        "instavc_microsoftedge": "Microsoft Edge",
        "instavc_ms_version": "Versione 44+",
        "instavc_safari": "Safari della mela",
        "instavc_safari_version": "Versione 12.1",
        "instavc_contact_msg": "Gi\u00e0 aggiornato, ma ha ancora problemi? Contattateci a",
        "instavc_camera_busy": "La macchina fotografica sembra essere occupata!",
        "instavc_close_camera": "Si prega di chiudere tutte le applicazioni e riprovare.",
        "instavc_resolution": "Risoluzione non supportata!",
        "instavc_camera_notsupport": "La vostra fotocamera non supporta questa risoluzione.",
        "instavc_camera_reconfig": "Riconfigurare le impostazioni con una risoluzione pi\u00f9 bassa e riprovare.",
        "instavc_change_resolution": "Risoluzione dei cambiamenti",
        "instavc_confirmation": "Conferma",
        "instavc_yes": "S\u00ec",
        "instavc_delete_room": "Sei sicuro di voler cancellare la Camera?",
        "instavc_delete_license": "Sei sicuro di voler cancellare la licenza?",
        "instavc_help": "Siamo qui per aiutarvi",
        "instavc_browse_questions": "Sfogliate le domande pi\u00f9 frequenti.",
        "instavc_os": "Sistema operativo",
        "instavc_macos": "Versione per Mac OS X:",
        "instavc_chrome_version": "Versione cromata:",
        "instavc_private_browsing": "Navigazione privata?",
        "instavc_faq_no": "No",
        "instavc_display_resolution": "instavc_display_risoluzione",
        "instavc_aspect_ratio": "Rapporto d'aspetto del display",
        "instavc_sys_hasspeakers": "Il sistema ha degli altoparlanti?",
        "instavc_found_speakers": "Dispositivi di altoparlanti trovati : 2",
        "instavc_speakers1": "Altoparlante 1",
        "instavc_speakers2": "Altoparlante 2",
        "instavc_sys_have_microphone": "Il sistema ha il microfono?",
        "instavc_found_microphone": "Dispositivi microfonici trovati : 2",
        "instavc_microphone1": "Microfono 1",
        "instavc_microphone2": "Microfono 2",
        "instavc_has_webcam": "Il sistema ha una webcam?",
        "instavc_found_speakers1": "Dispositivi di altoparlanti trovati : 1",
        "instavc_camera1": "Telecamera 1",
        "instavc_webcam_permission": "Il sito web ha i permessi per la webcam?",
        "instavc_microphone_permission": "Il sito web ha i permessi per il microfono?",
        "instavc_browser_allows": "Il browser consente getUserMedia in questa pagina?",
        "instavc_change_output_device": "\u00c8 possibile cambiare i dispositivi audio in uscita?",
        "instavc_change_camera_resolutions": "\u00c8 possibile modificare le risoluzioni delle telecamere senza fare una nuova richiesta getUserMedia?",
        "instavc_browser_support_webrtc": "Il browser supporta WebRTC (1.0 o 1.1)?",
        "instavc_browser_support_ortc": "Browser supporta ORTC (WebRTC 1.1)?",
        "instavc_initial_setup": "Si prega di attendere durante l'elaborazione della configurazione iniziale!",
        "instavc_close_browser": "Non chiudere il browser e non utilizzare il pulsante indietro in questo momento.",
        "instavc_whoops": "Scusate!",
        "instavc_nonet_msg": "Non \u00e8 stata trovata nessuna connessione internet. Si prega di controllare la connessione o di riprovare.",
        "instavc_nosearch": "Nessun risultato di ricerca trovato",
        "instavc_noserchmsg": "Non riusciamo a trovare nessun articolo che corrisponda alla tua ricerca.",
        "instavc_nosearch_filter": "Prova a regolare la tua ricerca o il filtro per trovare quello che stai cercando.",
        "instavc_gotohoem_page": "Vai alla home page",
        "instavc_pageloading": "Caricamento pagina... Si prega di attendere!",
        "instavc_process": "Elaborazione...",
        "instavc_session_timeout": "Timeout della sessione",
        "instavc_session_inactivity": "La vostra sessione \u00e8 stata cronometrata a causa dell'inattivit\u00e0.",
        "instavc_session_msg": "Si prega di scegliere di rimanere registrati.",
        "instavc_session_reconnect": "Ricollegare",
        "instavc_site_notreached": "Questo sito non \u00e8 raggiungibile!",
        "instavc_site_webaddress": "Assicuratevi di avere il giusto indirizzo web",
        "instavc_check_internet": "Controlla la tua rete",
        "instavc_search_url": "Cerca l'URL in un altro browser",
        "instavc_goto": "Vai a",
        "instavc_request_for_access": "Richiesta di accesso",
        "instavc_start_collabrate": "per iniziare a collaborare alla lavagna bianca",
        "instavc_large_conference_mode": "La sua modalit\u00e0 \"Grande riunione\", non puoi parlare o guardare gli altri fino a quando l'host non ti fornisce il permesso",
        "instavc_allow_participants": "La sua modalit\u00e0 \"Grande riunione\", si deve permettere manualmente ai partecipanti di parlare",
        "instavc_buy_premium": "Acquista Premium per sbloccare queste caratteristiche",
        "instavc_alert_tryagain": "Qualcosa \u00e8 andato storto, Riprovaci, per favore",
        "instavc_enter_name": "Inserisci il tuo nome",
        "instavc_confirm": "Confermare",
        "instavc_ok": "Ok",
        "instavc_askquestion": "Fai una domanda...",
        "instavc_recieve": "Ricevi",
        "instavc_delay": "Ritardo(Ms)",
        "instavc_audio_opus": "Audio\/opus",
        "instavc_video_vp8": "video\/vp8",
        "instavc_browser_not_support_incall": "Il vostro browser non supporta il cambiamento di risoluzione durante la chiamata!",
        "instavc_failed_toPublish": "Non \u00e8 stato possibile pubblicare il video",
        "instavc_publish_fail": "Pubblicazione non riuscita",
        "instavc_video_cant_publish": "Il video non pu\u00f2 essere pubblicato, a causa del raggiungimento del limite di utenza",
        "instavc_failed_joinConference": "Non \u00e8 riuscito a partecipare alla riunione",
        "instavc_mute_audio": "Non \u00e8 possibile disattivare\/riattivare l'audio a distanza se l'audio \u00e8 disattivato",
        "instavc_remote_audio_muted": "Audio remoto silenziato!",
        "instavc_enter_conferenceid": "Inserisci il tuo ID della riunione",
        "instavc_share_screen": "Schermo di condivisione",
        "instavc_sharing_your_screen": "Condividere il tuo schermo...",
        "instavc_stop": "Stop",
        "instavc_back": "Indietro",
        "instavc_choose_plan": "Scegliete il vostro piano dall'elenco sottostante",
        "instavc_have_promotionalcode": "Hai un Codice Promozionale \/ Codice Partner?",
        "instavc_contact_pricing": "Contattateci per i prezzi",
        "instavc_payment_failed": "Pagamento non riuscito",
        "instavc_payment_error": "Purtroppo si \u00e8 verificato un errore durante la gestione del pagamento.",
        "instavc_contact_support": "Si prega di contattare il nostro team di supporto se \u00e8 richiesta assistenza.",
        "instavc_tryagain": "Prova ancora",
        "instavc_payment_success": "Pagamento riuscito",
        "instavc_thanks_for_payment": "Grazie per il pagamento!",
        "instavc_payment_processed": "Il vostro pagamento \u00e8 stato elaborato con successo. Una ricevuta di pagamento automatica",
        "instavc_mail_sent": "sar\u00e0 inviato al tuo indirizzo email registrato a breve",
        "instavc_goto_homepage": "Torna alla home page",
        "instavc_want_toend_call": "Vuoi terminare la chiamata?",
        "instavc_exit_window": "Chiudendo questa finestra si uscir\u00e0 dalla vostra chiamata. Sei sicuro di voler terminare questa chiamata?",
        "instavc_create_room": "Creare una camera",
        "instavc_userlimit": "Limite utente",
        "instavc_edit_room": "Modifica camera",
        "instavc_update": "Aggiorna",
        "instavc_manage_license": "Gestire le licenze",
        "instavc_package": "Pacchetto",
        "instavc_no_of_rooms": "Numero di camere",
        "instavc_no_of_users": "Numero di utenti",
        "instavc_purchasedOn": "Acquistato il",
        "instavc_expiredon": "Scaduto il",
        "instavc_or": "OPPURE",
        "instavc_otherhost_email": "Se si desidera rendere qualsiasi utente come host, inserire il suo indirizzo e-mail.",
        "instavc_set_host_password": "Imposta la password di Host",
        "instavc_host_password": "Password di Host",
        "instavc_access_room": "Chi altro pu\u00f2 accedere a questa Camera?",
        "instavc_select_user_host": "Selezionare un qualsiasi utente dall'elenco per farne un ospite.",
        "instavc_prologin": "Accesso a InstaVC Pro Login",
        "instavc_start": "Iniziare",
        "instavc_enter_website": "Inserire il nome del sito web",
        "instavc_enter_valid_email": "Inserisci l'email valida",
        "instavc_enter_valid_phone": "Inserire il numero di telefono valido",
        "instavc_field_required": "Questo campo \u00e8 obbligatorio",
        "instavc_invitedby": "Invitato da",
        "instavc_storage": "Conservazione",
        "affiliate_approve": "Approvato",
        "affiliate_pending": "In attesa di",
        "affiliate_rejected": "Respinto",
        "affiliate_user_status": "Stato dell'utente",
        "commission_other": "Altre commissioni",
        "add_commission": "Aggiungi commissioni",
        "delete_commission": "Volete cancellare le provvigioni",
        "update_commission": "Commissione di aggiornamento",
        "name": "Nome",
        "offer_value": "Valore dell'offerta",
        "cancel": "Annulla",
        "add": "Aggiungi",
        "update": "Aggiorna",
        "affiliates": "Affiliati",
        "status": "Stato",
        "status_change_message": "Volete cambiare lo stato?",
        "approve": "Approvare",
        "reject": "Rifiuta",
        "commissions": "Commissioni",
        "withdraw_data": "Ritirare i dati",
        "withdraw_amount": "Prelievo Importo",
        "payment_method": "Metodo di pagamento",
        "payment_fee": "Tassa di pagamento",
        "total_amount_withdraw": "Importo totale per prelievo",
        "total_earnings": "Guadagno totale",
        "pending_amount": "Importo in sospeso",
        "paid_amount": "Importo pagato",
        "unpaid_amount": "Importo non pagato",
        "requested_withdraw": "Importo del prelievo richiesto",
        "english": "Italiano",
        "french": "Francese",
        "room": "Camera",
        "create": "CREARE",
        "repeat": "Ripetere",
        "daily": "Tutti i giorni",
        "repeat_every": "Ripetere ogni",
        "repeat_on": "Ripetere su",
        "repeat_by": "Ripetere per",
        "ends": "Fine",
        "after": "Dopo",
        "occurences": "eventi",
        "on": "su",
        "summary": "Sommario",
        "repeats": "Ripetizioni",
        "withdraws": "Ritiri",
        "every": "Ogni",
        "days": "giorni",
        "weeks": "settimane",
        "months": "Mese",
        "onday": "il giorno",
        "years": "anni",
        "times": "volte",
        "until": "fino a",
        "past_meeting_list": "Elenco delle riunioni completate",
        "organised_by": "Organizzato da",
        "delete": "Cancellare",
        "join": "Iscriviti a",
        "to": "A",
        "enter_password": "Inserisci la password",
        "submit": "inviare",
        "enter_passwordmessage": "Inserisci la password",
        "delete_message": "Vuoi cancellare",
        "meeting_permanent": "riunione permanente?",
        "day": "Giorno",
        "password_message": "la password non \u00e8 corretta",
        "timezone": "Fuso orario",
        "by": "Da",
        "weekdays": "[\"DOM\", \"LUN\", \"MAR\", \"MER\", \"GIO\", \"VEN\", \"SAB\"]",
        "allweek": "[\"Domenica\", \"Luned\u00ec\", \"Marted\u00ec\", \"Mercoled\u00ec\", \"Gioved\u00ec\", \"Venerd\u00ec\", \"Sabato\"]",
        "day_month": "giorno del mese",
        "day_week": "giorno della settimana",
        "week": "Settimana",
        "month": "Mese",
        "year": "Anno",
        "enterpassword": "Inserisci la tua nuova password e conferma la password",
        "passwordmessage": "La password e la password di conferma devono essere entrambe uguali",
        "passwordupdate": "Password aggiornata con successo",
        "numeric": "[\"primo\", \"secondo\", \"terzo\", \"quarto\", \"quinto\"]",
        "verify_email": "Verifica la tua e-mail",
        "confirm_code": "Codice di conferma",
        "verify_msg": "Si prega di controllare il codice sul cellulare.",
        "verify": "Verificare",
        "not_recieved": "Non ricevuto?",
        "resend_otp": "Inviare nuovamente OTP",
        "start_browsing": "Avviare la navigazione",
        "become_affiliate": "Diventa Affiliato",
        "affiliate": "Affiliato",
        "verify_mobile": "Verifica il tuo cellulare",
        "instavc_allow_DocumentAccess": "Permettere la condivisione di documenti",
        "camera_not_access": "Telecamera non accessibile a causa di uno dei seguenti motivi:",
        "camera_not_access_reason1 ": "Le impostazioni di risoluzione potrebbero non essere compatibili con questo display. Riconfigurare le impostazioni con una risoluzione pi\u00f9 bassa e riprovare.",
        "camera_not_access_reason2": "La vostra fotocamera \u00e8 utilizzato da diverse applicazioni, Si prega di chiudere tutte le applicazioni e riprovare facendo clic sul link.",
        "asgnQue_Assign_Question ": "Assegnare il questionario",
        "asgnQue_assigned_question": "Questionario assegnato",
        "quslsit_assig_que_list\t": "Elenco dei questionari assegnati",
        "quslsit_question_lists\t": "Elenchi di questionari",
        "quslsit_search\t": "Ricerca ...",
        "quslsit_add_new_question ": "Aggiungi nuovo questionario",
        "quslsit_no_question_assign ": "Non \u00e8 stato ancora assegnato alcun questionario!",
        "quslsit_assign-new_ques": "Assegnare un nuovo Questionario per cliccare sul pulsante Assegnare il Questionario di cui sopra",
        "mdl_quesnr_questionnaire": "Questionario",
        "mdl_quesnr_select_question": "Seleziona il questionario",
        "mdl_quesnr_duration": "Durata",
        "mdl_quesnr_time_limit": "Limite di tempo",
        "mdl_quesnr_show_results": "Mostra i risultati",
        "mdl_quesnr_cancel": "Annulla",
        "mdl_quesnr_save": "SALVARE",
        "viewAsg_question_details": "Dettagli del questionario",
        "viewAsg_content_group": "Gruppo di contenuti",
        "viewAsg_questions": "Domande",
        "viewAsg_question_list": "Elenco dei questionari",
        "viewAsg_list_assigned_ques": "Elenco delle domande assegnate:",
        "viewAsg_mins": "Mins",
        "viewAsg_marks": "Marchi",
        "asgQues_5_Marks": "( 5 Marchi )",
        "asgQues_answers": "Risposte",
        "asgQues_no_questions": "Nessuna domanda!",
        "grdSet_GPA_calculation": "Calcolo dell'AAP in percentuale",
        "grdSet_GPA_cal_msg": "La percentuale \u00e8 del 100%, che non \u00e8 altro che 100 punti. Quindi 25 punti in percentuale equivalgono a 1 punto nel sistema GPA. La formula della percentuale rispetto all'AAP \u00e8 semplicemente dividere la vostra percentuale per 100 e moltiplicare per 4. Se la vostra percentuale \u00e8 del 75%, il vostro voto sar\u00e0 (75\/100)*4 = 3.",
        "grdSet_grade_point": "Se i punti di valutazione sono",
        "mngGrd_grades": "Gradi",
        "mngGrd_cancel": "Annulla",
        "mngGrd_save": "SALVARE",
        "mngGrd_modify": "Modificare",
        "mngGrd_save_grades": "Salvare i gradi",
        "mngGrd_modify_grades": "Modificare i gradi",
        "mngGrd_grade_info": "Informazioni sul grado",
        "admnAns_answer_list": "Elenco delle risposte",
        "admnAns_answer_list_for_question": "Di seguito \u00e8 riportato l'elenco delle risposte a tutti i questionari di questo gruppo di contenuti.",
        "admnAns_answered": "Risposto a",
        "admnAns_correct_answer": "Risposta corretta",
        "prtcpt_certification": "Certificazioni:",
        "prtcpt_other_content_group": "In altri gruppi di contenuto",
        "prtcpt_sales": "Vendite",
        "prtcpt_marketing": "Marketing",
        "prtcpt_human_resource": "Risorse Umane",
        "mngPrtcpt_participants": "Partecipanti",
        "mngPrtcpt_add_participants": "Aggiungi partecipanti",
        "mngPrtcpt_participants_list": "Elenchi dei partecipanti",
        "mngPrtcpt_import_participants": "Partecipanti all'importazione",
        "mngPrtcpt_download_sample": "Scarica il campione",
        "mngPrtcpt_no_participants_added": "Non \u00e8 stato ancora aggiunto nessun partecipante!",
        "mngPrtcpt_add_new_participant": "Aggiungi un nuovo partecipante, per aggiungere un nuovo partecipante clicca sul pulsante Aggiungi partecipante",
        "addPrtcpt_select_participants": "Seleziona i partecipanti",
        "addPrtcpt_model_add_participants": "Aggiungi partecipanti",
        "prtcptGrde_participant_grade": "Dettagli sul grado del partecipante",
        "prtcptGrde_grade_participants_list": "Elenco dei partecipanti",
        "mngQuesnr_questionnaire_list": "Elenco dei questionari",
        "mngQuesnr_create_questionnaire": "Creare un questionario",
        "mngQuesnr_search": "Ricerca ...",
        "mngQues_question_details ": "Dettagli del questionario",
        "mngQues_add_questions": "Aggiungi domande",
        "mngQues_question_list": "Elenco dei questionari",
        "mngQues_questions": "Domande",
        "mngQues_list_assign_question": "Elenco delle domande assegnate:",
        "mngQues_no_question_created": "Non \u00e8 stata ancora creata nessuna domanda!",
        "mngQues_create_new_question": "Creare una nuova domanda, per creare una nuova domanda, cliccare sul pulsante Crea domanda di cui sopra",
        "mdlGrp_questionnaire": "Questionario",
        "mdlGrp_questionnaire_name": "Nome del questionario",
        "mdlGrp_description": "Descrizione",
        "mdlGrp_cancel": "Annulla",
        "mdlGrp_update": "Aggiorna",
        "mdlGrp_create": "CREARE",
        "mdlCrtQues_update_question": "Domanda di aggiornamento",
        "mdlCrtQues_create_questionnaire": "Creare un questionario",
        "mdlCrtQues_enter_question_here": "Inserisci qui la tua domanda",
        "mdlCrtQues_marks": "Marchi",
        "mdlCrtQues_question_type": "Tipo di domanda",
        "mdlCrtQues_sel_question_type": "Selezionare il tipo di domanda",
        "mdlCrtQues_single_sel_multiple_choice": "Singola selezione a scelta multipla",
        "mdlCrtQues_multiselect_multiple_choice": "Multiselezione a scelta multipla",
        "mdlCrtQues_ture_false": "Vero \/ falso",
        "mdlCrtQues_enter_answer_choice": "Inserire le opzioni di risposta e contrassegnare la risposta corretta",
        "mdlCrtQues_add_another_choice": "+ Aggiungi un'altra scelta",
        "mdlCrtQues_cancel": "Annulla",
        "viewQues_questionnaire_details": "Dettagli del questionario",
        "viewQues_add_questions": "Aggiungi domande",
        "viewQues_question_list": "Elenco dei questionari",
        "viewQues_question": "Domande",
        "viewQues_list_assign_questions": "Elenco delle domande assegnate:",
        "viewQues_no_question_created": "Non \u00e8 stata ancora creata nessuna domanda!",
        "viewQues_create_new_question": "Creare una nuova domanda, per creare una nuova domanda, cliccare sul pulsante Crea domanda di cui sopra",
        "crteContent_create_new_content": "Creare nuovi contenuti",
        "crteContent_update_content": "Aggiornare il contenuto",
        "crteContent_update": "Aggiorna",
        "crteContent_save_content": "Salvare il contenuto",
        "crteContent_save": "SALVARE",
        "crteContent_content_group": "Gruppo di contenuti",
        "crteContent_content_list": "Elenchi di contenuti",
        "crteContent_edit_content": "Modificare il contenuto",
        "crteContent_create_content": "Creare contenuti",
        "crteContent_create_your_content_like": "Create i vostri contenuti, aggiungendo supporti come immagini, file video, file audio e documenti.",
        "crteContent_upload_document": "Carica documenti",
        "crteContent_upload_media": "Caricare i media",
        "crteContent_document": "Documentazione",
        "crteContent_media_files": "File multimediali",
        "viewCon_content_view": "Vista del contenuto",
        "viewCon_content_group": "Gruppo di contenuti",
        "viewCon_content_list": "Elenchi di contenuti",
        "viewCon_View_content": "Visualizza il contenuto",
        "viewCon_enable": "Attivare:",
        "viewCon_document": "Documentazione",
        "viewCon_media_files": "File multimediali",
        "viewCon_content": "Contenuto",
        "conRow_enable": "Attivare:",
        "conRow_by": "Da:",
        "conRow_content_group": "Gruppo di contenuti",
        "grpRow_enable": "Attivare:",
        "grpRow_by": "Da:",
        "hozConNav_content": "Contenuto",
        "hozConNav_participants": "Partecipanti",
        "hozConNav_grade": "Gradi",
        "hozConNav_assign_questionnaire": "Assegnare il questionario",
        "mngCon_create_new_content": "Creare un nuovo contenuto, per creare un nuovo contenuto, fare clic sul pulsante Crea contenuto di cui sopra",
        "mngCon_all": "Tutti",
        "mngCon_content_list": "Elenchi di contenuti",
        "mngCon_no_content_created": "Non \u00e8 stato ancora creato alcun contenuto!",
        "mdlAccRight_share_access": "Condividere l'accesso con altri Istruttori",
        "mdlAccRight_type_id_add": "Digitare l'ID e-mail e aggiungere",
        "mdlAccRight_no_instructor_added": "Nessun Istruttore \u00e8 stato ancora aggiunto!",
        "mdlAccRight_add_people_from_addressbok": "Aggiungi persone dalla tua Rubrica, o digitando l'indirizzo e-mail.",
        "mdlAccRight_addressbook": "Rubrica",
        "mdlAccRight_search": "Ricerca ...",
        "mdlAccRight_contacts": "Contatti",
        "mdlAccRight_add_contacts": "Aggiungi contatti al Gruppo di contenuti, dai contatti della tua rubrica",
        "mdlAccRight_no_contacts_added": "Non \u00e8 stato ancora aggiunto alcun contatto!",
        "mdlAccRight_cancel": "Annulla",
        "mdlConfDel_confirmation": "Conferma",
        "mdlConfDel_are_you_sure_delete": "Sei sicuro di voler cancellare questo",
        "mdlConfDel_cancel": "Annulla",
        "mdlConfDel_delete": "Cancellare",
        "mdlCrtConGrp_alert_enter_group_title": "Inserire il titolo del gruppo",
        "mdlCrtConGrp_alert_enter_group_description": "Inserire la descrizione del gruppo",
        "mdlCrtConGrp_content_group": "Gruppo di contenuti",
        "mdlCrtConGrp_group_name": "Nome del gruppo",
        "mdlCrtConGrp_description": "Descrizione",
        "mdlCrtConGrp_cancel": "Annulla",
        "mdlCrtConGrp_save": "SALVARE",
        "mdlUpldoc_alert_enter_title_doc": "Inserire il titolo del documento",
        "mdlUpldoc_upload_document": "Carica documenti",
        "mdlUpldoc_document_title": "Titolo del documento",
        "mdlUpldoc_upload_doc_from_system": "Carica il documento dal tuo sistema, supporta .pdf, excel, documento word.",
        "mdlUpldoc_upload_doc": "Carica il documento",
        "mdlUpldoc_added_doc": "Aggiunta di documenti",
        "mdlUpldoc_cancel": "Annulla",
        "mdlUpldoc_save": "SALVARE",
        "mdlUplMedia_alert_enter_title_media": "Inserire il titolo dei media",
        "mdlUplMedia_alert_enter_title_url": "Inserire il titolo e l'URL",
        "mdlUplMedia_alert_enter_youtube_vimeo_url": "Si prega di inserire valido e URL (Youtube / Vimeo)",
        "mdlUplMedia_upload_media": "Caricare i media",
        "mdlUplMedia_upload_url": "Carica URL",
        "mdlUplMedia_Upload_media_system": "Carica i media dal tuo sistema, supporta .jpg, .png, .jpeg, .mp3, .mp4",
        "mdlUplMedia_add_media_url": "Aggiungere l'indirizzo URL del Media per allegare un Media",
        "mdlUplMedia_upload_media_url": "Carica l'URL dei media",
        "mdlUplMedia_upload_youtube_url": "Nota: Carica gli URL di YouTube",
        "mdlUplMedia_Add_media_file": "Aggiunti file multimediali",
        "mdlUplMedia_cancel": "Annulla",
        "mdlUplMedia_save": "SALVARE",
        "mngContent_content": "Contenuto",
        "mngContent_participants": "Paricipanti",
        "mngContent_grade_settings": "Impostazioni del grado",
        "mngContent_assign_questonnaire": "Assegnare il questionario",
        "content_all_content_group": "Tutti i gruppi di contenuto",
        "content_create_new_group": "Creare un nuovo Gruppo, per creare un nuovo Gruppo, cliccare sul pulsante Crea Gruppo soprastante",
        "content_search": "Ricerca ...",
        "content_no_group_created": "Non \u00e8 stato ancora creato nessun gruppo!",
        "conList_questionnaire": "Questionario",
        "conList_view": "Vedi",
        "conList_all_group": "Tutti i gruppi",
        "conList_content_list": "Elenchi di contenuti",
        "conList_no_content_created": "Non \u00e8 stato ancora creato alcun contenuto!",
        "grpList_all_content_group": "Tutti i gruppi di contenuto",
        "grpList_no_group_created": "Non \u00e8 stato ancora creato nessun gruppo!",
        "questnrList_questionnaire": "Questionario",
        "questnrList_questionnaire_list": "Elenco dei questionari",
        "questnrList_no_question_assign": "Non \u00e8 stato ancora assegnato alcun questionario!",
        "questnrList_marksz": "Marchi",
        "questnrList_mins": "Mins",
        "queStepper_can't_skip": "Non si pu\u00f2 saltare un passaggio che non \u00e8 facoltativo.",
        "queStepper_marks": "Marchi",
        "queStepper_back": "Indietro",
        "queStepper_next": "Avanti",
        "queStepper_finish": "Finitura",
        "queStepper_submit_next": "Invia & Avanti",
        "showCont_content_list": "Elenchi di contenuti",
        "showCont_document": "Documentazione",
        "showCont_media_file": "File multimediali",
        "showCont_previous": "Precedente",
        "showCont_next": "Avanti",
        "showCont_have_completed_topic": "Ho completato gli argomenti di questo contenuto",
        "showMedia_back": "Indietro",
        "showMedia_page": "Pagina",
        "showMedia_of": "di",
        "showMedia_next": "Avanti",
        "showMedia_previous": "Prev",
        "showQues_questionnaire_list": "Elenco dei questionari",
        "showQues_question": "Domande",
        "showQues_marks": "Marchi",
        "showQues_min_left": "min a sinistra",
        "showQues_completed_test": "Avete completato il test in questo questionario.",
        "showQues_go_back_content_group": "Torna al gruppo di contenuti",
        "showQues_list_assign_question": "Elenco delle domande assegnate:",
        "viewUserScore_view_score": "Visualizza il punteggio",
        "viewUserScore_question_list": "Elenco dei questionari",
        "viewUserScore_question": "Domande",
        "viewUserScore_total_marks": "Totale Marchi:",
        "viewUserScore_marks": "Marchi",
        "viewUserScore_scored_marks": "Punteggi ottenuti:",
        "viewUserScore_GPA_score": "Il tuo punteggio GPA:",
        "viewUserScore_answer_list": "Elenco delle risposte",
        "viewUserScore_below_list_of_answer": "Di seguito \u00e8 riportato l'elenco delle risposte a tutti i questionari di questo gruppo di contenuti.",
        "viewUserScore_answered": "Risposto a",
        "viewUserScore_correct_answer": "Risposta corretta",
        "confCancle_confirmation": "Conferma",
        "confCancle_are_you_sure": "Sei sicuro?",
        "confCancle_cancel": "Annulla",
        "confCancle_delete_user": "Cancella utente",
        "confSche_are_you_sure_delete": "Sei sicuro di voler cancellare questo programma?",
        "confSche_no": "No",
        "pastMtnItem_rn": "RN",
        "pastMtnItem_room_name": "Nome della camera",
        "pastMtnItem_webinar_list": "Elenco webinar",
        "pastMtnItem_list_webinar": "Di seguito l'elenco dei webinar.",
        "pastMtnItem_today": "Oggi",
        "pastMtnItem_webinar_title_falls": "Il titolo del webinar cade qui",
        "pastMtnItem_view": "Vedi",
        "pastMtnItem_upcoming": "Prossimo",
        "schedules_upload_custom_banner": "Carica un banner personalizzato per il tuo evento Webinar.",
        "scheView_edit_content": "Modificare il contenuto",
        "scheView_scheduled_by": "Programmato da:",
        "scheView_delete": "Cancellare",
        "scheView_download_calander": "Scaricare Calander",
        "scheView_invite_participants": "Invitare i partecipanti",
        "scheView_start_conference": "AVVIARE LA RIUNIONE",
        "scheView_event_details": "Dettagli dell'evento",
        "scheView_room_name": "Nome della camera",
        "scheView_duration": "Durata",
        "scheView_minutes": "minuti",
        "scheView_conference_password": "Password della riunione",
        "scheView_settings": "Impostazioni",
        "scheView_webinar_event": "Evento webinar",
        "scheView_list_webinar": "Elenca questo evento webinar su instavc.com\/webinar e accetta le registrazioni.",
        "scheView_guest_login_anonymous": "L'ospite pu\u00f2 accedere come Anonimo",
        "scheView_allow_invities": "Consentire solo le invidie di partecipare alla riunione",
        "scheView_enable_webinar_chat": "Attivare la chat del webinar",
        "scheView_enable_hand_rise": "Abilitare l'alzata della mano nel webinar",
        "scheView_list_of_people_invited": "Di seguito l'elenco delle persone invitate",
        "scheView_alert_err_to_join": "Errore di iscrizione",
        "webnrDtls_room_name": "Nome della camera",
        "webnrDtls_upcoming_webinar_room": "Qui di seguito il prossimo webinar per questa sala.",
        "webnrDtls_webinar_details": "Dettagli del webinar",
        "webnrDtls_webinar_list": "Elenco dei webinar",
        "webnrDtls_webinar_title": "Titolo del webinar",
        "webnrDtls_webinar_title_falls": "Il titolo del webinar cade qui",
        "webnrDtls_get_more_joining": "Ottieni di pi\u00f9 partecipando a questo webinar.",
        "webnrDtls_join_webinar": "Partecipa al webinar",
        "webnrList_room_name": "Nome della camera",
        "webnrList_webinar_list": "Elenco webinar",
        "webnrList_below_list_of_webinar": "Di seguito l'elenco dei webinar.",
        "webnrList_today": "Oggi",
        "webnrList_webinar_title_falls": "Il titolo del webinar cade qui",
        "webnrList_view": "Vedi",
        "webnrList_upcoming": "Prossimo",
        "addBook_address_book": "Rubrica",
        "addBook_contact": "Contatti",
        "addBook_add_contact_to_meeting": "Aggiungi contatti alla riunione, dai contatti della tua rubrica",
        "meetingList_past_meetings": "Riunioni completate",
        "meetingList_past_meetings_list": "Elenco delle riunioni completate",
        "meetingList_participants": "Partecipanti",
        "meetingList_room_name": "Nome della camera:",
        "meetingList_view_content": "Visualizza il contenuto",
        "meetingList_recording": "Registrazioni",
        "meetingList_view": "Vedi",
        "scheInvt_err_invalid_id": "ID e-mail non valido",
        "scheInvt_invite_participants": "Invitare i partecipanti",
        "scheInvt_address_book": "Rubrica",
        "scheInvt_invite_people_to_meeting": "Invita persone a questa riunione. Aggiungi persone dalla tua rubrica o inserendo l'indirizzo email di seguito e facendo clic su \"Aggiungi\".",
        "scheInvt_start": "Inizia @",
        "scheInvt_below_list_of_answer": "Di seguito \u00e8 riportato l'elenco delle risposte a tutti i questionari di questo gruppo di contenuti.",
        "scheInvt_room_name": "Nome della camera:",
        "scheInvt_host": "Ospite:",
        "scheInvt_minutes": "minuti",
        "scheInvt_add": "Aggiungi",
        "scheInvt_no_participants_added": "Nessun partecipante aggiunto",
        "scheInvt_add_ppl_to_meetings": "Aggiungi persone a questa riunione come partecipanti dalla tua rubrica o aggiungili sopra e fai clic su \"Aggiungi\".",
        "scheInvt_cancel": "Annulla",
        "scheInvt_send_invitation": "invia invito",
        "scheInvt_send_passcodes": "Invia i codici di accesso in un'e-mail separata dall'invito",
        "scheMeet_room_reserved_from": "Camera riservata da",
        "scheMeet_meeting_name": "con nome della riunione",
        "invitBody_invite_only_upto": "\u00c8 possibile invitare solo fino a 10 persone",
        "invitBody_invite_ppl_meeting_email_add": "Invita le persone a questo incontro, aggiungi persone dalla tua Rubrica o aggiungi per indirizzo e-mail.",
        "invitBody_copy": "Copia",
        "invitBody_enter_emailid": "Inserire l'ID e-mail",
        "invitBody_add": "Aggiungi",
        "invitBody_close": "Chiudere",
        "invitBody_send_invitation": "invia invito",
        "docShare_doc_sharing": "Condivisione dei documenti",
        "docShare_upload_doc_system_pdf": "Carica il documento dal tuo sistema, supporta .pdf, excel, documento word.",
        "docShare_upload_doc": "Carica il documento",
        "docShare_no_doc": "Nessun documento",
        "docShare_no_doc_in_list": "Non ci sono ancora documenti aggiunti alla lista.",
        "docShare_add_doc": "Aggiunta di documenti",
        "docShare_alert_title_doc": "Inserire il titolo del documento",
        "docShare_alert_something_went_wrong": "qualcosa \u00e8 andato storto, per favore riprova",
        "docShare_alert_try_again": "Riprovaci, per favore",
        "permitDocShr_permit_doc_share_access": "Permettere l'accesso alla condivisione dei documenti",
        "permitDocShr_req_permission_doc_share": "ha richiesto l'autorizzazione ad accedere alla condivisione dei documenti.",
        "permitDocShr_deny": "Negare",
        "permitDocShr_allow": "Consentire",
        "reqDocShr_req_doc_share_access": "Richiesta di accesso alla condivisione di documenti",
        "reqDocShr_access_permission_host": "Per abilitare la condivisione dei documenti, \u00e8 necessario il permesso di accesso da parte dell'host.",
        "reqDocShr_plz_wait": "Si prega di attendere",
        "reqDocShr_permission_start_doc_share": "(Host) autorizzazione ad avviare la condivisione dei documenti.",
        "reqDocShr_cancel": "Annulla",
        "reqDocShr_doc_share": "Condividi il documento!",
        "showDocViewback": "Indietro",
        "showDocViewdoc_title_name": "Titolo del documento Nome del documento",
        "showDocViewnext": "Avanti",
        "showDocViewprevious": "Prev",
        "showDocViewpage_of": "pagina di",
        "borcstScreen_alert_unsupported": "Ordine di gioco non supportato richiesto. I formati supportati sono webrtc e hls. Utilizzare qualcosa come playOrder=webrtc hls",
        "borcstScreen_alert_no_stream_spec": "Nessun flusso specificato. Si prega di aggiungere id={STREAM_ID} all'URL",
        "borcstScreen_Plz_enable_js": "Per visualizzare questo video si prega di abilitare JavaScript, e considerare l'aggiornamento ad un browser web che",
        "borcstScreen_support_HTML5": "supporta il video HTML5",
        "borcstScreen_steam_play_when_live": "Il vapore video verr\u00e0 riprodotto in diretta",
        "broadcast_alert_unsupported": "Ordine di gioco non supportato richiesto. I formati supportati sono webrtc e hls. Utilizzare qualcosa come playOrder=webrtc hls",
        "broadcast_alert_no_stream_spec": "Nessun flusso specificato. Si prega di aggiungere id={STREAM_ID} all'URL",
        "broadcast_enter_your_name": "Inserisci il tuo nome",
        "broadcast_enter_your_email": "Inserisci il tuo indirizzo e-mail",
        "broadcast_user_details": "Dettagli utente",
        "broadcast_submit_next": "inviare",
        "broadcast_Plz_enable_js": "Per visualizzare questo video si prega di abilitare JavaScript, e considerare l'aggiornamento ad un browser web che",
        "broadcast_support_HTML5": "supporta il video HTML5",
        "broadcast_steam_play_when_live": "Il vapore video verr\u00e0 riprodotto in diretta",
        "brdcstConf_whiteboard": "Lavagna bianca",
        "brdcstConf_screen_share": "Condivisione dello schermo",
        "brdcstConf_participants": "Partecipanti",
        "brdcstConf_broadcast": "Trasmissione",
        "brdcstConf_cancel": "Annulla",
        "brdcstConf_right": "a destra",
        "brdcstPart_broadcast_list": "Elenco delle emittenti",
        "calender_scheduled_calendar": "Calendario",
        "calender_select_all": "Seleziona tutti",
        "calender_calender": "Calendario",
        "calender_daily": "Giorno",
        "calender_weekly": "Settimanale",
        "calender_month": "Mese",
        "calender_today": "Oggi",
        "contact_alert_already_in_call": "Non \u00e8 possibile chiamare un altro utente, quando si \u00e8 gi\u00e0 chiamati",
        "contact_alert_user_offline": "Utente Offline",
        "meetReminder_scheduled_confirm": "Conferma del programma",
        "meetReminder_meeting_has_been_scheduled": "In questo momento \u00e8 stato programmato il seguente incontro",
        "meetReminder_start_meeting": "Inizia questa riunione",
        "meetReminder_join_meeting": "Partecipa a questo incontro",
        "meetReminder_quick_start_another_meeting": "Avvio istantaneo di un'altra riunione",
        "myMeetItem_start": "Iniziare",
        "myMeetItem_for": "per",
        "myMeetItem_minutes": "minuti",
        "myMeetItem_host": "Ospite:",
        "myMeet_my_meetings": "I miei incontri",
        "myMeet_search_my_meetings": "Cerca i miei incontri",
        "noScheMeet_no_meeting_scheduled\t": "o Riunioni in programma",
        "noScheMeet_no_upcoming_meetings\t": "Non avete in programma riunioni imminenti. Potete creare un nuovo programma o iniziare subito la vostra riunione.",
        "noScheMeet_create_schedule": "Creare un programma",
        "noScheMeet_quick_start": "Avvio istantaneo",
        "noScheMeet_or": "OPPURE",
        "roomSet_alert_select_license": "Selezionare la licenza",
        "roomSet_manage_licenses": "Gestire le licenze",
        "roomSet_package": "Pacchetto",
        "roomSet_no_of_rooms": "Numero di camere",
        "roomSet_no_of_user": "Numero di utenti",
        "roomSet_purchased_on": "Acquistato il",
        "roomSet_expired_on": "Scaduto il",
        "roomSet_create_room": "Creare una camera",
        "dashboard_alert_cannot_start": "Non \u00e8 possibile avviare la riunione, quando si \u00e8 in un'altra chiamata",
        "dashboard_alert_cannot_join": "Non puoi partecipare alla riunione, quando sei in un'altra chiamata",
        "dashboard_quick_start_conf": "RIUNIONE IMMEDIATA",
        "dashboard_loading": "Caricamento...",
        "dashboard_maintenance_msg": "Si prega di notare che il 22 aprile 2020 dalle 20.30 alle 21.00 a causa di una manutenzione ordinaria, il server sar\u00e0 inattivo il 22 aprile 2020.",
        "addCam_add_device": "Aggiungi dispositivo",
        "addSipDev_add_sip_details": "Aggiungi dettagli SIP",
        "addSipDev_sip_address": "Indirizzo SIP:",
        "addSipDev_connect": "Collegati a",
        "addSipDev_enter_sip_address": "Inserire l'indirizzo SIP",
        "confNotf_notification": "Notifiche",
        "confNotf_user_name": "Nome utente",
        "confNotf_want_to_join": "vuole entrare in questa sala riunioni.",
        "confNotf_allow": "Consentire",
        "confNotf_deny": "Negare",
        "confNotf_ask_question": "ha fatto una domanda.",
        "confNotf_send_msg_lobby": "ha inviato un messaggio dalla lobby.",
        "lobby_participants_loddy": "Partecipanti nella lobby",
        "lobby_pramote_all": "Pramote Tutti",
        "lobby_user_name": "Nome utente",
        "removeUserDlg_manage_user_selecting": "Gestire questo utente selezionando una qualsiasi di queste opzioni:",
        "removeUserDlg_user_name": "Nome utente",
        "removeUserDlg_block_participant": "Partecipante del blocco",
        "removeUserDlg_send_to_lobby": "Invia a Lobby",
        "removeUserDlg_remote_participant": "Rimuovere il partecipante",
        "removeUserDlg_close": "Chiudere",
        "PartList_participants_in_lobby": "Partecipanti nella lobby",
        "PartList_alert_something_went_wrong": "Qualcosa \u00e8 andato storto, Riprovaci, per favore",
        "PartList_in_webinar": "In Webinar",
        "PartList_promote_all": "Promuovi tutti",
        "waitingRoom_waiting_room": "Sala d'attesa",
        "waitingRoom_plz_wait_host_allow": "Si prega di attendere fino a quando l'organizzatore non vi permetter\u00e0 di partecipare alla riunione.",
        "waitingRoom_chat": "Chat",
        "waitingRoom_mins": "Mins",
        "waitingRoom_no_msg_yet": "Nessun messaggio, ancora!",
        "waitingRoom_no_msg_from_host": "Nessun messaggio da parte dell'host.",
        "waitingRoom_say_something": "Dite qualcosa!",
        "userRemoved_you_removed_by_host": "Sei stato rimosso dall'elenco dei partecipanti dall'host",
        "invitSlide_invite": "Invitare",
        "invitSlide_add_email": "Aggiungere e-mail",
        "invitSlide_add": "Aggiungi",
        "invitSlide_broadcast_list": "Collegamento radiotelevisivo",
        "invitSlide_invite_people_to_meeting": "Invitare le persone a questo incontro, digitare e aggiungere e-mail alla lista per inviare l'invito.",
        "invitSlide_err_invalid_id": "ID e-mail non valido",
        "particiChat_say_something": "Dite qualcosa!",
        "particiChat_no_chat_msg": "Ancora nessun messaggio in chat!",
        "particiChat_start_conversation": "Avvia la conversazione per vedere i messaggi qui.",
        "coBrows_host_share-video": "Aspetta, per favore! L'ospite sta per condividere un video",
        "lobbyPartici_no_participants": "Nessun partecipante",
        "lobbyPartici_no_participants_lobby": "Non ci sono partecipanti nella lobby.",
        "simulcast_alert_decline_access": "L'ospite ha rifiutato l'accesso",
        "simulcast_invalid_url": "URL non valido",
        "simulcast_no_host_available": "Sembra che non ci sia un host disponibile in questo momento, Si prega di diventare host per condividere lo schermo",
        "conference_access_to_participants": "Accesso ai partecipanti",
        "conference_screen_share": "Condivisione dello schermo",
        "conference_chat": "Chat",
        "conference_lock_all": "Blocca tutto",
        "conference_lock_conf": "Riunione sulla chiusura",
        "conference_become_host": "Diventa il padrone di casa",
        "conference_you_are_host": "Sei il padrone di casa",
        "conference_share": "Condividi",
        "conference_document_share": "Condividi il documento",
        "conference_content_share": "Condivisione dei contenuti",
        "conference_more": "Di pi\u00f9...",
        "conference_alert_no_host_aval_screen": "Sembra che non ci sia un host disponibile in questo momento, Si prega di diventare host per condividere lo schermo",
        "conference_alert_no_host_aval_doc": "Sembra che non ci sia un host disponibile in questo momento, Si prega di diventare host per condividere il documento",
        "conference_alert_err_sip_device": "Errore durante il collegamento del sifone",
        "conference_alert_host_decline_access": "L'ospite ha rifiutato l'accesso",
        "conference_alert_invalid_url": "URL non valido",
        "conference_unmute_mic_start_talk": "Disattivare il microfono per iniziare a parlare",
        "confTools_new_whiteboard": "La tua nuova lavagna bianca arriva qui",
        "confTools_video_sync": "Il tuo Video Sync",
        "confTools_microphone": "Microfono",
        "confTools_type_text_here": "Scrivi il tuo testo...",
        "confTools_whiteboard": "Lavagna bianca",
        "confLibs_alert_broadcast_screen_failed": "Schermo di trasmissione non funzionante",
        "confLibs_broadcast_failed": "Trasmissione non riuscita",
        "chatContainer_say_something": "Dite qualcosa!",
        "chatContainer_no_chat_msg": "Ancora nessun messaggio in chat!",
        "chatContainer_start_conversation": "Avvia la conversazione per vedere i messaggi qui.",
        "fmyRoom_ree": "Gratuito",
        "meetRec_video_rec_meeting_name": "Registrazioni video del nome della riunione",
        "meetRec_page_view": "Vista della pagina:",
        "recListView_title_name_rec": "Titolo Nome della registrazione",
        "recListView_name_user": "Nome dell'utente",
        "instavc_allow_Notes": "Note sul permesso",
        "brwUpg_req_fun_not_avail": "Funzionalit\u00e0 richieste non disponibili nel browser",
        "brwUpg_meeting_req_fun_sup": "Questo servizio di incontri richiede una funzionalit\u00e0 non supportata dal vostro browser.",
        "brwUpg_plz_upgrade": "Si prega di aggiornare, passare a un altro browser o controllare le impostazioni. Browser supportati:",
        "header_content": "Contenuto (Beta)",
        "header_loading": "Caricamento...",
        "crtePln_plz_enter_name": "Inserire il nome del piano",
        "crtePln_plz_amount_Plan": "Inserire l'importo del piano",
        "crtePln_plz_enter_des": "Inserire la descrizione",
        "crtePln_Plz_sel_product": "Selezionare il prodotto",
        "crtePln_plz_sel_no_host": "Si prega di selezionare il numero di host",
        "crtePln_plz_enter_no_participants": "Inserire il numero di partecipanti",
        "crtePln_plz_enter_roomno": "Si prega di inserire il numero di camere",
        "crtePln_select_period": "Selezionare il periodo",
        "crtePln_sel_interval": "Selezionare l'intervallo",
        "crtePln_update_succ": "Aggiornato con successo",
        "crtePln_err_creating_plan": "Errore nella creazione del piano",
        "brdcRaise_alert_question_50_char": "La tua domanda dovrebbe avere almeno 50 caratteri-chat",
        "cofCom_no_part_lobby": "Non c'\u00e8 nessun partecipante nella lobby.",
        "cofCom_no_participants": "Nessun partecipante",
        "cofCom_say_something": "Dite qualcosa!",
        "confmUser_plz_provide_name_email": "Si prega di fornire il proprio nome ed e-mail",
        "confmUser_notes": "Note",
        "confSetup_telepresence": "Telepresenza",
        "confSetup_alert_not_generate_meetingID": "Non \u00e8 in grado di generare l'ID della riunione, provare dopo un po' di tempo",
        "confSetup_auth_part_can_join": "Solo i Partecipanti Autorizzati possono aderire!",
        "confSetup_invalid_link": "Collegamento non valido",
        "confSetup_meeting_notyet_started": "Questo incontro non \u00e8 ancora iniziato. Per favore, unisciti a noi pi\u00f9 tardi!. Se sei un Host, accedi a instaVC e inizia la riunione",
        "confSetup_meeting_locked_byhost": "Questo incontro \u00e8 bloccato da Host",
        "confSetup_meeting_notyet_started_wait_host": "Questo incontro non \u00e8 ancora iniziato. Si prega di attendere l'inizio della riunione!",
        "newChat_panel_chat": "Chat del pannello",
        "newChat_webinar_chat": "Webinar Chat",
        "instaVc_server_busy": "Sembra che il server sia occupato! Provare a connettere un altro server...",
        "instaVc_server_busy_reload_tojoin": "Sembra che il server sia occupato! Ricarica per partecipare alla riunione",
        "instaVc_broadcast_scr_failed": "Schermo di trasmissione non funzionante",
        "instaVc_broadcast_failed": "Trasmissione non riuscita",
        "instaVc_waiting_for_host": "Aspettando il video dell'ospite",
        "modlCreate_plz_enter_title": "Inserire il titolo",
        "modlCreate_questionnaire": "Questionario",
        "modlCreate_questionnaire_name": "Nome del questionario",
        "modlCreate_desc": "Descrizione",
        "modlCreate_cancle": "Annulla",
        "modlCreate_poll": "Sondaggio",
        "mdlCretPoll_plz_enter_quest": "Inserisci la domanda",
        "mdlCretPoll_option_should_not_same": "Le opzioni non dovrebbero essere le stesse",
        "mdlCretPoll_plz_enter_option": "Inserire le opzioni",
        "mdlCretPoll_plz_select_type_option": "Selezionare il tipo di opzione",
        "mdlCretPoll_alert_server_error": "Errore interno del server, riprovare",
        "mdlCretPoll_update_ques": "Domanda di aggiornamento",
        "mdlCretPoll_create_ques": "Crea domanda",
        "mdlCretPoll_Question": "Domanda",
        "mdlCretPoll_enter_que_here": "Inserisci qui la tua domanda",
        "mdlCretPoll_single_sel_multi_choice": "Singola selezione a scelta multipla",
        "mdlCretPoll_enter_ans_choice": "Inserire le opzioni di risposta e contrassegnare la risposta corretta",
        "mdlCretPoll_end": "fine",
        "mdlCretPoll_cancle": "Annulla",
        "viewQues_viewQuest_delete_successfully": "Cancellato con successo",
        "viewQues_viewQuest_server_error": "Errore interno del server, riprovare",
        "viewQues_viewQuest_plz_try_again": "Riprovaci, per favore",
        "mngContent_mngContent_content": "Contenuto",
        "mngContent_mngContent_participants": "Partecipanti",
        "mngContent_mngContent_grade": "Gradi",
        "mngContent_mngContent_assign_quest": "Assegnare il questionario",
        "mngContent_mngContent_fav_prog_lang": "Qual \u00e8 il vostro linguaggio di programmazione preferito?",
        "mngContent_mngContent_grade_settings": "Impostazioni del grado",
        "mngContent_mngContent_pollings": "Sondaggi",
        "questStepper_you_cant_skip": "Non si pu\u00f2 saltare un passaggio che non \u00e8 facoltativo.",
        "questStepper_back": "Indietro",
        "questStepper_skip": "Salta",
        "questStepper_next": "Avanti",
        "showQue_obtained_marks": "Marchi ottenuti:",
        "viewUser_your_grade": "Il tuo grado:",
        "package_one_host": "1 Ospite e",
        "package_participants": "Partecipanti",
        "package_recordings": "Registrazione",
        "package_concurrentUser_room2": "20 Utenti contemporanei b\ufffd 2 Camere",
        "package_concurrentUser_room5": "30 Utenti contemporanei -5 Camere",
        "package_concurrentUser_room20": "100 Utenti contemporanei b\ufffd 20 Camere",
        "package_concurrentUser_room30": "200 Utenti contemporanei - 30 Camere",
        "package_concurrentUser_room50": "500 Utenti contemporanei b\ufffd 50 Camere",
        "pastMeeting_chat_history_deleted": "la cronologia delle chat \u00e8 stata cancellata con successo",
        "pastMeeting_plz_try_again": "Riprovaci, per favore",
        "pastMeeting_past_meeting_dtls": "Dettagli della riunioni completate",
        "pastMeeting_past_meeting": "Riunioni completate",
        "pastMeeting_room_name": "Nome della camera:",
        "pastMeeting_duration": "Durata:",
        "pastMeeting_download_parti_atten": "Scarica Partecipanti & Partecipanti",
        "pastMeeting_download_webinar": "Scarica il webinar",
        "pastMeeting_participants": "Partecipanti",
        "pastMeeting_attendees": "Partecipanti",
        "pastMeeting_chat_history": "Storia della chat",
        "pastMeeting_notes": "Note",
        "pastMeeting_recordings": "Registrazioni",
        "pastMeeting_webinar_user": "Utenti del webinar",
        "pastMeeting_webinar_chat": "Webinar Chat",
        "pastMeeting_no_participants_in_meeting": "Nessun partecipante a questo incontro!",
        "pastMeeting_no_parti_attended_meeting": "Non ci sono stati partecipanti a questo incontro.",
        "pastMeeting_guest_list": "Elenco degli ospiti",
        "pastMeeting_no_guest_in_meeting": "Nessun Ospite in questo incontro!",
        "pastMeeting_no_guests_were_invited ": "Non ci sono stati ospiti che sono stati invitati o hanno partecipato a questo incontro.",
        "pastMeeting_meeting_chat_history": "Storia della chat delle riunioni",
        "pastMeeting_delete_all": "Cancella tutto",
        "pastMeeting_no_chat_msg_inmeeting": "Nessun messaggio in chat in questo incontro!",
        "pastMeeting_no_conver_during_meeting": "Non ci sono state conversazioni in chat durante l'incontro.",
        "pastMeeting_no_user_inwebinar": "Nessun utente in questo webinar!",
        "pastMeeting_no_user_attand_webinar": "Non ci sono stati utenti che hanno partecipato a questo webinar.",
        "pastMeeting_webinar_chat_history": "Storia della chat del webinar",
        "pastMeeting_no_chat_msg_inwebinar": "Nessun messaggio di chat in questo webinar!",
        "pastMeeting_no_conver_during_webinar": "Non ci sono state conversazioni in chat durante il webinar.",
        "pastMeeting_del_chat_history": "Cancellare la cronologia della chat",
        "pastMeeting_delete_all_chat_history": "Sei sicuro di voler cancellare definitivamente la cronologia delle chat?",
        "pastMeeting_cancle": "Annulla",
        "pastMeeting_delete": "Cancellare",
        "schedul_webinar_event": "Evento webinar",
        "schedul_webinar_description": "Descrizione del webinar",
        "schedul_upload_custom_banner": "Carica un banner personalizzato per il tuo evento Webinar.",
        "schedul_setting": "Impostazioni",
        "schedulView_time": "Tempo",
        "schedulView_edit": "Modifica",
        "schedulView_enable_training_mode": "Attivare la modalit\u00e0 di addestramento",
        "schedulView_audio_conference": "Audio Riunione",
        "schedulView_enable_mix_video": "Abilita il video mix per impostazione predefinita",
        "questions": "Domande",
        "Save": "SALVARE",
        "answer_list": "Elenco delle risposte",
        "answer": "Risposte",
        "answered": "Risposto a",
        "correct_answer": "Risposta corretta",
        "participants": "Partecipanti",
        "please_try_again": "Riprovaci, per favore",
        "search": "Ricerca ...",
        "question": "Domanda",
        "questionnaire": "Questionario",
        "content": "Contenuto",
        "address_book": "Rubrica",
        "view": "Vedi",
        "rn": "RN",
        "quslsit_assig_que_list": "Elenco dei questionari assegnati",
        "quslsit_question_lists": "Elenchi di questionari",
        "quslsit_search": "Ricerca ...",
        "quslsit_assign_new_ques": "Assegnare un nuovo Questionario per cliccare sul pulsante Assegnare il Questionario di cui sopra",
        "select_questionnaire": "Seleziona il questionario",
        "duration": "Durata",
        "time_limit": "Limite di tempo",
        "show_results": "Mostra i risultati",
        "content_group": "Gruppo di contenuti",
        "list_assigned_ques": "Elenco delle domande assegnate:",
        "mins": "Mins",
        "marks": "Marchi",
        "questionnaire_list": "Elenco dei questionari",
        "edit_content": "Modificare il contenuto",
        "queStepper_cant_skip": "Non si pu\u00f2 saltare un passaggio che non \u00e8 facoltativo.",
        "queStepper_skip": "Salta",
        "alert_server_error": "Errore interno del server, riprovare",
        "delete_successfully": "Cancellato con successo",
        "pastMeeting_rn": "RN",
        "plz_enter_marks": "Indicare il numero di punti assegnati a questa domanda",
        "plz_sel_crtans": "Selezionare la risposta corretta",
        "truefalse_choice": "Scelta vera \/ falsa",
        "questionnarie": "Questionnarie",
        "content_title": "Titolo del contenuto",
        "media_title": "Titolo mediatico",
        "add_url_add": "Aggiungi l'indirizzo URL...",
        "val_shuld_grtr_from_val": "a valore dovrebbe essere maggiore che da Valore",
        "val_shuld_grtr_grade": "dal valore dovrebbe essere maggiore di Grado",
        "to_val": "da valutare",
        "enable_hand_raise": "Attivare l'alzata di mano \/ domanda di richiesta di informazioni in un webinar",
        "enable_waiting_room": "Attivare Sala d'attesa",
        "allow_only_invite": "Consentire solo agli invitati di partecipare alla riunione (protetto da password)",
        "guest_login_anonymous": "38\/5000\nL'ospite pu\u00f2 accedere in modo anonimo",
        "give_name_webinar": "Dai un nome al tuo webinar",
        "Webinar": "Webinar",
        "polls_list": "Elenco dei sondaggi",
        "no_poll_assign": "Non sono stati ancora assegnati sondaggi.",
        "poll_view": "Vista del sondaggio",
        "filter_block": "blocco filtro",
        "file_size": "Dimensione del file",
        "select_room": "Si prega di selezionare una camera",
        "provide_event_name": "Si prega di fornire il nome dell'evento",
        "end_time_greater_start_time": "Il tempo di fine deve essere maggiore dell'ora di inizio!",
        "invite_people_meeting": "Invitare le persone a questo incontro",
        "download_ical": "Scaricare iCal",
        "download": "Scaricare",
        "delete_meeting": "Cancella questo incontro",
        "new_event": "Nuovo evento",
        "end_repeat": "fine ripetizione",
        "invite_users_timezone": "invita gli utenti con il seguente fuso orario",
        "update_event": "Aggiornare l'evento",
        "create_event": "Creare un evento",
        "wrong_password": "Password errata",
        "college": "Collegio",
        "university": "Universit\u00e0",
        "country": "Paese",
        "time_period": "Periodo di tempo:",
        "currently_working_here": "Attualmente lavora qui",
        "from": "Da:",
        "graduated": "Laureato",
        "enter_description": "Inserisci qui la tua descrizione...",
        "concentration": "Concentrazione",
        "school": "Scuola",
        "poll_question": "Sondaggio Domanda",
        "create_poll_ques": "Crea domanda di sondaggio",
        "options": "opzioni",
        "no_ques_assigned_yet": "Nessuna domanda \u00e8 stata ancora assegnata!",
        "create_new_ques_click_create_ques": "Creare una nuova domanda, per cliccare sul pulsante Crea domanda di cui sopra",
        "enter_room_name": "Inserire il nome della camera",
        "billed_annually": "* Fatturato ogni anno",
        "subscribe_now": "Iscriviti ora",
        "group": "Gruppo",
        "sample_webinar": "Webinar campione",
        "webinar_affiliate": "Webinar sugli affiliati",
        "webinar_one": "Webinar Uno",
        "workspace": "Area di lavoro",
        "company": "Azienda",
        "add_another": "Aggiungi un altro",
        "assignment": "Compiti",
        "assignments": "Compiti",
        "create_new_poll": "Crea una nuova domanda di sondaggio per assegnarla",
        "plz_title_url": "Inserisci titolo e Url",
        "votes": "Voti",
        "enter_content_title": "Inserire il titolo del contenuto",
        "failed_result": "risultato non riuscito",
        "removed_successfully": "Rimosso con successo",
        "enter_duration": "Inserire la durata",
        "select_questionnarie": "Selezionare la questionnarie",
        "no_assignments": "Nessuna assegnazione",
        "create_new_assignment": "Crea una nuova attivit\u00e0, per creare fai clic sul pulsante Crea attivit\u00e0 sopra",
        "create_assignment": "Crea un'attivit\u00e0",
        "assignments_list": "Elenco delle attivit\u00e0",
        "assignment_description": "Descrizione dell'attivit\u00e0",
        "assignment_name": "Nome dell'attivit\u00e0",
        "update_assignment": "Aggiornare l'assegnazione",
        "add_new_assignment": "aggiungere una nuova attivit\u00e0",
        "assignment_list": "Elenco delle attivit\u00e0",
        "assign_assignments": "Assegnazione di compiti",
        "assigned_assignments_list": "Elenco delle attivit\u00e0 assegnate",
        "assignment_answer": "Risposta all'attivit\u00e0",
        "no_assig_submitted": "Non sono stati ancora presentati incarichi.",
        "no_assig_submitted_participants": "Non sono stati ancora presentati incarichi da parte dei partecipanti.",
        "view_assignment": "Vedi l'attivit\u00e0",
        "assigned_participants": "Partecipanti Assegnati",
        "select_assignment": "Selezionare l'assegnazione",
        "fill_your_assignment": "Compila il tuo incarico",
        "submit_assignment": "Invia un'attivit\u00e0",
        "assignment_details": "Dettagli dell'attivit\u00e0",
        "assignment_submit_success": "Compito inviato correttamente",
        "assignment_saved_success" : "Compito salvato con successo",
        "how_to_get_started": "Come iniziare.",
        "create_new_room": "Creare una nuova camera",
        "create_room_add_participants": "Creare una nuova sala e aggiungere i partecipanti alla sala.",
        "repeats_every": "Ripete ogni",
        "now": "Ora",
        "Plz_enter_title_meeting": "Inserire il titolo dell'incontro",
        "no_start_time": "Nessun tempo di inizio trovato",
        "no_end_time": "Nessun tempo di fine trovato",
        "item_id_not_found": "ID articolo Non trovato",
        "msg_title_not_found": "Titolo del messaggio Non trovato",
        "plz_join_conf_clicking_link": "Si prega di partecipare alla riunione cliccando sul seguente link",
        "hi_there": "Ciao,",
        "room_permanently": "Camera Permanente?",
        "sure_want_to_delete_participants": "Sei sicuro di voler cancellare questo partecipante?",
        "delete_participant": "Cancella partecipante",
        "add_email": "Aggiungi e-mail",
        "add_participants_to_the_room": "Aggiungete i partecipanti a questa sala digitando il loro indirizzo e-mail nella casella di input sottostante.",
        "no_of_users": "Numero di utenti",
        "edit_the_name": "\u00c8 possibile modificare il nome e il numero di utenti di questa stanza.",
        "delete_room": "Cancella Camera",
        "are_you_sure_delete": "Sei sicuro, vuoi cancellare",
        "delete_this_room": "Cancella questa stanza",
        "delete_room_will_parmanently": "Facendo clic su Elimina, questa parte verr\u00e0 eliminata in modo permanente",
        "enable_storage": "Abilita lo stoccaggio",
        "enable_recordings": "Attivare le registrazioni",
        "enable_SIP": "Abilita SIP",
        "addons": "Moduli aggiuntivi",
        "expires_on": "Scade il",
        "purchased_on": "Acquistato il",
        "more_storage": "Pi\u00f9 stoccaggio",
        "total_storage": "Stoccaggio totale",
        "storage_occupied": "Deposito occupato",
        "added": "Aggiunto",
        "open_graphs": "Grafici aperti",
        "participant_controls": "Controlli dei partecipanti",
        "dashboard_controls ": "Controlli del cruscotto",
        "start_meeting": "Inizia una riunione",
        "join_a_meeting": "Partecipare a una riunione",
        "meeting_settings": "Impostazioni della riunione",
        "videos_to_get_started": "Video per iniziare",
        "if_you_are_host": "Se sei un ospite, clicca il pulsante qui sotto per iniziare la riunione",
        "guest_link": "Link degli ospiti",
        "pay_now": "PAGARE ORA",
        "promo_code_apply_here": "Hai un codice promozionale? Richiedi qui",
        "per_month": "al mese",
        "billed_for": "fatturato per",
        "gst_applicable": "In conformit\u00e0 con le norme governative, un ulteriore 18% di GST sar\u00e0 applicabile per i clienti in India.",
        "select_country_code": "Seleziona il codice del paese",
        "country_code": "Codice paese",
        "enter_email_id": "Inserire l'ID e-mail ...",
        "downLoad_chat": "Chat DownLoad",
        "allow_guest_control_their": "Permettere agli ospiti di controllare il loro video\/audio",
        "below_list_people_invited": "Di seguito l'elenco delle persone invitate",
        "verification_code": "Codice di verifica",
        "open_link_enter_verify_code": "Aprite il seguente link URL e inserite il vostro codice di verifica per l'integrazione",
        "social_media_integration": "Integrazione con i social media",
        "integrate_social_media_platform": "\u00c8 possibile integrare piattaforme di social media, cliccando un pulsante, si otterr\u00e0 il codice di verifica, utilizzarlo per l'integrazione.",
        "plz_enter_val_email": "Inserisci un'e-mail valida",
        "record": "Registrare",
        "in_conference": "In Riunione",
        "password_if_any": "Password (se presente)",
        "stop_send": "Stop &amp; Inviare",
        "broadcast_not_enabled_meeting": "Trasmissione non abilitata per questo incontro",
        "facebook_url": "URL Facebook",
        "enter_facebook_url": "Inserisci l'URL RTMB di Facebook",
        "add_facebook_url": "Aggiungi l'URL RTMP di Facebook",
        "youtube_url": "URL Youtube",
        "enter_youtube_url": "Inserisci l'URL di Youtube RTMB",
        "add_youtube_url": "Aggiungi l'URL di Youtube RTMP",
        "register": "Registrati",
        "time_of_program": "Ora del programma",
        "date_of_program": "Data del programma",
        "name_program_interested": "Nome del programma interessato",
        "email_id": "ID e-mail",
        "dob": "Data di nascita",
        "applicant_address": "Indirizzo del richiedente",
        "qualification": "Qualificazioni",
        "designation": "Designazione",
        "org_name_address": "Nome e indirizzo dell'organizzazione",
        "fullname_participant": "Nome e cognome del partecipante",
        "registration_form": "Modulo di registrazione",
        "thanks_submitting_data": "Grazie per aver inviato i vostri dati",
        "manage_content": "Gestire il contenuto",
        "API_settings": "Impostazioni API",
        "schedule_API": "Programmazione API",
        "image_upload_success": "immagine caricata con successo",
        "less_than_10MB": "si prega di caricare il file a meno di 10MB",
        "at_time_of_event": "Al momento dell'evento",
        "min_5": "5 minuti prima",
        "min_10": "10 minuti prima",
        "min_15": "15 minuti prima",
        "min_30": "30 minuti prima",
        "hrs_1": "1 ora prima",
        "hrs_2": "2 ore prima",
        "day_1": "1 giorno prima",
        "day_2": "2 giorni prima",
        "jan": "Jan",
        "feb": "Feb",
        "mar": "Mar",
        "apr": "Apr",
        "may": "Maggio",
        "jun": "Giu",
        "jul": "LUGLIO",
        "aug": "Ago",
        "sep": "Sep",
        "oct": "Ottobre",
        "nov": "Novembre",
        "dec": "Dic",
        "mon": "Lun",
        "tue": "mar",
        "wed": "Mer",
        "thu": "Gio",
        "fri": "Ven",
        "sat": "Sab",
        "sun": "Dom",
        "all_day": "tutto il giorno",
        "starts_ddmmyyyy": "inizia (gg\/mm\/aaaa)",
        "ends_ddmmyyyy": "estremit\u00e0 (gg\/mm\/aaaa)",
        "ddmmyyyy": "(gg\/mm\/aaaa)",
        "by_dayweek": "Per giorno\/settimana",
        "never": "Mai",
        "on_date": "In data",
        "alert": "Allarme",
        "multi_factor_auth": "Attivare l'autenticazione a pi\u00f9 fattori (AMF)",
        "advanced_settings": "Impostazioni avanzate",
        "verify_Otp": "Verificare Otp",
        "otp_sent_mobile": "Inserire l'OTP inviato al proprio numero di cellulare",
        "update_mobile": "Aggiorna Mobile",
        "mob_verfi_pending": "La vostra verifica mobile \u00e8 in attesa.",
        "mobile_number": "Numero di cellulare",
        "enter_Otp": "Inserire Otp",
        "enter_mob_no": "inserire il numero di cellulare",
        "sure_want_to_delete_rec": "Sei sicuro di volere T0 Cancella la registrazione",
        "delete_recording": "Cancellare la registrazione",
        "invalid_credentials": "Credenziali non valide",
        "join_conf_by_clicking_link": "Si prega di partecipare alla riunione cliccando sul seguente link",
        "valid_room_name": "Inserire il nome della camera valido",
        "internal_server_error": "Errore interno del server, riprovare",
        "room_limit_exceed": "Superato il limite della camera",
        "cant_start_meeting_already_goingon": "Non si pu\u00f2 iniziare una riunione, perch\u00e9 c'\u00e8 gi\u00e0 una riunione in corso.",
        "video_recordings": "Registrazioni video",
        "hindi": "Hindi",
        "gujarati": "Gujarati",
        "kannada": "Kannada",
        "marathi": "Marathi",
        "tamil": "Tamil",
        "telugu": "Telugu",
        "urdu": "Urdu",
        "spanish": "Spagnolo",
        "malyalam": "Malyalam",
        "italian": "Italiano",
        "bangla": "Bangla",
        "portuguese": "Portoghese",
        "create_new_content": "Creare contenuti",
        "create_new_group": "Creare un gruppo",
        "noScheMeet_no_meeting_scheduled": "Non sono previste riunioni",
        "noScheMeet_no_upcoming_meetings": "Nessun incontro \u00e8 ancora programmato. Puoi creare una nuova pianificazione o avviare subito la tua conferenza istantanea.",
        "whiteboard": "Lavagna bianca",
        "video_syn": "Sincronizzazione video",
        "notepad": "Blocco note",
        "enable_handraise": "Abilitare l'alzata della mano in un webinar",
        "F": "Impostazioni della riunione",
        "social_integration": "Integrazione sociale",
        "advanced": "Avanzato",
        "add_youtube": "Aggiungi Youtube",
        "plugins": "Plugins",
        "subtitles": "Sottotitoli",
        "view_local": "Visualizza locale",
        "hide_local": "Nascondi locale",
        "switch_conf_mode": "Passare alla modalit\u00e0 conferenza",
        "switch_pres_mode": "Passare alla modalit\u00e0 presentatore",
        "conference_locked": "Conferenza chiusa a chiave",
        "conference_unlocked": "Conferenza sbloccata",
        "sharing_tools": "Strumenti di condivisione",
        "recording": "Registrazione",
        "plz_wait_host_shr_video": "Aspetta per favore ! L'organizzatore sta per condividere un video",
        "supported_sources_include": "Le fonti supportate includono",
        "team_plugins": "Plugins TEAMS",
        "open": "Aprire",
        "click": "Clicca",
        "more_app": "Altre applicazioni",
        "upload_custom_app": "Carica l'applicazione personalizzata",
        "download_team_plugin": "Scarica il plugin TEAMS",
        "install_mng_app_microsoft_team": "Come installare e gestire le app in Microsoft Teams",
        "microsoft_step1": "Passaggio 1: apri Microsoft TEAMS",
        "microsoft_step1_para1": "Per iniziare, devi prima scaricare il plug-in TEAMS sul tuo sistema, disponibile in alto a destra in questa pagina. Quindi apri Microsoft TEAMS e scegli",
        "microsoft_step1_para2": "opzioni di navigazione per visualizzare le applicazioni TEAMS.",
        "microsoft_step2": "Passaggio 2: accedi ad altre app",
        "microsoft_step2_para1": "Qui potete trovare alcune app insieme a un pulsante chiamato More Apps. Clicca",
        "microsoft_step2_para2": "per verificare ulteriormente la presenza di opzioni di caricamento personalizzate e di altre opzioni del negozio.",
        "microsoft_step3": "Passo 3: Caricare l'applicazione",
        "microsoft_step3_para1": "Una volta aperto il negozio, \u00e8 possibile trovare un",
        "microsoft_step3_para2": "nella navigazione a sinistra. Cliccate su di essa e caricate qui la vostra applicazione plugin scaricata.",
        "microsoft_step4": "Passo 4: Selezionare l'applicazione InstaVC dalla memoria",
        "microsoft_step4_para1": "Quando si apre la finestra, seleziona il plug-in InstaVC dal tuo sistema che \u00e8 stato scaricato (passaggio 1) e fai clic su",
        "microsoft_step5": "Passaggio 5: caricamento dell'applicazione Microsoft TEAMS",
        "microsoft_step5_para1": "Dopo aver scaricato il file, viene caricato nell'elenco delle applicazioni di Microsoft TEAMS. \u00c8 quindi possibile fare clic sull'applicazione per visualizzarla e aggiungerla ai team.",
        "microsoft_step6": "Passo 6: Aprire l'applicazione InstaVC",
        "microsoft_step6_para1": "Dopo aver cliccato sull'applicazione InstaVC, \u00e8 ora possibile visualizzare i dettagli completi dell'applicazione plugin insieme ad un pulsante per",
        "microsoft_step6_para2": "per aggiungere questa applicazione Microsoft TEAMS",
        "microsoft_step7": "Passaggio 7: InstaVC aggiunto a Microsoft TEAMS",
        "microsoft_step7_para1": "La tua applicazione verr\u00e0 ora aggiunta a Microsoft TEAMS nel menu di navigazione a sinistra per un facile utilizzo e",
        "ddyymm": "GG\/MM\/AAAAA",
        "fill_details_for_webinar": "Si prega di compilare i dati di registrazione per il webinar a",
        "microsoft_teams": "Microsoft TEAMS",
        "microsoft_outlook": "Microsoft Outlook",
        "select_the_plan": "Selezionare il piano",
        "enter_company_code": "Inserisci il Codice Aziendale",
        "enter_code": "Inserire il codice",
        "enter_valid_coupon_code": "Inserire il codice coupon valido",
        "coupon_alreadt_taken": "Questo numero di coupon \u00e8 gi\u00e0 stato utilizzato",
        "error_updating_plan": "Errore durante l'aggiornamento del piano",
        "unit": "unit\u00e0",
        "referrals": "Referenti",
        "sel_coupon": "Selezionare il coupon",
        "share_val_0_99": "Il valore condiviso deve essere compreso tra 0 e 99",
        "enter_share_val": "Inserire il valore dell'azione",
        "valid_commission_per": "Inserire la percentuale di commissione valida",
        "enter_commission_per": "Inserire la percentuale di commissione",
        "enter_commission_name": "Inserire il nome della Commissione",
        "sync_successfully": "Utenti sincronizzati con successo",
        "room_deleted_suc": "Camera cancellata con successo",
        "lic_deleted_suc": "Licenza cancellata",
        "female": "Femmina",
        "male": "Maschio",
        "insta_conference": "Insta Conferenza",
        "quick_meeting_by": "Riunione istantanea di",
        "in_lobby": "Nella sala d'attesa",
        "switch_to_broadcast": "Vai alla trasmissione",
        "Broadcasters_5": "Emittenti (5)",
        "Participants_5": "Partecipanti (5)",
        "search_participants": "Ricerca partecipanti",
        "host_controls": "Controlli dell'organizzatore",
        "deleteroom": "Cancella Camera",
        "pm": "PM",
        "am": "AM",
        "email_exist_inroom": "L'email esiste gi\u00e0 in questa stanza",
        "type_a_message": "Digitare un messaggio...",
        "please_enter_user_limit": "Inserisci il numero massimo di utenti",
        "user_limit_exceeded": "Superamento del limite del numero di utenti possibile",
        "sel_country_code": "Selezionare il codice del paese",
        "available_storage": "Stoccaggio disponibile",
        "enter_phone_number": "Inserisci il numero di telefono",
        "startdate_less_enddate": "selezionare la data di inizio meno della data di fine",
        "havent_add_data": "Non hai ancora aggiunto dati, per favore aggiungi dati.",
        "professional_skills": "Competenze professionali",
        "enter_prof_skills": "Inserisci le competenze professionali",
        "Present_Invitations": "Inviti attuali",
        "sending": "Invio in corso ...",
        "sent": "Inviato a",
        "invite_10ppl_only": "\u00c8 possibile invitare solo fino a 10 persone",
        "join_meeting": "Partecipa alla riunione",
        "enter_doc_title": "Inserire il titolo del documento",
        "meeting_name": "Nome della riunione",
        "select_the_weeks": "Selezionare le settimane",
        "grade": "Voto",
        "coference_ID": "ID conferenza ...",
        "dont_find_room_meetingid": "77\/5000\nImpossibile trovare sale associate a questo ID riunione",
        "wireless_share": "Condivisione wireless",
        "video_layout": "Layout video",
        "speaker_screen": "Schermo dell'altoparlante",
        "presentation_screen": "Schermo di presentazione",
        "multiple_display": "Display multiplo",
        "hellow": "Buongiorno",
        "enter_passcode": "Inserisci il codice di accesso",
        "another_meeting_stillon": "Un'altra riunione \u00e8 ancora in corso, non potete iniziare questa riunione proprio ora",
        "SIP_device": "Dispositivo SIP",
        "instavc_whiteBoard": "InstaVC WhiteBoard",
        "speaker": "Altoparlante",
        "instavc_notepad": "Blocco note InstaVC",
        "promote_in": "Promuovi in",
        "guest": "Ospite",
        "breakout_rooms": "Sale breakout",
        "stop_broadcast": "Stop Broadcast",
        "noise_suppress": "Soppressione del rumore",
        "echo_cancel": "Cancellazione dell'eco:",
        "enter_name_here": "Inserisci il tuo nome qui",
        "Viewer": "Visualizzatore",
        "SIP_user": "Utente SIP",
        "remove_participants": "Rimuovere i partecipanti",
        "disable_audio_video": "Disattiva audio e video",
        "disable_audio": "Disattivare l'audio",
        "enable_audio": "Attivare l'audio",
        "disable_video": "Disattivare il video",
        "enable_video": "Abilita il video",
        "audio_video": "Audio e video",
        "audio_only": "Solo audio",
        "video_only": "Solo video",
        "outlook_addins": "Componenti aggiuntivi di Outlook",
        "install_microsoft": "Come installare e gestire i componenti aggiuntivi in Microsoft Outlook",
        "outlook_step1": "Passo 1: Copiare Link & aprire Outlook",
        "first_copy_link": "Prima copia il link",
        "add_plugin_oulook": "da qui per aggiungere il plugin alle vostre prospettive.",
        "outlook_step2": "Passaggio 2: procurati degli integratori",
        "outlook_step2_para": "Ora apri Outlook e nella scheda Home troverai l'opzione \"Ottieni componenti aggiuntivi\". Fare clic su di esso per visualizzare i componenti aggiuntivi e altre opzioni personalizzate.",
        "outlook_step3": "Passo 3: I miei componenti",
        "outlook_step3_para": "Ora puoi visualizzare la pagina dei complementi con la barra di navigazione a sinistra. Vai a I miei componenti aggiuntivi per gestire i tuoi componenti aggiuntivi.",
        "outlook_step4": "Passaggio 4: aggiungi componenti aggiuntivi personalizzati",
        "outlook_step4_para": "Qui troverai un menu a discesa \"Aggiungi un componente aggiuntivo personalizzato\" in \"I miei componenti aggiuntivi\". Seleziona Aggiungi da URL dall'elenco a discesa.",
        "outlook_step5": "Etapa 5: Adicionar um suplemento privado do URL",
        "outlook_step5_para": "Ora puoi vedere una finestra che ti chiede di aggiungere un componente aggiuntivo privato dall'URL. Ora incolla il link che hai copiato (passaggio 1) nel campo di input e fai clic su OK, questo installa il componente aggiuntivo in Outlook.",
        "outlook_step6": "Passaggio 6: InstaVC aggiunto nei miei componenti aggiuntivi di Outlook",
        "outlook_step6_para": "Dopo l'aggiunta, puoi controllare il componente aggiuntivo aggiunto nell'elenco \"I miei componenti aggiuntivi\". Da l\u00ec, puoi gestire i tuoi componenti aggiuntivi in \u200b\u200bmodo pi\u00f9 dettagliato.",
        "outlook_step7": "Fase 7: Chiudere Outlook e riaprire",
        "outlook_step7_para": "Dopo l'installazione, assicurati di chiudere Oultlook una volta e di riaprirlo per vedere l'estensione.",
        "outlook_step8": "Passo 8: Vai ai nuovi articoli",
        "outlook_step8_para": "Dopo aver aperto Outlook, ora puoi andare a \"Nuove riunioni\" per vedere l'aggiunta di InstaVC in Outlook.",
        "outlook_step9": "Passaggio 9: aprire una riunione InstaVC",
        "outlook_step9_para": "Una volta aperte le riunioni, sarete aperti con una finestra di composizione della posta insieme all'add-in di InstaVC Meeting abilitato nella barra superiore sotto la scheda riunioni.",
        "outlook_step10": "Passo 10: Creare un programma",
        "outlook_step10_para": "Dalla pagina di composizione della posta, fare clic su Incontri Instavc per creare una nuova pianificazione della riunione. Se \u00e8 la prima volta che accedi a una riunione Instavc, accederai con l'accesso alla riunione Instavc sul lato destro. Gli utenti gi\u00e0 connessi verranno indirizzati all'elenco delle stanze virtuali e potranno pianificare una riunione.",
        "outlook_step11": "Passaggio 11: aprire una riunione InstaVC",
        "outlook_step11_para": "\u00c8 possibile pianificare il calendario delle riunioni sul lato sinistro e selezionare una sala sul lato destro per pianificare una riunione in una determinata sala. Dopo aver creato un calendario, puoi vedere il pulsante \"Avvia riunione\". Da l\u00ec puoi fare clic e avviare la riunione.",
        "outlook_step12": "Passo 12: Visualizzare il programma nel calendario",
        "outlook_step12_para": "Dopo aver pianificato una riunione, la prossima volta che apri il calendario, puoi anche controllare i tuoi programmi nel calendario.",
        "outlook_step13": "Passaggio 13: riunione pianificata",
        "outlook_step13_para": "Dopo aver pianificato una riunione, \u00e8 possibile vedere la posta composta e il pulsante \"Avvia riunione\" sul lato destro.",
        "sel_declaration": "Selezionare la dichiarazione",
        "invalid_company_code": "Codice aziendale non valido",
        "affiliate_acc": "Il tuo conto affiliato \u00e8 in attesa di approvazione.",
        "aff_acc_approved": "Il tuo conto affiliato \u00e8 approvato.",
        "aff_acc_rej": "Il vostro conto affiliato viene rifiutato.",
        "aff_TC": "Termini e condizioni di affiliazione",
        "ref_par_agreement": "ACCORDO CON IL PARTNER DI RIFERIMENTO",
        "aff_partner_agreement": "Il presente Contratto con i partner, costituito da questa pagina di copertina e dai Termini commerciali allegati (collettivamente, questo \"Contratto\"), \u00e8 stipulato e stipulato da e tra PeopleLink Unified Communications Pvt Ltd (denominata PeopleLink) e \"Partner\" o \"Utente\". Per \"Partner\" o \"Cliente\" si intende la persona o l'azienda (inclusi il nome dell'azienda e altre informazioni) indicate nel modulo presentato. Il Contratto diventer\u00e0 vincolante una volta completato e presentato il Modulo per il Programma Partner di Riferimento (\"Modulo\") per indicare l'accettazione del presente Contratto. PeopleLink e Partner sono talvolta indicati come \"Parte\", e insieme come \"Parti\".",
        "background": "Background",
        "aff_market_advertise": "PeopleLink offre servizi e soluzioni di comunicazione unificata InstaVC. Il partner desidera promuovere, commercializzare e pubblicizzare i prodotti e le soluzioni PeopleLink ai potenziali clienti PeopleLink (\"Referrals\") attraverso i propri siti web e altri canali di marketing, in conformit\u00e0 al presente accordo.",
        "agreement": "Accordo",
        "aff_parties_agree": "Le Parti convengono quanto segue:",
        "heading1": "IMPEGNI DEL PROGRAMMA",
        "heading2": "REFERENTI QUALIFICATI E COMMISSIONI.",
        "heading3": "TERMINE E RISOLUZIONE.",
        "heading4": "GENERALE",
        "the_program": "Il programma.",
        "to_participate_program_partner": "Per partecipare al Programma, il Partner del Programma deve compilare la domanda di partecipazione online che si trova su PeopleLink o sui siti web associati (\"Sito\") e sottoscrivere il presente Accordo. PeopleLink pu\u00f2 accettare o rifiutare qualsiasi richiesta a sua esclusiva discrezione.",
        "aff_str11": "Accordi legali.",
        "aff_str12": "Promozione, Attivit\u00e0 di Referral:",
        "aff_str13": "Attivit\u00e0 proibite.",
        "aff_str14": "Uso consentito dei marchi PeopleLink",
        "aff_str15": "Passivit\u00e0.",
        "aff_str16": "Relazioni con i clienti.",
        "aff_str11_para": "Come parte della sua partecipazione al Programma e nell'agire come Partner di PeopleLink, il Partner accetta e acconsente ai termini del presente Contratto e del Programma, alle Linee guida e a qualsiasi altra richiesta e regola stabilita da PeopleLink di volta in volta, a sua ragionevole discrezione, in relazione alla partecipazione continuativa del Partner al Programma e alla promozione dei prodotti, delle soluzioni e dei servizi di PeopleLink. In tutte le attivit\u00e0 previste dal presente Accordo, e in particolare in quelle relative alla promozione di PeopleLink da parte del Partner, il Partner dovr\u00e0 cooperare con PeopleLink e agire in buona fede. Con l'adesione al presente accordo il Partner riconosce e accetta inoltre i termini e le regole stabilite nei Termini di servizio di PeopleLink",
        "aff_str12_para": "Il partner accetta di impegnarsi nella promozione continua e attiva dei prodotti e delle soluzioni PeopleLink in vari canali di marketing utilizzando i marchi e i materiali di marketing in licenza, e di farlo in conformit\u00e0 con i termini del presente accordo.",
        "aff_str13_para": "Il Partner si impegna a non associare il Materiale di Marketing a contenuti che siano illegali in qualsiasi modo, o che siano altrimenti dannosi, minacciosi, diffamatori, osceni, offensivi, molesti, sessualmente espliciti, violenti, discriminatori o comunque discutibili a sola discrezione di PeopleLink. Il Partner si impegna a non inviare messaggi elettronici non richiesti a pi\u00f9 destinatari non correlati (\"Spamming\") per promuovere i servizi e le soluzioni di PeopleLink, o comunque ad impegnarsi in qualsiasi altra forma di comunicazioni elettroniche di massa proibite dalla legge in relazione alle attivit\u00e0 contemplate dal presente Accordo.",
        "aff_str14_para1": "Il Partner si impegna espressamente a rispettare tutti i termini del presente documento nell'utilizzo dei Marchi concessi in licenza e nella creazione di materiale di marketing.",
        "aff_str14_para2": "Attraverso le Linee guida e altro, PeopleLink fornir\u00e0 di volta in volta specifiche e altre istruzioni sull'uso consentito dei Marchi con licenza da parte del Partner nella creazione di materiale di marketing e nella promozione del servizio PeopleLink. Il Partner si impegna inoltre a rispettare tutte queste specifiche e istruzioni.",
        "aff_str14_para3": "Il Partner dovr\u00e0 garantire che tutti i Marchi concessi in licenza che appaiono sui suoi Materiali di marketing siano nella forma approvata da PeopleLink nelle Linee guida o in altro modo, non dovr\u00e0 modificare alcun Marchio PeopleLink o modificare in altro modo sostanziale altri Materiali di marketing in contrasto con le ragionevoli istruzioni fornite da PeopleLink, e dovr\u00e0 inoltre rispettare le ragionevoli istruzioni di PeopleLink per quanto riguarda la forma, il contenuto e la visualizzazione dei Materiali di marketing. Alla risoluzione del presente Accordo per qualsiasi ragione, o su richiesta scritta di PeopleLink, la licenza qui concessa scadr\u00e0 e il Partner cesser\u00e0 immediatamente tutte le sue attivit\u00e0 ai sensi del presente Accordo.",
        "aff_str15_para": "Il Partner sar\u00e0 l'unico responsabile delle sue operazioni nell'agire ai sensi del presente Accordo, inclusa, senza limitazioni, la legalit\u00e0 delle operazioni e dei materiali del Partner, creati e utilizzati in relazione al presente Accordo. Ad eccezione di un reclamo che sostiene che un marchio PeopleLink viola i diritti di marchio di terzi, PeopleLink non \u00e8 responsabile per lo sviluppo, il funzionamento o il contenuto dei materiali di marketing del Partner e il Partner si impegna a difendere, indennizzare e tenere PeopleLink indenne da qualsiasi reclamo, azione, causa di azione, danno o spesa (incluse le spese legali) relativi allo sviluppo, funzionamento, contenuto e manutenzione dei materiali di marketing del Partner.",
        "aff_str16_para": "Durante e dopo il Termine, PeopleLink sar\u00e0 il proprietario esclusivo di tutti i rapporti creati tramite Partner tra PeopleLink e Referrals in relazione al Servizio PeopleLink, incluse tutte le informazioni che identificano i Referrals che hanno stipulato un contratto con PeopleLink per l'utilizzo del Servizio PeopleLink. I Termini, l'Informativa sulla privacy e le regole e procedure di PeopleLink per il servizio PeopleLink si applicheranno a questi Referrals e possono essere modificati da PeopleLink senza preavviso al Partner, e il Partner accetta di trasmettere ai Referrals la natura delle loro relazioni con PeopleLink ai sensi dei Termini.",
        "aff_str20_para1": "che il Partner si rivolge a PeopleLink e che completano la procedura di iscrizione in conformit\u00e0 con la procedura descritta nella sezione 3(b) di seguito;",
        "aff_str20_para2": "di cui PeopleLink non ha alcuna registrazione in relazione al servizio PeopleLink, o che non sono, al momento riferite a PeopleLink dal Partner, in qualsiasi rapporto contrattuale o negoziazione in corso con PeopleLink in relazione al servizio PeopleLink;",
        "aff_str20_para3": "che accettano i Termini e acquisiscono, a discrezione del Referente e senza ricevere alcun incentivo monetario o di altro tipo da parte del Partner, almeno abbonamenti annuali di uno qualsiasi dei seguenti pacchetti di piani di servizi PeopleLink elencati sui siti web PeopleLink o altrimenti (iv) che non vengono rifiutati da PeopleLink, ed effettuano almeno un pagamento (pagamento annuale anticipato) o tre pagamenti (scadenza annuale, fatturazione mensile) per ricevere il Servizio PeopleLink.",
        "aff_str21": "Procedura di rinvio.",
        "aff_str22": "Commissioni. Responsabilit\u00e0.",
        "aff_str221": "Tasse di rinvio.",
        "aff_str222": "Vendite in corso da un referente",
        "aff_str223": "Oneri associati.",
        "aff_str23": "Rapporti Vendite\/Commissioni.",
        "aff_str21_para": "Ogni Referral sar\u00e0 indirizzato a PeopleLink dal Partner attraverso un url online fornito da PeopleLink al Partner, PeopleLink sar\u00e0 responsabile del processo di vendita a tutti i Referral, a condizione che le Parti continuino a cooperare in buona fede nel promuovere il processo di vendita al Referral.",
        "aff_str22_para": "PeopleLink raccoglier\u00e0 tutte le tasse dai Referrals per il servizio PeopleLink direttamente dai Referrals.",
        "aff_str22_para1": "Nel momento in cui un referente diventa un referente qualificato, PeopleLink pagher\u00e0 le spese per il referente del Partner in arretrato sulla base delle spese elencate al seguente indirizzo",
        "aff_str22_para12": "(esclusi eventuali sconti) a carico del Referente Qualificato. Tali Commissioni di Referral diventeranno pagabili e dovranno essere pagate al Partner entro circa (<add) novanta (90) giorni dalla data di inizio del servizio Pagato, a cui sono stati concessi gli obblighi di pagamento.",
        "aff_str22_para2": "- Il Partner ricever\u00e0 i compensi per le vendite aggiuntive da un Referente qualificato che avranno luogo entro i primi 90 giorni dalla data di presentazione iniziale del referente.",
        "aff_str22_para3": " Il Partner sar\u00e0 responsabile per il pagamento di tutte le tasse, imposte, oneri governativi e altri oneri simili prelevati sulle Commissioni di Referral, e il Partner indennizzer\u00e0, difender\u00e0 e terr\u00e0 PeopleLink indenne da e contro qualsiasi richiesta di risarcimento derivante da o relativa a tutti gli oneri derivanti dal pagamento delle Commissioni di Referral da parte di PeopleLink.",
        "aff_str23_para": "PeopleLink fornir\u00e0 al Partner nel suo dashboard online un report che riassume le attivit\u00e0 di vendita del Partner e le sue commissioni per i Referenti qualificati derivati dai Referenti. PeopleLink non riveler\u00e0 i nomi o altre informazioni personali sui referral e sui referral qualificati.",
        "aff_str31": "Termine iniziale.",
        "aff_str32": "Termine di rinnovo.",
        "aff_str33": "Cessazione anticipata. Senza causa.",
        "aff_str34": "Per la Causa.",
        "aff_str35": "Effetto della cessazione.",
        "aff_str31_para": "Il presente accordo entrer\u00e0 in vigore alla data di entrata in vigore e continuer\u00e0 per dodici (12) mesi successivi (\"Periodo iniziale\"), a meno che PeopleLink non rifiuti la richiesta di partecipazione al Programma da parte dei Partner.",
        "aff_str32_para": "Dopo la scadenza del Periodo Iniziale, il presente Accordo sar\u00e0 automaticamente rinnovato per ulteriori periodi consecutivi di dodici (12) mesi (ciascuno, \"Periodo di Rinnovo\"), a meno che una Parte non ne dia comunicazione scritta all'altra Parte almeno trenta (30) giorni prima della scadenza del Periodo Iniziale o di un eventuale Periodo di Rinnovo.",
        "aff_str33_para": "PeopleLink ha il diritto di rescindere il presente accordo in qualsiasi momento per qualsiasi motivo o senza motivo, dando cinque (5) giorni di preavviso scritto al Partner.",
        "aff_str34_para": "Ciascuna delle Parti pu\u00f2 recedere dal presente Accordo in qualsiasi momento, con effetto immediato, previa notifica scritta all'altra Parte che ha materialmente violato il presente Accordo, a condizione che prima di recedere dal presente Accordo la Parte che lo ha denunciato fornisca una notifica scritta di tale violazione materiale e trenta (30) giorni di opportunit\u00e0 per la Parte inadempiente di porre rimedio a tale violazione.",
        "aff_str35_para": "A partire dalla data di cessazione del presente Accordo e successivamente alla data di cessazione dei diritti del Partner ai sensi del presente Accordo, il Partner non avr\u00e0 diritto a ricevere alcuna commissione di rinvio o qualsiasi altro pagamento ai sensi del presente Accordo, ad eccezione delle commissioni o dei pagamenti guadagnati o maturati prima della cessazione del presente Accordo.",
        "aff_str41": "Assegnazione.",
        "aff_str42": "Diritti di propriet\u00e0 intellettuale.",
        "aff_str43": "Nessuna rinuncia.",
        "aff_str44": "Garanzia limitata.",
        "aff_str45": "Esclusione di garanzia.",
        "aff_str46": "Limitazione di responsabilit\u00e0.",
        "aff_str47": "Appaltatori indipendenti.",
        "aff_str48": "Indennizzo.",
        "aff_str49": "Forza maggiore.",
        "aff_str410": "Intero accordo; separabilit\u00e0.",
        "aff_str411": "Controparti; Avvisi.",
        "aff_str412": "Legge applicabile; giurisdizione; risoluzione delle controversie.",
        "aff_str41_para": "PeopleLink pu\u00f2 assegnare il presente Contratto in qualsiasi momento. Il Partner non pu\u00f2 cedere o trasferire questo Contratto senza il preventivo consenso scritto di PeopleLink, tale consenso non deve essere irragionevolmente negato.",
        "aff_str42_para": "Tutti i diritti di propriet\u00e0 intellettuale (come ad esempio, ma non solo, marchi, nomi commerciali, loghi, brevetti, diritti d'autore, nomi di dominio e diritti derivati) nei Marchi PeopleLink, il Servizio PeopleLink e i relativi contenuti e tecnologie in tutto il mondo (Diritti di propriet\u00e0 intellettuale PeopleLink) sono e rimarranno di propriet\u00e0 esclusiva di PeopleLink e delle sue societ\u00e0 affiliate. Il diritto del Partner di utilizzare i Marchi concessi in licenza \u00e8 a discrezione di PeopleLink ed \u00e8 soggetto al rispetto da parte del Partner dei termini del presente Contratto, delle Linee Guida e di tutte le leggi e regolamenti applicabili. Il Partner si impegna a (a) non utilizzare i diritti di propriet\u00e0 intellettuale di PeopleLink in qualsiasi modo che possa ragionevolmente violare il presente Contratto; (b) non fare nulla che possa contestare o pregiudicare i diritti di propriet\u00e0 intellettuale di PeopleLink; (c) non creare o ottenere alcun diritto di propriet\u00e0 intellettuale (come ad esempio, ma non solo, marchi, nomi commerciali, loghi, brevetti, diritti d'autore, nomi di dominio e diritti derivati) che sia sostanzialmente simile a qualsiasi diritto di propriet\u00e0 intellettuale di PeopleLink; (d) notificare tempestivamente a PeopleLink qualsiasi uso non autorizzato di qualsiasi diritto di propriet\u00e0 intellettuale di PeopleLink di cui il Partner sia effettivamente a conoscenza; e (e) utilizzare sempre i Marchi concessi in licenza e qualsiasi altro Marchio PeopleLink in conformit\u00e0 con le Linee Guida. PeopleLink pu\u00f2 eseguire revisioni periodiche di qualsiasi Materiale di marketing presentato dal Partner, e avr\u00e0 l'esclusiva autorit\u00e0 e discrezione di ordinare la rimozione e\/o la modifica di qualsiasi Materiale di marketing presentato dal Partner.",
        "aff_str43_para": "La mancata applicazione, da parte di una delle parti, della rigorosa esecuzione di qualsiasi disposizione del presente accordo da parte dell'altra parte non costituisce una rinuncia al diritto della prima parte di applicare successivamente tale disposizione o qualsiasi altra disposizione del presente accordo.",
        "aff_str44_para": "Entrambe le Parti garantiscono che, in qualsiasi momento durante il Periodo, rispetteranno tutte le leggi, i regolamenti, i codici di condotta applicabili, cos\u00ec come il presente Accordo, i Termini, la Politica sulla privacy e le Linee guida. Durante il Periodo e dopo la sua risoluzione per qualsiasi motivo, il Partner si impegna espressamente a non fare nulla che possa ragionevolmente essere ritenuto dannoso per l'attivit\u00e0, gli interessi o la reputazione di PeopleLink e non far\u00e0, pubblicher\u00e0 o permetter\u00e0 che vengano fatte o pubblicate osservazioni denigratorie riguardanti PeopleLink, i suoi rappresentanti, o il Servizio PeopleLink.",
        "aff_str45_para": "Ad eccezione della garanzia espressa di PeopleLink ai sensi della precedente sottosezione (e), PeopleLink non fornisce nessun'altra garanzia, espressa o implicita, di alcun tipo e PeopleLink declina espressamente qualsiasi garanzia e condizione, inclusa ma non limitata a qualsiasi garanzia implicita di commerciabilit\u00e0, idoneit\u00e0 per un particolare scopo, disponibilit\u00e0, sicurezza, titolo e\/o non violazione dell'oggetto del presente Contratto.",
        "aff_str46_para": "N\u00e9 PeopleLink n\u00e9 alcun funzionario, dipendente, direttore o altro rappresentante di PeopleLink sar\u00e0 responsabile nei confronti del Partner o di terzi, ai sensi o in relazione a questo Accordo o alla sua risoluzione, in contratto, pre-contratto, torto o altro per (i) qualsiasi perdita economica (inclusa la perdita di ricavi, profitti, contratti, affari o risparmi previsti) o (ii) qualsiasi perdita di avviamento o reputazione. Tali perdite includono, senza limitazioni, qualsiasi perdita o danno speciale, indiretto, incidentale, legale, punitivo o consequenziale, cos\u00ec come qualsiasi perdita o danno causato dall'interruzione delle operazioni. Nonostante qualsiasi altra circostanza o accordo che circonda qualsiasi relazione tra le parti, l'intera responsabilit\u00e0 di PeopleLink nei confronti del Partner ai sensi del presente accordo non deve superare l'INR (solo Mille Rupie Indiane) per qualsiasi e tutte le richieste di risarcimento per danni di qualsiasi tipo fatte dal Partner ai sensi del presente accordo, e con la stipula del presente accordo il Partner riconosce le limitazioni qui presenti sulla responsabilit\u00e0 di PeopleLink.",
        "aff_str47_para": "Le parti agiscono per conto proprio in qualit\u00e0 di contraenti indipendenti. Nulla nel presente Accordo creer\u00e0 alcuna joint venture, agenzia, franchising, rappresentante di vendita, occupazione o qualsiasi altro rapporto tra le Parti al di l\u00e0 dei rapporti stabiliti nel presente Accordo, e al Partner \u00e8 espressamente preclusa la possibilit\u00e0 di agire per conto di PeopleLink. L'esposizione da parte del Partner di Marchi su licenza ai sensi del presente Accordo, altri contenuti presentati dal Partner, o il contatto tra Partner e terze parti non deve travisare le relazioni descritte nel presente documento.",
        "aff_str48_para": "Il Partner indennizzer\u00e0, difender\u00e0 e terr\u00e0 PeopleLink e le sue consociate, affiliate, funzionari e dipendenti (le \"Parti indennizzate PeopleLink\") indennizzati da e contro qualsiasi costo, responsabilit\u00e0, perdita e spesa (incluse ma non limitate a ragionevoli spese legali) derivanti da qualsiasi rivendicazione, causa, azione, richiesta o procedura presentata da terzi contro le Parti indennizzate PeopleLink derivante da uno qualsiasi dei seguenti fattori: (i) una violazione del Contratto da parte del Partner; (ii) la negligenza, colpa grave o dolo del Partner o dei suoi dipendenti, agenti o appaltatori; o (iii) un'inadempienza da parte del Partner o dei suoi dipendenti, agenti, appaltatori o invitati a rispettare le leggi e i regolamenti a cui si fa riferimento in precedenza.",
        "aff_str49_para": "Una parte non \u00e8 obbligata ad adempiere ai suoi obblighi nel presente documento se \u00e8 impossibilitata a farlo per una situazione di forza maggiore. Gli eventi di \"forza maggiore\" includono eventi al di fuori del ragionevole controllo delle Parti, compresi i casi di forza maggiore, atti di governo, atti di natura, scioperi o sommosse, nonch\u00e9 l'adempimento improprio da parte dei fornitori di PeopleLinks o difetti di oggetti, materiali o software di terzi. Se una situazione di forza maggiore dura pi\u00f9 di trenta (30) giorni, ciascuna delle Parti pu\u00f2 rescindere il presente accordo previa comunicazione scritta all'altra Parte.",
        "aff_str410_para": "Il presente Accordo rappresenta l'intero accordo tra le Parti per quanto riguarda l'oggetto dello stesso e i rispettivi obblighi e impegni delle Parti. Nessun altro documento, o accordo orale o scritto tra le Parti riflette in alcun modo gli accordi stabiliti nel presente Accordo, e il presente Accordo non pu\u00f2 essere modificato se non in un documento scritto eseguito da entrambe le parti. . Ove possibile, ogni disposizione del presente accordo deve essere interpretata in modo da essere efficace e valida ai sensi della legge applicabile, ma se una qualsiasi disposizione del presente accordo \u00e8 proibita dalla legge applicabile o non \u00e8 valida ai sensi della legge applicabile, tale disposizione \u00e8 inefficace solo nella misura di tale divieto o invalidit\u00e0, senza invalidare il resto di tale disposizione o le restanti disposizioni del presente accordo.",
        "aff_str411_para": "Il presente accordo pu\u00f2 essere firmato in contropartita e tali controparti saranno valide e vincolanti per le parti con lo stesso effetto che se fossero state scambiate le firme originali.",
        "aff_str412_para": "Il presente Contratto \u00e8 disciplinato dalle leggi della giurisdizione di Hyderabad, Telangana, India, senza dare effetto ad alcun principio di conflitto di leggi. La giurisdizione sar\u00e0 esclusivamente dei tribunali distrettuali di Hyderabad, Telangana, India. L'unica ed esclusiva giurisdizione e sede per qualsiasi controversia derivante dal presente accordo sar\u00e0 un tribunale distrettuale o statale appropriato situato a Hyderabad, Telangana, India, e le parti si impegnano a non sollevare, e con la presente rinunciano a sollevare, eventuali obiezioni o difese basate su sede o foro non convenienza. Prima di avviare qualsiasi azione legale derivante da o relativa al presente Accordo, una Parte deve fornire all'altra Parte una notifica scritta di una controversia e le Parti devono negoziare attivamente e in buona fede per una rapida risoluzione di tale controversia entro trenta (30) giorni lavorativi dal ricevimento di tale notifica.",
        "aff_str40": "Modifica dell'accordo.",
        "aff_str40_para": "PeopleLink pu\u00f2 modificare il presente Accordo di volta in volta a sua ragionevole discrezione pubblicando una modifica sul Sito (all'indirizzo www.peoplelinkvc.com\/affiliatestermsofservice) o notificando il Partner via e-mail. Se il Partner si oppone a tali modifiche, il Partner pu\u00f2 rescindere il presente Accordo per giusta causa. La continua partecipazione del Partner al Programma dopo il ricevimento della notifica delle modifiche al presente Accordo costituir\u00e0 l'accettazione vincolante del presente Accordo cos\u00ec come modificato.",
        "qualified_ref": "Referenti qualificati",
        "mean_referrals": "media dei referenti",
        "apply": "APPLICARE",
        "company_code_validate": "Si prega di inserire il codice aziendale per convalidare",
        "golive_company_code": "Codice Aziendale Golive",
        "accept_TC": "Accetto i termini e le condizioni di cui sopra",
        "by_sub_referral": "Con l'invio della richiesta del partner referente, il partner dichiara e garantisce di aver letto, compreso e accettato il presente accordo.",
        "term": "Termini e condizioni",
        "found_at": "trovato a",
        "our_privacy_policy": "e la nostra politica sulla privacy",
        "privacy_policy": "Informativa sulla privacy",
        "as_applicable": "a seconda dei casi.",
        "screen-sharing": "condivisione dello schermo",
        "Document_access": "Accesso ai documenti",
        "share_the_screen": "condividi lo schermo!",
        "to_collaborate": "di collaborare!",
        "share_document": "Condividi il documento!",
        "exit_or_end": "Uscire o terminare la riunione?",
        "exit_from_meeting": "Vuoi lasciare la riunione?",
        "are_sure_exit_meeting_everyone": "Sei sicuro di voler uscire dalla riunione? Potete terminare la riunione per tutti o uscire dalla riunione.",
        "are_sure_exit_meeting": "Sei sicuro di voler uscire dalla riunione?",
        "exit_meeting": "Uscita dalla Riunione",
        "end_for_all": "Fine della riunione per tutti",
        "end_meeting": "Fine riunione",
        "will_let_alone_exit": "vi permetter\u00e0 di uscire da soli o di uscire dalla riunione.",
        "will_end_for_everyone": "concluder\u00e0 l'incontro per tutti i partecipanti alla conferenza (Tutti i partecipanti e i partecipanti).",
        "user_already_exists": "Utenti gi\u00e0 esistenti",
        "already_user_avail": "Ci sono gi\u00e0 alcuni utenti presenti alla riunione.",
        "if_like_continue": "Se vuoi continuare clicca su",
        "force_start_meetinh": "Forza inizia un altro incontro",
        "otherwise_click": "altrimenti clicca su",
        "to_go_back": "per tornare indietro.",

        //New by Keerthi - 17th Sep 2020
        "lang_settings" : "Language Settings",
        "lang_info" : "Choose your preferred language to use the application.",
        "select_language" : "Select Language",
        "settings_languages" : "Languages",

        "rec_plugin" : "Recording Plugin",
        "rec_heading1" : "How to install and manage InstaVC Screen-Video Recorder with the plugin",
        "rec_step1" : "Step 1: Copy & open Link in Chrome",
        "rec_step1_para" : "First copy the link ",
        "rec_step1_para_br" : "from here and open it in chrome to add the plugin to your browser. Click on ",
        "rec_step1_para_bold" : "Add to Chrome",
        "rec_step1_para_ct" : "to add this extension.",

        "rec_step2" : "Step 2: Pop Up for Permission",
        "rec_step2_para_br" : "When you click on Add to chrome, here appears a popup asking for permission to display notification access your content. Click on ",
        "rec_step2_para_bold" : "Add Extension",
        "rec_step2_para_ct" : "to proceed.",

        "rec_step3" : "Step 3: Extension Added to Chrome",
        "rec_step3_para" : "When the extension is added to chrome, it shows a window giving you an information with a plugin icon and how to manage this plugin from here.",

        "rec_step4" : "Step 4: Pin the plugin on the toolbar",
        "rec_step4_para_br" : "To pin the plugin to your toolbar, you need to click on manage extensions, go to ",
        "rec_step4_para_bold" : "InstaVC Screen-Recording",
        "rec_step4_para_ct" : "and click on the pin icon to add.",

        "rec_step5" : "Step 5: Extension/Plugin Pinned to Toolbar",
        "rec_step5_para" : "Once the extension is pinned, you can now see an instavc recording icon in your browser toolbar and start using it for easy access." ,

        "rec_step6" : "Step 6: InstVC Recorder Setup",
        "rec_step6_para_br" : "Once you have installed the plugin and the next time when you open it, you can now see the ",
        "rec_step6_para_bold" : "InstaVC Recorder Setup",
        "rec_step6_para_ct" : "page to enable or disable the recording tools. By default both the options will be enabled. Click on ",
        "rec_step6_para_bold1" : "Next",
        "rec_step6_para_ct1" : "to continue.",

        "rec_step7" : "Step 7: Additional Permissions",
        "rec_step7_para_br" : "Once the Next button is clicked, system will then show you a popup asking for additional permissions to read and change your data. Click ",
        "rec_step7_para_bold" : "Allow",
        "rec_step7_para_ct" : "to continue.",

        "rec_step8" : "Step 8: Permissions Allowed",
        "rec_step8_para" : "After allowing the permission, this page now gets frozen or in a disabled mode from where you can now start exploring recording options now.",

        "rec_heading2" : "Desktop Screen-Recording Plugin",

        "rec_step9" : "Step 9: Desktop Screen-Recording",
        "rec_step9_para_br" : "After allowing all permissions, you can now access recording menus from the plugin extension in your toolbar. Click on it to see further options. Here we have selected ",
        "rec_step9_para_bold" : "Desktop Screen Recording",
        "rec_step9_para_ct" : "and click on the",
        "rec_step9_para_bold1" : "Record",
        "rec_step9_para_ct1" : "button to start.",

        "rec_step9a" : "Step 9(a): Share your Screen Popup",
        "rec_step9a_para_br" : "Now the system shows up this popup asking to select the contents of your screen, whether its an Entire Screen or only application window. Click on the tab which you want to share and click on ",
        "rec_step9a_para_bold" : "Share",
        "rec_step9a_para_ct" : "button and go to that screen which you want to start recording.",

        "rec_step9b" : "Step 9(b): Desktop Screen Recording Started",
        "rec_step9b_para_br" : "The Desktop Screen recording is now started and you can see a blink in the toolbar while its in recording mode. Also, you can see a tab at the bottom showing buttons for ",
        "rec_step9b_para_bold" : "Stop Sharing",
        "rec_step9b_para_ct" : "or",
        "rec_step9b_para_bold1" : "Hide",
        "rec_step9b_para_ct1" : ". You can see the bottom message on any screen you are opening until you are in the desktop screen recording mode.",

        "rec_step9c" : "Step 9(c): Restart / Pause or Stop Desktop Screen Recording",
        "rec_step9c_para_br" : "Once you want to stop Recording. Click on the plugin in the toolbar and here you can see the options to ",
        "rec_step9c_para_bold" : "Restart, Pause",
        "rec_step9c_para_ct" : "and",
        "rec_step9c_para_bold1" : "Stop",
        "rec_step9c_para_ct1" : "buttons. You can click any of those accordingly.",

        "rec_step9d" : "Step 9(d):Recent Recording View",
        "rec_step9d_para_br" : "Once you stop the recording from the toolbar, the recent recording will now automatically open the recording in a new tab along with Title and ",
        "rec_step9d_para_bold" : "Download",
        "rec_step9d_para_ct" : "option in the top and the recording details in the right column.",
        
        "rec_heading3" : "Browser Tab Screen-Recording Plugin",

        "rec_step10" : "Step 10: Browser Tab Screen-Recording",
        "rec_step10_para_br" : "When we want to select ",
        "rec_step10_para_bold" : "Browser Tab",
        "rec_step10_para_ct" : "option for recording your browser screen. First open your screen(url) in chrome whichever you want to record and then open the plugin and click on Browser Tab, adjust microphone options and then click",
        "rec_step10_para_bold1" : "Record",
        "rec_step10_para_ct1" : "button to start recording.",

        "rec_step10a" : "Step 10(a): Browser Tab is being Recorded",
        "rec_step10a_para" : "Once the Browser tab is started recording, you can see a red dot blinking in your toolbar which indicates the tab is being recorded and also a blue box blinking in your browser title bar",
        
        "rec_step10b" : "Step 10(b): Record another tab",
        "rec_step10b_para_br" : "At the time of current screen recording, if you open another url and open the plugin, it will show you the recording status along with an option",
        "rec_step10b_para_bold" : "Record This Tab",
        "rec_step10b_para_ct" : "if you want to change the recording scree to the new url. Meanwhile the blue box blinking in the title bar of the browser indicates the tab that is being recorded at the moment.",

        "rec_step10c" : "Step 10(C): Stop and View recording",
        "rec_step10c_para_br" : "Later, when you stop the recording, the browser itself opens the recording in a new tab along with Title and",
        "rec_step10c_para_bold" : "Download",
        "rec_step10c_para_ct" : "Option at the top and the recording details in the right column.",

        "rec_heading4" : "Webcam Recording",

        "rec_step11" : "Step 11: Start Webcam Screen Recording",
        "rec_step11_para_br" : "When you start with Webcam screen recording, make sure to select the microphone & camera settings among the devices detected by the plugin. and then click on the",
        "rec_step11_para_bold" : "Record",
        "rec_step11_para_ct" : "button to start with.",

        "rec_step11a" : "Step 11(a): Webcam opens Self Video and starts Recording",
        "rec_step11a_para_br" : "The recording opens a web page with yourself video with",
        "rec_step11a_para_bold" : "Pause",
        "rec_step11a_para_ct" : "and",
        "rec_step11a_para_bold1" : "Stop",
        "rec_step11a_para_ct1" : "recording options at the bottom.",

        "rec_step11b" : "Step 11(b): View Webcam Recorded Video",
        "rec_step11b_para" : "When you stop the recording in the previous screen, the browser then opens the recording in a new tab to view and download along with the recording details.",
        
        "rec_heading5" : "Top Menus in Recording Plugin",

        "rec_step12" : "Step 12: Navigation Menus",
        "rec_step12_para_br" : "The plugin has few navigation menus to fetch your data as well as manage different actions related to the plugin. One of them is a",
        "rec_step12_para_bold" : "Hamburger Menu",
        "rec_step12_para_ct" : "Click on it to open.",

        "rec_step12a" : "Step 12(a): Open Hamburger Menu",
        "rec_step12a_para_br" : "On click of",
        "rec_step12a_para_bold" : "Hamburger Menu",
        "rec_step12a_para_ct" : ", you can find 2 tabs named",
        "rec_step12a_para_bold1" : "My Recordings",
        "rec_step12a_para_ct1" : "and",
        "rec_step12a_para_bold2" : "Options",
        "rec_step12a_para_ct2" : "which manages all the data you have been recording and options you have enabled or disabled for that plugin. Click on",
        "rec_step12a_para_bold3" : "My Recordings",
        "rec_step12a_para_ct3" : "to see the videos you have recorded till date.",

        "rec_step12b" : "Step 12(b): Access My Recordings",
        "rec_step12b_para_br" : "There is another way of accessing",
        "rec_step12b_para_bold" : "My Recordings",
        "rec_step12b_para_ct" : ", page from the top menu in the plugin which can be considered as a shortcut to navigate.",
        
        "rec_step12c" : "Step 12(c): My Recordings",
        "rec_step12c_para" : "Here will be the detailed list of videos(recordings) you have recorded till date. Each video contains the date, duration, size of the video along with delete icon in the thumbnail view. When you click on any video it opens the details in the view recording page. Note that all the videos will be stored locally here.",

        "rec_step12d" : "Step 12(d): Delete All recordings",
        "rec_step12d_para_br" : "Looking at My recordings Page, there is a more icon in the top right corner which opens the",
        "rec_step12d_para_bold" : "Delete All",
        "rec_step12d_para_ct" : "option to easily delete all recordings with a single click.",
        
        "rec_step12e" : "Step 12(e): Side Navigation",
        "rec_step12e_para_br" : "Instead of opening the plugin again and change, You can directly navigate to another tab from here using the Hamburger Menu in the top left corner of the page that opens the navigation to navigate to the",
        "rec_step12e_para_bold" : "Options",
        "rec_step12e_para_ct" : "page",

        "rec_heading6" : "Plugin Options",

        "rec_step13" : "Step 13: Plugin Options",
        "rec_step13_para_br" : "On click on Hamburger Menu, you can find another tab named Options which manages all the options you have enabled or disabled for that plugin. Click on",
        "rec_step13_para_bold" : "Options",
        "rec_step13_para_ct" : "to see the options that are enabled for you.",

        "rec_step13a" : "Step 13(a): Recorder Options",
        "rec_step13a_para" : "Recorder Options ask you for various options like video storage, Notifications, Keyboard Shortcuts",

        "rec_step13b" : "Step 13(b): Recorder Options",
        "rec_step13b_para" : "Recorder Options contain various other options like Recording Settings and advanced.",

        "rec_heading7" : "Plugin Notifications",

        "rec_step14" : "Step 14: Plugin Notifications",
        "rec_step14_para" : "On the top navigation part, you can find a bell icon for notifications if any related to instavc screen recording plugin.",

        "rec_step14a" : "Step 14(a): Open Notifications",
        "rec_step14a_para" : "On click on the notification icon, you can find the list of notifications if any or it shows an empty slide in.",

        "rec_heading8" : "Recording Settings",

        "rec_step15" : "Step 15: Settings",
        "rec_step15_para_br" : "Then comes the last navigation menu on the top right corner named",
        "rec_step15_para_bold" : "Settings",
        "rec_step15_para_ct" : "where you can open and manage the recording settings accordingly.",

        "rec_step15a" : "Step 15(a): Advanced Settings Options",
        "rec_step15a_para" : "Here you can adjust the settings based on the Resize tab, Limit resolution and maximum frame rate which are given by plugin.",
        
        "crteContent_upload_medialist" : "Uploaded Media List",
        "content_upld_desc_txt" : "The files you upload here will be available to users who view content.",
        "content_browse_txt" : "Browse Files to Upload",
        "content_upld_types" : "Files with only .jpg, .jpeg, .png, and .pdf are allowed limiting the max size upto 15MB",

        "video_qlty": "Qualità video",
        "audio_qlty": "Qualità audio",
        "cntnt_shrng": "Condivisione di contenuti",
        "fdb_poor": "Povero",
        "fdb_good": "Buona",
        "fdb_avg": "Media",
        "fdb_excellent": "Eccellente",
        "share_feedback": "Condividi il tuo FeedBack",
        "ovrl_rating": "Valutazione generale",
        "fdb_cmnts": "I tuoi commenti sono molto apprezzati",
        "no_thnks": "No grazie",
        "upload_size_max_15mb": "Puoi caricare il limite di dimensione massima di 15 MB",

        "pastMeeting_feedback" : "Risposta",
        "pastMeeting_comments" : "Commenti",
        
        "microsoft_webplugin" : "Plugin Web di Microsoft Outlook",
        "microsoft_installable" : "Plugin installabile di Microsoft Outlook",
        "outlook_installable": "Installabile Outlook",
        "customization_installer": "Come accedere al programma di installazione della personalizzazione",
        "plugin_step1" : "Passaggio 1: fare clic sul collegamento per scaricare il file",
        "click_link_download": "Fare clic sul collegamento per il download del file",
        "first_click_link": "Per prima cosa fare clic sul collegamento sottostante",
        "to_download_file": "per scaricare il file nel tuo sistema. Quindi apri il file scaricato per avviare l'installazione e fai clic su",
        "install_txt": "Installare",
        "plugin_step2" : "Passaggio 2: personalizzazione installata",
        "plugin_step2_para":"Once you start installing, wait untill the file gets installed and you get a popup showing successfully Installed and then close.",
        "plugin_step3" : "Passaggio 3: apri Outlook e vai a riunioni",
        "plugin_step3_para" : "Dopo l'installazione, apri Microsoft Outlook e vai alle riunioni.",
        "plugin_step3_para1" : "Vai a Nuova email> Riunioni",
        "plugin_step4" : "Passaggio 4: aprire le riunioni",
        "plugin_step4_para" : "Una volta aperta la finestra delle riunioni, spostati sull'elenco delle schede sopra e puoi quindi fare clic sulla scheda",
        "plugin_step4_para1": "Peoplelink",
        "plugin_step5": "Passaggio 5: pianificazione della conferenza",
        "plugin_step5_para": "Sotto Peoplelink hai una sottoscheda chiamata",
        "plugin_step5_para1": "Pianifica la conferenza.",
        "plugin_step5_para2": "Fai clic su di esso e puoi vedere le opzioni di pianificazione sul lato destro della finestra. Qui puoi gestire gli orari inVC.",
        "plugin_step6": "Passaggio 6: gestisci o personalizza i programmi",
        "plugin_step6_para": "Qui puoi gestire o personalizzare i programmi tramite l'elenco delle stanze, i tempi e puoi creare un nuovo programma da qui che ti reindirizza al browser e torna a Outlook.",
        "plugin_step6_para1": "Una volta che sei d'accordo con la pianificazione, puoi fare clic su",
        "plugin_step6_para2": "Ottieni collegamento",
        "plugin_step7": "Link generato",
        "plugin_step7_para": "Quindi qui viene generato il collegamento e composto nella posta che puoi persino utilizzare per la condivisione online o procedere oltre.",

    }
}