import React from "react";
import { Route, Redirect } from "react-router-dom";
import Home from '../containers/home/Home';
export default  ({ component: C, props: cProps, ...rest }) => {
     
    const redirect = 'orooms';
    
    return (
      <Route
        {...rest}
        render={props =>
          !cProps.isAuthenticated
        ? <Home {...props} {...cProps} component={(props) => <C  {...cProps} {...props} /> } />
            : <Redirect
                to={redirect}
              />}
      />
    );
  };

  //<C {...props} {...cProps} />

  //component={C} 