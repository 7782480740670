import React, { Component } from 'react';
import { API } from "aws-amplify";
import Routes from "./Routes";
// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Auth } from "aws-amplify";
import * as AuthAPI from './offline-auth/auth';
import { createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
// import P2P from './containers/conference/libs/P2P';
import InstaSignaler from './containers/conference/libs/InstaSignaler';
import VoiceCommandProcess from './containers/conference/libs/VoiceCommandProcess';
import './App.css';
import { config } from './config';
// import {lang} from `./locale/lang-${config.lang}.json`;
import {languageObj} from "./locale/index";
import {LanguageContext} from "./libs/context";
import { OffLineAPI } from './offline-auth/api';
import ls from 'local-storage';
import ActivityDetector from 'react-activity-detector';


var _API = config.isOffline ? OffLineAPI : API;
const axios = require('axios');
const parseDomain = require("parse-domain");
const _Auth = config.isOffline ? AuthAPI.Auth : Auth;

const theme = createMuiTheme({
  palette: {
    primary: { main: '#008abc', },
    secondary: {
      main: '#ff9200', contrastText: '#fff', dark: '#c67100',
    }
  },
  typography: {
    useNextVariants: true,
  },
});

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: undefined,
      isAdmin: undefined,
      isAffiliate: undefined,
      isPlanManager: undefined,
      userPayload: null,
      userId: null,
      isOnline: false,
      onlineUsers: [],
      onP2PCall: false,
      logo: "instavc-blue-small.png",
      whiteLable: false,
      language: languageObj['en'].locale,
      setLang: "en",
      subDomain: window.location.hostname
    };
    this.onVCCall = false;
    this.onlineUsers = [];
    this.uids = [];
    this.subscribedSelf = false;
    this.child = React.createRef();
    // this.P2P = new P2P();
    this.instaSignaler = new InstaSignaler();
    this.vcCommand = new VoiceCommandProcess()
    this.wsInitilised = false;
    this.lang = config.lang;
  }

  componentWillMount = () => {
    if (config.isOfflineMCU) {
      return;
    }

    const excludeHeaderDomainName = 'teams';
    const _domain = parseDomain(window.location.href);
    const _instavcLogo = 'instavc-blue-small.png';
    
    if(_domain && _domain.subdomain === 'connect') {
      // console.log = function () {}
    }
    
    // if (window.location.hostname !== 'localhost' && _domain && _domain.subdomain !== 'connect' && _domain.subdomain !== 'share') {
    //   API.get("domain", `/validate/${_domain.subdomain}`)
    //     .then(res => {
    //       // console.log('Domain Available', res);
    //       if (res.logo) {
    //         // this.setState({ logo: res.logo, whiteLable: true })
    //         this.setState({ logo: res.logo, whiteLable: true, excludeHeader: excludeHeaderDomainName === _domain.subdomain, subDomain: window.location.hostname })

    //       } else {
    //         // this.setState({ logo: _instavcLogo })
    //         this.setState({ logo: _instavcLogo, excludeHeader: excludeHeaderDomainName === _domain.subdomain, subDomain: window.location.hostname })

    //       }
    //     })
    //     .catch(err => {
    //       console.log("err====", err);
    //       // this.props.history.push('/notfound');
    //       // this.setState({ logo: _instavcLogo })
    //       window.location.href = 'https://connect.instavc.com/sitenotreached';
    //       return;
    //       // console.log("err in domain validation ", err);
    //     });
    // } else if (_domain && _domain.subdomain === 'share' && window.location.href !== 'https://share.instavc.com/wireless-share') {
    //   // alert('hello')
    //   window.location.href = 'https://share.instavc.com/wireless-share';
    //   return;
    // } else {
    //   this.setState({ logo: _instavcLogo, excludeHeader: excludeHeaderDomainName === _domain && _domain.subdomain })
    // }
    this.setState({ logo: _instavcLogo, excludeHeader: excludeHeaderDomainName === _domain && _domain.subdomain })
  }

  async componentDidMount() {
    
    // console.log(this.props);
    // this.lang = ls.set('lang',this.lang) || 'en';
    // console.log("this.lang", this.lang);
    // console.log("localgetItem=====>", localStorage.getItem('setLang'));
    

    this.lang =localStorage.getItem('setLang') ||'en';
    // console.log("this.lang======>", this.lang);
    // this.setLanguage(this.lang);
    // if (this.lang === 'fr') {
    //   // console.log("locallang", fnData);
    //   this.setState({ language: frData.locale })
    // } else if(this.lang === 'pt') {
    //   this.setState({ language: ptData.locale })
    // }
    // else {
      // console.log("locallang", enData);
      this.setState({ language: languageObj[this.lang] && languageObj[this.lang].locale ? languageObj[this.lang].locale : languageObj['en'].locale })
    // }
    // console.log("locale-en", locale);
    if (config.isOfflineMCU) {
      // this.setState({ isAuthenticating: false });
      await this.userHasAuthenticated(false, null);
      // return;
    }
    try {
      const mySession = await _Auth.currentSession();
      await this.userHasAuthenticated(true, mySession);
      console.log("mysession================", mySession);
    }
    catch (e) {
      // console.log("error in app did mount === ", e);
      await this.userHasAuthenticated(false, null);
      if (e !== 'No current user' && !config.isOfflineMCU) {
        // alert(e);
        console.log("error in userHasAuthenticated======", e);
      }
    }

    // var todayCurrency = 0.013948;
    // try{
    //   const curr = await axios.get('https://api.exchangerate-api.com/v4/latest/INR');
    //   const currencyData = curr.data;
    //   todayCurrency = currencyData.rates.USD;
    //   console.log('Curr', todayCurrency);
    // }catch(error) {

    // }
    // this.setState({ isAuthenticating: false, USD: todayCurrency });
  }

  isVCCall = (status) => {
    this.onVCCall = status;
  }

  VCCallStatus = () => {
    return this.onVCCall;
  }

  timeToLogout = async () => {
    await _Auth.signOut();
    _API.get('auth', '/removelogintoken')
      .then((res) => {
        if (res && res.status) {
          console.log("login token removed successfully")
        } else {
          console.log("Please try again")
        }
      }).catch(error => {
        console.log(error);
      })
    this.userHasAuthenticated(false, null);
  }

  onIdle = () => {
    this.timeToLogout()
    console.log("The user seems to be idle...");
  }

  onActive = () => {
    console.log("The user is active!");
  }

  userHasAuthenticated = async (authenticated, session = null) => {
    const payLoad = await _Auth.currentUserInfo();
    console.log("After Login PayLoad", payLoad, session);
    // console.log("After Login sessions", session);

    var todayCurrency = 0.013948;
    try {
      let curr="" //= //await axios.get('https://api.exchangerate-api.com/v4/latest/INR');
      const currencyData = curr.data;
      todayCurrency = currencyData.rates.USD;
      // console.log('Curr', todayCurrency);
    } catch (error) {

    }

    if (authenticated && payLoad && payLoad.attributes) {
      // start creating bucket folder
      // let body = {
      //   bucket: "instavcrecordings"
      // }
      // try {
      //   const createbucket = await _API.post("user", "/createbucket", { body })
      //   console.log("createbucket", createbucket)
      // } catch (err) {
      //   console.log("err", err);

      // }

      // end bucket folder





      if (!this.wsInitilised) {
         this.instaSignaler.init(payLoad.attributes.sub);
         this.vcCommand.init();
        this.wsInitilised = true;
      }
      let affiliateRes;
      try {
        affiliateRes = await _API.get("user", "/isaffiliate");
      } catch (error) {
        console.log("error in isaffiliate ==== ", error);
      }
      try {
        // console.log("affiliateRes", affiliateRes)
        // console.log("session.accessToken", session.accessToken);

        let userGroups = [];
        if (config.isOffline) {
          userGroups = session ? session.accessToken.payload['groups'] : [];
        } else {
          userGroups = session ? session.accessToken.payload['cognito:groups'] : [];
        }
        let isAdmin = userGroups && userGroups.findIndex(group => group === 'Admin') >= 0;
        // let isAdmin = true;
        // let isAffiliate = userGroups && userGroups.findIndex(group => group === 'Affiliate') >= 0;

        let isAffiliate = affiliateRes ? affiliateRes.status : false;
        // console.log("isAffiliate ==== ", isAffiliate);
        let isPlanManager = userGroups && userGroups.findIndex(group => group === 'PlanManager') >= 0;
        // let isPlanManager = true;
        // console.log('Plan', isPlanManager);
        this.setState({ isAuthenticated: authenticated, USD: todayCurrency, isAdmin: isAdmin, isAffiliate: isAffiliate, isPlanManager: isPlanManager, userPayload: (payLoad) ? payLoad.attributes : null, userId: payLoad.username });
      } catch (error) {
        console.log("error === ", error);
        this.setState({ isAuthenticated: authenticated, USD: todayCurrency, userPayload: (payLoad) ? payLoad.attributes : null, userId: payLoad.username });
      }
    } else {
      this.setState({ isAuthenticated: false, USD: todayCurrency, userPayload: null });
    }
  }

  componentDidCatch = (error, info) => {
    console.log('Error! Error!, ', error);
  }

  setLanguage = (lang) => {
    console.log("lang===", lang);
    if (this.lang !== lang) {
      this.lang = lang;
      console.log(this.lang);
      // if (this.lang === 'fr') {
      //   // console.log("locallang", fnData);
      //   this.setState({ language: frData.locale, setLang: lang })
      //   localStorage.setItem('setLang', lang);

      // } else if(this.lang === 'pt') {
      //   this.setState({ language: ptData.locale, setLang: lang })
      //   localStorage.setItem('setLang', lang);

      // }
      // else {
        console.log("locallang", languageObj);
        this.setState({ language: languageObj[this.lang] && languageObj[this.lang].locale ? languageObj[this.lang].locale : languageObj['en'].locale, setLang: lang })
        localStorage.setItem('setLang', lang);


      // }
    }
  }

  render() {

    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      USD: this.state.USD,
      isAdmin: this.state.isAdmin,
      isAffiliate: this.state.isAffiliate,
      isPlanManager: this.state.isPlanManager,
      userHasAuthenticated: this.userHasAuthenticated,
      instaSignaler: this.instaSignaler,
      vcCommand: this.vcCommand,
      callRequest: this.SendCallRequest,
      userPayload: this.state.userPayload,
      endP2PCall: this.endP2PCall,
      isVCCall: this.isVCCall,
      VCCallStatus: this.VCCallStatus,
      userId: this.state.userId,
      onP2PCall: this.state.onP2PCall,
      webLogo: this.state.logo,
      whiteLable: this.state.whiteLable,
      language: this.state.language,
      setLanguage: this.setLanguage,
      setLang: this.state.setLang,
      subDomain: this.state.subDomain
    };

    const customActivityEvents = ['click', 'mousemove', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'focus']

    return (
      <Provider store={this.props.store}>
        <MuiThemeProvider theme={theme}>
          <LanguageContext.Provider value={this.state.language}>
            <div className="app">
              {this.state.logo && this.state.language &&
                <Routes childProps={childProps} />}
                <ActivityDetector activityEvents={customActivityEvents} enabled={true} timeout={15 * 60 * 1000} onIdle={this.onIdle} onActive={this.onActive} />
            </div>
          </LanguageContext.Provider>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
