export const enData = {
    "locale": {
        "home_instavc_build": "Build meaningful relationships between organizations and employees",
        "home_instavc_conference_platform": "The Conference Platforms with an\nUnparalleled User Experience",
        "home_instavc_start_conference": "START CONFERENCE | JOIN CONFERENCE | INSTA CONFERENCE",
        "home_instavc_Hippa": "HIPAA ELIGIBLE AND PCI DSS, SOC, ISO/EIC 27001, ISO/EIC 27017, ISO/EIC 27018, AND ISO 9001 COMPLIANT.",
        "home_instavc_copyrights": "© 2018 · instaVC · PeopleLink Unified Communications Pvt. Ltd.",
        "home_instavc_terms_agree": "I agree to the",
        "home_instavc_terms": "Terms & Conditions",
        "instavc_login": "Log in",
        "instavc_login_email": "Email",
        "instavc_login_password": "Password",
        "instavc_login_button": "LOG IN",
        "instavc_login_forgotpassword": "Forgot Password ?",
        "instavc_login_have_account": "Don't have an account ?",
        "instavc_login_signup": "Signup",
        "instavc_signup": "Sign Up",
        "instavc_signup_firstname": "First Name",
        "instavc_signup_lastname": "Last Name",
        "instavc_signup_email": "Email",
        "instavc_signup_password": "Password",
        "instavc_signup_cpassword": "Confirm Password",
        "instavc_signup_button": "SIGN UP",
        "instavc_signup_already_have_account": "Already have an account?",
        "instavc_signup_login": "Log In",
        "instavc_signup_fname_error": "First name should be the alphabets only",
        "instavc_signup_lname_error": "Last name should be the alphabets only",
        "instavc_signup_email_error": "Email should be in a proper format",
        "instavc_signup_password_error": "Password should be six characters long and combination one uppercase, one lowercase, one digit and one special character ",
        "instavc_signup_cpassword_error": "Confirm password should match the above password",
        "instavc_forgotpassword_link": "We'll send you a link to your registered email. Just click the link and set your new password",
        "instavc_forgotpassword_email": "Enter your registered email address",
        "instavc_forgotpassword": "Forgot Password",
        "instavc_forgotpassword_button": "FORGOT PASSWORD",
        "instavc_confirmpass_heading": "Create a new password filling the form.",
        "instavc_confirmpass_verificationcode": "Enter verification code",
        "instavc_confirmpass_newpassword": "Enter new password",
        "instavc_confirmpass_retypepassword": "Retype new password",
        "instavc_confirmpass_button": "CREATE NEW PASSWORD",
        "instavc_forgotpassword_signin": "Sign In",
        "instavc_forgotpassword_passwordcreated": "Congratulations! Your new password has been created, you can now sign-in into instaVC.",
        "instavc_dashboard": "DASHBOARD",
        "instavc_dashboard_schedule": "SCHEDULE MEETING",
        "instavc_dashboard_meetinglist": "PAST MEETINGS",
        "instavc_dashboard_conferenceplatform": "The Conference Platform with an Unparalleled User Experience",
        "instavc_dashboard_SC_room": "Start Conference",
        "instavc_dashboard_SC_roomlist": "By selecting your scheduled meeting",
        "instavc_dashboard_startconference": "START CONFERENCE",
        "instavc_dashboard_JC": "Join Conference",
        "instavc_dashboard_JC_ID": "via Conference ID or Scheduled",
        "instavc_dashboard_JC_button": "JOIN CONFERENCE",
        "instavc_dashboard_SC": "Start Conference",
        "instavc_dashboard_SC_p2p": "By selecting a Room from your list",
        "instavc_dashboard_instaconference": "INSTA CONFERENCE",
        "instavc_dashboard_buynow": "BUY NOW",
        "instavc_dashboard_myprofile": "My Profile",
        "instavc_dashboard_settings": "Settings",
        "instavc_dashboard_myadmin": "My Admin",
        "instavc_dashboard_affiliate": "My Affiliate",
        "instavc_dashboard_logout": "Log Out",
        "conference_myrooms": "My Rooms",
        "conference_myrooms_host": "Host",
        "conference_myrooms_roomhost": "You are a host for this room.",
        "conference_myrooms_search": "Search ...",
        "conference_myrooms_hostuser": "Host User",
        "conference_myrooms_gotopremium": "Go to Premium",
        "conference_myrooms_buynow": "Buy Now",
        "conference_myrooms_renewal": "Renewal",
        "conference_setup": "Setup",
        "conference_myrooms_renewnow": "Renew Now",
        // "conference_myrooms_hosts": "Host",
        "conference_myrooms_join": "Join",
        "conference_myrooms_setup": "Room Setup",
        "conference_myrooms_sethost_email": "Set yourself as a host, or enter a user's email address to make him/her as a host.",
        "conference_myrooms_selfhost": "Set yourself as a host",
        "conference_myrooms_otherhost": "OR - If you want to make any user as host, enter his/her email address.",
        "conference_myrooms_email": "Email Address",
        "Cancel": "CANCEL",
        "conference_myrooms_apply": "APPLY",
        "view_schedule_myrooms": "My Rooms",
        "view_schedule_search": "Search ...",
        "view_schedule_schedule": "Schedule",
        "view_schedule_calender": "Below is the calendar to check the schedules of this room",
        "video_recordings": "Video Recordings",
        "view_recordings_search": "Search",
        "view_recordings_month": "This Month",
        "view_recordings_week": "Last 7 Days",
        "view_recordings_today": "Today",
        "no_recordings": "No Recordings Yet!",
        "no_recordings_message": "There are no videos here, right now.",
        "no_recordings_startmessage": "Once you start recording you can watch all your videos here.",
        "stop_recording": "Do You want to stop this recording ?",
        "stop_recording_agree": "Agree",
        "stop_recording_disagree": "Disagree",
        "conference_in_use": "Conference is in use",
        "conference_mode" : "Conference Mode",
        "large_conference_mode" : "Large Conference Mode",
        "conference_setup_configuration": "Configuration",
        "conference_setup_generateid": "No Meeting Id Found, Generate One!",
        "conference_setup_regenerate": "Regenerate",
        "conference_setup_meetingid": "Meeting Id",
        "conference_setup_camera": "FaceTime HD Camera",
        "conference_setup_defaultmicrophone": "Default - Internal Microphone (Built-in)",
        "conference_setup_microphone": "Internal Microphone (Built-in)",
        "conference_setup_volume": "Volume",
        "conference_setup_videomode": "Video Mode",
        "conference_setup_forward": "Forward",
        "conference_setup_mix": "Mix",
        "conference_setup_invite": "Invite",
        "conference_setup_invitepeople": "Invite People",
        "conference_setup_invitefriends": "Invite your friends and coworkers to collaborate.",
        "conference_setup_invite_directlink": "Direct Link to your Room",
        "conference_setup_invite_sharelink": "Share this link with your participants for them to join your session",
        "conference_setup_invite_link": "https://connect.instavc.com/conference-guest/eddca0fc7adcdc61a24dabe02597412a36bb96dac7302f3cedb6aac50169d58614eb46b940b556a9/f",
        "conference_setup_invitebyemail": "Invite By Mail",
        "conference_setup_invite_enteremail": "Enter the preferred email address to invite your participants",
        "conference_setup_invite_emailid": "Email Address",
        "conference_setup_invite_close": "CLOSE",
        "conference_setup_invite_button": "INVITE",
        "conference_setup_advancesettings": "Advance Settings",
        "conference_setup_changeresolution": "Choose Resolution",
        "conference_setup_lowbandwidth": "Low Bandwidth",
        "conference_setup_poornetwork": "Poor Network",
        "conference_setup_vga": "VGA - (640x480)",
        "conference_setup_vga_graphics": "For Video Graphics Array (VGA) Video Resolution",
        "conference_setup_hd1080": "HD1080p - (1920x1080)",
        "conference_setup_fhd": "For Full High Definition (FHD) Video Resolution",
        "conference_setup_4k": "4K - (3840 x 2160)",
        "conference_setup_4k_fhd": "For Full High Definition (FHD) Video Resolution",
        "conference_setup_sif": "SIF - (320x240)",
        "conference_setup_sif_vr": "For SIF Video Resolution",
        "conference_setup_hd720": "HD720p - (1280x720)",
        "conference_setup_hd720_vr": "For High Definition (HD) Video Resolution",
        "conference_setup_2k": "2K - (2048×1080)",
        "conference_setup_2k_fhd": "For Full High Definition (FHD) Video Resolution",
        "conference_setup_broadcastoptions": "Broadcast Options",
        "conference_setup_enable": "Enable",
        "conference_setup_disable": "Disable",
        "conference_setup_reportquality": "Report quality statistics to help improve voice and video calls",
        "conference_setup_havetroubleshoot": "Would you like to have a Conference Troubleshoot, then click the link here ",
        "conference_setup_conferencetroubleshoot": "CONFERENCE TROUBLESHOOTER",
        "conference_troubleshoot": "Troubleshoot",
        "conference_troubleshoot_faq": "FAQ",
        "conference_troubleshoot_emailreport": "EMAIL REPORT ",
        "conference_troubleshoot_gotoconference": "GO TO CONFERENCE",
        "conference_troubleshoot_os": "Operating System",
        "conference_troubleshoot_version": "version",
        "conference_troubleshoot_browser": "Browser",
        "conference_troubleshoot_chromeversion": "Chrome version",
        "conference_troubleshoot_privatebrowsing": "Private browsing",
        "conference_troubleshoot_displayresolution": "Display resolutions",
        "conference_troubleshoot_aspectratio": "Display aspect ratio",
        "conference_troubleshoot_hasspeakers": "System has Speakers ?",
        "conference_troubleshoot_foundspeaker": "Found Speaker devices ",
        "conference_troubleshoot_hasmicrophone": "System has Microphone ?",
        "conference_troubleshoot_foundmicrophone": "Found Microphone devices ",
        "conference_troubleshoot_haswebcam": "System has Webcam ?",
        "conference_troubleshoot_foundcameras": "Found Camera devices ",
        "conference_troubleshoot_webcampermission": "Website has webcam permissions ?",
        "conference_troubleshoot_microphonepermission": "Website has microphone permissions ?",
        "conference_troubleshoot_getUsermedia": "Browser allows getUserMedia on this page ?",
        "conference_troubleshoot_changeoutput_audiodevice": "Can you change output audio devices ?",
        "conference_troubleshoot_changecamera_resolution": "Can you change camera resolutions without making the new conference request ?",
        "conference_troubleshoot_supportwebrtc": "Browser Supports WebRTC (Either 1.0 or 1.1)?",
        "conference_troubleshoot_supportortc": "Browser Supports ORTC (WebRTC 1.1) ?",
        "conference_troubleshoot_turnserver": "Does TURN Server Reachable ?",
        "conference_troubleshoot_turn": "turn:139.59.64.172:443",
        "conference_troubleshoo_coturn" : "turn:coturn.instavc.com:443",
        "conference_troubleshoot_replacetracks": "Can you replace tracks without renegotiating peers ?",
        "conference_troubleshoot_record_remoteaudio": "Can your browser record remote audio or process remote audio stream in WebAudio API",
        "conference_troubleshoot_browsersupports_websocketapi": "Browsers support WebSockets API",
        "conference_troubleshoot_blockwebsocket": "Your system blocked WebSockets protocol, or WebSockets server is not accessible ? No",
        "conference_troubleshoot_supportwebaudioapi": "Browser Supports WebAudio API ?",
        "conference_troubleshoot_supportsctp": "Browser Supports SCTP Data Channels ?",
        "conference_troubleshoot_supportsrtp": "Browser Supports RTP Data Channels ?",
        "conference_troubleshoot_support_screencapturing": "This Page Supports Screen Capturing API ?",
        "conference_troubleshoot_support_multimonitor": "Does Browser Support multi-monitor selection & capturing screen of any monitor ?",
        "conference_troubleshoot_mobiledevice": "is it a mobile device ?",
        "conference_troubleshoot_supportscreencap_canvas": "Does Browser Supports Screen Capturing from Canvas ?",
        "conference_troubleshoot_supportscreencap_video": "Does Browser Supports Screen Capturing from Video ?",
        "conference_troubleshoot_supportpromisses": "Does Browser Supports Promises ?",
        "conference_troubleshoot_ipaddress": "Click to show IP addresses",
        "conference_troubleshoot_stunserver": "Does STUN Server Reachable ?",
        "cancel": "Cancel",
        "instavc_conference_revoke_fromhost" : "Revoke from host",
        "instavc_conference_startvideoconf_button": "START VIDEO CONFERENCE",
        "instavc_conference_loading": "loading your screen please wait",
        "instavc_conference_authenticating": "Authenticating into Instavc Server ....",
        "instavc_conference_joinconference": "Joining Conference",
        "instavc_conference_publishyourvideo": "Publishing your Video",
        "instavc_conference_publishyouraudio": "Publishing your Audio",
        "instavc_conference_settings": "Settings",
        "instavc_conference_setting": "Conference Settings",
        "instavc_conference_setting_source": "Source",
        "instavc_conference_setting_camera": "Camera",
        "instavc_conference_audioinputs": "Audio Inputs",
        "instavc_conference_setting_apply": "APPLY",
        "instavc_conference_setting_resolution": "RESOLUTION",
        "instavc_conference_setting_qr": "QR(BETA)",
        "instavc_conference_whiteboard": "Whiteboard",
        "instavc_conference_screenshare": "Screen Share",
        "instavc_conference_presenter": "Presenter",
        "instavc_conference_stopshare": "Stop Share",
        "instavc_conf_install_Screenshare" : "Install ScreenShare",
        "instavc_conference_videosync": "Watch Together",
        "instavc_conference_videourl": "Video URL",
        "instavc_conference_enter_videourl": "Enter Video URL",
        "instavc_conference_videourl_note": "Note: Only active participants can watch this video, late joinees cannot see the video",
        "instavc_conf_recordings" : "Recording",
        "instavc_conference_sharecontent": "Share your Screen",
        "instavc_conference_sharecontent_screen": "connect.instavc.com wants to share the contents of your screen. Choose what you'd like to share",
        "instavc_conference_shareentire_screen": "Your Entire Screen",
        "instavc_conference_sharescreen_applicationwindow": "Application Window",
        "instavc_conference_sharescreen_chrometab": "Chrome Tab",
        "instavc_conference_sharescreen_share": "Share",
        "instavc_conf_sharescreen":"You are sharing your screen!",
        "instavc_conf_share_otherparticipants": "Other participants can see the applications and documentations on your screen",
        "instavc_stopsharing" : "Stop Sharing",
        "instavc_conference_chat": "Chat",
        "instavc_conference_additional_camera": "Additional Camera",
        "instavc_conference_sharescreen_groupchat": "Group Chat",
        "instavc_conference_sharescreen_GC": "GC",
        "instavc_conference_entermessage": "Say Something!",
        "instavc_conference_adddevice": "Add Devices",
        "instavc_conference_adddevice_addcamera": "Add Camera",
        "instavc_conference_adddevice_source": "SOURCE",
        "instavc_conference_adddevice_camera": "Camera",
        "instavc_conference_adddevice_cameraname": "FaceTime HD Camera",
        "instavc_conference_adddevice_close": "CLOSE ",
        "instavc_conference_adddevice_publish": "PUBLISH",
        "instavc_conference_info": "info",
        "questions": "Questions",
        "instavc_conference_askquestions": "Ask Questions",
        "instavc_conference_info_meetingid": "Personal Meeting ID",
        "instavc_conference_fullscreen": "Full Screen",
        "conference_setup_invitepeoplel": "Invite People",
        "conference_setup_invite_linktojoin": "https://connect.instavc.com/conference-guest/93ac2135d88727ca29061b4b6bf9477bc8f285a8121ff580649512abad353fbc6751a134d56438f7/f",
        "conference_setup_invite_byemail": "Invite By Mail",
        "instavc_conference_participants": "Participants",
        "instavc_conference_participants_search": "Search ...",
        "instavc_conference_participant": "Participant",
        "instavc_conference_participants_muteall": "Mute All",
        "instavc_conference_participants_unmuteall": "UnMute All",
        "instavc_conference": "Conference",
        "instavc_conf_more": "More",
        "instavc_conference_picturemode": "This Video is playing in Picture-in-Picture Mode",
        "instavc_conference_mediastreams_stats": "Media Stream Stats",
        "instavc_conference_mediastreams_audio": "Audio",
        "instavc_conference_mediastreams_audio_send": "Send",
        "instavc_conference_mediastreams_audio_codec": "Codec",
        "instavc_conference_mediastreams_audio_rates": "Rates(Kbps)",
        "instavc_conference_mediastreams_audio_lost": "Lost",
        "instavc_conference_mediastreams_audio_fraction": "Fraction",
        "instavc_conference_mediastreams_video": "Video",
        "instavc_conference_mediastreams_video_send": "Send",
        "instavc_conference_mediastreams_video_codec": "Codec",
        "instavc_conference_mediastreams_video_rates": "Rates(Kbps)",
        "instavc_conference_mediastreams_video_lost": "Lost",
        "instavc_conference_mediastreams_video_fraction": "Fraction",
        "instavc_conference_resolution": "Resolution",
        "instavc_conference_framerate": "Frame rate",
        "instavc_conference_nack": "Nack",
        "instavc_conference_pli": "PLI",
        "instavc_conference_bw": "Available BW",
        "instavc_conference_audiomuted": "Audio Muted",
        "instavc_conference_microphonemuted": "Your Microphone Muted",
        "join_conference_join_meetinginvitation": "Join Meeting Invitations",
        "join_conference_search": "Search ...",
        "join_conference_meeting details": "meeting name\nInvited By:\ninvitor name",
        "join_conference_join": "JOIN",
        "join_conference_nomeetings": "No Meetings",
        "join_conference_noupcomingmeetings": "Oops! You have no upcoming meetings yet!",
        "insta_conference_mycontacts": "My Contacts",
        "insta_conference_search": "Search ...",
        "insta_conference_typemessage": "Type a message",
        "insta_conference_addcontact": "Add Contact",
        "insta_conference_enteremail": "Enter Mail ID ",
        "insta_conference_add": "ADD",
        "insta_conference_nouserfound": "No such user found with this email address",
        "instavc_conf_callnot_allowed" : "You cannot call other users when you are already in a call",
        "insta_conference_pendingapproval": "Pending Approval",
        "insta_conference_outgoingvideocall": "Outgoing Video Call",
        "insta_conference_calling": "Calling ....",
        "insta_conference_incomingvideocall": "Incoming Video Call",
        "insta_conference_calldecline": "Decline",
        "insta_conference_callaccept": "Accept",
        "insta_conference_instavcalert": "connect.instavc.com wants to allow",
        "insta_conference_usemicrophone": "Use your microphone",
        "insta_conference_usecamera": "Use your camera",
        "insta_conference_blockdevices": "Block",
        "insta_conference_allowdevices": "Allow",
        "instavc_schedulemeeting_createmeeting": "Create New Meeting / Edit",
        "instavc_schedulewebinar_createmeeting": "Create New Webinar / Edit",
        "instavc_schedulemeeting_meetingname": "Give a name to your meeting",
        "instavc_schedulemeeting_selectnames_tomeeting": "Select a room for your meeting",
        "instavc_schedulemeeting_setduration": "Set Duration",
        "instavc_schedulemeeting_15min": "15 Minutes",
        "instavc_schedulemeeting_30min": "30 Minutes",
        "instavc_schedulemeeting_60min": "60 Minutes",
        "instavc_schedulemeeting_setcustom": "Set Custom",
        "instavc_schedulemeeting_customduration": "Custom Duration",
        "instavc_schedulemeeting_minutes": "Minutes",
        "instavc_schedulemeeting_setconfpassword": "Set your conference password",
        "instavc_schedulemeeting_typepassword": "Type password for your meeting",
        "instavc_schedulemeeting_conferencecolor": "Give color to your conference icon",
        "instavc_schedulemeeting_invitepeople": "Invite People to this meeting",
        "instavc_schedulemeeting_search": "Search ...",
        "instavc_schedulemeeting_SM": "SM",
        "instvc_SM_message" : "The number of users with the Authorized user licence has exceeded the licence limit. If you want to invite more people you can",
        "instavc_SM_message_href" : "go to premium",
        "instavc_schedulemeeting_notify": "Notify the selected people about this meeting",
        "instavc_schedulemeeting_addreminder": "Add Reminders",
        "instavc_schedulemeeting_add": "ADD",
        "instavc_schedulemeeting_addmeetingreminder": "Add Meeting Reminder",
        "instavc_schedulemeeting_adddatetime": "Date picker , time picker",
        "instavc_schedulemeeting_apply": "APPLY",
        "instavc_schedulemeeting_name": "Please enter the meeting name",
        "instavc_schedulemeeting_selectroom": "Please select the room",
        "instavc_schedulemeeting_selectduration": "Please select the duration",
        "instavc_recuring_schedule" : "Recurring Schedule",
        "instavc_schedulemeeting_nothinginreminders": "Nothing in Reminders",
        "instavc_schedulemeeting_createbutton": "CREATE",
        "instavc_schedulemeeting_createdsuccessfully": "Schedule Created Successfully",
        "instavc_meetinglist_calender": "CALENDER",
        "instavc_meetinglist_selecteddates": "Your selected dates",
        "instavc_meetinglist_schedulecreted": "Schedules created",
        "instavc_meetinglist_pastschedule": "Past schedule",
        "instavc_meetinglist": "Meetings List",
        "instavc_meetinglist_meetingdetils": "Meeting name, Meeting Time, Organised By",
        "instavc_meetinglist_search": "Search ...",
        "instavc_meetinglist_deleterecuring": "Delete Recurring Schedule ",
        "instavc_meetinglist_alow_deleterecuring": "Would you like to delete only current schedule, or all schedules in the recurrence?",
        "instavc_meetinglist_schedule_series": "All other schedules in the series will remain:",
        "instavc_meetinglist_schedule_seriesdelete": "All Schedules in the series will be deleted:",
        "instavc_meetinglist_allrecuringsch": "All Recurring Schedules",
        "instavc_meetinglist_cancelchanges": "Cancel Changes",
        "instavc_meetinglist_noschedules": "No Schedules",
        "instavc_ml_schedulemessage" : "No meeting schedule's created yet! \n Create your schedule by clicking the button below.",
        "instavc_create_schedule" : "Create Schedule",
        "instavc_meetinglist_currentschedule": "Current Schedule",
        "instavc_meetinglist_join": "JOIN",
        "instavc_meetinglist_buynow": "BUY NOW",
        "instavc_dashboard_chooseplan": "Choose A Plan",
        "instavc_package_smbplan": "SMB Plans",
        "instavc_package_smbplan_host": "(Host Based License)",
        "instavc_package_smbplandescription": "A room can have only 1 host at a time. A Buyer can set or change the host account, who can control the room. Host accesses can’t be shared during the call. All other users can’t interact until Host joins the conference.",
        "instavc_package_basic": "Basic",
        "instavc_package_hostuser_smb": "1 Host and",
        "instavc_package_hostuser_smb_users": "Users",
        "instavc_package_currency": "$",
        "instavc_package_permonth": "/ month",
        "instavc_package_bill_smb": "Billed Annually",
        "instavc_package_include_smb": "This Package includes with",
        "instavc_package_specifictions_Whiteboard": "Whiteboard",
        "instavc_package_specifictions_Screen": "Screen Share",
        "instavc_package_specifictions_nChat": "Chat",
        "instavc_package_specifictions_Recording": "Recording",
        "instavc_package_specifictions_PresenterMode": "Presenter Mode",
        "instavc_package_buynowbutton": "BUY NOW",
        "instavc_package_fullfeatures_smb": "FULL FEATURES",
        "instavc_package_billinginfo": "Billing Information",
        "instavc_package_billinginfo_name": "Name",
        "instavc_package_billinginfo_orginazation": "Organization",
        "instavc_package_biilinginfo_gstin": "GSTIN",
        "Save": "SAVE",
        "instavc_package_billinginfo_edit": "EDIT",
        "instavc_package_billingdetails": "Billing Details",
        "instavc_package_billingdetails_firstname": "First Name",
        "instavc_package_billingdetails_lastname": "Last Name",
        "instavc_package_billingdetails_address": "Address",
        "instavc_package_billingdetails_zip": "Zip",
        "instavc_package_shippinginfo": "Shipping Information",
        "instavc_package_shippinginfo_edit": "EDIT",
        "instavc_package_shippingdetails": "Shipping Details",
        "instavc_package_shippingdetails_firstname": "First Name",
        "instavc_package_shippingdetails_lastname": "Last Name",
        "instavc_package_shippingdetails_address": "Address",
        "instavc_package_shippingdetails_zip": "Zip",
        "instavc_package_cartsummary": "Cart Summary",
        "instavc_package_hostbase": "Host Based License (License name)",
        "instavc_package_smbplan_basic": "SMB Plan - Basic",
        "instavc_package_plandetails": "$ 144.02\n\nbilled for 1 year\n$ 13 per month",
        "instavc_package_term": "Term",
        "instavc_package_1year": "1 Year",
        "instavc_package_2years": "2 Years",
        "instavc_package_3years": "3 Years",
        "instavc_package_4years": "4 Years",
        "instavc_package_5years": "5 Years",
        "instavc_package_subtotal": "Subtotal",
        "instavc_package_taxes": "Taxes & Fees (GST +18%)",
        "instavc_package_launchoffer": "LAUNCHOFFER",
        "instavc_package_promo": "Promo applied successfully",
        "instavc_package_remove": "Remove",
        "instavc_package_havepromocode": "Have a Promo Code ? Apply Here",
        "instavc_package_entercouponcode": "Please enter the valid coupon code",
        "instavc_package_total": "Total",
        "instavc_package_offerdisclaimer": "View Offer Disclaimer",
        "instavc_package_paynow": "PAY NOW",
        "instavc_package_payment_instavclicense": "PeopleLink Unified Communication\nPayment for InstaVC License",
        "instavc_package_phone": "Phone",
        "instavc_package_entervalid_contactnumber": "Please enter the valid contact number",
        "instavc_package_email": "Email",
        "instavc_package_enteremail": "Please enter a valid email id. Example : you@example.com",
        "instavc_package_paybycard": "PAY BY CARD",
        "instavc_package_securedbyrazorpay": "Secured by Razorpay",
        "instavc_package_card": "CARD",
        "instavc_package_enterotp": "Enter OTP sent on xxxxxxxxx\nto access Saved Cards",
        "instavc_package_resendotp": "Resend OTP",
        "instavc_package_skipsavedcards": " Skip Saved Cards",
        "instavc_package_enter_otp": "Please enter the OTP",
        "instavc_package_skipthesavedcards": "Skip the saved cards",
        "instavc_package_cardnumber": "Card Number",
        "instavc_package_entervalid_cardnumber": "Please enter a valid card number",
        "instavc_package_expiry": "Expiry",
        "instavc_package_cardholdername": "Card Holders Name",
        "instavc_package_cvv": "CVV",
        "instavc_package_3digitcode": "Its a 3 digit code printed on back of your card",
        "instavc_package_pay": "PAY",
        "instavc_package_remembercard": "Remember Card",
        "instavc_package_medium": "Medium",
        "instavc_package_hostuser_medium": "1 Host and 50 Users",
        "instavc_package_month_medium": "$15/ month",
        "instavc_package_bill_medium": "Billed Annually + GST 18%",
        "instavc_package_include_medium": "This Package includes with",
        "instavc_package_specifictions_medium": "Whiteboard\nScreen Share\nChat\nRecording\nPresenter Mode",
        "instavc_package_buynow_medium": "BUY NOW",
        "instavc_package_fullfeatures_medium": "FULL FEATURES",
        "instavc_package_advanced": "Advanced",
        "instavc_package_hostuser_advanced": "1 Host and 100 Users",
        "instavc_package_month_advanced": "$16/ month",
        "instavc_package_bill_advanced": "Billed Annually + GST 18%",
        "instavc_package_include_advanced": "This Package includes with",
        "instavc_package_specifictions_advanced": "Whiteboard\nScreen Share\nChat\nRecording\nPresenter Mode",
        "instavc_package_buynow_advanced": "BUY NOW",
        "instavc_package_fullfeatures_advanced": "FULL FEATURES",
        "instavc_package_virtualmcu": "Virtual MCU License",
        "instavc_package_virtualmcu_room": "(Room Based License)",
        "instavc_package_vmcudetails": "A Room can have only 1 host at a time. A Buyer can set or change the host account, who can control the room. Host accesses can’t be shared during the call. All other users can’t interact until Host joins the conference.",
        "instavc_package_instavc": "InstaVC",
        "instavc_package_silver": "Sliver",
        "instavc_package_userrooms_silver_users": "Users",
        "instavc_package_userrooms_silver_rooms": "Rooms",
        "instavc_package_month_silver": "$281/ month",
        "instavc_package_bill_silver": "Billed Annually + GST 18%",
        "instavc_package_include_silver": "This Package includes with",
        "instavc_package_specifictions_silver": "Whiteboard\nScreen Share\nChat\nRecording\nPresenter Mode",
        "instavc_package_buynow_silver": "BUY NOW",
        "instavc_package_fullfeatures_silver": "FULL FEATURES",
        "instavc_package_gold": "Gold",
        "instavc_package_userrooms_gold": "200 Users - 30 Rooms",
        "instavc_package_month_gold": "$467/ month",
        "instavc_package_bill_gold": "Billed Annually + GST 18%",
        "instavc_package_include_gold": "This Package includes with",
        "instavc_package_specifictions_gold": "Whiteboard\nScreen Share\nChat\nRecording\nPresenter Mode",
        "instavc_package_buynow_gold": "BUY NOW",
        "instavc_package_fullfeatures_gold": "FULL FEATURES",
        "instavc_package_platinum": "Platinum",
        "instavc_package_userrooms_platinum": "500 Users - 50 Rooms",
        "instavc_package_month_platinum": "$ 933/ month",
        "instavc_package_bill_platinum": "Billed Annually + GST 18%",
        "instavc_package_include_platinum": "This package includes with",
        "instavc_package_specifictions_platinum": "Whiteboard\nScreen Share\nChat\nRecording\nPresenter Mode",
        "instavc_package_buynow_platinum": "BUY NOW",
        "instavc_package_fullfeatures_platinum": "FULL FEATURES",
        "instavc_package_legacybaselicense": "Legacy Based License",
        "instavc_package_licensebased_ports": "License based on number of ports",
        "instavc_package_hardwareendpoint": "InstaVC With Hardware Endpoint",
        "instavc_package_webrtcconf_legacysupport": "WebRTC Conferencing with Legacy Supports",
        "instavc_package_sip": "SIP / BFCP Support",
        "instavc_package_chh323": "ChH323 / H.239 Support",
        "instavc_package_contactus": "CONTACT US",
        "instavc_myprofile_dashboard": "Dashboard",
        "instavc_myprofile_settings": "Settings",
        "instavc_myprofile": "MY PROFILE",
        "instavc_myprofile_personaldetail": "Personal Details",
        "instavc_myprofile_personaldetails_name": "Name",
        "instavc_myprofile_personaldetails_email": "Email",
        "instavc_myprofile_personaldetails_phoneno": "Phone No",
        "instavc_myprofile_personaldetails_address": "Address",
        "instavc_myprofile_personaldetails_city": "City",
        "instavc_myprofile_edit": "EDIT",
        "instavc_myprofile_personaldetails": "Personal Details",
        "instavc_myprofile_firstname": "First Name",
        "instavc_myprofile_lastname": "Last Name",
        "instavc_myprofile_email": "Email",
        "instavc_myprofile_phoneno": "Phone No",
        "instavc_myprofile_professionaldetails": "Professional Details",
        "instavc_myprofile_position": "Position",
        "instavc_myprofile_professionaldetails_email": "Email",
        "instavc_myprofile_professionaldetails_phoneno": "Phone No",
        "instavc_myprofile_professionaldetails_address": "Address",
        "instavc_myprofile_professionaldetails_branch": "Branch",
        "instavc_myprofile_professionaldetails_edit": "EDIT",
        "instavc_myprofile_officialdetails": "Official Details",
        "instavc_myprofile_officialdetails_position": "Position",
        "instavc_myprofile_officialdetails_email": "Email",
        "instavc_myprofile_officialdetails_phoneno": "Phone No",
        "instavc_myprofile_officialdetails_branch": "Branch",
        "instavc_myprofile_officialdetails_address": "Address",
        "instavc_myprofile_contactinfo": "CONTACT INFO",
        "instavc_myprofile_contactinformation": "Contact Information",
        "instavc_myprofile_add": "ADD",
        "instavc_myprofile_contactinformation_address": "Address",
        "instavc_myprofile_contactinformation_city": "City / Town",
        "instavc_myprofile_contactinformation_zip": "Zip",
        "instavc_myprofile_contactinformation_landmark": "Landmark",
        "instavc_myprofile_address": "Address",
        "instavc_myprofile_landmark": "Landmark",
        "instavc_myprofile_delete": "Alert",
        "instavc_myprofile_deletealert": "Are you sure you want to Delete this?",
        "instavc_myprofile_disagree": "DISAGREE",
        "instavc_myprofile_agree": "AGREE",
        "instavc_myprofile_websitelinks": "Website Link",
        "instavc_myprofile_website_add": "ADD",
        "instavc_myprofile_website": "Website",
        "instavc_myprofile_websiteexample": "https://www.google.com",
        "instavc_myprofile_sociallinks": "Social Links",
        "instavc_myprofile_sociallinks_add": "ADD",
        "instavc_myprofile_sociallink": "Social Links",
        "instavc_myprofile_sociallinks_example": "https://www.facebook.com/xxx",
        "instavc_myprofile_basicinfo": "Basic Information",
        "instavc_myprofile_basicinfo_add": "ADD",
        "instavc_myprofile_basicinfo_dob": "DOB",
        "instavc_myprofile_basicinfo_gender": "Gender",
        "instavc_myprofile_basicinformation": "Basic Information",
        "instavc_myprofile_basicinformation_dob": "Date Of Birth",
        "instavc_myprofile_basicinformation_gender": "Gender",
        "instavc_myprofile_basicinformation_deletealert": "Alert\nAre you sure you want to Delete this?",
        "instavc_myprofile_basicinformation_disagree": "DISAGREE",
        "instavc_myprofile_basicinformation_agree": "AGREE",
        "instavc_myprofile_uploadnewavatar": "UPLOAD NEW AVATAR",
        "instavc_myprofile_cropimage": "Crop Image",
        "instavc_myprofile_locale": "LOCALE",
        "instavc_myprofile_localesettings": "Locale Settings",
        "instavc_myprofile_preferredlanguage": "Preferred Language :",
        "instavc_myprofile_language": "English",
        "instavc_settings": "Settings",
        "instavc_settings_changepassword": "CHANGE PASSWORD",
        "instavc_settings_changepass": "Change Password",
        "instavc_settings_currentpassword": "Type Current Password",
        "instavc_settings_newpassword": "Type New Password",
        "instavc_settings_enter_currentpassword": "Please Enter the Current Password",
        "instavc_settings_enter_newpassword": "Please Enter the New Password",
        "instavc_settings_help": "Help",
        "instavc_settings_affiliate": "Become Affiliate",
        "instavc_settings_support": "Support Center",
        "instavc_settings_newticket": "Open a New Ticket",
        "instavc_settings_ticketstatus": "Check Ticket Status",
        "instavc_settings_troubleshoot": "Troubleshoot",
        "instavc_legacy_dailout": "Dial Out",
        "instavc_legacy_stats" : "Stats",
        "instavc_legacy_dail_in_detail" : "Dial-In Details",
        "instavc_legacy_h323_endpoint" : "H.323 End Point",
        "instavc_legacy_sip_endpoint" : "SIP End Point",
        "instavc_admin_users" : "Users",
        "instavc_admin_username" : "Username",
        "instavc_admin_user_accountstatus" : "Account Status",
        "instavc_admin_user_confirmed" : "Confirmed",
        "instavc_admin_user_unconfirmed" : "Unconfirmed",
        "instavc_admin_user_resetall" : "Reset All",
        "instavc_admin_user_sync" : "Sync",
        "instavc_admin_user_status" : "Status",
        "instavc_admin_user_phonenumber" : "Phone Number",
        "instavc_admin_user_created" : "Created",
        "instavc_admin_user_all" : "All",
        "instavc_admin_groups" : "Groups",
        "instavc_admin_addmore" : "Add More",
        "instavc_admin_group_peoplelink" : "Queens, GOLIVE, PeopleLink",
        "instavc_admin_select_plan" : "Select Plan",
        "instavc_admin_lastmodified" : "Last Modified",
        "instavc_admin_email_verified" : "Email Verified",
        "instavc_admin_verified" : "Verified",
        "instavc_admin_Unverified" : "Unverified",
        "instavc_admin_licenses" : "Licenses",
        "instavc_admin_addlicense" : "Add Licenses",
        "instavc_admin_defaultroom" : "Default Room",
        "instavc_admin_hostlicense" : "Host Based License",
        "instavc_admin_roomlicense" : "Room Based License",
        "instavc_admin_virtualmcu" : "Virtual MCU Based License",
        "instavc_admin_enterprise" : "Enterprise License",
        "instavc_mcuserver": "MCU Server",
        "instavc_mculink" : "https://awsmcu.instavc.com",
        "instavc_admin_expirydate" : "Expiry Date",
        "instavc_admin_settings" : "Admin Settings",
        "instavc_default_offer" : "Default Offer for Referral",
        "instavc_enter_coupon_code" : "Enter Coupon Code",
        "instavc_current_partshare" : "Current Partner Share",
        "instavc_share_value" : "Share Value",
        "instavc_affliate_share" : "Current Affiliate Share",
        "instavc_enter_share" : "Enter Share Value (%)",
        "instavc_roomname" : "Room Name",
        "instavc_room_ten" : "Ten",
        "instavc_room_twenty" : "Twenty",
        "instavc_room_thirty" : "Thirty",
        "instavc_room_hostbased" : "Host Based",
        "instavc_virtualmcu": "Virtual MCU",
        "instavc_rooms_enterprises" : "Enterprise",
        "instavc_roomid" : "Room ID",
        "instavc_roomtype" : "Room Type",
        "instavc_room_options" : "Room Options",
        "instavc_commercialtype": "Commercial Type",
        "instavc_free" : "Free",
        "instavc_poc" : "POC",
        "instavc_commercial" : "Commercial",
        "instavc_createdAt" : "Created At",
        "instavc_useraccess" : "User Access",
        "instavc_peoplelinkroom" : "Peoplelink Room",
        "instavc_inputlimit" : "Input Limit",
        "instavc_participant_limit" : "Participants Limit",
        "instavc_adduser" : "Add User",
        "instavc_searchbyname" : "Search By Name",
        "instavc_manage_plans" : "Manage Plans/Products",
        "instavc_new_plans": "New Plan/Product",
        "instavc_allplan_list" : "All Plans List",
        "instavc_sortby" : "Sort By",
        "instavc_active" : "Active",
        "instavc_inactive" : "Inactive",
        "instavc_create_plan" : "Create New Plan",
        "instavc_plan_name" : "Plan Name",
        "instavc_enteramount" : "Enter Amount (Min: INR 100)",
        "instavc_select_product" : "Select Product",
        "instavc_host_room" : "1 Host / Room",
        "instavc_multiroom" : "Multi Host / Room",
        "instavc_legacyrooms" : "Legacy Room",
        "instavc_number_hosts" : "Number of Hosts",
        "instavc_number_participants" : "Number of Participants",
        "instavc_number_room" : "Number of Rooms",
        "instavc_subscriptiom_period" : "Subscription Period",
        "instavc_sub_yearly" : "Yearly",
        "instavc_sub_monthly" : "Monthly",
        "instavc_sub_weekly" : "Weekly",
        "instavc_setinterval" : "Set Interval",
        "instavc_type" :"Type",
        "instavc_subscription" :"Subscription",
        "instavc_onetime" : "One Time",
        "instavc_description" : "Description",
        "instavc_planid" : "PlanID",
        "instavc_orders" : "Orders",
        "instavc_orderid" : "Order Id",
        "instavc_none" : "None",
        "instavc_customer" : "Customer",
        "instavc_build_anually" : "Build Annually",
        "instavc_recurring_billing" : "Recurring Billing",
        "instavc_next_due" : "Next Due on",
        "instavc_invoice_details" :"Invoices Detail",
        "instavc_recurring_payment" : "Recurring Payment",
        "instavc_todays_sale" : "Today's Sale",
        "instavc_total_order" : "Total Orders",
        "instavc_total_rooms" : "Total Rooms",
        "instavc_current_conference" : "Current Conferences",
        "instavc_current_user" : "Current Users",
        "instavc_live_broadcast" : "Live Broadcasts",
        "instavc_loading_chart" : "Loading Chart",
        "instavc_view" : "View",
        "instavc_coupon_info" : "Coupon Info",
        "instavc_new_coupon" : "New Coupon",
        "instavc_create_coupon" : "Create New Coupon",
        "instavc_coupon_code" : "Coupon Code",
        "instavc_company_code" : "Company Code",
        "instavc_select_plan" : "Select Plans",
        "instavc_discount" : "Discount",
        "instavc_activestatus" : "activeStatus",
        "instavc_usage" : "Usage",
        "instavc_activeusage" : "activeusage",
        "instavc_singleuse" : "Single Use",
        "instavc_multiuse" : "Multi Use",
        "instavc_first_page" : "First Page",
        "instavc_previosu_page" : "Previous Page",
        "instavc_next_page" : "Next Page",
        "instavc_last_page" : "Last Page",
        "instavc_plan" : "Plan",
        "instavc_request_withdrawal" : "Request Withdrawal",
        "instavc_withdraw" : "Withdraw",
        "instavc_account" : "Account",
        "instavc_verification" : "Verification",
        "instavc_withdraw_history" : "Withdraw History",
        "instavc_add_bank" : "Add a bank account for withdrawal",
        "instavc_bank_description" : "We protect your bank account by keeping your financial information confidential. We will email you the status whenever we receive a request for withdrawal from this account.To avoid withdrawal failures and return amount, the name on this account must match the name on your bank account. It may take some time to complete the transfer depending on your bank's policies and holiday schedules.",
        "instavc_bank_accountnumber" : "Bank Account Number",
        "instavc_enter_accountnumber" : "Enter your account number",
        "instavc_account_name" : "Account Holder Name",
        "instavc_per_bank_account" : "As per bank account",
        "instavc_ifsc" : "IFSC Code",
        "instavc_bankname" : "Bank Name",
        "instavc_branchname" : "Bank Branch Name",
        "instavc_confirmdetails" : "Confirm Details",
        "instavc_pandetails" : "Please provide below details as per your PAN",
        "instavc_enter_pan" : "Enter your name as per PAN",
        "instavc_fathername" : "Father's Name",
        "instavc_enter_fathername" : "Enter Father's Name",
        "instavc_pannumber" : "PAN Number",
        "instavc_panexampe" : "Ex: BNZPM2501G",
        "instavc_upload_pan_copy" : "Upload PAN Copy",
        "instavc_save_details" : "Save Details",
        "instavc_sales" : "Sales",
        "instavc_transactionid" : "Transaction Id",
        "instavc_date" : "Date",
        "instavc_amount" : "Amount",
        "instavc_setaccount_withdraw" : "Setup account details before a withdrawal",
        "instavc_first_withdraw" : "If you are a first time user requesting for withdrawal, you need to fill the account details and verify your account using PAN Card details in the next tabs. Once you are done with the verification, you can proceed to request for withdrawal from your available balance.",
        "instavc_accountdetails" : "Fill Account Details",
        "instavc_available_balance" : "Available Balance",
        "instavc_amount_withdraw" : "Amount to withdraw",
        "instavc_enteramount_withdraw" : "Enter the amount you want to withdraw",
        "instavc_select_payment_mode" : "Select Payment Mode",
        "instavc_paypal_payment" : "Paypal Payment",
        "instavc_bank_transaction" : "Bank Transaction",
        "instavc_paypalfee" : "Paypal Fee",
        "instavc_totalamount_withdraw" : "Total amount to withdraw",
        "instavc_sendrequest" : "Send Request",
        "instavc_no_request_withdraw" : "No requests for Withdrawal raised yet!",
        "instavc_start_process_withdraw" : "You can start processing for withdraw from below.",
        "instavc_request_withdraw_now" : "Request Withdraw Now",
        "instavc_current_commission" : "Current Commission",
        "instavc_from" : "From",
        "instavc_to" : "To",
        "instavc_today" : "Today",
        "instavc_last7days" : "Last 7 days",
        "instavc_lastmonth" : "This Month",
        "instavc_paid_customers" : "Paid Customers",
        "instavc_share_referral_link" : "Share your referral link with friends",
        "instavc_copylink" : "Copy Link",
        "instavc_minimum_payout" : "Minimum Payout",
        "instavc_referral_signup" : "Referral Signups",
        "instavc_referral_clicks" : "Referral Clicks",
        "instavc_paid" : "Paid",
        "instavc_unpaid" : "Unpaid",
        "instavc_totalearned" : "Total Earned",
        "instavc_myreferral" : "My Referrals",
        "instavc_viewall" : "View All",
        "instavc_mysales" : "My Sales",
        "instavc_sms_mfa" : "SMS MFA Status",
        "instavc_facebook" : "Facebook",
        "instavc_twitter" : "Twitter",
        "instavc_linkedin" : "LinkedIn",
        "instavc_referred_users" : "Referred Users",
        "instavc_created_date" : "CreatedDate",
        "instavc_myearnings" : "My Earnings",
        "instavc_earnings" : "Earnings",
        "instavc_commission_share" : "Commission Share",
        "instavc_commission_percentage" : "Commission Percentage",
        "instavc_coupon_offers": "Coupons/Offers",
        "instavc_my_referral_account" : "My Referral Account",
        "instavc_req_withdraw" : "Request Withdraw",
        "instavc_authenticating" :"Authenticating to instaVC server ...",
        "instavc_join_access_media" : "Joined.. Accessing Media Devices...",
        "instavc_publish_success" : "Publish Successful",
        "instavc_recieves" : 'Receives',
        "instavc_host_screenshare" : "You need to become a Host for screen / document sharing or",
        "instavc_request_sharescreen" : "Request access for screen / document sharing",
        "instavc_note" : "Note",
        "instavc_filesize" : "File size should not be more than 10MB",
        "instavc_become_host" : "To become a host click the doted overflow menu icons on the video",
        "instavc_clicklink" : "click Become a Host link, in the popup and enter host password and click Become a host button",
        "instavc_doc_share" : "Document Share",
        "instavc_search_host" : "Waiting for Host",
        "instavc_wait_for_host" : "Please wait for the host to start this meeting",
        "instavc_open_black_dialog" : "Open black dialog",
        "instavc_open_white_dialog" : "Open white dialog",
        "instavc_dail_detail" : "Dial - In - Details",
        "instavc_request_access" : "Request Access",
        "instavc_inordder_enable" : "In order to enable",
        "instavc_host_permission" : "you need access permission from the Host",
        "instavc_please_wait" : "Please Wait for ",
        "instavc_host_to_permit" : "(Host)  permission to",
        "instavc_alternate_content" : "Alternative Content",
        "instavc_meeting_room" : "'s Meeting Room",
        "instavc_host_left_conference" : "Host Left the conference!",
        "instavc_conf_end" : "Conference will end automatically in",
        "instavc_seconds" : "Seconds",
        "instavc_waiting_msg" : "Thanks for Waiting. We'll start the meeting when the host joins",
        "instavc_let_hostname" : "Do you want to let the host know you're waiting?",
        "instavc_exitconf" : "Exit Conference",
        "instavc_host_left" : "Host Left...",
        "instavc_end_host_msg" : "The host has ended this meeting. Thank you for attending.",
        "instavc_close_conf" : "you can go back to home (or) close the application.",
        "instavc_gohome" : "Go to Home",
        "instavc_hand_raised" : "Hand Raised",
        "instavc_ask" : "Ask",
        "instavc_protocal" : "Protocol",
        "instavc_bitrate" : "Bitrate",
        "instavc_confirm_details" : "Confirm Your Details",
        "instavc_enter_info_conf" : "Please enter your information before starting the conference.",
        "instavc_have_acc" : "If you already have an account?",
        "instavc_host_access" : "Host Access",
        "instavc_host_pass" : "Enter Host Password",
        "instavc_becomehost" : "Become Host",
        "instavc_permit_screenshare" : "Permit Screen-Share",
        "instavc_allow_access" : "Allow Whiteboard Access",
        "instavc_request_permission" : "has requested additional permissions to",
        "instavc_deny" : "Deny", 
        // "instavc_planid" : "PlanId",
        "instavc_customeremail" : "Customer Email",
        "instavc_orderlink" : 'Order Link',
        "instavc_customerid" : "Customer Id",

        "instavc_selectpackage" : "Select Package",
        "instavc_host_package" : "Host Based Package",
        "instavc_virtualbase_mcu" : "Virtual MCU Based",
        "instavc_plans_product" : "Plans/Products",
        "instavc_nodata" : "No Data Available",
        "instavc_nodata_msg" : "Oops! There is no data available to show you right now.",
        "instavc_basicplan" : "Basic Plan",
        "instavc_host_participants" : "1 Host with 30 participants",
        "instavc_link" : "Link",
        "instavc_cancel_order" : "Cancel Order",
        "instavc_per" : "per",
        "instavc_user_email" : "User Email",
        "instavc_owner" : "Owner",
        "instavc_user" : "User",
        "instavc_default_users" : "Default Offers",
        "instavc_system_settings" : "System Settings",
        "instavc_expires" : "Expires",
        "instavc_start_VC" : "Start Video Conference",
        "instavc_start_AC" : "Start Audio Conference",
        "instavc_date_error" : "Start date and time should be greater than current time",
        "instavc_browser_update" : "Browser Update Required",
        "instavc_browser_note" : "We noticed you are using an older version of BROWSERNAME which is now unsupported.",
        "instavc_browser_msg" : "Instavc.com requires an updated version of BROWSERNAME or any other modern browsers.",
        "instavc_browser_upgrade" :"Please Upgrade to any of the latest versions of these browsers for a better experience.",
        "instavc_googlechrome" : "Google Chrome",
        "instavc_gc_version74" : "Version 74",
        "instavc_mfirefox" : "Mozilla Firefox",
        "instavc_mff_version" : "Version 66",
        "instavc_opera" : "Opera",
        "instavc_opera_version" : "Version 60.0+",
        "instavc_microsoftedge" : "Microsoft Edge",
        "instavc_ms_version" :"Version 44+",
        "instavc_safari" : "Apple Safari",
        "instavc_safari_version" : "Version 12.1",
        "instavc_contact_msg" : "Already upgraded, but still having problem? Contact us at",
        "instavc_camera_busy" : "Camera Seems to be busy!",
        "instavc_close_camera" : "Please close all the application and try again.",
        "instavc_resolution" :"Resolution not supported!",
        "instavc_camera_notsupport" : "Your camera doesn’t support this resolution.",
        "instavc_camera_reconfig" : "Reconfigure your settings with lower resolution and try again.",
        "instavc_change_resolution" :"Change Resolution",
        "instavc_confirmation" : "Confirmation",
        "instavc_yes" : "Yes",
        "instavc_delete_room" : "Are you sure want to delete the Room?",
        "instavc_delete_license" : "Are you sure want to delete the License?",
        "instavc_help" :"We are here to help you",
        "instavc_browse_questions" : "Browse through the most frequently asked questions.",
        "instavc_os" :"Operating System",
        "instavc_macos" :"Mac OS X version:",
        "instavc_chrome_version" : "Chrome version:",
        "instavc_private_browsing" : "Private browsing?",
        "instavc_faq_no" : "No",
        "instavc_display_resolution" : "instavc_display_resolution",
        "instavc_aspect_ratio" : "Display aspect ratio",
        "instavc_sys_hasspeakers" : "System has Speakers?",
        "instavc_found_speakers" : "Found Speaker devices : 2",
        "instavc_speakers1" : "Speaker 1",
        "instavc_speakers2" : "Speaker 2",
        "instavc_sys_have_microphone" : "System has Microphone?",
        "instavc_found_microphone" : "Found Microphone devices : 2",
        "instavc_microphone1" : "Microphone 1",
        "instavc_microphone2" : "Microphone 2",
        "instavc_has_webcam" : "System has Webcam?",
        "instavc_found_speakers1" : "Found Speaker devices : 1",
        "instavc_camera1" : "Camera 1",
        "instavc_webcam_permission" : "Website has webcam permissions?",
        "instavc_microphone_permission" : "Website has microphone permissions?",
        "instavc_browser_allows" : "Browser allows getUserMedia on this page?",
        "instavc_change_output_device" : "Can you change output audio devices?",
        "instavc_change_camera_resolutions" : "Can you change camera resolutions without making new getUserMedia request?",
        "instavc_browser_support_webrtc" : "Browser Supports WebRTC (Either 1.0 or 1.1)?",
        "instavc_browser_support_ortc" : "Browser Supports ORTC (WebRTC 1.1)?",
        "instavc_initial_setup" : "Please wait while your initial setup is being processed!",
        "instavc_close_browser" : "Do not close your browser or use the back button at this time.",
        "instavc_whoops" : "Whoops!",
        "instavc_nonet_msg" : "No internet connection found. Please check your connection or try again.",
        "instavc_nosearch" : "No Search Results Found",
        "instavc_noserchmsg" : "We can't find any item matching your search.",
        "instavc_nosearch_filter" : "Try adjusting your search or filter to find what you're looking for.",
        "instavc_gotohoem_page" : "Go to home page",
        "instavc_pageloading" : "Page Loading... Please Wait!",
        "instavc_process" : "Processing...",
        "instavc_session_timeout" : "Session Timeout",
        "instavc_session_inactivity" : "Your session is being timed out due to inactivity.",
        "instavc_session_msg" : "Please choose to stay signed in.",
        "instavc_session_reconnect" : "Reconnect",
        "instavc_site_notreached" : 'This site cannot be reached!',
        "instavc_site_webaddress" : "Make sure that you've got the right web address",
        "instavc_check_internet" : "Check your network",
        "instavc_search_url" : "Search for the URL in other browser",
        "instavc_goto" : "Go to",
        "instavc_request_for_access" : "Request for Access",
        "instavc_start_collabrate" : "to start collaborating in whiteboard",
        "instavc_large_conference_mode" : 'Its "Large Conference" mode, you cannot talk or watch others until host provide you the permission',
        "instavc_allow_participants" : 'Its "Large Conference" mode, you have to manually allow participants to speak',
        "instavc_buy_premium" : "Buy Premium to unlock these features",
        "instavc_alert_tryagain" : "Something went wrong, Please try again",
        "instavc_enter_name" : "Enter Your Name",
        "instavc_confirm" : "Confirm",
        "instavc_ok" : "Ok",
        "instavc_askquestion" : "Ask a question...",
        "instavc_recieve" : "Receive",
        "instavc_delay" : "Delay(Ms)",
        "instavc_audio_opus" : "Audio/opus",
        "instavc_video_vp8" : "video/vp8",
        "instavc_browser_not_support_incall" : 'Your Browser does not support resolution change during the call!',
        "instavc_failed_toPublish" : "Failed to Publish Video",
        "instavc_publish_fail" : "Publish Failed",
        "instavc_video_cant_publish" : "Video cannot be published, due to user limit reach",
        "instavc_failed_joinConference" : 'Failed to Join conference',
        "instavc_mute_audio" : "You cannot mute/unmute if remote audio muted",
        "instavc_remote_audio_muted" : "Remote Audio Muted!",
        "instavc_enter_conferenceid" : "Enter Your Conference ID",
        "instavc_share_screen" : "Share Screen",
        "instavc_sharing_your_screen" : "Sharing your screen...",
        "instavc_stop" : "Stop",
        "instavc_back" : "Back",
        "instavc_choose_plan" : "Choose your plan from the list below",
        "instavc_have_promotionalcode" : "Have a Promotional Code / Partner Code ?",
        "instavc_contact_pricing" : "Contact us for Pricing",
        "instavc_payment_failed" : 'Payment Failed',
        "instavc_payment_error" : 'Unfortunately an error occurred while handling your payment.',
        "instavc_contact_support" : "Please contact our support team if any assistance required.",
        "instavc_tryagain" : "Try Again",
        "instavc_payment_success" : "Payment Successful",
        "instavc_thanks_for_payment" : "Thank you for your payment!",
        "instavc_payment_processed" : 'Your payment has been processed Successfully. An automated payment receipt',
        "instavc_mail_sent" : 'will be sent to your registered email address shortly',
        "instavc_goto_homepage" : "Go Back to home page",
        "instavc_want_toend_call" : "Want to End the call?",
        "instavc_exit_window" : 'Closing this window will exit from your call. Are you sure you want to end this call?',
        "instavc_create_room" : "Create Room",
        "instavc_userlimit" : "User Limit",
        "instavc_edit_room" : "Edit Room",
        "instavc_update" : 'Update',
        "instavc_manage_license" : 'Manage Licences',
        "instavc_package" : "Package",
        "instavc_no_of_rooms" : "No.of Rooms",
        "instavc_no_of_users" : "No.of Users",
        "instavc_purchasedOn" : "Purchased On",
        "instavc_expiredon" : "Expired On",
        "instavc_or" : "OR",
        "instavc_otherhost_email" : "If you want to make any user as host, enter his/her email address.",
        "instavc_set_host_password" : 'Set Host Password',
        "instavc_host_password" : 'Host Password',
        "instavc_access_room" : 'Who else can access this Room?',
        "instavc_select_user_host" : "Select any user from the list to make him/her a host.",
        "instavc_prologin" : "InstaVC Pro Login",
        "instavc_start" : "Start",
        "instavc_enter_website" : "Please Enter the Website name",
        "instavc_enter_valid_email" : 'Please enter the valid email',
        "instavc_enter_valid_phone" : 'Please enter the valid phone number',
        "instavc_field_required" : 'This Field is Required',
        "instavc_invitedby" : "Invited By",
        "instavc_storage" : "Storage",
        
        "affiliate_approve" : "Approved",
        "affiliate_pending" : "Pending",
        "affiliate_rejected" : "Rejected",
        "affiliate_user_status" : "User Status",
        "commission_other" : "Other Commissions",
        "add_commission" : "Add Commissions",
        "delete_commission" : "Do you want to delete commission",
        "update_commission" : "Update Commissions",
        "name" : "Name",
        "offer_value" : "Offer Value",
        "add" : "Add",
        "update" : "Update",
        "affiliates" : "Affiliates",
        "status" : "Status",
        "status_change_message" : "Would you like to change the Status?",
        "approve" : 'Approve',
        "reject" :"Reject",
        "commissions" : "Commissions",
        "withdraw_data" : "Withdraw Data",
        "withdraw_amount" : 'Withdraw Amount',
        "payment_method" : "Payment Method",
        "payment_fee" : "Payment Fee",
        "total_amount_withdraw" : "Total Amount For Withdraw",
        "total_earnings" : "Total Earnings",
        "pending_amount" : "Pending Amount",
        "paid_amount" : "Paid Amount",
        "unpaid_amount" : "Unpaid Amount",
        "requested_withdraw" : "Requested Withdraw Amount",
        "english": "English",
        "french" : "French",
        "hindi": "Hindi",
        "gujarati": "Gujarati",
        "kannada": "Kannada",
        "marathi": "Marathi",
        "tamil": "Tamil",
        "telugu": "Telugu",
        "urdu": "Urdu",
        "spanish": "Spanish",
        "malyalam": "Malyalam",
        "italian": "Italian",
        "bangla" : "Bangla",
        "portuguese": "Portuguese",
        "room" : "Room",
        "create" : 'Create',
        "repeat" : "Repeat",
        "daily" : "Daily",
        "repeat_every" : "Repeat Every",
        "repeat_on" : "Repeat on",
        "repeat_by" : "Repeat By",
        "ends" : "Ends",
        "after" : "After",
        "occurences" : "occurrences",
        "on" : "on",
        "summary" : "Summary",
        "repeats" : "Repeats",
        "withdraws" : "Withdraws",
        "every" : "Every",
        "days" : "Days",
        "month": "month",
        "onday" : "on day",
        "years" : "years",
        "weeks" : "weeks",
        "months": "months",
        "times" : "times",
        "until" : "until",
        "past_meeting_list" : "Past meeting list",
        "organised_by" : "Organised By",
        "delete" : "Delete",
        "join" : "Join",
        "to" : "to",
        "enter_password" : "Enter Password",
        "submit" : "submit",
        "enter_passwordmessage" : "Please Enter the Password",
        "delete_message" : "Do You want to delete",
        "meeting_permanent" : "meeting Permanently?",
        "day" : "Day",
        "password_message": "password is incorrect",
        "timezone" : "Time Zone",
        "by" : "By",
        "weekdays" : ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
        "allweek" : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        "day_month" : "day of the month",
        "day_week" : "day of the week",
        "week": "Week",
        "month": "Month",
        "year": "Year",
        "enterpassword": "Please enter your new password and confirm password",
        "passwordmessage" : "Password and Confirm Password both should be same",
        "passwordupdate" : "Password Updated Successfully",
        "numeric" : ['first', 'second', 'third', 'fourth', 'fifth'],
        "verify_email" : "Verify Your Email",
        "confirm_code" : "Confirmation Code",
        "verify_msg" : "Please check your mobile for the code.",
        "verify" : "Verify",
        "not_recieved" : "Not Received?",
        "resend_otp" : "Resend OTP",
        "start_browsing" : "Start Browsing",
        "become_affiliate" : "Become Affiliate",
        "affiliate" : "Affiliate",
        "verify_mobile" : "Verify Your Mobile",
        "instavc_allow_DocumentAccess" : "Permit Document-Share",
        "instavc_allow_Notes" : "Permit Notes",

        //common - CameraResolution	
        "camera_not_access" : "Camera not accessible due to one of the following reasons:",
        "camera_not_access_reason1" : "The resolution settings might not be compatible with this display. Reconfigure your settings to lower resolution and try again.",
        "camera_not_access_reason2" : "Your Camera is being used by a different application, Please close all applications and try again by clicking on the link. ",
            
        //Containers	
        //content - components - assignQuestionnaire	
        "asgnQue_Assign_Question" : "Assign Questionnaire",
        "asgnQue_assigned_question" : "Assigned Questionnaire",
        //assignQuestList	
        "quslsit_assig_que_list" : "Assigned Questionnaire List",
        "quslsit_question_lists" : "Questionnaire Lists",
        "quslsit_add_new_question" : "Add New Questionnaire",
        "quslsit_no_question_assign":"No Questionnaire has been assigned yet!",
        "quslsit_assign_new_ques" : "Assign a new Questionnaire to click the above Assign Questionnaire Button",

        //model-assign-Questionnaire	
        "select_questionnaire" : "Select Questionnaire",
        "duration" : "Duration",
        "time_limit" : "Time Limit",
        "show_results" : "Show Results",
        "save" : "Save",
        //viewAssigned	
        "content_group" : "Content Group",
        "list_assigned_ques" : "List of assigned questions:",
        "mins" : "mins",
        "marks" : "Marks",
        //AssignedQuestions	
        "asgQues_5_Marks" : "( 5 Marks )",
        "asgQues_answers" : "Answers",
        "asgQues_no_questions" : "No Questions!",

        //content - components - contentGrade	
        //GradeSetting	
        "grdSet_GPA_calculation" : "GPA Calculation from the percentage",
        "grdSet_GPA_cal_msg" : "The percentage is for 100% which is nothing but 100 points. So 25 points in percentage is equivalent to 1 point in GPA System. Percentage to GPA formula is just divide your percentage by 100 and multiply by 4. If you percentage is 75% then your grade would be (75/100)*4 = 3.",
        "grdSet_grade_point" : "If grade points is",
        //managegrade	
        "mngGrd_grades" : "Grades",
        "mngGrd_modify" : "Modify",
        "mngGrd_save_grades" : "Save Grades",
        "mngGrd_modify_grades" : "Modify Grades",
        "mngGrd_grade_info" : "Grade Info",
        //content - components - ContentParticipants	
        //components-admnAnsView
        "admnAns_answer_list_for_question" : "Below are the list of answers for all the questionnaire of this Content Group.",
        //components-participants	
        "prtcpt_certification" : "Certifications:",
        "prtcpt_other_content_group" : "In other Content Groups",
        "prtcpt_sales" : "Sales",
        "prtcpt_marketing" : "Marketing",
        "prtcpt_human_resource" : "Human Resource",
        //manageparticipants	
        "mngPrtcpt_add_participants" : "Add Participants",
        "mngPrtcpt_participants_list" : "Participants Lists",
        "mngPrtcpt_import_participants" : "Import Participants",
        "mngPrtcpt_download_sample" : "Download Sample",
        "mngPrtcpt_no_participants_added" : "No Participants has been added yet!",
        "mngPrtcpt_add_new_participant" : "Add a new Participant, to add click the above Add Participant Button",
        //modal-add-participants	
        "addPrtcpt_select_participants" : "Select Participants",
        "addPrtcpt_model_add_participants" : "Add Participants",
        //partipantGradeView	
        "prtcptGrde_participant_grade" : "Participant Grade Details",
        "prtcptGrde_grade_participants_list" : "Participant List",
        //content - components - ContentQuestionnaire	
        //managequestionnaire	
        "mngQuesnr_create_questionnaire" : "Create Questionnaire",
        "questionnaire_list" : "Questionnaire List",
        //managequestions	
        "mngQues_question_details" : "Questionnaire Details",
        "mngQues_no_question_created" : "No Question has been created yet!",
        "mngQues_create_new_question" : "Create a new Question, to create click the above Create Question Button",
        //modal-create-quest-group	
        "mdlGrp_questionnaire_name" : "Questionnaire Name",
        "mdlGrp_update" : "Update",
        "mdlGrp_create" : "Create",
        //modal-create-questions
        "mdlCrtQues_question_type" : "Question Type",
        "mdlCrtQues_sel_question_type" : "Select Question Type",
        "mdlCrtQues_single_sel_multiple_choice" : "Single Select Multiple Choice",
        "mdlCrtQues_multiselect_multiple_choice" : "Multiselect Multiple Choice",
        "mdlCrtQues_ture_false" : "True/False",
        "mdlCrtQues_enter_answer_choice" : "Enter the answer choices, and mark which answer is correct",
        "mdlCrtQues_add_another_choice" : "+ Add Another Choice",
        //viewQuestionnaire	
        "viewQues_add_questions" : "Add Questions",
        "viewQues_no_question_created" : "No Question has been created yet!",
        "viewQues_create_new_question" : "Create a new Question, to create click the above Create Question Button",

        //content - components - createContent	
        //createcontent	
        "crteContent_create_new_content" : "Create New Content",
        "crteContent_update_content" : "Update Content",
        "crteContent_save_content" : "Save Content",
        "crteContent_content_list" : "Content Lists",
        "edit_content" : "Edit content",
        "crteContent_create_content" : "Create content",
        "crteContent_create_your_content_like" : "Create your content by adding media like images, video files, audio files, and documents.",
        "crteContent_upload_document" : "Upload Documents",
        "crteContent_upload_media" : "Upload Media",
        "crteContent_document" : "Documents",
        "crteContent_media_files" : "Media Files",
        //view-content	
        "viewCon_content_view" : "Content View",
        "viewCon_content_list" : "Content Lists",
        "viewCon_View_content" : "View Content",
        "viewCon_enable" : "Enable: ",
        "viewCon_document" : "Documents",
        "viewCon_media_files" : "Media Files",

        //content - components - contentRow	
        "conRow_by" : "By: ",

        //content - components - managecontent	
        "create_new_content" : "Create Content",
        "mngCon_all" : "All",
        "mngCon_content_list" : "Content Lists",
        "mngCon_no_content_created" : "No Content has been created yet!",
        "mngCon_create_new_content" : "Create a new Content, to create click the above Create Content Button",
        //content - components - model-access-right	
        "mdlAccRight_share_access" : "Share access with other Instructors",
        "mdlAccRight_type_id_add" : "Type email id and add",
        "mdlAccRight_no_instructor_added" : "No Instructor has been added yet!.",
        "mdlAccRight_add_people_from_addressbok" : "Add people from your Address book, or by typing email address.",
        "mdlAccRight_contacts" : "Contacts",
        "mdlAccRight_add_contacts" : "Add contacts to the Content Group, from your address book contacts",
        "mdlAccRight_no_contacts_added" : "No Contacts has been added yet!.",
        //content - components - model-confirm-delete	
        "mdlConfDel_confirmation" : "Confirmation",
        "mdlConfDel_are_you_sure_delete" : "Are you sure you want to delete this",
        "mdlConfDel_delete" : "Delete",
        //content - components - model-create-content-group	
        "mdlCrtConGrp_alert_enter_group_title" : "Please enter the Group Title",
        "mdlCrtConGrp_alert_enter_group_description" : "Please enter the Group Description",
        "mdlCrtConGrp_group_name" : "Group Name",
        //content - components - model-upload-doc	
        "mdlUpldoc_alert_enter_title_doc" : "Please Enter Title of The Document",
        "mdlUpldoc_upload_document" : "Upload Documents",
        "mdlUpldoc_document_title" : "Document Title",
        "mdlUpldoc_upload_doc_from_system" : "Upload document from your system, it supports .pdf, excel, word document.",
        "mdlUpldoc_upload_doc" : "Upload Document",
        "mdlUpldoc_added_doc" : "Added Documents",
        //content - components - model-upload-media	
        "mdlUplMedia_alert_enter_title_media" : "Please Enter Title of The Media",
        "mdlUplMedia_alert_enter_title_url" : "Please Enter Title and URL",
        "mdlUplMedia_alert_enter_youtube_vimeo_url": "Please Enter valid and URL(Youtube/Vimeo)",
        "mdlUplMedia_upload_media" : "Upload Media",
        "mdlUplMedia_upload_url" : "Upload URL",
        "mdlUplMedia_Upload_media_system" : "Upload media form your system, it supports .jpg, .png, .jpeg, .mp3, .mp4",
        "mdlUplMedia_add_media_url" : "Add Media URL address to attach a Media",
        "mdlUplMedia_upload_media_url" : "Upload Media URL",
        "mdlUplMedia_upload_youtube_url" : "Note: Upload Youtube URL's",
        "mdlUplMedia_Add_media_file" : "Added Media Files",
        
        //content - managecontent - index	
        // "mngContent_content" : "Content",
        "mngContent_participants" : "Participants",
        "mngContent_grade_settings" : "Grade Settings",
        
        //content - index	
        "content_all_content_group" : "All Content Groups",
        "create_new_group" : "Create Group",
        "content_no_group_created" : "No Groups have been created yet!",
        "content_create_new_group" : "Create a new Group, to create click the above Create Group Button",

        //ContentShare - contentList
        "conList_all_group" : "All Groups",
        "conList_content_list" : "Content Lists",
        "conList_no_content_created" : "No Content has been created yet!",
        //contentShare - grouplist	
        "grpList_all_content_group" : "All Content Groups",
        "grpList_no_group_created" : "No Groups has been created yet!",

        //ContentShare - questionStepper	
        "queStepper_cant_skip" : "You can't skip a step that isn't optional.",
        "queStepper_back" : "Back",
        "queStepper_next" : "Next",
        "queStepper_skip" : "Skip",
        "queStepper_finish" : "Finish",
        "queStepper_submit_next" : "Submit & Next",
        //contentshare - showContent	
        "conList_content_list" : "Content List",
        "showCont_document" : "Documents",
        "showCont_previous" : "Previous",
        "showCont_next" : "Next",
        "showCont_have_completed_topic" : "I Have completed topics in this Content",
        //contentShare - showMedia
        "showMedia_page" : "Page",
        "showMedia_of" : "of",
        "showMedia_previous" : "Prev",

        //contentShare - showquestions	
        "showQues_min_left" : "min left",
        "showQues_completed_test" : "You have completed the test in this questionnaire.",
        "showQues_go_back_content_group" : "Go Back to Content Group",
        //contentShare - viewUserScore	
        "viewUserScore_view_score" : "View Score",
        "viewUserScore_total_marks" : "Total Marks:",
        "viewUserScore_scored_marks" : "Scored Marks:",
        "viewUserScore_GPA_score" : "Your GPA Score:",
        "viewUserScore_below_list_of_answer" : "Below are the list of answers for all the questionnaire of this Content Group.",
        "viewUserScore_answered" : "Answered",
        "viewUserScore_correct_answer" : "Correct Answer",

        //schedule - components	
        //ConfirmCancelInvite	
        "confCancle_confirmation" : "Confirmation",
        "confCancle_are_you_sure" : "Are you sure?",
        "confCancle_delete_user" : "Delete User",
        //confirmscheduledelete	
        "confSche_are_you_sure_delete" : "Are you sure you want to delete this schedule?",
        "confSche_no" : "No",
        //PastMeetingitem	
            // "pastMtnItem_rn" : "RN",
            // "pastMtnItem_room_name" : "Room Name: ",
            // "pastMtnItem_webinar_list" : "Webinar List",
            // "pastMtnItem_list_webinar" : "Below are the list of webinars.",
            // "pastMtnItem_today" : "Today",
            // "pastMtnItem_webinar_title_falls" : "Webinar Title Falls Here",
            // "pastMtnItem_view" : "View",
            // "pastMtnItem_upcoming" : "Upcoming",

        //SchedulesView	
        //"scheView_edit_content" : "Edit content",
        "scheView_scheduled_by" : "Scheduled By: ",
        "scheView_delete" : "Delete",
        "scheView_download_calander" : "Download Calendar",
        "scheView_invite_participants" : "Invite Participants",
        "scheView_start_conference" : "Start Conference",
        "scheView_event_details" : "Event Details",
        "scheView_room_name" : "Room Name",
        "scheView_minutes" : "Minutes",
        "scheView_conference_password" : "Conference Password",
        "scheView_settings" : "Settings",
        "scheView_webinar_event" : "Webinar Event",
        "scheView_list_webinar" : "List this webinar event in instavc.com/webinar, and accept registrations.",
        "scheView_guest_login_anonymous" : "Guest can log in as Anonymous",
        "scheView_allow_invities" : "Allow Only Invities to Join Conference",
        "scheView_enable_webinar_chat" : "Enable Webinar Chat",
        "scheView_enable_hand_rise" : "Enable Hand Raise in webinar",
        "scheView_list_of_people_invited" : "Below are the list of people invited",
        "scheView_alert_err_to_join" : "Error to Join",
        //WebinarDetails	
        "webnrDtls_upcoming_webinar_room" : "Below are the upcoming Webinar for this room.",
            // Webinar 1
            // Webinar 2
            // Webinar 3
        "webnrDtls_webinar_details" : "Webinar Details",
        "webnrDtls_webinar_list" : "Webinars List",
        "webnrDtls_webinar_title" : "Webinar Title",
        "webnrDtls_webinar_title_falls" : "Webinar Title falls here",
        "webnrDtls_get_more_joining" : "Get more by joining this webinar.",
        "webnrDtls_join_webinar" : "Join The Webinar",
        //webinarList	
        "webnrList_webinar_list" : "Webinar List",
        "webnrList_below_list_of_webinar" : "Below is the list of webinars.",
        "webnrList_today" : "Today",
        "webnrList_webinar_title_falls" : "Webinar Title Falls Here",
        "webnrList_view" : "View",
        "webnrList_upcoming" : "Upcoming",
            
        //schedule - 	
        //AddressBook	
        "addBook_address_book" : "Address Book",
        "addBook_contact" : "Contacts",
        "addBook_add_contact_to_meeting" : "Add contacts to the meeting, from your address book contacts",
        //MeetingList	
        "meetingList_past_meetings" : "Past Meetings",
        "meetingList_past_meetings_list" : "Past Meetings List",
        "meetingList_room_name" : "Room Name: ",
        "meetingList_view_content" : "View Content",
        "meetingList_recording" : "Recordings",
        "meetingList_view" : "View",
        //scheduleInvite	
        "scheInvt_err_invalid_id" : "Invalid Email Id",
        "scheInvt_invite_participants" : "Invite Participants",
        "scheInvt_address_book" : "Address Book",
        "scheInvt_invite_people_to_meeting" : "Invite people to this meeting. Add people from your address book, or by typing Email Address.",
        "scheInvt_start" : "Starts @ ",
        "scheInvt_below_list_of_answer" : "Below are the list of answers for all the questionnaire of this Content Group.",
        "scheInvt_host" : "Host:",
        "scheInvt_minutes" : "minutes",
            //Webinar 1
        "scheInvt_add" : "Add",
        "scheInvt_no_participants_added" : "No Participants added",
        "scheInvt_add_ppl_to_meetings" : "Add people to this meeting as participants from your Address Book, or add by the search.",
        "scheInvt_send_invitation" : "Send Invitation",
        "scheInvt_send_passcodes" : "Send Passcodes in separate mail.",

        //Invitation - component - invitationbody	
        "invitBody_invite_only_upto" : "You can invite only upto 10 People",
        "invitBody_invite_ppl_meeting_email_add" : "Invite people to this meeting, add people from your Address Book, or add by email address.",
        "invitBody_copy" : "Copy",
        "invitBody_enter_emailid" : "Enter Email ID",
        "invitBody_close" : "close",
        "invitBody_send_invitation" : "send invitation",

        //documentShare 	
        //index,js	
        "docShare_doc_sharing" : "Document Sharing",
        "docShare_upload_doc_system_pdf" : "Upload document from your system, it supports .pdf, excel, word document.",
        "docShare_upload_doc" : "Upload Document",
        "docShare_no_doc" : "No Documents",
        "docShare_no_doc_in_list" : "There are no documents added to the list yet.",
        "docShare_add_doc" : "Added Documents",
        "docShare_alert_title_doc" : "Please Enter Title of The Document",
        "docShare_alert_something_went_wrong" : "something went wrong please try again",
        //components - PermitDocShare	
        "permitDocShr_permit_doc_share_access" : "Permit Document Share Access",
        "permitDocShr_req_permission_doc_share" : "has requested permission to access document share.",
        "permitDocShr_deny" : "Deny",
        "permitDocShr_allow" : "Allow",
        //components - RequestDocShare	
        "reqDocShr_req_doc_share_access" : "Request Document Share Access",
        "reqDocShr_access_permission_host" : "In order to enable Document Sharing, you need access permission from the host.",
        "reqDocShr_plz_wait" : "Please wait for",
        "reqDocShr_permission_start_doc_share" : "(Host) permission to start document sharing.",
        "reqDocShr_doc_share" : "Document Share!",
        //components - ShowDocument View	
        "showDocViewback" : "Back",
        "showDocViewdoc_title_name" : "Document Title Name",
        "showDocViewnext" : "Next",
        "showDocViewprevious" : "Prev",

        //Broadcast - 	
        //boradcastScreenShare	
        "borcstScreen_alert_unsupported" : "Unsupported play order requested. Supported formats are webrtc and hls. Use something like playOrder=webrtc,hls",
        "borcstScreen_alert_no_stream_spec" : "No stream specified. Please add ?id={STREAM_ID}  to the url",
        "borcstScreen_Plz_enable_js" : "To view this video please enable JavaScript, and consider upgrading to a web browser that",
        "borcstScreen_support_HTML5" : "supports HTML5 video",
        "borcstScreen_steam_play_when_live" : "The video stream will play when it's live",
        //broadcast	
        "broadcast_alert_unsupported" : "Unsupported play order requested. Supported formats are webrtc and hls. Use something like playOrder=webrtc,hls",
        "broadcast_alert_no_stream_spec" : "No stream specified. Please add ?id={STREAM_ID}  to the url",
        "broadcast_enter_your_name" : "Please Enter Your Name",
        "broadcast_enter_your_email" : "Please Enter Your Email",
        "broadcast_user_details" : "User Details",
        "broadcast_submit_next" : "Submit",
        "broadcast_Plz_enable_js" : "To view this video please enable JavaScript, and consider upgrading to a web browser that ",
        "broadcast_support_HTML5" : "supports HTML5 video",
        "broadcast_steam_play_when_live" : "The video steam will play when it's live",
        //broadcastConference	
        "brdcstConf_whiteboard" : "Whiteboard",
        "brdcstConf_screen_share" : "Screen Share",
        // "brdcstConf_participants" : "participants",
        "brdcstConf_broadcast" : "Broadcast",
        "brdcstConf_right" : "right",
        //broadcast - component	
        //broadcasterParicipants	
        "brdcstPart_broadcast_list" : "Broadcaster List",

        //Containers - calender	
        //index	
        "calender_scheduled_calendar" : "Schedule Calendar",
        "calender_select_all" : "Select All",
        "calender_calender" : "Calendar",
        "calender_daily" : "Daily",
        "calender_weekly" : "Weekly",
        "calender_month" : "Month",
        "calender_today" : "Today",

        //Dashboard - componenet	

        //MeetingReminder	
        "meetReminder_scheduled_confirm" : "Schedule Confirmation",
        "meetReminder_meeting_has_been_scheduled" : "Following Meeting has been scheduled at this moment",
        "meetReminder_start_meeting" : "Start This Meeting",
        "meetReminder_join_meeting" : "Join This Meeting",
        "meetReminder_quick_start_another_meeting" : "Quick Start Another Meeting",
        //MyMeetingItem	
        "myMeetItem_start" : "Start",
        "myMeetItem_for" : "for",
        "myMeetItem_minutes" : "minutes",
        "myMeetItem_host" : "Host: ",
        //MyMeeting	
        "myMeet_my_meetings" : "My Meetings",
        "myMeet_search_my_meetings" : "Search my meetings",
        //NoScheduledMeeting	
        "noScheMeet_no_meeting_scheduled" : "No Meetings Scheduled",
        "noScheMeet_no_upcoming_meetings" : "You have no upcoming meetings scheduled yet. You can create a new schedule or quick start your conference now. ",
        "noScheMeet_create_schedule" : "Create Schedule",
        "noScheMeet_quick_start" : "Quick Start",
        "noScheMeet_or" : "OR",
        //RoomSettings	
        "roomSet_alert_select_license" : "Please Select the License",
        "roomSet_manage_licenses" : "Manage Licenses",
        "roomSet_package" : "Package",
        "roomSet_no_of_rooms" : "Number of Rooms",
        "roomSet_no_of_user" : "Number of Users",
        "roomSet_purchased_on" : "Purchased On",
        "roomSet_expired_on" : "Expired On",
        "roomSet_create_room" : "Create Room",


        //conference - component	
        //AddSipDevice	
        "addSipDev_add_sip_details" : "Add SIP Details",
        "addSipDev_sip_address" : "SIP Address:",
        "addSipDev_connect" : "Connect",
        "addSipDev_enter_sip_address" : "Enter SIP Address",
        //ConfNotification	
        "confNotf_notification" : "Notifications",
        "confNotf_user_name" : "User Name",
        "confNotf_want_to_join" : "wants to join this meeting room.",
        "confNotf_allow" : "Allow",
        "confNotf_deny" : "Deny",
        "confNotf_ask_question" : "asked a question.",
        "confNotf_send_msg_lobby" : "sent a message from the lobby.",
        //Loddy	
        "lobby_participants_loddy" : "Participants in the lobby",
        "lobby_pramote_all" : "Promote All",
        "lobby_user_name" : "User Name",
        //RemoveUserDialog	
        "removeUserDlg_manage_user_selecting" : "Manage this user by selecting any of these options:",
        "removeUserDlg_user_name" : "User Name",
        "removeUserDlg_block_participant" : "Block Participant",
        "removeUserDlg_send_to_lobby" : "Send to Lobby",
        "removeUserDlg_remote_participant" : "Remove Participant",
        "removeUserDlg_close" : "Close",
        //participantsList	
        "PartList_participants_in_lobby" : "Participants in the lobby",
        "PartList_alert_something_went_wrong" : "Something went wrong, Please try again",
        "PartList_in_webinar" : "In Webinar",
        "PartList_promote_all" : "Promote All",
        //participants2020	Retry
        //WaitingRoom	
        "waitingRoom_waiting_room" : "Waiting Room",
        "waitingRoom_plz_wait_host_allow" : "Please wait until the host allows you to join the conference.",
        "waitingRoom_chat" : "Chat",
        "waitingRoom_mins" : "Mins",
        "waitingRoom_no_msg_yet" : "No Messages, yet!",
        "waitingRoom_no_msg_from_host" : "No messages from the host.",
        //userRemoved	
        "userRemoved_you_removed_by_host" : "You have been removed from the Participants List by the host", 
        //invitationSlide	
        "invitSlide_invite" : "Invite",
        "invitSlide_add_email" : "Add emails",
        "invitSlide_add" : "Add",
        "invitSlide_broadcast_list" : "Broadcast Link",
        "invitSlide_invite_people_to_meeting" : "Invite people to this meeting. Add people by typing their email address and add to the list to send an invitation.",
        "invitSlide_err_invalid_id" : "Invalid Email Id",
        //participantsChat	
        "particiChat_no_chat_msg" : "No Chat Messages yet!",
        "particiChat_start_conversation" : "Start Conversation to see messages here.",

        
        //components - lobbyparticipants	
        "lobbyPartici_no_participants" : "No Participants",
        "lobbyPartici_no_participants_lobby" : "There are no participant in the lobby.",


        //conference	
        //simulcast	
        "simulcast_alert_decline_access" : "Host declined the access",
        "simulcast_invalid_url" : "Invalid URL",
        "simulcast_no_host_available" : "Looks no host available at this moment, Please become host to share the screen",
        //simulcastVAD & // Conference.js	
        "conference_access_to_participants" : "Access to Participants",
        "conference_screen_share" : "Screen Share",
        "conference_chat" : "Chat",
        "conference_lock_all" : "Lock All",
        "conference_lock_conf" : "Lock Conference",
        "conference_become_host" : "Become Host",
        "conference_you_are_host" : "You are Host",
        "conference_share" : "Share",
        "conference_document_share" : "Document Share",
        "conference_content_share" : "Content Share",
        "conference_more" : "More...",
        "conference_become_host" : "Become the Host",
        "conference_alert_no_host_aval_screen" : "Looks no host available at this moment, Please become host to share the screen",
        "conference_alert_no_host_aval_doc" : "Looks no host available at this moment, Please become host to share the document",
        "conference_alert_err_sip_device" : "Error while connecting sip device",
        "conference_alert_host_decline_access" : "Host declined the access",
        "conference_alert_invalid_url" : "Invalid URL",	
        "conference_unmute_mic_start_talk" : "Unmute your microphone to start talking",

        // tools	
        "confTools_new_whiteboard" : "Your New Whiteboard Comes here",
        "confTools_video_sync" : "Your Video Sync",
        "confTools_microphone" : "Microphone",
        "confTools_type_text_here" : "Type your text...",
        "whiteboard" : "Whiteboard",

        //meetingRecording	
        "meetRec_video_rec_meeting_name" : "Video Recordings of Meeting Name",
        "meetRec_page_view" : "Page View:",
        //meetingRecording- recordinglistview	
        "recListView_title_name_rec" : "Title Name of the Recording",
        "recListView_name_user" : "Name of user",




        // /////////////////////////////////////////////////////////////////////
        //Src	
        //component - common - BrowserUpgrade	
        "brwUpg_req_fun_not_avail" : "Required functionality not available in your browser",
        "brwUpg_meeting_req_fun_sup" : "This meeting service requires functionalist not supported by your browser.",
        "brwUpg_plz_upgrade" : "Please upgrade, switch to a different browser, or check your settings. Supported browsers:",
        //"    Samsung Internet
        //component - common - header - header.js	
        "header_content" : "Content (Beta)",
        "header_loading" : "Loading...",
        
        //containers/admin/plans/components/createPlan.js	
        "crtePln_plz_enter_name" : "Please Enter the Name of the Plan",
        "crtePln_plz_amount_Plan" : "Please Enter Amount of the Plan",
        "crtePln_plz_enter_des" : "Please Enter the Description",
        "crtePln_Plz_sel_product" : "Please select the Product",
        "crtePln_plz_sel_no_host" : "Please select the no. of hosts",
        "crtePln_plz_enter_no_participants" : "Please Enter no. of Participants",
        "crtePln_plz_enter_roomno" : "Please Enter no. of Rooms",
        "crtePln_select_period" : "Please select the period",
        "crtePln_sel_interval" : "Please select the interval",
        "crtePln_update_succ" : "Updated Successfully",
        "crtePln_err_creating_plan" : "Error in creating plan",

        //containers/broadcast/components/BroadcastHandRaise.js	
        "brdcRaise_alert_question_50_char" : "Your question should have minimum 50 characters",
        //containers/conference/components/components	
        "cofCom_no_part_lobby" : "There is no participant in the lobby.",
        //containers/conference/components/ConfirmUserDetails.js	
        "confmUser_plz_provide_name_email" : "Please provide your name and email",
        "confmUser_notes" : "Notes",
        //containers/conference/ConferenceSetup.js 	
        "confSetup_telepresence" : "Telepresence",
        "confSetup_alert_not_generate_meetingID" : "Not able to generate meeting id, try after some time",
        "confSetup_auth_part_can_join" : "Only Authorized Participants can join!.",
        "confSetup_invalid_link" : "Invalid link",
        "confSetup_meeting_notyet_started" : "This meeting is not yet started. Please join Later!. If you are a Host, please login to instaVC and start the meeting",
        "confSetup_meeting_locked_byhost" : "This meeting is Locked by Host",
        "confSetup_meeting_notyet_started_wait_host" : "This meeting is not yet started. Please wait for host to start the meeting!",
        //containers/conference/components/NewChat.js",
        "newChat_panel_chat" : "Panel Chat",
        //containers/conference/libs/InstaVC.js 	
        "instaVc_server_busy" : "Looks Server is Busy! Trying to connect another server...",
        //"    load-message",
        "instaVc_server_busy_reload_tojoin" : "Looks Server is Busy! Reload to Join Conference",
        "instaVc_broadcast_scr_failed" : "Broadcast Screen Failed",
        "instaVc_broadcast_failed" : "Broadcast Failed",
        "instaVc_waiting_for_host" : "Waiting for Host Video",
        //containers/content/components/contentQuestionnaire/modal-create-quest-group.js 	
        "modlCreate_plz_enter_title" : "Please enter the Title",
        "modlCreate_poll" : "Poll",
        //containers/content/components/contentQuestionnaire/modal-create-poll-question.js	
        "mdlCretPoll_plz_enter_quest" : "Please Enter the Question",
        "mdlCretPoll_option_should_not_same" : "Options should not be the same",
        "mdlCretPoll_plz_enter_option" : "Please Enter the Options",
        "mdlCretPoll_plz_select_type_option" : "Please Select the Type of the options",
        "alert_server_error" : "Internal Server Error, Please Try Again",
        "mdlCretPoll_update_ques" : "Update Question",
        "mdlCretPoll_create_ques" : "Create Question",
        "mdlCretPoll_enter_que_here" : "Enter your question here",
        "mdlCretPoll_end" : "end",
        //containers/content/components/contentQuestionnaire/viewQuestionnaire.js	
        "delete_successfully" : "Deleted Successfully",

        //containers/content/components/hozContentNavBlock.js / managecontent-index.js	
       // "mngContent_mngContent_content" : "Content",
        // "mngContent_mngContent_participants" : "Participants",
        "mngContent_mngContent_fav_prog_lang" : "What is you favourite  Programming Language?",
        "mngContent_mngContent_pollings" : "Pollings",
        

        //containers/contentShare/showQuestions.js 	
        "showQue_obtained_marks" : "Obtained Marks:",
        //containers/contentShare/viewUserScore.js	
        "viewUser_your_grade" : "Your Grade:",
        //containers/packages/Package.js	
        "package_one_host" : "1 Host and",
        //"package_participants" : "Participants",
           // SOHO
        //"    SMB 300
        "package_concurrentUser_room2" : "20 Concurrent Users – 2 Rooms",
        "package_concurrentUser_room5" : "30 Concurrent Users -5 Rooms",
        "package_concurrentUser_room20" : "100 Concurrent Users – 20 Rooms",
        "package_concurrentUser_room30" : "200 Concurrent Users - 30 Rooms",
        "package_concurrentUser_room50" : "500 Concurrent Users – 50 Rooms",

        //containers/schedule/components/PastMeetingItem.js	
        "pastMeeting_rn" : "RN",
        "pastMeeting_chat_history_deleted" : "chat history deleted successfully",
        "pastMeeting_past_meeting_dtls" : "Past Meeting Details",
        "pastMeeting_duration" : "Duration:",
        "pastMeeting_download_parti_atten" : "Download Participant & Attendees",
        "pastMeeting_download_webinar" : "Download Webinar",
        // "pastMeeting_participants" : "Participants",
        "pastMeeting_attendees" : "Attendees",
        "pastMeeting_chat_history" : "Chat History",
        "pastMeeting_recordings" : "Recordings",
        "pastMeeting_webinar_user" : "Webinar Users",
        "pastMeeting_webinar_chat" : "Webinar Chat",
        "pastMeeting_no_participants_in_meeting" : "No Participants in this meeting!",
        "pastMeeting_no_parti_attended_meeting" : "There were no participants who attended this meeting.",
        "pastMeeting_guest_list" : "Guest List",
        "pastMeeting_no_guest_in_meeting" : "No Guests in this meeting!",
        "pastMeeting_no_guests_were_invited " : "There were no guests who were invited or attended this meeting.",
        "pastMeeting_meeting_chat_history" : "Meeting Chat History",
        "pastMeeting_delete_all" : "Delete All",
        "pastMeeting_no_chat_msg_inmeeting" : "No Chat messages in this meeting!",
        "pastMeeting_no_conver_during_meeting" : "There were no conversations in chat during the meeting.",
        "pastMeeting_no_user_inwebinar" : "No users in this webinar!",
        "pastMeeting_no_user_attand_webinar" : "There were no users who attended this webinar.",
        "pastMeeting_webinar_chat_history" : "Webinar Chat History",
        "pastMeeting_no_chat_msg_inwebinar" : "No Chat messages in this webinar!",
        "pastMeeting_no_conver_during_webinar" : "There were no conversations in chat during the webinar.",
        "pastMeeting_del_chat_history" : "Delete Chat History",
        "pastMeeting_delete_all_chat_history" : "Are you sure, you want to delete all chat history permanently?",
        "pastMeeting_delete" : "Delete",
        //containers/schedule/components/Schedules.js	
        "schedul_webinar_description" : "Webinar Description",
        "schedul_upload_custom_banner" : "Upload a custom banner for your Webinar event.",
        "schedul_setting" : "Settings",
        //containers/schedule/components/SchedulesView.js	
        "schedulView_time" : "Time",
        "schedulView_edit" : "Edit",
        "schedulView_enable_training_mode" : "Enable Training Mode",
        "schedulView_audio_conference" : "Audio Conference",
        "schedulView_enable_mix_video" : "Enable Mix Video by Default",
        
        //class components
        //content - components - hozContentNavBlock	
        // "hozConNav_content" : "Content",
        //scheduleMeeting	
        "scheMeet_room_reserved_from" : "Room reserved from ",
        "scheMeet_meeting_name" : "with meeting name",
        //dashboard - component -Contact	
        "contact_alert_already_in_call" : "You cannot call other user, when are you already in call",
        "contact_alert_user_offline" : "User Offline",
        //dashboard - dashboard.js	
        "dashboard_alert_cannot_start" : "You cannot start a conference, when you are in another call",
        "dashboard_alert_cannot_join" : "You cannot join the conference, when you are in another call",
        "dashboard_quick_start_conf" : "Quick Start Conference",
        "dashboard_maintenance_msg" : "Please note that we will be experiencing server downtime on the 22nd April 2020 from 8.30 PM IST until 9.00 PM IST due to routine maintenance.",
        //coBrowsing	
        "coBrows_host_share-video" : "Please Wait! Host is about to share a video",
        //Additionalcamera	
        "addCam_add_device" : "Add Device",

        // genral same lang
        "answer_list" : "Answers List",
        "answer" : "Answers",
        "answered" : "Answered",
        "correct_answer" : "Correct Answer",
        "please_try_again" : "Please Try Again",
        "question" : "Question",
        "cancel" : "Cancel",
        "questionnaire" : "Questionnaire",
        "content" : "Content",
        "address_book" : "Address Book",
        "view" : "View",
        "rn" : "RN",

        // new langs
        "plz_enter_marks" :"Please Enter Marks of the Question",
        "plz_sel_crtans" : "Please Select the Correct Answer",
        "truefalse_choice" : "True / False Choice",
        "questionnarie" : "Questionnaire",
        "content_title" : "Content Title",
        "media_title" : "Media Title",
        "add_url_add" : "Add URL address...",
        "val_shuld_grtr_from_val" : "to value should be greater than from Value",
        "val_shuld_grtr_grade" : " from value should be greater than Grade ",
        "to_val" : "to value",
        "enable_hand_raise" : "Enable Hand Raise/Ask Question in a webinar",
        "enable_waiting_room" : "Enable Waiting Room",
        "allow_only_invite" : "Meeting password (Password Protected)",
        "guest_login_anonymous" : "Guest can login as Anonymous",
        "give_name_webinar" : "Give a name to your webinar",
        "Webinar" : "Webinar",
        "video_syn" : "Video Sync",
        "notepad" : "Notepad",
        "enable_handraise" : "Enable Hand Raise in a webinar",
        "pastMeeting_notes" : "Notes",
        //new one venkatesh
        "polls_list" : "Polls List",
        "no_poll_assign" : "No Poll has been assigned yet!",
        "poll_view" : "Poll View",

        // new Prudhvi
        "filter_block": "filter block",
        "file_size": "File Size",
        "select_room": "Please select a room",
        "provide_event_name": "Please provide a event name",
        "end_time_greater_start_time": "End Time must be greater than start time!",
        "invite_people_meeting": "Invite people to this meeting",
        "download_ical": "Download iCal",
        "download": "Download",
        "delete_meeting": "Delete this meeting",
        "room": "room",
        "new_event": "New Event",
        "end_repeat": "end repeat",
        "invite_users_timezone": "invite users with following timezone",
        "update_event": "Update Event",
        "create_event": "Create Event",
        "wrong_password": "Wrong Password",
        "college": "College",
        "university": "University",
        "country": "Country",
        "time_period": "Time Period:",
        "currently_working_here": "Currently Working Here",
        "from": "From:",
        "to": "To:",
        "graduated": "Graduated",
        "enter_description": "Enter your description here...",
        "concentration": "Concentration",
        "school": "School",
        "poll_question": "Poll Question",
        "create_poll_ques": "Create Poll Question",
        "options": "options",
        "no_ques_assigned_yet": "No Questions has been assigned yet!",
        "create_new_ques_click_create_ques": "Create a new Questions, to click the above Create Question Button",
        "enter_room_name": "Please Enter Room Name",
        "billed_annually": "* Billed Annually",
        "subscribe_now": "Subscribe Now",
        "group": "Group",
        "sample_webinar": "Sample Webinar",
        "webinar_affiliate": "Webinar About Affiliates",
        "webinar_one": "Webinar One",
        "workspace": "WorkSpace",
        "company": "Company",
        "add_another": "Add Another",

        //assignmnet
        "assignment" : "Assignment",
        "assignments" : "Assignments",
        "create_new_poll" : "Create a new poll question to start assigning.",
        "no_poll_assign" : "No Polls have been assigned yet.",
        "plz_title_url" : "Plaese Enter Title and Url",
        "votes" : "Votes",
        "enter_content_title" : "Please Enter the Title of Content",
        "failed_result" : "failed result",
        "removed_successfully" : "Removed successfully",
        "enter_duration" : "Please Enter the duration",
        "select_questionnarie" : "Please Select the questionnarie",
        "questionnaire_list" : "Questionnaire list",
        "no_assignments" : "No Assignments",
        "create_new_assignment" : "Create a new Assignment, to create click the above Create Assignment Button",
        "create_assignment" : "Create Assignment",
        "assignments_list" : "Assignments List",
        "assignment_description" : "Assignment Description",
        "assignment_name" : "Assignment Name",
        "update_assignment" : "Update Assignment",
        "add_new_assignment" : "Add New Assignment",
        "assignment_list" : "Assignment List",
        "assign_assignments" : "Assign Assignments",
        "assigned_assignments_list" : "Assigned Assignments List",
        "assignment_answer" : "Assignment Answer",
        "no_assig_submitted" : "No Assignments submitted yet.",
        "no_assig_submitted_participants" : "No Assignments submitted from any participants yet.",
        "view_assignment" : "View Assignment",
        "assigned_participants" : "Assigned Participants",
        "select_assignment" : "Select Assignment",
        "fill_your_assignment" : "Fill your Assignment",
        "submit_assignment" : "Submit Assignment",
        "assignment_details" : "Assignment Details",
        "assignment_submit_success" : "Assignment submitted successfully",
        "assignment_saved_success" : "Assignment saved successfully",
        "how_to_get_started" : "How to get started.",
        "create_new_room" : "Create New Room",
        "create_room_add_participants" : "Create a new room and add participants to the room.",
        "repeats_every" : "Repeats every",
        "now" : "Now",
        "Plz_enter_title_meeting" : "Please Enter Title of the Meeting",
        "no_start_time" : "No Start Time Found",
        "no_end_time" : "No End Time Found",
        "item_id_not_found" : "Item ID Not found",
        "msg_title_not_found" : "Message Title Not found",
        "plz_join_conf_clicking_link" : "Please join conference by clicking following link",
        "hi_there" : "Hi There",
        "room_permanently" : "Room Permanently?",
        "sure_want_to_delete_participants" : "Are you sure, you want to delete this participant?",
        "delete_participant" : "Delete Participant",
        "add_email" : "Add email",
        "add_participants_to_the_room" : "Add participants to this room by typing their email address in the below input box.",
        "no_of_users" : "No of users",
        "edit_the_name" : "You can edit the name, and the users number of this room.",
        "delete_room" : "Delete Room",
        "are_you_sure_delete" : "Are you sure, you want to delete",
        "delete_this_room" : "Delete This Room",
        "delete_room_will_parmanently" : "By clicking Delete, this room will be parmanently gets deleted.",
        "enable_storage" : "Enable Storage",
        "enable_recordings" : "Enable Recordings",
        "enable_SIP" : "Enable SIP",
        "addons" : "Addons",
        "expires_on" : "Expires On",
        "purchased_on" : "Purchased On",
        "more_storage" : "More Storage",
        "total_storage" : "Total Storage",
        "storage_occupied" : "Storage Occupied",
        "added" : "Added",
        "open_graphs" : "Open Graphs",
        "participant_controls" : "Participant Controls",
        "dashboard_controls " : "Dashboard Controls ",
        "start_meeting" : "Start a Meeting",
        "join_a_meeting" : "Join a Meeting",
        "F" : "Meeting Settings",
        "videos_to_get_started" : "Videos to get started",
        "if_you_are_host" : "If you are a host, click the below button to start the meeting",
        "guest_link" : "Guest Link",
        "pay_now" : "Pay Now",
        "promo_code_apply_here" : "Have a Promo Code? Apply Here",
        "per_month" : "per month",
        "billed_for" : "billed for",
        "gst_applicable" : "As per compliance with Government norms, additional 18% GST will be applicable for customers in India.",
        "select_country_code" : "Select Country Code",
        "country_code" : "Country Code",
        "enter_email_id" : "Enter Email Id ...",
        "downLoad_chat" : "DownLoad Chat",
        "allow_guest_control_their" : "Allow guest to control their video/audio",
        "below_list_people_invited" : "Below is the list of people invited",
        "verification_code" : "Verification code",
        "open_link_enter_verify_code" : "Open the below URL link, and enter your verification code for integration",
        "social_media_integration" : "Social Media Integration",
        "integrate_social_media_platform" : "You can integrate social media platforms, by clicking a button, you will get verification code, use it for integration.",
        "plz_enter_val_email" : "Please Enter Valid Email",
        "record" : "Record",
        "in_conference" : "In Conference",
        "password_if_any" : "Password (if any)",
        "stop_send" : "Stop &amp; Send",
        "broadcast_not_enabled_meeting" : "Broadcast not enabled for this meeting",
        "facebook_url" : "Facebook URL",
        "enter_facebook_url" : "Enter Facebook RTMB URL",
        "add_facebook_url" : "Add Facebook RTMP URL",
        "youtube_url" : "Youtube URL",
        "enter_youtube_url" : "Enter Youtube RTMB URL",
        "add_youtube_url" : "Add Youtube RTMP URL",
        
        "register" : "Register",
        "time_of_program" : "Time of Program",
        "date_of_program" : "Date of the Program",
        "name_program_interested" : "Name of Program Interested",
        "email_id" : "Email Id",
        "dob" : "Date of Birth",
        "applicant_address" : "Applicant's Address",
        "qualification" : "Qualification",
        "designation" : "Designation",
        "org_name_address" : "Organization Name & Address",
        "fullname_participant" : "Full Name of Participant",
        "registration_form" : "Registration Form",
        "thanks_submitting_data" : "Thanks for submitting your data",
        "manage_content" : "Manage Content",
        "API_settings" : "API Settings",
        "schedule_API" : "Schedule API",
        "image_upload_success" : "image uploaded successfully",
        "less_than_10MB" : "please upload file less than 10MB",
        "at_time_of_event":"At the time of event",
        "min_5" : "5 minutes before",
        "min_10" : "10 minutes before",
        "min_15" : "15 minutes before",
        "min_30" : "30 minutes before",
        "hrs_1" : "1 hour before",
        "hrs_2" : "2 hour before",
        "day_1" : "1 day before",
        "day_2" : "2 days before",
        "jan" : "Jan",
        "feb" : "Feb",
        "mar" : "Mar",
        "apr" : "Apr",
        "may" : "May",
        "jun" : "Jun",
        "jul" : "Jul",
        "aug" : "Aug",
        "sep" : "Sep",
        "oct" : "Oct",
        "nov" : "Nov",
        "dec" : "Dec",
        "mon" : "Mon",
        "tue" : "Tue",
        "wed" : "Wed",
        "thu" : "Thu",
        "fri" : "Fri",
        "sat" : "Sat",
        "sun" : "Sun",
        "all_day" : "all-day",
        "starts_ddmmyyyy" : "starts (dd/mm/yyyy)",
        "ends_ddmmyyyy" : "ends (dd/mm/yyyy)",
        "ddmmyyyy" : "(dd/mm/yyyy)",
        "repeat" : "repeat",
        "by_dayweek" : "By Day/Week",
        "never" : "Never",
        "on_date" : "On Date",
        "alert" : "alert",

        "multi_factor_auth" : "Enable Multi Factor Authentication (MFA)",
        "advanced_settings" : "Advanced Settings",
        "verify_Otp" : "Verify Otp",
        "resend_Otp" : "Resend Otp",
        "otp_sent_mobile" : "Enter the OTP sent to your mobile number",
        "update_mobile" : "Update Mobile",
        "verify_mobile" : "Verify Mobile",
        "mob_verfi_pending" : "Your mobile verification is pending.",
        "mobile_number" : "Mobile Number",
        "enter_Otp" : "Enter Otp",
        "enter_mob_no" : "please enter Mobile Number",
        "sure_want_to_delete_rec" : "Are You Sure Want T0 Delete Recording",
        "delete_recording" : "Delete Recording",
        "invalid_credentials" : "Invalid Credentials",
        "join_conf_by_clicking_link" : "Please join the conference by clicking the following link",
        "hi_there" : "Hi There,",
        "valid_room_name" : "Please Enter Valid  Room Name",
        "internal_server_error" : "Internal server error, Please try again",
        "room_limit_exceed" : "Room Limit exceeded",
        "cant_start_meeting_already_goingon" : "You cannot start a meeting, since there is a meeting already going on.",
        
        //new
        "social_integration" : "Social Integration",
        "advanced" : "Advanced",
        "add_youtube" : "Add Youtube",
        "plugins" : "Plugins",
        "subtitles" : "Subtitles",
        "view_local" : "View Local",
        "hide_local" : "Hide Local",
        "switch_conf_mode" : "Switch to conference mode",
        "switch_pres_mode" : "Switch to presenter mode",
        "conference_locked" : "Conference Locked",
        "conference_unlocked" : "Conference Unlocked",
        "sharing_tools" : "Sharing Tools",
        "record" : "Record",
        "recording" : "Recording",
        "plz_wait_host_shr_video" : "Please Wait! The host is about to share a video",
        "supported_sources_include" : "Supported sources include",
        "team_plugins" : "Team Plugins",
        "open" : "Open",
        "click" : "Click",
        "more_app" : "More Apps",
        "upload_custom_app" : "Upload Custom App",
        "download_team_plugin" : "Download Team Plugin",
        "install_mng_app_microsoft_team" : "How to install and manage app in Microsoft Teams",
        "microsoft_step1" : "Step 1: Open Microsoft Teams",
        "microsoft_step1_para1" : "To get started, first you need to download the team plugin to your system available on the top of this page. Then open your Microsoft Teams and choose ",
        "microsoft_step1_para2" : " options from the side navigation to view the team apps.",
        "microsoft_step2" : "Step 2: Go to More Apps",
        "microsoft_step2_para1" : "Here you can find a few apps along with a button named More Apps. Click on ",
        "microsoft_step2_para2" : "to further check for custom upload option and other store options.",
        "microsoft_step3" : "Step 3: Upload App",
        "microsoft_step3_para1" : "Once you open the store, you can find an ",
        "microsoft_step3_para2" : " option in the left navigation. Click on it and upload your downloaded plugin app here. ",
        "microsoft_step4" : "Step 4: Select InstaVC app from storage",
        "microsoft_step4_para1" : "When the window prompts, select the InstaVC plugin app from your system that was downloaded in (Step 1) and click ",
        "microsoft_step5" : "Step 5: Uploaded App in Microsoft Teams",
        "microsoft_step5_para1" : "After uploading the file, it gets uploaded in the Microsoft team Apps List. You can then click the App to view and add it to teams. ",
        "microsoft_step6" : "Step 6: Open InstaVC App",
        "microsoft_step6_para1" : "Once you click on the InstaVC app, you can now view the complete details of the plugin app along with a button to ",
        "microsoft_step6_para2" : "button to add this app to Microsoft Teams.",
        "microsoft_step7" : "Step 7: InstaVC Added to Microsoft Teams",
        "microsoft_step7_para1" : "Now your App will be added to Microsoft teams on the left side navigation for easy and customizable usage.",

        "ddyymm" : "DD/MM/YYYY",
        "fill_details_for_webinar" : "Please fill your registration details for Webinar at",
        "microsoft_teams" : "Microsoft Teams",
        "microsoft_outlook" : "Microsoft Outlook",
        "select_the_plan" : "Please select the plan",
        "enter_company_code" : "Please enter the Company Code",
        "enter_code" : "Please enter the Code",
        "enter_valid_coupon_code" : "Please enter the valid coupon code",
        "coupon_alreadt_taken" : "This coupon code already taken",
        "error_updating_plan" : "Error in updating plan",
        "unit" : "unit",
        "referrals" : "Referrals",
        "sel_coupon" : "Please Select the Coupon",
        "share_val_0_99" : "Share Value should be in between 0 to 99",
        "enter_share_val" : "Please Enter the Share Value",
        "valid_commission_per" : "Please Enter Valid Commission Percentage",
        "enter_commission_per" : "Please Enter the Commission Percentage",
        "enter_commission_name" : "Please Enter the Commission Name",
        "sync_successfully" : "Users Sync Successfully",
        "room_deleted_suc" : "Room Deleted Successfully",
        "lic_deleted_suc" : "License Deleted Successfully",
        "female" : "Female",
        "male" : "Male",
        "insta_conference" : "Insta Conference",
        "quick_meeting_by" : "Quick Meeting By",
        "in_lobby" : "In Lobby",
        "switch_to_broadcast" : "Switch to Broadcast",
        "Broadcasters_5" : "Broadcasters (5)",
        "Participants_5" : "Participants (5)",
        "search_participants" : "Search participants",
        "host_controls" : "Host Controls",
        "deleteroom" : "delete room",
        "pm" : "PM",
        "am" : "AM",
        "email_exist_inroom" : "Email Already Existed in this Room",
        "type_a_message" : "Type a message...",
        "please_enter_user_limit" : "Please Enter User Limit",
        "user_limit_exceeded" : "User Limit is exceeded",
        "sel_country_code" : "Please select country code",
        "available_storage" : "Available Storage",
        "enter_phone_number" : "Enter Phone Number",
        "startdate_less_enddate" : "please select start date less than end date",
        "havent_add_data" : "You haven't added any data yet, Please add data.",
        "professional_skills" : "Professional Skills",
        "enter_prof_skills" : "Enter Professional skills",
        "Present_Invitations" : "Present Invitations",
        "sending" : "Sending...",
        "sent" : "Sent",
        "invite_10ppl_only" : "You can invite only up to 10 People",
        "join_meeting" : "Join Meeting",
        "enter_doc_title" : "Enter Document Title",
        "meeting_name" : "Meeting Name",
        "select_the_weeks" : "Please Select the weeks",
        "grade" : "Grade",
        "coference_ID" : "Coference ID...",
        "dont_find_room_meetingid" : "We dont find any room, which is associated with this meeting id",
        "wireless_share" : "Wireless Share",
        "video_layout" : "Video Layout",
        "speaker_screen" : "Speaker Screen",
        "presentation_screen" : "Presentation Screen",
        "multiple_display" : "Multiple Display",
        "hellow" : "hellow",
        "enter_passcode" : "Enter the passcode",
        "another_meeting_stillon" : "Another meeting is still on, you cannot start this meeting right now",
        "SIP_device" : "SIP Device",
        "instavc_whiteBoard" : "InstaVC WhiteBoard",
        "speaker" : "Speaker",
        "instavc_notepad" : "InstaVC Notepad",
        "promote_in" : "Promote In",
        "guest" : "Guest",
        "breakout_rooms" : "Breakout Rooms",
        "stop_broadcast" : "Stop Broadcast",
        "noise_suppress" : "Noise Suppression",
        "echo_cancel" : "Echo Cancellation:",
        "enter_name_here" : "Enter Name Here",
        "Viewer" : "Viewer",
        "SIP_user" : "SIP User",
        "remove_participants" : "Remove Participants",
        "disable_audio_video" : "Disable Remote Audio & Video",
        "disable_audio" : "Disable Audio",
        "enable_audio" : "Enable Audio",
        "control" : "Controller",
        "disable_video" : "Disable Video",
        "enable_video" : "Enable Video",
        "audio_video" : "Audio & Video",
        "audio_only" : "Audio Only",
        "video_only" : "Video Only",
        "outlook_addins" : "Outlook Add-ins",
        "install_microsoft" : "How to install and manage add-ins in Microsoft Outlook",
        "outlook_step1" : "Step 1: Copy Link & open Outlook",
        "first_copy_link" : "First copy the link",
        "add_plugin_oulook" : "from here to add the plugin to your outlook.",
        "outlook_step2" : "Step 2: Get Add-ins",
        "outlook_step2_para" : "Now open outlook and under Home tab you can find an option Get Add-ins. Click on it to view add-ins and other custom options. ",
        "outlook_step3" : "Step 3: My Add-ins",
        "outlook_step3_para" : "You can now view the add-ins page with left navigation. Go to My add-ins to manage your add-ins.",
        "outlook_step4" : "Step 4: Add Custom Add-ins",
        "outlook_step4_para" : "Here you can find a dropdown Add Custom Add-in under custom add-ins in My add-ins page. Select Add from URL from the drop down.",
        "outlook_step5" : "Step 5: Add Private Add-in from URL",
        "outlook_step5_para" : "You can now see a pop up asking to Add private Add-in from URL. Now paste the link you copied in (Step 1) in the input field and click OK, that installs the add-in to outlook. ",
        "outlook_step6" : "Step 6: InstaVC Added in My Add-ins",
        "outlook_step6_para" : "After adding, you can check the add in added to your My Add-ins List. From here you can manage your add-ins further.",
        "outlook_step7" : "Step 7: Close Outlook and reopen",
        "outlook_step7_para" : "After installing, make sure you close the complete outlook once and re open again to see the add-in.",
        "outlook_step8" : "Step 8: Go to New Items",
        "outlook_step8_para" : "After opening outlook, You can now go to New Meetings &#62; Meeting to see the InstaVC add in added to outlook.",
        "outlook_step9" : "Step 9: Open InstaVC Meeting",
        "outlook_step9_para" : "Once you open meetings, you will be opened with a compose mail window along with InstaVC Meeting add-in enabled in the top bar under meetings tab.",
        "outlook_step10" : "Step 10: Create Schedule",
        "outlook_step10_para" : "From the compose mail layout, click on Instavc meetings to create a new schedule. you will be opened with InstaVC Meeting Login on the right side if it's a first time. For the users who already logged in , they will be directed to rooms list and can create a schedule.",
        "outlook_step11" : "Step 11: Open InstaVC Meeting",
        "outlook_step11_para" : "You can create a schedule on the left side and select a room on the right side to schedule meeting in a particular room. Once you create a schedule, you can see the Start Meeting button now. From here you can click and start the meeting.",
        "outlook_step12" : "Step 12: View Schedule in Calendar",
        "outlook_step12_para" : "Once you have created schedule, the next time when you open calendar, you can view your schedules in the calendar as well.",
        "outlook_step13" : "Step 13: Schedule Created",
        "outlook_step13_para" : "Once you create a schedule, you can then see the composed mail along with a Start Meeting button on the right side.",
        "sel_declaration" : "Please Select the Declaration",
        "enter_company_code" : "Please Enter the Company Code",
        "invalid_company_code" : "Invalid Company Code",
        "affiliate_acc" : "Your affiliate account is pending approval.",
        "aff_acc_approved" : "Your affiliate account is approved.",
        "aff_acc_rej" : "Your affiliate account is rejected.",
        "aff_TC" : "Affiliate Terms and Conditions",
        "ref_par_agreement" : "REFERRAL PARTNER AGREEMENT",
        "aff_partner_agreement" : 'This Partner Agreement, consisting of this Cover Page and the attached Business Terms (collectively, this "Agreement"), is made and entered into by and between PeopleLink Unified Communications Pvt Ltd (referred to as PeopleLink) and “Partner” or “You”. "Partner" or "You" means the person or company (including Company Name and other information) detailed in the Form submitted to. The Agreement shall become binding once You complete and submit the Referral Partner Program Form ("Form") to indicate Your acceptance of this Agreement. PeopleLink and Partner are sometimes referred to as a "Party", and together as the "Parties."',
        "background" : "Background",
        "aff_market_advertise" : 'PeopleLink offers InstaVC Unified communication services and solutions. Partner wishes to promote, market and advertise PeopleLink products and solutions to potential PeopleLink customers ("Referrals") through its website(s) and other marketing channels, in accordance with this Agreement.', 
        "agreement" : "Agreement",
        "aff_parties_agree" : "The Parties agree as follows:",
        "heading1" : "PROGRAM COMMITMENTS",
        "heading2" : "QUALIFIED REFERRALS, COMMISSIONS.",
        "heading3" : "TERM AND TERMINATION.",
        "heading4" : "GENERAL",
        "the_program" : "The Program. ",
        "to_participate_program_partner" : 'To participate in the Program Partner must complete the online application for participation in the Program found on PeopleLink or its associated websites ("Site") and enter this Agreement. PeopleLink may accept or reject any application at its sole discretion.',
        "aff_str11" : "Legal Agreements.",
        "aff_str12" : "Promotion, Referral Activities:",
        "aff_str13" : "Prohibited Activities.",
        "aff_str14" : "Permissible Use of PeopleLink Marks",
        "aff_str15" : "Liabilities.",
        "aff_str16" : "Customer Relations.",
        "aff_str11_para" : "As part of its participation in the Program and in acting as PeopleLink’s Partner, Partner hereby agrees and consents to the terms of this Agreement and the Program, the Guidelines, and any other requests and rules set by PeopleLink from time to time, in its reasonable discretion, in connection with Partner's ongoing participation in the Program and promotion of PeopleLink products, Solutions and Service to Referrals. In all its activities under this Agreement, and specific such activities relating to the Partner's promotion of PeopleLink, Partner shall cooperate with PeopleLink and act in good faith. By entering this Agreement Partner further recognizes and accepts the terms and rules set in PeopleLink’s Terms of Service",
        "aff_str12_para" : "Partner agrees to engage in continued, active promotion of the PeopleLink Products and Solutions in various marketing channels using the Licensed Marks and Marketing Materials, and do so in compliance with the terms of this Agreement.",
        "aff_str13_para" : 'Partner agrees not to associate Marketing Materials with content that is unlawful in any manner, or which is otherwise harmful, threatening, defamatory, obscene, offensive, harassing, sexually explicit, violent, discriminatory, or otherwise objectionable in PeopleLink’s sole discretion. Partner agrees not to send unsolicited electronic messages to multiple unrelated recipients ("Spamming") in promoting the PeopleLink services and solutions, or otherwise to engage in any other form of mass electronic communications prohibited by law in connection with activities contemplated under this Agreement.',
        "aff_str14_para1" : "Partner expressly agrees to comply with all the terms herein in using the Licensed Marks and in creating Marketing Materials.",
        "aff_str14_para2" : "Through the Guidelines and otherwise, PeopleLink shall provide specifications and other instructions from time to time as to Partner's permissible use of the Licensed Marks in creating Marketing Materials and promoting the PeopleLink Service. Partner further agrees to comply with all such specifications and instructions.",
        "aff_str14_para3" : "Partner shall ensure that all Licensed Marks appearing on its Marketing Materials are in the form approved by PeopleLink in the Guidelines or otherwise, shall not modify any PeopleLink Marks or otherwise substantially modify other Marketing Materials contrary to reasonable instructions provided by PeopleLink, and shall further comply with reasonable instructions from PeopleLink as to the form, content and display of Marketing Materials. Upon termination of this Agreement for any reason whatsoever, or upon written request by PeopleLink, the license granted herein shall expire and Partner shall immediately cease all its activities under this Agreement.",
        "aff_str15_para" : "Partner shall be solely responsible for its operations in acting under this Agreement, including, without limitation, the legality of Partner's operations and materials, created and used in connection with this Agreement. Except for a claim alleging that a PeopleLink Mark violates a third party's trademark rights, PeopleLink is not responsible for the development, operation or content of Partner's Marketing Materials and Partner agrees to defend, indemnify and hold PeopleLink harmless against any claims, actions, causes of action, damages, or expenses (including attorney fees) relating to the development, operation, content and maintenance of Partner's Marketing Materials.",
        "aff_str16_para" : "During and after the Term, PeopleLink shall be the exclusive owner of all relations created via Partner among PeopleLink and Referrals with respect to the PeopleLink Service, including any and all information identifying Referrals who contract with PeopleLink for the use of the PeopleLink Service. The Terms, Privacy Policy, and PeopleLink's rules and procedures for the PeopleLink Service will apply to these Referrals and may be changed by PeopleLink without prior notice to Partner, and Partner agrees to convey to Referrals the nature of their relations with PeopleLink under the Terms.",
        "aff_str20_para1" : "referred by Partner to PeopleLink and who complete the sign-up procedure in accordance with the procedure described in Section 3(b) below; ",
        "aff_str20_para2" : "of whom PeopleLink has no record in connection with the PeopleLink Service, or who are not, at the time referred to PeopleLink by Partner, in any contractual relations or ongoing negotiations with PeopleLink in connection with the PeopleLink Service;",
        "aff_str20_para3" : "who accept the Terms and acquire, at a Referral's own discretion and without receiving any monetary or other incentive from Partner, at least annual subscriptions of any of the following PeopleLink Service plan bundles listed on PeopleLink websites or otherwise (iv) who are not rejected by PeopleLink, and make at least one payment (annual prepay) or three payments (annual term, monthly billing) to receive the PeopleLink Service.",
        "aff_str21" : "Referral Procedure.",
        "aff_str22" : "Commissions. Responsibilities.",
        "aff_str221" : "Referral Fees.",
        "aff_str222" : "Ongoing Sales From A Referral",
        "aff_str223" : "Associated charges.",
        "aff_str23" : "Sales/Commissions Reports.",
        "aff_str21_para" : "Each Referral shall be referred to PeopleLink by Partner through an online url provided by PeopleLink to Partner, PeopleLink shall be responsible for the sales process to all Referrals, subject to the Parties' continued good-faith cooperation in promoting the sales process to Referral.",
        "aff_str22_para" : "PeopleLink shall collect all fees from Referrals for the PeopleLink Service directly from Referrals.",
        "aff_str22_para1" : "Upon a Referral becoming a Qualified Referral, PeopleLink shall pay Partner referral fees in arrears based on the listed fees located at ",
        "aff_str22_para12" : "(excluding any discounts) payable by the Qualified Referral. Such Referral Fees shall become payable and be paid to Partner within approximately (<add) ninety (90) days of the Paid service start date granted payment obligations are achieved.",
        "aff_str22_para2" : "- Partner shall receive referral fees for additional sales from a Qualified Referral that take place within first 90 days of the date the referral was initially submitted.",
        "aff_str22_para3" : " Partner shall be responsible for payment of all taxes, duties, governmental charges and other like charges levied on the Referral Fees, and Partner shall indemnify, defend and hold PeopleLink harmless from and against any claims arising out or relating to all charges emanating from PeopleLink's payment of Referral Fees.",
        "aff_str23_para" : "PeopleLink shall provide Partner in their online dashboard report summarizing the sales activities of Partner and its commissions for Qualified Referrals derived from Referrals. PeopleLink shall not reveal the names or other personal information about Referrals and Qualified Referrals.",
        "aff_str31" : "Initial Term.",
        "aff_str32" : "Renewal Term.",
        "aff_str33" : "Early Termination.Without Cause.",
        "aff_str34" : "For Cause.",
        "aff_str35" : "Effect of Termination.",
        "aff_str31_para" : 'This Agreement shall become effective as of the Effective Date and shall continue for twelve (12) months thereafter ("Initial Term"), unless PeopleLink rejects Partners application to participate in the Program',
        "aff_str32_para" : 'Following the expiration of the Initial Term, this Agreement will be automatically renewed for additional consecutive terms of twelve (12) months (each, "Renewal Term"), unless a Party gives written notice of termination to the other Party at least thirty (30) days prior to the end of the Initial Term or any Renewal Term.',
        "aff_str33_para" : "PeopleLink shall have the right to terminate this Agreement at any time for any or no reason by giving five (5) days prior written notice to Partner.",
        "aff_str34_para" : "Either Party may terminate this Agreement at any time, effective immediately upon written notice to the other Party who has materially breached this Agreement, provided that prior to terminating this Agreement the terminating Party shall provide written notice of such material breach and thirty (30) days opportunity for the breaching Party to cure such breach.",
        "aff_str35_para" : "From and following the date of termination of this Agreement Partner's rights under this Agreement shall terminate, and Partner shall not be entitled to receive any Referral Fees or any other payments under this Agreement other than commissions or payments earned or accrued prior to termination of this Agreement.",
        "aff_str41" : "Assignment.",
        "aff_str42" : "Intellectual Property Rights.",
        "aff_str43" : "No Waiver.",
        "aff_str44" : "Limited Warranty.",
        "aff_str45" : "Disclaimer of Warranty.",
        "aff_str46" : "Limitation of Liability.",
        "aff_str47" : "Independent Contractors.",
        "aff_str48" : "Indemnification.",
        "aff_str49" : "Force Majeure.",
        "aff_str410" : "Entire Agreement; Severability.",
        "aff_str411" : "Counterparts; Notices.",
        "aff_str412" : "Governing Law; Jurisdiction; Dispute Resolution.",
        "aff_str41_para" : "PeopleLink may assign this Agreement at any time. Partner may not assign or transfer this Agreement without PeopleLink's prior written consent, such consent not to be unreasonably withheld.",
        "aff_str42_para" : "All intellectual property rights (such as but not limited to trademarks, trade names, logos, patents, copyrights, domain names and derivative rights) in PeopleLink Marks, the PeopleLink Service and related content and technology around the world (PeopleLink IP Rights) are and will remain the exclusive property of PeopleLink and its subsidiary companies. Partner's right to use the Licensed Marks is at the discretion of PeopleLink and is subject to Partner's compliance with the terms of this Agreement, Guidelines, and with all applicable laws and regulations. Partner agrees to (a) not use any PeopleLink IP Rights in any manner reasonably likely to breach this Agreement; (b) not do anything contesting or impairing any PeopleLink IP Rights; (c) not create or obtain any intellectual property rights (such as but not limited to trademarks, trade names, logos, patents, copyrights, domain names and derivative rights) that are substantially similar to any PeopleLink IP Rights; (d) promptly notify PeopleLink of any unauthorized use of any PeopleLink IP Rights of which Partner has actual knowledge; and (e) always use the Licensed Marks and any other PeopleLink Marks in compliance with the Guidelines. PeopleLink may perform periodic reviews of any Marketing Materials presented by Partner, and shall have the exclusive authority and discretion to order the removal and/or amendment of any Marketing Materials presented by Partner.",
        "aff_str43_para" : "Either Party's failure to enforce the other Party's strict performance of any provision of this Agreement will not constitute a waiver of the first Party's right to subsequently enforce such provision or any other provision of this Agreement.",
        "aff_str44_para" : "Both Parties warrant that at all times during the Term they will comply with all applicable laws, regulations, codes of practice, as well as this Agreement, the Terms, Privacy Policy and Guidelines. During the Term and after its termination for any reason whatsoever, Partner expressly undertakes not to do anything that might reasonably be expected to damage the business, interests or reputation of PeopleLink and will not make, publish or allow to be made or published any disparaging remarks concerning PeopleLink, its representatives, or the PeopleLink Service.",
        "aff_str45_para" : "Other than PeopleLink's express warranty under the previous subsection (e), PeopleLink makes no other warranty, express or implied, of any kind and PeopleLink expressly disclaims any warranties and conditions, including but not limited to any implied warranty of merchantability, fitness for a particular purpose, availability, security, title, and/or non-infringement of the subject matter of this Agreement.",
        "aff_str46_para" : "Neither PeopleLink nor any officer, employee, director or any other representative of PeopleLink shall be liable towards Partner or towards any third party, under or in connection with this Agreement or its termination, in contract, pre-contract, tort or otherwise for (i) any economic loss (including loss of revenues, profits, contracts, business or anticipated savings) or (ii) any loss of goodwill or reputation. Such losses include, without limitation, any special, indirect, incidental, statutory, punitive or consequential losses or damages as well as any losses or damages caused by interruption of operations. Notwithstanding any other circumstances or understandings surroundings any relations among the Parties, PeopleLink's entire liability to Partner under this Agreement shall not exceed INR (Indian Rupees One thousand only) for any and all claims for damages of any kind made by Partner under this Agreement, and by entering this Agreement Partner recognizes the limitations herein on PeopleLink's liability.",
        "aff_str47_para" : "The Parties herein act on their own behalf as independent contractor. Nothing in this Agreement shall create any joint venture, agency, franchise, sales representative, employment or any other relationship between the Parties beyond the relations set out in this Agreement, and Partner is expressly precluded from acting on PeopleLink's behalf. Partner's display of Licensed Marks under this Agreement, other content presented by Partner, or contact among Partner and third parties shall not misrepresent the relations described herein.",
        "aff_str48_para" : 'Partner will indemnify, defend and hold PeopleLink and its subsidiaries, affiliates, officers and employees (the "PeopleLink Indemnified Parties") harmless from and against any costs, liabilities, losses and expenses (including but not limited to reasonable attorneys fees) resulting from any claim, suit, action, demand or proceeding brought by any third party against the PeopleLink Indemnified Parties arising from any of the following: (i) a breach of the Agreement by Partner; (ii) the negligence, gross negligence or willful misconduct of Partner or its employees, agents or contractors; or (iii) a failure by Partner or its employees, agents, contractors or invitees to comply with the laws and regulations referenced hereinbefore.',
        "aff_str49_para" : 'A Party shall not be obliged to perform any of its obligations herein if it is prevented from doing so by a situation of force majeure. "Force majeure" events shall include events beyond the reasonable control of the Parties, including acts of God, acts of government, acts of nature, strikes or riots, as well as improper performance by PeopleLinks suppliers or defects in objects, materials or software of third parties. If a situation of force majeure lasts for more than thirty (30) days, either Party may terminate this agreement upon written notice to the other Party.',
        "aff_str410_para" : "This Agreement represents the entire agreement among the Parties regarding the subject matter thereof and the Parties' respective obligations and commitments herein. No other documents, or oral or written agreements among the Parties reflect in any way on the agreements laid out in this Agreement, and this Agreement may not be modified except in a writing executed by both parties. . Whenever possible, each provision of this Agreement shall be interpreted in such a manner as to be effective and valid under applicable law, but if any provision of this Agreement shall be prohibited by or invalid under applicable law, such provision shall be ineffective only to the extent of such prohibition or invalidity, without invalidating the remainder of such provision or the remaining provisions of this Agreement.",
        "aff_str411_para" : "This Agreement may be signed in counterparts and such counterparts shall be valid and binding on the parties hereto with the same effect as if original signatures had been exchanged.",
        "aff_str412_para" : "This Agreement shall be governed by the laws of the jurisdiction of Hyderabad, Telangana, India, without giving effect to any principles of conflicts of law. Jurisdiction shall lie exclusively in the District Courts of Hyderabad, Telangana, India. The sole and exclusive jurisdiction and venue for any litigation arising out of this Agreement shall be an appropriate district or state court located at Hyderabad, Telangana, India, and the Parties agree not to raise, and hereby waive, any objections or defenses based upon venue or forum non convenience. Prior to initiating any legal action arising under or relating to this Agreement, a Party shall provide the other Party written notice of a dispute and the Parties shall actively and in good faith negotiate with a view to speedy resolution of such dispute within thirty (30) business days of the receipt of such notice.",
        "aff_str40" : "Modification of Agreement.",
        "aff_str40_para" : "PeopleLink may modify this Agreement from time-to-time at its reasonable discretion by posting a change on the Site (at www.peoplelinkvc.com/affiliatestermsofservice) or notifying Partner via email. If Partner objects to any such change, Partner may terminate this Agreement for cause. Partner's continued participation in the Program following receipt of notice about changes to this Agreement shall constitute binding acceptance of this Agreement as amended.",
        "qualified_ref" : "Qualified Referrals",
        "mean_referrals" : "mean Referrals",
        "apply" :"Apply",
        "company_code_validate" : "Please enter the company code to validate",
        "golive_company_code" : "Golive Company Code",
        "accept_TC" : "I accept the above terms and conditions",
        "by_sub_referral" : "By submitting the Referral Partner Application, Partner represents and warrants that it has read, understands and accepts this Agreement.",
        "term" : "Terms",
        "found_at" : "found at",
        "our_privacy_policy" : "and our Privacy Policy",
        "privacy_policy" : "Privacy Policy",
        "as_applicable" : "as applicable.",
        "screen-sharing" : "screen sharing",
        "whiteboard_access" : "whiteboard access",
        "Document_access" : "Document access",
        "share_the_screen" : "share the screen!",
        "to_collaborate" : "to collaborate!",
        "share_document" : "share document!",

        "exit_or_end" : "Exit or End Meeting?",
        "exit_from_meeting" : "Exit from Meeting?",
        "are_sure_exit_meeting_everyone" : "Are you sure you want to exit from the meeting? You can either end the meeting for everyone or you can exit from the meeting.",
        "are_sure_exit_meeting" : "Are you sure you want to exit from the meeting?",
        "exit_meeting" : "Exit Meeting",
        "end_for_all" : "End Meeting for all",
        "end_meeting" : "End Meeting",
        "will_let_alone_exit" : "will let you alone exit or leave from the meeting.",
        "will_end_for_everyone" : "will end the meeting for everyone in the conference (All participants & attendees).",
        "user_already_exists" : "Users Already Exists",
        "already_user_avail" : "There are already some users available in the conference. ",
        "if_like_continue" : "If you would like to continue click on ",
        "force_start_meetinh" : "Force Start Another Meeting ",
        "otherwise_click" : "otherwise click on ",
        "to_go_back" : "to go back. ",

        //New by Keerthi - 17th Sep 2020
        "lang_settings" : "Language Settings",
        "lang_info" : "Choose your preferred language to use the application.",
        "select_language" : "Select Language",
        "settings_languages" : "Languages",

        "rec_plugin" : "Recording Plugin",
        "rec_heading1" : "How to install and manage InstaVC Screen-Video Recorder with the plugin",
        "rec_step1" : "Step 1: Copy & open Link in Chrome",
        "rec_step1_para" : "First copy the link ",
        "rec_step1_para_br" : "from here and open it in chrome to add the plugin to your browser. Click on ",
        "rec_step1_para_bold" : "Add to Chrome",
        "rec_step1_para_ct" : "to add this extension.",

        "rec_step2" : "Step 2: Pop Up for Permission",
        "rec_step2_para_br" : "When you click on Add to chrome, here appears a popup asking for permission to display notification access your content. Click on ",
        "rec_step2_para_bold" : "Add Extension",
        "rec_step2_para_ct" : "to proceed.",

        "rec_step3" : "Step 3: Extension Added to Chrome",
        "rec_step3_para" : "When the extension is added to chrome, it shows a window giving you an information with a plugin icon and how to manage this plugin from here.",

        "rec_step4" : "Step 4: Pin the plugin on the toolbar",
        "rec_step4_para_br" : "To pin the plugin to your toolbar, you need to click on manage extensions, go to ",
        "rec_step4_para_bold" : "InstaVC Screen-Recording",
        "rec_step4_para_ct" : "and click on the pin icon to add.",

        "rec_step5" : "Step 5: Extension/Plugin Pinned to Toolbar",
        "rec_step5_para" : "Once the extension is pinned, you can now see an instavc recording icon in your browser toolbar and start using it for easy access." ,

        "rec_step6" : "Step 6: InstVC Recorder Setup",
        "rec_step6_para_br" : "Once you have installed the plugin and the next time when you open it, you can now see the ",
        "rec_step6_para_bold" : "InstaVC Recorder Setup",
        "rec_step6_para_ct" : "page to enable or disable the recording tools. By default both the options will be enabled. Click on ",
        "rec_step6_para_bold1" : "Next",
        "rec_step6_para_ct1" : "to continue.",

        "rec_step7" : "Step 7: Additional Permissions",
        "rec_step7_para_br" : "Once the Next button is clicked, system will then show you a popup asking for additional permissions to read and change your data. Click ",
        "rec_step7_para_bold" : "Allow",
        "rec_step7_para_ct" : "to continue.",

        "rec_step8" : "Step 8: Permissions Allowed",
        "rec_step8_para" : "After allowing the permission, this page now gets frozen or in a disabled mode from where you can now start exploring recording options now.",

        "rec_heading2" : "Desktop Screen-Recording Plugin",

        "rec_step9" : "Step 9: Desktop Screen-Recording",
        "rec_step9_para_br" : "After allowing all permissions, you can now access recording menus from the plugin extension in your toolbar. Click on it to see further options. Here we have selected ",
        "rec_step9_para_bold" : "Desktop Screen Recording",
        "rec_step9_para_ct" : "and click on the",
        "rec_step9_para_bold1" : "Record",
        "rec_step9_para_ct1" : "button to start.",

        "rec_step9a" : "Step 9(a): Share your Screen Popup",
        "rec_step9a_para_br" : "Now the system shows up this popup asking to select the contents of your screen, whether its an Entire Screen or only application window. Click on the tab which you want to share and click on ",
        "rec_step9a_para_bold" : "Share",
        "rec_step9a_para_ct" : "button and go to that screen which you want to start recording.",

        "rec_step9b" : "Step 9(b): Desktop Screen Recording Started",
        "rec_step9b_para_br" : "The Desktop Screen recording is now started and you can see a blink in the toolbar while its in recording mode. Also, you can see a tab at the bottom showing buttons for ",
        "rec_step9b_para_bold" : "Stop Sharing",
        "rec_step9b_para_ct" : "or",
        "rec_step9b_para_bold1" : "Hide",
        "rec_step9b_para_ct1" : ". You can see the bottom message on any screen you are opening until you are in the desktop screen recording mode.",

        "rec_step9c" : "Step 9(c): Restart / Pause or Stop Desktop Screen Recording",
        "rec_step9c_para_br" : "Once you want to stop Recording. Click on the plugin in the toolbar and here you can see the options to ",
        "rec_step9c_para_bold" : "Restart, Pause",
        "rec_step9c_para_ct" : "and",
        "rec_step9c_para_bold1" : "Stop",
        "rec_step9c_para_ct1" : "buttons. You can click any of those accordingly.",

        "rec_step9d" : "Step 9(d):Recent Recording View",
        "rec_step9d_para_br" : "Once you stop the recording from the toolbar, the recent recording will now automatically open the recording in a new tab along with Title and ",
        "rec_step9d_para_bold" : "Download",
        "rec_step9d_para_ct" : "option in the top and the recording details in the right column.",
        
        "rec_heading3" : "Browser Tab Screen-Recording Plugin",

        "rec_step10" : "Step 10: Browser Tab Screen-Recording",
        "rec_step10_para_br" : "When we want to select ",
        "rec_step10_para_bold" : "Browser Tab",
        "rec_step10_para_ct" : "option for recording your browser screen. First open your screen(url) in chrome whichever you want to record and then open the plugin and click on Browser Tab, adjust microphone options and then click",
        "rec_step10_para_bold1" : "Record",
        "rec_step10_para_ct1" : "button to start recording.",

        "rec_step10a" : "Step 10(a): Browser Tab is being Recorded",
        "rec_step10a_para" : "Once the Browser tab is started recording, you can see a red dot blinking in your toolbar which indicates the tab is being recorded and also a blue box blinking in your browser title bar",
        
        "rec_step10b" : "Step 10(b): Record another tab",
        "rec_step10b_para_br" : "At the time of current screen recording, if you open another url and open the plugin, it will show you the recording status along with an option",
        "rec_step10b_para_bold" : "Record This Tab",
        "rec_step10b_para_ct" : "if you want to change the recording scree to the new url. Meanwhile the blue box blinking in the title bar of the browser indicates the tab that is being recorded at the moment.",

        "rec_step10c" : "Step 10(C): Stop and View recording",
        "rec_step10c_para_br" : "Later, when you stop the recording, the browser itself opens the recording in a new tab along with Title and",
        "rec_step10c_para_bold" : "Download",
        "rec_step10c_para_ct" : "Option at the top and the recording details in the right column.",

        "rec_heading4" : "Webcam Recording",

        "rec_step11" : "Step 11: Start Webcam Screen Recording",
        "rec_step11_para_br" : "When you start with Webcam screen recording, make sure to select the microphone & camera settings among the devices detected by the plugin. and then click on the",
        "rec_step11_para_bold" : "Record",
        "rec_step11_para_ct" : "button to start with.",

        "rec_step11a" : "Step 11(a): Webcam opens Self Video and starts Recording",
        "rec_step11a_para_br" : "The recording opens a web page with yourself video with",
        "rec_step11a_para_bold" : "Pause",
        "rec_step11a_para_ct" : "and",
        "rec_step11a_para_bold1" : "Stop",
        "rec_step11a_para_ct1" : "recording options at the bottom.",

        "rec_step11b" : "Step 11(b): View Webcam Recorded Video",
        "rec_step11b_para" : "When you stop the recording in the previous screen, the browser then opens the recording in a new tab to view and download along with the recording details.",
        
        "rec_heading5" : "Top Menus in Recording Plugin",

        "rec_step12" : "Step 12: Navigation Menus",
        "rec_step12_para_br" : "The plugin has few navigation menus to fetch your data as well as manage different actions related to the plugin. One of them is a",
        "rec_step12_para_bold" : "Hamburger Menu",
        "rec_step12_para_ct" : "Click on it to open.",

        "rec_step12a" : "Step 12(a): Open Hamburger Menu",
        "rec_step12a_para_br" : "On click of",
        "rec_step12a_para_bold" : "Hamburger Menu",
        "rec_step12a_para_ct" : ", you can find 2 tabs named",
        "rec_step12a_para_bold1" : "My Recordings",
        "rec_step12a_para_ct1" : "and",
        "rec_step12a_para_bold2" : "Options",
        "rec_step12a_para_ct2" : "which manages all the data you have been recording and options you have enabled or disabled for that plugin. Click on",
        "rec_step12a_para_bold3" : "My Recordings",
        "rec_step12a_para_ct3" : "to see the videos you have recorded till date.",

        "rec_step12b" : "Step 12(b): Access My Recordings",
        "rec_step12b_para_br" : "There is another way of accessing",
        "rec_step12b_para_bold" : "My Recordings",
        "rec_step12b_para_ct" : ", page from the top menu in the plugin which can be considered as a shortcut to navigate.",
        
        "rec_step12c" : "Step 12(c): My Recordings",
        "rec_step12c_para" : "Here will be the detailed list of videos(recordings) you have recorded till date. Each video contains the date, duration, size of the video along with delete icon in the thumbnail view. When you click on any video it opens the details in the view recording page. Note that all the videos will be stored locally here.",

        "rec_step12d" : "Step 12(d): Delete All recordings",
        "rec_step12d_para_br" : "Looking at My recordings Page, there is a more icon in the top right corner which opens the",
        "rec_step12d_para_bold" : "Delete All",
        "rec_step12d_para_ct" : "option to easily delete all recordings with a single click.",
        
        "rec_step12e" : "Step 12(e): Side Navigation",
        "rec_step12e_para_br" : "Instead of opening the plugin again and change, You can directly navigate to another tab from here using the Hamburger Menu in the top left corner of the page that opens the navigation to navigate to the",
        "rec_step12e_para_bold" : "Options",
        "rec_step12e_para_ct" : "page",

        "rec_heading6" : "Plugin Options",

        "rec_step13" : "Step 13: Plugin Options",
        "rec_step13_para_br" : "On click on Hamburger Menu, you can find another tab named Options which manages all the options you have enabled or disabled for that plugin. Click on",
        "rec_step13_para_bold" : "Options",
        "rec_step13_para_ct" : "to see the options that are enabled for you.",

        "rec_step13a" : "Step 13(a): Recorder Options",
        "rec_step13a_para" : "Recorder Options ask you for various options like video storage, Notifications, Keyboard Shortcuts",

        "rec_step13b" : "Step 13(b): Recorder Options",
        "rec_step13b_para" : "Recorder Options contain various other options like Recording Settings and advanced.",

        "rec_heading7" : "Plugin Notifications",

        "rec_step14" : "Step 14: Plugin Notifications",
        "rec_step14_para" : "On the top navigation part, you can find a bell icon for notifications if any related to instavc screen recording plugin.",

        "rec_step14a" : "Step 14(a): Open Notifications",
        "rec_step14a_para" : "On click on the notification icon, you can find the list of notifications if any or it shows an empty slide in.",

        "rec_heading8" : "Recording Settings",

        "rec_step15" : "Step 15: Settings",
        "rec_step15_para_br" : "Then comes the last navigation menu on the top right corner named",
        "rec_step15_para_bold" : "Settings",
        "rec_step15_para_ct" : "where you can open and manage the recording settings accordingly.",

        "rec_step15a" : "Step 15(a): Advanced Settings Options",
        "rec_step15a_para" : "Here you can adjust the settings based on the Resize tab, Limit resolution and maximum frame rate which are given by plugin.",

        "crteContent_upload_medialist" : "Uploaded Media List",
        "content_upld_desc_txt" : "The files you upload here will be available to users who view content.",
        "content_browse_txt" : "Browse Files to Upload",
        "content_upld_types" : "Files with only .jpg, .jpeg, .png, and .pdf are allowed limiting the max size upto 15MB",

        "video_qlty": "Video Quality",
        "audio_qlty": "Audio Quality",
        "cntnt_shrng": "Content Sharing",
        "fdb_poor": "Poor",
        "fdb_good": "Good",
        "fdb_avg": "Average",
        "fdb_excellent": "Excellent",
        "share_feedback": "Share your FeedBack",
        "ovrl_rating": "Overall Rating",
        "fdb_cmnts": "Your Comments are highly appreciated",
        "no_thnks": "No Thanks",
        

        "upload_size_max_15mb": "You can upload max size limit is 15MB",

        "pastMeeting_feedback" : "Feedback",
        "pastMeeting_comments" : "Comments",
        
        "microsoft_webplugin" : "Microsoft Outlook Web Plugin",
        "microsoft_installable" : "Microsoft Outlook Installable Plugin",
        "outlook_installable": "Outlook Installable",
        "customization_installer": "How to access customization Installer",
        "plugin_step1" : "Step 1: Click the link to download file",
        "click_link_download": "Click the Link to Download File",
        "first_click_link": "First Click on the link below",
        "to_download_file": "to download the file into your system. Then open the downloaded file to start the installation and click",
        "install_txt": "Install",
        "plugin_step2" : "Step 2: Customization Installed",
        "plugin_step2_para":"Once you start installing, wait untill the file gets installed and you get a popup showing successfully Installed and then close.",
        "plugin_step3" : "Step 3: Open Outlook & Go to Meetings",
        "plugin_step3_para" : "After installation, Open Microsoft Outlook and go to meetings.",
        "plugin_step3_para1" : "Go to New Email > Meetings",
        "plugin_step4" : "Step 4: Open Meetings",
        "plugin_step4_para" : "Once Meetings Window gets open move on the list of the above tabs and you can then go on clicking tab",
        "plugin_step4_para1": "Peoplelink",
        "plugin_step5": "Step5: Schedule Conference",
        "plugin_step5_para": "Under Peoplelink you have a sub Tab named",
        "plugin_step5_para1": "Schedule Conference.",
        "plugin_step5_para2": "Click on it and you can see Schedule options on the right side of the window. Here you can manage inVC Schedules.",
        "plugin_step6": "Step6: Manage or Customize Schedules",
        "plugin_step6_para": "Here you can manage or customize schedules by the list of rooms, timings and can create a new schedule from here which redirects you to the browser and back to outlook.",
        "plugin_step6_para1": "Once you are okay with the schedule you can click on ",
        "plugin_step6_para2": "Get Link",
        "plugin_step7": "Link Generated",
        "plugin_step7_para": "Then here gets the link generated and composed in the mail which you can even use it for sharing online or proceed further.",
        
        








    }
}

        // "yt_basic_meeting" : "Basic Settings for Meeting",
        // "yt_advance_meeting" : "Advanced Settings for Meeting",
        // "yt_joinMeeting_roomID" : "Join a Meeting with Room ID",
        // "yt_joinMeeting_URL" : "Join a Meeting with URL",
        // "yt_sched_meeting" : "How to Schedule Meeting",
        // "yt_view_past_meeting" : "How to View Past Meetings",
        // "yt_upcoming_meeting" : "How to View UpComing meetings",
        // "yt_p2p_meeting" : "P2P Meeting with Directory Contacts",
        // "yt_my_profile" : "My Profile",
        // "yt_partici_control" : "Participant Controls",
        // "yt_parti_become_host" : "How Participant becomes Host",
        // "yt_sharing_multiple_scr" : "Sharing Multiple Screens",
        // "yt_add_cam_to_meeting" : "Add Multiple Cameras to the meeting",
        // "yt_meeying_control" : "Host Meeting Controls",
        // "yt_collaboration_tool" : "Host Collaboration Tools",