import loadJS from 'load-js';
// import { PubSub } from 'aws-amplify';

function SignalingChannel() {

  this.onMessage = null;
  this.onServerDisconnected = null;

  var clientType = 'Web';
  var clientVersion = '4.2';

  var wsServer = null;

  var self = this;

  let connectPromise = null;

  var MAX_TRIALS = 10;
  var reconnectTimes = 0;

  /* TODO: Do remember to trigger onMessage when new message is received.
     if(this.onMessage)
       this.onMessage(from, message);
   */

  // message should a string.
  this.send = function (targetId, message) {
    var data = {
      data: message,
      to: targetId
    };
    return new Promise((resolve, reject) => {
      wsServer.emit('owt-message', data, function (err) {
        if (err)
          reject(err);
        else
          resolve();
      });
    });
  };

  this.connect = function (loginInfo) {
    var serverAddress = loginInfo.host;
    var token = loginInfo.token;
    var paramters = [];
    var queryString = null;
    paramters.push('clientType=' + clientType);
    paramters.push('clientVersion=' + clientVersion);
    if (token)
      paramters.push('token=' + encodeURIComponent(token));
    if (paramters)
      queryString = paramters.join('&');
    console.log('Query string: ' + queryString);
    var opts = {
      query: queryString,
      'reconnection': true,
      'reconnectionAttempts': MAX_TRIALS,
      'force new connection': true
    };
    wsServer = window.io(serverAddress, opts);

    wsServer.on('connect', function () {
      reconnectTimes = 0;
      console.info('Connected to websocket server.');
    });

    wsServer.on('server-authenticated', function (data) {
      console.log('Authentication passed. User ID: ' + data.uid);
      if (connectPromise) {
        connectPromise.resolve(data.uid);
      }
      connectPromise = null;
    });

    wsServer.on('reconnecting', function () {
      reconnectTimes++;
    });

    wsServer.on('reconnect_failed', function () {
      if (self.onServerDisconnected)
        self.onServerDisconnected();
    })

    wsServer.on('server-disconnect', function () {
      reconnectTimes = MAX_TRIALS;
    })

    wsServer.on('disconnect', function () {
      console.info('Disconnected from websocket server.');
      if (reconnectTimes >= MAX_TRIALS && self.onServerDisconnected)
        self.onServerDisconnected();
    });

    wsServer.on('connect_failed', function (errorCode) {
      console.error('Connect to websocket server failed, error:' +
        errorCode + '.');
      if (connectPromise) {
        connectPromise.reject(parseInt(errorCode))
      }
      connectPromise = null;
    });

    wsServer.on('error', function (err) {
      console.error('Socket.IO error:' + err);
      if ((err === '2103' || err === 2103) && connectPromise) {
        connectPromise.reject(err)
        connectPromise = null;
      }
    });

    wsServer.on('owt-message', function (data) {
      console.info('Received woogeen message.');
      if (self.onMessage)
        self.onMessage(data.from, data.data);
    });

    return new Promise((resolve, reject) => {
      connectPromise = {
        resolve: resolve,
        reject: reject
      };
    });
  };

  this.disconnect = function () {
    reconnectTimes = MAX_TRIALS;
    if (wsServer)
      wsServer.close();
    return Promise.resolve();
  };

}

export default class P2P {
  constructor() {
    loadJS([{
      url: "/js/socket.js"
    }, {
      url: "/js/adapter.js"
    }, {
      url: "/js/owt.js"
    }]).then(() => {
      console.log('P2P Initialised');
      this.serverAddress = "https://awspeer4.instavc.com"
      this.signaling = new SignalingChannel();

    });
    this.p2p = null;
    this.mediaStream = null;
    this.localStream = null;
    this.publicationForCamera = null;
  }
  connectP2PServer = async (uid, remoteid, isPublish, turnCredentials, callback) => {
    this.p2p = new window.Owt.P2P.P2PClient({
      audioEncodings: true,
      videoEncodings: [{ codec: { name: 'h264' } }, { codec: { name: 'vp9' } }, { codec: { name: 'vp8' } }],
      rtcConfiguration: {
        iceServers: [{
          urls: "stun:164.100.166.174:3478"
        }, {
          urls: [
            "turn:164.100.166.174:443?transport=udp",
            "turn:164.100.166.174:443?transport=tcp"
          ],
          credential: turnCredentials.credential,
          username: turnCredentials.username
        }
        ]
      },
    }, this.signaling);
    
    this.p2p.addEventListener('serverdisconnected', (event) => {
      callback({ disconnect: true });
      this.p2p.clearEventListener('serverdisconnected');
    });
    this.p2p.addEventListener('messagereceived', (event) => {

    });

    this.p2p.connect({
      host: this.serverAddress,
      token: uid
    }).then(async () => {
      this.p2p.allowedRemoteIds = [remoteid];
      // console.log('Connected to P2P server', cam);
      if (isPublish) {
        var cam = await this.setLocalCamera();
        this.p2p.publish(remoteid, cam).then(publication => {
          this.publicationForCamera = publication;
          // PubSub.publish(`call-${remoteid}` , { command: 'CALL_RESPONDED_2', from: uid });
        }, error => {
          console.log('Failed to share screen.', error);
        });
      }
      //$('#uid').prop('disabled', true);
    }, error => {
      console.log('Failed to connect to the signaling server.');
    });

    this.p2p.addEventListener('streamadded', async (event) => {
      console.log('Stream added!!!!', event);
      let remote = document.getElementById('remote');
      remote.srcObject = event.stream.mediaStream;
      remote.play();
      remote.style.transform = "none";

      if (!isPublish) {
        var cam = await this.setLocalCamera();
        this.p2p.publish(remoteid, this.localStream).then(publication => {
          this.publicationForCamera = publication;
          //PubSub.publish(`call-${remoteid}` , { command: 'CALL_RESPONDED_2', from: uid });
        }, error => {
          console.log('Failed to share screen.', error);
        });
      }
      // PubSub.publish(`call-${remoteid}` , { command: 'CALL_RESPONDED_1', from: uid });
      // PubSub.publish()
    });
  }

  exitCall = () => {
    try {
      this.p2p.clearEventListener('streamadded');
      this.p2p.clearEventListener('messagereceived');
      this.p2p.disconnect();
      this.localStream.mediaStream.getTracks().forEach((track) => { track.stop() });
    } catch (error) {
      this.localStream = null;
    }
  }

  selfMute = (kind) => {
    if (this.publicationForCamera) {
      for (const track of this.mediaStream.getTracks()) {
        console.log(track, track.kind, track.enabled);
        if (track.kind === kind) {
          track.enabled = false;
          break;
        }
      }
      // this.publicationForCamera.mute('audio').then(() => {
      //   let track = this.mediaStream.getAudioTracks()[0];
      //   track.enabled = false;
      //   // this.muteStatus = true;
      //   // this.dispatch(`mute${this.selfOrigin}`, {status: true, kind: 'audio'});
      //   // this.dispatch('audio-mute', true);
      // })
    }
  }

  selfUnMute = (kind) => {
    if (this.publicationForCamera) {
      for (const track of this.mediaStream.getTracks()) {
        if (track.kind === kind) {
          track.enabled = true;
          break;
        }
      }
    }
  }

  publishVideo = (remoteid) => {
    this.p2p.publish(remoteid, this.localStream).then(publication => {
      this.publicationForCamera = publication;
    }, error => {
      console.log('Failed to share screen.', error);
    });
  }

  setLocalCamera = () => {
    const Owt = window.Owt;
    const audioConstraintsForMic = new Owt.Base.AudioTrackConstraints(Owt.Base.AudioSourceInfo.MIC);
    var videoConstraintsForCamera = new Owt.Base.VideoTrackConstraints(Owt.Base.VideoSourceInfo.CAMERA);
    // videoConstraintsForCamera.resolution = new Owt.Base.Resolution(320, 180);
    return Owt.Base.MediaStreamFactory.createMediaStream(new Owt.Base.StreamConstraints(audioConstraintsForMic, videoConstraintsForCamera)).then(stream => {
      this.mediaStream = stream;
      this.localStream = new Owt.Base.LocalStream(this.mediaStream, new Owt.Base.StreamSourceInfo('mic', 'camera'));
      let objLocal = document.getElementById('self');
      objLocal.srcObject = this.localStream.mediaStream;
      objLocal.play();
      window.localStream = this.localStream;
      return this.localStream;
      //   $('#local').children('video').get(0).srcObject = localStream.mediaStream;
      //   p2p.publish(getTargetId(), localStream).then(publication=>{
      //     publicationForCamera = publication;
      //   }, error => {
      //     console.log('Failed to share video.');
      //   });
    }, err => {
      console.error('Failed to create MediaStream, ' + err);
    });
  }
}