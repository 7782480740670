import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './SideNav.css';
// import './SideNavRtl.css';
class PluginNav extends Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	componentDidMount() {
		console.log('teams Pro', this.props);
	}

	render() {
		return (
			<React.Fragment>
				<ul>
					<li>
						<NavLink to="/plugin" activeClassName="activeTabNav"><span className="imgLink"><img src="/images/blue/teamsicon.png" alt="dashboard" /></span><span className="txtLink">{this.props.language.microsoft_teams}</span></NavLink>
					</li>
					<li>
						<NavLink to="/plugin/outlook-installable" activeClassName="activeTabNav"><span className="imgLink"><img src="/images/blue/installable.png" alt="dashboard" /></span><span className="txtLink">{this.props.language.microsoft_installable}</span></NavLink>
					</li>
					<li>
						<NavLink to="/plugin/outlook" activeClassName="activeTabNav"><span className="imgLink"><img src="/images/blue/outlookicon.png" alt="dashboard" /></span><span className="txtLink">{this.props.language.microsoft_webplugin}</span></NavLink>
					</li>
					<li>
						<NavLink to="/plugin/recording" activeClassName="activeTabNav"><span className="imgLink"><img src="/images/blue/instavc-recording.png" alt="dashboard" /></span><span className="txtLink">InstaVC Screen-Video Recorder</span></NavLink>
					</li>
				</ul>
			</React.Fragment>
		)
	}
}

export default PluginNav;