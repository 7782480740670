import { API } from "aws-amplify";

export const GROUPS_LIST = 'GROUPS_LIST';
export const CONTENT_LIST = 'CONTENT_LIST';
export const CONTENT_VIEW = 'CONTENT_VIEW';
export const CONTENT_INDEX = 'CONTENT_INDEX';
export const QUESTIONNARIE_LIST = 'QUESTIONNARIE_LIST';
export const QUESTIONNARIE = 'QUESTIONNARIE';
export const CONTENT_MEDIA = 'CONTENT_MEDIA';
export const CLEAR_DATA = 'CLEAR_DATA';
export const SET_DATA = 'SET_DATA';
export const POLLS_LIST = 'POLLS_LIST';
export const SET_POLL = 'SET_POLL';
export const CONTENT_SCROLL = 'CONTENT_SCROLL';

// export function getGroupsList(email, isPublish = null) {
//     return (dispatch) => {
//         return new Promise((resolve, reject) => {
//             let path = isPublish !== null ? `/groups/${email}?isPublish=${isPublish}` : `/groups/${email}`;
//             return API.get("content", path).then(res => {
//                 if (res && res.data) {
//                     dispatch(saveGroups(res.data))
//                     resolve(res.data)
//                     return;
//                 } else {
//                     dispatch(saveGroups([]))
//                     resolve([])
//                     return;
//                 }
//             }).catch(err => {
//                 console.log("err === ", err);
//                 dispatch(saveGroups([]))
//                 reject(err)
//                 return;
//             });
//         });
//     }
// }

export function saveGroupsList(data) {
    return (dispatch) => dispatch(saveGroups(data))
}

export function saveGroups(data) {
    return {
        type: GROUPS_LIST,
        data: data
    }
}

export function saveContentList(groupData, contentList) {
    return (dispatch) => dispatch(saveContents(groupData, contentList))
}

export function saveContents(group, data) {
    return {
        type: CONTENT_LIST,
        data: data,
        group: group
    }
}

export function setContentView(data) {
    return (dispatch) => dispatch(setContent(data))
}

export function setContent(data) {
    return {
        type: CONTENT_VIEW,
        data: data
    }
}

export function setContentIndex(index) {
    return (dispatch) => dispatch(setIndex(index))
}

export function setIndex(index) {
    return {
        type: CONTENT_INDEX,
        index
    }
}

export function saveQuestionnarieList(data) {
    return (dispatch) => dispatch(setQuestionnaries(data))
}

export function setQuestionnaries(data) {
    return {
        type: QUESTIONNARIE_LIST,
        data
    }
}

export function saveQuestionnarie(data) {
    return (dispatch) => dispatch(setQuestionnarie(data))
}

export function setQuestionnarie(data) {
    return {
        type: QUESTIONNARIE,
        data
    }
}

export function saveMedia(data) {
    return (dispatch) => dispatch(setMedia(data))
}

export function setPercentage(data) {
    return (dispatch) => dispatch({
        type: CONTENT_SCROLL,
        data
    });
}

export function setMedia(data) {
    return {
        type: CONTENT_MEDIA,
        data
    }
}

export function setContentData(data) {
    return (dispatch) => dispatch(setData(data))
}

export function setData(data) {
    return {
        type: SET_DATA,
        data
    }
}

export function savePollsList(data) {
    return {
        type: POLLS_LIST,
        data
    }
}

export function savePoll(data) {
    return {
        type: SET_POLL,
        data
    }
}

export function clearContentData() {
    return (dispatch) => dispatch(clearData())
}

export function clearData() {
    return {
        type: CLEAR_DATA
    }
}


