import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "./components/AsyncComponent";
// import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ConfRoute from "./components/ConfRoute";
import AuthenticatedMSRoute from "./components/AuthenticatedMSRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import MSRoute from "./components/MSRoute";
import GuestRoute from "./components/GuestRoute";
import MRoute from "./components/MRoute";
import HostRoute from "./components/HostRoute";
import LegacyRoute from "./components/LegacyRoute";
import LegacyGuestRoute from "./components/LegacyGuestRoute";
import AdminRoute from "./components/AdminRoute";
import AffiliateRoute from "./components/AffiliateRoute";
import ContentRoute from "./components/ContentRoute";
import PluginRoute from "./components/PluginRoute";
import ApiRoute from "./components/ApiRoute";
import { config } from "./config";
import GuestSignup from "./containers/guest-signup/GuestSignup";

// const AsyncHome = asyncComponent(() => import("./containers/home/Home"));
const AsyncLogin = lazy(() => import("./containers/login/Login"));
const AsyncOLogin = lazy(() => import("./containers/outlook/login/Login"));
const AsyncORooms = lazy(() => import("./containers/outlook/rooms"));
const AsyncSignup = lazy(() => import("./containers/signup/signup"));
const AsyncForgotPassword = lazy(() => import("./containers/forgotPassword/forgotPassword"));
const AsyncConfirmPassword = lazy(() => import("./containers/forgotPassword/confirmPassword"));
const AsyncDashboard = lazy(() => import("./containers/dashboard/dashboard"));

const AsyncScheduleCalendar = lazy(() => import("./containers/calendar/CalendarSchedule"));
const AsynccalenderList = lazy(() => import("./containers/calendar/ListSchedule"));

const AsyncScheduleMeeting = lazy(() => import("./containers/schedule/scheduleMeeting"));
// const AsyncRoomSettings = lazy(() => import("./containers/dashboard/components/RoomSettings"));
const AsyncManageLicenses = lazy(() => import('./containers/dashboard/components/ManageLicenses'));
const AsyncCreateNewRoom = lazy(() => import('./containers/dashboard/components/CreateNewRoom'));
// const AsyncScheduleMeeting = lazy(() => import("./containers/calendar/"));
const AsyncMeetingsList = lazy(() => import("./containers/schedule/MeetingsList"));
const AsyncPastMeetingItem = lazy(() => import("./containers/schedule/components/PastMeetingItem"));

const AsyncWebinarList = lazy(() => import("./containers/schedule/components/WebinarList"));
const AsyncWebinarDetails = lazy(() => import("./containers/schedule/components/WebinarDetails"));
// const AsyncBuyRoom = lazy(() => import("./containers/buyRoom/BuyRoom"));
const AsyncSetup = lazy(() => import("./components/common/InitialSetup"));
const AsyncConfSetup = lazy(() => import("./containers/conference/ConferenceSetup"));
const AsyncConfFeedback = lazy(() => import("./containers/confsimulcast/conffeedback/feedback"));

//import("./containers/conference/ConferenceSetup"));
const AsyncConference = lazy(() => import("./containers/conference/Conference"));
const AsyncSimulcast = lazy(() => import("./containers/confsimulcast/conference"));
const AsyncConference2020 = lazy(() => import("./containers/conference/Conference2020"));
const AsyncConferenceMix2020 = lazy(() => import("./containers/conference/ConferenceMix2020"));

// NEW CONFERENCE
const AsyncConferenceNew = lazy(() => import("./containers/conference/conf2021/index"));

//const AsyncSimulcast = lazy(() => import("./containers/conference/SimulcastVAD"));
const AsyncLegacy = lazy(() => import("./containers/conference/Legacy"));
const AsyncNotFound = asyncComponent(() => import("./containers/errors/NotFound"));
const AsyncInvalidUrl = lazy(() => import("./containers/errors/InvalidUrl"));
const AsyncHostLeft = lazy(() => import("./containers/conference/components/HostLeftMsg"));
const AsyncProfile = lazy(() => import("./containers/profile/MyProfile"));
const AsyncSettings = lazy(() => import("./containers/settings/Settings"));
const AsyncAffiliate = lazy(() => import("./containers/settings/affiliate"));
const AsyncWaitingRoom = lazy(() => import("./containers/conference/components/WaitingRoom"));
const AsyncPastInvitationLists = lazy(() => import("./containers/invitations/PastInvitaionsList"));
const AsyncPresentInvitationsList = lazy(() => import("./containers/invitations/PresentInvitationsList"));
const AsyncGuestInvitation = lazy(() => import("./containers/guest/guestInvitation/GuestInvitation"));
const AsyncPaymentSuccess = lazy(() => import("./containers/buyRoom/PaymentSuccess"));
const AsyncPaymentFailure = lazy(() => import("./containers/buyRoom/PaymentFailure"));
const AsyncErrorPage = lazy(() => import("./containers/errors/ErrorPage"));
const AsyncSnackbar = lazy(() => import("./components/common/SnackBars"));
const AsyncNoData = lazy(() => import("./components/common/Nodata"));
const AsyncNoSearchResult = lazy(() => import("./components/common/NoSearchResults"));
const AsyncRoomFull = lazy(() => import("./containers/errors/RoomFull"));
const AsyncScreensharePoup = lazy(() => import("./containers/conference/components/ScreenSharePopup"));
const AsyncCameraResolution = lazy(() => import("./components/common/CameraResolution"));
const AsyncBrowserSupport = lazy(() => import("./components/common/BrowserSupport"));
const AsyncBrowserUpgrade = lazy(() => import("./components/common/BrowserUpgrade"));
const AsyncSiteNotReached = lazy(() => import("./components/common/SiteNotReached"));
const AsyncTroubleshoot = lazy(() => import("./components/common/Troubleshoot"));
const AsyncTroubleshootNew = lazy(() => import("./components/common/TroubleshootNew"));
const AsyncFAQComponent = lazy(() => import("./components/common/FAQ"));
const AsyncAnswerShare = lazy(() => import('./containers/conference/components/UiReference'));
const AsyncRequestShare = lazy(() => import('./containers/conference/components/RequestShare'));
const AsyncPackages = lazy(() => import("./containers/packages/Package"));
const AsyncMcuLogin = lazy(() => import("./containers/mcu/McuLogin"));
const AsyncCheckout = lazy(() => import("./containers/packages/Checkout"));
const AsyncProgress = lazy(() => import("./components/common/ProgressLoading"));
const AsyncSecondScreen = lazy(() => import("./containers/conference/SecondScreen"));
const AsyncPresentationScreen = lazy(() => import("./containers/conference/PresentationScreen"));
const AsyncSpeakerScreen = lazy(() => import("./containers/conference/SpeakerScreen"));
const AsyncViewSchedule = lazy(() => import("./containers/dashboard/components/ViewSchedule"));
const AsyncViewRecordings = lazy(() => import("./containers/recordings/recordingGallery"));
const AsyncViewMeetingRecordings = lazy(() => import("./containers/meetingRecording/index"));
const AsyncUserRemoved = lazy(() => import("./containers/conference/components/UserRemoved"));

const AsyncWirelessShare = lazy(() => import("./containers/conference/WirelessShare"));
const AsyncBroadcast = lazy(() => import("./containers/broadcast/broadcast"));
const AsyncCoBrowsing = lazy(() => import("./containers/conference/components/coBrowsing"));
const AsyncPlayer = lazy(() => import("./containers/conference/components/Player"));
const AsyncRecordings = lazy(() => import("./containers/recordings/recordingGallery"));
const AsyncTutorial = lazy(() => import("./containers/dashboard/components/StartTutorial"));
/******* ADMIN Routes *******/
const AsyncAdminDashbord = lazy(() => import("./containers/admin/dashboard/dashboard"));
const AsyncAdminPlans = lazy(() => import("./containers/admin/plans/plans"));
const AsyncAdminOrders = lazy(() => import("./containers/admin/orders/OrdersList"));
const AsyncAdminUsers = lazy(() => import("./containers/admin/users/UsersList"));
const AsyncAdminCoupons = lazy(() => import("./containers/admin/coupons/CouponList"));
const AsyncAdminRooms = lazy(() => import("./containers/admin/rooms/RoomsList"));
const AsyncAdminSettings = lazy(() => import("./containers/admin/settings/adminSettings"));
const AsyncAdminAffiliates = lazy(() => import("./containers/admin/affiliates/AffiliateList"));
const AsyncAdminWithdraw = lazy(() => import("./containers/admin/withdraws/Withdraw"));

/****** REPORTS Routes ********/
const AsyncReferralAccount = lazy(() => import("./containers/reports/referralAccount/referralAct"));
const AsyncMyReferral = lazy(() => import("./containers/reports/myReferral/myreferral"));
const AsyncMySales = lazy(() => import("./containers/reports/mySales/mysales"));
const AsyncMyEarnings = lazy(() => import("./containers/reports/myEarnings/myearnings"));
const AsyncRequestWithdraw = lazy(() => import("./containers/reports/requestWithdraw/requestwithdraw"));

const AsyncCarousel = lazy(() => import("./containers/carousel/index"));

/****** Content Routes ********/
const AsyncContent = lazy(() => import("./containers/content/index"));
const AsyncManageContent = lazy(() => import("./containers/content/managecontent/index"));
const AsyncCreateContent = lazy(() => import("./containers/content/components/createContent/createcontent"));
const AsyncViewContent = lazy(() => import("./containers/content/components/createContent/view_content"));
const AsyncViewAccessContent = lazy(() => import("./containers/content/components/createContent/view_access_content"));
const AsyncManageParticipants = lazy(() => import("./containers/content/components/contentParticipants/manageparticipants"));
const AsyncManageGrades = lazy(() => import("./containers/content/components/contentGrades/managegrade"));
const AsyncQuestionnaire = lazy(() => import("./containers/content/components/contentQuestionnaire/managequestionnaire"));
const AsyncQuestions = lazy(() => import("./containers/content/components/contentQuestionnaire/managequestions"));
const AsyncAssignQuestionnaire = lazy(() => import("./containers/content/components/assignQuestionnaire/assignQuestList"));
const AsyncViewQuestionnaire = lazy(() => import("./containers/content/components/contentQuestionnaire/viewQuestionnaire"));
const AsyncViewAssignedQuest = lazy(() => import("./containers/content/components/assignQuestionnaire/viewAssignedQuest"));
const AsyncViewPollAssignedQuest = lazy(() => import("./containers/content/components/viewpollasignquestionary"));
const AsyncAssignments = lazy(() => import("./containers/content/components/assignments/index"));
const AsyncSubmitAssignments = lazy(() => import("./containers/content/components/assignments/assignmentTab/submitAssignment"));
const AsyncViewAssignments = lazy(() => import("./containers/content/components/assignments/assignmentTab/viewAssignment"));
const AsyncViewSubmittedAssignment = lazy(() => import("./containers/content/components/assignments/assignmentTab/viewSubmittedAssignment"));
const AsyncUploads = lazy(() => import("./containers/content/uploads/index"));
const AsyncPollView = lazy(() => import("./containers/content/components/pollView"));

/******* API Routes *******/
const AsyncApiDashbord = lazy(() => import("./containers/api/dashboard/index"));
const AsyncScheduleApi = lazy(() => import("./containers/api/scheduleApi/index"));

/******* PLUGIN Routes *******/
const AsyncPlugin = lazy(() => import("./containers/plugin/index"));
const AsyncpluginOutlookInstallable = lazy(() => import ("./containers/plugin/PluginInstallable/index"));
const AsyncpluginOutlook = lazy(() => import("./containers/plugin/PluginOutlook/index"));
const AsyncRecordingPlugin = lazy(() => import("./containers/plugin/RecordingPlugin/index"));

/************ITR INDIA FORM ****************/
const AsyncItrIndia = lazy(() => import("./itrform/index"));

/*************************Broadcast2020  ********************/
const AsyncBroadcast2020 = lazy(() => import("./containers/broadcast2020/broadcast"));

const AsyncNotInvited = lazy(() => import("./containers/confsimulcast/conference/components/notInvited"));


export default ({ childProps }) =>
  <Switch>
    {/* <AppliedRoute
      path="/"
      exact
      component={AsyncHome}
      props={childProps}
    /> */}
    <UnauthenticatedRoute
      path="/"
      exact
      component={(!config.isOfflineMCU) ? AsyncLogin : AsyncMcuLogin}
      props={childProps}
    />
    <MSRoute
      path="/ologin"
      exact
      component={AsyncOLogin}
      props={childProps}
    />
    <AuthenticatedMSRoute
      path="/orooms"
      exact
      component={AsyncORooms}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/sign-up"
      exact
      component={AsyncSignup}
      props={childProps}
    />
    <GuestSignup
      path="/guest-sign-up"
      exact
      component={GuestSignup}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/forgot-password"
      exact
      component={AsyncForgotPassword}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/confirm-password"
      exact
      component={AsyncConfirmPassword}
      props={childProps}
    />



    <AuthenticatedRoute
      path="/dashboard"
      exact
      component={AsyncDashboard}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/schedule-meeting"
      exact
      component={AsyncScheduleMeeting}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/calendar"
      exact
      component={AsyncScheduleCalendar}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/calendarList"
      exact
      component={AsynccalenderList}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/past-meetings"
      exact
      component={AsyncMeetingsList}
      props={childProps}
    />
    {/* <AuthenticatedRoute
      path="/roomsettings"
      exact
      component={AsyncRoomSettings}
      props={childProps}
    /> */}
    <AuthenticatedRoute
      path="/manage-licenses"
      exact
      component={AsyncManageLicenses}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/create-new-room"
      exact
      component={AsyncCreateNewRoom}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/past-meeting-item/:id"
      exact
      component={AsyncPastMeetingItem}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/webinar-list"
      component={AsyncWebinarList}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/webinar-details"
      component={AsyncWebinarDetails}
      props={childProps}
    />
    {/* <AuthenticatedRoute
      path="/buy-room"
      exact
      component={AsyncBuyRoom}
      props={childProps}
    /> */}
    <AuthenticatedRoute
      path="/setup"
      exact
      component={AsyncSetup}
      props={childProps}
    />
    <HostRoute
      path="/conference-setup/:roomid"
      exact
      component={AsyncConfSetup}
      props={childProps}
    />
    <HostRoute
      path="/conference-setup/:roomid/:videomode"
      exact
      component={AsyncConfSetup}
      props={childProps}
    />
    <LegacyRoute
      path="/conference-legacy/:roomid"
      exact
      component={AsyncConfSetup}
      props={childProps}
    />
    <LegacyRoute
      path="/conference-legacy/:roomid/:videomode"
      exact
      component={AsyncConfSetup}
      props={childProps}
    />
    <LegacyRoute
      path="/ljoin/:meetingid"
      exact
      component={AsyncConfSetup}
      props={childProps}
    />
    <LegacyGuestRoute
      path="/legacy-guest-setup/:roomid/:videomode"
      exact
      component={AsyncConfSetup}
      props={childProps}
    />
    <GuestRoute
      path="/conference-guest/:roomid/:videomode"
      exact
      component={AsyncConfSetup}
      props={childProps}
    />
    <GuestRoute
      path="/second-display/:roomid/:streamid"
      exact
      component={AsyncSecondScreen}
      props={childProps}
    />
    <GuestRoute
      path="/presentation-screen/:roomid"
      exact
      component={AsyncPresentationScreen}
      props={childProps}
    />
    <GuestRoute
      path="/speaker-screen/:roomid"
      exact
      component={AsyncSpeakerScreen}
      props={childProps}
    />
    <GuestRoute
      path="/legacy-guest/:roomid"
      exact
      component={AsyncLegacy}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/conference/:roomid"
      exact
      component={AsyncConference}
      props={childProps}
    />
    <MRoute
      path="/inconf/:id"
      exact
      component={AsyncSimulcast}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/conference2020/:roominfo"
      exact
      component={AsyncConference2020}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/conferenceMix2020"
      exact
      component={AsyncConferenceMix2020}
      props={childProps}
    />
    {/* NEW CONFERENCE */}
    <AuthenticatedRoute path="/conference2021" exact component={AsyncConferenceNew} props={childProps} />
    <GuestRoute
      path="/guest2020/:roominfo"
      exact
      component={AsyncConference2020}
      props={childProps}
    />
    <MRoute
      path="/join/:id"
      exact
      component={AsyncSimulcast}
      props={childProps}
    />
    <MRoute
      path="/host/:id"
      exact
      component={AsyncSimulcast}
      props={childProps}
    />
    {/* <AuthenticatedRoute
      path="/simulcast/:roomid"
      exact
      component={AsyncSimulcast}
      props={childProps}
    /> */}
    <AuthenticatedRoute
      path="/viewschedule/:roomid"
      exact
      component={AsyncViewSchedule}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/videorecording/:roomid"
      exact
      component={AsyncViewRecordings}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/legacy/:roomid"
      exact
      component={AsyncLegacy}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/viewMeetingRecordings/:meetingId"
      exact
      component={AsyncViewMeetingRecordings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/userremoved"
      exact
      component={AsyncUserRemoved}
      props={childProps}
    />



    <GuestRoute
      path="/guest/:roomid/:guest"
      exact
      component={AsyncConference}
      props={childProps}
    />
    <GuestRoute
      path="/sguest/:roomid/:guest"
      exact
      component={AsyncSimulcast}
      props={childProps}
    />
    <GuestRoute
      path="/guestlegacy/:roomid/:guest"
      exact
      component={AsyncLegacy}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/myprofile"
      exact
      component={AsyncProfile}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/settings"
      exact
      component={AsyncSettings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/affiliate"
      exact
      component={AsyncAffiliate}
      props={childProps}
    />
    <GuestRoute
      path="/waitingroom/:roominfo"
      exact
      component={AsyncWaitingRoom}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/past-invitations"
      exact
      component={AsyncPastInvitationLists}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/present-invitations"
      exact
      component={AsyncPresentInvitationsList}
      props={childProps}
    />
    <HostRoute
      path="/guest-invitation"
      exact
      component={AsyncGuestInvitation}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/payment-success"
      exact
      component={AsyncPaymentSuccess}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/payment-failure"
      exact
      component={AsyncPaymentFailure}
      props={childProps}
    />
    <GuestRoute
      path="/payment-failure"
      exact component={AsyncInvalidUrl}
      props={childProps}
    />
    <GuestRoute
      path="/host-left"
      exact component={AsyncHostLeft}
      props={childProps}
    />
    <GuestRoute
      path="/errorpage"
      exact component={AsyncErrorPage}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/snackbars"
      exact
      component={AsyncSnackbar}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/nodata"
      exact
      component={AsyncNoData}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/nosearch"
      exact
      component={AsyncNoSearchResult}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/screenshare-poup"
      exact
      component={AsyncScreensharePoup}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/cobrowsing"
      exact
      component={AsyncCoBrowsing}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/player"
      exact
      component={AsyncPlayer}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/recordings"
      exact
      component={AsyncRecordings}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/tutorial"
      exact
      component={AsyncTutorial}
      props={childProps}
    />
    <GuestRoute
      path="/room-full"
      exact component={AsyncRoomFull}
      props={childProps}
    />
    <GuestRoute
      path="/camera-res/:roomhash"
      exact
      component={AsyncCameraResolution}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/browser-support"
      exact
      component={AsyncBrowserSupport}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/uireference"
      exact
      component={AsyncAnswerShare}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/request"
      exact
      component={AsyncRequestShare}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/packages"
      exact
      component={AsyncPackages}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/checkout/:planid"
      exact
      component={AsyncCheckout}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/renew/checkout/:licenseid"
      exact
      component={AsyncCheckout}
      props={childProps}
    />
    {/* <GuestRoute
      path="/troubleshoot"
      exact
      component={AsyncTroubleshoot}
      props={childProps}
    /> */}
    <GuestRoute
      path="/troubleshoot"
      exact
      component={AsyncTroubleshootNew}
      props={childProps}
    />
    <GuestRoute
      path="/faq"
      exact
      component={AsyncFAQComponent}
      props={childProps}
    />
    <GuestRoute
      path="/sitenotreached"
      exact
      component={AsyncSiteNotReached}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/mcu"
      exact
      component={AsyncMcuLogin}
      props={childProps}
    />

    <AuthenticatedRoute
      path="/progress"
      exact
      component={AsyncProgress}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/browser-upgrade"
      exact
      component={AsyncBrowserUpgrade}
      props={childProps}
    />


    <GuestRoute
      path="/broadcast/:roomid"
      exact
      component={AsyncBroadcast}
      props={childProps}
    />
    <GuestRoute
      path="/wireless-share"
      exact
      component={AsyncWirelessShare}
      props={childProps}
    />

    <GuestRoute
      path="/broadcast2020/:roomid"
      exact
      component={AsyncBroadcast2020}
      props={childProps}
    />

    {/********* ADMIN ROUTES ********/}
    {/* <AdminRoute
      path="/admin"
      exact
      validate={true}
      component={AsyncAdminDashbord}
      props={childProps}
    /> */}
    <AdminRoute
      path="/admin/plans"
      exact
      validate={childProps.isPlanManager}
      component={AsyncAdminPlans}
      props={childProps}
    />
    <AdminRoute
      path="/admin/orders"
      exact
      validate={childProps.isPlanManager}
      component={AsyncAdminOrders}
      props={childProps}
    />
    <AdminRoute
      path="/admin/users"
      exact
      validate={childProps.isAdmin}
      component={AsyncAdminUsers}
      props={childProps}
    />
    <AdminRoute
      path="/admin/coupons"
      exact
      validate={childProps.isPlanManager}
      component={AsyncAdminCoupons}
      props={childProps}
    />
    <AdminRoute
      path="/admin/rooms"
      exact
      validate={childProps.isPlanManager}
      component={AsyncAdminRooms}
      props={childProps}
    />
    <AdminRoute
      path="/admin/settings"
      exact
      validate={childProps.isPlanManager}
      component={AsyncAdminSettings}
      props={childProps}
    />
    <AdminRoute
      path="/admin/affiliatelist"
      exact
      validate={childProps.isPlanManager}
      component={AsyncAdminAffiliates}
      props={childProps}
    />
    <AdminRoute
      path="/admin/withdrawlist"
      exact
      validate={childProps.isPlanManager}
      component={AsyncAdminWithdraw}
      props={childProps}
    />



    {/********* AffiliateRoute ROUTES ********/}
    <AffiliateRoute
      path="/referral"
      exact
      validate={childProps.isAffiliate}
      component={AsyncReferralAccount}
      props={childProps}
    />
    <AffiliateRoute
      path="/reports/myreferral"
      exact
      validate={childProps.isAffiliate}
      component={AsyncMyReferral}
      props={childProps}
    />
    <AffiliateRoute
      path="/reports/mysales"
      exact
      validate={childProps.isAffiliate}
      component={AsyncMySales}
      props={childProps}
    />
    <AffiliateRoute
      path="/reports/myearnings"
      exact
      validate={childProps.isAffiliate}
      component={AsyncMyEarnings}
      props={childProps}
    />
    <AffiliateRoute
      path="/reports/requestwithdraw"
      exact
      validate={childProps.isAffiliate}
      component={AsyncRequestWithdraw}
      props={childProps}
    />

    {/********* CONTENT ROUTES ********/}
    <ContentRoute
      path="/content"
      exact
      validate={true}
      component={AsyncContent}
      props={childProps}
    />
    <ContentRoute
      path="/content/createcontent/:id/:cid"
      exact
      validate={true}
      component={AsyncCreateContent}
      props={childProps}
    />
    <ContentRoute
      path="/content/manage/:id"
      exact
      validate={true}
      component={AsyncManageContent}
      props={childProps}
    />
    <ContentRoute
      path="/content/managecontent/view-content/:id"
      exact
      validate={true}
      component={AsyncViewAccessContent}
      props={childProps}
    />
    <ContentRoute
      path="/content/view-content/:id"
      exact
      validate={true}
      component={AsyncViewContent}
      props={childProps}
    />



    {/* <ContentRoute
    path="/content/manageparticipants"
    exact
    validate = {true}
    component={AsyncManageParticipants}
    props={childProps}
    />
    <ContentRoute
    path="/content/managegrades"
    exact
    validate = {true}
    component={AsyncManageGrades}
    props={childProps}
    />*/}
    <ContentRoute
      path="/questionnaire"
      exact
      validate={true}
      component={AsyncQuestionnaire}
      props={childProps}
    />
    <ContentRoute
      path="/content/questions/:id"
      exact
      validate={true}
      component={AsyncQuestions}
      props={childProps}
    />
    <ContentRoute
      path="/content/assignquestionnaire"
      exact
      validate={true}
      component={AsyncAssignQuestionnaire}
      props={childProps}
    />
    <ContentRoute
      path="/questionnaire/view-questionnaire/:id"
      exact
      validate={true}
      component={AsyncViewQuestionnaire}
      props={childProps}
    />
    <ContentRoute
      path="/content/assignquestionnaire/viewassignedquest/:id"
      exact
      validate={true}
      component={AsyncViewAssignedQuest}
      props={childProps}
    />
    <ContentRoute
      path="/content/pollassignquestionnaire/viewassignedquest/:id"
      exact
      validate={true}
      component={AsyncViewPollAssignedQuest}
    />
    <ContentRoute
      path="/assignments"
      exact
      validate={true}
      component={AsyncAssignments}
      props={childProps}
    />
    <ContentRoute
      path="/content/assignments/assignmentTab/submitAssignment/:gid/:aid"
      exact
      validate={true}
      component={AsyncSubmitAssignments}
      props={childProps}
    />
    <ContentRoute
      path="/content/assignments/assignmentTab/viewAssignment/:gid/:aid"
      exact
      validate={true}
      component={AsyncViewAssignments}
      props={childProps}
    />
    <ContentRoute
      path="/content/assignments/assignmentTab/viewSubmittedAssignment"
      exact
      validate={true}
      component={AsyncViewSubmittedAssignment}
      props={childProps}
    />
    <ContentRoute
      path="/uploads"
      exact
      validate={true}
      component={AsyncUploads}
      props={childProps}
    />


    {/********* API ROUTES ********/}
    <ApiRoute
      path="/apidashboard"
      exact
      validate={true}
      component={AsyncApiDashbord}
      props={childProps}
    />
    <ApiRoute
      path="/apidashboard/scheduleapi"
      exact
      validate={true}
      component={AsyncScheduleApi}
      props={childProps}
    />
    {/********* PLUGIN ROUTES ********/}
    <PluginRoute
      path="/plugin"
      exact
      validate={true}
      component={AsyncPlugin}
      props={childProps}
    />
    <PluginRoute
      path="/plugin/outlook-installable"
      exact
      validate={true}
      component={AsyncpluginOutlookInstallable}
      props={childProps}
    />
    <PluginRoute
      path="/plugin/outlook"
      exact
      validate={true}
      component={AsyncpluginOutlook}
      props={childProps}
    />
    <PluginRoute
      path="/plugin/recording"
      exact
      validate={true}
      component={AsyncRecordingPlugin}
      props={childProps}
    />
    {/**** To Check Carousel Check ***/}
    <AuthenticatedRoute
      path="/carousel"
      exact
      component={AsyncCarousel}
      props={childProps}
    />

    {/************ ITR INDIA **************/}
    <GuestRoute
      path="/ipindia"
      exact
      component={AsyncItrIndia}
      props={childProps}
    />
    <GuestRoute
      path="/feedback/:meetingId"
      exact
      component={AsyncConfFeedback}
      props={childProps}
    />
    <GuestRoute
      path="/notinvited"
      exact
      component={AsyncNotInvited}
      props={childProps}
    />





    <Route component={AsyncNotFound} />
  </Switch>;
