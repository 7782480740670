// Import Actions
import { CLEAR_DATA, SET_DATA, GROUPS_LIST, CONTENT_LIST, CONTENT_VIEW, CONTENT_INDEX, 
    QUESTIONNARIE_LIST, QUESTIONNARIE, CONTENT_MEDIA, POLLS_LIST, SET_POLL, CONTENT_SCROLL } from '../actions/content_share_actions';

// Initial State
const initialState = {
    page: 'GL',
  groupsList: null,
  group: null, contentList: null,
  content: {data: null, index: -1},
  questionnarieList: null,
  questionnarie: null,
  pollsList: null,
  poll: null,
  media: {data: null, type: '', page: null},
  perc: null
};

const ContentReducer = (state = initialState, action) => {
  switch (action.type) {
      case SET_DATA:
        return Object.assign({}, state, action.data);
    case GROUPS_LIST:
      return Object.assign({}, state, {page: 'GL', groupsList: action.data, group:null, contentList: null, content: {data: null, index: -1}, questionnarieList: null, media: {data: null, type: '', page: null}, pollsList: null});
    case CONTENT_LIST:
        return Object.assign({}, state, {page: 'CL', group: action.group, contentList: action.data, content: {data: null, index: -1}, media: {data: null, type: '', page: null}});
    case CONTENT_VIEW:
        return Object.assign({}, state, {page: 'CV', content: action.data, media: {data: null, type: '', page: null}});
    case CONTENT_INDEX:
        return Object.assign({}, state, {content: {data: state.content.data, index: action.index}});
    case QUESTIONNARIE_LIST:
        return Object.assign({}, state, {page: 'QL', questionnarieList: action.data, questionnarie: null});
    case QUESTIONNARIE:
        return Object.assign({}, state, {page: 'QV', questionnarie: action.data});
    case POLLS_LIST:
        return Object.assign({}, state, {page: 'PL', pollsList: action.data});
    case SET_POLL:
        return Object.assign({}, state, {page: 'PV', poll: action.data});
    case CONTENT_MEDIA: 
        return Object.assign({}, state, {page: 'SM', media: {data: action.data.item, type: action.data.media, page: action.data.page}})
    case CONTENT_SCROLL:
        return Object.assign({}, state, {perc: action.data})
    case CLEAR_DATA:
        return Object.assign({}, state, initialState);
    default:
      return state;
  }
};

/* Selectors */

// Get showAddPost
// export const getShowAddPost = state => state.app.showAddPost;

// Export Reducer
export default ContentReducer;
