import React, { Component, Suspense } from 'react';
import { Route } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import '../../App.css';
import './mainlayout.css';
/*import '../AppRtl.css';*/
import Header from '../../components/common/header/header';
// import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import Draggable from 'react-draggable';
// import Fab from '@material-ui/core/Fab';
//import SessionTimeout from '../../components/common/SessionTimeout';
import P2P from '../conference/libs/P2P';

class ConfLayout extends Component {

	constructor(props) {
		super(props);

	}
	componentDidMount = () => {

	}

	render() {
		return (
			<div className="homeWrapper">
				<div className="homeContainer">
					{/* <div className="homeColG brdrWLeft homeCol2responsive"> */}
					<div className="homeColG homeCol2responsive">
						<Suspense fallback={<Loading />}>
							<Route exact path={this.props.path} component={this.props.component} />
						</Suspense>
					</div>

				</div>
			</div>
		)
	}
}

export default ConfLayout;