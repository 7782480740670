import { API } from "aws-amplify";

// Export Constants
import {OffLineAPI} from '../../offline-auth/api';
import {config} from '../../config';
export const SET_CONTACTS = 'SET_CONTACTS';
export const ADD_PENDING_CONTACT = 'ADD_PENDING_CONTACT';
export const ADD_CONTACT = 'ADD_CONTACT';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';
export const SET_ERROR = 'SET_ERROR';
export const ONLINE_CONTACTS = 'ONLINE_CONTACTS';
export const SET_LICENSE_DATA = 'SET_LICENSE_DATA';
export const ADD_CHAT = 'ADD_CHAT';
export const CLEAR_READ_COUNT = 'CLEAR_READ_COUNT';
export const SET_READ_COUNT = 'SET_READ_COUNT';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const SET_UNREAD = 'SET_UNREAD';

let _API = config.isOffline ? OffLineAPI : API;

// Export Actions
export function getContacts() {
    return dispatch => {
        return _API.get("insta", "/getcontacts")
            .then(res => {
                console.log("res === ", res);
                if (res.unread) {
                    dispatch(setUnread(res.unread))
                }
                return dispatch(setContacts(res.data));
            })
            .catch(err => {
                console.log("err in handleSearch ", err);
                return dispatch(setContacts([]));
            });
    }
}


export function setContacts(data) {
    let pendingContacts = [];
    let contacts = [];
    if (data && data.length > 0) {
        for (let i in data) {
            let item = data[i];
            item.requeststatus === 'pending' ? pendingContacts.push(item) : contacts.push(item);
        }
        // pendingContacts = data.filter(item => item.requeststatus === 'pending');
        // contacts = data.filter(item => item.requeststatus !== 'pending');
    }
    return {
      type: SET_CONTACTS,
      contacts,
      pendingContacts
    };
}

// Export Actions
export function sendContactRequest(obj) {
    return dispatch => {
        return new Promise((resolve, reject)=> {
            _API.post("insta", "/addcontact", {body: obj.userData})
            .then(res => {
                console.log("res === ", res);
                resolve(dispatch(addPendingContact(obj)));
                return;
            })
            .catch(err => {
                console.log("err in sendContactRequest ", err);
                reject(dispatch(setError(err)));
                return;
            });

        });
    };
}

export function addPendingContact(obj) {
    return {
      type: ADD_PENDING_CONTACT,
      data: [obj]
    };
}

// Export Actions
export function acceptRequest(obj) {
    return dispatch => {
        return new Promise((resolve, reject)=> {
            _API.post("insta", "/acceptcontact", {body: obj})
		    .then(res => {
                // console.log("res === ", res);
                resolve(dispatch(addContact(obj)));
                return;
            })
            .catch(err => {
                console.log("err in acceptRequest ", err);
                reject(dispatch(setError(err)));
                return;
            });
        });
    }
}

export function rejectRequest(obj) {
    return dispatch => {
        return new Promise((resolve, reject)=> {
            _API.post("insta", "/rejectcontact", {body: obj})
		    .then(res => {
                console.log("res === ", res);
                resolve(dispatch(removeContact(obj)));
                return;
            })
            .catch(err => {
                console.log("err in acceptRequest ", err);
                reject(dispatch(setError(err)));
                return;
            });
        });
    }
}

export function removeContact(obj) {
    return {
        type: REMOVE_CONTACT,
        data: obj
    }
}

export function addContact(obj) {
    return {
      type: ADD_CONTACT,
      data: obj
    };
}

export function setError (error) {
    return {
        type: SET_ERROR,
        error
    }
} 

export function getLicenseData () {
    return dispatch => {
        return new Promise((resolve, reject) => {
            _API.get("transaction", `/license`).then(res => {
                // console.log("res == ", res.data);
                if (res && res.status) {
                    dispatch(setLicenseData(res.data));
                    resolve(res.data);
                    return;
                } else {
                    dispatch(setLicenseData([]));
                    resolve([]);
                    return;
                }
            });
        })
    }
}


export function setLicenseData(data) {
    return {
        type: SET_LICENSE_DATA,
        data: data
    }
}


export function saveFile(file) {
    return new Promise((resolve, reject)=> {
        const formData = new FormData();
        formData.append('fileData', file);
        // formData.set('filename', obj.fileName);
        _API.upload("api", "/savefile", formData)
        .then(res => {
            console.log("res === ", res);
            resolve(res);
            return;
        })
        .catch(err => {
            console.log("err in saveFile ", err);
            reject(err);
            return;
        });
    });
}

export function sendChatData(body, signaler) {
    return dispatch => {
        signaler.sendChatMessage(body);
        dispatch(addChat(body));
        return;
    };
}

export function addChat(body) {
    return {
        type: ADD_CHAT,
        data: body
    }
}

export function clearReadCount(id) {
    return {
        type: CLEAR_READ_COUNT,
        id
    }
}

export function setReadCount(uid) {
    return {
        type: SET_READ_COUNT,
        uid
    }
}

export function setUnread(data) {
    return {
        type: SET_UNREAD,
        data
    }
}

export function setSelectedUser(uid) {
    return {
        type: SET_SELECTED_USER,
        uid
    }
}

