import React from "react";
import { Route, Redirect } from "react-router-dom";
import SimulcastRoute from '../containers/confsimulcast/SimulcastRoute';

// function querystring(name, url = window.location.href) {
//     name = name.replace(/[[]]/g, "\\$&");

//     const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
//     const results = regex.exec(url);

//     if (!results) {
//         return null;
//     }
//     if (!results[2]) {
//         return "";
//     }

//     return decodeURIComponent(results[2].replace(/\+/g, " "));
// }


export default  ({ component: C, props: cProps, ...rest }) => {

    // const redirect = querystring("redirect");
    return (
      <Route
        {...rest}
        render={props =>
        (cProps.isAuthenticated !== undefined) &&
        <SimulcastRoute {...props} {...cProps} component={(props) => <C key='conference' {...cProps} {...props} isGuest={true} isHost={false} /> } />
        //: (cProps.isAuthenticated === false ) && <Guest {...props} {...cProps} component={(props) => <C key='conference' {...cProps} {...props} isGuest={true} isHost={false} /> } />
            // : <Redirect
            //     to={redirect === "" || redirect === null ? '/dashboard' : redirect}
            //   />
            }
      />
    );
  };

  //<C {...props} {...cProps} />

  //component={C} 