export const ptData = {
    "locale": {
        "home_instavc_build": "Estabelecer relacionamentos significativos entre organiza\u00e7\u00f5es e funcion\u00e1rios",
        "home_instavc_conference_platform": "As Plataformas de Confer\u00eancia com um\nExperi\u00eancia inigual\u00e1vel para o usu\u00e1rio",
        "home_instavc_start_conference": "INICIAR REUNI\u00c3O | JUNTE-SE \u00c0 REUNI\u00c3O |  REUNI\u00c3O INSTANT\u00c2NEA",
        "home_instavc_Hippa": "HIPAAA ELEG\u00cdVEIS E CONFORMIDADE PCI DSS, SOC, ISO\/EIC 27001, ISO\/EIC 27017, ISO\/EIC 27018, E ISO 9001.",
        "home_instavc_copyrights": "\u00a9 2018 - InstaVC - PeopleLink Unified Communications Pvt. Ltd.",
        "home_instavc_terms_agree": "Eu concordo com os",
        "home_instavc_terms": "Termos e Condi\u00e7\u00f5es",
        "instavc_login": "Entrar no sistema",
        "instavc_login_email": "E-mail",
        "instavc_login_password": "Senha",
        "instavc_login_button": "ENTRAR",
        "instavc_login_forgotpassword": "Esqueceu a senha?",
        "instavc_login_have_account": "N\u00e3o tem uma conta ?",
        "instavc_login_signup": "Cadastre-se",
        "instavc_signup": "Cadastre-se",
        "instavc_signup_firstname": "Nome",
        "instavc_signup_lastname": "Sobrenome",
        "instavc_signup_email": "Email",
        "instavc_signup_password": "Senha",
        "instavc_signup_cpassword": "Confirmar Senha",
        "instavc_signup_button": "CADASTRE-SE",
        "instavc_signup_already_have_account": "J\u00e1 tem uma conta?",
        "instavc_signup_login": "Entrar no sistema",
        "instavc_signup_fname_error": "O primeiro nome deve ser apenas com alfabeto",
        "instavc_signup_lname_error": "O sobrenome deve ser apenas com alfabeto",
        "instavc_signup_email_error": "O e-mail deve estar em um formato adequado",
        "instavc_signup_password_error": "A senha deve ter seis caracteres e combinar uma mai\u00fascula, uma min\u00fascula, um d\u00edgito e um caracter especial",
        "instavc_signup_cpassword_error": "Confirmar senha deve corresponder \u00e0 senha acima",
        "instavc_forgotpassword_link": "N\u00f3s lhe enviaremos um link para o seu e-mail cadastrado. Basta clicar no link e definir sua nova senha",
        "instavc_forgotpassword_email": "Digite seu endere\u00e7o de e-mail cadastrado",
        "instavc_forgotpassword": "Esqueci minha senha",
        "instavc_forgotpassword_button": "ESQUECI MINHA SENHA",
        "instavc_confirmpass_heading": "Crie uma nova senha preenchendo o formul\u00e1rio.",
        "instavc_confirmpass_verificationcode": "Digite o c\u00f3digo de verifica\u00e7\u00e3o",
        "instavc_confirmpass_newpassword": "Digite nova senha",
        "instavc_confirmpass_retypepassword": "Digite a nova senha novamente",
        "instavc_confirmpass_button": "CRIAR NOVA SENHA",
        "instavc_forgotpassword_signin": "Entrar",
        "instavc_forgotpassword_passwordcreated": "Parab\u00e9ns! Sua nova senha foi criada, agora voc\u00ea pode entrar no InstaVC.",
        "instavc_dashboard": "PAINEL DE CONTROLE",
        "instavc_dashboard_schedule": "AGENDA DA REUNI\u00c3O",
        "instavc_dashboard_meetinglist": "REUNI\u00d5ES COMPLETADAS",
        "instavc_dashboard_conferenceplatform": "Nossas solu\u00e7\u00f5es de Confer\u00eancia com uma Experi\u00eancia de Usu\u00e1rio Inigual\u00e1vel",
        "instavc_dashboard_SC_room": "Iniciar reuni\u00e3o",
        "instavc_dashboard_SC_roomlist": "Ao selecionar a sua reuni\u00e3o agendada",
        "instavc_dashboard_startconference": "INICIAR REUNI\u00c3O",
        "instavc_dashboard_JC": "Participe da reuni\u00e3o",
        "instavc_dashboard_JC_ID": "via ID da Confer\u00eancia ou sua agenda",
        "instavc_dashboard_JC_button": "PARTICIPE DA CONFER\u00caNCIA",
        "instavc_dashboard_SC": "Iniciar reuni\u00e3o",
        "instavc_dashboard_SC_p2p": "Ao selecionar uma sala da sua lista",
        "instavc_dashboard_instaconference": "INSTA CONFER\u00caNCIA",
        "instavc_dashboard_buynow": "COMPRE AGORA",
        "instavc_dashboard_myprofile": "Meu Perfil",
        "instavc_dashboard_settings": "Configura\u00e7\u00f5es",
        "instavc_dashboard_myadmin": "Minha Admin",
        "instavc_dashboard_affiliate": "Meu Afiliado",
        "instavc_dashboard_logout": "Sair",
        "conference_myrooms": "Minhas Salas",
        "conference_myrooms_host": "Anfitri\u00e3o",
        "conference_myrooms_roomhost": "Voc\u00ea \u00e9 um anfitri\u00e3o para esta sala.",
        "conference_myrooms_search": "Pesquisar ...",
        "conference_myrooms_hostuser": "Anfitri\u00e3o participante",
        "conference_myrooms_gotopremium": "Compre uma licen\u00e7a",
        "conference_myrooms_buynow": "Compre Agora",
        "conference_myrooms_renewal": "Renova\u00e7\u00e3o",
        "conference_setup": "Configura\u00e7\u00e3o",
        "conference_myrooms_renewnow": "Renovar Agora",
        "conference_myrooms_join": "Participar",
        "conference_myrooms_setup": "Configura\u00e7\u00e3o da Sala",
        "conference_myrooms_sethost_email": "Defina voc\u00ea mesmo como anfitri\u00e3o, ou digite um endere\u00e7o de e-mail de usu\u00e1rio para torn\u00e1-lo como anfitri\u00e3o.",
        "conference_myrooms_selfhost": "Defina-se como um anfitri\u00e3o",
        "conference_myrooms_otherhost": "OU - Se voc\u00ea quiser fazer qualquer usu\u00e1rio como anfitri\u00e3o, digite seu endere\u00e7o de e-mail.",
        "conference_myrooms_email": "Endere\u00e7o de e-mail",
        "conference_myrooms_cancel": "Cancelar",
        "conference_myrooms_apply": "Aplicar",
        "view_schedule_myrooms": "Minhas Salas",
        "view_schedule_search": "Pesquisar ...",
        "view_schedule_schedule": "Hor\u00e1rio",
        "view_schedule_Videorecording": "Grava\u00e7\u00f5es de V\u00eddeo",
        "view_schedule_calender": "Abaixo est\u00e1 o calend\u00e1rio para verificar os hor\u00e1rios desta sala",
        "view_recordings": "Grava\u00e7\u00f5es de V\u00eddeo",
        "view_recordings_search": "Busca",
        "view_recordings_month": "Neste M\u00eas",
        "view_recordings_week": "\u00daltimos 7 dias",
        "view_recordings_today": "Hoje",
        "no_recordings": "Sem Grava\u00e7\u00f5es Ainda!",
        "no_recordings_message": "N\u00e3o h\u00e1 v\u00eddeos aqui no momento.",
        "no_recordings_startmessage": "Quando voc\u00ea come\u00e7ar a gravar voc\u00ea pode assistir todos os seus v\u00eddeos aqui.",
        "stop_recording": "Voc\u00ea quer parar esta grava\u00e7\u00e3o?",
        "stop_recording_agree": "ACORDO",
        "stop_recording_disagree": "DESACORDO",
        "conference_in_use": "Reuni\u00e3o est\u00e1 em andamento",
        "conference_mode": "Modo da reuni\u00e3o",
        "large_conference_mode": "Modo \"Reuni\u00e3o Grande\"",
        "conference_setup_configuration": "Configura\u00e7\u00e3o",
        "conference_setup_generateid": "Nenhuma Reuni\u00e3o Encontrada, Gere Uma!",
        "conference_setup_regenerate": "Regenere",
        "conference_setup_meetingid": "Id de Reuni\u00e3o",
        "conference_setup_camera": "C\u00e2mera HD FaceTime",
        "conference_setup_defaultmicrophone": "Padr\u00e3o - Microfone Interno (integrado)",
        "conference_setup_microphone": "Microfone Interno (integrado)",
        "conference_setup_volume": "Volume",
        "conference_setup_videomode": "Modo V\u00eddeo",
        "conference_setup_forward": "Direto",
        "conference_setup_mix": "Misture",
        "conference_setup_invite": "Convidar",
        "conference_setup_invitepeople": "Convidar Pessoas",
        "conference_setup_invitefriends": "Convide seus amigos e colegas de trabalho para colaborar.",
        "conference_setup_invite_directlink": "Link direto para o sua sala",
        "conference_setup_invite_sharelink": "Compartilhe este link com seus participantes para que eles possam participar de sua reuni\u00e3o",
        "conference_setup_invite_link": "https:\/\/connect.instavc.com\/conference-guest\/eddca0fc7adcdc61a24dabe02597412a36bb96dac7302f3cedb6aac50169d58614eb46b940b556a9\/f",
        "conference_setup_invitebyemail": "Convite por e-mail",
        "conference_setup_invite_enteremail": "Digite o endere\u00e7o de e-mail preferido para convidar seus participantes",
        "conference_setup_invite_emailid": "Endere\u00e7o de e-mail",
        "conference_setup_invite_close": "FECHAR",
        "conference_setup_invite_button": "CONVIDAR",
        "conference_setup_advancesettings": "Configura\u00e7\u00e3o avan\u00e7ada",
        "conference_setup_changeresolution": "Escolha a Resolu\u00e7\u00e3o",
        "conference_setup_lowbandwidth": "Baixa Largura de Banda",
        "conference_setup_poornetwork": "Rede fraca",
        "conference_setup_vga": "VGA - (640x480)",
        "conference_setup_vga_graphics": "Para Resolu\u00e7\u00e3o de V\u00eddeo (VGA)",
        "conference_setup_hd1080": "HD1080p - (1920x1080)",
        "conference_setup_fhd": "Para Resolu\u00e7\u00e3o de V\u00eddeo em Alta Defini\u00e7\u00e3o (Full HD)",
        "conference_setup_4k": "4K - (3840 x 2160)",
        "conference_setup_4k_fhd": "Para Resolu\u00e7\u00e3o de V\u00eddeo em Alta Defini\u00e7\u00e3o (Full HD)",
        "conference_setup_sif": "SIF - (320x240)",
        "conference_setup_sif_vr": "Para Resolu\u00e7\u00e3o de V\u00eddeo SIF",
        "conference_setup_hd720": "HD720p - (1280x720)",
        "conference_setup_hd720_vr": "Para Resolu\u00e7\u00e3o de V\u00eddeo Alta Defini\u00e7\u00e3o (HD)",
        "conference_setup_2k": "2K - (2048\u00d71080)",
        "conference_setup_2k_fhd": "Para Resolu\u00e7\u00e3o de V\u00eddeo em Alta Defini\u00e7\u00e3o (FHD)",
        "conference_setup_broadcastoptions": "Op\u00e7\u00f5es de transmiss\u00e3o",
        "conference_setup_enable": "Ativar",
        "conference_setup_disable": "Desativar",
        "conference_setup_reportquality": "Relatar estat\u00edsticas de qualidade para ajudar a melhorar as chamadas de voz e v\u00eddeo",
        "conference_setup_havetroubleshoot": "Voc\u00ea gostaria de abrir a ferramenta de resolu\u00e7\u00e3o de problemas, ent\u00e3o clique no link aqui",
        "conference_setup_conferencetroubleshoot": "SOLUCIONADOR DE PROBLEMAS",
        "conference_troubleshoot": "Resolu\u00e7\u00e3o de Problemas",
        "conference_troubleshoot_faq": "Perguntas frequentes",
        "conference_troubleshoot_emailreport": "RELAT\u00d3RIO DE E-MAIL",
        "conference_troubleshoot_gotoconference": "IR \u00c0 REUNI\u00c3O",
        "conference_troubleshoot_os": "Sistema Operacional",
        "conference_troubleshoot_version": "vers\u00e3o",
        "conference_troubleshoot_browser": "Navegador",
        "conference_troubleshoot_chromeversion": "Vers\u00e3o Chrome",
        "conference_troubleshoot_privatebrowsing": "Navega\u00e7\u00e3o privada",
        "conference_troubleshoot_displayresolution": "Exibir resolu\u00e7\u00f5es",
        "conference_troubleshoot_aspectratio": "Exibir a rela\u00e7\u00e3o de altura \/ largura",
        "conference_troubleshoot_hasspeakers": "O sistema possui alto-falantes ?",
        "conference_troubleshoot_foundspeaker": "alto-falantes encontrados",
        "conference_troubleshoot_hasmicrophone": "O sistema possui Microfone ?",
        "conference_troubleshoot_foundmicrophone": "Microfones encontrados",
        "conference_troubleshoot_haswebcam": "O sistema tem c\u00e2mera ?",
        "conference_troubleshoot_foundcameras": "Dispositivos de C\u00e2mera encontrados",
        "conference_troubleshoot_webcampermission": "O site tem permiss\u00f5es de webcam ?",
        "conference_troubleshoot_microphonepermission": "O site tem permiss\u00f5es de microfone ?",
        "conference_troubleshoot_getUsermedia": "Navegador permite acessar getUserMedia nesta p\u00e1gina ?",
        "conference_troubleshoot_changeoutput_audiodevice": "Voc\u00ea pode mudar os dispositivos de \u00e1udio de sa\u00edda ?",
        "conference_troubleshoot_changecamera_resolution": "Voc\u00ea pode mudar as resolu\u00e7\u00f5es das c\u00e2meras sem fazer nova solicita\u00e7\u00e3o de reuni\u00e3o ?",
        "conference_troubleshoot_supportwebrtc": "O Browser suporta WebRTC (1.0 ou 1.1)?",
        "conference_troubleshoot_supportortc": "O Navegador suporta ORTC (WebRTC 1.1) ?",
        "conference_troubleshoot_turnserver": "O Servidor TURN \u00e9 alcan\u00e7\u00e1vel?",
        "conference_troubleshoot_turn": "turn:139.59.64.172:443",
        "conference_troubleshoo_coturn": "turn:coturn.instavc.com:443",
        "conference_troubleshoot_replacetracks": "Voc\u00ea pode substituir as pistas sem renegociar os pares?",
        "conference_troubleshoot_record_remoteaudio": "Seu navegador pode gravar \u00e1udio remoto ou processar streaming de \u00e1udio remoto em APO WebAudio",
        "conference_troubleshoot_browsersupports_websocketapi": "Os navegadores suportam WebSockets API",
        "conference_troubleshoot_blockwebsocket": "Seu sistema bloqueou o protocolo WebSockets ou servidor WebSockets n\u00e3o est\u00e1 acess\u00edvel ? N\u00e3o",
        "conference_troubleshoot_supportwebaudioapi": "O Browser Suporta API WebAudio ?",
        "conference_troubleshoot_supportsctp": "O Browser Suporta Canais de Dados SCTP ?",
        "conference_troubleshoot_supportsrtp": "Navegador Suporta Canais de Dados RTP ?",
        "conference_troubleshoot_support_screencapturing": "Esta P\u00e1gina Suporta API de Captura de Tela ?",
        "conference_troubleshoot_support_multimonitor": "O Browser suporta sele\u00e7\u00e3o e captura de telas de qualquer monitor?",
        "conference_troubleshoot_mobiledevice": "\u00e9 um dispositivo m\u00f3vel?",
        "conference_troubleshoot_supportscreencap_canvas": "O Browser Suporta Captura a partir de Tela (Canvas) ?",
        "conference_troubleshoot_supportscreencap_video": "O Browser Suporta Captura de Tela a partir de V\u00eddeo ?",
        "conference_troubleshoot_supportpromisses": "O Browser Suporta processamento ass\u00edncrono (JS Promises)?",
        "conference_troubleshoot_ipaddress": "Clique para mostrar os endere\u00e7os IP",
        "conference_troubleshoot_stunserver": "O STUN Server \u00e9 alcan\u00e7\u00e1vel?",
        "conference_troubleshoot_cancel": "CANCELAR",
        "instavc_conference_revoke_fromhost": "Revoga\u00e7\u00e3o do anfitri\u00e3o",
        "instavc_conference_startvideoconf_button": "INICIAR VIDEOCONFER\u00caNCIA",
        "instavc_conference_loading": "carregando sua tela por favor aguarde",
        "instavc_conference_authenticating": "Autentica\u00e7\u00e3o no Servidor Instavc ....",
        "instavc_conference_joinconference": "Participe da Reuni\u00e3o",
        "instavc_conference_publishyourvideo": "Publicando seu V\u00eddeo",
        "instavc_conference_publishyouraudio": "Publicando seu \u00c1udio",
        "instavc_conference_settings": "Configura\u00e7\u00f5es",
        "instavc_conference_setting": "Configura\u00e7\u00f5es da Reuni\u00e3o",
        "instavc_conference_setting_source": "FONTE",
        "instavc_conference_setting_camera": "C\u00e2mera",
        "instavc_conference_audioinputs": "Entradas de \u00c1udio",
        "instavc_conference_setting_cancel": "CANCELAR",
        "instavc_conference_setting_apply": "APLICAR",
        "instavc_conference_setting_resolution": "RESOLU\u00c7\u00c3O",
        "instavc_conference_setting_qr": "QR(BETA)",
        "instavc_conference_whiteboard": "Quadro branco",
        "instavc_conference_screenshare": "Compartilhar tela",
        "instavc_conference_presenter": "Apresentador",
        "instavc_conference_stopshare": "Parar Partilha",
        "instavc_conf_install_Screenshare": "Instalar ScreenShare",
        "instavc_conference_videosync": "Sincroniza\u00e7\u00e3o de V\u00eddeo",
        "instavc_conference_videourl": "URL do V\u00eddeo",
        "instavc_conference_enter_videourl": "Digite a URL do V\u00eddeo",
        "instavc_conference_videourl_note": "Nota: Somente os participantes ativos podem assistir a este v\u00eddeo, os inscritos atrasados n\u00e3o podem ver o v\u00eddeo.",
        "instavc_conf_recordings": "Grava\u00e7\u00e3o",
        "instavc_conference_sharecontent": "Compartilhe sua tela",
        "instavc_conference_sharecontent_screen": "O connect.instavc.com quer compartilhar o conte\u00fado da sua tela. Escolha o que voc\u00ea gostaria de compartilhar",
        "instavc_conference_shareentire_screen": "Sua Tela Inteira",
        "instavc_conference_sharescreen_applicationwindow": "Janela de Aplica\u00e7\u00e3o",
        "instavc_conference_sharescreen_chrometab": "Separador Chrome",
        "instavc_conference_sharescreen_cancel": "Cancelar",
        "instavc_conference_sharescreen_share": "Compartilhar",
        "instavc_conf_sharescreen": "Voc\u00ea est\u00e1 compartilhando sua tela!",
        "instavc_conf_share_otherparticipants": "Outros participantes podem ver aplica\u00e7\u00f5es e documenta\u00e7\u00f5es em sua tela",
        "instavc_stopsharing": "Pare de Compartilhar",
        "instavc_conference_chat": "Bate-Papo",
        "instavc_conference_additional_camera": "C\u00e2mera adicional",
        "instavc_conference_sharescreen_groupchat": "Bate-papo em Grupo",
        "instavc_conference_sharescreen_GC": "GC",
        "instavc_conference_entermessage": "Diga Algo!",
        "instavc_conference_adddevice": "Adicionar Dispositivos",
        "instavc_conference_adddevice_addcamera": "Adicionar C\u00e2mera",
        "instavc_conference_adddevice_source": "FONTE",
        "instavc_conference_adddevice_camera": "C\u00e2mera",
        "instavc_conference_adddevice_cameraname": "C\u00e2mera HD FaceTime",
        "instavc_conference_adddevice_close": "FECHAR",
        "instavc_conference_adddevice_publish": "PUBLICAR",
        "instavc_conference_info": "info",
        "instavc_conference_questions": "D\u00favidas",
        "instavc_conference_askquestions": "Fa\u00e7a perguntas",
        "instavc_conference_info_meetingid": "ID da reuni\u00e3o pessoal",
        "instavc_conference_fullscreen": "Tela Cheia",
        "conference_setup_invitepeoplel": "Convidar Pessoas",
        "conference_setup_invite_linktojoin": "https:\/\/connect.instavc.com\/conference-guest\/93ac2135d88727ca29061b4b6bf9477bc8f285a8121ff580649512abad353fbc6751a134d56438f7\/f",
        "conference_setup_invite_byemail": "Convite por e-mail",
        "instavc_conference_participants": "Participantes",
        "instavc_conference_participants_search": "Pesquisar ...",
        "instavc_conference_participant": "Participante",
        "instavc_conference_participants_muteall": "Meter todos em mudo",
        "instavc_conference_participants_unmuteall": "Remover o mute de todos",
        "instavc_conference": "Reuni\u00e3o",
        "instavc_conf_more": "Mais",
        "instavc_conference_picturemode": "Este v\u00eddeo est\u00e1 sendo reproduzido no modo Picture-in-Picture",
        "instavc_conference_mediastreams_stats": "Estat\u00edsticas de fluxo de m\u00eddia",
        "instavc_conference_mediastreams_audio": "\u00c1udio",
        "instavc_conference_mediastreams_audio_send": "Enviar",
        "instavc_conference_mediastreams_audio_codec": "Codec",
        "instavc_conference_mediastreams_audio_rates": "Taxa(Kbps)",
        "instavc_conference_mediastreams_audio_lost": "Perdido",
        "instavc_conference_mediastreams_audio_fraction": "Fra\u00e7\u00e3o",
        "instavc_conference_mediastreams_video": "V\u00eddeo",
        "instavc_conference_mediastreams_video_send": "Enviar",
        "instavc_conference_mediastreams_video_codec": "Codec",
        "instavc_conference_mediastreams_video_rates": "Taxa(Kbps)",
        "instavc_conference_mediastreams_video_lost": "Perdido",
        "instavc_conference_mediastreams_video_fraction": "Fra\u00e7\u00e3o",
        "instavc_conference_resolution": "Resolu\u00e7\u00e3o",
        "instavc_conference_framerate": "Frequ\u00eancia de imagens",
        "instavc_conference_nack": "Nack",
        "instavc_conference_pli": "PLI",
        "instavc_conference_bw": "Dispon\u00edvel BW",
        "instavc_conference_audiomuted": "\u00c1udio Mudo",
        "instavc_conference_microphonemuted": "Seu Microfone est\u00e1 mudo",
        "join_conference_join_meetinginvitation": "Convites para Reuni\u00f5es",
        "join_conference_search": "Pesquisar ...",
        "join_conference_meeting details": "nome da reuni\u00e3o\nConvidado por:\nnome do convidado",
        "join_conference_join": "PARTICIPAR",
        "join_conference_nomeetings": "Sem Reuni\u00f5es",
        "join_conference_noupcomingmeetings": "Voc\u00ea n\u00e3o tem convite para reuni\u00e3o",
        "insta_conference_mycontacts": "Meus Contatos",
        "insta_conference_search": "Pesquisar ...",
        "insta_conference_typemessage": "Digite uma mensagem",
        "insta_conference_addcontact": "Adicionar Contato",
        "insta_conference_enteremail": "Digite o e-mail",
        "insta_conference_add": "Adicionar",
        "insta_conference_nouserfound": "N\u00e3o foi encontrado nenhum usu\u00e1rio com este endere\u00e7o de e-mail",
        "instavc_conf_callnot_allowed": "Voc\u00ea n\u00e3o pode ligar para outros usu\u00e1rios quando j\u00e1 est\u00e1 em uma chamada",
        "insta_conference_pendingapproval": "Pendente de Aprova\u00e7\u00e3o",
        "insta_conference_outgoingvideocall": "V\u00eddeo Chamada de Sa\u00edda",
        "insta_conference_calling": "Chamando ....",
        "insta_conference_incomingvideocall": "V\u00eddeo chamada de entrada",
        "insta_conference_calldecline": "Recusar",
        "insta_conference_callaccept": "Aceitar",
        "insta_conference_instavcalert": "O connect.instavc.com quer permitir",
        "insta_conference_usemicrophone": "Use seu microfone",
        "insta_conference_usecamera": "Use sua c\u00e2mera",
        "insta_conference_blockdevices": "Bloqueio",
        "insta_conference_allowdevices": "Permitir",
        "instavc_schedulemeeting_createmeeting": "Criar Nova Reuni\u00e3o \/ Editar",
        "instavc_schedulewebinar_createmeeting": "Criar Novo Webinar \/ Editar",
        "instavc_schedulemeeting_meetingname": "D\u00ea um nome \u00e0 sua reuni\u00e3o",
        "instavc_schedulemeeting_selectnames_tomeeting": "Selecione uma sala para a sua reuni\u00e3o",
        "instavc_schedulemeeting_setduration": "Dura\u00e7\u00e3o Definida",
        "instavc_schedulemeeting_15min": "15 Minutos",
        "instavc_schedulemeeting_30min": "30 Minutos",
        "instavc_schedulemeeting_60min": "60 Minutos",
        "instavc_schedulemeeting_setcustom": "Conjunto Personalizado",
        "instavc_schedulemeeting_customduration": "Dura\u00e7\u00e3o Personalizada",
        "instavc_schedulemeeting_minutes": "Minutos",
        "instavc_schedulemeeting_setconfpassword": "Defina sua senha para a confer\u00eancia",
        "instavc_schedulemeeting_typepassword": "Digite a senha para sua reuni\u00e3o",
        "instavc_schedulemeeting_conferencecolor": "D\u00ea cor ao \u00edcone da sua reuni\u00e3o",
        "instavc_schedulemeeting_invitepeople": "Convide pessoas para esta reuni\u00e3o",
        "instavc_schedulemeeting_search": "Pesquisar ...",
        "instavc_schedulemeeting_SM": "SM",
        "instvc_SM_message": "O n\u00famero de usu\u00e1rios com a licen\u00e7a de usu\u00e1rio Autorizado ultrapassou o limite da licen\u00e7a. Se voc\u00ea quiser convidar mais pessoas, voc\u00ea pode",
        "instavc_SM_message_href": "Compre uma licen\u00e7a",
        "instavc_schedulemeeting_notify": "Notifique as pessoas selecionadas sobre esta reuni\u00e3o",
        "instavc_schedulemeeting_addreminder": "Adicionar Lembretes",
        "instavc_schedulemeeting_add": "ADICIONAR",
        "instavc_schedulemeeting_addmeetingreminder": "Adicionar Lembrete de Reuni\u00e3o",
        "instavc_schedulemeeting_adddatetime": "Selecionador de data , selecionador de hora",
        "instavc_schedulemeeting_cancel": "CANCELAR",
        "instavc_schedulemeeting_apply": "APLICAR",
        "instavc_schedulemeeting_name": "Por favor, digite o nome da reuni\u00e3o",
        "instavc_schedulemeeting_selectroom": "Por favor, selecione a sala",
        "instavc_schedulemeeting_selectduration": "Por favor selecione a dura\u00e7\u00e3o",
        "instavc_recuring_schedule": "Planifica\u00e7\u00e3o Recorrente",
        "instavc_schedulemeeting_nothinginreminders": "Nada em Lembretes",
        "instavc_schedulemeeting_createbutton": "CRIAR",
        "instavc_schedulemeeting_createdsuccessfully": "Agendamento Criado com Sucesso",
        "instavc_meetinglist_calender": "CALEND\u00c1RIO",
        "instavc_meetinglist_selecteddates": "Suas datas selecionadas",
        "instavc_meetinglist_schedulecreted": "Agendamentos criados",
        "instavc_meetinglist_pastschedule": "Agenda passada",
        "instavc_meetinglist": "Lista de Reuni\u00f5es",
        "instavc_meetinglist_meetingdetils": "Nome da Reuni\u00e3o, Hora da Reuni\u00e3o, Organizada por",
        "instavc_meetinglist_search": "Pesquisar ...",
        "instavc_meetinglist_deleterecuring": "Apagar Agendamento Recorrente",
        "instavc_meetinglist_alow_deleterecuring": "Voc\u00ea gostaria de excluir apenas os hor\u00e1rios atuais, ou todos os hor\u00e1rios na recorr\u00eancia?",
        "instavc_meetinglist_schedule_series": "Todos os outros agendamentos da s\u00e9rie permanecer\u00e3o:",
        "instavc_meetinglist_schedule_seriesdelete": "Todos os agendamentos da s\u00e9rie ser\u00e3o exclu\u00eddos:",
        "instavc_meetinglist_allrecuringsch": "Todos os Agendamentos Recorrentes",
        "instavc_meetinglist_cancelchanges": "Cancelar Altera\u00e7\u00f5es",
        "instavc_meetinglist_noschedules": "Sem Agendamentos",
        "instavc_ml_schedulemessage": "Ainda n\u00e3o foi criada uma agenda de reuni\u00f5es!\n Crie sua agenda clicando no bot\u00e3o abaixo.",
        "instavc_create_schedule": "Agendar uma reuni\u00e3o",
        "instavc_meetinglist_currentschedule": "Agendamento Atual",
        "instavc_meetinglist_join": "PARTICIPAR",
        "instavc_meetinglist_buynow": "COMPRE AGORA",
        "instavc_dashboard_chooseplan": "Escolha um plano",
        "instavc_package_smbplan": "Planos PME",
        "instavc_package_smbplan_host": "(Licen\u00e7a Baseada no Anfitri\u00e3o)",
        "instavc_package_smbplandescription": "Um quarto pode ter apenas 1 anfitri\u00e3o de cada vez. Um comprador pode definir ou alterar a conta do anfitri\u00e3o, que pode controlar o quarto. Os acessos ao anfitri\u00e3o n\u00e3o podem ser compartilhados durante a chamada. Todos os outros usu\u00e1rios n\u00e3o podem interagir at\u00e9 que o anfitri\u00e3o ingresse na reuni\u00e3o.",
        "instavc_package_basic": "B\u00e1sico",
        "instavc_package_hostuser_smb": "1 Anfitri\u00e3o e",
        "instavc_package_hostuser_smb_users": "Usu\u00e1rios",
        "instavc_package_currency": "$",
        "instavc_package_permonth": "\/ m\u00eas",
        "instavc_package_bill_smb": "Faturamento Anual",
        "instavc_package_include_smb": "Este pacote inclui com",
        "instavc_package_specifictions_Whiteboard": "Quadro branco",
        "instavc_package_specifictions_Screen": "Compartilhar tela",
        "instavc_package_specifictions_nChat": "Bate-Papo",
        "instavc_package_specifictions_Recording": "Grava\u00e7\u00e3o",
        "instavc_package_specifictions_PresenterMode": "Modo Apresentador",
        "instavc_package_buynowbutton": "COMPRE AGORA",
        "instavc_package_fullfeatures_smb": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_billinginfo": "Informa\u00e7\u00f5es de Faturamento",
        "instavc_package_billinginfo_name": "Nome",
        "instavc_package_billinginfo_orginazation": "Empresa",
        "instavc_package_biilinginfo_gstin": "GSTIN",
        "instavc_package_billinginfo_save": "SALVAR",
        "instavc_package_billinginfo_edit": "EDITAR",
        "instavc_package_billingdetails": "Detalhes de Faturamento",
        "instavc_package_billingdetails_firstname": "Nome",
        "instavc_package_billingdetails_lastname": "Sobrenome",
        "instavc_package_billingdetails_address": "Endere\u00e7o",
        "instavc_package_billingdetails_city": "Cidade",
        "instavc_package_billingdetails_zip": "CEP",
        "instavc_package_billingdetails_cancel": "CANCELAR",
        "instavc_package_billingdetails_save": "SALVAR",
        "instavc_package_shippinginfo": "Informa\u00e7\u00f5es de expedi\u00e7\u00e3o",
        "instavc_package_shippinginfo_edit": "EDITAR",
        "instavc_package_shippingdetails": "Detalhes de Expedi\u00e7\u00e3o",
        "instavc_package_shippingdetails_firstname": "Nome",
        "instavc_package_shippingdetails_lastname": "Sobrenome",
        "instavc_package_shippingdetails_address": "Endere\u00e7o",
        "instavc_package_shippingdetails_city": "Cidade",
        "instavc_package_shippingdetails_zip": "CEP",
        "instavc_package_shippingdetails_cancel": "CANCELAR",
        "instavc_package_shippingdetails_save": "SALVAR",
        "instavc_package_cartsummary": "Resumo do Carrinho",
        "instavc_package_hostbase": "Host Based License (Nome da licen\u00e7a)",
        "instavc_package_smbplan_basic": "Plano PME - B\u00e1sico",
        "instavc_package_plandetails": "$ 144.02",
        "instavc_package_term": "Termo",
        "instavc_package_1year": "1 Ano",
        "instavc_package_2years": "2 Anos",
        "instavc_package_3years": "3 Anos",
        "instavc_package_4years": "4 Anos",
        "instavc_package_5years": "5 Anos",
        "instavc_package_subtotal": "Subtotal",
        "instavc_package_taxes": "Impostos e taxas (GST +18%)",
        "instavc_package_launchoffer": "OFERTALANCAMENTO",
        "instavc_package_promo": "Promo\u00e7\u00e3o aplicada com sucesso",
        "instavc_package_remove": "Remover",
        "instavc_package_havepromocode": "Tem um C\u00f3digo Promocional ? Cadastre-se aqui",
        "instavc_package_entercouponcode": "Por favor, digite o c\u00f3digo do cupom v\u00e1lido",
        "instavc_package_total": "Total",
        "instavc_package_offerdisclaimer": "Ver Termos de Responsabilidades sobre a oferta",
        "instavc_package_paynow": "PAGUE AGORA",
        "instavc_package_payment_instavclicense": "PeopleLink Unified Communication\nPagamento da Licen\u00e7a InstaVC",
        "instavc_package_phone": "Telefone",
        "instavc_package_entervalid_contactnumber": "Por favor, digite o n\u00famero de contato v\u00e1lido",
        "instavc_package_email": "Email",
        "instavc_package_enteremail": "Por favor, digite uma identifica\u00e7\u00e3o emai v\u00e1lida. Exemplo : you@example.com",
        "instavc_package_paybycard": "PAGUE POR CART\u00c3O",
        "instavc_package_securedbyrazorpay": "Assegurada por Razorpay",
        "instavc_package_card": "CART\u00c3O",
        "instavc_package_enterotp": "Digite OTP enviado em xxxxxxxxx\npara acessar os Cart\u00f5es Salvos",
        "instavc_package_resendotp": "Reenviar OTP",
        "instavc_package_skipsavedcards": "ignorar Cart\u00f5es Salvos",
        "instavc_package_enter_otp": "Por favor, entre na otp",
        "instavc_package_skipthesavedcards": "Ignorar os cart\u00f5es salvos",
        "instavc_package_cardnumber": "N\u00famero do Cart\u00e3o",
        "instavc_package_entervalid_cardnumber": "Por favor, digite um n\u00famero de cart\u00e3o v\u00e1lido",
        "instavc_package_expiry": "Validade",
        "instavc_package_cardholdername": "Nome do portador do cart\u00e3o",
        "instavc_package_cvv": "CVV",
        "instavc_package_3digitcode": "\u00c9 um c\u00f3digo de 3 d\u00edgitos impresso no verso do seu cart\u00e3o",
        "instavc_package_pay": "PAGAR",
        "instavc_package_remembercard": "Lembrar cart\u00e3o",
        "instavc_package_medium": "M\u00e9dio",
        "instavc_package_hostuser_medium": "1 Anfitri\u00e3o e 50 Usu\u00e1rios",
        "instavc_package_month_medium": "$15\/m\u00eas",
        "instavc_package_bill_medium": "Faturamento Anual + GST 18%",
        "instavc_package_include_medium": "Este pacote inclui com",
        "instavc_package_specifictions_medium": "Quadro branco\nCompartilhar tela\nBate-papo\nGrava\u00e7\u00e3o\nModo Apresentador",
        "instavc_package_buynow_medium": "COMPRE AGORA",
        "instavc_package_fullfeatures_medium": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_advanced": "Avan\u00e7ado",
        "instavc_package_hostuser_advanced": "1 Anfitri\u00e3o e 100 Usu\u00e1rios",
        "instavc_package_month_advanced": "US$ 16\/ m\u00eas",
        "instavc_package_bill_advanced": "Faturamento Anual + GST 18%",
        "instavc_package_include_advanced": "Este pacote inclui com",
        "instavc_package_specifictions_advanced": "Quadro branco\nCompartilhar tela\nBate-papo\nGrava\u00e7\u00e3o\nModo Apresentador",
        "instavc_package_buynow_advanced": "COMPRE AGORA",
        "instavc_package_fullfeatures_advanced": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_virtualmcu": "Licen\u00e7a de MCU Virtual",
        "instavc_package_virtualmcu_room": "(Licen\u00e7a baseada na sala)",
        "instavc_package_vmcudetails": "Um quarto pode ter apenas 1 anfitri\u00e3o de cada vez. Um comprador pode definir ou alterar a conta do anfitri\u00e3o, que pode controlar o quarto. Os acessos ao anfitri\u00e3o n\u00e3o podem ser compartilhados durante a chamada. Todos os outros usu\u00e1rios n\u00e3o podem interagir at\u00e9 que o anfitri\u00e3o ingresse na reuni\u00e3o.",
        "instavc_package_instavc": "InstaVC",
        "instavc_package_silver": "Prata",
        "instavc_package_userrooms_silver_users": "Usu\u00e1rios",
        "instavc_package_userrooms_silver_rooms": "Salas",
        "instavc_package_month_silver": "$281\/ m\u00eas",
        "instavc_package_bill_silver": "Faturamento Anual + GST 18%",
        "instavc_package_include_silver": "Este pacote inclui com",
        "instavc_package_specifictions_silver": "Quadro branco\nCompartilhar tela\nBate-papo\nGrava\u00e7\u00e3o\nModo Apresentador",
        "instavc_package_buynow_silver": "COMPRE AGORA",
        "instavc_package_fullfeatures_silver": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_gold": "Ouro",
        "instavc_package_userrooms_gold": "200 Usu\u00e1rios - 30 Salas",
        "instavc_package_month_gold": "$467\/ m\u00eas",
        "instavc_package_bill_gold": "Faturamento Anual + GST 18%",
        "instavc_package_include_gold": "Este pacote inclui com",
        "instavc_package_specifictions_gold": "Quadro branco\nCompartilhar tela\nBate-papo\nGrava\u00e7\u00e3o\nModo Apresentador",
        "instavc_package_buynow_gold": "COMPRE AGORA",
        "instavc_package_fullfeatures_gold": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_platinum": "Platina",
        "instavc_package_userrooms_platinum": "500 Usu\u00e1rios - 50 Salas",
        "instavc_package_month_platinum": "933 $\/ m\u00eas",
        "instavc_package_bill_platinum": "Faturamento Anual + GST 18%",
        "instavc_package_include_platinum": "Este pacote inclui com",
        "instavc_package_specifictions_platinum": "Quadro branco\nCompartilhar tela\nBate-papo\nGrava\u00e7\u00e3o\nModo Apresentador",
        "instavc_package_buynow_platinum": "COMPRE AGORA",
        "instavc_package_fullfeatures_platinum": "CARACTER\u00cdSTICAS COMPLETAS",
        "instavc_package_legacybaselicense": "Antiga Licen\u00e7a",
        "instavc_package_licensebased_ports": "Licen\u00e7a com base no n\u00famero de portas",
        "instavc_package_hardwareendpoint": "InstaVC Com Terminal Hardware",
        "instavc_package_webrtcconf_legacysupport": "Confer\u00eancia WebRTC com Apoios Legados",
        "instavc_package_sip": "Suporte SIP \/ BFCP",
        "instavc_package_chh323": "Suporte ChH323 \/ H.239",
        "instavc_package_contactus": "FALE CONOSCO",
        "instavc_myprofile_dashboard": "Painel de controle",
        "instavc_myprofile_settings": "Configura\u00e7\u00f5es",
        "instavc_myprofile": "MEU PERFIL",
        "instavc_myprofile_personaldetail": "Dados Pessoais",
        "instavc_myprofile_personaldetails_name": "Nome",
        "instavc_myprofile_personaldetails_email": "Email",
        "instavc_myprofile_personaldetails_phoneno": "Num. Telefone",
        "instavc_myprofile_personaldetails_address": "Endere\u00e7o",
        "instavc_myprofile_personaldetails_city": "Cidade",
        "instavc_myprofile_edit": "Editar",
        "instavc_myprofile_personaldetails": "Dados Pessoais",
        "instavc_myprofile_firstname": "Nome",
        "instavc_myprofile_lastname": "Sobrenome",
        "instavc_myprofile_email": "Email",
        "instavc_myprofile_phoneno": "Num. Telefone",
        "instavc_myprofile_cancel": "CANCELAR",
        "instavc_myprofile_save": "SALVAR",
        "instavc_myprofile_professionaldetails": "Detalhes Profissionais",
        "instavc_myprofile_position": "Posi\u00e7\u00e3o",
        "instavc_myprofile_professionaldetails_email": "Email",
        "instavc_myprofile_professionaldetails_phoneno": "Num. Telefone",
        "instavc_myprofile_professionaldetails_address": "Endere\u00e7o",
        "instavc_myprofile_professionaldetails_branch": "Filial",
        "instavc_myprofile_professionaldetails_edit": "Editar",
        "instavc_myprofile_officialdetails": "Detalhes Oficiais",
        "instavc_myprofile_officialdetails_position": "Posi\u00e7\u00e3o",
        "instavc_myprofile_officialdetails_email": "Email",
        "instavc_myprofile_officialdetails_phoneno": "Num. Telefone",
        "instavc_myprofile_officialdetails_branch": "Filial",
        "instavc_myprofile_officialdetails_address": "Endere\u00e7o",
        "instavc_myprofile_officialdetails_cancel": "CANCELAR",
        "instavc_myprofile_officialdetails_save": "SALVAR",
        "instavc_myprofile_contactinfo": "INFORMA\u00c7\u00d5ES DE CONTATO",
        "instavc_myprofile_contactinformation": "Informa\u00e7\u00f5es de Contato",
        "instavc_myprofile_add": "Adicionar",
        "instavc_myprofile_contactinformation_address": "Endere\u00e7o",
        "instavc_myprofile_contactinformation_city": "Cidade \/ Cidade",
        "instavc_myprofile_contactinformation_zip": "CEP",
        "instavc_myprofile_contactinformation_landmark": "Ponto de refer\u00eancia",
        "instavc_myprofile_contactinformation_cancel": "CANCELAR",
        "instavc_myprofile_contactinformation_save": "Salvar",
        "instavc_myprofile_address": "Endere\u00e7o",
        "instavc_myprofile_landmark": "Ponto de refer\u00eancia",
        "instavc_myprofile_delete": "Alerta",
        "instavc_myprofile_deletealert": "Voc\u00ea tem certeza que quer Excluir isto?",
        "instavc_myprofile_disagree": "DESACORDO",
        "instavc_myprofile_agree": "ACORDO",
        "instavc_myprofile_websitelinks": "Link do site",
        "instavc_myprofile_website_add": "Adicionar",
        "instavc_myprofile_website": "Site",
        "instavc_myprofile_websiteexample": "https:\/\/www.google.com",
        "instavc_myprofile_website_cancel": "Cancelar",
        "instavc_myprofile_website_save": "Salvar",
        "instavc_myprofile_sociallinks": "Links Sociais",
        "instavc_myprofile_sociallinks_add": "ADICIONAR",
        "instavc_myprofile_sociallink": "Links Sociais",
        "instavc_myprofile_sociallinks_example": "https:\/\/www.facebook.com\/xxx",
        "instavc_myprofile_sociallink_cancel": "CANCELAR",
        "instavc_myprofile_sociallink_save": "SALVAR",
        "instavc_myprofile_basicinfo": "Informa\u00e7\u00f5es b\u00e1sicas",
        "instavc_myprofile_basicinfo_add": "Adicionar",
        "instavc_myprofile_basicinfo_dob": "Data de nascimento",
        "instavc_myprofile_basicinfo_gender": "G\u00eanero",
        "instavc_myprofile_basicinformation": "Informa\u00e7\u00f5es b\u00e1sicas",
        "instavc_myprofile_basicinformation_dob": "Data de Nascimento",
        "instavc_myprofile_basicinformation_gender": "G\u00eanero",
        "instavc_myprofile_basicinformation_cancel": "Cancelar",
        "instavc_myprofile_basicinformation_save": "Salvar",
        "instavc_myprofile_basicinformation_deletealert": "Alerta\nVoc\u00ea tem certeza que quer Excluir isto?",
        "instavc_myprofile_basicinformation_disagree": "DESACORDO",
        "instavc_myprofile_basicinformation_agree": "ACORDO",
        "instavc_myprofile_uploadnewavatar": "CARREGAR NOVO AVATAR",
        "instavc_myprofile_cropimage": "Cortar a imagem",
        "instavc_myprofile_image_cancel": "Cancelar",
        "instavc_myprofile_image_save": "Salvar",
        "instavc_myprofile_locale": "LOCAL",
        "instavc_myprofile_localesettings": "Configura\u00e7\u00f5es Locais",
        "instavc_myprofile_preferredlanguage": "Idioma Preferido :",
        "instavc_myprofile_language": "Ingl\u00eas",
        "instavc_settings": "Configura\u00e7\u00f5es",
        "instavc_settings_changepassword": "ALTERAR SENHA",
        "instavc_settings_changepass": "Alterar Senha",
        "instavc_settings_currentpassword": "Tipo Senha atual",
        "instavc_settings_newpassword": "Digite Nova Senha",
        "instavc_settings_changepassword_cancel": "CANCELAR",
        "instavc_settings_changepassword_save": "SALVAR",
        "instavc_settings_enter_currentpassword": "Por favor, digite a senha atual",
        "instavc_settings_enter_newpassword": "Por favor insira a nova senha",
        "instavc_settings_help": "Ajuda",
        "instavc_settings_affiliate": "Seja Afiliado",
        "instavc_settings_support": "Centro de Apoio",
        "instavc_settings_newticket": "Abra um Novo Ticket",
        "instavc_settings_ticketstatus": "Verificar status do ingresso",
        "instavc_settings_troubleshoot": "Resolu\u00e7\u00e3o de Problemas",
        "instavc_legacy_dailout": "Disque para fora",
        "instavc_legacy_stats": "Estat\u00edsticas",
        "instavc_legacy_dail_in_detail": "Detalhes de chamadas",
        "instavc_legacy_h323_endpoint": "Terminal H.323",
        "instavc_legacy_sip_endpoint": "Teminal SIP",
        "instavc_admin_users": "Usu\u00e1rios",
        "instavc_admin_username": "Nome de usu\u00e1rio",
        "instavc_admin_user_accountstatus": "Status da Conta",
        "instavc_admin_user_confirmed": "Confirmado",
        "instavc_admin_user_unconfirmed": "N\u00e3o confirmado",
        "instavc_admin_user_resetall": "Repor Tudo",
        "instavc_admin_user_sync": "Sincro",
        "instavc_admin_user_status": "Status",
        "instavc_admin_user_phonenumber": "Telefone de Contato",
        "instavc_admin_user_created": "Criado",
        "instavc_admin_user_all": "Todos",
        "instavc_admin_groups": "Grupos",
        "instavc_admin_addmore": "Adicionar Mais",
        "instavc_admin_group_peoplelink": "Queens, GOLIVE, PeopleLink",
        "instavc_admin_select_plan": "Selecione o plano",
        "instavc_admin_lastmodified": "\u00daltima Modifica\u00e7\u00e3o",
        "instavc_admin_email_verified": "E-mail Verificado",
        "instavc_admin_verified": "Verificado",
        "instavc_admin_Unverified": "N\u00e3o verificado",
        "instavc_admin_licenses": "Licen\u00e7as",
        "instavc_admin_addlicense": "Adicionar Licen\u00e7as",
        "instavc_admin_defaultroom": "Sala por Defeito",
        "instavc_admin_hostlicense": "Licen\u00e7a Baseada no Anfitri\u00e3o",
        "instavc_admin_roomlicense": "Licen\u00e7a de baseada em  Sala",
        "instavc_admin_virtualmcu": "Licen\u00e7a Virtual MCU",
        "instavc_admin_enterprise": "Licen\u00e7a Empresarial",
        "instavc_mcuserver": "Servidor MCU",
        "instavc_mculink": "https:\/\/awsmcu.instavc.com",
        "instavc_admin_expirydate": "Data de Validade",
        "instavc_admin_settings": "Configura\u00e7\u00f5es",
        "instavc_default_offer": "Oferta Padr\u00e3o para Indica\u00e7\u00e3o",
        "instavc_enter_coupon_code": "Digite o c\u00f3digo do cupom",
        "instavc_current_partshare": "Participa\u00e7\u00e3o Atual do Parceiro",
        "instavc_share_value": "Valor da A\u00e7\u00e3o",
        "instavc_affliate_share": "Participa\u00e7\u00e3o Afiliada atual",
        "instavc_enter_share": "Digite o valor da comiss\u00e3o (%)",
        "instavc_roomname": "Nome da sala",
        "instavc_room_ten": "Dez",
        "instavc_room_twenty": "Vinte",
        "instavc_room_thirty": "Trinta",
        "instavc_room_hostbased": "Baseado no Anfitri\u00e3o",
        "instavc_virtualmcu": "MCU Virtual",
        "instavc_rooms_enterprises": "Empresa",
        "instavc_roomid": "Identifica\u00e7\u00e3o da sala",
        "instavc_roomtype": "Tipo de Sala",
        "instavc_room_options": "Op\u00e7\u00f5es de Sala",
        "instavc_commercialtype": "Tipo Comercial",
        "instavc_free": "Gr\u00e1tis",
        "instavc_poc": "POC",
        "instavc_commercial": "Comercial",
        "instavc_createdAt": "Criado em",
        "instavc_useraccess": "Acesso do usu\u00e1rio",
        "instavc_peoplelinkroom": "Sala Peoplelink",
        "instavc_inputlimit": "Limite de entrada",
        "instavc_participant_limit": "Limite de participantes",
        "instavc_adduser": "Adicionar usu\u00e1rio",
        "instavc_searchbyname": "Busca por nome",
        "instavc_manage_plans": "Gerenciar Planos\/Produtos",
        "instavc_new_plans": "Novo Plano\/Produto",
        "instavc_allplan_list": "Lista de todos os planos",
        "instavc_sortby": "Ordenar por",
        "instavc_active": "Ativo",
        "instavc_inactive": "Inativo",
        "instavc_create_plan": "Criar Novo Plano",
        "instavc_plan_name": "Nome do Plano",
        "instavc_enteramount": "Digite o valor (M\u00ednimo: INR 100)",
        "instavc_select_product": "Selecione o produto",
        "instavc_host_room": "1 Anfitri\u00e3o \/ Sala",
        "instavc_multiroom": "Multi Anfitri\u00e3o \/ Sala",
        "instavc_legacyrooms": "Sala de tipo antigo",
        "instavc_number_hosts": "N\u00famero de Anfitri\u00f5es",
        "instavc_number_participants": "N\u00famero de Participantes",
        "instavc_number_room": "N\u00famero de Salas",
        "instavc_subscriptiom_period": "Per\u00edodo de Assinatura",
        "instavc_sub_yearly": "Anualmente",
        "instavc_sub_monthly": "Mensal",
        "instavc_sub_weekly": "Semana",
        "instavc_setinterval": "Ajuste de Intervalo",
        "instavc_type": "Tipo",
        "instavc_subscription": "Assinatura",
        "instavc_onetime": "Uma Vez",
        "instavc_description": "Descri\u00e7\u00e3o",
        "instavc_planid": "ID do plano",
        "instavc_orders": "Encomendas",
        "instavc_orderid": "Identifica\u00e7\u00e3o da encomenda",
        "instavc_none": "Nenhum",
        "instavc_customer": "Cliente",
        "instavc_build_anually": "Estabelecer Anualmente",
        "instavc_recurring_billing": "Faturamento Recorrente",
        "instavc_next_due": "Pr\u00f3xima data de vencimento em",
        "instavc_invoice_details": "Detalhe das Notas Fiscais",
        "instavc_recurring_payment": "Pagamento Recorrente",
        "instavc_todays_sale": "A Venda de Hoje",
        "instavc_total_order": "Total de Pedidos",
        "instavc_total_rooms": "Total de Salas",
        "instavc_current_conference": "reuni\u00e3os Atuais",
        "instavc_current_user": "Usu\u00e1rios Atuais",
        "instavc_live_broadcast": "Transmiss\u00f5es ao vivo",
        "instavc_loading_chart": "Carregamento do Gr\u00e1fico",
        "instavc_view": "Ver",
        "instavc_coupon_info": "Informa\u00e7\u00f5es do cupom",
        "instavc_new_coupon": "Novo cupom",
        "instavc_create_coupon": "Criar Novo Cupom",
        "instavc_coupon_code": "C\u00f3digo do Cupom",
        "instavc_company_code": "C\u00f3digo da Empresa",
        "instavc_select_plan": "Selecione os planos",
        "instavc_discount": "Desconto",
        "instavc_activestatus": "ActiveStatus",
        "instavc_usage": "Utiliza\u00e7\u00e3o",
        "instavc_activeusage": "activeusage",
        "instavc_singleuse": "Uso \u00danico",
        "instavc_multiuse": "Multiuso",
        "instavc_first_page": "Primeira P\u00e1gina",
        "instavc_previosu_page": "P\u00e1gina Anterior",
        "instavc_next_page": "Pr\u00f3xima p\u00e1gina",
        "instavc_last_page": "\u00daltima P\u00e1gina",
        "instavc_plan": "Plano",
        "instavc_request_withdrawal": "Solicita\u00e7\u00e3o de Retirada",
        "instavc_withdraw": "Retirada",
        "instavc_account": "Conta",
        "instavc_verification": "Verifica\u00e7\u00e3o",
        "instavc_withdraw_history": "Hist\u00f3rico de Retiradas",
        "instavc_add_bank": "Adicionar uma conta banc\u00e1ria para saque",
        "instavc_bank_description": "Protegemos sua conta banc\u00e1ria, mantendo suas informa\u00e7\u00f5es financeiras confidenciais. Para evitar falhas no saque e devolu\u00e7\u00e3o de valores, o nome desta conta deve ser igual ao nome da sua conta banc\u00e1ria. Pode demorar algum tempo para completar a transfer\u00eancia, dependendo das pol\u00edticas do seu banco e dos hor\u00e1rios de feriados.",
        "instavc_bank_accountnumber": "N\u00famero da Conta Banc\u00e1ria",
        "instavc_enter_accountnumber": "Digite o n\u00famero de sua conta",
        "instavc_account_name": "Nome do Titular da Conta",
        "instavc_per_bank_account": "Por conta banc\u00e1ria",
        "instavc_ifsc": "C\u00f3digo IFSC",
        "instavc_bankname": "Nome do Banco",
        "instavc_branchname": "Nome da ag\u00eancia do banco",
        "instavc_confirmdetails": "Confirmar Detalhes",
        "instavc_pandetails": "Por favor, forne\u00e7a abaixo os detalhes de acordo com o seu PAN",
        "instavc_enter_pan": "Digite seu nome de acordo com o PAN",
        "instavc_fathername": "Nome do Pai",
        "instavc_enter_fathername": "Digite o nome do pai",
        "instavc_pannumber": "N\u00famero PAN",
        "instavc_panexampe": "Ex: BNZPM2501G",
        "instavc_upload_pan_copy": "Upload de c\u00f3pia PAN",
        "instavc_save_details": "Salvar Detalhes",
        "instavc_sales": "Vendas",
        "instavc_transactionid": "Id de Transa\u00e7\u00e3o",
        "instavc_date": "Data",
        "instavc_amount": "Valor",
        "instavc_setaccount_withdraw": "Configura\u00e7\u00e3o dos detalhes da conta antes de uma retirada",
        "instavc_first_withdraw": "Se voc\u00ea for um usu\u00e1rio pela primeira vez solicitando retirada, voc\u00ea precisa preencher os detalhes da conta e verificar seus dados usando os detalhes do Cart\u00e3o nas pr\u00f3ximas etapas. Uma vez terminada a verifica\u00e7\u00e3o, voc\u00ea pode proceder \u00e0 solicita\u00e7\u00e3o de saque a partir do seu saldo dispon\u00edvel.",
        "instavc_accountdetails": "Preencher Detalhes da Conta",
        "instavc_available_balance": "Saldo Dispon\u00edvel",
        "instavc_amount_withdraw": "Montante a retirar",
        "instavc_enteramount_withdraw": "Digite o valor que voc\u00ea deseja retirar",
        "instavc_select_payment_mode": "Selecione a Forma de Pagamento",
        "instavc_paypal_payment": "Pagamento por Paypal",
        "instavc_bank_transaction": "Transa\u00e7\u00e3o Banc\u00e1ria",
        "instavc_paypalfee": "Taxa do Paypal",
        "instavc_totalamount_withdraw": "Valor total a retirar",
        "instavc_sendrequest": "Enviar Pedido",
        "instavc_no_request_withdraw": "Nenhum pedido de Retirada ainda foi levantado!",
        "instavc_start_process_withdraw": "Voc\u00ea pode iniciar o processamento da retirada",
        "instavc_request_withdraw_now": "Solicitar Retirada Agora",
        "instavc_current_commission": "Comiss\u00e3o Atual",
        "instavc_from": "De",
        "instavc_to": "Para",
        "instavc_today": "Hoje",
        "instavc_last7days": "\u00daltimos 7 dias",
        "instavc_lastmonth": "Neste M\u00eas",
        "instavc_paid_customers": "Clientes Pagos",
        "instavc_share_referral_link": "Compartilhe seu link de indica\u00e7\u00e3o com amigos",
        "instavc_copylink": "Copiar o Link",
        "instavc_minimum_payout": "Pagamento M\u00ednimo",
        "instavc_referral_signup": "Cadastros de Indica\u00e7\u00e3o",
        "instavc_referral_clicks": "Cliques de Indica\u00e7\u00e3o",
        "instavc_paid": "Pago",
        "instavc_unpaid": "N\u00e3o pago",
        "instavc_totalearned": "Total de Ganhos",
        "instavc_myreferral": "Minhas Refer\u00eancias",
        "instavc_viewall": "Ver todos",
        "instavc_mysales": "Minhas vendas",
        "instavc_sms_mfa": "Status do SMS MFA",
        "instavc_facebook": "Facebook",
        "instavc_twitter": "Twitter",
        "instavc_linkedin": "LinkedIn",
        "instavc_referred_users": "Usu\u00e1rios Indicados",
        "instavc_created_date": "CreatedDate",
        "instavc_myearnings": "Meus Ganhos",
        "instavc_earnings": "Ganhos",
        "instavc_commission_share": "Participa\u00e7\u00e3o da Comiss\u00e3o",
        "instavc_commission_percentage": "Porcentagem da Comiss\u00e3o",
        "instavc_coupon_offers": "Cupons\/Ofertas",
        "instavc_my_referral_account": "Minha Conta de Indica\u00e7\u00e3o",
        "instavc_req_withdraw": "Solicita\u00e7\u00e3o de Retirada",
        "instavc_authenticating": "Autentica\u00e7\u00e3o ao servidor InstaVC ...",
        "instavc_join_access_media": "Juntou-se... Acesso a Dispositivos de M\u00eddia...",
        "instavc_publish_success": "Publicado com Sucesso",
        "instavc_recieves": "Recebe",
        "instavc_host_screenshare": "Voc\u00ea precisa se tornar um Anfitri\u00e3o para compartilhamento de tela\/documento ou",
        "instavc_request_sharescreen": "Solicitar acesso para compartilhamento de tela\/documento",
        "instavc_note": "Nota",
        "instavc_filesize": "O tamanho do arquivo n\u00e3o deve ser maior que 10MB",
        "instavc_become_host": "Para se tornar um anfitri\u00e3o, clique no \u00edcone no v\u00eddeo",
        "instavc_clicklink": "clique no link Torne-se um anfitri\u00e3o, no popup digite a senha do anfitri\u00e3o e clique no bot\u00e3o Torne-se um anfitri\u00e3o",
        "instavc_doc_share": "Partilha de documentos",
        "instavc_search_host": "Esperando por Anfitri\u00e3o",
        "instavc_wait_for_host": "Por favor, aguarde que o anfitri\u00e3o inicie esta reuni\u00e3o",
        "instavc_open_black_dialog": "Abrir di\u00e1logo negro",
        "instavc_open_white_dialog": "Abrir di\u00e1logo branco",
        "instavc_dail_detail": "Numera\u00e7\u00e3o - Detalhes",
        "instavc_request_access": "Solicitar Acesso",
        "instavc_inordder_enable": "A fim de habilitar",
        "instavc_host_permission": "voc\u00ea precisa da permiss\u00e3o de acesso do Anfitri\u00e3o",
        "instavc_please_wait": "Por favor Aguarde por",
        "instavc_host_to_permit": "permiss\u00e3o do Anfitri\u00e3o para",
        "instavc_alternate_content": "Conte\u00fado alternativo",
        "instavc_meeting_room": "Sala de Reuni\u00f5es",
        "instavc_host_left_conference": "O anfitri\u00e3o deixou a confer\u00eancia!",
        "instavc_conf_end": "A reuni\u00e3o terminar\u00e1 automaticamente em",
        "instavc_seconds": "Segundos",
        "instavc_waiting_msg": "Obrigado por esperar. Iniciaremos a reuni\u00e3o quando o anfitri\u00e3o se juntar a n\u00f3s.",
        "instavc_let_hostname": "Voc\u00ea quer que o anfitri\u00e3o saiba que est\u00e1 esperando?",
        "instavc_exitconf": "Sair da reuni\u00e3o",
        "instavc_host_left": "Anfitri\u00e3o deixou a reuni\u00e3o...",
        "instavc_end_host_msg": "O anfitri\u00e3o encerrou esta reuni\u00e3o. Obrigado pela sua participa\u00e7\u00e3o.",
        "instavc_close_conf": "voc\u00ea pode voltar para p\u00e1gina principal (ou) fechar a aplica\u00e7\u00e3o.",
        "instavc_gohome": "Ir para p\u00e1gina principal",
        "instavc_hand_raised": "M\u00e3o Levantada",
        "instavc_ask": "Pergunte",
        "instavc_protocal": "Protocolo",
        "instavc_bitrate": "Taxa de bits",
        "instavc_confirm_details": "Confirme seus dados",
        "instavc_enter_info_conf": "Por favor, digite suas informa\u00e7\u00f5es antes de iniciar a reuni\u00e3o.",
        "instavc_have_acc": "Se voc\u00ea j\u00e1 tem uma conta?",
        "instavc_host_access": "Acesso ao Anfitri\u00e3o",
        "instavc_host_pass": "Digite a Senha do Anfitri\u00e3o",
        "instavc_becomehost": "Seja Anfitri\u00e3o",
        "instavc_permit_screenshare": "Permiss\u00e3o de Tela Compartilhada",
        "instavc_allow_access": "Permitir acesso ao quadro branco",
        "instavc_request_permission": "solicitou permiss\u00f5es adicionais para",
        "instavc_deny": "Negar",
        "instavc_customeremail": "E-mail do Cliente",
        "instavc_orderlink": "Link para Encomenda",
        "instavc_customerid": "Identifica\u00e7\u00e3o do Cliente",
        "instavc_selectpackage": "Selecione o pacote",
        "instavc_host_package": "Pacote Baseado no Anfitri\u00e3o",
        "instavc_virtualbase_mcu": "Baseado na MCU Virtual",
        "instavc_plans_product": "Planos\/Produtos",
        "instavc_nodata": "N\u00e3o h\u00e1 dados dispon\u00edveis",
        "instavc_nodata_msg": "N\u00e3o h\u00e1 dados dispon\u00edveis para mostrar a voc\u00ea agora.",
        "instavc_basicplan": "Plano B\u00e1sico",
        "instavc_host_participants": "1 Anfitri\u00e3o com 30 participantes",
        "instavc_link": "Link",
        "instavc_cancel_order": "Cancelar Pedido",
        "instavc_per": "por",
        "instavc_user_email": "Email do Usu\u00e1rio",
        "instavc_owner": "Propriet\u00e1rio",
        "instavc_user": "Usu\u00e1rio",
        "instavc_default_users": "Ofertas Padr\u00e3o",
        "instavc_system_settings": "Configura\u00e7\u00f5es do Sistema",
        "instavc_expires": "Expira",
        "instavc_start_VC": "INICIAR VIDEOCONFER\u00caNCIA",
        "instavc_start_AC": "Iniciar reuni\u00e3o de audio",
        "instavc_date_error": "A data e hora de in\u00edcio deve ser posterior \u00e0 hora atual",
        "instavc_browser_update": "Atualiza\u00e7\u00e3o de Navegador Necess\u00e1ria",
        "instavc_browser_note": "Notamos que voc\u00ea est\u00e1 usando uma vers\u00e3o mais antiga do BROWSERNAME que agora n\u00e3o tem suporte.",
        "instavc_browser_msg": "O Instavc.com requer uma vers\u00e3o atualizada do BROWSERNAME ou de qualquer outro navegador moderno.",
        "instavc_browser_upgrade": "Por favor atualize para qualquer uma das \u00faltimas vers\u00f5es destes navegadores para uma melhor experi\u00eancia.",
        "instavc_googlechrome": "Google Chrome",
        "instavc_gc_version74": "Vers\u00e3o 74",
        "instavc_mfirefox": "Mozilla Firefox",
        "instavc_mff_version": "Vers\u00e3o 66",
        "instavc_opera": "\u00d3pera",
        "instavc_opera_version": "Vers\u00e3o 60.0+",
        "instavc_microsoftedge": "Microsoft Edge",
        "instavc_ms_version": "Vers\u00e3o 44+",
        "instavc_safari": "Saf\u00e1ri da Apple",
        "instavc_safari_version": "Vers\u00e3o 12.1",
        "instavc_contact_msg": "J\u00e1 atualizado, mas ainda com problemas? Entre em contato conosco em",
        "instavc_camera_busy": "A c\u00e2mera parece estar ocupada!",
        "instavc_close_camera": "Por favor, feche toda a aplica\u00e7\u00e3o e tente novamente.",
        "instavc_resolution": "Resolu\u00e7\u00e3o n\u00e3o suportada!",
        "instavc_camera_notsupport": "Sua c\u00e2mera n\u00e3o suporta esta resolu\u00e7\u00e3o.",
        "instavc_camera_reconfig": "Reconfigure suas configura\u00e7\u00f5es com resolu\u00e7\u00e3o mais baixa e tente novamente.",
        "instavc_change_resolution": "Mudar Resolu\u00e7\u00e3o",
        "instavc_confirmation": "Confirma\u00e7\u00e3o",
        "instavc_yes": "Sim",
        "instavc_delete_room": "Voc\u00ea tem certeza que quer apagar a Sala?",
        "instavc_delete_license": "Voc\u00ea tem certeza que quer excluir a Licen\u00e7a?",
        "instavc_help": "N\u00f3s estamos aqui para ajud\u00e1-lo",
        "instavc_browse_questions": "Navegue pelas perguntas mais frequentes.",
        "instavc_os": "Sistema Operacional",
        "instavc_macos": "Vers\u00e3o Mac OS X:",
        "instavc_chrome_version": "Vers\u00e3o Chrome:",
        "instavc_private_browsing": "Navega\u00e7\u00e3o privada?",
        "instavc_faq_no": "N\u00e3o",
        "instavc_display_resolution": "instavc_display_resolution",
        "instavc_aspect_ratio": "Exibir a rela\u00e7\u00e3o de altura \/ largura",
        "instavc_sys_hasspeakers": "O sistema tem alto-falantes?",
        "instavc_found_speakers": "Encontrados Dispositivos de Alto-falantes : 2",
        "instavc_speakers1": "Orador 1",
        "instavc_speakers2": "Orador 2",
        "instavc_sys_have_microphone": "O sistema possui Microfone?",
        "instavc_found_microphone": "Dispositivos de microfones encontrados : 2",
        "instavc_microphone1": "Microfone 1",
        "instavc_microphone2": "Microfone 2",
        "instavc_has_webcam": "O sistema tem Webcam?",
        "instavc_found_speakers1": "Encontrados Dispositivos de Alto-falantes : 1",
        "instavc_camera1": "C\u00e2mera 1",
        "instavc_webcam_permission": "O site tem permiss\u00f5es de webcam?",
        "instavc_microphone_permission": "O site tem permiss\u00f5es de microfone?",
        "instavc_browser_allows": "O navegador permite obter getUserMedia nesta p\u00e1gina?",
        "instavc_change_output_device": "Voc\u00ea pode mudar os dispositivos de \u00e1udio de sa\u00edda?",
        "instavc_change_camera_resolutions": "Voc\u00ea pode mudar as resolu\u00e7\u00f5es da c\u00e2mera sem fazer um novo pedido ao getUserMedia?",
        "instavc_browser_support_webrtc": "O Browser suporta WebRTC (1.0 ou 1.1)?",
        "instavc_browser_support_ortc": "O navegador suporta ORTC (WebRTC 1.1)?",
        "instavc_initial_setup": "Por favor aguarde enquanto sua configura\u00e7\u00e3o inicial est\u00e1 sendo processada!",
        "instavc_close_browser": "N\u00e3o feche o seu navegador ou use o bot\u00e3o voltar neste momento.",
        "instavc_whoops": "Desculpe!",
        "instavc_nonet_msg": "N\u00e3o foi encontrada nenhuma conex\u00e3o de internet. Por favor, verifique sua conex\u00e3o ou tente novamente.",
        "instavc_nosearch": "Nenhum resultado de pesquisa encontrado",
        "instavc_noserchmsg": "N\u00e3o conseguimos encontrar nenhum item que corresponda \u00e0 sua pesquisa.",
        "instavc_nosearch_filter": "Tente ajustar sua busca ou filtro para encontrar o que voc\u00ea est\u00e1 procurando.",
        "instavc_gotohoem_page": "Ir para a p\u00e1gina inicial",
        "instavc_pageloading": "Carregamento de p\u00e1gina... Por favor aguarde!",
        "instavc_process": "Processamento...",
        "instavc_session_timeout": "Tempo limite da sess\u00e3o",
        "instavc_session_inactivity": "Sua sess\u00e3o est\u00e1 sendo interrompida devido \u00e0 inatividade.",
        "instavc_session_msg": "Por favor, escolha permanecer conectado.",
        "instavc_session_reconnect": "Reconecte",
        "instavc_site_notreached": "Este site n\u00e3o pode ser alcan\u00e7ado!",
        "instavc_site_webaddress": "Certifique-se de que voc\u00ea tem o endere\u00e7o web certo",
        "instavc_check_internet": "Confira sua rede",
        "instavc_search_url": "Pesquisar a URL em outro navegador",
        "instavc_goto": "Ir para",
        "instavc_request_for_access": "Solicita\u00e7\u00e3o de Acesso",
        "instavc_start_collabrate": "para come\u00e7ar a colaborar em quadro branco",
        "instavc_large_conference_mode": "Est\u00e1 na modalidade \"Grande Reuni\u00e3o\", voc\u00ea n\u00e3o pode falar ou assistir aos outros at\u00e9 que o anfitri\u00e3o lhe d\u00ea a permiss\u00e3o",
        "instavc_allow_participants": "Voc\u00ea est\u00e1 na modalidade \"Grande Reuni\u00e3o\", voc\u00ea tem que permitir manualmente que os participantes falem",
        "instavc_buy_premium": "Compre Premium para desbloquear estes recursos",
        "instavc_alert_tryagain": "Algo deu errado, por favor tente novamente",
        "instavc_enter_name": "Digite seu nome",
        "instavc_confirm": "Confirme",
        "instavc_ok": "Ok",
        "instavc_askquestion": "Fa\u00e7a uma pergunta...",
        "instavc_recieve": "Receba",
        "instavc_delay": "Atraso(ms)",
        "instavc_audio_opus": "\u00c1udio\/opus",
        "instavc_video_vp8": "v\u00eddeo\/vp8",
        "instavc_browser_not_support_incall": "Seu Navegador n\u00e3o suporta mudan\u00e7a de resolu\u00e7\u00e3o durante a chamada!",
        "instavc_failed_toPublish": "Falha na transmiss\u00e3o do v\u00eddeo",
        "instavc_publish_fail": "Erro na Publica\u00e7\u00e3o",
        "instavc_video_cant_publish": "O v\u00eddeo n\u00e3o pode ser publicado, devido ao alcance do limite de usu\u00e1rios",
        "instavc_failed_joinConference": "Falha em participar da reuni\u00e3o",
        "instavc_mute_audio": "Voc\u00ea n\u00e3o pode silenciar\/unmutar se o \u00e1udio remoto for silenciado",
        "instavc_remote_audio_muted": "\u00c1udio remoto mudo!",
        "instavc_enter_conferenceid": "Digite seu ID da reuni\u00e3o",
        "instavc_share_screen": "Tela de Compartilhamento",
        "instavc_sharing_your_screen": "Compartilhando sua tela...",
        "instavc_stop": "Parar",
        "instavc_back": "Voltar",
        "instavc_choose_plan": "Escolha seu plano a partir da lista abaixo",
        "instavc_have_promotionalcode": "Tem um C\u00f3digo Promocional \/ C\u00f3digo de Parceiro ?",
        "instavc_contact_pricing": "Entre em contato conosco para saber os pre\u00e7os",
        "instavc_payment_failed": "Pagamento Falhou",
        "instavc_payment_error": "Infelizmente um erro ocorreu durante o processamento do seu pagamento.",
        "instavc_contact_support": "Por favor, contate nossa equipe de suporte caso necessite de alguma assist\u00eancia.",
        "instavc_tryagain": "Tente novamente",
        "instavc_payment_success": "Pagamento Bem Sucedido",
        "instavc_thanks_for_payment": "Obrigado por seu pagamento!",
        "instavc_payment_processed": "O seu pagamento foi processado com sucesso. Um recibo de pagamento autom\u00e1tico",
        "instavc_mail_sent": "ser\u00e1 enviado para o seu endere\u00e7o de e-mail cadastrado em breve",
        "instavc_goto_homepage": "Voltar \u00e0 p\u00e1gina inicial",
        "instavc_want_toend_call": "Quer Terminar a chamada?",
        "instavc_exit_window": "Fechando esta janela, voc\u00ea sair\u00e1 de sua chamada. Voc\u00ea tem certeza de que quer encerrar esta chamada?",
        "instavc_create_room": "Criar Sala",
        "instavc_userlimit": "Limite de usu\u00e1rios",
        "instavc_edit_room": "Editar Sala",
        "instavc_update": "Alterar",
        "instavc_manage_license": "Gerenciar Licen\u00e7as",
        "instavc_package": "Pacote",
        "instavc_no_of_rooms": "N\u00ba de Quartos",
        "instavc_no_of_users": "N\u00ba de Usu\u00e1rios",
        "instavc_purchasedOn": "Comprado em",
        "instavc_expiredon": "Expirado em",
        "instavc_or": "OU",
        "instavc_otherhost_email": "Se voc\u00ea quiser fazer qualquer usu\u00e1rio como anfitri\u00e3o, digite seu endere\u00e7o de e-mail.",
        "instavc_set_host_password": "Definir Senha do Anfitri\u00e3o",
        "instavc_host_password": "Senha do Anfitri\u00e3o",
        "instavc_access_room": "Quem mais pode acessar esta Sala?",
        "instavc_select_user_host": "Selecione qualquer usu\u00e1rio da lista para torn\u00e1-lo um host.",
        "instavc_prologin": "Login InstaVC Pro",
        "instavc_start": "In\u00edcio",
        "instavc_enter_website": "Por favor, digite o nome do WebSite",
        "instavc_enter_valid_email": "Por favor, digite um e-mail v\u00e1lido",
        "instavc_enter_valid_phone": "Por favor, digite um n\u00famero de telefone v\u00e1lido",
        "instavc_field_required": "Este Campo \u00e9 Obrigat\u00f3rio",
        "instavc_invitedby": "Convidado por",
        "instavc_storage": "Armazenagem",
        "affiliate_approve": "Aprovada",
        "affiliate_pending": "Pendente",
        "affiliate_rejected": "Rejeitada",
        "affiliate_user_status": "Estado do usu\u00e1rio",
        "commission_other": "Outras Comiss\u00f5es",
        "add_commission": "Adicionar comiss\u00f5es",
        "delete_commission": "Voc\u00ea quer apagar comiss\u00e3o",
        "update_commission": "Comiss\u00e3o de Atualiza\u00e7\u00e3o",
        "name": "Nome",
        "offer_value": "Valor da Oferta",
        "cancel": "Cancelar",
        "add": "Adicionar",
        "update": "Alterar",
        "affiliates": "Afiliados",
        "status": "Status",
        "status_change_message": "Voc\u00ea gostaria de mudar o Status?",
        "approve": "Aprovar",
        "reject": "Rejeitar",
        "commissions": "Comiss\u00f5es",
        "withdraw_data": "Dados de Retirada",
        "withdraw_amount": "Valor de Retirada",
        "payment_method": "Forma de Pagamento",
        "payment_fee": "Taxa de Pagamento",
        "total_amount_withdraw": "Valor Total para Retirada",
        "total_earnings": "Total de Lucros",
        "pending_amount": "Valor Pendente",
        "paid_amount": "Valor pago",
        "unpaid_amount": "Valor n\u00e3o pago",
        "requested_withdraw": "Valor do Retirada Solicitada",
        "english": "Ingl\u00eas",
        "french": "Franc\u00eas",
        "room": "Sala",
        "create": "Criar",
        "repeat": "Repetir",
        "daily": "Diariamente",
        "repeat_every": "Repetir Cada",
        "repeat_on": "Repita em",
        "repeat_by": "Repetir Por",
        "ends": "Fim",
        "after": "Depois",
        "occurences": "ocorr\u00eancias",
        "on": "em",
        "summary": "Sum\u00e1rio",
        "repeats": "Repeti\u00e7\u00f5es",
        "withdraws": "Retiradas",
        "every": "Todo",
        "days": "dias",
        "weeks": "semanas",
        "months": "M\u00eas",
        "onday": "no dia",
        "years": "anos",
        "times": "vezes",
        "until": "at\u00e9",
        "past_meeting_list": "Lista de Reuni\u00f5es completadas",
        "organised_by": "Organizado por",
        "delete": "Remover",
        "join": "Participar",
        "to": "Para",
        "enter_password": "Digite a senha",
        "submit": "Submeter",
        "enter_passwordmessage": "Por favor, digite a senha",
        "delete_message": "Voc\u00ea quer excluir",
        "meeting_permanent": "Reuni\u00e3o permanente?",
        "day": "Dia",
        "password_message": "a senha est\u00e1 incorreta",
        "timezone": "Fuso Hor\u00e1rio",
        "by": "Por",
        "weekdays": "[\"DOM\", \"SEG\", \"TER\", \"QUA\", \"QUI\", \"SEX\", \"SAB\"]",
        "allweek": "[\"Domingo\",\"2\u00aa-Feira\",\"3\u00aa-Feira\",\"4\u00aa-Feira\",\"5\u00aa-Feira\",\"6\u00aa-Feira\",\"S\u00e1bado\"]",
        "day_month": "dia do m\u00eas",
        "day_week": "dia da semana",
        "week": "Semana",
        "month": "M\u00eas",
        "year": "Ano",
        "enterpassword": "Por favor, digite sua nova senha e confirme a senha",
        "passwordmessage": "Senha e Confirmar Senha ambos devem ser iguais",
        "passwordupdate": "Senha Atualizada com Sucesso",
        "numeric": "[\"primeiro\", \"segundo\", \"terceiro\", \"quarto\", \"quinto\"]",
        "verify_email": "Verifique o seu e-mail",
        "confirm_code": "C\u00f3digo de Confirma\u00e7\u00e3o",
        "verify_msg": "Por favor, verifique o c\u00f3digo no seu celular.",
        "verify": "Confira",
        "not_recieved": "N\u00e3o Recebido?",
        "resend_otp": "Reenviar OTP",
        "start_browsing": "Iniciar Navega\u00e7\u00e3o",
        "become_affiliate": "Seja Afiliado",
        "affiliate": "Afilia\u00e7\u00e3o",
        "verify_mobile": "Verifique o seu celular",
        "instavc_allow_DocumentAccess": "Permitir partilha de Documentos",
        "camera_not_access": "C\u00e2mera n\u00e3o acess\u00edvel devido a uma das seguintes raz\u00f5es:",
        "camera_not_access_reason1 ": "As configura\u00e7\u00f5es de resolu\u00e7\u00e3o podem n\u00e3o ser compat\u00edveis. Reconfigure suas configura\u00e7\u00f5es com resolu\u00e7\u00e3o mais baixa e tente novamente.",
        "camera_not_access_reason2": "Sua c\u00e2mera est\u00e1 sendo usada por diferentes aplica\u00e7\u00f5es, por favor feche todas as aplica\u00e7\u00f5es e tente novamente, clicando no link.",
        "asgnQue_Assign_Question ": "Atribuir Question\u00e1rio",
        "asgnQue_assigned_question": "Question\u00e1rio atribuido",
        "quslsit_assig_que_list\t": "Lista de Question\u00e1rios Atribu\u00eddos",
        "quslsit_question_lists\t": "Listas de Question\u00e1rios",
        "quslsit_search\t": "Pesquisar ...",
        "quslsit_add_new_question ": "Adicionar Novo Question\u00e1rio",
        "quslsit_no_question_assign ": "Ainda n\u00e3o foi designado nenhum question\u00e1rio!",
        "quslsit_assign-new_ques": "Atribua um novo Question\u00e1rio para clicar no bot\u00e3o Atribuir Question\u00e1rio acima",
        "mdl_quesnr_questionnaire": "Question\u00e1rio",
        "mdl_quesnr_select_question": "Selecione Question\u00e1rio",
        "mdl_quesnr_duration": "Dura\u00e7\u00e3o",
        "mdl_quesnr_time_limit": "Tempo Limite",
        "mdl_quesnr_show_results": "Mostrar Resultados",
        "mdl_quesnr_cancel": "Cancelar",
        "mdl_quesnr_save": "Salvar",
        "viewAsg_question_details": "Detalhes do Question\u00e1rio",
        "viewAsg_content_group": "Grupo de Conte\u00fado",
        "viewAsg_questions": "Perguntas",
        "viewAsg_question_list": "Lista de question\u00e1rios",
        "viewAsg_list_assigned_ques": "Lista de perguntas atribu\u00eddas:",
        "viewAsg_mins": "mins",
        "viewAsg_marks": "Pontos",
        "asgQues_5_Marks": "( 5 Pontos )",
        "asgQues_answers": "Respostas",
        "asgQues_no_questions": "Sem Perguntas!",
        "grdSet_GPA_calculation": "C\u00e1lculo a partir do percentual",
        "grdSet_GPA_cal_msg": "O percentual \u00e9 para 100%, que n\u00e3o \u00e9 nada al\u00e9m de 100 pontos. Portanto, 25 pontos em porcentagem \u00e9 equivalente a 1 ponto no Sistema GPA. A porcentagem para a f\u00f3rmula GPA \u00e9 apenas dividir sua porcentagem por 100 e multiplicar por 4. Se a sua porcentagem for 75% ent\u00e3o sua nota seria (75\/100)*4 = 3.",
        "grdSet_grade_point": "Se as notas s\u00e3o",
        "mngGrd_grades": "Notas",
        "mngGrd_cancel": "Cancelar",
        "mngGrd_save": "Salvar",
        "mngGrd_modify": "Modificar",
        "mngGrd_save_grades": "Salve Notas",
        "mngGrd_modify_grades": "Modificar Notas",
        "mngGrd_grade_info": "Informa\u00e7\u00f5es da Nota",
        "admnAns_answer_list": "Lista de respostas",
        "admnAns_answer_list_for_question": "Veja abaixo a lista de respostas para todo o question\u00e1rio deste Grupo de Conte\u00fado.",
        "admnAns_answered": "Respondidas",
        "admnAns_correct_answer": "Resposta Correta",
        "prtcpt_certification": "Certifica\u00e7\u00f5es:",
        "prtcpt_other_content_group": "Em outros Grupos de Conte\u00fado",
        "prtcpt_sales": "Vendas",
        "prtcpt_marketing": "Marketing",
        "prtcpt_human_resource": "Recursos Humanos",
        "mngPrtcpt_participants": "Participantes",
        "mngPrtcpt_add_participants": "Adicione participantes",
        "mngPrtcpt_participants_list": "Listas de Participantes",
        "mngPrtcpt_import_participants": "Participantes de Importa\u00e7\u00e3o",
        "mngPrtcpt_download_sample": "Baixar Amostra",
        "mngPrtcpt_no_participants_added": "Nenhum Participante foi adicionado ainda!",
        "mngPrtcpt_add_new_participant": "Adicione um novo Participante, para adicionar clique no Bot\u00e3o Adicionar Participante acima",
        "addPrtcpt_select_participants": "Selecione os Participantes",
        "addPrtcpt_model_add_participants": "Adicione participantes",
        "prtcptGrde_participant_grade": "Detalhes das Notas dos Participantes",
        "prtcptGrde_grade_participants_list": "Lista de Participantes",
        "mngQuesnr_questionnaire_list": "Lista de question\u00e1rios",
        "mngQuesnr_create_questionnaire": "Criar Question\u00e1rio",
        "mngQuesnr_search": "Pesquisar ...",
        "mngQues_question_details ": "Detalhes do Question\u00e1rio",
        "mngQues_add_questions": "Adicionar Perguntas",
        "mngQues_question_list": "Lista de question\u00e1rios",
        "mngQues_questions": "D\u00favidas",
        "mngQues_list_assign_question": "Lista de perguntas atribu\u00eddas:",
        "mngQues_no_question_created": "Ainda n\u00e3o foi criada nenhuma pergunta!",
        "mngQues_create_new_question": "Criar uma nova Pergunta, para criar clique no Bot\u00e3o Criar Pergunta acima",
        "mdlGrp_questionnaire": "Question\u00e1rio",
        "mdlGrp_questionnaire_name": "Nome do Question\u00e1rio",
        "mdlGrp_description": "Descri\u00e7\u00e3o",
        "mdlGrp_cancel": "Cancelar",
        "mdlGrp_update": "Alterar",
        "mdlGrp_create": "Criar",
        "mdlCrtQues_update_question": "Pergunta de Atualiza\u00e7\u00e3o",
        "mdlCrtQues_create_questionnaire": "Criar Question\u00e1rio",
        "mdlCrtQues_enter_question_here": "Digite aqui sua pergunta",
        "mdlCrtQues_marks": "Pontos",
        "mdlCrtQues_question_type": "Tipo de Pergunta",
        "mdlCrtQues_sel_question_type": "Selecione o Tipo de Pergunta",
        "mdlCrtQues_single_sel_multiple_choice": "Selec\u00e7\u00e3o \u00danica Escolha M\u00faltipla",
        "mdlCrtQues_multiselect_multiple_choice": "Pergunta de M\u00faltipla Escolha",
        "mdlCrtQues_ture_false": "Verdadeiro\/Falso",
        "mdlCrtQues_enter_answer_choice": "Digite as op\u00e7\u00f5es de resposta, e marque qual resposta est\u00e1 correta",
        "mdlCrtQues_add_another_choice": "+ Adicione outra escolha",
        "mdlCrtQues_cancel": "Cancelar",
        "viewQues_questionnaire_details": "Detalhes do Question\u00e1rio",
        "viewQues_add_questions": "Adicionar Perguntas",
        "viewQues_question_list": "Lista de question\u00e1rios",
        "viewQues_question": "Perguntas",
        "viewQues_list_assign_questions": "Lista de perguntas atribu\u00eddas:",
        "viewQues_no_question_created": "Ainda n\u00e3o foi criada nenhuma pergunta!",
        "viewQues_create_new_question": "Criar uma nova Pergunta, para criar clique no Bot\u00e3o Criar Pergunta acima",
        "crteContent_create_new_content": "Criar Novo Conte\u00fado",
        "crteContent_update_content": "Atualizar Conte\u00fado",
        "crteContent_update": "Alterar",
        "crteContent_save_content": "Salvar Conte\u00fado",
        "crteContent_save": "Salvar",
        "crteContent_content_group": "Grupo de Conte\u00fado",
        "crteContent_content_list": "Listas de Conte\u00fado",
        "crteContent_edit_content": "Editar conte\u00fado",
        "crteContent_create_content": "Criar conte\u00fado",
        "crteContent_create_your_content_like": "Crie seu conte\u00fado, adicionando m\u00eddias como, imagens, arquivos de v\u00eddeo, arquivos de \u00e1udio e documentos.",
        "crteContent_upload_document": "Upload de Documentos",
        "crteContent_upload_media": "Upload de M\u00eddias",
        "crteContent_document": "Documentos",
        "crteContent_media_files": "Arquivos de M\u00eddia",
        "viewCon_content_view": "Visualiza\u00e7\u00e3o de conte\u00fado",
        "viewCon_content_group": "Grupo de Conte\u00fado",
        "viewCon_content_list": "Listas de Conte\u00fado",
        "viewCon_View_content": "Ver Conte\u00fado",
        "viewCon_enable": "Ativar:",
        "viewCon_document": "Documentos",
        "viewCon_media_files": "Arquivos de M\u00eddia",
        "viewCon_content": "Conte\u00fado",
        "conRow_enable": "Ativar:",
        "conRow_by": "Por:",
        "conRow_content_group": "Grupo de Conte\u00fado",
        "grpRow_enable": "Ativar:",
        "grpRow_by": "Por:",
        "hozConNav_content": "Conte\u00fado",
        "hozConNav_participants": "Participantes",
        "hozConNav_grade": "Notas",
        "hozConNav_assign_questionnaire": "Atribuir Question\u00e1rio",
        "mngCon_create_new_content": "Criar um novo Conte\u00fado, para criar clique no Bot\u00e3o Criar Conte\u00fado acima",
        "mngCon_all": "Todos",
        "mngCon_content_list": "Listas de Conte\u00fado",
        "mngCon_no_content_created": "Nenhum Conte\u00fado foi criado ainda!",
        "mdlAccRight_share_access": "Compartilhar acesso com outros Instrutores",
        "mdlAccRight_type_id_add": "Digite e-mail id e adicione",
        "mdlAccRight_no_instructor_added": "Nenhum Instrutor foi adicionado ainda!...",
        "mdlAccRight_add_people_from_addressbok": "Adicione pessoas da sua agenda de endere\u00e7os, ou digitando o seu endere\u00e7o de e-mail.",
        "mdlAccRight_addressbook": "Agenda de contatos",
        "mdlAccRight_search": "Pesquisar ...",
        "mdlAccRight_contacts": "Contatos",
        "mdlAccRight_add_contacts": "Adicionar contatos ao Grupo de Conte\u00fado, a partir dos contatos de sua agenda de endere\u00e7os",
        "mdlAccRight_no_contacts_added": "Nenhum Contato foi adicionado ainda!.",
        "mdlAccRight_cancel": "Cancelar",
        "mdlConfDel_confirmation": "Confirma\u00e7\u00e3o",
        "mdlConfDel_are_you_sure_delete": "Voc\u00ea tem certeza de que quer apagar isso?",
        "mdlConfDel_cancel": "Cancelar",
        "mdlConfDel_delete": "Remover",
        "mdlCrtConGrp_alert_enter_group_title": "Por favor, digite o T\u00edtulo do Grupo",
        "mdlCrtConGrp_alert_enter_group_description": "Por favor, digite a Descri\u00e7\u00e3o do Grupo",
        "mdlCrtConGrp_content_group": "Grupo de Conte\u00fado",
        "mdlCrtConGrp_group_name": "Nome do Grupo",
        "mdlCrtConGrp_description": "Descri\u00e7\u00e3o",
        "mdlCrtConGrp_cancel": "Cancelar",
        "mdlCrtConGrp_save": "Salvar",
        "mdlUpldoc_alert_enter_title_doc": "Por favor insira o t\u00edtulo do documento",
        "mdlUpldoc_upload_document": "Upload de Documentos",
        "mdlUpldoc_document_title": "T\u00edtulo do documento",
        "mdlUpldoc_upload_doc_from_system": "Carregue o documento do seu sistema, ele suporta documentos pdf, excel, word.",
        "mdlUpldoc_upload_doc": "Carregar documento",
        "mdlUpldoc_added_doc": "Documentos Adicionados",
        "mdlUpldoc_cancel": "Cancelar",
        "mdlUpldoc_save": "Salvar",
        "mdlUplMedia_alert_enter_title_media": "Por favor, digite o t\u00edtulo da m\u00eddia",
        "mdlUplMedia_alert_enter_title_url": "Por favor, digite o t\u00edtulo e URL",
        "mdlUplMedia_alert_enter_youtube_vimeo_url": "Insira um URL válido (Youtube / Vimeo)",
        "mdlUplMedia_upload_media": "Upload de M\u00eddias",
        "mdlUplMedia_upload_url": "URL do Upload",
        "mdlUplMedia_Upload_media_system": "Upload de m\u00eddia do seu sistema, suporta .jpg, .png, .jpeg, .mp3, .mp4",
        "mdlUplMedia_add_media_url": "Adicionar endere\u00e7o URL da M\u00eddia para anexar uma M\u00eddia",
        "mdlUplMedia_upload_media_url": "Upload da URL da m\u00eddia",
        "mdlUplMedia_upload_youtube_url": "Nota: Upload de URL's do YouTube",
        "mdlUplMedia_Add_media_file": "Arquivos de m\u00eddia adicionados",
        "mdlUplMedia_cancel": "Cancelar",
        "mdlUplMedia_save": "Salvar",
        "mngContent_content": "Conte\u00fado",
        "mngContent_participants": "Participantes",
        "mngContent_grade_settings": "Ajustes de Notas",
        "mngContent_assign_questonnaire": "Atribuir Question\u00e1rio",
        "content_all_content_group": "Todos os Grupos de Conte\u00fado",
        "content_create_new_group": "Criar um novo Grupo, para criar clique no Bot\u00e3o Criar Grupo acima",
        "content_search": "Pesquisar ...",
        "content_no_group_created": "Nenhum Grupo foi criado ainda!",
        "conList_questionnaire": "Question\u00e1rio",
        "conList_view": "Veja",
        "conList_all_group": "Todos os Grupos",
        "conList_content_list": "Listas de Conte\u00fado",
        "conList_no_content_created": "Nenhum Conte\u00fado foi criado ainda!",
        "grpList_all_content_group": "Todos os Grupos de Conte\u00fado",
        "grpList_no_group_created": "Nenhum Grupo foi criado ainda!",
        "questnrList_questionnaire": "Question\u00e1rio",
        "questnrList_questionnaire_list": "Lista de question\u00e1rios",
        "questnrList_no_question_assign": "Ainda n\u00e3o foi designado nenhum question\u00e1rio!",
        "questnrList_marksz": "Pontos",
        "questnrList_mins": "mins",
        "queStepper_can't_skip": "Voc\u00ea n\u00e3o pode pular um passo que n\u00e3o \u00e9 opcional.",
        "queStepper_marks": "Pontos",
        "queStepper_back": "Voltar",
        "queStepper_next": "Pr\u00f3ximo",
        "queStepper_finish": "Terminar",
        "queStepper_submit_next": "Submeter & Pr\u00f3ximo",
        "showCont_content_list": "Listas de Conte\u00fado",
        "showCont_document": "Documentos",
        "showCont_media_file": "Arquivos de M\u00eddia",
        "showCont_previous": "Anterior",
        "showCont_next": "Pr\u00f3ximo",
        "showCont_have_completed_topic": "Tenho t\u00f3picos completos neste Conte\u00fado",
        "showMedia_back": "Voltar",
        "showMedia_page": "P\u00e1gina",
        "showMedia_of": "de",
        "showMedia_next": "Pr\u00f3ximo",
        "showMedia_previous": "Prev",
        "showQues_questionnaire_list": "Lista de question\u00e1rios",
        "showQues_question": "D\u00favidas",
        "showQues_marks": "Pontos",
        "showQues_min_left": "min esquerda",
        "showQues_completed_test": "Voc\u00ea completou o teste neste question\u00e1rio.",
        "showQues_go_back_content_group": "Voltar ao Grupo de Conte\u00fado",
        "showQues_list_assign_question": "Lista de perguntas atribu\u00eddas:",
        "viewUserScore_view_score": "Ver Pontua\u00e7\u00e3o",
        "viewUserScore_question_list": "Lista de question\u00e1rios",
        "viewUserScore_question": "D\u00favidas",
        "viewUserScore_total_marks": "Total de Pontos:",
        "viewUserScore_marks": "Pontos",
        "viewUserScore_scored_marks": "Pontos marcados:",
        "viewUserScore_GPA_score": "Sua Pontua\u00e7\u00e3o na GPA:",
        "viewUserScore_answer_list": "Lista de respostas",
        "viewUserScore_below_list_of_answer": "Veja abaixo a lista de respostas para todo o question\u00e1rio deste Grupo de Conte\u00fado.",
        "viewUserScore_answered": "Respondidas",
        "viewUserScore_correct_answer": "Resposta Correta",
        "confCancle_confirmation": "Confirma\u00e7\u00e3o",
        "confCancle_are_you_sure": "Voc\u00ea tem certeza?",
        "confCancle_cancel": "Cancelar",
        "confCancle_delete_user": "Excluir usu\u00e1rio",
        "confSche_are_you_sure_delete": "Voc\u00ea tem certeza de que quer apagar este hor\u00e1rio?",
        "confSche_no": "N\u00e3o",
        "pastMtnItem_rn": "RN",
        "pastMtnItem_room_name": "Nome da sala",
        "pastMtnItem_webinar_list": "Lista de Webinars",
        "pastMtnItem_list_webinar": "Veja abaixo a lista de webinars.",
        "pastMtnItem_today": "Hoje",
        "pastMtnItem_webinar_title_falls": "T\u00edtulo do Webinar cai aqui",
        "pastMtnItem_view": "Ver",
        "pastMtnItem_upcoming": "Pr\u00f3xima",
        "schedules_upload_custom_banner": "Fa\u00e7a o upload de um banner personalizado para o seu evento de Webinar.",
        "scheView_edit_content": "Editar conte\u00fado",
        "scheView_scheduled_by": "Agendado por:",
        "scheView_delete": "Remover",
        "scheView_download_calander": "Baixar agendamentos",
        "scheView_invite_participants": "Convidar Participantes",
        "scheView_start_conference": "Iniciar reuni\u00e3o",
        "scheView_event_details": "Detalhes do Evento",
        "scheView_room_name": "Nome da sala",
        "scheView_duration": "Dura\u00e7\u00e3o",
        "scheView_minutes": "Minutos",
        "scheView_conference_password": "Senha da reuni\u00e3o",
        "scheView_settings": "Configura\u00e7\u00f5es",
        "scheView_webinar_event": "Evento Webinar",
        "scheView_list_webinar": "Liste este evento de webinar em instavc.com\/webinar, e aceite inscri\u00e7\u00f5es.",
        "scheView_guest_login_anonymous": "Convidado pode fazer login como An\u00f4nimo",
        "scheView_allow_invities": "Permitir Somente Convidados a Participar da reuni\u00e3o",
        "scheView_enable_webinar_chat": "Habilitar bate-papo em Webinar",
        "scheView_enable_hand_rise": "Habilitar m\u00e3o levantada em webinar",
        "scheView_list_of_people_invited": "Veja abaixo a lista de pessoas convidadas",
        "scheView_alert_err_to_join": "Erro para participar",
        "webnrDtls_room_name": "Nome da sala",
        "webnrDtls_upcoming_webinar_room": "Abaixo est\u00e1 o pr\u00f3ximo Webinar para esta sala.",
        "webnrDtls_webinar_details": "Detalhes do Webinar",
        "webnrDtls_webinar_list": "Lista de Webinars",
        "webnrDtls_webinar_title": "T\u00edtulo do Webinar",
        "webnrDtls_webinar_title_falls": "T\u00edtulo do Webinar cai aqui",
        "webnrDtls_get_more_joining": "Obtenha mais informa\u00e7\u00f5es aderindo a este webinar.",
        "webnrDtls_join_webinar": "Participe do Webinar",
        "webnrList_room_name": "Nome da sala",
        "webnrList_webinar_list": "Lista de Webinars",
        "webnrList_below_list_of_webinar": "Veja abaixo a lista de webinars.",
        "webnrList_today": "Hoje",
        "webnrList_webinar_title_falls": "T\u00edtulo do Webinar cai aqui",
        "webnrList_view": "Ver",
        "webnrList_upcoming": "Pr\u00f3xima",
        "addBook_address_book": "Agenda de contatos",
        "addBook_contact": "Contatos",
        "addBook_add_contact_to_meeting": "Adicione contatos para a reuni\u00e3o, a partir de sua agenda de contatos",
        "meetingList_past_meetings": "Reuni\u00f5es completadas",
        "meetingList_past_meetings_list": "Lista de Reuni\u00f5es completadas",
        "meetingList_participants": "Participantes",
        "meetingList_room_name": "Nome da sala:",
        "meetingList_view_content": "Ver Conte\u00fado",
        "meetingList_recording": "Grava\u00e7\u00f5es",
        "meetingList_view": "Ver",
        "scheInvt_err_invalid_id": "Identifica\u00e7\u00e3o de e-mail inv\u00e1lido",
        "scheInvt_invite_participants": "Convidar Participantes",
        "scheInvt_address_book": "Agenda de contatos",
        "scheInvt_invite_people_to_meeting": "Convide pessoas para esta reuni\u00e3o. Adicione pessoas do seu cat\u00e1logo de endere\u00e7os ou digite o endere\u00e7o de e-mail abaixo e clique em \"Adicionar\".",
        "scheInvt_start": "Come\u00e7a @",
        "scheInvt_below_list_of_answer": "Veja abaixo a lista de respostas para todo o question\u00e1rio deste Grupo de Conte\u00fado.",
        "scheInvt_room_name": "Nome da sala:",
        "scheInvt_host": "Anfitri\u00e3o:",
        "scheInvt_minutes": "Minutos",
        "scheInvt_add": "Adicionar",
        "scheInvt_no_participants_added": "Nenhum Participante adicionado",
        "scheInvt_add_ppl_to_meetings": "Adicione pessoas a esta reuni\u00e3o como participantes de sua lista de endere\u00e7os ou adicione-as acima e clique em \"Adicionar\".",
        "scheInvt_cancel": "Cancelar",
        "scheInvt_send_invitation": "enviar convite",
        "scheInvt_send_passcodes": "62\/5000\nEnvie os c\u00f3digos de acesso em um e-mail separado do convite",
        "scheMeet_room_reserved_from": "Quarto reservado a partir de",
        "scheMeet_meeting_name": "com nome da reuni\u00e3o",
        "invitBody_invite_only_upto": "Voc\u00ea s\u00f3 pode convidar at\u00e9 10 pessoas",
        "invitBody_invite_ppl_meeting_email_add": "Convide pessoas para esta reuni\u00e3o, adicione pessoas do seu cat\u00e1logo de endere\u00e7os, ou adicione por e-mail.",
        "invitBody_copy": "C\u00f3piar",
        "invitBody_enter_emailid": "Digite o E-mail ID",
        "invitBody_add": "Adicionar",
        "invitBody_close": "Fechar",
        "invitBody_send_invitation": "enviar convite",
        "docShare_doc_sharing": "Compartilhamento de Documentos",
        "docShare_upload_doc_system_pdf": "Carregue o documento do seu sistema, ele suporta documentos pdf, excel, word.",
        "docShare_upload_doc": "Carregar documento",
        "docShare_no_doc": "Sem documentos",
        "docShare_no_doc_in_list": "Ainda n\u00e3o h\u00e1 documentos adicionados \u00e0 lista.",
        "docShare_add_doc": "Documentos Adicionados",
        "docShare_alert_title_doc": "Por favor insira o t\u00edtulo do documento",
        "docShare_alert_something_went_wrong": "algo deu errado, por favor tente novamente",
        "docShare_alert_try_again": "por favor tente novamente",
        "permitDocShr_permit_doc_share_access": "Permiss\u00e3o de Acesso compartilhado de documentos",
        "permitDocShr_req_permission_doc_share": "solicitou permiss\u00e3o para acessar o compartilhamento de documentos.",
        "permitDocShr_deny": "Negar",
        "permitDocShr_allow": "Permitir",
        "reqDocShr_req_doc_share_access": "Solicitar Acesso a Compartilhamento de Documentos",
        "reqDocShr_access_permission_host": "Para habilitar o compartilhamento de documentos, voc\u00ea precisa da permiss\u00e3o de acesso do host.",
        "reqDocShr_plz_wait": "Por favor Aguarde por",
        "reqDocShr_permission_start_doc_share": "(Anfitri\u00e3o) permiss\u00e3o para iniciar o compartilhamento de documentos.",
        "reqDocShr_cancel": "Cancelar",
        "reqDocShr_doc_share": "Compartilhe Documentos!",
        "showDocViewback": "Voltar",
        "showDocViewdoc_title_name": "Nome do T\u00edtulo do Documento",
        "showDocViewnext": "Pr\u00f3ximo",
        "showDocViewprevious": "Prev",
        "showDocViewpage_of": "p\u00e1gina de",
        "borcstScreen_alert_unsupported": "Ordem de execu\u00e7\u00e3o n\u00e3o suportada. Os formatos suportados s\u00e3o webrtc e hls.",
        "borcstScreen_alert_no_stream_spec": "Nenhum fluxo especificado. Por favor adicione ?id={STREAM_ID} \u00e0 URL",
        "borcstScreen_Plz_enable_js": "Para visualizar este v\u00eddeo, habilite o JavaScript, e considere a possibilidade de atualizar para um navegador web que",
        "borcstScreen_support_HTML5": "suporte v\u00eddeo HTML5",
        "borcstScreen_steam_play_when_live": "O v\u00eddeo vai ser executado quando a transmiss\u00e3o estiver ao vivo",
        "broadcast_alert_unsupported": "formato n\u00e3o suportado. Os formatos suportados s\u00e3o webrtc e hls.",
        "broadcast_alert_no_stream_spec": "Nenhum fluxo especificado. Por favor adicione ?id={STREAM_ID} \u00e0 URL",
        "broadcast_enter_your_name": "Por favor insira o seu nome",
        "broadcast_enter_your_email": "Por favor insira o seu e-mail",
        "broadcast_user_details": "Detalhes do Usu\u00e1rio",
        "broadcast_submit_next": "Submeter",
        "broadcast_Plz_enable_js": "Para visualizar este v\u00eddeo, habilite o JavaScript, e considere a possibilidade de atualizar para um navegador web que",
        "broadcast_support_HTML5": "suporte v\u00eddeo HTML5",
        "broadcast_steam_play_when_live": "O v\u00eddeo vai ser executado quando a transmiss\u00e3o estiver ao vivo",
        "brdcstConf_whiteboard": "Quadro branco",
        "brdcstConf_screen_share": "Compartilhar tela",
        "brdcstConf_participants": "participantes",
        "brdcstConf_broadcast": "Transmiss\u00e3o",
        "brdcstConf_cancel": "Cancelar",
        "brdcstConf_right": "direita",
        "brdcstPart_broadcast_list": "Lista de transmiss\u00e3o",
        "calender_scheduled_calendar": "Calend\u00e1rio de Hor\u00e1rios",
        "calender_select_all": "Selecione Todos",
        "calender_calender": "Calend\u00e1rio",
        "calender_daily": "Dia",
        "calender_weekly": "Semanalmente",
        "calender_month": "M\u00eas",
        "calender_today": "Hoje",
        "contact_alert_already_in_call": "Voc\u00ea n\u00e3o pode chamar outro usu\u00e1rio, quando j\u00e1 est\u00e1 em chamada",
        "contact_alert_user_offline": "Usu\u00e1rio Offline",
        "meetReminder_scheduled_confirm": "Confirma\u00e7\u00e3o de hor\u00e1rio",
        "meetReminder_meeting_has_been_scheduled": "A seguinte Reuni\u00e3o foi agendada para este momento",
        "meetReminder_start_meeting": "Inicie esta reuni\u00e3o",
        "meetReminder_join_meeting": "Participe deste Encontro",
        "meetReminder_quick_start_another_meeting": "In\u00edcio instant\u00e2neo Outra Reuni\u00e3o",
        "myMeetItem_start": "In\u00edcio",
        "myMeetItem_for": "para",
        "myMeetItem_minutes": "Minutos",
        "myMeetItem_host": "Anfitri\u00e3o:",
        "myMeet_my_meetings": "Minhas reuni\u00f5es",
        "myMeet_search_my_meetings": "Pesquisar minhas reuni\u00f5es",
        "noScheMeet_no_meeting_scheduled\t": "o Reuni\u00f5es agendadas",
        "noScheMeet_no_upcoming_meetings\t": "Voc\u00ea ainda n\u00e3o tem nenhuma reuni\u00e3o agendada. Voc\u00ea pode criar uma nova agenda ou iniciar rapidamente sua reuni\u00e3o agora.",
        "noScheMeet_create_schedule": "Agendar uma reuni\u00e3o",
        "noScheMeet_quick_start": "In\u00edcio instant\u00e2neo",
        "noScheMeet_or": "OU",
        "roomSet_alert_select_license": "Por favor, selecione a licen\u00e7a",
        "roomSet_manage_licenses": "Gerenciar Licen\u00e7as",
        "roomSet_package": "Pacote",
        "roomSet_no_of_rooms": "N\u00famero de Salas",
        "roomSet_no_of_user": "N\u00famero de Usu\u00e1rios",
        "roomSet_purchased_on": "Comprado em",
        "roomSet_expired_on": "Expirado em",
        "roomSet_create_room": "Criar Sala",
        "dashboard_alert_cannot_start": "Voc\u00ea n\u00e3o pode come\u00e7ar a reuni\u00e3o, quando estiver em outra chamada",
        "dashboard_alert_cannot_join": "Voc\u00ea n\u00e3o pode participar da reuni\u00e3o, quando estiver em outra chamada",
        "dashboard_quick_start_conf": "REUNI\u00c3O INSTANT\u00c2NEA",
        "dashboard_loading": "Carregando...",
        "dashboard_maintenance_msg": "Por favor, note que estaremos experimentando um downtime de servidor no dia 22 de abril de 2020 das 20h30 at\u00e9 as 21h00 devido \u00e0 rotina de manuten\u00e7\u00e3o.",
        "addCam_add_device": "Adicione dispositivo",
        "addSipDev_add_sip_details": "Adicione detalhes SIP",
        "addSipDev_sip_address": "Endere\u00e7o SIP:",
        "addSipDev_connect": "Conecte-se",
        "addSipDev_enter_sip_address": "Digite o endere\u00e7o SIP",
        "confNotf_notification": "Notifica\u00e7\u00f5es",
        "confNotf_user_name": "Nome do Usu\u00e1rio",
        "confNotf_want_to_join": "quer se juntar a esta sala de reuni\u00f5es.",
        "confNotf_allow": "Permitir",
        "confNotf_deny": "Negar",
        "confNotf_ask_question": "Fa\u00e7a uma pergunta.",
        "confNotf_send_msg_lobby": "enviou uma mensagem do Hall de entrada.",
        "lobby_participants_loddy": "Participantes do lobby",
        "lobby_pramote_all": "Autorizar Todos",
        "lobby_user_name": "Nome do Usu\u00e1rio",
        "removeUserDlg_manage_user_selecting": "Gerencie este usu\u00e1rio selecionando qualquer uma destas op\u00e7\u00f5es:",
        "removeUserDlg_user_name": "Nome do Usu\u00e1rio",
        "removeUserDlg_block_participant": "Bloquear participante",
        "removeUserDlg_send_to_lobby": "Enviar para a sala de espera",
        "removeUserDlg_remote_participant": "Remover Participante",
        "removeUserDlg_close": "FECHAR",
        "PartList_participants_in_lobby": "Participantes na sala de espera",
        "PartList_alert_something_went_wrong": "Algo deu errado, por favor tente novamente",
        "PartList_in_webinar": "Em Webinar",
        "PartList_promote_all": "Promover Todos",
        "waitingRoom_waiting_room": "Sala de Espera",
        "waitingRoom_plz_wait_host_allow": "Por favor, aguarde at\u00e9 que o anfitri\u00e3o permita a sua participa\u00e7\u00e3o na reuni\u00e3o.",
        "waitingRoom_chat": "Bate-Papo",
        "waitingRoom_mins": "mins",
        "waitingRoom_no_msg_yet": "Sem Mensagens, ainda!",
        "waitingRoom_no_msg_from_host": "Nenhuma mensagem do hospedeiro.",
        "waitingRoom_say_something": "Diga Algo!",
        "userRemoved_you_removed_by_host": "Voc\u00ea foi retirado da Lista de Participantes pelo anfitri\u00e3o",
        "invitSlide_invite": "Convidar",
        "invitSlide_add_email": "Adicionar e-mails",
        "invitSlide_add": "Adicionar",
        "invitSlide_broadcast_list": "Link de Transmiss\u00e3o",
        "invitSlide_invite_people_to_meeting": "Convide pessoas para esta reuni\u00e3o, digite e adicione e-mails \u00e0 lista para enviar o convite.",
        "invitSlide_err_invalid_id": "Identifica\u00e7\u00e3o de e-mail inv\u00e1lido",
        "particiChat_say_something": "Diga Algo!",
        "particiChat_no_chat_msg": "Sem mensagens de bate-papo ainda!",
        "particiChat_start_conversation": "Comece a Conversar para ver as mensagens aqui.",
        "coBrows_host_share-video": "Por favor, aguarde! O anfitri\u00e3o est\u00e1 prestes a compartilhar um v\u00eddeo",
        "lobbyPartici_no_participants": "Sem Participantes",
        "lobbyPartici_no_participants_lobby": "N\u00e3o h\u00e1 nenhum participante no lobby.",
        "simulcast_alert_decline_access": "O anfitri\u00e3o recusou o acesso",
        "simulcast_invalid_url": "URL invalida",
        "simulcast_no_host_available": "N\u00e3o parece haver anfitri\u00e3o dispon\u00edvel no momento, por favor, torne-se anfitri\u00e3o para compartilhar a tela",
        "conference_access_to_participants": "Acesso aos Participantes",
        "conference_screen_share": "Compartilhar tela",
        "conference_chat": "Bate-Papo",
        "conference_lock_all": "Tranque tudo",
        "conference_lock_conf": "Tranque o acesso \u00e0 reuni\u00e3o",
        "conference_become_host": "Seja o anfitri\u00e3o",
        "conference_you_are_host": "Voc\u00ea \u00e9 Anfitri\u00e3o",
        "conference_share": "Compartilhar",
        "conference_document_share": "Partilha de documentos",
        "conference_content_share": "Partilha de conte\u00fado",
        "conference_more": "Mais...",
        "conference_alert_no_host_aval_screen": "N\u00e3o parece haver anfitri\u00e3o dispon\u00edvel no momento, por favor, torne-se anfitri\u00e3o para compartilhar a tela",
        "conference_alert_no_host_aval_doc": "N\u00e3o parece haver anfitri\u00e3o dispon\u00edvel neste momento, por favor, torne-se anfitri\u00e3o para compartilhar o documento",
        "conference_alert_err_sip_device": "Erro ao conectar o dispositivo SIP",
        "conference_alert_host_decline_access": "o anfitri\u00e3o recusou o acesso",
        "conference_alert_invalid_url": "URL invalida",
        "conference_unmute_mic_start_talk": "Sem som no microfone para come\u00e7ar a falar",
        "confTools_new_whiteboard": "Seu Novo Quadro Branco Chega aqui",
        "confTools_video_sync": "Sua Sincroniza\u00e7\u00e3o de V\u00eddeo",
        "confTools_microphone": "Microfone",
        "confTools_type_text_here": "Digite seu texto...",
        "confTools_whiteboard": "Quadro branco",
        "confLibs_alert_broadcast_screen_failed": "Transmiss\u00e3o da tela falhou",
        "confLibs_broadcast_failed": "Falha na Transmiss\u00e3o",
        "chatContainer_say_something": "Diga Algo!",
        "chatContainer_no_chat_msg": "Sem mensagens de bate-papo ainda!",
        "chatContainer_start_conversation": "Comece a Conversar para ver as mensagens aqui.",
        "fmyRoom_ree": "Gr\u00e1tis",
        "meetRec_video_rec_meeting_name": "Grava\u00e7\u00f5es de V\u00eddeo do Nome da Reuni\u00e3o",
        "meetRec_page_view": "Visualizar p\u00e1gina:",
        "recListView_title_name_rec": "Nome do T\u00edtulo da Grava\u00e7\u00e3o",
        "recListView_name_user": "Nome do usu\u00e1rio",
        "instavc_allow_Notes": "Permitir apontamentos",
        "brwUpg_req_fun_not_avail": "Funcionalidade necess\u00e1ria n\u00e3o dispon\u00edvel em seu navegador",
        "brwUpg_meeting_req_fun_sup": "Este servi\u00e7o de reuni\u00e3o requer uma funcionalidade n\u00e3o suportada por seu navegador.",
        "brwUpg_plz_upgrade": "Por favor, atualize, mude para um navegador diferente, ou verifique suas configura\u00e7\u00f5es. Navegadores suportados:",
        "header_content": "Conte\u00fado (Beta)",
        "header_loading": "Carregando...",
        "crtePln_plz_enter_name": "Favor digitar o nome do plano",
        "crtePln_plz_amount_Plan": "Favor inserir o valor do plano",
        "crtePln_plz_enter_des": "Favor digitar a descri\u00e7\u00e3o",
        "crtePln_Plz_sel_product": "Por favor, selecione o Produto",
        "crtePln_plz_sel_no_host": "Favor selecionar o n\u00famero de anfitri\u00f5es",
        "crtePln_plz_enter_no_participants": "Por favor, digite o n\u00famero de participantes",
        "crtePln_plz_enter_roomno": "Por favor, digite o n\u00famero de salas",
        "crtePln_select_period": "Favor selecionar o per\u00edodo",
        "crtePln_sel_interval": "Favor selecionar o intervalo",
        "crtePln_update_succ": "Atualizado com sucesso",
        "crtePln_err_creating_plan": "Erro na cria\u00e7\u00e3o do plano",
        "brdcRaise_alert_question_50_char": "Sua pergunta deve ter no m\u00ednimo 50 caracteres",
        "cofCom_no_part_lobby": "N\u00e3o h\u00e1 nenhum participante no lobby.",
        "cofCom_no_participants": "Sem Participantes",
        "cofCom_say_something": "Diga Algo!",
        "confmUser_plz_provide_name_email": "Por favor, forne\u00e7a seu nome e e-mail",
        "confmUser_notes": "Notas",
        "confSetup_telepresence": "Telepresen\u00e7a",
        "confSetup_alert_not_generate_meetingID": "N\u00e3o \u00e9 capaz de gerar identifica\u00e7\u00e3o de reuni\u00e3o, tente novamente mais tarde",
        "confSetup_auth_part_can_join": "Somente Participantes Autorizados podem participar!",
        "confSetup_invalid_link": "Link inv\u00e1lido",
        "confSetup_meeting_notyet_started": "Esta reuni\u00e3o ainda n\u00e3o foi iniciada. Por favor, junte-se mais tarde! Se voc\u00ea \u00e9 um anfitri\u00e3o, por favor, fa\u00e7a o login na InstaVC e inicie a reuni\u00e3o",
        "confSetup_meeting_locked_byhost": "Esta reuni\u00e3o \u00e9 bloqueada pelo anfitri\u00e3o",
        "confSetup_meeting_notyet_started_wait_host": "Esta reuni\u00e3o ainda n\u00e3o foi iniciada. Por favor, espere que o anfitri\u00e3o inicie a reuni\u00e3o!",
        "newChat_panel_chat": "Painel de bate-papo",
        "newChat_webinar_chat": "Bate-papo de Webinar",
        "instaVc_server_busy": "O Servidor est\u00e1 ocupado! Tentando conectar outro servidor...",
        "instaVc_server_busy_reload_tojoin": "O Servidor est\u00e1 ocupado! Recarregar para participar da reuni\u00e3o",
        "instaVc_broadcast_scr_failed": "Transmiss\u00e3o da tela falhou",
        "instaVc_broadcast_failed": "Falha na Transmiss\u00e3o",
        "instaVc_waiting_for_host": "Esperando por um v\u00eddeo hospede",
        "modlCreate_plz_enter_title": "Por favor, digite o t\u00edtulo",
        "modlCreate_questionnaire": "Question\u00e1rio",
        "modlCreate_questionnaire_name": "Nome do Question\u00e1rio",
        "modlCreate_desc": "Descri\u00e7\u00e3o",
        "modlCreate_cancle": "Cancelar",
        "modlCreate_poll": "Enquete",
        "mdlCretPoll_plz_enter_quest": "Por favor, insira a pergunta",
        "mdlCretPoll_option_should_not_same": "As op\u00e7\u00f5es n\u00e3o devem ser as mesmas",
        "mdlCretPoll_plz_enter_option": "Por favor, digite as op\u00e7\u00f5es",
        "mdlCretPoll_plz_select_type_option": "Favor selecionar o tipo de op\u00e7\u00f5es",
        "mdlCretPoll_alert_server_error": "Erro interno do servidor, por favor tente novamente",
        "mdlCretPoll_update_ques": "Alterar Pergunta",
        "mdlCretPoll_create_ques": "Criar Pergunta",
        "mdlCretPoll_Question": "Pergunta",
        "mdlCretPoll_enter_que_here": "Digite aqui sua pergunta",
        "mdlCretPoll_single_sel_multi_choice": "Selec\u00e7\u00e3o \u00danica Escolha M\u00faltipla",
        "mdlCretPoll_enter_ans_choice": "Digite as op\u00e7\u00f5es de resposta, e marque qual resposta est\u00e1 correta",
        "mdlCretPoll_end": "fim",
        "mdlCretPoll_cancle": "Cancelar",
        "viewQues_viewQuest_delete_successfully": "Eliminado com sucesso",
        "viewQues_viewQuest_server_error": "Erro interno do servidor, por favor tente novamente",
        "viewQues_viewQuest_plz_try_again": "por favor tente novamente",
        "mngContent_mngContent_content": "Conte\u00fado",
        "mngContent_mngContent_participants": "Participantes",
        "mngContent_mngContent_grade": "Notas",
        "mngContent_mngContent_assign_quest": "Atribuir Question\u00e1rio",
        "mngContent_mngContent_fav_prog_lang": "Qual \u00e9 a sua linguagem de programa\u00e7\u00e3o favorita?",
        "mngContent_mngContent_grade_settings": "Ajustes de Notas",
        "mngContent_mngContent_pollings": "Vota\u00e7\u00f5es",
        "questStepper_you_cant_skip": "Voc\u00ea n\u00e3o pode pular um passo que n\u00e3o \u00e9 opcional.",
        "questStepper_back": "Voltar",
        "questStepper_skip": "Pular",
        "questStepper_next": "Pr\u00f3ximo",
        "showQue_obtained_marks": "Marcas Obtidas:",
        "viewUser_your_grade": "Sua classifica\u00e7\u00e3o:",
        "package_one_host": "1 Anfitri\u00e3o e",
        "package_participants": "Participantes",
        "package_recordings": "Gravando",
        "package_concurrentUser_room2": "20 Usu\u00e1rios simult\u00e2neos \/ 2 Salas",
        "package_concurrentUser_room5": "30 Usu\u00e1rios simult\u00e2neos \/ 5 Salas",
        "package_concurrentUser_room20": "100 Usu\u00e1rios simult\u00e2neos \/ 20 Salas",
        "package_concurrentUser_room30": "200 Usu\u00e1rios simult\u00e2neos \/ 30 Salas",
        "package_concurrentUser_room50": "500 Usu\u00e1rios simult\u00e2neos \/ 50 Salas",
        "pastMeeting_chat_history_deleted": "hist\u00f3rico de bate-papo apagado com sucesso",
        "pastMeeting_plz_try_again": "por favor tente novamente",
        "pastMeeting_past_meeting_dtls": "Detalhes da Reuni\u00f5es completadas",
        "pastMeeting_past_meeting": "Reuni\u00f5es completadas",
        "pastMeeting_room_name": "Nome da sala:",
        "pastMeeting_duration": "Dura\u00e7\u00e3o:",
        "pastMeeting_download_parti_atten": "Download Participantes",
        "pastMeeting_download_webinar": "Download do Webinar",
        "pastMeeting_participants": "Participantes",
        "pastMeeting_attendees": "Participantes",
        "pastMeeting_chat_history": "Hist\u00f3rico do bate-papo",
        "pastMeeting_notes": "Notas",
        "pastMeeting_recordings": "Grava\u00e7\u00f5es",
        "pastMeeting_webinar_user": "Usu\u00e1rios do Webinar",
        "pastMeeting_webinar_chat": "Bate-papo de Webinar",
        "pastMeeting_no_participants_in_meeting": "Nenhum Participante nesta reuni\u00e3o!",
        "pastMeeting_no_parti_attended_meeting": "N\u00e3o houve participantes que compareceram a esta reuni\u00e3o.",
        "pastMeeting_guest_list": "Lista de convidados",
        "pastMeeting_no_guest_in_meeting": "Nenhum convidado para esta reuni\u00e3o!",
        "pastMeeting_no_guests_were_invited ": "N\u00e3o houve convidados que tenham sido convidados ou participado desta reuni\u00e3o.",
        "pastMeeting_meeting_chat_history": "Hist\u00f3rico do bate-papo da r\u00e9uni\u00e3o",
        "pastMeeting_delete_all": "Remover tudo",
        "pastMeeting_no_chat_msg_inmeeting": "Nenhuma mensagem de bate-papo nesta reuni\u00e3o!",
        "pastMeeting_no_conver_during_meeting": "N\u00e3o houve conversas no bate-papo durante a reuni\u00e3o.",
        "pastMeeting_no_user_inwebinar": "Nenhum usu\u00e1rio neste webinar!",
        "pastMeeting_no_user_attand_webinar": "N\u00e3o houve usu\u00e1rios que compareceram a este webinar.",
        "pastMeeting_webinar_chat_history": "Hist\u00f3rico do bate-papo do Webinar",
        "pastMeeting_no_chat_msg_inwebinar": "Nenhuma mensagem de bate-papo neste webinar!",
        "pastMeeting_no_conver_during_webinar": "N\u00e3o houve conversas no bate-papo durante o webinar.",
        "pastMeeting_del_chat_history": "Excluir hist\u00f3rico do bate-papo",
        "pastMeeting_delete_all_chat_history": "Voc\u00ea tem certeza de que quer apagar permanentemente todo o hist\u00f3rico do bate-papo?",
        "pastMeeting_cancle": "Cancelar",
        "pastMeeting_delete": "Remover",
        "schedul_webinar_event": "Evento Webinar",
        "schedul_webinar_description": "Descri\u00e7\u00e3o do Webinar",
        "schedul_upload_custom_banner": "Fa\u00e7a o upload de um banner personalizado para o seu evento de Webinar.",
        "schedul_setting": "Configura\u00e7\u00f5es",
        "schedulView_time": "Hora",
        "schedulView_edit": "Editar",
        "schedulView_enable_training_mode": "Habilitar o Modo de Treinamento",
        "schedulView_audio_conference": "Reuni\u00e3o de \u00e1udio",
        "schedulView_enable_mix_video": "Habilitar v\u00eddeo misto por padr\u00e3o",
        "questions": "D\u00favidas",
        "Save": "Salvar",
        "answer_list": "Lista de respostas",
        "answer": "Respostas",
        "answered": "Respondidas",
        "correct_answer": "Resposta Correta",
        "participants": "Participantes",
        "please_try_again": "por favor tente novamente",
        "search": "Pesquisar ...",
        "question": "Pergunta",
        "questionnaire": "Question\u00e1rio",
        "content": "Conte\u00fado",
        "address_book": "Agenda de contatos",
        "view": "Ver",
        "rn": "RN",
        "quslsit_assig_que_list": "Lista de Question\u00e1rios Atribu\u00eddos",
        "quslsit_question_lists": "Listas de Question\u00e1rios",
        "quslsit_search": "Pesquisar ...",
        "quslsit_assign_new_ques": "Atribua um novo Question\u00e1rio para clicar no bot\u00e3o Atribuir Question\u00e1rio acima",
        "select_questionnaire": "Selecione Question\u00e1rio",
        "duration": "Dura\u00e7\u00e3o",
        "time_limit": "Tempo Limite",
        "show_results": "Mostrar Resultados",
        "content_group": "Grupo de Conte\u00fado",
        "list_assigned_ques": "Lista de perguntas atribu\u00eddas:",
        "mins": "mins",
        "marks": "Pontos",
        "questionnaire_list": "Lista de question\u00e1rios",
        "edit_content": "Editar conte\u00fado",
        "queStepper_cant_skip": "Voc\u00ea n\u00e3o pode pular um passo que n\u00e3o \u00e9 opcional.",
        "queStepper_skip": "Pular",
        "alert_server_error": "Erro interno do servidor, por favor tente novamente",
        "delete_successfully": "Eliminado com sucesso",
        "pastMeeting_rn": "RN",
        "plz_enter_marks": "Indique o n\u00famero de pontos atribu\u00eddos a esta pergunta",
        "plz_sel_crtans": "Favor selecionar a Resposta Corrente",
        "truefalse_choice": "Escolha Verdadeira \/ Falsa",
        "questionnarie": "Questionnarie",
        "content_title": "T\u00edtulo do conte\u00fado",
        "media_title": "T\u00edtulo da M\u00eddia",
        "add_url_add": "Adicionar endere\u00e7o URL...",
        "val_shuld_grtr_from_val": "para valor deve ser maior do que do Valor",
        "val_shuld_grtr_grade": "de valor deve ser maior do que a classe",
        "to_val": "para valorizar",
        "enable_hand_raise": "Habilitar a pergunta \"Levantar a M\u00e3o\/Ask\" em um webinar",
        "enable_waiting_room": "Habilitar Sala de Espera",
        "allow_only_invite": "Permitir Somente Convidados a Participar da reuni\u00e3o (Protegido por Senha)",
        "guest_login_anonymous": "O h\u00f3spede pode fazer login anonimamente",
        "give_name_webinar": "D\u00ea um nome ao seu webinar",
        "Webinar": "Webinar",
        "polls_list": "Lista de vota\u00e7\u00f5es",
        "no_poll_assign": "Ainda n\u00e3o foram designadas sondagens.",
        "poll_view": "Vis\u00e3o da Enquete",
        "filter_block": "bloco de filtro",
        "file_size": "Tamanho do arquivo",
        "select_room": "Por favor, selecione uma sala",
        "provide_event_name": "Por favor, forne\u00e7a um nome para o evento",
        "end_time_greater_start_time": "O tempo final deve ser maior do que o tempo de in\u00edcio!",
        "invite_people_meeting": "Convide pessoas para esta reuni\u00e3o",
        "download_ical": "Baixar o iCal",
        "download": "Download",
        "delete_meeting": "Eliminar esta reuni\u00e3o",
        "new_event": "Novo Evento",
        "end_repeat": "repetir o fim",
        "invite_users_timezone": "convidar usu\u00e1rios com o seguinte fuso hor\u00e1rio",
        "update_event": "Evento de atualiza\u00e7\u00e3o",
        "create_event": "Criar Evento",
        "wrong_password": "Senha incorreta",
        "college": "Col\u00e9gio",
        "university": "Universidade",
        "country": "Pa\u00eds",
        "time_period": "Per\u00edodo de tempo:",
        "currently_working_here": "Trabalhando atualmente aqui",
        "from": "De:",
        "graduated": "Graduado",
        "enter_description": "Digite aqui sua descri\u00e7\u00e3o...",
        "concentration": "Concentra\u00e7\u00e3o",
        "school": "Escola",
        "poll_question": "Pergunta de pesquisa",
        "create_poll_ques": "Criar pergunta de pesquisa",
        "options": "op\u00e7\u00f5es",
        "no_ques_assigned_yet": "Nenhuma pergunta foi designada ainda!",
        "create_new_ques_click_create_ques": "Criar uma nova Pergunta, para clicar no Bot\u00e3o Criar Pergunta acima",
        "enter_room_name": "Por favor, digite o nome do quarto",
        "billed_annually": "* Faturado anualmente",
        "subscribe_now": "Assine agora",
        "group": "Grupo",
        "sample_webinar": "Exemplo de Webinar",
        "webinar_affiliate": "Webinar sobre Afiliados",
        "webinar_one": "Webinar Um",
        "workspace": "\u00c1rea de trabalho",
        "company": "Empresa",
        "add_another": "Adicione outro",
        "assignment": "Tarefas",
        "assignments": "Tarefas",
        "create_new_poll": "Crie uma nova pergunta de pesquisa para atribu\u00ed-la",
        "plz_title_url": "Plaese Enter Title e Url",
        "votes": "Votos",
        "enter_content_title": "Favor digitar o t\u00edtulo do conte\u00fado",
        "failed_result": "resultado fracassado",
        "removed_successfully": "Removido com sucesso",
        "enter_duration": "Favor Inserir a dura\u00e7\u00e3o",
        "select_questionnarie": "Por favor, selecione a pergunta",
        "no_assignments": "Sem atribui\u00e7\u00f5es",
        "create_new_assignment": "Crie uma nova tarefa, para criar clique no bot\u00e3o Criar tarefa acima",
        "create_assignment": "Crie uma tarefa",
        "assignments_list": "Lista de tarefas",
        "assignment_description": "Descri\u00e7\u00e3o da tarefa",
        "assignment_name": "Nome da tarefa",
        "update_assignment": "Atribui\u00e7\u00e3o de atualiza\u00e7\u00e3o",
        "add_new_assignment": "Adicionar Nova Atribui\u00e7\u00e3o",
        "assignment_list": "Lista de tarefas",
        "assign_assignments": "Atribui\u00e7\u00e3o de tarefas",
        "assigned_assignments_list": "Lista de tarefas Atribu\u00eddas",
        "assignment_answer": "Resposta \u00e0 tarefa",
        "no_assig_submitted": "Nenhuma tarefa apresentada ainda.",
        "no_assig_submitted_participants": "Ainda n\u00e3o foram apresentadas tarefas de nenhum participante.",
        "view_assignment": "Veja a tarefa",
        "assigned_participants": "Participantes Designados",
        "select_assignment": "Selecione a atribui\u00e7\u00e3o",
        "fill_your_assignment": "Preencha sua designa\u00e7\u00e3o",
        "submit_assignment": "Enviar uma tarefa",
        "assignment_details": "Detalhes da tarefa",
        "assignment_submit_success": "Tarefa denviada com sucesso",
        "assignment_saved_success" : "Tarefa salva com sucesso",
        "how_to_get_started": "Como come\u00e7ar.",
        "create_new_room": "Criar novo quarto",
        "create_room_add_participants": "Crie uma nova sala e acrescente participantes \u00e0 sala.",
        "repeats_every": "Repete cada",
        "now": "Agora",
        "Plz_enter_title_meeting": "Por favor, digite o t\u00edtulo da Reuni\u00e3o",
        "no_start_time": "Sem hora de in\u00edcio encontrada",
        "no_end_time": "Sem tempo final encontrado",
        "item_id_not_found": "Item ID N\u00e3o encontrado",
        "msg_title_not_found": "T\u00edtulo da Mensagem N\u00e3o encontrado",
        "plz_join_conf_clicking_link": "Por favor, participe da reuni\u00e3o clicando no seguinte link",
        "hi_there": "Ol\u00e1,",
        "room_permanently": "Quarto Permanente?",
        "sure_want_to_delete_participants": "Voc\u00ea tem certeza de que quer excluir este participante?",
        "delete_participant": "Eliminar Participante",
        "add_email": "Adicionar e-mail",
        "add_participants_to_the_room": "Adicione os participantes a esta sala digitando seu endere\u00e7o de e-mail na caixa de entrada abaixo.",
        "no_of_users": "N\u00famero de usu\u00e1rios",
        "edit_the_name": "Voc\u00ea pode editar o nome, e o n\u00famero de usu\u00e1rios desta sala.",
        "delete_room": "Excluir sala",
        "are_you_sure_delete": "Voc\u00ea tem certeza, voc\u00ea quer apagar",
        "delete_this_room": "Apagar esta sala",
        "delete_room_will_parmanently": "Ao clicar em Excluir, esta parte ser\u00e1 exclu\u00edda permanentemente",
        "enable_storage": "Habilitar o armazenamento",
        "enable_recordings": "Habilitar grava\u00e7\u00f5es",
        "enable_SIP": "Habilitar SIP",
        "addons": "M\u00f3dulos adicionais",
        "expires_on": "Expira em",
        "purchased_on": "Comprado em",
        "more_storage": "Mais Armazenamento",
        "total_storage": "Armazenamento total",
        "storage_occupied": "Armazenagem Ocupada",
        "added": "Adicionado",
        "open_graphs": "Gr\u00e1ficos abertos",
        "participant_controls": "Controles dos participantes",
        "dashboard_controls ": "Controles do painel de controle",
        "start_meeting": "Iniciar uma reuni\u00e3o",
        "join_a_meeting": "Participe de uma reuni\u00e3o",
        "meeting_settings": "Configura\u00e7\u00f5es das reuni\u00f5es",
        "videos_to_get_started": "V\u00eddeos para come\u00e7ar",
        "if_you_are_host": "Se voc\u00ea \u00e9 um anfitri\u00e3o, clique no bot\u00e3o abaixo para iniciar a reuni\u00e3o",
        "guest_link": "Link do convidado",
        "pay_now": "PAGUE AGORA",
        "promo_code_apply_here": "Tem um C\u00f3digo Promocional? Inscreva-se aqui",
        "per_month": "por m\u00eas",
        "billed_for": "faturado por",
        "gst_applicable": "Em conformidade com as normas governamentais, 18% adicionais de GST ser\u00e3o aplic\u00e1veis aos clientes na \u00cdndia.",
        "select_country_code": "Selecione o c\u00f3digo do pa\u00eds",
        "country_code": "C\u00f3digo do pa\u00eds",
        "enter_email_id": "Digite Email Id ...",
        "downLoad_chat": "Bate-papo DownLoad",
        "allow_guest_control_their": "Permitir que o convidado controle seu v\u00eddeo\/\u00e1udio",
        "below_list_people_invited": "Abaixo est\u00e1 a lista de pessoas convidadas",
        "verification_code": "C\u00f3digo de verifica\u00e7\u00e3o",
        "open_link_enter_verify_code": "Abra o link URL abaixo, e digite seu c\u00f3digo de verifica\u00e7\u00e3o para integra\u00e7\u00e3o",
        "social_media_integration": "Integra\u00e7\u00e3o das m\u00eddias sociais",
        "integrate_social_media_platform": "Voc\u00ea pode integrar plataformas de m\u00eddia social, ao clicar em um bot\u00e3o, voc\u00ea obter\u00e1 o c\u00f3digo de verifica\u00e7\u00e3o, utiliz\u00e1-lo para integra\u00e7\u00e3o.",
        "plz_enter_val_email": "Favor inserir e-mail v\u00e1lido",
        "record": "Registro",
        "in_conference": "Em Reuni\u00e3o",
        "password_if_any": "Senha (se houver)",
        "stop_send": "Stop &amp; Enviar",
        "broadcast_not_enabled_meeting": "Transmiss\u00e3o n\u00e3o habilitada para esta reuni\u00e3o",
        "facebook_url": "URL do Facebook",
        "enter_facebook_url": "Digite a URL RTMB do Facebook",
        "add_facebook_url": "Adicionar a URL RTMP do Facebook",
        "youtube_url": "URL do Youtube",
        "enter_youtube_url": "Digite a URL do Youtube RTMB",
        "add_youtube_url": "Adicionar o URL do Youtube RTMP",
        "register": "Cadastre-se",
        "time_of_program": "Hora do programa",
        "date_of_program": "Data do Programa",
        "name_program_interested": "Nome do Programa Interessado",
        "email_id": "Identifica\u00e7\u00e3o por e-mail",
        "dob": "Data de Nascimento",
        "applicant_address": "Endere\u00e7o do candidato",
        "qualification": "Qualifica\u00e7\u00e3o",
        "designation": "Designa\u00e7\u00e3o",
        "org_name_address": "Nome e endere\u00e7o da organiza\u00e7\u00e3o",
        "fullname_participant": "Nome completo do Participante",
        "registration_form": "Formul\u00e1rio de Inscri\u00e7\u00e3o",
        "thanks_submitting_data": "Obrigado por enviar seus dados",
        "manage_content": "Gerenciar conte\u00fado",
        "API_settings": "Configura\u00e7\u00f5es de API",
        "schedule_API": "Programa\u00e7\u00e3o API",
        "image_upload_success": "imagem carregada com sucesso",
        "less_than_10MB": "favor carregar arquivo com menos de 10MB",
        "at_time_of_event": "No momento do evento",
        "min_5": "5 minutos antes",
        "min_10": "10 minutos antes",
        "min_15": "15 minutos antes",
        "min_30": "30 minutos antes",
        "hrs_1": "1 hora antes",
        "hrs_2": "2 horas antes",
        "day_1": "1 dia antes",
        "day_2": "2 dias antes",
        "jan": "Jan",
        "feb": "Fev",
        "mar": "Mar",
        "apr": "Apr",
        "may": "Maio",
        "jun": "Jun",
        "jul": "Jul",
        "aug": "Ago",
        "sep": "Set",
        "oct": "Out",
        "nov": "Nov",
        "dec": "Dez",
        "mon": "Seg",
        "tue": "Ter",
        "wed": "Qua",
        "thu": "Qui",
        "fri": "Sex",
        "sat": "S\u00e1b",
        "sun": "Dom",
        "all_day": "o dia todo",
        "starts_ddmmyyyy": "inicia (dd\/mm\/aaaaa)",
        "ends_ddmmyyyy": "fins (dd\/mm\/aaaaa)",
        "ddmmyyyy": "(dd\/mm\/aaaaa)",
        "by_dayweek": "Por dia\/semana",
        "never": "Nunca",
        "on_date": "Na data",
        "alert": "Alerta",
        "multi_factor_auth": "Habilitar Autentica\u00e7\u00e3o Multi-fator (MFA)",
        "advanced_settings": "Configura\u00e7\u00f5es avan\u00e7adas",
        "verify_Otp": "Verificar Otp",
        "otp_sent_mobile": "Digite o OTP enviado para seu n\u00famero de celular",
        "update_mobile": "Atualiza\u00e7\u00e3o m\u00f3vel",
        "mob_verfi_pending": "Sua verifica\u00e7\u00e3o m\u00f3vel est\u00e1 pendente.",
        "mobile_number": "N\u00famero de celular",
        "enter_Otp": "Entre em Otp",
        "enter_mob_no": "por favor, digite o n\u00famero do celular",
        "sure_want_to_delete_rec": "Tem certeza de que voc\u00ea quer T0 apagar a grava\u00e7\u00e3o",
        "delete_recording": "Excluir Grava\u00e7\u00e3o",
        "invalid_credentials": "Credenciais inv\u00e1lidas",
        "join_conf_by_clicking_link": "Por favor, junte-se \u00e0 reuni\u00e3o clicando no seguinte link",
        "valid_room_name": "Favor informar o nome v\u00e1lido da sala",
        "internal_server_error": "Erro interno do servidor, por favor tente novamente",
        "room_limit_exceed": "Limite de salas excedido",
        "cant_start_meeting_already_goingon": "N\u00e3o se pode iniciar uma reuni\u00e3o, pois j\u00e1 existe uma reuni\u00e3o em andamento.",
        "video_recordings": "Grava\u00e7\u00f5es de V\u00eddeo",
        "hindi": "Hindi",
        "gujarati": "Gujarati",
        "kannada": "Kannada",
        "marathi": "Marathi",
        "tamil": "T\u00e2mil",
        "telugu": "Telugu",
        "urdu": "Urdu",
        "spanish": "Espanhol",
        "malyalam": "Malyalam",
        "italian": "Italiano",
        "bangla": "Bangla",
        "portuguese": "Portugu\u00eas",
        "create_new_content": "Criar conte\u00fado",
        "create_new_group": "Criar Grupo",
        "noScheMeet_no_meeting_scheduled": "Nenhuma reuni\u00e3o agendada",
        "noScheMeet_no_upcoming_meetings": "Nenhuma reuni\u00e3o est\u00e1 agendada ainda. Voc\u00ea pode criar uma nova programa\u00e7\u00e3o ou iniciar sua confer\u00eancia instant\u00e2nea agora.",
        "whiteboard": "Quadro branco",
        "video_syn": "Sincroniza\u00e7\u00e3o de V\u00eddeo",
        "notepad": "Bloco de notas",
        "enable_handraise": "Habilitar o levantamento das m\u00e3os em um webinar",
        "F": "Configura\u00e7\u00f5es das reuni\u00f5es",
        "social_integration": "Integra\u00e7\u00e3o social",
        "advanced": "Avan\u00e7ado",
        "add_youtube": "Adicionar Youtube",
        "plugins": "Plugins",
        "subtitles": "Subt\u00edtulos",
        "view_local": "Ver Local",
        "hide_local": "Ocultar local",
        "switch_conf_mode": "Mudar para o modo confer\u00eancia",
        "switch_pres_mode": "Mudar para o modo apresentador",
        "conference_locked": "Confer\u00eancia Trancada",
        "conference_unlocked": "Confer\u00eancia Desbloqueada",
        "sharing_tools": "Ferramentas de compartilhamento",
        "recording": "Grava\u00e7\u00e3o",
        "plz_wait_host_shr_video": "Espere, por favor ! O organizador est\u00e1 prestes a compartilhar um v\u00eddeo",
        "supported_sources_include": "As fontes suportadas incluem",
        "team_plugins": "Plugins TEAMS",
        "open": "Ver",
        "click": "Clique",
        "more_app": "Mais aplicativos",
        "upload_custom_app": "Upload de aplicativos personalizados",
        "download_team_plugin": "Baixe o plugin TEAMS",
        "install_mng_app_microsoft_team": "Como instalar e gerenciar aplicativos nas Equipes da Microsoft",
        "microsoft_step1": "Etapa 1: Abra o Microsoft TEAMS",
        "microsoft_step1_para1": "Para come\u00e7ar, voc\u00ea deve primeiro baixar o plugin TEAMS em seu sistema, dispon\u00edvel no canto superior direito desta p\u00e1gina. Em seguida, abra o Microsoft TEAMS e escolha",
        "microsoft_step1_para2": "op\u00e7\u00f5es de navega\u00e7\u00e3o para visualizar os aplicativos do TEAMS.",
        "microsoft_step2": "Etapa 2: acessar mais aplicativos",
        "microsoft_step2_para1": "Aqui voc\u00ea pode encontrar alguns aplicativos junto com um bot\u00e3o chamado More Apps (Mais aplicativos). Clique",
        "microsoft_step2_para2": "para verificar ainda mais a op\u00e7\u00e3o de carregamento personalizado e outras op\u00e7\u00f5es de loja.",
        "microsoft_step3": "Etapa 3: Upload App",
        "microsoft_step3_para1": "Uma vez aberta a loja, voc\u00ea pode encontrar um",
        "microsoft_step3_para2": "op\u00e7\u00e3o na navega\u00e7\u00e3o \u00e0 esquerda. Clique sobre ela e fa\u00e7a o upload de seu aplicativo plugin baixado aqui.",
        "microsoft_step4": "Passo 4: Selecione o aplicativo InstaVC a partir do armazenamento",
        "microsoft_step4_para1": "Quando a janela abrir, selecione o plugin InstaVC do seu sistema que foi baixado (etapa 1) e clique",
        "microsoft_step5": "Etapa 5: Carregando o aplicativo Microsoft TEAMS",
        "microsoft_step5_para1": "Ap\u00f3s fazer o download do arquivo, ele \u00e9 carregado na lista de aplicativos do Microsoft TEAMS. Voc\u00ea pode clicar no aplicativo para visualiz\u00e1-lo e adicion\u00e1-lo \u00e0s equipes.",
        "microsoft_step6": "Passo 6: Abrir o InstaVC App",
        "microsoft_step6_para1": "Uma vez que voc\u00ea clique no aplicativo InstaVC, agora voc\u00ea pode visualizar os detalhes completos do aplicativo plugin juntamente com um bot\u00e3o para",
        "microsoft_step6_para2": "para adicionar este aplicativo Microsoft TEAMS",
        "microsoft_step7": "Etapa 7: InstaVC adicionado ao Microsoft TEAMS",
        "microsoft_step7_para1": "Seu aplicativo agora ser\u00e1 adicionado ao Microsoft TEAMS no menu de navega\u00e7\u00e3o \u00e0 esquerda para facilitar o uso e",
        "ddyymm": "DD\/MM\/YYYYY",
        "fill_details_for_webinar": "Por favor, preencha seus dados de registro para o Webinar em",
        "microsoft_teams": "Microsoft TEAMS",
        "microsoft_outlook": "Microsoft Outlook",
        "select_the_plan": "Por favor, selecione o plano",
        "enter_company_code": "Favor digitar o c\u00f3digo da empresa",
        "enter_code": "Por favor, digite o C\u00f3digo",
        "enter_valid_coupon_code": "Por favor, digite o c\u00f3digo do cupom v\u00e1lido",
        "coupon_alreadt_taken": "Este n\u00famero de cupom j\u00e1 foi usado",
        "error_updating_plan": "Erro ao atualizar seu plano",
        "unit": "unidade",
        "referrals": "Referentes",
        "sel_coupon": "Favor selecionar o cupom",
        "share_val_0_99": "O valor compartilhado deve estar entre 0 e 99",
        "enter_share_val": "Favor Inserir o valor da a\u00e7\u00e3o",
        "valid_commission_per": "Por favor, digite a porcentagem da comiss\u00e3o v\u00e1lida",
        "enter_commission_per": "Por favor, digite a porcentagem da Comiss\u00e3o",
        "enter_commission_name": "Por favor, digite o nome da Comiss\u00e3o",
        "sync_successfully": "Sincroniza\u00e7\u00e3o de usu\u00e1rios com sucesso",
        "room_deleted_suc": "Sala Eliminada com Sucesso",
        "lic_deleted_suc": "Licen\u00e7a Eliminada",
        "female": "Feminino",
        "male": "Macho",
        "insta_conference": "Insta Confer\u00eancia",
        "quick_meeting_by": "Reuni\u00e3o instant\u00e2nea por",
        "in_lobby": "Na sala de espera",
        "switch_to_broadcast": "V\u00e1 para a transmiss\u00e3o",
        "Broadcasters_5": "Emissoras (5)",
        "Participants_5": "Participantes (5)",
        "search_participants": "Procurar participantes",
        "host_controls": "Controles do organizador",
        "deleteroom": "Excluir sala",
        "pm": "PM",
        "am": "AM",
        "email_exist_inroom": "O email j\u00e1 existe nesta sala",
        "type_a_message": "Digite uma mensagem...",
        "please_enter_user_limit": "Insira o n\u00famero m\u00e1ximo de usu\u00e1rios",
        "user_limit_exceeded": "Ultrapassando o limite do n\u00famero de usu\u00e1rios poss\u00edvel",
        "sel_country_code": "Favor selecionar o c\u00f3digo do pa\u00eds",
        "available_storage": "Armazenamento dispon\u00edvel",
        "enter_phone_number": "Digite o n\u00famero de telefone",
        "startdate_less_enddate": "favor selecionar data de in\u00edcio menor que a data final",
        "havent_add_data": "Voc\u00ea ainda n\u00e3o adicionou nenhum dado, por favor, adicione dados.",
        "professional_skills": "Habilidades profissionais",
        "enter_prof_skills": "Entrar Compet\u00eancias profissionais",
        "Present_Invitations": "Convites atuais",
        "sending": "Envio em andamento ...",
        "sent": "Enviado",
        "invite_10ppl_only": "Voc\u00ea pode convidar apenas at\u00e9 10 pessoas",
        "join_meeting": "Junte-se \u00e0 reuni\u00e3o",
        "enter_doc_title": "Digite o t\u00edtulo do documento",
        "meeting_name": "Nome da reuni\u00e3o",
        "select_the_weeks": "Por favor, selecione as semanas",
        "grade": "Nota",
        "coference_ID": "ID da confer\u00eancia ...",
        "dont_find_room_meetingid": "N\u00e3o encontramos nenhuma sala associada a este ID de reuni\u00e3o",
        "wireless_share": "Participa\u00e7\u00e3o sem fio",
        "video_layout": "Layout do v\u00eddeo",
        "speaker_screen": "Tela do alto-falante",
        "presentation_screen": "Tela de apresenta\u00e7\u00e3o",
        "multiple_display": "Exibi\u00e7\u00e3o m\u00faltipla",
        "hellow": "Ol\u00e1",
        "enter_passcode": "Digite o c\u00f3digo de acesso",
        "another_meeting_stillon": "Outra reuni\u00e3o ainda est\u00e1 acontecendo, voc\u00ea n\u00e3o pode come\u00e7ar esta reuni\u00e3o agora mesmo",
        "SIP_device": "Dispositivo SIP",
        "instavc_whiteBoard": "InstaVC WhiteBoard",
        "speaker": "Orador",
        "instavc_notepad": "Bloco de notas InstaVC",
        "promote_in": "Promover em",
        "guest": "Convidado",
        "breakout_rooms": "Salas de descanso",
        "stop_broadcast": "Parar a transmiss\u00e3o",
        "noise_suppress": "Supress\u00e3o de ru\u00eddos",
        "echo_cancel": "Cancelamento de Echo:",
        "enter_name_here": "Digite aqui o nome",
        "Viewer": "Visualizador",
        "SIP_user": "Usu\u00e1rio SIP",
        "remove_participants": "Retirar Participantes",
        "disable_audio_video": "Desligue o \u00e1udio e o v\u00eddeo",
        "disable_audio": "Desativar \u00e1udio",
        "enable_audio": "Habilitar \u00e1udio",
        "disable_video": "Desativar v\u00eddeo",
        "enable_video": "Habilitar v\u00eddeo",
        "audio_video": "\u00c1udio & V\u00eddeo",
        "audio_only": "Somente \u00e1udio",
        "video_only": "Somente v\u00eddeo",
        "outlook_addins": "Suplementos do Outlook",
        "install_microsoft": "Como instalar e gerenciar add-ins no Microsoft Outlook",
        "outlook_step1": "Passo 1: Copiar Link e abrir Outlook",
        "first_copy_link": "Primeiro copie o link",
        "add_plugin_oulook": "daqui para adicionar o plugin \u00e0 sua vis\u00e3o.",
        "outlook_step2": "Etapa 2: Obter suplementos",
        "outlook_step2_para": "Agora abra o Outlook e na guia P\u00e1gina inicial, voc\u00ea encontrar\u00e1 uma op\u00e7\u00e3o \"Obter suplementos\". Clique nele para ver os suplementos e outras op\u00e7\u00f5es personalizadas.",
        "outlook_step3": "Etapa 3: Meus suplementos",
        "outlook_step3_para": "Agora voc\u00ea pode visualizar a p\u00e1gina de complementos com a navega\u00e7\u00e3o \u00e0 esquerda. V\u00e1 para Meus complementos para gerenciar seus complementos.",
        "outlook_step4": "Etapa 4: adicionar suplementos personalizados",
        "outlook_step4_para": "Aqui voc\u00ea encontrar\u00e1 um menu suspenso \"Adicionar um suplemento personalizado\" em \"Meus suplementos\". Selecione Adicionar do URL na lista suspensa.",
        "outlook_step5": "Passaggio 5: aggiunta di un componente aggiuntivo privato dall'URL",
        "outlook_step5_para": "Agora voc\u00ea pode ver uma janela solicitando que voc\u00ea adicione um suplemento privado do URL. Agora cole o link que voc\u00ea copiou (etapa 1) no campo de entrada e clique em OK, isso instala o add-in no Outlook.",
        "outlook_step6": "Etapa 6: InstaVC adicionado em meus suplementos do Outlook",
        "outlook_step6_para": "Depois de adicionar, voc\u00ea pode verificar o suplemento adicionado em sua lista \"Meus suplementos\". A partir da\u00ed, voc\u00ea pode gerenciar seus add-ins com mais detalhes.",
        "outlook_step7": "Passo 7: Fechar o Outlook e reabrir",
        "outlook_step7_para": "Dopo l'installazione, assicurati di chiudere Oultlook una volta e di riaprirlo per vedere l'estensione.",
        "outlook_step8": "Passo 8: Ir para Novos Itens",
        "outlook_step8_para": "Ap\u00f3s abrir o Outlook, agora voc\u00ea pode ir para \"Novas Reuni\u00f5es\" para ver a adi\u00e7\u00e3o de InstaVC no Outlook.",
        "outlook_step9": "Etapa 9: Abra uma reuni\u00e3o InstaVC",
        "outlook_step9_para": "Uma vez abertas as reuni\u00f5es, voc\u00ea ser\u00e1 aberto com uma janela de correio composto junto com o add-in InstaVC Meeting habilitado na barra superior sob a aba reuni\u00f5es.",
        "outlook_step10": "Passo 10: Criar cronograma",
        "outlook_step10_para": "Na p\u00e1gina de redigir e-mail, clique em Reuni\u00f5es Instavc para criar uma nova programa\u00e7\u00e3o de reuni\u00e3o. Se esta for a primeira vez que voc\u00ea entra em uma reuni\u00e3o Instavc, voc\u00ea ser\u00e1 conectado com o Login de reuni\u00e3o Instavc no lado direito. Para usu\u00e1rios j\u00e1 logados, eles ser\u00e3o direcionados para a lista de salas e podem agendar uma reuni\u00e3o.",
        "outlook_step11": "Etapa 11: Abra uma reuni\u00e3o InstaVC",
        "outlook_step11_para": "Voc\u00ea pode agendar o calend\u00e1rio de reuni\u00f5es no lado esquerdo e selecionar uma sala no lado direito para agendar uma reuni\u00e3o em uma sala espec\u00edfica. Depois de criar um calend\u00e1rio, voc\u00ea pode ver o bot\u00e3o \"Iniciar Reuni\u00e3o\". A partir da\u00ed, voc\u00ea pode clicar e iniciar a reuni\u00e3o.",
        "outlook_step12": "Etapa 12: Ver calend\u00e1rio no Calend\u00e1rio",
        "outlook_step12_para": "Depois de agendar uma reuni\u00e3o, na pr\u00f3xima vez em que abrir o calend\u00e1rio, voc\u00ea tamb\u00e9m poder\u00e1 verificar seus hor\u00e1rios no calend\u00e1rio.",
        "outlook_step13": "Etapa 13: reuni\u00e3o agendada",
        "outlook_step13_para": "Depois de agendar uma reuni\u00e3o, voc\u00ea pode ver o e-mail composto, bem como um bot\u00e3o \"Iniciar Reuni\u00e3o\" no lado direito.",
        "sel_declaration": "Favor selecionar a Declara\u00e7\u00e3o",
        "invalid_company_code": "C\u00f3digo de empresa inv\u00e1lido",
        "affiliate_acc": "Sua conta de afiliado est\u00e1 pendente de aprova\u00e7\u00e3o.",
        "aff_acc_approved": "Sua conta de afiliado \u00e9 aprovada.",
        "aff_acc_rej": "Sua conta de afiliado \u00e9 rejeitada.",
        "aff_TC": "Termos e condi\u00e7\u00f5es de afilia\u00e7\u00e3o",
        "ref_par_agreement": "ACORDO DE PARCERIA DE REFER\u00caNCIA",
        "aff_partner_agreement": "Este Contrato de Parceria, que consiste desta P\u00e1gina de Apresenta\u00e7\u00e3o e dos Termos Comerciais anexos (coletivamente, este \"Contrato\"), \u00e9 feito e celebrado por e entre PeopleLink Unified Communications Pvt Ltd (referido como PeopleLink) e \"Parceiro\" ou \"Voc\u00ea\". \"Parceiro\" ou \"Voc\u00ea\" significa a pessoa ou empresa (incluindo Nome da Empresa e outras informa\u00e7\u00f5es) detalhada no Formul\u00e1rio submetido. O Contrato se tornar\u00e1 obrigat\u00f3rio assim que Voc\u00ea preencher e enviar o Formul\u00e1rio do Programa de Indica\u00e7\u00e3o de Parceiro (\"Formul\u00e1rio\") para indicar sua aceita\u00e7\u00e3o deste Contrato. PeopleLink e Partner s\u00e3o \u00e0s vezes referidos como uma \"Parte\", e juntos como as \"Partes\".",
        "background": "Antecedentes",
        "aff_market_advertise": "PeopleLink oferece servi\u00e7os e solu\u00e7\u00f5es de comunica\u00e7\u00e3o unificada InstaVC. O parceiro deseja promover, comercializar e anunciar os produtos e solu\u00e7\u00f5es PeopleLink aos potenciais clientes PeopleLink (\"Refer\u00eancias\") atrav\u00e9s de seu(s) website(s) e outros canais de marketing, em conformidade com este Contrato.",
        "agreement": "Acordo",
        "aff_parties_agree": "As partes concordam com o seguinte:",
        "heading1": "COMPROMISSOS DO PROGRAMA",
        "heading2": "REFERENTES E COMISS\u00d5ES QUALIFICADAS.",
        "heading3": "PRAZO E RESCIS\u00c3O.",
        "heading4": "GERAL",
        "the_program": "O Programa.",
        "to_participate_program_partner": "Para participar do Programa, o Parceiro deve preencher a solicita\u00e7\u00e3o on-line de participa\u00e7\u00e3o no Programa encontrada no PeopleLink ou em seus sites associados (\"Site\") e entrar neste Acordo. O PeopleLink pode aceitar ou rejeitar qualquer inscri\u00e7\u00e3o a seu exclusivo crit\u00e9rio.",
        "aff_str11": "Acordos legais.",
        "aff_str12": "Promo\u00e7\u00e3o, Atividades de Indica\u00e7\u00e3o:",
        "aff_str13": "Atividades Proibidas.",
        "aff_str14": "Uso admiss\u00edvel das Marcas PeopleLink",
        "aff_str15": "Obriga\u00e7\u00f5es.",
        "aff_str16": "Relacionamento com o cliente.",
        "aff_str11_para": "Como parte de sua participa\u00e7\u00e3o no Programa e ao atuar como Parceiro do PeopleLink, o Parceiro concorda e consente com os termos deste Contrato e do Programa, as Diretrizes e quaisquer outras solicita\u00e7\u00f5es e regras estabelecidas pelo PeopleLink de tempos em tempos, a seu crit\u00e9rio razo\u00e1vel, em conex\u00e3o com a participa\u00e7\u00e3o cont\u00ednua do Parceiro no Programa e na promo\u00e7\u00e3o dos produtos, solu\u00e7\u00f5es e servi\u00e7os do PeopleLink para Refer\u00eancias. Em todas as suas atividades sob este Contrato e atividades espec\u00edficas relacionadas \u00e0 promo\u00e7\u00e3o do PeopleLink por parte do Parceiro, o Parceiro dever\u00e1 cooperar com o PeopleLink e agir de boa f\u00e9. Ao celebrar este Contrato, o Parceiro reconhece e aceita ainda os termos e regras estabelecidos nos Termos de Servi\u00e7o do PeopleLink.",
        "aff_str12_para": "O parceiro concorda em se engajar na promo\u00e7\u00e3o cont\u00ednua e ativa dos Produtos e Solu\u00e7\u00f5es PeopleLink em v\u00e1rios canais de marketing usando as Marcas Licenciadas e os Materiais de Marketing, e faz\u00ea-lo em conformidade com os termos deste Acordo.",
        "aff_str13_para": "O parceiro concorda em n\u00e3o associar Materiais de Marketing com conte\u00fado ilegal de qualquer forma, ou que de outra forma seja prejudicial, amea\u00e7ador, difamat\u00f3rio, obsceno, ofensivo, assediador, sexualmente expl\u00edcito, violento, discriminat\u00f3rio ou de outra forma censur\u00e1vel a crit\u00e9rio exclusivo do PeopleLink. O parceiro concorda em n\u00e3o enviar mensagens eletr\u00f4nicas n\u00e3o solicitadas a m\u00faltiplos destinat\u00e1rios n\u00e3o relacionados (\"Spamming\") na promo\u00e7\u00e3o dos servi\u00e7os e solu\u00e7\u00f5es do PeopleLink, ou de outra forma se envolver em qualquer outra forma de comunica\u00e7\u00e3o eletr\u00f4nica em massa proibida por lei em conex\u00e3o com atividades contempladas sob este Contrato.",
        "aff_str14_para1": "O parceiro concorda expressamente em cumprir com todos os termos aqui contidos na utiliza\u00e7\u00e3o das Marcas Licenciadas e na cria\u00e7\u00e3o de Materiais de Marketing.",
        "aff_str14_para2": "Atrav\u00e9s das Diretrizes e de outras formas, o PeopleLink dever\u00e1 fornecer especifica\u00e7\u00f5es e outras instru\u00e7\u00f5es de tempos em tempos quanto ao uso permitido pelo Parceiro das Marcas Licenciadas na cria\u00e7\u00e3o de Materiais de Marketing e na promo\u00e7\u00e3o do Servi\u00e7o PeopleLink. O Parceiro tamb\u00e9m concorda em cumprir com todas essas especifica\u00e7\u00f5es e instru\u00e7\u00f5es.",
        "aff_str14_para3": "O parceiro dever\u00e1 assegurar que todas as Marcas Licenciadas que aparecem em seus Materiais de Marketing estejam na forma aprovada pelo PeopleLink nas Diretrizes ou de outra forma, n\u00e3o dever\u00e1 modificar nenhuma Marca PeopleLink ou de outra forma modificar substancialmente outros Materiais de Marketing contr\u00e1rios \u00e0s instru\u00e7\u00f5es razo\u00e1veis fornecidas pelo PeopleLink, e dever\u00e1 ainda cumprir as instru\u00e7\u00f5es razo\u00e1veis do PeopleLink quanto \u00e0 forma, conte\u00fado e exibi\u00e7\u00e3o dos Materiais de Marketing. Ao t\u00e9rmino deste Contrato por qualquer raz\u00e3o, ou mediante solicita\u00e7\u00e3o por escrito da PeopleLink, a licen\u00e7a aqui concedida expirar\u00e1 e o Parceiro dever\u00e1 cessar imediatamente todas as suas atividades sob este Contrato.",
        "aff_str15_para": "O Parceiro ser\u00e1 o \u00fanico respons\u00e1vel por suas opera\u00e7\u00f5es ao agir sob este Contrato, incluindo, sem limita\u00e7\u00e3o, a legalidade das opera\u00e7\u00f5es e materiais do Parceiro, criados e utilizados em conex\u00e3o com este Contrato. Exceto por uma reclama\u00e7\u00e3o alegando que uma Marca PeopleLink viola os direitos de marca registrada de terceiros, a PeopleLink n\u00e3o \u00e9 respons\u00e1vel pelo desenvolvimento, opera\u00e7\u00e3o ou conte\u00fado dos Materiais de Marketing do Parceiro e o Parceiro concorda em defender, indenizar e isentar a PeopleLink de quaisquer reclama\u00e7\u00f5es, a\u00e7\u00f5es, causas de a\u00e7\u00e3o, danos ou despesas (incluindo honor\u00e1rios advocat\u00edcios) relacionados ao desenvolvimento, opera\u00e7\u00e3o, conte\u00fado e manuten\u00e7\u00e3o dos Materiais de Marketing do Parceiro.",
        "aff_str16_para": "Durante e ap\u00f3s o Prazo, o PeopleLink ser\u00e1 o propriet\u00e1rio exclusivo de todas as rela\u00e7\u00f5es criadas via Parceiro entre o PeopleLink e Refer\u00eancias com respeito ao Servi\u00e7o PeopleLink, incluindo toda e qualquer informa\u00e7\u00e3o identificando Refer\u00eancias que contratam com o PeopleLink para o uso do Servi\u00e7o PeopleLink. Os Termos, Pol\u00edtica de Privacidade e as regras e procedimentos do PeopleLink para o Servi\u00e7o PeopleLink ser\u00e3o aplic\u00e1veis a estas Refer\u00eancias e poder\u00e3o ser alterados pelo PeopleLink sem aviso pr\u00e9vio ao Parceiro, e o Parceiro concorda em transmitir \u00e0s Refer\u00eancias a natureza de suas rela\u00e7\u00f5es com o PeopleLink sob os Termos.",
        "aff_str20_para1": "encaminhados pelo Partner ao PeopleLink e que completam o procedimento de inscri\u00e7\u00e3o de acordo com o procedimento descrito na Se\u00e7\u00e3o 3(b) abaixo;",
        "aff_str20_para2": "dos quais o PeopleLink n\u00e3o tem registro em conex\u00e3o com o Servi\u00e7o PeopleLink, ou que n\u00e3o est\u00e3o, no momento referido ao PeopleLink pelo Parceiro, em quaisquer rela\u00e7\u00f5es contratuais ou negocia\u00e7\u00f5es em andamento com o PeopleLink em conex\u00e3o com o Servi\u00e7o PeopleLink;",
        "aff_str20_para3": "que aceitem os Termos e adquiram, a seu crit\u00e9rio e sem receber qualquer incentivo monet\u00e1rio ou de outro tipo do Parceiro, pelo menos assinaturas anuais de qualquer um dos seguintes pacotes de planos do Servi\u00e7o PeopleLink listados nos sites PeopleLink ou de outra forma (iv) que n\u00e3o sejam rejeitados pelo PeopleLink, e fa\u00e7am pelo menos um pagamento (pr\u00e9-pagamento anual) ou tr\u00eas pagamentos (prazo anual, faturamento mensal) para receber o Servi\u00e7o PeopleLink.",
        "aff_str21": "Procedimento de Indica\u00e7\u00e3o.",
        "aff_str22": "Comiss\u00f5es. Responsabilidades.",
        "aff_str221": "Taxas por indica\u00e7\u00e3o.",
        "aff_str222": "Vendas em andamento a partir de uma indica\u00e7\u00e3o",
        "aff_str223": "Taxas associadas.",
        "aff_str23": "Relat\u00f3rios de Vendas\/Comiss\u00f5es.",
        "aff_str21_para": "Cada Refer\u00eancia ser\u00e1 encaminhada ao PeopleLink pelo Parceiro atrav\u00e9s de uma url on-line fornecida pelo PeopleLink ao Parceiro, o PeopleLink ser\u00e1 respons\u00e1vel pelo processo de vendas a todas as Refer\u00eancias, sujeito \u00e0 coopera\u00e7\u00e3o cont\u00ednua e de boa f\u00e9 das Partes na promo\u00e7\u00e3o do processo de vendas a Refer\u00eancia.",
        "aff_str22_para": "O PeopleLink coletar\u00e1 todas as taxas de Refer\u00eancias para o Servi\u00e7o PeopleLink diretamente das Refer\u00eancias.",
        "aff_str22_para1": "Quando uma indica\u00e7\u00e3o se torna uma indica\u00e7\u00e3o qualificada, o PeopleLink pagar\u00e1 taxas de indica\u00e7\u00e3o de parceiros em atraso com base nas taxas listadas localizadas em",
        "aff_str22_para12": "(excluindo quaisquer descontos) a pagar pela Indica\u00e7\u00e3o Qualificada. Tais taxas por indica\u00e7\u00e3o dever\u00e3o ser pagas ao Parceiro dentro de aproximadamente (<add) noventa (90) dias a partir da data de in\u00edcio do servi\u00e7o pago, sendo cumpridas as obriga\u00e7\u00f5es de pagamento.",
        "aff_str22_para2": "- O parceiro receber\u00e1 taxas por indica\u00e7\u00e3o para vendas adicionais de uma Indica\u00e7\u00e3o Qualificada que ocorram dentro dos primeiros 90 dias da data em que a indica\u00e7\u00e3o foi inicialmente apresentada.",
        "aff_str22_para3": " O Parceiro ser\u00e1 respons\u00e1vel pelo pagamento de todos os impostos, taxas, encargos governamentais e outros encargos similares cobrados sobre as Taxas de Indica\u00e7\u00e3o, e o Parceiro dever\u00e1 indenizar, defender e isentar o PeopleLink de e contra quaisquer reclama\u00e7\u00f5es decorrentes ou relacionadas a todos os encargos emanados do pagamento das Taxas de Indica\u00e7\u00e3o pelo PeopleLink.",
        "aff_str23_para": "O PeopleLink deve fornecer ao Parceiro em seu painel de controle on-line um relat\u00f3rio resumindo as atividades de vendas do Parceiro e suas comiss\u00f5es para Refer\u00eancias Qualificadas derivadas de Refer\u00eancias. O PeopleLink n\u00e3o deve revelar os nomes ou outras informa\u00e7\u00f5es pessoais sobre Refer\u00eancias e Refer\u00eancias Qualificadas.",
        "aff_str31": "Prazo inicial.",
        "aff_str32": "Termo de Renova\u00e7\u00e3o.",
        "aff_str33": "Rescis\u00e3o antecipada. Sem Causa.",
        "aff_str34": "Por Causa.",
        "aff_str35": "Efeito da rescis\u00e3o.",
        "aff_str31_para": "Este Acordo entrar\u00e1 em vigor a partir da Data de Entrada em Vigor e dever\u00e1 continuar por doze (12) meses depois (\"Prazo Inicial\"), a menos que o PeopleLink rejeite a solicita\u00e7\u00e3o dos Parceiros para participar do Programa",
        "aff_str32_para": "Ap\u00f3s a expira\u00e7\u00e3o do Prazo Inicial, este Contrato ser\u00e1 automaticamente renovado por per\u00edodos adicionais consecutivos de doze (12) meses (cada um, \"Prazo de Renova\u00e7\u00e3o\"), a menos que uma Parte d\u00ea um aviso escrito de t\u00e9rmino \u00e0 outra Parte pelo menos trinta (30) dias antes do t\u00e9rmino do Prazo Inicial ou de qualquer Prazo de Renova\u00e7\u00e3o.",
        "aff_str33_para": "O PeopleLink ter\u00e1 o direito de rescindir este Contrato a qualquer momento por qualquer ou nenhuma raz\u00e3o, dando cinco (5) dias de aviso pr\u00e9vio por escrito ao Parceiro.",
        "aff_str34_para": "Qualquer uma das Partes poder\u00e1 rescindir este Contrato a qualquer momento, com efeito imediato mediante notifica\u00e7\u00e3o por escrito \u00e0 outra Parte que tenha violado materialmente este Contrato, desde que antes de rescindir este Contrato, a Parte que o rescindir dever\u00e1 fornecer notifica\u00e7\u00e3o por escrito de tal viola\u00e7\u00e3o material e 30 (trinta) dias de oportunidade para que a Parte infratora remedeie tal viola\u00e7\u00e3o.",
        "aff_str35_para": "A partir e ap\u00f3s a data do t\u00e9rmino deste Contrato, os direitos do Parceiro sob este Contrato terminar\u00e3o, e o Parceiro n\u00e3o ter\u00e1 direito a receber quaisquer taxas de indica\u00e7\u00e3o ou quaisquer outros pagamentos sob este Contrato que n\u00e3o sejam comiss\u00f5es ou pagamentos ganhos ou acumulados antes do t\u00e9rmino deste Contrato.",
        "aff_str41": "Atribui\u00e7\u00e3o.",
        "aff_str42": "Direitos de Propriedade Intelectual.",
        "aff_str43": "Sem Ren\u00fancia.",
        "aff_str44": "Garantia limitada.",
        "aff_str45": "Ren\u00fancia de Garantia.",
        "aff_str46": "Limita\u00e7\u00e3o de Responsabilidade Civil.",
        "aff_str47": "Empreiteiros independentes.",
        "aff_str48": "Indeniza\u00e7\u00e3o.",
        "aff_str49": "For\u00e7a Maior.",
        "aff_str410": "Acordo integral; Divisibilidade.",
        "aff_str411": "Contrapartes; Avisos.",
        "aff_str412": "Direito aplic\u00e1vel; Jurisdi\u00e7\u00e3o; Resolu\u00e7\u00e3o de disputas.",
        "aff_str41_para": "O PeopleLink pode atribuir este Contrato a qualquer momento. O parceiro n\u00e3o pode ceder ou transferir este Contrato sem o consentimento pr\u00e9vio por escrito do PeopleLink, tal consentimento n\u00e3o deve ser retido injustificadamente.",
        "aff_str42_para": "Todos os direitos de propriedade intelectual (tais como, mas n\u00e3o limitados a marcas registradas, nomes comerciais, logotipos, patentes, direitos autorais, nomes de dom\u00ednio e direitos derivados) nas Marcas PeopleLink, no Servi\u00e7o PeopleLink e no conte\u00fado e tecnologia relacionados em todo o mundo (Direitos de PI PeopleLink) s\u00e3o e continuar\u00e3o a ser propriedade exclusiva da PeopleLink e de suas empresas subsidi\u00e1rias. O direito do Parceiro de usar as Marcas Licenciadas fica a crit\u00e9rio do PeopleLink e est\u00e1 sujeito \u00e0 conformidade do Parceiro com os termos deste Contrato, Diretrizes, e com todas as leis e regulamentos aplic\u00e1veis. O Parceiro concorda em (a) n\u00e3o utilizar quaisquer Direitos de PI PeopleLink de qualquer maneira razoavelmente prov\u00e1vel de violar este Contrato; (b) n\u00e3o fazer nada que conteste ou prejudique quaisquer Direitos de PI PeopleLink; (c) n\u00e3o criar ou obter quaisquer direitos de propriedade intelectual (tais como, mas n\u00e3o limitados a marcas registradas, nomes comerciais, logotipos, patentes, direitos autorais, nomes de dom\u00ednio e direitos derivados) que sejam substancialmente similares a quaisquer Direitos de PI PeopleLink; (d) notificar imediatamente o PeopleLink sobre qualquer uso n\u00e3o autorizado de quaisquer Direitos de PI PeopleLink dos quais o Parceiro tenha conhecimento real; e (e) usar sempre as Marcas Licenciadas e quaisquer outras Marcas PeopleLink em conformidade com as Diretrizes. O PeopleLink poder\u00e1 realizar revis\u00f5es peri\u00f3dicas de quaisquer Materiais de Marketing apresentados pelo Parceiro, e ter\u00e1 autoridade e discri\u00e7\u00e3o exclusivas para ordenar a remo\u00e7\u00e3o e\/ou emenda de quaisquer Materiais de Marketing apresentados pelo Parceiro.",
        "aff_str43_para": "A falha de uma das Partes em impor o estrito cumprimento pela outra Parte de qualquer provis\u00e3o deste Acordo n\u00e3o constituir\u00e1 uma ren\u00fancia ao direito da primeira Parte de impor subseq\u00fcentemente tal provis\u00e3o ou qualquer outra provis\u00e3o deste Acordo.",
        "aff_str44_para": "Ambas as partes garantem que, a todo momento durante o Prazo, cumprir\u00e3o todas as leis, regulamentos, c\u00f3digos de pr\u00e1tica aplic\u00e1veis, bem como este Contrato, os Termos, Pol\u00edtica de Privacidade e Diretrizes. Durante o Prazo e ap\u00f3s seu t\u00e9rmino por qualquer motivo, o Parceiro se compromete expressamente a n\u00e3o fazer nada que possa prejudicar o neg\u00f3cio, os interesses ou a reputa\u00e7\u00e3o do PeopleLink e n\u00e3o far\u00e1, publicar\u00e1 ou permitir\u00e1 que sejam feitas ou publicadas quaisquer observa\u00e7\u00f5es depreciativas sobre o PeopleLink, seus representantes ou o Servi\u00e7o PeopleLink.",
        "aff_str45_para": "Al\u00e9m da garantia expressa do PeopleLink sob a subse\u00e7\u00e3o anterior (e), o PeopleLink n\u00e3o faz nenhuma outra garantia, expressa ou impl\u00edcita, de qualquer tipo e o PeopleLink renuncia expressamente a quaisquer garantias e condi\u00e7\u00f5es, incluindo mas n\u00e3o se limitando a qualquer garantia impl\u00edcita de comerciabilidade, adequa\u00e7\u00e3o a uma finalidade espec\u00edfica, disponibilidade, seguran\u00e7a, t\u00edtulo e\/ou n\u00e3o-infra\u00e7\u00e3o do objeto deste Contrato.",
        "aff_str46_para": "Nem o PeopleLink nem qualquer executivo, funcion\u00e1rio, diretor ou qualquer outro representante do PeopleLink ser\u00e1 respons\u00e1vel perante o S\u00f3cio ou perante terceiros, sob ou em conex\u00e3o com este Contrato ou sua rescis\u00e3o, em contrato, pr\u00e9-contrato, ato il\u00edcito ou de outra forma por (i) qualquer perda econ\u00f4mica (incluindo perda de receitas, lucros, contratos, neg\u00f3cios ou economias previstas) ou (ii) qualquer perda de boa vontade ou reputa\u00e7\u00e3o. Tais perdas incluem, sem limita\u00e7\u00e3o, quaisquer perdas ou danos especiais, indiretos, incidentais, estatut\u00e1rios, punitivos ou conseq\u00fcentes, assim como quaisquer perdas ou danos causados pela interrup\u00e7\u00e3o das opera\u00e7\u00f5es. N\u00e3o obstante quaisquer outras circunst\u00e2ncias ou entendimentos que envolvam quaisquer rela\u00e7\u00f5es entre as Partes, a responsabilidade total da PeopleLink para com o Parceiro sob este Contrato n\u00e3o dever\u00e1 exceder INR (R\u00fapias indianas apenas mil) para toda e qualquer reivindica\u00e7\u00e3o por danos de qualquer tipo feita pelo Parceiro sob este Contrato, e ao entrar neste Contrato o Parceiro reconhece as limita\u00e7\u00f5es aqui contidas sobre a responsabilidade da PeopleLink.",
        "aff_str47_para": "As partes aqui atuam em seu pr\u00f3prio nome como contratante independente. Nada neste Contrato dever\u00e1 criar qualquer joint venture, ag\u00eancia, franquia, representante de vendas, emprego ou qualquer outra rela\u00e7\u00e3o entre as Partes al\u00e9m das rela\u00e7\u00f5es estabelecidas neste Contrato, e o Parceiro est\u00e1 expressamente impedido de agir em nome do PeopleLink. A exibi\u00e7\u00e3o de Marcas Licenciadas pelo Parceiro sob este Contrato, outros conte\u00fados apresentados pelo Parceiro, ou o contato entre o Parceiro e terceiros n\u00e3o dever\u00e1 deturpar as rela\u00e7\u00f5es aqui descritas.",
        "aff_str48_para": "O s\u00f3cio indenizar\u00e1, defender\u00e1 e manter\u00e1 o PeopleLink e suas subsidi\u00e1rias, afiliadas, diretores e funcion\u00e1rios (as \"Partes Indenizadas PeopleLink\") isentos de e contra quaisquer custos, responsabilidades, perdas e despesas (incluindo mas n\u00e3o se limitando a honor\u00e1rios advocat\u00edcios razo\u00e1veis) resultantes de qualquer reivindica\u00e7\u00e3o, processo, a\u00e7\u00e3o, demanda ou processo interposto por qualquer terceiro contra as Partes Indenizadas PeopleLink decorrentes de qualquer uma das seguintes (i) viola\u00e7\u00e3o do Contrato pelo S\u00f3cio; (ii) neglig\u00eancia, neglig\u00eancia grosseira ou m\u00e1 conduta deliberada do S\u00f3cio ou de seus funcion\u00e1rios, agentes ou contratados; ou (iii) falha do S\u00f3cio ou de seus funcion\u00e1rios, agentes, contratados ou convidados em cumprir as leis e regulamentos aqui mencionados anteriormente.",
        "aff_str49_para": "Uma Parte n\u00e3o ser\u00e1 obrigada a cumprir nenhuma de suas obriga\u00e7\u00f5es aqui contidas se for impedida de o fazer por uma situa\u00e7\u00e3o de for\u00e7a maior. Eventos de \"for\u00e7a maior\" incluir\u00e3o eventos fora do controle razo\u00e1vel das Partes, incluindo atos de Deus, atos de governo, atos da natureza, greves ou motins, bem como desempenho impr\u00f3prio de fornecedores PeopleLinks ou defeitos em objetos, materiais ou software de terceiros. Se uma situa\u00e7\u00e3o de for\u00e7a maior durar mais de trinta (30) dias, qualquer uma das Partes poder\u00e1 rescindir este contrato mediante notifica\u00e7\u00e3o por escrito \u00e0 outra Parte.",
        "aff_str410_para": "Este Acordo representa a totalidade do acordo entre as Partes no que diz respeito ao seu objeto e as respectivas obriga\u00e7\u00f5es e compromissos das Partes aqui contidos. Nenhum outro documento, ou acordo oral ou escrito entre as Partes reflete de forma alguma sobre os acordos estabelecidos neste Contrato, e este Contrato n\u00e3o pode ser modificado exceto por escrito executado por ambas as Partes. . Sempre que poss\u00edvel, cada disposi\u00e7\u00e3o deste Contrato dever\u00e1 ser interpretada de forma a ser efetiva e v\u00e1lida sob a lei aplic\u00e1vel, mas se qualquer disposi\u00e7\u00e3o deste Contrato for proibida ou inv\u00e1lida sob a lei aplic\u00e1vel, tal disposi\u00e7\u00e3o ser\u00e1 ineficaz somente na medida de tal proibi\u00e7\u00e3o ou invalidade, sem invalidar o restante de tal disposi\u00e7\u00e3o ou as demais disposi\u00e7\u00f5es deste Contrato.",
        "aff_str411_para": "Este Acordo pode ser assinado em contrapartidas e tais contrapartidas ser\u00e3o v\u00e1lidas e vinculativas para as partes com o mesmo efeito que se as assinaturas originais tivessem sido trocadas.",
        "aff_str412_para": "O presente Acordo ser\u00e1 regido pelas leis da jurisdi\u00e7\u00e3o de Hyderabad, Telangana, \u00cdndia, sem dar efeito a quaisquer princ\u00edpios de conflitos de leis. A jurisdi\u00e7\u00e3o ser\u00e1 exclusivamente nos tribunais distritais de Hyderabad, Telangana, \u00cdndia. A \u00fanica e exclusiva jurisdi\u00e7\u00e3o e foro para qualquer lit\u00edgio decorrente do presente Acordo ser\u00e1 um tribunal distrital ou estadual apropriado localizado em Hyderabad, Telangana, \u00cdndia, e as partes concordam em n\u00e3o levantar, e por meio deste, renunciam a quaisquer obje\u00e7\u00f5es ou defesas baseadas em local ou foro n\u00e3o conveniente. Antes de iniciar qualquer a\u00e7\u00e3o legal decorrente ou relacionada ao presente Acordo, uma Parte dever\u00e1 fornecer \u00e0 outra Parte notifica\u00e7\u00e3o escrita de uma disputa e as Partes dever\u00e3o negociar ativamente e de boa f\u00e9 com vistas a uma r\u00e1pida resolu\u00e7\u00e3o de tal disputa dentro de trinta (30) dias \u00fateis do recebimento de tal notifica\u00e7\u00e3o.",
        "aff_str40": "Modifica\u00e7\u00e3o do acordo.",
        "aff_str40_para": "O PeopleLink pode modificar este Contrato de tempos em tempos, a seu crit\u00e9rio razo\u00e1vel, colocando uma altera\u00e7\u00e3o no Site (em www.peoplelinkvc.com\/affiliatestermsofservice) ou notificando o Parceiro por e-mail. Se o Parceiro se opuser a qualquer altera\u00e7\u00e3o, o Parceiro poder\u00e1 rescindir este Contrato por justa causa. A participa\u00e7\u00e3o cont\u00ednua do Parceiro no Programa ap\u00f3s o recebimento de notifica\u00e7\u00e3o sobre altera\u00e7\u00f5es neste Contrato constituir\u00e1 aceita\u00e7\u00e3o obrigat\u00f3ria deste Contrato, conforme emendado.",
        "qualified_ref": "Referentes qualificados",
        "mean_referrals": "m\u00e9dia de Referentes",
        "apply": "Aplicar",
        "company_code_validate": "Por favor, insira o c\u00f3digo da empresa para validar",
        "golive_company_code": "C\u00f3digo de empresa Golive",
        "accept_TC": "Eu aceito os termos e condi\u00e7\u00f5es acima",
        "by_sub_referral": "Ao apresentar a Solicita\u00e7\u00e3o de Indica\u00e7\u00e3o de Parceiro, o Parceiro representa e garante que leu, entende e aceita este Contrato.",
        "term": "Termos",
        "found_at": "encontrado em",
        "our_privacy_policy": "e nossa Pol\u00edtica de Privacidade",
        "privacy_policy": "Pol\u00edtica de Privacidade",
        "as_applicable": "conforme aplic\u00e1vel.",
        "screen-sharing": "compartilhamento de tela",
        "whiteboard_access": "acesso ao quadro branco",
        "Document_access": "Acesso aos documentos",
        "share_the_screen": "compartilhe a tela!",
        "to_collaborate": "para colaborar!",
        "share_document": "documento de partilha!",
        "exit_or_end": "Sair ou encerrar a reuni\u00e3o?",
        "exit_from_meeting": "Voc\u00ea gostaria de sair da reuni\u00e3o?",
        "are_sure_exit_meeting_everyone": "Voc\u00ea tem certeza de que quer sair da reuni\u00e3o? Voc\u00ea pode encerrar a reuni\u00e3o para todos ou pode sair da reuni\u00e3o.",
        "are_sure_exit_meeting": "Voc\u00ea tem certeza de que quer sair da reuni\u00e3o?",
        "exit_meeting": "Saia da Reuni\u00e3o",
        "end_for_all": "Reuni\u00e3o final para todos",
        "end_meeting": "Reuni\u00e3o final",
        "will_let_alone_exit": "deixar\u00e1 voc\u00ea sozinho sair ou sair da reuni\u00e3o.",
        "will_end_for_everyone": "terminar\u00e1 a reuni\u00e3o para todos os participantes da confer\u00eancia (Todos os participantes e participantes).",
        "user_already_exists": "Usu\u00e1rios J\u00e1 Existentes",
        "already_user_avail": "J\u00e1 existem alguns usu\u00e1rios presentes na reuni\u00e3o.",
        "if_like_continue": "Se voc\u00ea quiser continuar, clique em",
        "force_start_meetinh": "For\u00e7ar o in\u00edcio de outra reuni\u00e3o",
        "otherwise_click": "caso contr\u00e1rio, clique em",
        "to_go_back": "para voltar.",

        //New by Keerthi - 17th Sep 2020
        "lang_settings" : "Language Settings",
        "lang_info" : "Choose your preferred language to use the application.",
        "select_language" : "Select Language",
        "settings_languages" : "Languages",

        "rec_plugin" : "Recording Plugin",
        "rec_heading1" : "How to install and manage InstaVC Screen-Video Recorder with the plugin",
        "rec_step1" : "Step 1: Copy & open Link in Chrome",
        "rec_step1_para" : "First copy the link ",
        "rec_step1_para_br" : "from here and open it in chrome to add the plugin to your browser. Click on ",
        "rec_step1_para_bold" : "Add to Chrome",
        "rec_step1_para_ct" : "to add this extension.",

        "rec_step2" : "Step 2: Pop Up for Permission",
        "rec_step2_para_br" : "When you click on Add to chrome, here appears a popup asking for permission to display notification access your content. Click on ",
        "rec_step2_para_bold" : "Add Extension",
        "rec_step2_para_ct" : "to proceed.",

        "rec_step3" : "Step 3: Extension Added to Chrome",
        "rec_step3_para" : "When the extension is added to chrome, it shows a window giving you an information with a plugin icon and how to manage this plugin from here.",

        "rec_step4" : "Step 4: Pin the plugin on the toolbar",
        "rec_step4_para_br" : "To pin the plugin to your toolbar, you need to click on manage extensions, go to ",
        "rec_step4_para_bold" : "InstaVC Screen-Recording",
        "rec_step4_para_ct" : "and click on the pin icon to add.",

        "rec_step5" : "Step 5: Extension/Plugin Pinned to Toolbar",
        "rec_step5_para" : "Once the extension is pinned, you can now see an instavc recording icon in your browser toolbar and start using it for easy access." ,

        "rec_step6" : "Step 6: InstVC Recorder Setup",
        "rec_step6_para_br" : "Once you have installed the plugin and the next time when you open it, you can now see the ",
        "rec_step6_para_bold" : "InstaVC Recorder Setup",
        "rec_step6_para_ct" : "page to enable or disable the recording tools. By default both the options will be enabled. Click on ",
        "rec_step6_para_bold1" : "Next",
        "rec_step6_para_ct1" : "to continue.",

        "rec_step7" : "Step 7: Additional Permissions",
        "rec_step7_para_br" : "Once the Next button is clicked, system will then show you a popup asking for additional permissions to read and change your data. Click ",
        "rec_step7_para_bold" : "Allow",
        "rec_step7_para_ct" : "to continue.",

        "rec_step8" : "Step 8: Permissions Allowed",
        "rec_step8_para" : "After allowing the permission, this page now gets frozen or in a disabled mode from where you can now start exploring recording options now.",

        "rec_heading2" : "Desktop Screen-Recording Plugin",

        "rec_step9" : "Step 9: Desktop Screen-Recording",
        "rec_step9_para_br" : "After allowing all permissions, you can now access recording menus from the plugin extension in your toolbar. Click on it to see further options. Here we have selected ",
        "rec_step9_para_bold" : "Desktop Screen Recording",
        "rec_step9_para_ct" : "and click on the",
        "rec_step9_para_bold1" : "Record",
        "rec_step9_para_ct1" : "button to start.",

        "rec_step9a" : "Step 9(a): Share your Screen Popup",
        "rec_step9a_para_br" : "Now the system shows up this popup asking to select the contents of your screen, whether its an Entire Screen or only application window. Click on the tab which you want to share and click on ",
        "rec_step9a_para_bold" : "Share",
        "rec_step9a_para_ct" : "button and go to that screen which you want to start recording.",

        "rec_step9b" : "Step 9(b): Desktop Screen Recording Started",
        "rec_step9b_para_br" : "The Desktop Screen recording is now started and you can see a blink in the toolbar while its in recording mode. Also, you can see a tab at the bottom showing buttons for ",
        "rec_step9b_para_bold" : "Stop Sharing",
        "rec_step9b_para_ct" : "or",
        "rec_step9b_para_bold1" : "Hide",
        "rec_step9b_para_ct1" : ". You can see the bottom message on any screen you are opening until you are in the desktop screen recording mode.",

        "rec_step9c" : "Step 9(c): Restart / Pause or Stop Desktop Screen Recording",
        "rec_step9c_para_br" : "Once you want to stop Recording. Click on the plugin in the toolbar and here you can see the options to ",
        "rec_step9c_para_bold" : "Restart, Pause",
        "rec_step9c_para_ct" : "and",
        "rec_step9c_para_bold1" : "Stop",
        "rec_step9c_para_ct1" : "buttons. You can click any of those accordingly.",

        "rec_step9d" : "Step 9(d):Recent Recording View",
        "rec_step9d_para_br" : "Once you stop the recording from the toolbar, the recent recording will now automatically open the recording in a new tab along with Title and ",
        "rec_step9d_para_bold" : "Download",
        "rec_step9d_para_ct" : "option in the top and the recording details in the right column.",
        
        "rec_heading3" : "Browser Tab Screen-Recording Plugin",

        "rec_step10" : "Step 10: Browser Tab Screen-Recording",
        "rec_step10_para_br" : "When we want to select ",
        "rec_step10_para_bold" : "Browser Tab",
        "rec_step10_para_ct" : "option for recording your browser screen. First open your screen(url) in chrome whichever you want to record and then open the plugin and click on Browser Tab, adjust microphone options and then click",
        "rec_step10_para_bold1" : "Record",
        "rec_step10_para_ct1" : "button to start recording.",

        "rec_step10a" : "Step 10(a): Browser Tab is being Recorded",
        "rec_step10a_para" : "Once the Browser tab is started recording, you can see a red dot blinking in your toolbar which indicates the tab is being recorded and also a blue box blinking in your browser title bar",
        
        "rec_step10b" : "Step 10(b): Record another tab",
        "rec_step10b_para_br" : "At the time of current screen recording, if you open another url and open the plugin, it will show you the recording status along with an option",
        "rec_step10b_para_bold" : "Record This Tab",
        "rec_step10b_para_ct" : "if you want to change the recording scree to the new url. Meanwhile the blue box blinking in the title bar of the browser indicates the tab that is being recorded at the moment.",

        "rec_step10c" : "Step 10(C): Stop and View recording",
        "rec_step10c_para_br" : "Later, when you stop the recording, the browser itself opens the recording in a new tab along with Title and",
        "rec_step10c_para_bold" : "Download",
        "rec_step10c_para_ct" : "Option at the top and the recording details in the right column.",

        "rec_heading4" : "Webcam Recording",

        "rec_step11" : "Step 11: Start Webcam Screen Recording",
        "rec_step11_para_br" : "When you start with Webcam screen recording, make sure to select the microphone & camera settings among the devices detected by the plugin. and then click on the",
        "rec_step11_para_bold" : "Record",
        "rec_step11_para_ct" : "button to start with.",

        "rec_step11a" : "Step 11(a): Webcam opens Self Video and starts Recording",
        "rec_step11a_para_br" : "The recording opens a web page with yourself video with",
        "rec_step11a_para_bold" : "Pause",
        "rec_step11a_para_ct" : "and",
        "rec_step11a_para_bold1" : "Stop",
        "rec_step11a_para_ct1" : "recording options at the bottom.",

        "rec_step11b" : "Step 11(b): View Webcam Recorded Video",
        "rec_step11b_para" : "When you stop the recording in the previous screen, the browser then opens the recording in a new tab to view and download along with the recording details.",
        
        "rec_heading5" : "Top Menus in Recording Plugin",

        "rec_step12" : "Step 12: Navigation Menus",
        "rec_step12_para_br" : "The plugin has few navigation menus to fetch your data as well as manage different actions related to the plugin. One of them is a",
        "rec_step12_para_bold" : "Hamburger Menu",
        "rec_step12_para_ct" : "Click on it to open.",

        "rec_step12a" : "Step 12(a): Open Hamburger Menu",
        "rec_step12a_para_br" : "On click of",
        "rec_step12a_para_bold" : "Hamburger Menu",
        "rec_step12a_para_ct" : ", you can find 2 tabs named",
        "rec_step12a_para_bold1" : "My Recordings",
        "rec_step12a_para_ct1" : "and",
        "rec_step12a_para_bold2" : "Options",
        "rec_step12a_para_ct2" : "which manages all the data you have been recording and options you have enabled or disabled for that plugin. Click on",
        "rec_step12a_para_bold3" : "My Recordings",
        "rec_step12a_para_ct3" : "to see the videos you have recorded till date.",

        "rec_step12b" : "Step 12(b): Access My Recordings",
        "rec_step12b_para_br" : "There is another way of accessing",
        "rec_step12b_para_bold" : "My Recordings",
        "rec_step12b_para_ct" : ", page from the top menu in the plugin which can be considered as a shortcut to navigate.",
        
        "rec_step12c" : "Step 12(c): My Recordings",
        "rec_step12c_para" : "Here will be the detailed list of videos(recordings) you have recorded till date. Each video contains the date, duration, size of the video along with delete icon in the thumbnail view. When you click on any video it opens the details in the view recording page. Note that all the videos will be stored locally here.",

        "rec_step12d" : "Step 12(d): Delete All recordings",
        "rec_step12d_para_br" : "Looking at My recordings Page, there is a more icon in the top right corner which opens the",
        "rec_step12d_para_bold" : "Delete All",
        "rec_step12d_para_ct" : "option to easily delete all recordings with a single click.",
        
        "rec_step12e" : "Step 12(e): Side Navigation",
        "rec_step12e_para_br" : "Instead of opening the plugin again and change, You can directly navigate to another tab from here using the Hamburger Menu in the top left corner of the page that opens the navigation to navigate to the",
        "rec_step12e_para_bold" : "Options",
        "rec_step12e_para_ct" : "page",

        "rec_heading6" : "Plugin Options",

        "rec_step13" : "Step 13: Plugin Options",
        "rec_step13_para_br" : "On click on Hamburger Menu, you can find another tab named Options which manages all the options you have enabled or disabled for that plugin. Click on",
        "rec_step13_para_bold" : "Options",
        "rec_step13_para_ct" : "to see the options that are enabled for you.",

        "rec_step13a" : "Step 13(a): Recorder Options",
        "rec_step13a_para" : "Recorder Options ask you for various options like video storage, Notifications, Keyboard Shortcuts",

        "rec_step13b" : "Step 13(b): Recorder Options",
        "rec_step13b_para" : "Recorder Options contain various other options like Recording Settings and advanced.",

        "rec_heading7" : "Plugin Notifications",

        "rec_step14" : "Step 14: Plugin Notifications",
        "rec_step14_para" : "On the top navigation part, you can find a bell icon for notifications if any related to instavc screen recording plugin.",

        "rec_step14a" : "Step 14(a): Open Notifications",
        "rec_step14a_para" : "On click on the notification icon, you can find the list of notifications if any or it shows an empty slide in.",

        "rec_heading8" : "Recording Settings",

        "rec_step15" : "Step 15: Settings",
        "rec_step15_para_br" : "Then comes the last navigation menu on the top right corner named",
        "rec_step15_para_bold" : "Settings",
        "rec_step15_para_ct" : "where you can open and manage the recording settings accordingly.",

        "rec_step15a" : "Step 15(a): Advanced Settings Options",
        "rec_step15a_para" : "Here you can adjust the settings based on the Resize tab, Limit resolution and maximum frame rate which are given by plugin.",

        "crteContent_upload_medialist" : "Uploaded Media List",
        "content_upld_desc_txt" : "The files you upload here will be available to users who view content.",
        "content_browse_txt" : "Browse Files to Upload",
        "content_upld_types" : "Files with only .jpg, .jpeg, .png, and .pdf are allowed limiting the max size upto 15MB",

        "video_qlty": "Qualidade de vídeo",
        "audio_qlty": "Qualidade de áudio",
        "cntnt_shrng": "Compartilhamento de conteúdo",
        "fdb_poor": "Pobre",
        "fdb_good": "Bom",
        "fdb_avg": "Média",
        "fdb_excellent": "Excelente",
        "share_feedback": "Compartilhe seu FeedBack",
        "ovrl_rating": "Avaliação geral",
        "fdb_cmnts": "Seus comentários são muito apreciados",
        "no_thnks": "Não, obrigado",
        "upload_size_max_15mb": "O limite máximo de upload é 15 MB",

        "pastMeeting_feedback" : "Comentários",
        "pastMeeting_comments" : "Comentários",
        
        "microsoft_webplugin" : "Plug-in da Web do Microsoft Outlook",
        "microsoft_installable" : "Plug-in instalável do Microsoft Outlook",
        "outlook_installable": "Outlook instalável",
        "customization_installer": "Como acessar o instalador de personalização",
        "plugin_step1" : "Etapa 1: Clique no link para baixar o arquivo",
        "click_link_download": "Clique no link para baixar o arquivo",
        "first_click_link": "Primeiro clique no link abaixo",
        "to_download_file": "para baixar o arquivo em seu sistema. Em seguida, abra o arquivo baixado para iniciar a instalação e clique",
        "install_txt": "Instalar",
        "plugin_step2" : "Etapa 2: Personalização instalada",
        "plugin_step2_para":"Once you start installing, wait untill the file gets installed and you get a popup showing successfully Installed and then close.",
        "plugin_step3" : "Etapa 3: Abra o Outlook e vá para as reuniões",
        "plugin_step3_para" : "Após a instalação, abra o Microsoft Outlook e vá para as reuniões.",
        "plugin_step3_para1" : "Vá para Novo Email> Reuniões",
        "plugin_step4" : "Etapa 4: abrir reuniões",
        "plugin_step4_para" : "Assim que a janela de reuniões for aberta, mova para a lista das guias acima e você poderá clicar na guia",
        "plugin_step4_para1": "Peoplelink",
        "plugin_step5": "Etapa 5: Agendar Conferência",
        "plugin_step5_para": "Em Peoplelink, você tem uma subguia chamada",
        "plugin_step5_para1": "Agendar Conferência.",
        "plugin_step5_para2": "Clique nele e você verá as opções de programação no lado direito da janela. Aqui você pode gerenciar as programações inVC.",
        "plugin_step6": "Etapa 6: Gerenciar ou personalizar programações",
        "plugin_step6_para": "Aqui você pode gerenciar ou personalizar horários pela lista de quartos, horários e pode criar um novo horário a partir daqui que o redireciona para o navegador e de volta ao Outlook.",
        "plugin_step6_para1": "Assim que estiver de acordo com a programação, você pode clicar em",
        "plugin_step6_para2": "Obter link",
        "plugin_step7": "Link Gerado",
        "plugin_step7_para": "Então aqui fica o link gerado e composto no e-mail que você ainda pode usar para compartilhar online ou prosseguir.",
    }
}