import React from "react";
import { Route, Redirect } from "react-router-dom";
import AdminLayout from '../containers/layout/adminLayout';

export default ({ component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      (cProps.isAuthenticated && cProps.isAdmin && rest.validate)
    ?  <AdminLayout {...props} {...cProps} component={(props) => <C {...cProps} {...props} />} />
        : <Redirect
            to={`/`}
          />}
  />;

  //</MainLayout><C {...props} {...cProps} />