export const config = {
  s3: {
    REGION: "ap-southeast-1",
    BUCKET: "instavc-uploads",
    PROFILEBUCKET: "instavcprofileimages",
    INSTAVCRECORDINGSBUCKET: "instavcrecordings",
    accessKeyId: 'AKIAI26N5NQUPNFOADCA',
    secretAccessKey: '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',
    folder: "content",
    chatfolder: "chat",
    CONTENTBUCKET: "instavc-content",
    webinarfolder: "webinar"
  },

  apiGateway: {
    REGION: "ap-southeast-1",
    URL: "https://eegi2amvu2.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiDomainGateway: {
    REGION: "ap-southeast-1",
    URL: "https://9t8j88lg0c.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiMcuGateway: {
    REGION: "ap-southeast-1",
    URL: "https://qcyitmmxyk.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiUserGateway: {
    REGION: "ap-southeast-1",
    URL: "https://0bc6tiezl0.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiPlanGateway: {
    REGION: "ap-southeast-1",
    URL: "https://cmqlgnv8q9.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiCouponGateway: {
    REGION: "ap-southeast-1",
    URL: "https://az5ppjief3.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiTransactionGateway: {
    REGION: "ap-southeast-1",
    URL: "https://bgqcicypd5.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiReferralGateway: {
    REGION: "ap-southeast-1",
    URL: "https://ffmee5izni.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiRecordingGateway: {
    REGION: "ap-southeast-1",
    URL: "https://7wjcuvbr41.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apimcuRecordingGateway: {
    REGION: "ap-southeast-1",
    URL: "https://uncor4f5t4.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiContentGateway: {
    REGION: "ap-southeast-1",
    URL: "https://jc9qea7ebl.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiScheduleGateway: {
    REGION: "ap-southeast-1",
    URL: "https://uok783utce.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiAddressGateway: {
    REGION: "ap-southeast-1",
    URL: "https://kn78rmyqri.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiMultipartUploadGateway: {
    REGION: "ap-southeast-1",
    URL: "https://tbl46h4bpc.execute-api.ap-southeast-1.amazonaws.com"
  },
  apiMeetingGateway: {
    REGION: "ap-southeast-1",
    URL: "https://l19ficrbhh.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiMailGateway: {
    REGION: "ap-southeast-1",
    URL: "https://9ifql6qy7f.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiBroadcastGateway: {
    REGION: "ap-southeast-1",
    URL: "https://agealag4cb.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiTestGateway: {
    REGION: "ap-southeast-1",
    URL: "https://4sfzodzzs2.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiDocumentShareGateway: {
    REGION: "ap-southeast-1",
    URL: "https://ekd90jm8y4.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiChatGateway: {
    REGION: "ap-southeast-1",
    URL: "https://f5t7wafoce.execute-api.ap-southeast-1.amazonaws.com/dev"
  },
  apiAdminGateway: {
    REGION: "ap-southeast-1",
    URL: "https://00iqjt7bra.execute-api.ap-southeast-1.amazonaws.com/dev"
  },

  //ipindia gateway
  ipIndiaGateway: {
    REGION: "ap-southeast-1",
    URL: "https://aa5kqrsqtf.execute-api.ap-southeast-1.amazonaws.com/dev"

  },

  // apiCurrency: {
  //   URL: "https://api.exchangerate-api.com/v4/latest"
  // },
  cognito: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: "ap-southeast-1_8fTF1RV9K",
    APP_CLIENT_ID: "7t8ld9sh059uh8gd1fs7je7qsb",
    IDENTITY_POOL_ID: "ap-southeast-1:2d78ca46-2cb7-4522-8089-3264e6150e60"
  },
  payment: {
    status: true, /* falst -test, true- live */
    key: {
      test: "rzp_test_krtrWUUchmwGkm",
      live: "rzp_live_QPqaEyL4FL5Tgj"
    },
    packages: {
      host_basic: {
        test: 'd2520570-af61-11e9-a85e-3da1de138a21',
        live: 'd2520570-af61-11e9-a85e-3da1de138a21',
        price: 252,
        users: 30,
        rooms: 1
      },
      host_medium: {
        test: 'fff007c0-af61-11e9-a85e-3da1de138a21',
        live: 'fff007c0-af61-11e9-a85e-3da1de138a21',
        price: 336,
        users: 50,
        rooms: 1
      },
      host_advanced: {
        test: '27f3b690-af62-11e9-a85e-3da1de138a21',
        live: '27f3b690-af62-11e9-a85e-3da1de138a21',
        price: 492,
        users: 100,
        rooms: 1
      },
      mcu_silver: {
        test: '63a31a00-af62-11e9-a85e-3da1de138a21',
        live: '63a31a00-af62-11e9-a85e-3da1de138a21',
        price: 3960,
        users: 100,
        rooms: 20
      },
      mcu_gold: {
        test: 'c50ed450-af62-11e9-a85e-3da1de138a21',
        live: 'c50ed450-af62-11e9-a85e-3da1de138a21',
        price: 6648,
        users: 200,
        rooms: 30
      },
      mcu_platinum: {
        test: '01207c00-af63-11e9-a85e-3da1de138a21',
        live: '01207c00-af63-11e9-a85e-3da1de138a21',
        price: 13320,
        users: 500,
        rooms: 50
      },
      host_flexi: {
        test: '6d5ea7b0-6f53-11ea-aad5-6dca2a51289b',
        live: '6d5ea7b0-6f53-11ea-aad5-6dca2a51289b',
        price: 1128,
        users: 30,
        rooms: 5
      },
      soho: {
        test: '4c034f00-b6e6-11ea-9c6b-b9ca6490e62f',
        live: '4c034f00-b6e6-11ea-9c6b-b9ca6490e62f',
        price: 99,
        users: 15,
        rooms: 1
      },
      smb100: {
        test: 'e34a6ba0-b6e6-11ea-9c6b-b9ca6490e62f',
        live: 'e34a6ba0-b6e6-11ea-9c6b-b9ca6490e62f',
        price: 140,
        users: 100,
        rooms: 1
      },
      smb300: {
        test: '00ad4f00-b6e7-11ea-9c6b-b9ca6490e62f',
        live: '00ad4f00-b6e7-11ea-9c6b-b9ca6490e62f',
        price: 190,
        users: 100,
        rooms: 1
      },
      vr20: {
        test: 'fc966a60-b6ea-11ea-a177-536325686627',
        live: 'fc966a60-b6ea-11ea-a177-536325686627',
        price: 660,
        users: 20,
        rooms: 2
      },
      vr30: {
        test: '19d216b0-b6eb-11ea-a177-536325686627',
        live: '19d216b0-b6eb-11ea-a177-536325686627',
        price: 1128,
        users: 30,
        rooms: 5
      },
      vr100: {
        test: '383e7ad0-b6eb-11ea-a177-536325686627',
        live: '383e7ad0-b6eb-11ea-a177-536325686627',
        price: 3960,
        users: 100,
        rooms: 20
      },
      vr200: {
        test: '3b5d78a0-b6ec-11ea-a177-536325686627',
        live: '3b5d78a0-b6ec-11ea-a177-536325686627',
        price: 6648,
        users: 200,
        rooms: 30
      },
      vr500: {
        test: '5c028eb0-b6ec-11ea-a177-536325686627',
        live: '5c028eb0-b6ec-11ea-a177-536325686627',
        price: 13320,
        users: 500,
        rooms: 50
      },
    },
  
  },
  isOfflineMCU: false,
  isOffline: true,
  AppUse: 2, //1 - InstaVC(Intl), 2-InVC (MSoup), 3 - (Jitsi)

  mcuURLifOffline: 'wss://mcu.peoplelinkvc.com:20072',
  // mcuURLifOffline: 'wss://mcupromanager.instavc.com:20072',
  iot: {
    awsIotHost: "a3an2ji8k0hxn4-ats.iot.ap-southeast-1.amazonaws.com",
    // socialFacebookAppId: config.FacebookAppId,
    // socialGoogleClientId: config.GoogleAppId,
    logLevel: 1, //config.LogLevel,
    mqttDebugLevel: 1, //config.MqttDebugLevel,
  },
  lang: "fr",
  captchasitekey: '6LfJoOYUAAAAAE0sw6bZqcSwTqvTOJDyR5FRC_er',
  invccaptchasitekey: '6Ld17rkaAAAAAGMHwTxu8FePEdu-lQMxAnZj0QDX',
  sampleparticipantUrl: 'https://instavc-content.s3-ap-southeast-1.amazonaws.com/sampleparticipants.xlsx',
  isClass: true 
};

export var workspace = {
  peer: {
    url: "https://awspeer.instavc.com"
  }
}