import React, { Component, Suspense } from 'react';
import { Route } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import '../../App.css';
import './mainlayout.css';
import '../conference/Conference.css';
import "./p2pStyles.css";
/*import '../AppRtl.css';*/
import Header from '../../components/common/header/header';
// import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import Draggable from 'react-draggable';
// import Fab from '@material-ui/core/Fab';
//import SessionTimeout from '../../components/common/SessionTimeout';
import P2P from '../conference/libs/P2P';
import Tooltip from '@material-ui/core/Tooltip';
import { API } from "aws-amplify";
import { config } from '../../config';
import { OffLineAPI } from '../../offline-auth/api'
import asyncComponent from '../../components/AsyncComponent';
var _API = config.isOffline ? OffLineAPI : API;

class MainLayout extends Component {

	constructor(props) {
		super(props);
		this.state = {
			setSession: false,
			onP2PCall: false,
			audio: true,
			video: true,
			turnCredentials: null
		}
		this.p2p = new P2P();
		this.remoteId = null;
		document.addEventListener('webkitfullscreenchange', (e) => {
			if (document.webkitFullscreenElement) {
				this.makeFullScreen(true);
			} else {
				this.makeFullScreen(false);
			}
		}, false);

		document.addEventListener('mozfullscreenchange', (e) => {
			if (document.mozFullScreenElement) {
				this.makeFullScreen(true);
			} else {
				this.makeFullScreen(false);
			}
		}, false);

		document.addEventListener('fullscreenchange', (e) => {
			if (document.fullscreenElement) {
				this.makeFullScreen(true);
			} else {
				this.makeFullScreen(false);
			}
		}, false);
	}

	makeFullScreen = (status) => {
		this.setState({ isFullScreen: status });
	}

	getTurnCredentials = async()=>{
		try {

			const turnCredentials = await _API.get('auth', `/p2pturnlist`);
			console.log("turncredentials===============", turnCredentials);
			if (turnCredentials.data != null) {
				console.log("turncredentials===============", turnCredentials);
				this.setState({ turnCredentials: turnCredentials.data })
			} else {

				try {
					const createTurnCredentials = await _API.post('auth', '/createp2pturn',{});
					console.log("createturncredentials===============", createTurnCredentials);
					if (createTurnCredentials.data != null) {
						console.log("turncredentials===============", createTurnCredentials);
						this.setState({ turnCredentials: createTurnCredentials.data })
					}
					else {
						//console.log("turncredentials===============", error);
						alert('Error to create turn getdetails! please contact admin')
						//this.props.history.push('/dashboard');
					}
				} catch (error) {
					console.log('error at creating turn details! please contact admin', error)
					alert('error at creating turn details! please contact admin');
					//this.props.history.push('/dashboard');
				}
			}
		}
		catch (error) {
			console.log('error to get turn details! please contact admin', error)
			//alert('error to get turn details! please contact admin');
			//this.props.history.push('/dashboard');
		}
	}

	componentDidMount =  () => {
		
		this.getTurnCredentials()
		this.props.instaSignaler.on('closed-ws', (event) => {
			// if(!this.props.VCCallStatus()){
			// this.setState({ setSession: true });
			// }
		});

		this.props.instaSignaler.on('oncall-ended', (event) => {

			this.props.instaSignaler.setBusy(false);
			let remote = document.getElementById('remote');
			remote.style.transform = "scale(0, 0)";
			let { isFullScreen } = this.state;
			if (isFullScreen) {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			}
			_API.post('auth', '/deletep2pturn',{}).then((res) => {
				if (res && res.status) {
					console.log("deleted turn credentials")
				}
			}).catch(error => {
				alert('error at deelte turn credentials!');
			})

			this.p2p.exitCall();
		});

		this.props.instaSignaler.on('call-accepted', (event) => {
			// console.log('Call Accepted', event);
			this.remoteId = event.remoteId;
			// if(event.publishVideo) {
			this.setState({ onP2PCall: true }, () => {
				this.p2p.connectP2PServer(event.selfId, event.remoteId, event.publishVideo, this.state.turnCredentials, (event) => {
					if (event.disconnect) {
						this.setState({ onP2PCall: false });
					}
				});
			})
			// }else {

			// }
		});
	}
	componentWillUnmount = () => {
		this.props.instaSignaler.off('closed-ws', (event) => { });
		this.props.instaSignaler.off('call-accepted', (event) => { });
	}

	leaveP2PCall = () => {
		// this.p2p.exitCall();
		let { isFullScreen } = this.state;
		if (isFullScreen) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
		this.props.instaSignaler.sendEndCallRequest(this.remoteId);
	}
	handleClose = () => {
		this.setState({ setSession: false });
	}

	gotoBuy = () => {
		this.props.history.push('/buy-room');
	}

	handleFullScreen = () => {
		let ele = this.refs.fullScreenElement;
		let { isFullScreen } = this.state;
		if (isFullScreen) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		} else {
			if (ele.requestFullscreen) {
				ele.requestFullscreen();
			} else if (ele.msRequestFullscreen) {
				ele.msRequestFullscreen();
			} else if (ele.mozRequestFullScreen) {
				ele.mozRequestFullScreen();
			} else if (ele.webkitRequestFullscreen) {
				ele.webkitRequestFullscreen();
			}
		}
	}

	muteAudio = () => {
		let { audio } = this.state;
		this.setState(prevState => { return { audio: !prevState.audio } });
		if (audio) {
			this.p2p.selfMute("audio");
		} else {
			this.p2p.selfUnMute("audio");
		}
	}

	muteVideo = () => {
		let { video } = this.state;
		this.setState(prevState => { return { video: !prevState.video } });
		if (video) {
			this.p2p.selfMute("video");
		} else {
			this.p2p.selfUnMute("video");
		}
	}

	render() {
		let { isFullScreen } = this.state;

		return (
			<div className="mainLayout">
				<Header {...this.props} />
				{/* <Draggable>
					<div className={`floatingP2P ${isFullScreen ? 'floatingFullScreen' : ''} ${(!this.state.onP2PCall) ? 'hideout' : ''}`} ref="fullScreenElement">
						<video id="remote" playsInline />
						<video id="self" muted playsInline />
						<div className="itemsControl p2pIconBlock">
							<div className="confControls">
								<div className="circle circleSm blueBg confBtns" onClick={this.muteAudio}>
									{(!this.state.audio) ?
										<img src="/images/icons/microphone-mute-white.png" alt="icon" />
										:
										<img src="/images/icons/microphone-white.png" alt="icon" />
									}
								</div>
								<div className="circle circleSm endBg confBtns p2pEnd" onClick={this.leaveP2PCall}>
									<img src="/images/icons/hang-white.png" alt="icon" />
								</div>
								<div className="circle circleSm blueBg confBtns" onClick={this.muteVideo}>
									{(!this.state.video) ?
										<img src="/images/icons/white-video-disabled.png" alt="icon" />
										:
										<img src="/images/icons/white-video.png" alt="icon" />
									}
								</div>
							</div>
						</div>
						<div className="p2pIconOptions">
							<div className="iconTxtBox">
								<Tooltip title="Full Screen" placement="top">
									<div className="iconBox24" onClick={this.handleFullScreen}>
										<img src="/images/icons/fullscreen.png" alt="icon" />
									</div>
								</Tooltip>
							</div>
						</div>
					</div>
				</Draggable> */}

				{/* NEW PTP CALL */}

				<Draggable>
					<div className={`p2pFloatCntnr ${isFullScreen ? 'p2pFullScreen' : ''} ${(!this.state.onP2PCall) ? 'hideout' : ''}`} ref="fullScreenElement">
						<div className="p2pFloatHdr">
							<button className="btnExit" onClick={this.leaveP2PCall}>
								<span className="btnExitTxt">Exit</span>
							</button>
							<div className="abslutTools" onClick={this.handleFullScreen}>
								<img src="/images/icons/fullscreen.png" alt="icon" className="abslutIcom" />
							</div>
						</div>
						<div className="p2pFloatBdy">
							<div className="p2pVideoCntnr">
								<div className="p2pMediaRemote">
									<div className="p2pVideo16x9">
										<div className="p2pVideoBox">
											{/* <img src="/images/banners/instavc-b1.jpg" /> */}
											<video id="remote" playsInline />
										</div>
									</div>
									<div className="p2pVideo16x9 selfVideo">
										<div className="p2pVideoBox">
											{/* <img src="/images/banners/instavc-b1.jpg" id="self" /> */}
											<video id="self" muted playsInline />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="p2pFloatFtr">
							<div className="p2pBtnActnGroup">
								<button className="p2pBtn btn_primary" onClick={this.muteAudio}>
									{(!this.state.audio) ?
										<img src="/images/icons/microphone-mute-white.png" alt="icon" />
										:
										<img src="/images/icons/microphone-white.png" alt="icon" />
									}
								</button>
								<button className="p2pBtn btn_primary" onClick={this.muteVideo}>
									{(!this.state.video) ?
										<img src="/images/icons/white-video-disabled.png" alt="icon" />
										:
										<img src="/images/icons/white-video.png" alt="icon" />
									}
								</button>
							</div>
						</div>
					</div>
				</Draggable>







				{/* <SessionTimeout instaSignaler={this.props.instaSignaler} uid={this.props.userPayload.sub} isOpen={this.state.setSession} closeCallback={this.handleClose} /> */}
				<div className="mainContainer">
					<Suspense fallback={<Loading />}>
						<Route exact path={this.props.path} component={this.props.component} />
					</Suspense>
				</div>
				{/* <div className="floatingJoinBtn">
					<Fab variant="extended" size="small" color="secondary" className="buyNowBtnResponsive" onClick={this.gotoBuy}>
						<AddShoppingCart className="leftIcon" />
						Buy Now
					</Fab>
				</div> */}
			</div>
		)
	}
}

export default MainLayout;