// Import Actions
import {
  ONLINE_CONTACTS, SET_CONTACTS, ADD_PENDING_CONTACT, ADD_CONTACT, SET_ERROR, SET_LICENSE_DATA,
  CLEAR_READ_COUNT, SET_READ_COUNT, SET_SELECTED_USER, SET_UNREAD, REMOVE_CONTACT
} from './actions';

// Initial State
const initialState = {
  contacts: [],
  pendingContacts: [],
  error: '',
  licenses: [],
  read: {},
  selectedUser: null
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ONLINE_CONTACTS:
      return Object.assign({}, state, { contacts: action.contacts });
    case SET_CONTACTS:
      return Object.assign({}, state, { contacts: action.contacts, pendingContacts: action.pendingContacts });
    case ADD_PENDING_CONTACT:
      return Object.assign({}, state, { pendingContacts: [...state.pendingContacts, ...action.data] });
    case SET_ERROR:
      return Object.assign({}, state, { error: action.error });
    case ADD_CONTACT:
      let addContact = state.pendingContacts.filter(item => item.userId === action.data.userId);
      addContact[0]['requeststatus'] = 'approved';
      let contacts = [...state.contacts, ...addContact];
      let pendingContacts = state.pendingContacts.filter(item => item.userId !== action.data.userId);
      return Object.assign({}, state, { contacts: [...contacts], pendingContacts: [...pendingContacts] });
    case REMOVE_CONTACT:
      let _pendingContacts = state.pendingContacts.filter(item => item.userId !== action.data.userId);
      return Object.assign({}, state, { pendingContacts: [..._pendingContacts] });
    case SET_LICENSE_DATA:
      return Object.assign({}, state, { licenses: [...action.data] });
    case CLEAR_READ_COUNT:
      let { read } = state;
      if (read[action.id]) {
        delete read[action.id];
        return Object.assign({}, state, { read: { ...read } });
      } else {
        return state;
      }
    case SET_READ_COUNT:
      let _read = {...state.read};
      _read[action.uid] = _read[action.uid] ? _read[action.uid] + 1 : 1;
      return Object.assign({}, state, { read: { ..._read } });
    case SET_SELECTED_USER:
      return Object.assign({}, state, { selectedUser: action.uid });
    case SET_UNREAD: return Object.assign({}, state, {read: {...action.data}});
    default:
      return state;
  }
};

/* Selectors */

// Get showAddPost
// export const getShowAddPost = state => state.app.showAddPost;

// Export Reducer
export default DashboardReducer;
