import React from "react";
import { Route, Redirect } from "react-router-dom";
import Guest from '../containers/guest/Guest';

export default ({ component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      (cProps.isAuthenticated) ?
     <Guest {...props} {...cProps} component={(props) => <C isLegacy={true} isGuest={true} {...cProps} {...props} />} />
     : (cProps.isAuthenticated === false ) && <Guest {...props} {...cProps} component={(props) => <C isLegacy={true} isGuest={true} {...cProps} {...props} />} />
        // : <Redirect
        //     to={`/?redirect=${props.location.pathname}${props.location
        //       .search}`}
        //   />
    }
  />;

  //</MainLayout><C {...props} {...cProps} />