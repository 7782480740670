import DispatcherEvent from "./DispatcherEvent";
import { setReadCount } from "../../dashboard/actions";

export default class InstaSignaler {
    constructor() {
        this.events = {};
        this.wsUri = "wss://opvcws.invc.in";
        // this.wsUri = "wss://8a7v2yzkva.execute-api.ap-southeast-1.amazonaws.com/dev";
        this.webSocket = null;
        this.isConnected = false;
        this.subscribers = [];
        this.reConnect = false;
        this.uid = null;
        this.isBusy = false;
    }

    dispatch(eventName, data) {
        const event = this.events[eventName];
        if (event) {
            event.fire(data);
        }
    }

    on(eventName, callback) {
        let event = this.events[eventName];
        console.log("event in on",event)
        if (!event) {
            console.log("pushing the event")
            event = new DispatcherEvent(eventName);
            this.events[eventName] = event;
        }
        event.registerCallback(callback);
        console.log("event in on",event)
    }

    off(eventName, callback) {
        const event = this.events[eventName];
        if (event && event.callbacks.indexOf(callback) > -1) {
            event.unregisterCallback(callback);
            if (event.callbacks.length === 0) {
                delete this.events[eventName];
            }
        }
    }

    subscribeOnlineStatus = (userSub, myId,action="subscribe") => {
        if (this.isConnected) {
            console.log('Subscribed for', userSub);
            try {
                if (this.subscribers.indexOf(userSub) < 0) {
                    this.subscribers.push(userSub);
                }
                console.log('Trying to send messagge to ', userSub);
                let message = {
                    "action": 'isOnline',
                    "message": action,
                    "selfId": myId,
                    "uid":userSub,
                    "data":{
                        "selfId": myId,
                        "uid":userSub,
                        
                    }
                   
                }
                this.webSocket.send(JSON.stringify(message));
                //this.webSocket.send(`{"action" : "isOnline" , "message" : "subscribe", "selfId" : "${myId}", "uid" : "${userSub}"}`);
            } catch (error) {
                console.log('Error connecting socket', error);
            }
        } else {
            // try after 5 seconds
            setTimeout(() => {
                this.subscribeOnlineStatus(userSub, myId);
            }, 3000);
        }
    }

    init = (uid, reConnect = false) => {
        console.log('uid to ', uid);
        if (localStorage.getItem('token')) {
            this.webSocket = new WebSocket(this.wsUri + '?userId=' + uid,localStorage.getItem('token'));
            this.webSocket.onopen = this.onOpen;
            this.webSocket.onclose = this.onClose;
            this.webSocket.onmessage = this.onMessage;
            this.webSocket.onerror = this.onError;
            window.webSock = this.webSocket;

            this.reConnect = reConnect;
            this.uid = uid;
        }
    }

    onOpen = (event) => {
        console.log("Websocket Open", event);
        this.isConnected = true;
        this.dispatch('connected', event.data);

        if (this.reConnect && this.uid) {
            this.subscribers.forEach((subscriber) => {
                this.subscribeOnlineStatus(subscriber, this.uid);
            });
            this.reConnect = false;
        }
    }

    onClose = (event) => {
        this.isConnected = false;
        console.log("Websocket Closed", event);
        // this.uid = null;
        this.dispatch('off-subscribe', event.data);
        this.dispatch('closed-ws', event.data);
        setTimeout(() => {
            this.init(this.uid, true);
        }, 300);
    }

    onMessage = (event) => {
        //this.dispatch(data.command)
        console.log("Websocket onMessage", event.data);
        const data = JSON.parse(event.data);
        if (data.command === 'subscribe') {
            console.log("Should be dispatched")
            this.dispatch(data.body.uid, data.body.isOnline);
            //this.dispatch(data.body.selfId, true);

        if (data.command === 'unsubscribe') {
                console.log("Should be dispatched")
                this.dispatch(data.body.selfId, false); 
        }   
        }
        // if (data.command === 'logout') {
        //     console.log("Should be dispatched")
        //     this.dispatch(data.body.selfId, true);
        // }
        if (data.command === 'PRESENCE') {
            this.dispatch(data.uid, data.status);
        }
        if (data.command === 'CALLREQUEST') {
            // if(is)
            this.dispatch('oncall-receiver', data.body);
        }

        if (data.command === 'CALLDECLAINED') {
            this.dispatch('call-declained', {});
        }

        if (data.command === 'CALLACCEPTED') {
            this.dispatch('call-accepted', { selfId: data.body.remoteId, remoteId: data.body.senderId, publishVideo: true });
        }
        if (data.command === 'ENDCALL') {
            this.dispatch('oncall-ended', {});
        }
        if (data.command === 'INDCHAT') {
            console.log("store ==== ", window.store);
            let { store } = window;
            if (store.getState().dashboard.selectedUser !== data.body.createdBy) {
                store.dispatch(setReadCount(data.body.createdBy));
            } else {
                this.dispatch('recive-ind-chat', data.body);
            }
        }
        if (data.command === 'CONTACTS') {
            console.log('InstasingalR getcontacts from signal')
            this.dispatch('get-contacts', {});
        }

        else{
            this.dispatch(data.command)
        }
    }

    setBusy = (status) => {
        this.isBusy = status;
    }

    getBusyStatus = () => {
        return this.isBusy;
    }
    sendScreenShareRequest = (selfName, toId) => {
        if (this.isConnected) {
            let message = {
                action: 'onMessage',
                message: 'REQUEST_SCREENSHARE',
                uid: toId,
                data: {
                    senderName: selfName,
                }
            }
            //this.webSocket.send(`{"action" : "onMessage" , "message" : "callrequest", "selfId" : "${selfId}", "name" : "${userName}", "uid" : "${userId}"}`);
            this.webSocket.send(JSON.stringify(message));
            // this.dispatch('oncall-ended', {});
        }
    }
    sendEndCallRequest = (toId) => {
        if (this.isConnected) {
            let message = {
                action: 'onMessage',
                message: 'ENDCALL',
                uid: toId,
                data: {

                }
            }
            //this.webSocket.send(`{"action" : "onMessage" , "message" : "callrequest", "selfId" : "${selfId}", "name" : "${userName}", "uid" : "${userId}"}`);
            this.webSocket.send(JSON.stringify(message));
            this.dispatch('oncall-ended', {});
        }
    }
    sendCallRequest = (selfId, userId, name, remoteUserName) => {
        console.log('Busy Status', this.isBusy);
        if (this.isConnected && !this.isBusy) {
            let message = {
                action: 'onMessage',
                message: 'CALLREQUEST',
                uid: userId,
                data: {
                    senderId: selfId,
                    name: name,
                    remoteUserName: remoteUserName,
                }
            }
            //this.webSocket.send(`{"action" : "onMessage" , "message" : "callrequest", "selfId" : "${selfId}", "name" : "${userName}", "uid" : "${userId}"}`);
            this.webSocket.send(JSON.stringify(message));
            this.dispatch('oncall-sender', message);
            return 1; //Request Sent
        } else if (this.isBusy) {
            return 2; //User Busy
        } else {
            return 3; //User Not Connected
        }
    }

    sendDeclainRequest = (selfId, userId) => {
        if (this.isConnected) {
            let message = {
                action: 'onMessage',
                message: 'CALLDECLAINED',
                uid: userId,
                data: {
                    senderId: selfId,
                }
            }
            this.webSocket.send(JSON.stringify(message));
            this.dispatch('call-declained', message);
        }
    }

    callAccepted = (selfId, remoteId) => {
        if (this.isConnected) {
            let message = {
                action: 'onMessage',
                message: 'CALLACCEPTED',
                uid: remoteId,
                data: {
                    senderId: selfId,
                    remoteId: remoteId
                }
            }
            this.webSocket.send(JSON.stringify(message));
            this.dispatch('call-accepted', { selfId: selfId, remoteId: remoteId, publishVideo: false });
        }
    }

    sendIndChat = (data, userId) => {
        console.log("data","userid",data,userId)
        if (this.isConnected) {
            console.log("socket connected")
            let message = {
                action: 'onMessage',
                message: 'INDCHAT',
                uid: userId,
                data: data
            }
            this.webSocket.send(JSON.stringify(message));
            this.dispatch('recive-ind-chat', data);
        }
    }

    sendCustomRequest = (obj) => {
        let message = {
            action: 'onMessage',
            message: 'CONTACTS',
            uid: obj.userId,
            data: obj
        }
        // console.log('InstasingalR getcontacts from signal method');
        // console.log("message ===== ", message);
        this.webSocket.send(JSON.stringify(message));
        this.dispatch('get-contacts', obj);
    }

    onError = (event) => {
        console.log("Websocket Error", event)
    }

    closeSocket =()=> {
        try {
            this.webSocket.send(`{"action" : "isOffline" , "subscribers" : "${this.subscribers}"}`);
            this.webSocket.close();
        } catch (error) {
            console.log('Error connecting socket', error);
        }
    }
}

