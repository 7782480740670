// Export Constants
export const SET_ID = 'SET_ID';
export const SET_DOC = 'SET_DOC';
export const SET_PARTICIPANT = 'SET_PARTICIPANT';
export const SET_IND_CHAT = 'SET_IND_CHAT';
export const CLEAR_IND_COUNT = 'CLEAR_IND_COUNT';
export const CAM_CONTROLE = 'CAM_CONTROLE';
export const CAM_CONTROLE_ID = 'CAM_CONTROLE_ID';
// Export Actions
export function setConferenceId() {
  return {
    type: SET_ID,
    id: "12345"
  };
}

export function setCamControle(type) {
  return {
    type: CAM_CONTROLE,
    status: type
  };
}

export function setCamControledPeerId(id) {
  return {
    type: CAM_CONTROLE_ID,
    id: id
  };
}

export function setDocument(obj) {
  return {
    type: SET_DOC,
    val: obj.value,
    shareId: obj.email
  }
}

export function setParticipant(id) {
  return {
    type: SET_PARTICIPANT,
    id
  }
}

export function saveIndChat(message, id) {
  return {
    type: SET_IND_CHAT,
    message,
    id
  }
}

export function clearcount(id) {
  return {
    type: CLEAR_IND_COUNT,
    id
  }
}
