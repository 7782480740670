import { API, Storage} from "aws-amplify";
import {OffLineAPI} from '../../offline-auth/api';
import {config} from '../../config';


// API.configure({Auth:config.cognito, Storage:Storage.configure({
//     AWSS3: {
//         bucket: 'instavcprofileimages',
//         region: 'ap-southeast-1'
//     }
// })
// });

let _API = config.isOffline ? OffLineAPI : API;

// Export Constants
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILE_IMG = 'SET_PROFILE_IMG';


// Export Actions
export function getProfile() {
    return dispatch => {
        return _API.get("insta", "/profile")
            .then(res => {
                 console.log("res === ", res);
                return dispatch(setProfile(res.data));
            })
            .catch(err => {
                console.log("err in getProfile ", err);
                return dispatch(setProfile({}));
            });
    }
}

// Export Actions
export function saveProfile(body) {
     console.log("body ==== ", body);
    // return dispatch => {
        return _API.post("insta", "/profile", {body})
            .then(res => {
                 console.log("res === ", res);
                return res;
                // return dispatch(setProfile(res.data));
            })
            .catch(err => {
                console.log("err in getProfile ", err);
                return err;
                // return dispatch(setProfile({}));
            });
    // }
}

// Export Actions
export function setProfile(data) {
    return {
      type: SET_PROFILE,
      data
    };
}

// export function updateProfilePic(file, fileName) {
//     console.log("action file=====>",file);

//     return dispatch => {
//         const formData = new FormData();
//         formData.append('profileImage', file);
//         // formData.set('filename', fileName);
//         console.log("formdata======>", formData);
        
//        let headers ={
//         'Content-Type': 'multipart/form-data'
//        }

//         return _API.post("user", "/updateprofileimage", {headers:headers}, {body: formData})
//             .then(res => {
//                  console.log("res === ", res);
//                 // return res;
//                 return dispatch(setProfileImg(res.data.profileImage));
//             })
//             .catch(err => {
//                 console.log("err in upload profile ", err);
//                 // return err;
//                 return dispatch(setProfileImg(''));
//             });
//         }
// }

export function updateProfilePic(file, fileName) {
    console.log("filename=====>",fileName);

    return dispatch => {
        const formData = new FormData();
        formData.append('profileImage', file);
        formData.set('filename', fileName);
        

        return _API.upload("auth", "/updateprofileimage?filename="+fileName, formData)
            .then(res => {
                console.log("res === ", res);
                // return res;
                return dispatch(setProfileImg(res.path));
            })
            .catch(err => {
                console.log("err in upload profile ", err);
                // return err;
                return dispatch(setProfileImg(''));
            });
        }
}


export function setProfileImg(data) {
    return {
        type: SET_PROFILE_IMG,
        data
      };
}
